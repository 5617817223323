import React from 'react';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
// import { NumericFormat } from 'react-number-format';

// @Mui
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { Chip, TableBody } from '@mui/material';

// @Icons
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { formatNumber } from '../../../../utils/format-number.util';

// @Utils
import { saleType } from '../../../../utils/select-items.util';
import ColumHeader from '../../../../components/TableColumnHeader';

// @Interfaces and Types
import { IProps, IRow, chipItem } from '../types';

function Row({ row }: IRow) {
  const [open, setOpen] = React.useState(false);

  const { t } = useTranslation();

  const action: chipItem[] = [
    { value: 'update', color: 'warning', label: `${t('general.update', { ns: 'common' })}` },
    { value: 'create', color: 'info', label: `${t('general.create', { ns: 'common' })}` },
  ];

  const description = action.find(description => description.value === row.action);

  return (
    <React.Fragment>
      <TableRow>
        <TableCell>
          <IconButton aria-label='expand row' size='small' onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component='th' scope='row'>
          {row.updatedAt && format(new Date(row.updatedAt), 'MM/dd/yyyy - hh:mm:ss a')}
        </TableCell>
        <TableCell align='center'>
          <Chip
            size='medium'
            label={description?.label || ''}
            color={description?.color}
            sx={{
              textTransform: 'capitalize',
              padding: '0.5rem',
              margin: '0 0.5rem',
            }}
          />
        </TableCell>
        <TableCell align='center'>{row.userName}</TableCell>
        <TableCell component='th' scope='row'>
          {row.quotationCode}
        </TableCell>
        <TableCell align='center'>
          {row.countryOrigin}/{row.countryDestiny}
        </TableCell>
        <TableCell align='center'>{row.transportTime}</TableCell>
        <TableCell align='center'>{row.comission}%</TableCell>
        <TableCell align='center'>{format(new Date(row.expDate), 'MM/dd/yyyy')}</TableCell>
        <TableCell align='center'>{formatNumber(row.total)}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0, margin: '0 auto', width: '90%' }} colSpan={6}>
          <Collapse in={open} timeout='auto' unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant='h6' gutterBottom component='div' align='center'>
                {t('general.services', { ns: 'common' })}
              </Typography>
              <Table stickyHeader aria-label='collapsible table' sx={{ ml: 3 }}>
                <TableHead sx={{ backgroundColor: 'primary.main' }}>
                  <ColumHeader
                    headers={[
                      t('general.service', { ns: 'common' }),
                      t('general.cost', { ns: 'common' }),
                      t('general.commission', { ns: 'common' }),
                      t('general.typeSale', { ns: 'common' }),
                    ]}
                  />
                </TableHead>
                {row.services.map((service: any) => (
                  <TableBody key={service.id}>
                    <TableRow>
                      <TableCell align='center'>{service.service}</TableCell>
                      <TableCell align='center'>{formatNumber(service.cost)}</TableCell>
                      <TableCell align='center'>{service.comission}%</TableCell>
                      <TableCell align='center'>
                        {saleType.find(type => type.value === service.saleType)?.label || ''}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ))}
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function ServicesupplierTable({ data }: IProps) {
  const { t } = useTranslation();

  const rows = data;

  return (
    <TableContainer component={Paper} sx={{ maxHeight: 400, minHeight: 400 }}>
      <Table stickyHeader aria-label='collapsible table'>
        <TableHead sx={{ backgroundColor: 'primary.main' }}>
          <ColumHeader
            headers={[
              '',
              t('title.date', { ns: 'common' }),
              t('title.description', { ns: 'common' }),
              t('title.username', { ns: 'common' }),
              t('general.invoice', { ns: 'common' }),
              `${t('general.origin', { ns: 'common' })}/${t('general.destiny', { ns: 'common' })}`,
              t('general.transportTime', { ns: 'common' }),
              t('general.commission', { ns: 'common' }),
              t('general.validity', { ns: 'common' }),
              t('general.total', { ns: 'common' }),
            ]}
          />
        </TableHead>
        {rows.length > 0 ? (
          <TableBody>
            {rows.map((row, index) => (
              <Row key={index} row={row} />
            ))}
          </TableBody>
        ) : (
          <TableBody>
            <TableRow>
              <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
                <Box
                  sx={{
                    display: 'flex',
                    textAlign: ' center',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: '#F7F7F7',
                    height: 300,
                  }}
                >
                  <Typography sx={{ textAlign: 'center' }} gutterBottom component='div'>
                    {t('general.noRows', { ns: 'validations' })}
                  </Typography>
                </Box>
              </TableCell>
            </TableRow>
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );
}
