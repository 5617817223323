import React, { useState } from 'react';

// @MUI Components
import { Box } from '@mui/material';

// @Components
import NavLink from './NavLink/NavLink';
import NavGroup from './NavGroup/NavGroup';
import NavSectionTitle from './NavSectionTitle';

// @Interfaces
import { Item } from '../../models/items';

interface Props {
  items: Item[];
  open: boolean;
  isMobile?: boolean;
  setOpen?: (arg0: boolean) => void;
}

export default function NavItems({ items, open, isMobile, setOpen }: Props) {
  const [openGroup, setOpenGroup] = useState('');

  return (
    <React.Fragment>
      {items?.map((item: Item, index: number) => (
        <Box key={index}>
          {item.sectionTitle && <NavSectionTitle open={open} item={item} />}
          {item.children && <NavGroup item={item} show={open} openGroup={openGroup} setOpenGroup={setOpenGroup} />}
          {!item.sectionTitle && !item.children && (
            <NavLink open={open} item={item} isMobile={isMobile} setOpen={setOpen} />
          )}
        </Box>
      ))}
    </React.Fragment>
  );
}
