import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, Control, FieldValues, FieldPath } from 'react-hook-form';

// @MUI Components
import Skeleton from '@mui/material/Skeleton';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import TextField, { StandardTextFieldProps } from '@mui/material/TextField';

// @Utils
import { axiosEstimates } from '../utils/api';

// @Interfaces and Types
import { IServiceTypesRequest, ServiceTypesSelect } from '../models';
interface Props<T extends FieldValues> extends StandardTextFieldProps {
  name: FieldPath<T>;
  watcherValue?: number[];
  control: Control<T, any>;
  loading?: boolean;
  categoriesId?: number[];
}

/**
 * @component
 * @subcategory Global
 *
 * @description
 * A component that filters services based on the selected types.
 * #### Example
 * ```
 * return(
 * <MultipleServicesTypeFilter name="example" loading={loading} control={control} categoriesId={categoriesId} watcherValue={watcherValue} />
 *
 * )
 * ```
 *
 * @property {Fieldpath<T>} name The name of the input field.
 * @property {boolean} [loading] A boolean indicating whether the input is loading.
 * @property {Control<T, any>} control The control object from react-hook-form.
 * @property {number[]} [categoriesId] An array of category IDs.
 * @property {number[]} [watcherValue] An array of selected service type IDs.
 *
 * @return {JSX.Element}
 * @author CML Exports Front-End Developers
 */
export default function MultipleServicesTypeFilter<T extends FieldValues>({
  name,
  loading,
  control,
  categoriesId = [],
  watcherValue,
  ...props
}: Props<T>) {
  const [options, setOptions] = useState<ServiceTypesSelect[]>([]);
  const [originalOptions, setOriginalOptions] = useState<ServiceTypesSelect[]>([]);
  const [selectOptions, setSelectOptions] = useState<number[]>([]);
  const [spinner, setSpinner] = useState<boolean>(false);
  const { t, i18n } = useTranslation(['common']);

  const handleFilter = async (value: string) => {
    setSpinner(true);

    try {
      const response = await axiosEstimates.post<IServiceTypesRequest[]>(
        'service-types-by-category',
        { name: value, categories: categoriesId },
        { headers: { 'x-module': 'services' } },
      );

      const items: ServiceTypesSelect[] = response.data.map(type => ({
        label: type.name,
        labelEN: type.nameEN,
        value: type.id,
        category: 0,
      }));
      setOriginalOptions(items);

      const newItems: ServiceTypesSelect[] = [];

      for (const item of items) {
        if (!selectOptions.includes(item.value)) {
          newItems.push(item);
        }
      }

      setOptions(newItems);
    } catch (error) {
      console.error(error);
      setOptions([]);
    } finally {
      setSpinner(false);
    }
  };

  const resetValue = (selectOptions: any) => {
    const newArray = selectOptions.filter((item: any) => {
      return originalOptions.some(option => option.value === item.value);
    });
    return newArray;
  };

  useEffect(() => {
    if (watcherValue) {
      setSelectOptions(watcherValue);
    }
    handleFilter('');
  }, [watcherValue]);

  return (
    <>
      {!loading ? (
        <Controller
          control={control}
          name={name}
          render={({ field: { value, onChange, ...otherField }, fieldState: { error } }) => (
            <Autocomplete
              freeSolo
              multiple
              loading={spinner}
              options={options}
              getOptionLabel={(option: any) => (i18n.language === 'es' ? option.label : option.labelEN)}
              value={categoriesId.length !== 0 ? resetValue(value) : []}
              onInputChange={async (event, value) => {
                handleFilter(value);
              }}
              onChange={async (event, item: any) => {
                const ids = new Set();
                const newItems = item.filter((item: any) => {
                  if (ids.has(item.value)) {
                    return false;
                  }
                  ids.add(item.value);
                  return true;
                });

                setSelectOptions(newItems.map((item: { value: number }) => item.value));
                onChange(newItems);
                await handleFilter('');
              }}
              {...otherField}
              renderInput={params => (
                <TextField
                  {...params}
                  {...props}
                  error={Boolean(error?.message)}
                  helperText={t(`${error?.message || ''}`)}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {spinner ? <CircularProgress color='inherit' size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
          )}
        />
      ) : (
        <Skeleton variant='rectangular' height={60} />
      )}
    </>
  );
}
