export const Translations = {
  en: {
    common: require('./en/common.json'),
    placeholder: require('./en/placeholder.json'),
    validations: require('./en/validations.json'),
  },
  es: {
    common: require('./es/common.json'),
    placeholder: require('./es/placeholder.json'),
    validations: require('./es/validations.json'),
  },
};
