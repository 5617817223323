import { axiosEstimates } from '../../../utils/api';
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { IServiceTypesRequest } from '../../../models';

export const handleGetSuppliersDate = async (date: any) => {
  try {
    const body = { date };
    const response = await axiosEstimates.post<any>('/servicetypes-historydate', body);

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export function useHistory() {
  const [history, setHistory] = useState<any>([]);
  const [serviceTypes, setServiceTypes] = useState<any>([]);

  const navigate = useNavigate();

  const { id } = useParams();

  const handleGet = async () => {
    try {
      const response = await axiosEstimates.get<any>('/service-types-history/' + id);
      setHistory(response.data);
    } catch (error) {
      console.error(error);
    }
  };
  const ActualGet = async () => {
    try {
      const response = await axiosEstimates.get<IServiceTypesRequest[]>('/servicetypes/' + id);
      setServiceTypes(response.data[0]);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    handleGet();
    ActualGet();
  }, []);

  return {
    navigate,
    history,
    serviceTypes,
  };
}
