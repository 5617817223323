import { axiosEstimates } from '../../../utils/api';

export async function cancelEmailMassive(token: string): Promise<void> {
  await axiosEstimates.post(
    'email-massive/cancel',
    {},
    {
      headers: {
        'x-customer-token': token,
      },
    },
  );
}
