import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

// @Utils
import { axiosEstimates } from '../../utils/api';

// @Models
import { IDocumentExpense, IDocumentExpenseFiles } from '../../models/expenses/expenses-form.model';

export default function useExpenses() {
  const [status, setStatus] = useState(1);
  const [filter, setFilter] = useState('');
  const [expenses, setExpenses] = useState<IDocumentExpense[]>([]);
  const [expensesFiles, setExpensesFiles] = useState<IDocumentExpenseFiles[]>([]);
  const [permissions, setPermissions] = useState<any>();

  const { t } = useTranslation('common');

  const navigate = useNavigate();

  const createExpense = () => navigate('/expenses/create');

  const handleStatus = (value: number) => setStatus(value);

  const handleFilter = (value: string) => setFilter(value);

  const handleGet = async () => {
    try {
      const endpoint = `expenses?filter=${filter}&status=${status}`;
      const expenses = await axiosEstimates.get(endpoint, {
        headers: { 'x-module': 'expenses' },
      });

      setExpenses(expenses.data.data.expenses);
      setPermissions(expenses.data.data.permission);
    } catch (err) {
      console.error(err);
    }
  };

  const handleGetFiles = async () => {
    try {
      const expenseFiles = await axiosEstimates.get<IDocumentExpenseFiles[]>('files-expenses');
      setExpensesFiles(expenseFiles.data);
    } catch (err) {
      console.error(err);
    }
  };

  const expensesHasDocuments = (id: number) => {
    const hasDocuments = expensesFiles.find(item => item.id === id);
    return hasDocuments?.hasFile === 1;
  };

  const changeStatus = (id: number, status: number) => {
    const data = { id, status };

    Swal.fire({
      title:
        status === 2
          ? t('general.sure', { ns: 'validations' }) || ''
          : t(`budget.${status}Title`, { ns: 'validations' }) || '',
      text:
        status === 2
          ? t('delete.budget', { ns: 'validations' }) || ''
          : t(`budget.${status}`, { ns: 'validations' }) || '',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: t('general.cancel', { ns: 'common' }) || '',
      confirmButtonText: t('general.confirm', { ns: 'common' }) || '',
    }).then(result => {
      if (result.isConfirmed) {
        axiosEstimates.post('expenses/status/', data).then(() => handleGet());
        Swal.fire(
          t('general.good', { ns: 'validations' }) || '',
          t(`budget.${status}Success`, { ns: 'validations' }) || '',
          'success',
        );
      }
    });
  };

  useEffect(() => {
    handleGet();
    handleGetFiles();
  }, [filter, status]);

  return {
    expenses,
    t,
    createExpense,
    filter,
    handleStatus,
    handleFilter,
    status,
    changeStatus,
    expensesHasDocuments,
    permissions,
  };
}
