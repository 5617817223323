// @MUI Components
import { Grid } from '@mui/material';

// @Hooks
import useInvoices from './useInvoices';

// @Components
import Loader from '../../components/Loader';
import { ActionsBar } from './Components/ActionBar';
import InvoiceList from './Components/InvoiceList';
import Pagination from './Components/Pagination';

// @Interfaces and Types
import IProps from './interfaces/row';

export const Invoices: React.FC<IProps> = () => {
  const {
    // NOTE: PERMISSIONS
    permissions,
    // NOTE: STATUS
    isLoading,
    noRows,
    // NOTE: FORM CONTROL
    control,
    // NOTE: DATA
    invoiceData,
    // NOTE: PAGINATION
    page,
    pageSize,
    setPageSize,
    totalBills,
    // NOTE: FILTERS
    typeFilter,
    setTypeFilter,
    customerItems,
    searchValue,
    setSearchValue,
    // NOTE: HANDLE FUNCTIONS
    handleSetPage,
    handleCancel,
    handleCustomerFilter,
  } = useInvoices();

  return (
    <Grid container sx={{ width: '90%', ml: 'auto', mr: { xs: 0.5, md: 'auto' }, zIndex: 1 }}>
      <Grid item xs={12}>
        <Grid item xs={12}>
          <ActionsBar
            control={control}
            typeFilter={typeFilter}
            setTypeFilter={setTypeFilter}
            customerItems={customerItems}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            handleCustomerFilter={handleCustomerFilter}
          />
        </Grid>

        {isLoading ? (
          <Loader />
        ) : (
          <InvoiceList invoices={invoiceData} handleCancel={handleCancel} noRows={noRows} permissions={permissions} />
        )}

        <Grid item xs={12} sx={{ width: '100%' }}>
          <Pagination
            total={totalBills}
            setPage={handleSetPage}
            page={page - 1}
            setPageSize={setPageSize}
            pageSize={pageSize}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
