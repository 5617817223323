/**
 * formatNumber
 * @category Utils
 * @function
 * @param {Number} number Number to evaluate.
 * @description Get a format number.
 */
export const formatNumber = (number: number) => {
  const config = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };
  return Intl.NumberFormat('en-US', config).format(number);
};

/**
 * FormatNumber
 * @Category Utils
 * @function
 * @param {Number} number Number to evaluate.
 * @description This is another way to get a format number.
 */
export const FormatNumber = (number: number) => {
  const parts = number.toFixed(2).split('.');
  const integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return `${integerPart}.${parts[1]}`;
};
