import * as yup from 'yup';
import { Manifiesto } from '../../../../../models';

export const schemaValidation = yup
  .object()
  .shape({
    reference: yup.string().required('required.general'),
    invoice: yup.string().required('required.general'),
    countryOrigin: yup.string().required('required.general'),
    countryDestiny: yup.string().required('required.general'),
    expeditionDate: yup.string().required('required.general'),
    forwarder: yup.object().shape({
      name: yup.string().required('required.general'),
      pilot: yup.string().required('required.general'),
      licensePlate: yup.string().required('required.general'),
      van: yup.string().required('required.general'),
      numberLicense: yup.string().required('required.general'),
    }),
    loadingPlace: yup.string().required('required.general'),
    exit: yup.string().required('required.general'),
    entrance: yup.string().required('required.general'),
    finalDestination: yup.string().required('required.general'),
    referenceCode: yup.string().required('required.general'),
    content: yup.array().of(
      yup.object().shape({
        cartaPorte: yup.string(),
        bl: yup.string(),
        ra: yup.string(),
        quantity: yup
          .number()
          .optional()
          .nullable()
          .transform(value => (isNaN(value) ? null : value)),
        rate: yup
          .number()
          .optional()
          .nullable()
          .transform(value => (isNaN(value) ? null : value)),
        decription: yup.string().required('required.general'),
        grossWeight: yup
          .number()
          .optional()
          .nullable()
          .transform(value => (isNaN(value) ? null : value)),
        volume: yup
          .number()
          .optional()
          .nullable()
          .transform(value => (isNaN(value) ? null : value)),
      }),
    ),
    by: yup.object().shape({
      name: yup.string().required('required.general'),
      direction: yup.string().required('required.general'),
      email: yup.string().notRequired(),
      phone: yup.string().notRequired(),
    }),
    shipper: yup.object().shape({
      name: yup.string().required('required.general'),
      direction: yup.string().required('required.general'),
      email: yup.string().notRequired(),
      phone: yup.string().notRequired(),
    }),
    notifyto: yup.object().shape({
      name: yup.string().required('required.general'),
      contact: yup.string().required('required.general'),
    }),
    destinyAgent: yup.object().shape({
      name: yup.string().required('required.general'),
      contact: yup.string().required('required.general'),
    }),
  })
  .required();

export const initialValues: Manifiesto = {
  bill: '',
  reference: '',
  invoice: '',
  countryOrigin: '',
  countryDestiny: '',
  consignee: '',
  forwarder: {
    name: '',
    pilot: '',
    licensePlate: '',
    van: '',
    numberLicense: '',
  },
  loadingPlace: '',
  exit: '',
  entrance: '',
  finalDestination: '',
  referenceCode: '',
  expeditionDate: '',
  content: [],
  by: {
    name: '',
    email: '',
    phone: '',
    direction: '',
  },
  shipper: {
    name: '',
    email: '',
    phone: '',
    direction: '',
  },
  notifyto: {
    name: '',
    contact: '',
  },
  destinyAgent: {
    name: '',
    contact: '',
  },
};
