import { useTranslation } from 'react-i18next';

// @MUI Imports
import { Box, CircularProgress, SxProps, Typography } from '@mui/material/';

// @Interfaces and Types
import { DefaultTFuncReturn } from 'i18next';
interface IProps {
  text?: string | DefaultTFuncReturn;
  size?: number;
  sx?: SxProps;
}

/**
 * @component
 * @subcategory Global
 *
 * @description
 * A loader component that displays a circular progress indicator and an optional text message.
 * #### Example
 * ```
 * import Loader from './Loader';
 *
 * return(
 *  <Loader text="Loading..." size={30} sx={{ margin: '20px' }} />;
 * )
 * ```
 *
 * @property {string | DefaultTFuncReturn} [text] The loader message.
 * @property {number} [size=50] The loader size.
 * @property {SxProps} [sx] Extra sx props.
 *
 * @returns {JSX.Element} A Loader component.
 * @author CML Exports Front-End Developers
 */
export default function Loader({ text, size = 50, sx = {} }: IProps) {
  const { t } = useTranslation('common');

  return (
    <Box
      sx={{
        width: '90%',
        mt: '25vh',
        ml: 'auto',
        mr: { xs: 0.5, md: 'auto' },
        zIndex: 1,
        p: 5,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        ...sx,
      }}
    >
      <CircularProgress color='primary' size={size} />

      <Typography variant='h2' sx={{ mt: 2, textAlign: 'center', fontSize: '1.4rem', color: '#afafaf' }}>
        {text || t('customerInteraction.loading.message')}
      </Typography>
    </Box>
  );
}
