// @Utils
import { axiosEstimates } from '../../../utils';

// @Interfaces and Types
import { PaymentsResponse } from '../types';

const getInvoicePayments = async (id: string) => {
  const response = await axiosEstimates.get<PaymentsResponse>(`/api/bill-payments/${id}`);
  const { status, message, data } = response.data;

  if (status === 200) return data;
  else throw new Error(`${status} - ${message || 'Error al guardar el pago'} `);
};

export default getInvoicePayments;
