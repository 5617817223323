import * as yup from 'yup';
import { Box, Divider, FormControl, Grid, Stack, Typography, Button } from '@mui/material';
import { ButtonForm, TextForm, SelectInput } from '../../../../components';
import { CFFCaptureData } from '../../models/Models';
import { useInvoiceFormCCF } from './UseCCFInvoice';
import { CCFCreateConditionValidationSchema, CCFCreateToValidationsSchema, defaultCCFValues } from './validations/CCF';
import { useForm, Resolver } from 'react-hook-form';
interface IProps {
  data?: CFFCaptureData;
}

export const FormInvoiceCCF = ({ data }: IProps) => {
  const { t, onSubmit, cancel, total } = useInvoiceFormCCF({ data });
  const numericTotal = total && parseFloat(total);
  const isValidationEnabled = numericTotal && numericTotal > 11428;

  const customResolver: Resolver<CFFCaptureData, any> = async (values, context, options) => {
    try {
      if (isValidationEnabled) {
        await CCFCreateToValidationsSchema.validate(values, { abortEarly: false });
      } else {
        await CCFCreateConditionValidationSchema.validate(values, { abortEarly: false });
      }

      return {
        values,
        errors: {}, // Provide an empty object for the errors property
      };
    } catch (errors) {
      const validationErrors = errors as yup.ValidationError;

      const formErrors: Record<keyof CFFCaptureData, string> = validationErrors.inner.reduce((acc, error) => {
        const path = error.path as keyof CFFCaptureData;
        acc[path] = error.message;
        return acc;
      }, {} as Record<keyof CFFCaptureData, string>);

      return {
        values,
        errors: formErrors,
      };
    }
  };

  const { control, handleSubmit } = useForm<CFFCaptureData>({
    defaultValues: {
      ...defaultCCFValues,
    },
    resolver: customResolver,
  });

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '70vh',
        width: '100%',
      }}
    >
      <Box
        sx={{
          width: '800px',
          backgroundColor: 'white',
          borderRadius: '8px',
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
        }}
      >
        <Stack sx={{ padding: '30px' }} spacing={2} direction='column' alignItems='center'>
          <Typography sx={{ textAlign: 'center', fontSize: 'x-large', fontWeight: '600' }} component='div'>
            {t('general.missingData', { ns: 'common' })}
          </Typography>
          <Divider sx={{ width: '100%' }} />
          <form>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                {/* Campos de la primera columna */}
                <FormControl fullWidth sx={{ width: '100%', marginBottom: 2 }}>
                  <SelectInput
                    name='condition'
                    control={control}
                    label={t('general.conditionPayment')}
                    items={[
                      { value: 'Crédito', label: t('general.credit') },
                      { value: 'Contado', label: t('general.cashCount') },
                    ]}
                  />
                </FormControl>
                <Typography sx={{ marginBottom: 2 }}>{t('general.deliveryby', { ns: 'common' })}</Typography>
                <TextForm
                  control={control}
                  name='deliveryby.name'
                  label={t('general.nameBusiness')}
                  type='string'
                  sx={{ marginBottom: 2 }}
                />
                <TextForm
                  control={control}
                  name='deliveryby.DUI'
                  label={t('general.nitDui')}
                  type='string'
                  sx={{ marginBottom: 2 }}
                />
              </Grid>
              <Grid item xs={6}>
                {/* Campos de la segunda columna */}
                <TextForm
                  control={control}
                  name='customerPayments'
                  label={t('general.customerPayments')}
                  type='string'
                  sx={{ marginBottom: 2 }}
                />
                <Typography sx={{ marginBottom: 2 }}>{t('general.receivedby', { ns: 'common' })}</Typography>
                <TextForm
                  control={control}
                  name='receivedby.name'
                  label={t('general.nameBusiness')}
                  type='string'
                  sx={{ marginBottom: 2 }}
                />
                <TextForm
                  control={control}
                  name='receivedby.DUI'
                  label={t('general.nitDui')}
                  type='string'
                  sx={{ marginBottom: 2 }}
                />
              </Grid>
            </Grid>
          </form>
          <Divider sx={{ width: '100%' }} />
          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            {/* <ButtonForm
              label={t('general.cancel', { ns: 'common' })}
              onClick={() => cancel()}
              customStyles={{
                backgroundColor: 'red',
                color: 'white',
              }}
            /> */}
            <Button color='error' variant='contained' onClick={() => cancel()}>
              {t('general.cancel', { ns: 'common' })}
            </Button>
            {<ButtonForm size='large' label={t('general.send', { ns: 'common' })} onClick={handleSubmit(onSubmit)} />}
          </Box>
        </Stack>
      </Box>
    </Box>
  );
};
