import { useTranslation } from 'react-i18next';

// @Hooks
import { useServiceForm } from './use-service-form.hook';

// @MUI Components
import { Box, Button, Grid } from '@mui/material';

// @Components
import { ReactSelectForm, TextForm } from '../../../components';

// @Interfaces and Types
import { IGetServices } from '../../../models';
interface IProps {
  handleRender: (render: boolean) => void;
  addState: (data: IGetServices) => void;
  editState: (data: IGetServices) => void;
  service?: IGetServices;
}

export function ServiceForm({ handleRender, addState, service, editState }: IProps) {
  const { onSubmit, control, serviceTypesItemsEN, serviceTypesItemsES } = useServiceForm({
    addState,
    service,
    editState,
  });

  const { t, i18n } = useTranslation('common');

  return (
    <Box component={'form'} onSubmit={onSubmit}>
      <Grid container sx={{ display: 'flex', justifyContent: { xs: 'center', md: 'space-between' }, gap: 3 }}>
        <Grid item xs={12} sm={8} md={4}>
          <ReactSelectForm
            placeholder='Select a service type'
            control={control}
            name='idServiceType'
            label={t('services.type')}
            items={i18n.language === 'en' ? serviceTypesItemsEN : serviceTypesItemsES}
            translationFile='validations'
          />
        </Grid>

        <Grid item xs={12} sm={8} md={3.5}>
          <TextForm
            fullWidth
            control={control}
            name='serviceName'
            label={t('services.name')}
            translationFile='validations'
          />
        </Grid>

        <Grid item xs={12} sm={8} md={3.5}>
          <TextForm
            fullWidth
            control={control}
            name='serviceNameEN'
            label={t('services.nameEN')}
            translationFile='validations'
          />
        </Grid>

        <Box sx={{ width: '100%', display: 'flex', justifyContent: { xs: 'center', md: 'flex-end' } }}>
          <Button type='button' sx={{ mr: 3 }} variant='contained' color='error' onClick={() => handleRender(false)}>
            {t('general.cancel')}
          </Button>

          <Button type='submit' variant='contained' color='info'>
            {t('general.save')}
          </Button>
        </Box>
      </Grid>
    </Box>
  );
}
