import { DefaultTFuncReturn } from 'i18next';

// @MUI Components
import { Box, SxProps, Typography } from '@mui/material';

// @Interfaces and Types
import { ThemeColor } from '../models';

interface IProps {
  title: DefaultTFuncReturn | string;
  text: string | number | DefaultTFuncReturn;
  direction?: 'column' | 'row';
  color?: ThemeColor;
  size?: 1 | 1.25 | 1.5 | 1.75 | 2;
  capitalize?: boolean;
  bold?: boolean;
  sx?: SxProps;
}

/**
 * @component
 * @subcategory Global
 *
 * @description
 * A reusable DataBox component that displays a title and a text. It can be customized with a direction, color, size, capitalize, bold, and sx props.
 * #### Example
 * ```tsx
 * import { DataBox } from './DataBox';
 *
 * return (
 *  <DataBox title="Title" text="Text" direction="row" color="secondary" size={1} capitalize={false} bold={false} sx={{}} />
 * )
 * ```
 *
 * @property {DefaultTFuncReturn | string} title The title to be displayed.
 * @property {string | number | DefaultTFuncReturn} text The text to be displayed.
 * @property {'column' | 'row'} [direction='row'] The direction of the layout. Can be either 'column' or 'row'.
 * @property {ThemeColor} [color='secondary'] The color of the text. Can be any valid ThemeColor value.
 * @property {1 | 1.25 | 1.5 | 1.75 | 2} [size=1] The font size of the text. Can be any valid size value.
 * @property {boolean} [capitalize=false] Whether or not to capitalize the first letter of the text.
 * @property {boolean} [bold=false] Whether or not to make the text bold.
 * @property {SxProps} [sx={}] An optional sx prop to apply custom styles to the component.
 *
 * @return {JSX.Element} Returns a Box component that contains a title and a text.
 * @author CML Exports Front-End Developers
 */
const DataBox = ({
  title,
  text,
  direction = 'row',
  color = 'secondary',
  size = 1,
  capitalize = false,
  bold = false,
  sx = {},
}: IProps) => (
  <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: direction, gap: 0.5, ...sx }}>
    <Typography
      sx={{
        fontWeight: 600,
        fontSize: `${size}rem`,
        color: ({ palette }) => palette[color].main,
        textTransform: capitalize ? 'capitalize' : '',
      }}
    >{`${title}${direction === 'row' ? ':' : ''}`}</Typography>
    <Typography
      sx={{
        fontWeight: bold ? 600 : 500,
        fontSize: `${size}rem`,
        color: ({ palette }) => palette[color].main,
        textTransform: capitalize ? 'capitalize' : '',
      }}
    >
      {text}
    </Typography>
  </Box>
);

export default DataBox;
