import { axiosEstimates } from '../../../utils';
import { GetBudget, GetBudgetData, GetDocumentsByBudget, Document } from '../models';

export async function getBudget(id: string): Promise<GetBudgetData> {
  const response = await axiosEstimates.get<GetBudget>('/v2/budget/' + id);
  return response.data.data;
}

export async function getDocumentsByBudget(id: string): Promise<Document[]> {
  const response = await axiosEstimates.get<GetDocumentsByBudget>('/api/document/' + id);

  return response.data.data;
}
