// @Hooks
import { useServiceForm } from './useServiceForm';

// @Models
import { IShipmentService } from '../../../../../models';

// ** MUI Imports
import Drawer from '@mui/material/Drawer';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

// ** Icons Imports
import Close from 'mdi-material-ui/Close';

// @Components
import { SelectInput, TextForm, ButtonForm, NumberField, IntegerField } from '../../../../../components';
import SelectWithSearch from '../../../../../components/SelectWithSearch';

// @Services
import getCategoryItems from '../../../../../pages/Suppliers/services/getCategoryItems';
import getServicesTypesItems from '../../../../../pages/Suppliers/services/getServicesTypesItems';

const Header = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 4),
  justifyContent: 'space-between',
  backgroundColor: theme.palette.background.default,
}));

interface IProps {
  open: boolean;
  toggle: () => void;
  handleAddService: (value: Omit<IShipmentService, 'id'>) => void;
  handleEditService: (value: IShipmentService) => void;
  service?: IShipmentService;
}

export default function ServiceForm({ handleAddService, handleEditService, service, open, toggle }: IProps) {
  const {
    control,
    onSubmit,
    t,
    unit,
    typeUnit,
    onChangeTransportations,
    onChangeTypeService,
    watchIdTransportation,
    watchIdServiceType,
  } = useServiceForm({
    handleAddService,
    handleEditService,
    service,
    open,
  });

  return (
    <Drawer
      open={open}
      anchor='right'
      variant='temporary'
      onClose={toggle}
      ModalProps={{ keepMounted: true }}
      sx={{ '& .MuiDrawer-paper': { width: { xs: 300, sm: 400 } } }}
    >
      <Header>
        <Typography variant='h6'>{t('general.service', { ns: 'common' })}</Typography>
        <Close fontSize='small' onClick={toggle} sx={{ cursor: 'pointer' }} />
      </Header>

      <Box sx={{ padding: '0 1rem' }}>
        <Grid container spacing={3} sx={{ my: 0.25 }}>
          <Grid item xs={12}>
            <SelectWithSearch
              control={control}
              name='idTransportation'
              label={t('general.category', { ns: 'common' })}
              getInitialItems
              setInitialItem={watchIdTransportation || ''}
              getItemsFunction={getCategoryItems}
              onExternalChange={onChangeTransportations}
            />
          </Grid>

          <Grid item xs={12}>
            <SelectWithSearch
              control={control}
              name='idServiceType'
              label={t('general.typeService', { ns: 'common' })}
              getInitialItems={Boolean(watchIdTransportation)}
              setInitialItem={(watchIdTransportation && watchIdServiceType) || ''}
              getItemsFunction={getServicesTypesItems}
              functionParams={{ idTransportation: watchIdTransportation }}
              onExternalChange={onChangeTypeService}
              // eslint-disable-next-line no-extra-boolean-cast
              disabled={!Boolean(watchIdTransportation)}
            />
          </Grid>

          <Grid item xs={12}>
            <TextForm control={control} name='content' label={t('general.content', { ns: 'common' })} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <NumberField control={control} name='insurance' label={`${t('shipment.insurance', { ns: 'common' })}`} />
          </Grid>

          {/* <Grid item xs={12} sm={4}>
          <SelectForm control={control} name='incoterms' label='Incoterms' items={incotermsItem} />
        </Grid> */}

          <Grid item xs={12} sm={6}>
            <SelectInput
              control={control}
              name='unitOfMeasurement'
              label={t('shipment.unitOfMeasurement', { ns: 'common' })}
              items={typeUnit}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <IntegerField
              type='number'
              control={control}
              name='length'
              label={`${t('shipment.length', { ns: 'common' })} (${unit?.size || ''})`}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <IntegerField
              type='number'
              control={control}
              name='high'
              label={`${t('shipment.high', { ns: 'common' })} (${unit?.size || ''})`}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <IntegerField
              type='number'
              control={control}
              name='width'
              label={`${t('shipment.width', { ns: 'common' })} (${unit?.size || ''})`}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <IntegerField
              type='number'
              control={control}
              name='physicalWeight'
              label={`${t('shipment.physicalWeight', { ns: 'common' })} (${unit?.weight || ''})`}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <IntegerField
              control={control}
              name='area'
              label={`${t('shipment.area', { ns: 'common' })} (${unit?.area || ''})`}
              disabled
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <IntegerField
              control={control}
              name='volumetricWeight'
              label={`${t('shipment.volumetricWeight', { ns: 'common' })} (${unit?.weight || ''})`}
              disabled
            />
          </Grid>

          <Grid item xs={12}>
            <TextForm
              control={control}
              name='comments'
              label={t('general.comments', { ns: 'common' })}
              multiline
              minRows={4}
            />
          </Grid>
        </Grid>

        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', my: 2, gap: 2 }}>
          <ButtonForm label={t('general.cancel', { ns: 'common' })} color='error' onClick={toggle} />
          <ButtonForm label={t('general.save', { ns: 'common' })} onClick={onSubmit} />
        </Box>
      </Box>
    </Drawer>
  );
}
