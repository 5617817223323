// @Models
import { DataGrid } from '@mui/x-data-grid';
import { Shipment } from '../models';
import { defaultColumns } from '../utils';
import { DataGridStyles } from '../../../../utils';

interface Props {
  shipments: Shipment[];
  handleSelectShipment: (ids: any) => void;
  selectedRows: number[];
}

export function ShipmentList({ shipments, handleSelectShipment, selectedRows }: Props) {
  const columns = defaultColumns();
  return (
    <div style={{ height: 400, width: '100%' }}>
      <DataGrid
        rows={shipments}
        checkboxSelection
        disableSelectionOnClick
        columns={columns as any}
        // page={page}
        // pageSize={pageSize}
        // localeText={LocalizedTextMap()}
        sx={{
          ...DataGridStyles,
        }}
        onSelectionModelChange={newSelection => {
          handleSelectShipment(newSelection);
        }}
        selectionModel={selectedRows}
        hideFooter
      />
    </div>
  );
}
