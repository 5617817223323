import { useState } from 'react';
import { Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

// @MUI Components
import {
  Box,
  Grid,
  CardHeader,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
} from '@mui/material/';
import { DataGrid, GridRowParams } from '@mui/x-data-grid';

// @MUI Icons
import CloseCircleOutline from 'mdi-material-ui/CloseCircleOutline';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// @Components
import Pagination from '../../../../components/Pagination';
import { ReactSelectForm } from '../../../../components';

// @Utils
import { defaultColumns } from '../utils/suppliers-columns';
import LocalizedTextMap from '../../../../translations/LocalizedESTextMap';
import DataGridStyles from '../../../../utils/DataGridStyles';

// @Models
import { ISelectInput, IServicesOfSuppliers, IShipmentForm, IServiceBudgetForm } from '../../../../models';

interface IProps {
  suppliers: IServicesOfSuppliers[];
  handleSelect: (row: IServicesOfSuppliers) => void;
  control: Control<IShipmentForm, any>;
  transportationItems: ISelectInput<number>[];
  typeService: ISelectInput<number>[];
  rowId: number | null;
  services: IServiceBudgetForm[];
  selectRows: any;
  selectedRows: any;
  resetFilters(): void;
}

function TableSuppliers({
  suppliers,
  handleSelect,
  control,
  transportationItems,
  typeService,
  services,
  selectRows,
  selectedRows,
  resetFilters,
}: IProps) {
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(5);
  const [expand, setExpand] = useState<boolean>(false);

  const columns = defaultColumns(handleSelect, services);

  const { t } = useTranslation('common');

  return (
    <Box style={{ width: '100%' }}>
      <CardHeader
        title={t('title.servicepersuppliers')}
        sx={{ display: 'flex', textAlign: 'center' }}
        titleTypographyProps={{ variant: 'h6' }}
      />

      <Accordion sx={{ mt: 2 }} expanded={expand}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel1a-content'
          id='panel1a-header'
          onClick={() => setExpand(!expand)}
        >
          {expand ? (
            <Typography sx={{ display: 'flex', textAlign: 'center' }} variant='subtitle1'>
              {t('filters.hideall')}
            </Typography>
          ) : (
            <Typography sx={{ display: 'flex', textAlign: 'center' }} variant='subtitle1'>
              {t('filters.showall')}
            </Typography>
          )}
        </AccordionSummary>

        <AccordionDetails sx={{ m: 2 }}>
          <Grid container spacing={5} sx={{ my: 2 }}>
            <Grid item xs={12} sm={6}>
              <ReactSelectForm
                control={control}
                name='transportationId'
                label={t('general.categories')}
                items={transportationItems}
              />
            </Grid>

            <Grid item xs={11} sm={5}>
              <ReactSelectForm
                control={control}
                name='serviceType'
                label={t('general.typeService')}
                items={typeService}
              />
            </Grid>

            <Grid item xs={1}>
              <IconButton
                title={`${t('general.cancel')}`}
                name='general.edit'
                sx={{ display: 'flex', alignItems: 'center' }}
              >
                <CloseCircleOutline color='error' fontSize='inherit' onClick={resetFilters} />
              </IconButton>
            </Grid>
          </Grid>

          <DataGrid
            isRowSelectable={(row: GridRowParams) => moment(row.row.expDate).diff(moment(new Date()), 'days') >= 0}
            page={page}
            pageSize={pageSize}
            autoHeight
            rows={suppliers}
            sx={{ ...DataGridStyles }}
            checkboxSelection
            disableSelectionOnClick
            columns={columns as any}
            hideFooter
            onSelectionModelChange={newSelection => selectRows(newSelection)}
            selectionModel={selectedRows}
            localeText={LocalizedTextMap()}
          />
        </AccordionDetails>
      </Accordion>

      <Pagination
        count={suppliers.length}
        page={page}
        setPage={setPage}
        pageSize={pageSize}
        setPageSize={setPageSize}
        limitPagination={[5]}
      />
    </Box>
  );
}

export default TableSuppliers;
