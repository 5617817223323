// @Utils
import { axiosEstimates } from '../../../utils';

// @Interfaces and Types
import { IDocument } from '../../../models';
import { fileBase64 } from '../types';

const getExpenseDocument = async (id: string) => {
  try {
    const expenseDocumentResponse = await axiosEstimates.get<IDocument>(`expenses/${id}`);

    if (!expenseDocumentResponse.status) throw Error(expenseDocumentResponse.statusText);

    const expenseFileResponse = await axiosEstimates.get<fileBase64[]>(`/file-expenses/${id}`);

    return { data: expenseDocumentResponse.data, file: expenseFileResponse.data };
  } catch (err) {
    console.error(err);
    return null;
  }
};

export default getExpenseDocument;
