// @MUI Imports
import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Box, { BoxProps } from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';

// ** Icons Imports
import Attachment from 'mdi-material-ui/Attachment';

// @Components
import { CustomAvatar, Dialog } from '../../components';
import FileUpload from '../../components/FileUploader/FileUpload';

// @Views
import ChatLog from '../../views/send-supplier/chat/components/ChatLog';
import { RequestContent } from '../../views/send-supplier/chat/components/request-content';

// @Utils Imports
import { getInitials } from '../../utils';

// @Hooks
import { useSupplierChat } from './use-supplier-chat.hook';
import { Grid } from '@mui/material';

// @Styled Components
const ChatFormWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  boxShadow: theme.shadows[1],
  padding: theme.spacing(1.25, 4),
  justifyContent: 'space-between',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.paper,
}));

const Form = styled('form')(({ theme }) => ({
  padding: theme.spacing(0, 5, 5),
}));

export function SupplierChat() {
  const {
    handleSendMsg,
    msg,
    setMsg,
    chat,
    handleToggle,
    idBudget,
    open,
    handleSendFile,
    config,
    openFileUploader,
    setOpenFileUploader,
    file,
    setFile,
  } = useSupplierChat();

  return (
    <>
      <RequestContent idBudget={idBudget || 0} open={open} toggle={handleToggle} />

      <Grid
        container
        className='app-chat'
        sx={{
          width: { xs: '80%', lg: '70%' },
          mx: 'auto',
          zIndex: 1,
          display: 'flex',
          borderRadius: 1,
          overflow: 'hidden',
          position: 'relative',
          backgroundColor: 'background.paper',
          boxShadow: 6,
          margin: 'auto',
          border: theme => `1px solid ${theme.palette.divider}`,
        }}
      >
        <Box sx={{ flexGrow: 1, width: '100%', height: '95vh', backgroundColor: theme => theme.palette.action.hover }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              background: theme => theme.palette.background.paper,
              padding: '1rem',
              justifyContent: 'space-between',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
              <Badge overlap='circular' anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} sx={{ mr: 3 }}>
                <CustomAvatar
                  skin='light'
                  src={config?.logo}
                  sx={{ width: '2.375rem', height: '2.375rem', fontSize: '1rem' }}
                >
                  {config?.logo === null ? getInitials(config.name) : null}
                </CustomAvatar>
              </Badge>

              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography sx={{ fontWeight: 500, fontSize: '0.875rem' }}>{config?.name}</Typography>
              </Box>
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Button size='medium' sx={{ color: 'text.info' }} onClick={handleToggle}>
                Ver Peticion
              </Button>
            </Box>
          </Box>

          <Divider />

          <Box
            sx={{
              position: 'relative',
              flexGrow: 1,
              width: '100%',
              height: '100%',
              backgroundColor: theme => theme.palette.action.hover,
            }}
          >
            <Box sx={{ width: '100%', height: '80%' }}>
              <ChatLog
                hidden={false}
                idUser={null}
                data={{
                  chat: chat || {
                    chat: [],
                    id: 1,
                    unseenMsgs: 0,
                    userId: 1,
                  },
                  contact: {
                    id: 0,
                    fullName: 'CML Exports Inc.',
                    about: '',
                  },
                  userContact: {
                    id: null,
                    avatar: '',
                    fullName: 'Suppplier',
                    about: '',
                  },
                }}
              />
            </Box>

            <Form
              onSubmit={handleSendMsg}
              sx={{ position: 'absolute', width: '90%', left: '50%', bottom: '10%', transform: 'translateX(-50%)' }}
            >
              <ChatFormWrapper>
                <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
                  <TextField
                    fullWidth
                    value={msg}
                    size='small'
                    multiline
                    placeholder='Type your message here…'
                    onChange={e => setMsg(e.target.value)}
                    sx={{ '& .MuiOutlinedInput-input': { pl: 0 }, '& fieldset': { border: '0 !important' } }}
                  />
                </Box>

                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <IconButton
                    onClick={() => setOpenFileUploader((prev: any) => !prev)}
                    size='small'
                    component='label'
                    htmlFor='upload-img'
                    sx={{ mr: 4, color: 'text.primary' }}
                  >
                    <Attachment sx={{ fontSize: '1.375rem' }} />
                  </IconButton>

                  <Button type='submit' variant='contained' disabled={msg.length === 0}>
                    Send
                  </Button>
                  <Dialog handleClose={() => setOpenFileUploader((prev: any) => !prev)} open={openFileUploader}>
                    <FileUpload file={file} setFile={setFile} />
                    <Box
                      sx={{
                        m: '1rem',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Button variant='contained' onClick={handleSendFile}>
                        Enviar
                      </Button>
                    </Box>
                  </Dialog>
                </Box>
              </ChatFormWrapper>
            </Form>
          </Box>
        </Box>
      </Grid>
    </>
  );
}
