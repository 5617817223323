import Swal, { SweetAlertIcon } from 'sweetalert2';

// @Configs
import i18n from '../i18n';

/**
 * @Category Utils
 * @function CustomSwalAlert
 * @param title Alert's title, usually we use "Success!" or "Error!" but you can set any string.
 * @param text Alert's description for give more information to the user.
 * @param icon Alert's icon, this is typed with the icons that "Sweetalert2" has available.
 * @param cancelButton Boolean for show or hide the "Cancel" button when is an alert of confirmation.
 * @param cb Callback to execute as long as the alert is of confirmation and the user push down the button "Ok" (Can be async and also nest another CustomSwalAlert).
 * @example CustomSwalAlert("Warning!", "You're about to delete this element", "warning", true, () => { closeModal() })
 */
const CustomSwalAlert = (title: string, text: string, icon: SweetAlertIcon, cancelButton: boolean, cb?: () => void) => {
  Swal.fire({
    title,
    text,
    icon,
    showCancelButton: cancelButton,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    cancelButtonText: i18n.language === 'es' ? 'Cancelar' : 'Cancel',
    confirmButtonText: i18n.language === 'es' ? 'Aceptar' : 'Ok',
    width: 575,
  }).then(result => {
    if (result.isConfirmed && cb) cb();
  });
};

export default CustomSwalAlert;
