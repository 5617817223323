import * as yup from 'yup';

export const serviceValidationSchema = yup.object().shape({
  serviceName: yup.string().required('service.serviceName'),
  serviceNameEN: yup.string().required('service.serviceNameEN'),
  serviceType: yup
    .mixed()
    .test(
      'isNumberOrObject',
      'service.serviceType',
      value => typeof value === 'number' || (typeof value === 'object' && value !== null),
    )
    .required('service.serviceType'),
  categoryId: yup.array().of(yup.number().typeError('service.category')).required('service.category'),
});

export const categoryValidationSchema = yup.object().shape({
  name: yup.string().required('general.required'),
  nameEN: yup.string().required('general.required'),
});

export const serviceTypeValidationSchema = yup.object().shape({
  name: yup.string().required('serviceType.name'),
  nameEN: yup.string().required('serviceType.nameEN'),
  categories: yup.array().of(yup.number().typeError('service.category')).required('service.category'),
});
