import { axiosEstimates } from '../../../utils/api';
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

export const handleGetSuppliersId = async (id: any) => {
  try {
    const response = await axiosEstimates.get<any>('/supplier-history/' + id);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export function useHistory() {
  const [history, setHistory] = useState<any>([]);
  const [services, setServices] = useState<any>([]);
  const navigate = useNavigate();
  const { id } = useParams();

  const handleGet = async () => {
    try {
      const response = await axiosEstimates.get<any>('/services-history/' + id);
      setHistory(response.data);
    } catch (error) {
      console.error(error);
    }
  };
  const ActualGet = async () => {
    try {
      const response = await axiosEstimates.get<any>('/servicesdetails/' + id);
      setServices(response.data[0]);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    handleGet();
    ActualGet();
  }, []);

  return {
    navigate,
    history,
    services,
  };
}
