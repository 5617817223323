import { ReactNode } from 'react';

// @Mui
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Card } from '@mui/material';

// ** Views
import { PdfSettings } from '../PDFSettings/PdfSettings';
import { FolioManagmentView } from '../FolioManagment/folio-managment.page';
import { useDesignOptions } from './design-options.hook';

interface TabPanelProps {
  children?: ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ m: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function DesignOptionsTabs() {
  const { value, handleChange, t } = useDesignOptions();

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Card>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} aria-label='basic tabs example'>
              <Tab label={t('PDF´S', { ns: 'common' })} {...a11yProps(0)} />
              <Tab label={t('general.invoice', { ns: 'common' })} {...a11yProps(1)} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <PdfSettings />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <FolioManagmentView />
          </TabPanel>
        </Card>
      </Box>
    </>
  );
}
