import * as yup from 'yup';

const serviceSchema = yup
  .object()
  .shape({
    idService: yup.number().typeError('errors.require.idService').required('errors.require.idService'),
    idTransportation: yup.number().typeError('errors.require.transportation').required('errors.require.transportation'),
    idServiceType: yup.number().typeError('errors.require.idServiceType').required('errors.require.idServiceType'),
    salePrice: yup.number(),
    saleType: yup.number().typeError('errors.require.saleType').required('errors.require.saleType'),
  })
  .required();

export default serviceSchema;
