import moment from 'moment'; // deprecated
import es from 'date-fns/locale/es';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { registerLocale } from 'react-datepicker';

// @MUI Components
import { Card, Box, Grid, Button, Divider, Typography, FormControlLabel, Checkbox } from '@mui/material';

// @Components
import Row from './components/Row';
import Error from '../../components/Error';
import Loader from '../../components/Loader';
import FormExpenses from './components/ExpenseForm';
import FileUpload from '../../components/FileUploader/FileUpload';
import TableComponent from '../../components/TableContainer';
import { TextForm, SelectInput, DatePicker, ReactSelectForm } from '../../components';

// @Hooks
import useExpenseForm from './hooks/useExpensesForm';

// @Utils
import { documentType } from '../../utils/select-items.util';
import { formatNumber } from '../../utils/format-number.util';
import i18n from '../../i18n';

registerLocale('es', es);

export function ExpensesForm() {
  const {
    // NOTE: PAGE LOAD STATUS
    isLoading,
    isError,
    // NOTE: DATA
    suppliers,
    expenses,
    expense,
    budgetItems,
    total,
    // NOTE: ACTIONS
    sendExpenseDocument,
    addExpense,
    editExpense,
    deleteExpense,
    // NOTE: HANDLE FUNCTIONS
    handleToggleForm,
    handleEditExpense,
    handleBack,
    // NOTE: FORM STATES
    formIsOpen,
    control,
    expenseFile,
    setExpenseFile,
    handleSubmit,
  } = useExpenseForm();

  const { t } = useTranslation('common');

  if (isError) return <Error />;

  if (isLoading) return <Loader />;

  return (
    <>
      <Grid container spacing={2} sx={{ width: '90%', ml: 'auto', mr: { xs: 0.5, md: 'auto' }, zIndex: 1 }}>
        <Card
          sx={{
            width: '100%',
            p: '5%',
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          {/* MAIN FORM */}
          <Grid container spacing={3} sx={{ display: 'flex' }}>
            <Grid item xs={12}>
              <ReactSelectForm
                name='idBudget'
                control={control}
                items={budgetItems}
                label={t('expenses.quotation', { ns: 'common' })}
              />
            </Grid>

            <Grid item xs={12}>
              <Divider sx={{ mt: 4, mb: 2 }} />
            </Grid>

            <Grid item xs={12}>
              <Typography variant='h5' sx={{ textAlign: 'center' }}>
                {t('expenses.details', { ns: 'common' })}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <ReactSelectForm
                name='idSupplier'
                control={control}
                items={suppliers}
                label={t('general.supplier', { ns: 'common' })}
              />
            </Grid>

            <Grid item xs={6}>
              <SelectInput
                name='type'
                control={control}
                items={documentType}
                label={t('general.typeDocument', { ns: 'common' })}
              />
            </Grid>

            <Grid item xs={6}>
              <DatePicker
                name='date'
                control={control}
                minDate={moment().subtract(30, 'days').toDate()}
                maxDate={new Date()}
              />
            </Grid>

            <Grid item xs={6}>
              <TextForm control={control} name='code' label={t('general.invoice', { ns: 'common' })} />
            </Grid>

            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Controller
                    control={control}
                    name='otherCharges'
                    render={({ field: props }) => (
                      <Checkbox {...props} checked={props.value} onChange={e => props.onChange(e.target.checked)} />
                    )}
                  />
                }
                label={t('expenses.accountalien', { ns: 'common' })}
              />
            </Grid>

            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', width: { xs: 20 } }}>
              <FileUpload
                file={expenseFile}
                setFile={setExpenseFile}
                maxMbSize={10}
                caption={
                  i18n.language === 'es'
                    ? 'Solo admite extensiones JPG, PNG, PDF, peso máximo de 10mb'
                    : 'Only supports JPG, PNG, PDF extensions, maximum size of 10mb'
                }
              />
            </Grid>
          </Grid>

          <Grid item xs={12} sx={{ mt: 6, mb: 2.5, display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant='h6'>
              {t('general.total', { ns: 'common' })}: {formatNumber(total)}
            </Typography>

            <Button
              size='medium'
              variant='contained'
              onClick={handleToggleForm}
              startIcon={<i className='fa-sharp fa-solid fa-plus' />}
            >
              {t('add.general')}
            </Button>
          </Grid>

          <Grid item xs={12} sx={{}}>
            <TableComponent
              Row={Row}
              data={expenses}
              headers={[
                [t('general.actions'), 'left'],
                [t('title.description'), 'left'],
                [t('general.quantity'), 'left'],
                [t('general.unitPrice'), 'left'],
                [t('general.subTotal'), 'left'],
                [t('general.iva'), 'left'],
                [t('expenses.retention'), 'left'],
                ['Total', 'left'],
              ]}
              props={{ handleEdit: handleEditExpense, handleDelete: deleteExpense }}
            />
          </Grid>

          {/* ACTIONS */}
          <Grid item xs={12}>
            <Box sx={{ m: '2%', display: 'flex', justifyContent: 'center' }}>
              <Button sx={{ m: '2%' }} variant='contained' color='error' onClick={handleBack}>
                {t('general.cancel', { ns: 'common' })}
              </Button>
              <Button
                sx={{ m: '2%' }}
                variant='contained'
                onClick={handleSubmit(sendExpenseDocument)}
                disabled={expenses.length < 1 || total <= 0}
              >
                {t('general.save', { ns: 'common' })}
              </Button>
            </Box>
          </Grid>
        </Card>
      </Grid>

      {/* Expenses form */}
      <FormExpenses
        formIsOpen={formIsOpen}
        handleToggleForm={handleToggleForm}
        addExpense={addExpense}
        editExpense={editExpense}
        expense={expense}
      />
    </>
  );
}
