import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

// @Redux
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/reducers';
import { useAppDispatch } from '../../../redux/store';
import { getServicesByType } from '../../../redux/slices/services/actions-async';

// @Interfaces and Types
import { IServicesSupplier, IShipment, ISelectInput } from '../../../models';
import serviceSchema from '../views/ServicesBudget/utils/serviceSchema';

interface IParams {
  open: boolean;
  shipments: IShipment[];
  service?: Partial<IServicesSupplier>;
  handleAddService: (value: Omit<IServicesSupplier, 'id'>) => void;
  handleEditService: (value: IServicesSupplier) => void;
}

// @Initial states
const initialValuesForm: Omit<IServicesSupplier, 'id'> = {
  idServiceSupplier: null,
  cost: 0,
  idShipment: null,
  idService: null,
  idServiceType: null,
  saleType: null,
  serviceName: '',
  serviceNameEN: '',
  serviceTypeName: '',
  serviceTypeNameEN: '',
  idTransportation: null,
  comission: 0,
  salePrice: 0,
  type: 'SE',
};

export function useServiceSuppliersForm({ handleAddService, handleEditService, service, open, shipments }: IParams) {
  const [shipmentItems, setShipmentItems] = useState<ISelectInput<number>[]>([]);

  const {
    services: { serviceTypes, services },
  } = useSelector((state: RootState) => state);

  const { control, handleSubmit, watch, reset, setValue, setError, clearErrors, getValues } = useForm<
    Omit<IServicesSupplier, 'id'>
  >({
    defaultValues: initialValuesForm,
    resolver: yupResolver(serviceSchema),
  });

  const watchCost = watch('cost');
  const watchComission = watch('comission');
  const watchPriceWithComission = watch('salePrice');
  const watchIdTransportation = watch('idTransportation');
  const watchIdServiceType = watch('idServiceType');
  const watchIdService = watch('idService');

  const dispatch = useAppDispatch();

  // NOTE: ALL ACTIONS
  const onSubmit = (data: Omit<IServicesSupplier, 'id'>) => {
    if (validateCost(data.comission, data.cost, true)) {
      if (data.idShipment === null && shipments.length > 0) {
        setError('idShipment', { message: 'errors.required' });
      } else if (service?.id) handleEditService({ ...data, id: service.id });
      else handleAddService(data);
    }
  };

  // SUCCESS: Manual validations for field "cost"
  const validateCost = (
    comission: IServicesSupplier['comission'],
    cost: IServicesSupplier['cost'],
    fromSubmit?: boolean,
  ) => {
    // Validations for MANAGEMENT
    if (service?.type === 'MA') {
      if (cost && comission && cost > getValues('salePrice')) {
        setError('cost', { type: 'required', message: 'errors.test.cost' });
        return false;
      } else {
        clearErrors('cost');
        return true;
      }
    }

    // Validations for SERVICE
    if (service?.type === 'SE') {
      if (fromSubmit && !cost) {
        setError('cost', { type: 'required', message: 'errors.require.cost' });
        return false;
      } else if (cost && comission && cost >= getValues('salePrice')) {
        setError('cost', { type: 'required', message: 'errors.test.cost' });
        return false;
      } else {
        clearErrors('cost');
        return true;
      }
    }
  };

  // NOTE: ALL HANDLE FUNCTIONS
  const onChangeCost = (value: unknown) => {
    const priceCost = value as number;
    const com = getValues('comission');
    const type = getValues('salePrice');

    if (type === 1) {
      const salePrice = priceCost + priceCost * (com / 100);

      setValue('cost', priceCost);
      setValue('salePrice', salePrice);
    } else {
      setValue('cost', priceCost);
      setValue('salePrice', priceCost);
      setValue('comission', 0);
    }
  };

  const onChangeCom = (value: unknown) => {
    const com = value as number;
    const cost = getValues('cost');

    if (cost) {
      const salePrice = cost + cost * (com / 100);

      setValue('comission', com);
      setValue('salePrice', salePrice);
    }
  };

  const onChangeSalePrice = (value: unknown) => {
    const salePrice = value as number;
    const cost = getValues('cost');

    if (cost) {
      const com = 100 * (salePrice / cost - 1);
      setValue('comission', com);
      setValue('salePrice', salePrice);
    } else {
      setValue('salePrice', salePrice);
    }
  };

  const onChangeTransportations = async (transportationData: ISelectInput<number> | number) => {
    if (!transportationData) return;

    const idTransportation = typeof transportationData === 'number' ? transportationData : transportationData.value;

    setValue('idTransportation', idTransportation);
    setValue('idServiceType', null);
    setValue('idService', null);
  };

  const onChangeTypeService = async (typeServiceData: ISelectInput<number> | number) => {
    if (!typeServiceData) return;

    const typeServiceId = typeof typeServiceData === 'number' ? typeServiceData : typeServiceData.value;

    const serviceType = serviceTypes.find(type => type.id === typeServiceId);

    setValue('idServiceType', typeServiceId);
    setValue('serviceTypeName', serviceType?.name || '');
    setValue('serviceTypeNameEN', serviceType?.nameEN || serviceType?.name || '');

    dispatch(getServicesByType({ serviceTypeId: typeServiceId }));
  };

  const onChangeService = async (serviceData: ISelectInput<number> | number) => {
    if (!serviceData) return;

    const idService = typeof serviceData === 'number' ? serviceData : serviceData.value;

    const service = services.find(serv => serv.id === idService);

    setValue('idService', idService);
    setValue('serviceName', service?.name || '');
    setValue('serviceNameEN', service?.nameEN || service?.name || '');
  };

  const onChangeShipment = async (value: unknown) => {
    const idShipment = value as number;
    const shipment = shipments.find(shipm => shipm.id === idShipment);

    setValue('idShipment', idShipment);

    if (shipment) {
      if (shipment.idTransportation) onChangeTransportations(shipment.idTransportation);
      if (shipment.idServiceType) onChangeTypeService(shipment.idServiceType);
    }
  };

  // NOTE: ALL USE EFFECTS
  useEffect(() => {
    if (service?.id) {
      reset(service);
      if (service.idTransportation) onChangeTransportations(service.idTransportation);
      if (service.idServiceType) onChangeTypeService(service.idServiceType);
      if (service.idService) onChangeService(service.idService);
    } else reset(Object.assign(initialValuesForm, service));

    const items: ISelectInput<number>[] = shipments.map(shipment => {
      return { label: shipment.content, value: shipment.id };
    });

    setShipmentItems(items);
  }, [service, open]);

  useEffect(() => {
    validateCost(watchComission, watchCost);
  }, [watchCost, watchPriceWithComission]);

  useEffect(() => {
    if (!watchIdTransportation) setValue('idServiceType', null);
  }, [watchIdTransportation]);

  useEffect(() => {
    if (!watchIdServiceType) setValue('idService', null);
  }, [watchIdServiceType]);

  return {
    // NOTE: ACTIONS
    onSubmit: handleSubmit(onSubmit, () => validateCost(watchComission, watchCost, true)),
    // NOTE: ALL DATA
    control,
    shipmentItems,
    watchIdTransportation,
    watchIdServiceType,
    watchIdService,
    type: getValues('type'),
    // NOTE: ALL HANDLE FUNCTIONS
    onChangeTransportations,
    onChangeService,
    onChangeTypeService,
    onChangeShipment,
    onChangeCom,
    onChangeCost,
    onChangeSalePrice,
  };
}
