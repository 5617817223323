import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// @MUI Components
import { Tabs, Tab, Box, Card, CardHeader, Divider, Grid } from '@mui/material/';

// @Hooks
import { useService } from './hooks/useService';
import { useServiceType } from './hooks/useServiceType';
import { useServiceCategory } from './hooks/useServiceCategory';

// @Utils
import { useNavigation } from '../../utils';

// @Components
import TabPanel from './components/TabPanel';
import { ServicePage } from './components/ServicePage';
import { ServiceType } from './components/ServiceType';
import { ServiceCategory } from './components/ServiceCategory';
import EmptyData from '../../components/EmptyData';
import Loader from '../../components/Loader';
import { Error401 } from '../../components/Error401';

// @Interfaces and Types
type TabType = {
  tabName: string;
  translationText: string;
  permission: 'view_services' | 'see_type_services' | 'see_categories';
  Component: React.ReactElement;
};

export default function Services() {
  const [tabs, setTabs] = useState<TabType[]>([]);
  const [value, setValue] = useState(0);

  const servicesData = useService();
  const typeServicesData = useServiceType(value);
  const categoryData = useServiceCategory();

  const { permissions } = useNavigation();

  const { t } = useTranslation(['common']);

  const handleChange = (_: React.SyntheticEvent, newValue: number) => setValue(newValue);

  // NOTE: ALL USE EFFECTS
  useEffect(() => {
    const viewPermissions = [servicesData.permissions, typeServicesData.permissions, categoryData.permissions].flat();

    const allTabs: TabType[] = [
      {
        tabName: 'categories',
        translationText: 'general.category',
        permission: 'see_categories',
        Component: <ServiceCategory categoryData={categoryData} />,
      },
      {
        tabName: 'typeService',
        translationText: 'services.type',
        permission: 'see_type_services',
        Component: <ServiceType typeServicesData={typeServicesData} />,
      },
      {
        tabName: 'services',
        translationText: 'general.services',
        permission: 'view_services',
        Component: <ServicePage serviceData={servicesData} />,
      },
    ];

    setTabs(allTabs.filter(tab => viewPermissions.includes(tab.permission)));
  }, [
    servicesData.permissions,
    servicesData.services,
    servicesData.filter,
    servicesData.check,
    servicesData.show,
    typeServicesData.permissions,
    typeServicesData.serviceTypes,
    typeServicesData.show,
    categoryData.permissions,
    categoryData.serviceCategories,
    categoryData.show,
  ]);

  if (servicesData.isLoading || typeServicesData.isLoading || categoryData.isLoading) return <Loader />;

  if (!permissions.includes('services')) {
    return <Error401 />;
  }

  // if (servicesData.isError) return <Error description={servicesData.isError?.des || null} />;

  if (!tabs.length) return <EmptyData />;

  return (
    <Grid container sx={{ width: '90%', ml: 'auto', mr: { xs: 0.5, md: 'auto' }, zIndex: 1 }}>
      <Card sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label='basic tabs example'>
            {tabs.map((tab, i) => (
              <Tab
                key={tab.tabName}
                id={`simple-tab-${i}`}
                label={t(tab.translationText)}
                aria-controls={`simple-tabpanel-${i}`}
              />
            ))}
          </Tabs>
        </Box>

        <CardHeader title={t(tabs[value].translationText)} sx={{ my: 2, display: 'flex', textAlign: 'center' }} />

        <Divider sx={{ width: '93.5%', mx: 'auto', my: 2 }} />

        {tabs.map((tab, i) => (
          <TabPanel key={tab.tabName} value={value} index={i}>
            {tab.Component}
          </TabPanel>
        ))}
      </Card>
    </Grid>
  );
}
