// ** Redux Imports
import { createSlice, createAsyncThunk, AsyncThunkPayloadCreator } from '@reduxjs/toolkit';

// ** Axios Imports
import { axiosEstimates } from '../../../utils';
import { socket } from '../../../utils/sockets.util';

// ** Types
// import { Dispatch } from 'redux';
import {
  ChatsArrType,
  ContactType,
  ISupplierDetails,
  ProfileUserType,
  SelectedChatType,
  SendMsgParamsType,
  SupplierChat,
} from '../../../models';

// ** Fetch User Profile
export const fetchUserProfile = createAsyncThunk('appChat/fetchUserProfile', async () => {
  // const response = await axios.get('/apps/chat/users/profile-user');

  const user: ProfileUserType = {
    id: 1,
    about: 'sadasdas',
    avatar: '',
    fullName: 'Cml',
    // role: 'admin',
    // status: 'online',
    /* settings: {
      isNotificationsOn: true,
      isTwoStepAuthVerificationEnabled: true,
    }, */
  };

  return user;
});

// ** Fetch Chats & Contacts
const getChatsContacts: AsyncThunkPayloadCreator<
  { contacts: ContactType[]; chatsContacts: ChatsArrType[] },
  { idBudget: number },
  {}
> = async (payload, { rejectWithValue }) => {
  try {
    const response = await axiosEstimates.get<ISupplierDetails[]>('/supplier-details');

    const responseChat = await axiosEstimates.get<{ suppliers: SupplierChat[] }>('/supplier-chat/' + payload.idBudget);

    const contacts: ContactType[] = response.data.map(supplier => ({
      id: supplier.id,
      fullName: supplier.supplier,
      about: `${supplier.email} ${supplier.phone} ${supplier.country} ${supplier.address}`,
    }));

    const suppliers = responseChat.data.suppliers;
    const chatsContacts: ChatsArrType[] = suppliers.map(supplier => ({
      id: supplier.id,
      fullName: supplier.name,
      about: '',
      chat: {
        id: payload.idBudget,
        userId: supplier.id,
        unseenMsgs: 0,
        chat: supplier.supplierChats.map(chat => ({
          message: chat.content,
          senderId: chat.idUser,
          username: chat.username,
          time: chat.created_at,
          isFile: chat.isFile,
          filename: chat.filename || '',
          url: chat.url || '',
        })),
      },
    }));

    return { contacts, chatsContacts };
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
};

export const fetchChatsContacts = createAsyncThunk('appChat/fetchChatsContacts', getChatsContacts);

// ** Select Chat
export const selectChat = createAsyncThunk(
  'appChat/selectChat',
  async ({ id, idBudget }: { id: number; idBudget: number }) => {
    try {
      const response = await axiosEstimates.get<{ supplier: SupplierChat }>(`/supplier-chat/${idBudget}/${id}`);

      const supplier = response.data.supplier;

      const chats: SelectedChatType = {
        chat: {
          chat: supplier.supplierChats.map(chat => ({
            message: chat.content,
            username: chat.username,
            senderId: chat.idUser,
            time: chat.created_at,
            isFile: chat.isFile,
            filename: chat.filename || '',
            url: chat.url || '',
          })),
          id: idBudget,
          unseenMsgs: 0,
          userId: supplier.id,
        },
        contact: {
          id: supplier.id,
          about: '',
          fullName: supplier.name,
          chat: {
            chat: supplier.supplierChats.map(chat => ({
              message: chat.content,
              username: chat.username,
              senderId: chat.idUser,
              time: chat.created_at,
              isFile: chat.isFile,
              filename: chat.filename || '',
              url: chat.url || '',
            })),
            id: idBudget,
            unseenMsgs: 0,
            userId: supplier.id,
          },
        },
      };

      return chats;
    } catch (error) {
      return null;
    }
  },
);

// ** Send Msg
export const sendMsg = createAsyncThunk('appChat/sendMsg', async (obj: SendMsgParamsType, { dispatch }) => {
  const data = {
    idBudget: obj.idBudget,
    idUser: obj.idUser,
    content: obj.message,
    idSupplier: obj.contact?.id,
  };
  socket.emit('new-message-supplier', data);

  if (obj.contact) {
    await dispatch(selectChat({ id: obj.contact.id, idBudget: obj.idBudget }));
  }
});

interface InitialState {
  chats: ChatsArrType[] | null;
  contacts: ContactType[] | null;
  userProfile: ProfileUserType | null;
  selectedChat: SelectedChatType | null;
}

const initialState: InitialState = {
  chats: null,
  contacts: null,
  userProfile: null,
  selectedChat: null,
};

export const appChatSlice = createSlice({
  name: 'appChat',
  initialState,
  reducers: {
    removeSelectedChat: state => {
      state.selectedChat = null;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchUserProfile.fulfilled, (state, action) => {
      state.userProfile = action.payload;
    });
    builder.addCase(fetchChatsContacts.fulfilled, (state, action) => {
      state.contacts = action.payload.contacts;
      state.chats = action.payload.chatsContacts;
    });
    builder.addCase(selectChat.fulfilled, (state, action) => {
      state.selectedChat = action.payload;
    });
    builder.addCase(selectChat.rejected, state => {
      state.selectedChat = null;
    });
  },
});

export const { removeSelectedChat } = appChatSlice.actions;

export default appChatSlice.reducer;
