// @Utils
// import i18n from '../../../../../i18n';
import { axiosEstimates } from '../../../utils';

// @Interfaces and Types
import { IServiceTypesRequest } from '../../../models';

const getServicesTypesItems = async (params: { idTransportation: string | number }) => {
  try {
    const response = await axiosEstimates.post<IServiceTypesRequest[]>(
      'service-types-by-category',
      { categories: [params.idTransportation] },
      { headers: { 'x-module': 'services' } },
    );

    return response.data;
  } catch (err) {
    console.error(err);
    return null;
  }
};

export default getServicesTypesItems;
