import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// @MUI Components
import { Box, Grid, Card, IconButton, Typography, CardContent } from '@mui/material';

// @MUI Icons
import KeyboardBackspace from '@mui/icons-material/KeyboardBackspace';

// @Hooks
import useHistory from './useHistory';

// @Utils
import SuppliersHistoryTable from './components/SuppliersHistoryTable';

// @Components
import TabInformation from '../Details/components/TabInformation';

export const SupplierHistory = () => {
  const { suppliersData } = useHistory();

  const { t } = useTranslation('common');

  const navigate = useNavigate();

  return (
    <Grid item xs={12} sx={{ width: '100%' }}>
      <Card sx={{ mb: 2 }}>
        <IconButton sx={{ display: 'flex', mt: 1 }} color='error' onClick={() => navigate('/suppliers')}>
          <KeyboardBackspace />
        </IconButton>

        <Box sx={{ m: 2 }}>
          <TabInformation />
        </Box>
      </Card>

      <Card>
        <Box sx={{ p: 5, display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', gap: 4, justifyContent: 'flex-end' }}>
            <Typography variant='h5' sx={{ textAlign: 'center' }}>
              {' '}
              {t('general.logbook')}
            </Typography>
          </Box>
        </Box>

        <CardContent>
          <SuppliersHistoryTable suppliersData={suppliersData} />
        </CardContent>
      </Card>
    </Grid>
  );
};
