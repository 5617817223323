import { useTranslation } from 'react-i18next';

// @MUI Components
import { TableCell, TableRow } from '@mui/material';

// @Interfaces and Types
interface IProps {
  headers: any[];
}

/**
 * @component
 * @subcategory Global
 *
 * @description
 * A reusable column header component for displaying table headers.
 * The component accepts an array of headers and uses the react-i18next translation hook to translate the header texts.
 *
 * #### Example
 * ```tsx
 * return(
 * <ColumHeader headers={['header1', 'header2']} />
 * )
 * ```
 *
 * @property {any[]} headers An array of header texts or arrays containing the header text and alignment.
 *
 * @return {JSX.Element} The ColumHeader component.
 * @author CML Exports Front-End Developers
 */
const ColumHeader = ({ headers }: IProps) => {
  const { t } = useTranslation('common');

  return (
    <TableRow sx={{ backgroundColor: 'primary.main' }}>
      {headers.map(h => (
        <TableCell
          key={h}
          sx={{ color: '#fff', fontWeight: 'bold', backgroundColor: 'primary.main', borderBlockColor: 'primary.main' }}
          align={Array.isArray(h) ? h[1] : 'center'}
        >
          {Array.isArray(h) ? t(h[0]) : t(h)}
        </TableCell>
      ))}
    </TableRow>
  );
};

export default ColumHeader;
