import { useNavigate, useParams } from 'react-router-dom';

// @MUI Components
import { TableRow, TableCell, Typography, IconButton } from '@mui/material/';

// @Icons
import { Icon } from '@iconify/react';

// @Utils
import { formatNumber } from '../../../../../../utils';

// @Models
import { IQuotesOfSuppliers } from '../../../../../../models';
import { useTranslation } from 'react-i18next';

interface IProps {
  row: IQuotesOfSuppliers;
  suppliersHasDocuments: (id: number) => boolean;
  permissions: any;
}

const Row = ({ row, suppliersHasDocuments, permissions }: IProps) => {
  const { t } = useTranslation(['common']);

  const navigate = useNavigate();

  const { id } = useParams();

  return (
    <TableRow>
      <TableCell>
        <Typography noWrap sx={{ textTransform: 'capitalize', textAlign: 'center' }}>
          {row.quotationCode}
        </Typography>
      </TableCell>

      <TableCell>
        <Typography noWrap sx={{ textTransform: 'capitalize', textAlign: 'center' }}>
          {row.services.length}
        </Typography>
      </TableCell>

      <TableCell>
        <Typography noWrap sx={{ textAlign: 'center', textTransform: 'capitalize' }}>
          {formatNumber(row.services.reduce((prev, current) => prev + current.cost, 0))}
        </Typography>
      </TableCell>

      <TableCell align='center'>
        {permissions.consult_document && suppliersHasDocuments(row.id) && (
          <IconButton
            title={`${t('budgetGuide.viewDocumentButtonTittle')}`}
            size='small'
            onClick={() => navigate(`/filesView/${row.id}?model=suppliers&&ctg=${id}`)}
          >
            <Icon icon='mi:document' width='22' />
          </IconButton>
        )}
        {/* {permissions.quote_history && (
          <IconButton title={`${t('general.historyChanges')}`}>
            <Icon icon='mdi:clipboard-text-history-outline' height={22} color='#00f0ff' />
          </IconButton>
        )} */}
      </TableCell>
    </TableRow>
  );
};

export default Row;
