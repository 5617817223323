import { useTranslation } from 'react-i18next';

// @MUI Components
import { Card, CardHeader, Typography, CardContent, Box, Divider, IconButton } from '@mui/material';

// @MUI Icons
import EditIcon from '@mui/icons-material/EditOutlined';
import FileIcon from '@mui/icons-material/TextSnippet';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';

// @Utils
import { formatNumber } from '../../../utils';

// @Components
import DataBox from '../../../components/DataBox';

// @Interfaces and Types
import { PaymentType, PaymentTypeType } from '../types';

interface IProps {
  data: PaymentType;
  handleEditPayment: (id: number | undefined) => void;
  handlePreviewFiles: (paymentId: number) => void;
  handleDeletePayment: (id: number | undefined, type: PaymentTypeType) => Promise<void>;
}

export default function CardPayment({ data, handleEditPayment, handlePreviewFiles, handleDeletePayment }: IProps) {
  const {
    id,
    type,
    reference,
    bank,
    depositAccount,
    receivedAmount,
    paymentMethod,
    paymentDate,
    // note
    user,
    haveFile,
  } = data;

  if (!id) return null;

  const { t } = useTranslation('common');

  const date = paymentDate ? new Date(paymentDate) : null;

  return (
    <Card
      sx={{
        mb: 5,
        mx: 'auto',
        width: { xs: '80%', sm: '60%', md: '88%', lg: '80%', xl: '65%' },
        boxShadow: '0 0 6px 2px #00000025',
      }}
    >
      <CardHeader
        avatar={<PaidOutlinedIcon color={type === 'income' ? 'success' : 'error'} sx={{ fontSize: '2.5rem' }} />}
        sx={{
          p: 1,
          m: '0 !important',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderBottom: 'thin solid #00000025',
        }}
        title={
          <Typography
            variant='h4'
            sx={{
              color: ({ palette }) => (type === 'income' ? palette.success.main : palette.error.main),
              fontSize: '1.5rem',
            }}
          >
            {`$${formatNumber(receivedAmount || 0)}`}
          </Typography>
        }
      />
      <CardContent sx={{ pb: '16px !important', m: 0, display: 'flex', flexDirection: 'column', gap: 0.25 }}>
        <DataBox
          title={t('general.date')}
          sx={{ justifyContent: 'space-between' }}
          text={date ? `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}` : ''}
        />
        <DataBox title={t('general.bank')} text={bank || ''} sx={{ justifyContent: 'space-between' }} capitalize />
        <DataBox
          title={t('general.depositAccount')}
          text={depositAccount || ''}
          sx={{ justifyContent: 'space-between' }}
        />
        <DataBox
          title={t('general.paymentMethod')}
          text={paymentMethod}
          capitalize
          sx={{ justifyContent: 'space-between' }}
        />
        <DataBox
          title={t('general.reference')}
          text={reference || t('general.none')}
          sx={{ justifyContent: 'space-between' }}
        />
        <DataBox
          text={user?.name || 'System'}
          title={t('general.generatedBy')}
          sx={{ justifyContent: 'space-between' }}
        />

        <Divider sx={{ width: '90%', my: 2, mx: 'auto' }} />

        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }}>
          <IconButton
            color='primary'
            onClick={() => handleEditPayment(id)}
            sx={{ px: 4, width: 'max-content', borderRadius: '0.25rem', fontWeight: 600 }}
          >
            <EditIcon />
          </IconButton>

          {haveFile && (
            <IconButton
              color='secondary'
              onClick={() => handlePreviewFiles(id)}
              sx={{ px: 4, width: 'max-content', borderRadius: '0.25rem', fontWeight: 600 }}
            >
              <FileIcon />
            </IconButton>
          )}

          <IconButton
            color='error'
            onClick={() => handleDeletePayment(id, type)}
            sx={{ px: 4, width: 'max-content', borderRadius: '0.25rem', fontWeight: 600 }}
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      </CardContent>
    </Card>
  );
}
