import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// @MUI Components
import { TableRow, TableCell, Typography, IconButton, Box, CardHeader, Divider, Switch } from '@mui/material';

// @Icons
import { Icon } from '@iconify/react';
import DeleteOutline from 'mdi-material-ui/DeleteOutline';
import PencilOutline from 'mdi-material-ui/PencilOutline';

// @Components
import Pagination from '../../../components/Pagination';
import TableComponent from '../../../components/TableContainer';

// @Models
import { IGetServices } from '../../../models';
import Loader from '../../../components/Loader';

interface IRowProps {
  handleEdit: (render: boolean, data: IGetServices) => void;
  handleDelete: (id: number) => void;
  onStatus: (id: number, supplierStatus: 'active' | 'inactive') => void;
  row: any;
  i18n: any;
  canEdit: boolean;
  canDelete: boolean;
  canSeeHistory: boolean;
  canChangeStatus: boolean;
}

const Row = ({
  row,
  handleEdit,
  handleDelete,
  onStatus,
  i18n,
  canEdit,
  canDelete,
  canSeeHistory,
  canChangeStatus,
}: IRowProps) => {
  const { t } = useTranslation(['common']);

  const handleChange = () => {
    if (row.status.toLowerCase() === 'active') onStatus(row.id, 'inactive');
    else onStatus(row.id, 'active');
  };

  return (
    <TableRow>
      <TableCell>
        <Typography
          noWrap
          sx={{ textTransform: 'capitalize', display: 'flex', alignItems: 'center', flexDirection: 'column' }}
        >
          {i18n.language === 'es' ? row.name : row.nameEN !== null ? row.nameEN : row.name}
          <Typography component='span' sx={{ fontSize: '0.8rem', color: 'text.secondary' }}>
            {row.username}
          </Typography>
        </Typography>
      </TableCell>

      <TableCell>
        <Typography noWrap sx={{ textTransform: 'capitalize', display: 'flex', alignItems: 'center' }}>
          {i18n.language === 'es' ? row.serviceTypeName : row.serviceTypeNameEng}
        </Typography>
      </TableCell>

      <TableCell align='center'>
        {canEdit && row.canEdit && (
          <IconButton color='info' onClick={() => handleEdit(true, row)} title={`${t('general.edit')}`}>
            <PencilOutline fontSize='medium' />
          </IconButton>
        )}

        {canDelete && (
          <IconButton color='error' onClick={() => handleDelete(row.id)} title={`${t('general.delete')}`}>
            <DeleteOutline fontSize='medium' />
          </IconButton>
        )}

        {canSeeHistory && (
          <Link to={`/services/history/${row.id}`}>
            <IconButton title={`${t('general.history')}`}>
              <Icon icon='mdi:clipboard-text-history-outline' height={22} color='#00f0ff' />
            </IconButton>
          </Link>
        )}

        {canChangeStatus && <Switch onChange={handleChange} checked={row.status.toLowerCase() === 'active'} />}
      </TableCell>
    </TableRow>
  );
};

interface IProps {
  tableIsLoading: boolean;
  services: IGetServices[];
  permissions: string[];
  handleEdit: (render: boolean, data: IGetServices) => void;
  handleDelete: (id: number) => void;
  onStatus: (id: number, supplierStatus: 'active' | 'inactive') => void;
}

export const ServicesTable = ({
  tableIsLoading,
  services,
  permissions,
  handleEdit,
  handleDelete,
  onStatus,
}: IProps) => {
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(5);

  const { i18n } = useTranslation();

  const { t } = useTranslation('common');

  if (tableIsLoading) return <Loader />;

  return (
    <Box sx={{ display: 'block', height: 500, width: '100%', mb: 20 }}>
      <CardHeader sx={{ display: 'flex', textAlign: 'center' }} titleTypographyProps={{ variant: 'h6' }} />

      <Divider sx={{ margin: 0 }} />

      <TableComponent
        Row={Row}
        data={services.slice(page * pageSize, (page + 1) * pageSize)}
        headers={[t('general.name'), t('services.type'), t('general.actions')]}
        props={{
          handleEdit,
          handleDelete,
          onStatus,
          i18n,
          canEdit: permissions.includes('edit_service'),
          canDelete: permissions.includes('delete_service'),
          canSeeHistory: permissions.includes('service_history'),
          canChangeStatus: permissions.includes('service_status'),
        }}
      />

      <Pagination count={services.length} setPage={setPage} setPageSize={setPageSize} page={page} pageSize={pageSize} />
    </Box>
  );
};
