// @Library
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

// @Components
import Dialog from '../../../../components/Dialog';

// @Hooks
import { useEditService } from './useEdit.hook';

// @Mui
import { CardContent, Box, Grid, Button } from '@mui/material';

import { MultipleTransportationFilter, MultipleServicesTypeFilter } from '../../../../components';

interface IProps {
  open: boolean;
  toggle: () => void;
  id: number;
}

export function EditServices({ open, toggle, id }: IProps): JSX.Element {
  const { t, control, categories, serviceTypes, onSubmit } = useEditService({ id, toggle });

  return (
    <Dialog open={open} handleClose={() => toggle()} dialogTitle={t('title.editService', { ns: 'common' })}>
      <Box sx={{ width: { xs: '18rem', sm: '34rem', md: '48rem' } }}>
        <CardContent sx={{ width: '100%', height: '100%' }}>
          <form onSubmit={onSubmit}>
            <Grid container spacing={3} sx={{ display: 'flex' }}>
              <Grid item xs={12}>
                <MultipleTransportationFilter
                  control={control}
                  name='categories'
                  label={t('general.category', { ns: 'common' })}
                  watcherValue={categories?.map((category: { value: any }) => category.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <MultipleServicesTypeFilter
                  control={control}
                  name='serviceTypes'
                  watcherValue={[]}
                  label={t('services.type', { ns: 'common' })}
                  categoriesId={categories.map(category => category.value)}
                />
              </Grid>
            </Grid>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button sx={{ mr: 2, mt: 4 }} variant='contained' color='error' onClick={() => toggle()}>
                {t('general.cancel', { ns: 'common' })}
              </Button>
              <Button sx={{ ml: 2, mt: 4 }} variant='contained' type='submit'>
                {t('general.save', { ns: 'common' })}
              </Button>
            </Box>
          </form>
        </CardContent>
      </Box>
    </Dialog>
  );
}
