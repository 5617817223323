import { useTranslation } from 'react-i18next';

// @MUI Components
import { CardContent, Button, Grid, Box, Switch, FormControlLabel, Divider } from '@mui/material';

// @MUI Icons
import AddIcon from '@mui/icons-material/Add';

// @Components
import { SearchField } from '../../../components';
import { ServicesTable, ServiceForm } from '../../../views/services';
import EmptyData from '../../../components/EmptyData';

// @Interfaces and Types
import { IGetServices } from '../../../models';
interface IProps {
  serviceData: {
    permissions: string[];
    tableIsLoading: boolean;
    show: boolean;
    check: boolean;
    filter: string;
    services: IGetServices[];
    service: IGetServices | undefined;
    addServiceTitle: { name: string; ESname: string };
    onStatus: (id: number, supplierStatus: 'active' | 'inactive') => void;
    deleteService: (id: number) => Promise<void>;
    handleShow: (render: boolean, service?: IGetServices) => void;
    handleAddService: (service: IGetServices) => void;
    handleEditService: (editService: IGetServices) => void;
    handleFilter: (value: string) => void;
    handleToggleCheck: () => void;
  };
}

export const ServicePage = ({ serviceData }: IProps) => {
  const {
    // NOTE: PERMISSIONS
    permissions,
    // NOTE: STATUS
    tableIsLoading,
    show,
    check,
    // NOTE: FILTER
    filter,
    // NOTE: DATA
    addServiceTitle,
    services,
    service,
    // NOTE: ACTIONS
    onStatus,
    deleteService,
    // NOTE: HANDLE FUNCTIONS
    handleShow,
    handleAddService,
    handleEditService,
    handleFilter,
    handleToggleCheck,
  } = serviceData;

  const { t, i18n } = useTranslation(['common']);

  return (
    <CardContent>
      {show ? null : (
        <Grid
          container
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            flexDirection: { xs: 'column', md: 'row' },
            rowGap: 4,
          }}
        >
          <Grid
            item
            xs={12}
            sm
            md={8}
            lg={6}
            sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: 3, ml: -1 }}
          >
            <Box sx={{ width: { sx: '100%', lg: '75%', xl: '65%' } }}>
              <SearchField value={filter} handleFilter={handleFilter} placeholder={t('general.search')} />
            </Box>

            {permissions.includes('service_status') && (
              <FormControlLabel
                control={<Switch checked={check} onChange={handleToggleCheck} />}
                label={t('general.status')}
              />
            )}
          </Grid>

          {permissions.includes('add_service') && (
            <Grid
              item
              xs={12}
              sm
              sx={{ width: '100%', display: 'flex', justifyContent: { xs: 'center', md: 'flex-end' }, pr: 2 }}
            >
              <Button size='large' variant='contained' onClick={() => handleShow(true)}>
                <AddIcon />
                {i18n.language === 'es' ? addServiceTitle.ESname : addServiceTitle.name}
              </Button>
            </Grid>
          )}
        </Grid>
      )}

      {(permissions.includes('add_service') || permissions.includes('edit_service')) && show && (
        <ServiceForm
          handleRender={handleShow}
          addState={handleAddService}
          service={service}
          editState={handleEditService}
        />
      )}

      {services.length ? (
        <ServicesTable
          tableIsLoading={tableIsLoading}
          services={services}
          permissions={permissions}
          handleDelete={deleteService}
          handleEdit={handleShow}
          onStatus={onStatus}
        />
      ) : (
        <>
          <Divider sx={{ mt: 5 }} />
          <EmptyData sx={{ mt: 5 }} />
        </>
      )}
    </CardContent>
  );
};
