// @MUI Components
import { TableRow, TableCell, Typography, IconButton } from '@mui/material';

// @MUI Icons
import DeleteOutline from 'mdi-material-ui/DeleteOutline';
import PencilOutline from 'mdi-material-ui/PencilOutline';

// @Interfaces
interface IRowProps {
  row: any;
  editTitle: string;
  deleteTitle: string;
  canEdit: boolean;
  canDelete: boolean;
  handleDelete: (id: number) => void;
  handleEdit: (id: number) => void;
}

const Row = ({ row, deleteTitle, editTitle, canDelete, canEdit, handleDelete, handleEdit }: IRowProps) => {
  return (
    <TableRow>
      <TableCell align='center'>
        <Typography noWrap sx={{ textTransform: 'capitalize' }}>
          {row?.name}
        </Typography>
      </TableCell>

      <TableCell align='center'>
        <Typography noWrap sx={{ textTransform: 'capitalize' }}>
          {row?.user}
        </Typography>
      </TableCell>

      <TableCell align='center'>
        <Typography noWrap sx={{ textTransform: 'capitalize' }}>
          {canEdit && (
            <IconButton color='info' onClick={() => handleEdit(row.id)} title={editTitle}>
              <PencilOutline fontSize='medium' />
            </IconButton>
          )}

          {canDelete && (
            <IconButton color='error' onClick={() => handleDelete(row.id)} title={deleteTitle}>
              <DeleteOutline fontSize='medium' />
            </IconButton>
          )}
        </Typography>
      </TableCell>
    </TableRow>
  );
};

export default Row;
