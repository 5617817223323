// @Hooks
import { useServiceForm } from './use-service-form.hook';

// @MUI Components
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import CardActions from '@mui/material/CardActions';

// @Components
import AddServices from './components/add-services.component';
import Table from './components/table-suppliers.component';
import { TableServices } from './components/table-services.component';
import { Shipment } from './components/shipment.component';
import ButtonForm from '../../../components/ButtonForm';

import i18n from '../../../i18n';

// @Interfaces and Types
import { IShipmentForm, IShipment } from '../../../models';
interface IProps {
  addService: (service: IShipmentForm) => void;
  editService: (service: IShipmentForm) => void;
  service?: IShipmentForm;
  previousService?: IShipment;
  handleRenderForm: () => void;
  servicesCount: number;
  customerCommission?: number;
  edit?: boolean;
  status?: string;
  budgetType: number;
}

export function ServiceForm({
  addService,
  editService,
  service,
  handleRenderForm,
  previousService,
  servicesCount,
  customerCommission,
  edit,
  status,
  budgetType,
}: IProps) {
  const {
    t,
    control,
    serviceTypesItemsEN,
    serviceTypesItemsES,
    onSubmit,
    transportationItemsES,
    transportationItemsEN,
    countryItems,
    suppliers,
    handleSelectSupplier,
    rowId,
    services,
    handleAddServices,
    handleDeleteService,
    countries,
    destiny,
    origin,
    selectService,
    setValue,
    selectRows,
    selectedRows,
    handleAddSelectServices,
    role,
    handleSelectService,
    handleEditServices,
    isEdit,
    open,
    setOpen,
    typeUnit,
    resetFilters,
    calculateCrating,
    conutryItemsES,
  } = useServiceForm({
    addService,
    editService,
    service,
    previousService,
    customerCommission,
    budgetType,
  });

  return (
    <Grid container item xs={12} sx={{ mx: 'auto' }}>
      <Shipment
        control={control}
        countryItems={i18n.language === 'en' ? countryItems : conutryItemsES}
        setValue={setValue}
        service={service}
        servicesCount={servicesCount}
        edit={edit}
        count={services.length}
        budgetType={budgetType}
      />

      {status !== 'request' && (
        <Table
          suppliers={suppliers}
          handleSelect={handleSelectSupplier}
          control={control}
          transportationItems={i18n.language === 'en' ? transportationItemsEN : transportationItemsES}
          typeService={i18n.language === 'en' ? serviceTypesItemsEN : serviceTypesItemsES}
          rowId={rowId}
          services={services}
          selectRows={selectRows}
          selectedRows={selectedRows}
          resetFilters={resetFilters}
        />
      )}

      {selectedRows.length > 0 && (
        <Grid container spacing={5} sx={{ my: 1 }}>
          <Grid item xs={12}>
            <CardActions sx={{ justifyContent: 'end' }}>
              <ButtonForm label={t('add.services', { ns: 'common' })} onClick={handleAddSelectServices} />
            </CardActions>
          </Grid>
        </Grid>
      )}

      {selectedRows.length === 0 && (
        <AddServices
          countryItems={i18n.language === 'en' ? countryItems : conutryItemsES}
          countries={countries}
          destiny={destiny}
          origin={origin}
          service={selectService}
          handleAddService={handleAddServices}
          handleEditService={handleEditServices}
          rowId={rowId}
          role={role}
          isEdit={isEdit}
          open={open}
          toggle={() => setOpen(!open)}
          typeUnit={typeUnit}
          calculateCrating={calculateCrating}
          budgetType={budgetType}
        />
      )}

      <Divider sx={{ margin: 0 }} />

      {status !== 'request' && (
        <TableServices
          services={services}
          handleDeleteService={handleDeleteService}
          handleSelect={handleSelectService}
        />
      )}

      <Divider sx={{ margin: 0 }} />

      <CardActions sx={{ width: '100%', justifyContent: 'space-around' }}>
        <ButtonForm color='error' onClick={handleRenderForm} label={t('general.back')} />

        <ButtonForm
          type='submit'
          onClick={onSubmit}
          label={t('general.continue', { ns: 'common' })}
          disabled={status === 'request' ? false : services.filter(service => service.amountOfService > 0).length === 0}
          sx={{ mr: 2 }}
        />
      </CardActions>
    </Grid>
  );
}
