import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// @MUI Components
import {
  Box,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  TableBody,
  TableFooter,
  TablePagination,
} from '@mui/material';

// @Redux
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/reducers';

// @Components
import Row from './Row';
import Loader from '../../../components/Loader';
import ColumHeader from '../../../components/TableColumnHeader';

// @Interfaces and Types
import { IGetBudgets } from '../../../models';
interface IProps {
  isSV: boolean;
  isUSA: boolean;
  isCTIS: boolean;
  permissions: string[];
  isLoading: boolean;
  data: IGetBudgets[];
  page: number;
  setPage: (page: number) => void;
  count: number;
  rowsPerPage: number;
  setRowsPerPage: (rowsPerPage: number) => void;
  handleView?: (id: number) => void;
  cancelStatus?: (id: number, status: string) => void;
  toggleComments: (idBudget?: number) => void;
}

export default function CollapsibleBudget({
  isSV,
  isUSA,
  isCTIS,
  permissions,
  isLoading,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  data,
  count,
  handleView,
  cancelStatus,
  toggleComments,
}: IProps) {
  const { t } = useTranslation('common');

  const [selectedRow, setSelectedRow] = useState(null);
  const [header, setHeader] = useState([
    '',
    ['budgetPage.customer', 'left'],
    'title.quotation',
    'budgetPage.status',
    ['budgetPage.actions', 'right'],
  ]);

  const { user } = useSelector((state: RootState) => state.user);

  useEffect(() => {
    if (permissions.length) {
      const newHeader = [
        '',
        ['budgetPage.customer', 'left'],
        'title.quotation',
        'budgetPage.status',
        ['budgetPage.actions', 'right'],
      ];
      if (permissions.includes('see_value')) {
        newHeader.splice(3, 0, 'budgetPage.value');
      }
      if (permissions.includes('see_utility')) {
        newHeader.splice(permissions.includes('see_value') ? 4 : 3, 0, 'general.utility');
      }
      setHeader(newHeader);
    }
  }, [permissions]);

  const isReseller = user?.is_reseller;

  const handleChangePage = (_: unknown, newPage: number) => setPage(newPage + 1);

  const handleChangeRowsPerPage = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPage(1);
    setRowsPerPage(parseInt(e.target.value));
  };

  if (isLoading) return <Loader sx={{ mt: '15vh', height: 400 }} />;

  if ((!data || data.length === 0) && !isLoading) {
    return (
      <Box>
        <Typography sx={{ textAlign: 'center' }} gutterBottom component='div'>
          {t('general.noRows', { ns: 'validations' })}
        </Typography>
      </Box>
    );
  }

  return (
    <>
      {/* BUDGETS */}
      <TableContainer component={Paper} sx={{ height: 400 }}>
        <Table stickyHeader aria-label='sticky table'>
          <TableHead sx={{ backgroundColor: 'primary.main' }}>
            <ColumHeader headers={header} />
          </TableHead>

          <TableBody sx={{ overflowY: 'hidden' }}>
            {data.length > 0 ? (
              data.map((row, i) => (
                <Row
                  key={row.id}
                  isSV={isSV}
                  isUSA={isUSA}
                  isCTIS={isCTIS}
                  index={i}
                  isReseller={Boolean(isReseller)}
                  idBudget={row.id}
                  permissions={permissions}
                  canEdit={row.canEdit}
                  row={row}
                  selectedRow={selectedRow}
                  setSelectedRow={setSelectedRow}
                  handleView={handleView}
                  cancelStatus={cancelStatus}
                  toggleComments={toggleComments}
                  header={header}
                />
              ))
            ) : (
              <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                  <Box
                    sx={{
                      display: 'flex',
                      textAlign: ' center',
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundColor: '#F7F7F7',
                      height: 300,
                    }}
                  >
                    <Typography sx={{ textAlign: 'center' }} gutterBottom component='div'>
                      {t('general.noRows', { ns: 'validations' })}
                    </Typography>
                  </Box>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* TABLE FOOTER PAGINATION */}
      <Table sx={{ mt: 5 }}>
        <TableFooter>
          <TableRow>
            <TablePagination
              sx={{ width: '100vw' }}
              count={count}
              page={page - 1}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[5, 10, 25, 50]}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage={t('filters.rowsPerPage')}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </>
  );
}
