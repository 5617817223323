import { useTranslation } from 'react-i18next';

export function useItems() {
  const { t } = useTranslation(['common']);

  const incotermsItem = [
    { value: 'EXW', label: t('budgetPage.incoterms.EXW', { ns: 'common' }) },
    { value: 'FAS', label: t('budgetPage.incoterms.FAS', { ns: 'common' }) },
    { value: 'FOB', label: t('budgetPage.incoterms.FOB', { ns: 'common' }) },
    { value: 'CFR', label: t('budgetPage.incoterms.CFR', { ns: 'common' }) },
    { value: 'CIF', label: t('budgetPage.incoterms.CIF', { ns: 'common' }) },
    { value: 'CIP', label: t('budgetPage.incoterms.CIP', { ns: 'common' }) },
    { value: 'DPU', label: t('budgetPage.incoterms.DPU', { ns: 'common' }) },
    { value: 'DAP', label: t('budgetPage.incoterms.DAP', { ns: 'common' }) },
    { value: 'DDP', label: t('budgetPage.incoterms.DDP', { ns: 'common' }) },
  ];

  const taxationItem = [
    { value: 1, label: t('general.origin', { ns: 'common' }) },
    { value: 2, label: t('general.destiny', { ns: 'common' }) },
  ];

  const typeUnit = [
    { value: 'cmkg', label: 'cmkg', scale: 5000, scaleArea: 1000000, size: 'cm', weight: 'kg', area: 'm3' },
    { value: 'inlb', label: 'inlb', scale: 139, scaleArea: 1728, size: 'in', weight: 'lb', area: 'ft3' },
  ];

  return { incotermsItem, typeUnit, taxationItem };
}
