// @Libraries
import React, { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import { useFieldArray, useForm } from 'react-hook-form';
import Swal from 'sweetalert2';

// @Redux
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/reducers';

// @Models
import { AirGuide } from '../../../../models';

// @Hooks
import { useDocument } from '../../hooks';

// @Utils
import { axiosEstimates } from '../../../../utils';
import { initialValues, schemaValidation } from './utils/validationSchema';
import { DOCUMENT_TYPE_OBJ } from '../../utils';

const maxLength = 255;

const useAirGuide = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const [counter, setCounter] = React.useState('');
  const [documentId, setDocumentId] = useState<number | null>(null);
  const { id } = useParams<{ id: string }>();
  const { budget, documents, handleNavigatePrint, handleFormChange, handleFormChange2 } = useDocument();
  const [currentData, setCurrentData] = useState<object | null>(null);

  const { control, handleSubmit, reset, watch, getValues } = useForm<AirGuide>({
    defaultValues: initialValues,
    resolver: yupResolver(schemaValidation),
    reValidateMode: 'onChange',
  });

  useEffect(() => {
    getValues();
    const current = JSON.stringify(currentData);
    const updated = JSON.stringify(getValues());
    if (current !== updated) {
      handleFormChange();
    } else {
      handleFormChange2();
    }
  }, [watch()]);

  const {
    countries: { countryItems },
  } = useSelector((state: RootState) => state);

  const {
    fields: contentFields,
    append: contentAppend,
    remove: contentRemove,
  } = useFieldArray({ control, name: 'content' });

  if (contentFields.length === 0) {
    contentAppend({
      quantity: null,
      grossWeight: null,
      kglb: '',
      itemNumber: null,
      rateClass: null,
      chargeableWeight: null,
      rate: null,
      total: null,
      description: '',
    });
  }

  const getBudgetId = async () => {
    const data = budget;
    if (data) {
      const document = documents.find(document => document.document_types.name === DOCUMENT_TYPE_OBJ.AIR_GUIDE);

      if (!document) {
        const dataAir = {
          expeditionDate: new Date().toLocaleDateString(),
          // invoiceoperation: data.budget.code,
          shipper: 0, // Supplier select
          nameshipper: 'CML EXPORTS AND MOVING SOLUTIONS INC',
          addressshipper: '112 Bartram Oaks Walk #600877 Jacksonville, FL 32259 US',
          mailshipper: 'operations@cmlexports.com',
          phoneshipper: '(904) 295 4450',
          consignee: 0, // Customer select
          nameconsignee: data.customer.name,
          addressconsignee: data.customer.address,
          mailconsignee: data.customer.email,
          phoneconsignee: data.customer.phone,
        };
        reset(dataAir);
        setDocumentId(null);
      } else {
        const dataAir = {
          invoiceoperation: document.quotation || '',
          referenceCode: document.reference || '',
          expeditionDate: document.expeditionDate || '',
          iCarriername: document.conveyors[0]?.name || '',
          iCarriercity: document.conveyors[0]?.city || '',
          accountingInformation: document.conveyors[0]?.accountingInformation || '',
          nameshipper: document.shipperPerson?.name || '',
          addressshipper: document.shipperPerson?.address || '',
          mailshipper: document.shipperPerson?.email || '',
          phoneshipper: document.shipperPerson?.phone || '',
          nameconsignee: document.consignedToPerson?.name || '',
          addressconsignee: document.consignedToPerson?.address || '',
          mailconsignee: document.consignedToPerson?.email || '',
          phoneconsignee: document.consignedToPerson?.phone || '',
          agentIATACode: document.airlines[0]?.iataCode || '',
          agentAccountNo: document.airlines[0]?.accountNumber || '',
          airpotOfDeparture: document.airlines[0]?.departureAirport || '',
          referenceNumber: document.airlines[0]?.referenceCode || '',
          optionalShippingInformation: document.airlines[0]?.shipmentInformation || '',
          firstTo: document.airlines[0]?.firstFor || '',
          byFirstCarrier: document.airlines[0]?.byFirstAirline || '',
          secondTo: document.airlines[0]?.secondFor || '',
          secondCollect: Number(document.airlines[0]?.secondCharge || ''),
          routingAndDestinitation: document.airlines[0]?.routeAndDestination || '',
          collect: Number(document.collect || null),
          secondBy: document.airlines[0]?.bySecondAirline || '',
          thirdTo: document.airlines[0]?.byThirdAirline || '',
          thirdBy: document.airlines[0]?.thirdFor || '',
          currency: document.currency || '',
          chgs: document.chgs || '',
          prepaid: Number(document.airlines[0]?.paidInAdvance || 0),
          weightCharge: Number(document.airlines[0]?.charge || 0),
          secondPrepaid: Number(document.airlines[0]?.secondPaymentFor || 0),
          valuationCharge: Number(document.airlines[0]?.valuationCharge || 0),
          security: Number(document.airlines[0]?.security || 0),
          tax: Number(document.airlines[0]?.iva || 0),
          fuelServices: Number(document.airlines[0]?.fuelServices || 0),
          totalOtherChargesDueAgent: Number(document.airlines[0]?.totalOtherAgentFee || 0),
          totalOtherChargesDueCarrier: Number(document.airlines[0]?.totalOtherChargesDueCarrier || 0),
          totalPrepaid: Number(document.airlines[0]?.fullPaidInAdvance || 0),
          totalCollect: Number(document.airlines[0]?.totalCharge || 0),
          carriesrsuse: document.airlines[0]?.exclusiveUseOfAirline || '',
          chargesatdestinations: document.airlines[0]?.destinationCharges || '',
          airpotOfDestination: document.airlines[0]?.airpotOfDestination || '',
          requestedFlight: document.airlines[0]?.requestedflight || '',
          handlingInformation: document.airlines[0]?.handlingInformation || '',
          date: document.airlines[0]?.date || '',
          declaredValueForCarriage: document.airlines[0]?.declaredValueForCarriage || '',
          declaredValueForCustoms: document.airlines[0]?.declaredValueForCustoms || '',
          totalCollectCharges: Number(document.airlines[0]?.totalChargesReceivable || 0),
          amountOfInsurance: Number(document.airlines[0]?.amountOfInsurance || 0),
          content: document.documentContent.map(content => ({
            quantity: content.amount || 0,
            grossWeight: Number(content.grossWeight) || 0,
            kglb: content.kglb || '',
            itemNumber: Number(content.number) || 0,
            rateClass: Number(content.rate_class) || 0,
            chargeableWeight: Number(content.loadableWeight) || 0,
            rate: Number(content.fee) || 0,
            total: Number(content.total) || 0,
            description: content.description || '',
          })),
          issuedBy: document.consignee || '',
        };
        setCounter(document.conveyors[0]?.accountingInformation || '');
        reset(dataAir);
        setDocumentId(document.id);
      }
    }
  };
  useEffect(() => {
    setCurrentData(getValues());
    getBudgetId();
  }, [budget]);

  function handleChange(event: { target: { value: React.SetStateAction<string> } }) {
    setCounter(event.target.value);
  }

  const onSubmit = async (data: AirGuide) => {
    data.accountingInformation = counter;

    try {
      const content = data.content.map(content => ({
        amount: content.quantity,
        number: `${content.itemNumber}`,
        grossWeight: `${content.grossWeight}`,
        description: content.description,
        kglb: `${content.kglb}`,
        loadableWeight: `${content.chargeableWeight}`,
        rate_class: `${content.rateClass}`,
        fee: `${content.rate}`,
        total: `${content.total}`,
      }));
      const AirData = {
        quotation: data.invoiceoperation,
        budgetId: parseInt(id || '0'),
        strategy: DOCUMENT_TYPE_OBJ.AIR_GUIDE,
        reference: data.referenceCode,
        expeditionDate: data.expeditionDate,
        consignee: data.issuedBy,
        conveyors: {
          name: data.iCarriername,
          city: data.iCarriercity,
          accountingInformation: data.accountingInformation,
        },
        shipperPerson: {
          name: data.nameshipper,
          address: data.addressshipper,
          email: data.mailshipper,
          phone: data.phoneshipper,
        },
        consignedToPerson: {
          name: data.nameconsignee,
          address: data.addressconsignee,
          email: data.mailconsignee,
          phone: data.phoneconsignee,
        },
        comments: '',
        collect: String(data.collect),
        isDangerous: false,
        airlineIataCode: data.agentIATACode,
        airlineAccountNumber: data.agentAccountNo,
        airlineDepartureAirport: data.airpotOfDeparture,
        airlineReferenceCode: data.referenceNumber,
        airlineShipmentInformation: data.optionalShippingInformation,
        airlineFirstFor: data.firstTo,
        airlineByFirstAirline: data.byFirstCarrier,
        airlineSecondFor: data.secondTo,
        airlineRouteAndDestination: data.routingAndDestinitation,
        airlineBySecondAirline: data.secondBy,
        airlineByThirdAirline: data.thirdBy,
        airlineThirdFor: data.thirdTo,
        currency: data.currency,
        chgs: data.chgs,
        airlinePaidInAdvance: `${data.prepaid}`,
        airlineCharge: `${data.weightCharge}`,
        airlineSecondPaymentFor: `${data.secondPrepaid}`,
        airlineSecondCharge: `${data.secondCollect}`,
        airlineValuationCharge: `${data.valuationCharge}`,
        airlineSecurity: `${data.security}`,
        airlineIva: `${data.tax}`,
        airlineFuelServices: `${data.fuelServices}`,
        airlineTotalOtherAgentFee: `${data.totalOtherChargesDueAgent}`,
        airlineFullPaidInAdvance: `${data.totalPrepaid}`,
        airlineTotalCharge: `${data.totalCollect}`,
        airlineExclusiveUseOfAirline: data.carriesrsuse,
        airlineDestinationCharges: data.chargesatdestinations,
        airlineTotalChargesReceivable: `${data.totalCollectCharges}`,
        airlineAirpotOfDestination: data.airpotOfDestination,
        airlineRequestedflight: data.requestedFlight,
        airlineHandlingInformation: data.handlingInformation,
        airlineDate: data.date,
        airlineDeclaredValueForCarriage: data.declaredValueForCarriage,
        airlineDeclaredValueForCustoms: data.declaredValueForCustoms,
        airlineAmountOfInsurance: `${data.amountOfInsurance}`,
        airlineTotalOtherChargesDueCarrier: `${data.totalOtherChargesDueCarrier}`,
        documentContent: content,
      };

      let document: any = null;

      if (!documentId) {
        const axiosResponse = await axiosEstimates.post('/api/document', AirData);
        document = axiosResponse.data.data;
      } else {
        const axiosResponse = await axiosEstimates.put('/api/document', { ...AirData, id: documentId });
        document = axiosResponse.data.data;
      }

      if (document) {
        handleNavigatePrint(document.id, location.pathname + '&value=6');

        Swal.fire({
          icon: 'success',
          title: t('alert.success', { ns: 'common' }) || '',
          text: t('alert.supplier.success', { ns: 'common' }) || '',
          showCloseButton: true,
        });
      }
    } catch (err) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: t('alert.somethingerror', { ns: 'common' }) || '',
        showCloseButton: true,
      });
    }
  };
  return {
    t,
    control,
    countryItems,
    contentFields,
    contentAppend,
    contentRemove,
    counter,
    maxLength,
    handleChange,
    onSubmit: handleSubmit(onSubmit, err => console.error(err)),
    documentId,
    navigate: handleNavigatePrint,
  };
};

export default useAirGuide;
