import { useTranslation } from 'react-i18next';
import { useNavigation } from '../../utils';

// @Hooks
import useHomePage from './useHomePage';

// @MUI Components
import { Box, Button, Container, Divider, Grid, Typography } from '@mui/material';

// @MUI Icons
import { Eraser } from 'mdi-material-ui';

// @Components
import Loader from '../../components/Loader';
import { DatePicker } from '../../components';
import { CardStats } from '../../components/CardStats';
import MultipleSelectChip from '../../components/MultipleSelectChip';

/**
 * @component HomePage
 * @subcategory Pages
 * @description This module shows the invoice's stats as long as the user have permissions.
 *
 * <br />
 * <br />
 *
 * <h5>Variants</h5>
 *
 * <li>With permissions: Appears an image.</li>
 * <li>Withous permissions: Appears three inputs for filter the invoice's stats received.</li>
 *
 * <br />
 *
 * <h5>Invoice's stats specifications</h5>
 * <p>The specifications are divided into three <a href='#cardstats'>CardStats</a></p>
 * <ol>
 *    <li>Invoices: All Invoices' total amount calculated.</li>
 *    <li>Paid: All invoices' total amount paid.</li>
 *    <li>Payable: All invoices' total amount payable.</li>
 * </ol>
 * @return {JSX} The HomePage's content content depending of the permissions.
 * @author CML Exports Front-End Developers
 */
export const HomePage = () => {
  const {
    // NOTE: STATUS
    isLoading,
    actionsDisabled,
    // NOTE: FORM CONTROL
    control,
    // NOTE: DATA
    statsData,
    // NOTE: HANDLE FUNCTIONS
    handleGetStats,
    handleClearStatsDates,
  } = useHomePage();

  const { t } = useTranslation('common');

  const { permissions } = useNavigation();

  if (!statsData.length) return null;

  return (
    <Container maxWidth='xl'>
      <Grid container spacing={3} sx={{ mx: 'auto', width: '100%', display: 'flex', gap: 5, justifyContent: 'center' }}>
        <Grid item xs={12}>
          {!permissions.includes('home') ? (
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Box component='img' src='/assets/images-quotes.png' sx={{ mx: 'auto', width: '60%' }} />
            </Box>
          ) : (
            <Box display='flex' flexWrap='wrap' justifyContent='center' gap={3}>
              <Typography sx={{ fontWeight: '600', color: '#5a5a5a' }}>{t('filters.invoicesStats')}</Typography>

              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: { xs: 'column', sm: 'row' },
                  justifyContent: 'center',
                  gap: 3,
                }}
              >
                <MultipleSelectChip
                  name='billsType'
                  control={control}
                  label={t('filters.billType')}
                  items={[
                    { label: 'FF', value: 'ff' },
                    { label: 'CCF', value: 'ccf' },
                    { label: 'CML', value: 'cml' },
                    { label: 'CTIS', value: 'ctis' },
                  ]}
                />
                <DatePicker name='firstDate' control={control} label={t('filters.firstDate')} />
                <DatePicker name='lastDate' control={control} label={t('filters.lastDate')} />

                <Box
                  sx={{
                    width: { xs: '100%', md: '80%', lg: '60%' },
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: 1,
                  }}
                >
                  <Button
                    color='error'
                    variant='contained'
                    sx={{ py: 2, width: 'max-content', fontWeight: 'bold' }}
                    disabled={actionsDisabled}
                    onClick={handleClearStatsDates}
                  >
                    <Eraser />
                  </Button>

                  <Button
                    variant='contained'
                    sx={{ py: 2, width: '100%', fontWeight: 'bold' }}
                    disabled={actionsDisabled}
                    onClick={handleGetStats}
                  >
                    {t('general.search')}
                  </Button>
                </Box>
              </Box>

              <Divider sx={{ my: '2', width: '100%' }} />

              <Box width={'100%'} display='flex' justifyContent={'center'} alignItems='center' flexWrap='wrap' gap={2}>
                {isLoading ? (
                  <Loader sx={{ my: 1 }} />
                ) : (
                  statsData.map((stats, i) => <CardStats key={i} statsData={stats} />)
                )}
              </Box>
            </Box>
          )}
        </Grid>
      </Grid>
    </Container>
  );
};
