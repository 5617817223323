// @Library
import { useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';

// @Redux
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../redux/store';
import { setCustomerForm, nextStep } from '../../../redux/slices/budget-form';
import { getCustomers, getCompanyByRol } from '../../../redux/slices/budget-form/actions-async';
import { RootState } from '../../../redux/reducers';

// @Models
import { ICustomerForm } from '../../../models';

export function useCustomerForm() {
  const { budgetForm, user } = useSelector((state: RootState) => state);
  const [commission, setCommission] = useState<number>(0);
  const userData = user.user;
  const dispatch = useAppDispatch();
  const { customerForm, customers, customersData, sellerForm, companyByRol } = budgetForm;

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<ICustomerForm>({
    defaultValues: customerForm,
  });

  const customerId = useWatch({ control, name: 'customerId' });

  useEffect(() => {
    if (userData && sellerForm.email !== '') {
      dispatch(getCustomers({ email: sellerForm.email }));
      // dispatch(getCompanyByRol({ email: sellerForm.email }));
    }
  }, []);

  useEffect(() => {
    const customer = customersData.filter(customer => customer.id === customerId)[0];
    if (customer) {
      const customerDataForm: ICustomerForm = {
        customerId: customer.id,
        city: customer.city,
        fullName: customer.name,
        identifier: customer.taxId,
        country: customer.country,
        state: customer.state,
        address: customer.address,
        postalCode: customer.postalCode,
        email: customer.email,
        phone: customer.phone,
        commission: ['subscriber', 'maintainer', 'agentExternal'].includes(user.role) ? 0 : customer.commission,
      };

      const resetForm: ICustomerForm =
        customerDataForm.customerId === customerForm.customerId ? customerForm : customerDataForm;

      setCommission(resetForm.commission);

      reset(resetForm);
    }
  }, [customerId]);

  const onSubmit = (data: ICustomerForm) => {
    const customer = customersData.filter(customer => customer.id === customerId)[0];

    const body: ICustomerForm = Object.assign(data, {
      commission: data.commission === 0 ? customer.commission : data.commission,
    });

    dispatch(setCustomerForm(body));
    dispatch(nextStep());
  };

  return { commission, control, errors, onSubmit: handleSubmit(onSubmit), customers, companyByRol };
}
