// @Utils
import { axiosEstimates } from '../../../utils';

const sendFile = async (paymentId: string, paymentFile: File) => {
  const formData = new FormData();

  formData.set('paymentFile', paymentFile);

  const response = await axiosEstimates.post(`/api/bill-payment-file/${paymentId}`, formData);
  const { status, message } = response.data;

  if (status === 200) return true;
  else throw new Error(`${status} - ${message || 'Error al guardar el archivo'} `);
};

export default sendFile;
