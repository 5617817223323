// ** MUI Imports
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import MuiAvatar, { AvatarProps } from '@mui/material/Avatar';

// ** Icon Imports
import { Icon } from '@iconify/react';

type CardStatsHorizontalProps = {
  title: string;
  stats: string;
  icon: string;
  color?: 'info' | 'success' | 'warning' | 'error' | 'primary';
  trend?: 'positive' | 'negative';
};

// ** Styled Avatar component
const Avatar = styled(MuiAvatar)<AvatarProps>(({ theme }) => ({
  width: 44,
  height: 44,
  boxShadow: theme.shadows[3],
  marginRight: theme.spacing(2.75),
  backgroundColor: theme.palette.background.paper,
  '& svg': { fontSize: '1.75rem' },
}));

/**
 * @component
 * @subcategory Global
 *
 * @description
 * A mini card component that displays stats and an icon.
 * #### Example
 * ```
 * return(
    <MiniCardComponent title="Example Title" stats="100" icon="mdi:chart-line" color="primary" trend="positive" />
 * )
 * ```
 *
 * @property {string} title The title of the mini card.
 * @property {string} stats The stats to be displayed on the mini card.
 * @property {string} icon The icon to be displayed on the mini card.
 * @property {'info' | 'success' | 'warning' | 'error' | 'primary'} [color="primary"] The color of the mini card.
 * @property {'positive' | 'negative'} [trend="positive"] The trend of the stats.
 *
 * @return {JSX.Element}
 * @author CML Exports Front-End Developers
 */
const MiniCardComponent = ({ title, icon, stats, color = 'primary', trend = 'positive' }: CardStatsHorizontalProps) => {
  return (
    <Card
      sx={{
        backgroundColor: 'transparent !important',
        boxShadow: theme => `${theme.shadows[0]} !important`,
        border: theme => `1px solid ${theme.palette.divider}`,
      }}
    >
      <CardContent>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Avatar variant='rounded' sx={{ color: `${color}.main` }}>
            <Icon icon={icon} />
          </Avatar>

          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant='caption'>{title}</Typography>

            <Box sx={{ mt: 0.5, display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
              <Typography variant='h6' sx={{ mr: 1, fontWeight: 600, lineHeight: 1.05 }}>
                {stats}
              </Typography>

              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {/* <Box
                  component='span'
                  sx={{ display: 'inline-flex', color: trend === 'positive' ? 'success.main' : 'error.main' }}
                >
                  <Icon icon={trend === 'positive' ? 'mdi:chevron-up' : 'mdi:chevron-down'} />
                </Box> */}
              </Box>
            </Box>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default MiniCardComponent;
