import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, useWatch } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { axiosEstimates } from '../../../../utils/api';

import { ISupplierRequest, ISelectInput, ServiceTypesSelect } from '../../../../models';

interface IProps {
  id: number;
  toggle: () => void;
}

export interface ISupplierForm {
  categories: ISelectInput<number>[];
  serviceTypes: ServiceTypesSelect[];
}

const preLoadFields: ISupplierForm = {
  categories: [],
  serviceTypes: [],
};

export function useEditService({ id, toggle }: IProps) {
  const { t } = useTranslation(['common']);
  const { control, reset, handleSubmit } = useForm({ defaultValues: preLoadFields });
  const [alert, setAlert] = useState({ open: false, type: 'Info', content: '' });
  const categories = useWatch({ control, name: 'categories' });
  const navigate = useNavigate();
  const serviceTypes = useWatch({ control, name: 'serviceTypes' });

  const getSuppliers = () => {
    (async () => {
      const responseGET = await axiosEstimates.get<ISupplierRequest>(`supplierdetails/${id}`, {
        headers: { 'x-module': 'services' },
      });
      if (!responseGET?.data?.message) {
        const data = responseGET.data;

        const categories: ISelectInput<number>[] = [];
        const serviceTypes: ServiceTypesSelect[] = [];

        if (data) {
          data.transportation.forEach(category => {
            categories.push({
              label: category.name,
              labelEN: category.nameEN,
              value: category.id,
            });
          });

          data.service_types.forEach(type => {
            serviceTypes.push({
              label: type.name,
              labelEN: type.nameEN,
              value: type.id,
              category: 1,
            });
          });
        }

        reset({
          categories,
          serviceTypes,
        });
      }
    })();
  };

  const onSubmit = async (datos: ISupplierForm) => {
    try {
      await axiosEstimates.post('/supplierdetails/updateservice', {
        id,
        serviceTypes: datos.serviceTypes.map(type => type.value),
        categories: datos.categories.map(category => category.value),
      });

      setAlert({ open: true, type: 'success', content: 'Proveedor registrado con exito' });
      toggle();
    } catch (error: any) {
      setAlert({ open: true, type: 'error', content: error.response.data.message });
    }
  };

  useEffect(() => {
    getSuppliers();
  }, [id]);

  return { t, control, categories, serviceTypes, onSubmit: handleSubmit(onSubmit) };
}
