import i18n from '../../../i18n';
import { useTranslation } from 'react-i18next';

// @MUI Components
import {
  Card,
  CardContent,
  FormControl,
  FormLabel,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  useMediaQuery,
  useTheme,
  Select,
  MenuItem,
  InputLabel,
} from '@mui/material';

// @Utils
import { statusItems } from '../../../utils';

// @Interfaces
import { ISelectInput } from '../../../models';
interface IStatusRadioButtons {
  handleStatus: (value: number) => void;
}

export default function StatusRadioButtons({ handleStatus }: IStatusRadioButtons) {
  const theme = useTheme();

  const { t } = useTranslation('common');

  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Card sx={{ width: '100%', mb: 6, zIndex: 1 }}>
      <CardContent>
        <FormControl fullWidth>
          {matches ? (
            <>
              <InputLabel>{t('general.status', { ns: 'common' })}</InputLabel>

              <Select fullWidth label={t('general.status', { ns: 'common' })} sx={{ width: 300 }}>
                {statusItems.map((item: ISelectInput<number>) => (
                  <MenuItem key={item.value} value={item.value}>
                    {i18n.language === 'es' ? item.label : item.labelEN}
                  </MenuItem>
                ))}
              </Select>
            </>
          ) : (
            <>
              <FormLabel>
                <Typography> {t('general.status', { ns: 'common' })} </Typography>
              </FormLabel>

              <RadioGroup
                row
                aria-labelledby='demo-row-radio-buttons-group-label'
                name='row-radio-buttons-group'
                onChange={e => handleStatus(parseInt(e.target.value))}
                defaultValue={1}
              >
                <FormControlLabel value={0} control={<Radio />} label={t('general.all')} />
                <FormControlLabel value={3} control={<Radio />} label={t('general.approve')} />
                <FormControlLabel value={1} control={<Radio />} label={t('general.pending')} />
                <FormControlLabel value={2} control={<Radio />} label={t('general.cancelled')} />
                <FormControlLabel value={4} control={<Radio />} label={t('general.paid')} />
              </RadioGroup>
            </>
          )}
        </FormControl>
      </CardContent>
    </Card>
  );
}
