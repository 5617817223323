// @MUI Components
import { IconButton } from '@mui/material/';

// @MUI Icons
import MenuIcon from '@mui/icons-material/Menu';

// @Interface
interface IProps {
  open: boolean;
  handleDrawerToggle: () => void;
}

/**
 * @component
 * @subcategory Global
 *
 * @description
 * BurgerMenu component description
 * #### Example
 *```
 *  const handleDrawerToggle = () => {
 *   // handle drawer toggle implementation
 * }
 * return (
 *   <BurgerMenu handleDrawerToggle={handleDrawerToggle} open={false} />
 * )
 *```
 *
 * @param {boolean} open The state of the drawer
 * @param {function} handleDrawerToggle The function to handle the drawer toggle
 *
 * @return {JSX.Element} BurgerMenu component that displays a menu icon and handles the drawer toggle
 * @author CML Exports Front-End Developers
 */
export default function BurgerMenu({ handleDrawerToggle, open }: IProps) {
  return (
    <IconButton
      color='inherit'
      edge='start'
      aria-label='open drawer'
      onClick={handleDrawerToggle}
      sx={{ mr: 2, display: open ? 'none' : 'block' }}
    >
      <MenuIcon />
    </IconButton>
  );
}
