// @MUI
import { CardActions, Table, TableBody, TablePagination, TableRow } from '@mui/material';

// @Interfaces
interface pagProps {
  total: number;
  setPage: (page: number) => void;
  page: number;
  setPageSize: React.Dispatch<React.SetStateAction<number>>;
  limitPagination?: number;
  pageSize: number;
}

const Pagination = ({ total, setPage, page, setPageSize, limitPagination, pageSize }: pagProps) => {
  const newPage = (e: unknown, newPage: number) => setPage(newPage + 1);
  const handlePageSize = (e: React.ChangeEvent<HTMLInputElement>) => setPageSize(parseInt(e.target.value));

  return (
    <CardActions sx={{ mt: 5 }}>
      <Table>
        <TableBody>
          <TableRow>
            <TablePagination
              sx={{ width: '100vw' }}
              count={total}
              page={page}
              rowsPerPage={pageSize}
              onPageChange={newPage}
              onRowsPerPageChange={handlePageSize}
              rowsPerPageOptions={limitPagination ? [limitPagination] : []}
              labelRowsPerPage=''
            />
          </TableRow>
        </TableBody>
      </Table>
    </CardActions>
  );
};

export default Pagination;
