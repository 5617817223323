/* eslint-disable unused-imports/no-unused-imports */
import { Button, Card, CardContent, Grid, IconButton, Typography } from '@mui/material';
import { DatePicker, SelectInput, TextForm } from '../../../../components';
import CancelIcon from '@mui/icons-material/Cancel';
import useCartaPorte from './use-CartaPorte';
import { useLocation } from 'react-router-dom';
// import { kglb } from '../../../../utils/select-items.util';
import CustomSwalAlert from '../../../../utils/CustomSwalAlert';
import { useTranslation } from 'react-i18next';

const CartaPorteForm = () => {
  const location = useLocation();
  const {
    // t,
    control,
    countryItems,
    contentFields,
    contentAppend,
    contentRemove,
    chargesFields,
    chargesAppend,
    chargesRemove,
    onSubmit,
    documentId,
    navigate,
    setIsSigning,
    isSigning,
    signingDocument,
    canEdit,
  } = useCartaPorte();

  const { t, i18n } = useTranslation('common');

  const signinAlert = () => {
    CustomSwalAlert(t('signStamp.title') || '', t('signStamp.text') || '', 'question', true, () => {
      setIsSigning(true);
      signingDocument();
    });
  };

  return (
    <Grid container columnSpacing={5} rowSpacing={5}>
      <Grid item xs={12} sm={4}>
        <TextForm
          translationFile='validations'
          control={control}
          label={t('pdfs.reference', { ns: 'placeholder' })}
          name={'reference'}
          disabled={isSigning}
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <TextForm
          translationFile='validations'
          control={control}
          label={t('airGuide.invoice', { ns: 'placeholder' })}
          name={'invoice'}
          disabled={isSigning}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextForm
          translationFile='validations'
          control={control}
          label={t('airGuide.bill', { ns: 'placeholder' })}
          name={'bill'}
          disabled={isSigning}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <DatePicker
          control={control}
          label={t('pdfs.expeditionDate', { ns: 'placeholder' })}
          name={'expeditionDate'}
          disabled={isSigning}
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <SelectInput
          disabled
          control={control}
          name='countryOrigin'
          label={t('pdfs.countryOrigin', { ns: 'placeholder' })}
          items={countryItems}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <SelectInput
          disabled
          control={control}
          name='countryDestiny'
          label={t('pdfs.countryDestiny', { ns: 'placeholder' })}
          items={countryItems}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <Card variant={'outlined'}>
          <CardContent>
            <Grid container columnSpacing={5} rowSpacing={5}>
              <Grid item xs={12}>
                <Typography variant='subtitle1'>{t('pdfs.consignatario', { ns: 'placeholder' })}</Typography>
              </Grid>

              <Grid item xs={12}>
                <TextForm
                  translationFile='validations'
                  control={control}
                  label={t('general.name')}
                  name={'by.name'}
                  disabled={isSigning}
                />
              </Grid>
              <Grid item xs={12}>
                <TextForm
                  translationFile='validations'
                  control={control}
                  label={t('pdfs.direction', { ns: 'placeholder' })}
                  name={'by.direction'}
                  disabled={isSigning}
                />
              </Grid>
              <Grid item xs={12}>
                <TextForm
                  translationFile='validations'
                  control={control}
                  label={t('general.email')}
                  name={'by.email'}
                  disabled={isSigning}
                />
              </Grid>
              <Grid item xs={12}>
                <TextForm
                  translationFile='validations'
                  control={control}
                  label={t('general.phone')}
                  name={'by.phone'}
                  disabled={isSigning}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12} sm={6}>
        <Card variant={'outlined'}>
          <CardContent>
            <Grid container columnSpacing={5} rowSpacing={5}>
              <Grid item xs={12}>
                <Typography variant='subtitle1'>{t('pdfs.embarcador', { ns: 'placeholder' })}</Typography>
              </Grid>
              <Grid item xs={12}>
                <TextForm
                  translationFile='validations'
                  control={control}
                  label={t('pdfs.name', { ns: 'placeholder' })}
                  name={'shipper.name'}
                  disabled={isSigning}
                />
              </Grid>
              <Grid item xs={12}>
                <TextForm
                  translationFile='validations'
                  control={control}
                  label={t('pdfs.direction', { ns: 'placeholder' })}
                  name={'shipper.direction'}
                  disabled={isSigning}
                />
              </Grid>
              <Grid item xs={12}>
                <TextForm
                  translationFile='validations'
                  control={control}
                  label={t('general.email')}
                  name={'shipper.email'}
                  disabled={isSigning}
                />
              </Grid>
              <Grid item xs={12}>
                <TextForm
                  translationFile='validations'
                  control={control}
                  label={t('general.phone')}
                  name={'shipper.phone'}
                  disabled={isSigning}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12} sm={6}>
        <Card variant={'outlined'}>
          <CardContent>
            <Grid container columnSpacing={5} rowSpacing={5}>
              <Grid item xs={12}>
                <Typography variant='subtitle1'> {t('title.notifyTo')} </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextForm
                  translationFile='validations'
                  control={control}
                  label={t('general.name')}
                  name={'notifyto.name'}
                  disabled={isSigning}
                />
              </Grid>
              <Grid item xs={12}>
                <TextForm
                  translationFile='validations'
                  control={control}
                  label={t('pdfs.contact', { ns: 'placeholder' })}
                  name={'notifyto.contact'}
                  disabled={isSigning}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12} sm={6}>
        <Card variant={'outlined'}>
          <CardContent>
            <Grid container columnSpacing={5} rowSpacing={5}>
              <Grid item xs={12}>
                <Typography variant='subtitle1'> {t('title.destinyAgent')} </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextForm
                  translationFile='validations'
                  control={control}
                  label={t('general.name')}
                  name={'destinyAgent.name'}
                  disabled={isSigning}
                />
              </Grid>
              <Grid item xs={12}>
                <TextForm
                  translationFile='validations'
                  control={control}
                  label={t('pdfs.contact', { ns: 'placeholder' })}
                  name={'destinyAgent.contact'}
                  disabled={isSigning}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Grid container columnSpacing={5} rowSpacing={5}>
          <Grid item xs={12} sx={{ textAlign: 'center' }}>
            <Typography variant='subtitle1'>{t('pdfs.forwarder.forwarder', { ns: 'placeholder' })}</Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextForm
              translationFile='validations'
              control={control}
              label={t('pdfs.name', { ns: 'placeholder' })}
              name={'forwarder.name'}
              disabled={isSigning}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextForm
              translationFile='validations'
              control={control}
              label={t('pdfs.pilot', { ns: 'placeholder' })}
              name={'forwarder.pilot'}
              disabled={isSigning}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <TextForm
              translationFile='validations'
              control={control}
              label={t('pdfs.referenceCode', { ns: 'placeholder' })}
              name={'referenceCode'}
              disabled={isSigning}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextForm
              translationFile='validations'
              control={control}
              label={t('pdfs.licensePlate', { ns: 'placeholder' })}
              name={'forwarder.licensePlate'}
              disabled={isSigning}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextForm
              translationFile='validations'
              control={control}
              label={t('pdfs.van', { ns: 'placeholder' })}
              name={'forwarder.van'}
              disabled={isSigning}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextForm
              translationFile='validations'
              control={control}
              label={t('pdfs.numberLicense', { ns: 'placeholder' })}
              name={'forwarder.numberLicense'}
              disabled={isSigning}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <TextForm
              translationFile='validations'
              control={control}
              label={t('pdfs.loadingPlace', { ns: 'placeholder' })}
              name={'loadingPlace'}
              disabled={isSigning}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextForm
              translationFile='validations'
              control={control}
              label={t('pdfs.exit', { ns: 'placeholder' })}
              name={'exit'}
              disabled={isSigning}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <TextForm
              translationFile='validations'
              control={control}
              label={t('pdfs.entrance', { ns: 'placeholder' })}
              name={'entrance'}
              disabled={isSigning}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextForm
              translationFile='validations'
              control={control}
              label={t('pdfs.finalDestination', { ns: 'placeholder' })}
              name={'finalDestination'}
              disabled={isSigning}
            />
          </Grid>
        </Grid>
      </Grid>

      {/* CONTENIDO */}
      <Grid item xs={12}>
        <Grid container columnSpacing={5} rowSpacing={5}>
          <Grid item xs={12} sx={{ textAlign: 'center' }}>
            <Typography variant='subtitle1'> {t('general.content')} </Typography>
          </Grid>

          {contentFields.map((field, index) => (
            <Grid key={field.id} item>
              <Card variant='outlined' sx={{ p: 5, position: 'relative' }}>
                <Grid container columnSpacing={5} rowSpacing={5}>
                  <Grid item xs={12} sm={3}>
                    <TextForm
                      translationFile='validations'
                      control={control}
                      label={t('pdfs.content.type', { ns: 'placeholder' })}
                      name={`content.${index}.type`}
                      disabled={isSigning}
                    />
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <TextForm
                      translationFile='validations'
                      control={control}
                      label={t('pdfs.content.quantity', { ns: 'placeholder' })}
                      name={`content.${index}.quantity`}
                      disabled={isSigning}
                    />
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <TextForm
                      translationFile='validations'
                      control={control}
                      label={t('pdfs.content.grossWeight', { ns: 'placeholder' })}
                      name={`content.${index}.grossWeight`}
                      disabled={isSigning}
                    />
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <TextForm
                      translationFile='validations'
                      control={control}
                      label={t('pdfs.content.volume', { ns: 'placeholder' })}
                      name={`content.${index}.volume`}
                      disabled={isSigning}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextForm
                      translationFile='validations'
                      control={control}
                      label={t('pdfs.description', { ns: 'placeholder' })}
                      name={`content.${index}.decription`}
                      disabled={isSigning}
                    />
                  </Grid>

                  {index !== 0 && (
                    <Grid item xs={12}>
                      <IconButton
                        onClick={() => contentRemove(index)}
                        disabled={isSigning}
                        color='error'
                        sx={{
                          position: 'absolute',
                          bottom: 0,
                          left: 0,
                          fontSize: '16px',
                          paddingLeft: '32px',
                          paddingTop: '4px',
                        }}
                      >
                        <CancelIcon />
                        {t('general.deleteContent')}
                      </IconButton>
                    </Grid>
                  )}
                </Grid>
              </Card>
            </Grid>
          ))}

          <Grid item xs={12} sx={{ textAlign: 'center' }}>
            <Button
              type='button'
              sx={{ color: '#00ef14', verticalAlign: 'middle' }}
              disabled={contentFields.length >= 10 || isSigning}
              onClick={() => {
                contentAppend({
                  type: '',
                  quantity: null,
                  decription: '',
                  kglb: 'Kg',
                  grossWeight: null,
                  volume: null,
                });
              }}
            >
              <i className='fa-solid fa-circle-plus text-xl text-green-500 mr-2' />
              <p>{t('add.content')}</p>
            </Button>
          </Grid>
        </Grid>
      </Grid>

      {/* CARGAS */}
      <Grid item xs={12}>
        <Grid container columnSpacing={5} rowSpacing={5}>
          <Grid item xs={12} sx={{ textAlign: 'center' }}>
            <Typography variant='subtitle1'>{t('pdfs.charges.charges', { ns: 'placeholder' })}</Typography>
          </Grid>

          {chargesFields.map((field, index) => {
            return (
              <Grid key={field.id} item xs={12}>
                <Card variant='outlined' sx={{ p: 5, position: 'relative' }}>
                  <Grid container columnSpacing={5} rowSpacing={5}>
                    <Grid item xs={12} sm={4}>
                      <TextForm
                        translationFile='validations'
                        control={control}
                        label={t('pdfs.description', { ns: 'placeholder' })}
                        name={`charges.${index}.description`}
                        disabled={isSigning}
                      />
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <TextForm
                        translationFile='validations'
                        control={control}
                        label={t('pdfs.charges.prepaid', { ns: 'placeholder' })}
                        name={`charges.${index}.prepaid`}
                        disabled={isSigning}
                      />
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <TextForm
                        translationFile='validations'
                        control={control}
                        label={t('pdfs.charges.collect', { ns: 'placeholder' })}
                        name={`charges.${index}.collect`}
                        disabled={isSigning}
                      />
                    </Grid>

                    {index !== 0 && (
                      <Grid item xs={12}>
                        <IconButton
                          onClick={() => {
                            chargesRemove(index);
                          }}
                          disabled={isSigning}
                          color='error'
                          sx={{
                            position: 'absolute',
                            bottom: 0,
                            left: 0,
                            fontSize: '16px',
                            paddingLeft: '32px',
                            paddingTop: '4px',
                          }}
                        >
                          <CancelIcon />
                          {t('general.deleteCharge')}
                        </IconButton>
                      </Grid>
                    )}
                  </Grid>
                </Card>
              </Grid>
            );
          })}
          <Grid item xs={12} sx={{ textAlign: 'center' }}>
            <Button
              type='button'
              sx={{ color: '#00ef14' }}
              onClick={() => {
                chargesAppend({
                  description: '',
                  collect: null,
                  prepaid: null,
                });
              }}
              disabled={chargesFields.length > 4 || isSigning}
            >
              <i className='fa-solid fa-circle-plus text-xl text-green-500 mr-2' />
              <p>{t('airGuide.addCharges', { ns: 'placeholder' })}</p>
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        item
        xs={12}
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          justifyContent: 'center',
          alignItems: 'center',
          gap: 2,
        }}
      >
        <Button disabled={!canEdit && isSigning} variant='contained' onClick={onSubmit}>
          {documentId === null
            ? i18n.language === 'es'
              ? 'Guardar'
              : 'Save'
            : t('budgetGuide.saveDocumentButtonTittle')}
        </Button>

        {documentId !== null && (
          <Button
            color='success'
            disabled={isSigning}
            variant='contained'
            sx={{ backgroundColor: '#04ec2b' }}
            onClick={() => signinAlert()}
          >
            {t('signStamp.btn')}
          </Button>
        )}

        {documentId !== null && (
          <Button
            variant='contained'
            onClick={() => navigate(documentId, location.pathname + '&value=1')}
            color='secondary'
          >
            {t('budgetGuide.viewDocumentButtonTittle')}
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

export default CartaPorteForm;
