// @Library
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';
import { EditorState } from 'draft-js';
import { useParams, useNavigate } from 'react-router-dom';

// @MUI components
import { createFilterOptions } from '@mui/material/Autocomplete';

// @Redux
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/reducers';

// @Utils
import { axiosEstimates } from '../../../../utils';
import { getInvoice } from '../../service/http';

const filter = createFilterOptions();

export function useShareInvoice() {
  const [title, setTitle] = useState<string>('');
  const [emailTo, setEmailTo] = useState<string[]>([]);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const { user } = useSelector((state: RootState) => state.user);

  const menuItemsArr: string[] = [];

  const { id, billType } = useParams();

  const navigate = useNavigate();

  const { t } = useTranslation(['common']);

  useEffect(() => {
    handleGet();
  }, []);

  const handleGet = async () => {
    try {
      if (!id) throw Error('Esta factura no existe');
      if (!billType) throw Error('El tipo de factura es requerido');

      setTitle(`${t('toast.loading')}`);

      const data = await getInvoice(id, billType);

      setTitle(`${data.folio} - ${data.customerName}`);
      setEmailTo([data.customerEmail]);
    } catch (err) {
      console.error(err);
    }
  };

  const addNewOption = (options: string[], params: any): string[] => {
    const filtered = filter(options, params);
    const { inputValue } = params;
    const isExisting = options.some(option => inputValue === option);

    if (inputValue !== '' && !isExisting) filtered.push(inputValue);

    // @ts-ignore
    return filtered;
  };

  const handleAddEmail = (email: string[]) => {
    setEmailTo(email);
  };

  const handleEditorChange = (value: EditorState) => {
    setEditorState(value);
  };

  const handleSendMail = async (action: string) => {
    if (!(emailTo.length > 0)) return toast.error(t('send.emailRequire'));

    if (!user || !id) return null;

    const country = user.person?.country_alpha2code;

    const toastLoading = toast.loading('toast.loading');

    try {
      await axiosEstimates.post('/api/bill/share', {
        action,
        emails: emailTo,
        country,
        idInvoice: parseInt(id),
      });

      toast.success(t('general.success'));
      navigate('/invoices');
    } catch (error) {
      toast.error(t('general.error', { ns: 'common' }));
    } finally {
      toast.dismiss(toastLoading);
    }
  };

  const handleBack = () => navigate('/invoices');

  return {
    t,
    emailTo,
    addNewOption,
    handleAddEmail,
    menuItemsArr,
    editorState,
    handleEditorChange,
    handleBack,
    handleSendMail,
    title,
  };
}
