import { useTranslation } from 'react-i18next';

// @MUI Components
import { Box, Grid } from '@mui/material';

// @Components
import InvoiceCard from './InvoiceCard';
import EmptyData from '../../../components/EmptyData';

// @Interfaces and Types
import { IBillResponse } from '../models/Models';

interface IRowProps {
  invoices: IBillResponse['billsData'];
  handleCancel: (id: number, hasPayments: boolean) => void;
  noRows: boolean;
  permissions: string[];
}

export default function InvoiceList({ invoices, handleCancel, noRows, permissions }: IRowProps) {
  const { t } = useTranslation('common');

  if (noRows) return <EmptyData text={t('general.noRows', { ns: 'validations' })} />;

  return (
    <Grid
      container
      sx={{
        pt: 6,
        display: 'grid',
        gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)', lg: 'repeat(3, 1fr)' },
        rowGap: 5,
        columnGap: 3,
      }}
    >
      {invoices?.map(data => (
        <InvoiceCard
          key={data.id}
          data={data}
          canPrint={permissions.includes('print')}
          canSend={permissions.includes('send')}
          canCancel={permissions.includes('cancel')}
          canPreview={permissions.includes('consult')}
          handleCancel={handleCancel}
        />
      ))}
    </Grid>
  );
}
