import { Loading } from '../models';

/**
 * LOADING_INIT
 * @Category Utils
 * @property {boolean} show
 * @property {string} type
 */
export const LOADING_INIT: Loading = {
  show: false,
  type: 'screen',
};
