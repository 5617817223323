// @MUI Components
import { Box, Button, Grid } from '@mui/material';

// @Hooks
import useInvoiceSetting from '../hooks/useInvoiceSetting';

// @Components
import { MiniCard } from './MiniCards';
import { FormInvoice } from './FormInvoice';
import { CodeSettingTable } from './CodeSettingTable';

export function InvoiceCodeView({ type }: any) {
  const { t, control, handleSubmit, data, show, handleShow, handleShowUpdate } = useInvoiceSetting(type);

  return (
    <Box style={{ width: '100%' }}>
      <MiniCard t={t} data={data} />

      {!show && (
        <Grid container spacing={4} sx={{ mt: 1, paddingLeft: '8px' }}>
          <Box sx={{ m: 3, display: 'flex' }}>
            <Button type='button' sx={{ mr: 4.5 }} variant='contained' color='info' onClick={() => handleShow(true)}>
              {t('add.invoices', { ns: 'common' })}
            </Button>
            <Button type='submit' variant='contained' color='success' onClick={() => handleShowUpdate(true)}>
              {t('general.edit', { ns: 'common' })}
            </Button>
          </Box>
        </Grid>
      )}

      {show && (
        <FormInvoice
          t={t}
          data={data}
          control={control}
          isFirst={data?.data?.configCode?.length === 0}
          handleSubmit={handleSubmit}
          handleShow={handleShow}
        />
      )}

      <Grid item xs={12}>
        <CodeSettingTable t={t} data={data} />
      </Grid>
    </Box>
  );
}
