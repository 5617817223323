// @Mui
import { Card, CircularProgress, Grid } from '@mui/material';

// @Components
import { CustomTabs } from '../../components';

// @Models
import { TabOption } from '../../models';

// ** Views
import { ClientTab } from '../../views/budget-edit/client-tab/client-tab.view';
import { ServiceStep } from '../../views/budget-edit/shipments-tab/shipments-tab.view';

// ** Hooks
import { useBudgetEdit } from './use-budget-edit.hook';

export default function BudgetFormEdit() {
  const { value, handleChange, t, clientTab, servicesTab, status, id, budgetHaveBill, updateDateOfExpiration } =
    useBudgetEdit();

  if (!clientTab || !servicesTab) {
    return (
      <Grid container sx={{ width: '90%', ml: 'auto', mr: { xs: 0.5, md: 'auto' }, zIndex: 1 }}>
        <Card sx={{ width: '100%', height: '40vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress />
        </Card>
      </Grid>
    );
  }

  const tabs: TabOption[] = [
    {
      id: 1,
      label: t('general.customer', { ns: 'common' }),
      content: (
        <ClientTab
          data={clientTab}
          budgetStatus={status}
          idBudget={Number(id || '0')}
          handleChange={handleChange}
          updateDateOfExpiration={updateDateOfExpiration}
          budgetHaveBill={budgetHaveBill}
        />
      ),
    },
    {
      id: 2,
      label: t('general.services', { ns: 'common' }),
      content: (
        <ServiceStep
          data={servicesTab}
          customer={clientTab}
          budgetStatus={status}
          idBudget={Number(id || '0')}
          tabChange={handleChange}
          budgetHaveBill={budgetHaveBill}
        />
      ),
    },
  ];

  return (
    <Grid container sx={{ width: '90%', ml: 'auto', mr: { xs: 0.25, md: 'auto' }, zIndex: 1 }}>
      <Card sx={{ width: '100%' }}>
        <CustomTabs tabs={tabs} tab={value} />
      </Card>
    </Grid>
  );
}
