// @Interfaces and Types
import { ExpenseType, InvoiceType, LoadingType, PaymentType, UserType } from '../types';

export const initialLoadingProcess: LoadingType = { invoiceData: false, expensesData: false, paymentsData: false };
export const initialInvoiceData: InvoiceType = {
  id: 0,
  budgetId: 0,
  folio: '',
  nitDui: 0,
  customerId: 0,
  customerName: '',
  generatedBy: 0,
  nameGeneratedBy: '',
  address: '',
  email: '',
  phone: '',
  payCondition: '',
  billTypeId: 0,
  billTypeName: '',
  iva: 0,
  subTotal: 0,
  totalAmount: 0,
  totalExempt: 0,
  totalNotSubject: 0,
  totalSales: 0,
  totalTaxed: 0,
  totalPaid: 0,
  totalToPay: 0,
  expenses: [],
  payments: [],
  permissions: {},
  createAt: '',
  updateAt: '',
};

export const initialUser: UserType = {
  id: '',
  role: '',
  name: 'System',
  email: '',
  phone: '',
  avatar: '',
  address: '',
  position: '',
  state: '',
  status: false,
  username: '',
  typeLocation: '',
  identification: '',
  taxIdentification: '',
};

export const initialPaymentsData: PaymentType[] | [] = [];
export const initialPayment: PaymentType = {
  billId: 0, // Auto fill
  type: 'income', // Auto fill
  paymentMethod: '', // In form
  bank: '', // In form
  depositAccount: '', // In form
  paymentDate: '', // In form
  reference: '', // In form
  receivedAmount: 0, // In form
  limitAmount: 0, // Auto fill custom data
  expenseId: null, // Auto fill
  note: '', // Unused
  userId: 0, // Auto fill
  user: initialUser, // Auto fill
  haveFile: false, // Auto fill
};

export const initialExpensesData: ExpenseType[] | [] = [];
export const initialExpense: ExpenseType = {
  id: '',
  idDocument: '',
  quantity: 0,
  type: 0,
  description: '',
  unitPrice: 0,
  subTotal: 0,
  iva: 0,
  total: 0,
  createdAt: '',
  updateAt: '',
  ivaRet: 0,
  isrRet: 0,
  code: '',
  date: '',
  otherCharges: false,
  supplierName: '',
  // Custom data
  payments: [],
};
