import { useTranslation } from 'react-i18next';

// @MUI Components
import { Box, Card } from '@mui/material';

// @Icons

// @Components
import { SearchField } from '../../../components';

// @Hooks

// @Interfaces and Types
interface IRowProps {
  control: any;
  typeFilter: number;
  setTypeFilter: React.Dispatch<React.SetStateAction<number>>;
  customerItems: any[];
  searchValue: string;
  setSearchValue: React.Dispatch<React.SetStateAction<string>>;
  handleCustomerFilter: (value: number) => void;
}
export const ActionsBar: React.FC<IRowProps> = ({
  control,
  typeFilter,
  setTypeFilter,
  customerItems,
  searchValue,
  setSearchValue,
  handleCustomerFilter,
}) => {
  const { t } = useTranslation('common');

  return (
    <Card
      sx={{
        p: { xs: 2, md: 5 },
        width: '100%',
        display: 'flex',
        flexWrap: { xs: 'wrap', sm: 'nowrap' },
        alignItems: 'center',
        justifyContent: { xs: 'center', sm: 'space-between' },
        gap: 1,
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: { xs: 'center', sm: 'flex-start' }, height: '36.5px' }}>
        {/* <Box sx={{ height: '36.5px', display: 'flex', flexDirection: 'row' }}>
          <IconButton
            color='primary'
            sx={{ height: '36.5px' }}
            onClick={() => setTypeFilter(2)}
            disabled={typeFilter === 2}
          >
            <Icon icon='ri:user-search-fill' width={36.5} height={36.5} />
          </IconButton>

          <IconButton
            color='primary'
            sx={{ height: '36.5px' }}
            onClick={() => setTypeFilter(1)}
            disabled={typeFilter === 1}
          >
            <Icon icon='mdi:file-search' width={36.5} height={36.5} />
          </IconButton>
        </Box> */}

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            height: '36.5px',
            '@media (max-width: 600px)': { height: '20px', width: '150px' },
          }}
        >
          <SearchField value={searchValue} handleFilter={setSearchValue} placeholder={t('general.search')} />
        </Box>
        {/* {typeFilter === 1 ? (

        ) : (
          <Box
            sx={{
              ml: '6px',
              width: '270px',
              height: '36.5px',
              '@media (max-width: 600px)': { height: '20px', width: '150px' },
            }}
          >
            <SelectForm
              size='small'
              control={control}
              name='customerFilter'
              label={t('filter.customer')}
              items={customerItems}
              onExternalChange={e => handleCustomerFilter(e as number)}
            />
          </Box>
        )} */}
      </Box>
    </Card>
  );
};
