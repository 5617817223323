import { createSlice, PayloadAction, AnyAction, AsyncThunk } from '@reduxjs/toolkit';

// @Models
import { Loading, IBudgetState, IGetRequestBudgets, IGetBudgetAnalysis } from '../../../models';

// @Actions
import { getBudgets, getBudgetAnalysis, getBudgetStatusCount } from './actions-async';

// @AsyncThunk
type GenericAsyncThunk = AsyncThunk<unknown, unknown, any>;
type PendingAction = ReturnType<GenericAsyncThunk['pending']>;
type RejectedAction = ReturnType<GenericAsyncThunk['rejected']>;

const isLoaderAction = (action: AnyAction): action is PendingAction => {
  return action.type.startsWith('budget/') && action.type.endsWith('/pending');
};

const LOADING_INIT: Loading = {
  show: false,
  type: 'screen',
};

const initialState: IBudgetState = {
  budgets: [],
  budgetItems: [],
  statusCount: [],
  permissions: {},
  pagination: { count: 0, page: 1, perPage: 10 },
  loading: LOADING_INIT,
  errors: {
    error: false,
    text: [],
  },
};

export const budgetSlice = createSlice({
  name: 'budget',
  initialState,
  reducers: {
    clearBudgets: () => initialState,
  },

  extraReducers: builder => {
    builder.addCase(getBudgets.fulfilled, (state, action: PayloadAction<IGetRequestBudgets>) => {
      state.budgets = action.payload.budgets;
      state.permissions = action.payload.permissions;
      state.loading = LOADING_INIT;

      state.budgetItems = action.payload.budgets.map(budget => ({
        value: budget.id,
        label: `${budget.code} - ${budget.customerName}`,
      }));

      state.pagination = action.payload.pagination;
    });

    builder.addCase(getBudgetAnalysis.fulfilled, (state, action: PayloadAction<IGetBudgetAnalysis>) => {
      state.budget = action.payload;
    });

    builder.addCase(getBudgetStatusCount.fulfilled, (state, action: PayloadAction<any>) => {
      state.statusCount = action.payload;
    });

    // Matcher para saber cuando una petición isLoading
    builder.addMatcher(isLoaderAction, state => {
      state.errors = { text: [], error: false };
      state.loading = { show: true, type: 'screen' };
    });

    // Matcher para saber cuando una petición es rejected
    builder.addMatcher(
      (action): action is RejectedAction => action.type.startsWith('user/') && action.type.endsWith('/rejected'),
      state => {
        state.loading = LOADING_INIT;
        state.errors = {
          text: ['Ocurrió un error al procesar tu solicitud'],
          error: true,
        };
      },
    );
  },
});

export const { clearBudgets } = budgetSlice.actions;

export default budgetSlice.reducer;
