import { AsyncThunkPayloadCreator, createAsyncThunk } from '@reduxjs/toolkit';
// @Models
import { ILoginProps, ILoginTokenProps } from '../../../models';
// @Utils
import { axiosSystem, axiosEstimates } from '../../../utils/api';

// !--- INIT HANDLER ---!
const handlerLogin: AsyncThunkPayloadCreator<any, ILoginProps, {}> = async (payload, { rejectWithValue }) => {
  try {
    const response = await axiosSystem.post('login', payload);
    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
};

const handlerLoginToken: AsyncThunkPayloadCreator<any, ILoginTokenProps, {}> = async (payload, { rejectWithValue }) => {
  try {
    const response = await axiosSystem.post(`login/${payload.token}`, payload);
    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
};

const handlerGetUser: AsyncThunkPayloadCreator<any, { email: string; idSystem: number }, {}> = async (
  payload,
  { rejectWithValue },
) => {
  try {
    const response = await axiosEstimates.post('currentUser', payload);
    return response.data;
  } catch (error: any) {
    if (error.response.data.message === 'Request failed with status code 404') {
      return rejectWithValue('Verificar la posición del usuario');
    }

    return rejectWithValue(error.response.data);
  }
};
// !--- END HANDLER ---!
// !--- INIT THUNKS ---!
export const getLogin = createAsyncThunk('user/getLogin', handlerLogin);
export const getUser = createAsyncThunk('user/getUser', handlerGetUser);
export const getLoginToken = createAsyncThunk('user/getLoginToken', handlerLoginToken);
// !--- END THUNKS ---!
