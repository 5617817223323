import toast from 'react-hot-toast';
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';

// @Redux
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/reducers';
import { useAppDispatch } from '../../../redux/store';
import { getBudgets } from '../../../redux/slices/budgets/actions-async';
import { clearBudgets } from '../../../redux/slices/budgets';

// @Utils
import CustomSwalAlert from '../../../utils/CustomSwalAlert';
import { initialValues, schemaValidation } from '../utils/expenseDetailsSchema';

// @Services
import getSuppliers from '../services/getSuppliers';
import createExpenseDocument from '../services/createExpenseDocument';
import editExpenseDocument from '../services/editExpenseDocument';
import getExpenseDocument from '../services/getExpenseDocument';

// @Models
import { expenseFileType } from '../types';
import { IAddExpense, IExpensesForm, ISelectInput } from '../../../models';
import { CustomFiles } from '../../../models/CustomFiles';

// @Initial States
const initialSuppliers: ISelectInput<number>[] = [];
const initialExpenses: IAddExpense[] = [];
const initialExpense: IAddExpense | undefined = undefined;
const initialExpenseFile: CustomFiles = null;

export default function useExpenseForm() {
  // NOTE: Page load status
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [isReadySuppliers, setIsReadySuppliers] = useState(false);
  const [isReadyExpenseDocument, setIsReadyExpenseDocument] = useState(false);
  // NOTE: Essential data
  const [suppliers, setSuppliers] = useState(initialSuppliers);
  const [expenses, setExpenses] = useState(initialExpenses);
  const [expense, setExpense] = useState(initialExpense);
  // NOTE: Form states
  const [formIsOpen, setFormIsOpen] = useState(false);
  const [expenseFile, setExpenseFile] = useState(initialExpenseFile);
  const { control, handleSubmit, setValue } = useForm<IExpensesForm>({
    defaultValues: initialValues,
    resolver: yupResolver(schemaValidation),
  });

  const { t } = useTranslation('common');

  const { id } = useParams();

  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const {
    budgets: { budgetItems },
    user: { role, user },
  } = useSelector((state: RootState) => state);

  // NOTE: ALl ACTIONS
  const fetchSuppliers = async () => {
    try {
      const suppliers = await getSuppliers();

      setSuppliers(suppliers);

      setIsReadySuppliers(true);
    } catch (err: any) {
      console.error(err);
      setIsError(true);
      const msg = err.msg || err.statusText || err.message || err.text || t('general.errorUnexpected');
      CustomSwalAlert(t('errors.getSuppliers'), msg, 'error', false);
    }
  };

  const fetchExpenseDocument = async (id: string) => {
    const expenseDocument = await getExpenseDocument(id);

    if (!expenseDocument) {
      return CustomSwalAlert(t('errors.editExpenseDocument'), t('errors.expenseDocumentEmptyData'), 'error', false);
    }

    const expenseDocumentFile = expenseDocument.file[0];
    if (expenseDocumentFile) setExpenseFile(expenseDocumentFile);

    const expenseDocumentData = expenseDocument.data;

    setValue('code', expenseDocumentData.code);
    setValue('date', expenseDocumentData.date);
    setValue('idBudget', expenseDocumentData.idBudget);
    setValue('idSupplier', expenseDocumentData.idSupplier);
    setValue('otherCharges', expenseDocumentData.otherCharges);
    setValue('status', expenseDocumentData.status);
    setValue('type', expenseDocumentData.type);
    setValue('expenses', []);

    setExpenses(
      expenseDocumentData.expenses.map((e, i) => ({
        id: i + 1,
        idExpense: e.id,
        description: e.description,
        iva: e.iva,
        ivaRet: e.ivaRet,
        quantity: e.quantity,
        subTotal: e.subTotal,
        total: e.total,
        type: e.type,
        unitPrice: e.unitPrice,
        isrRet: e.isrRet,
      })),
    );

    setIsReadyExpenseDocument(true);
  };

  // SUCCESS: CREATE OR EDIT EXPENSE DOCUMENT
  const sendExpenseDocument = async (data: IExpensesForm) => {
    data.expenses = expenses;

    const toastId = toast.loading(t('toast.loading'));

    try {
      if (id) await editExpenseDocument(id, data, expenseFile instanceof File ? expenseFile : null);
      else await createExpenseDocument(data, expenseFile instanceof File ? expenseFile : null);

      toast.dismiss(toastId);
      toast.success(t('toast.success2'));

      handleBack();
    } catch (error) {
      console.error(error);
      toast.dismiss(toastId);
      toast.error(t('toast.error'));
    }
  };

  // SUCCESS: ADD A NEW EXPENSE
  const addExpense = (data: Omit<IAddExpense, 'id' | 'idExpense'>) => {
    setExpenses([...expenses, { id: expenses.length + 1, idExpense: null, ...data }]);
    setFormIsOpen(false);
  };

  // SUCCESS: EDIT EXPENSE
  const editExpense = (data: IAddExpense) => {
    setExpenses(expenses.map(e => (e.id === data.id ? data : e)));
    setFormIsOpen(false);
  };

  // SUCCESS: DELETE EXPENSE
  const deleteExpense = (id: number) => {
    setExpenses(expenses.filter(e => e.id !== id));
  };

  // NOTE: ALl HANDLE FUNCTIONS
  const handleToggleForm = () => setFormIsOpen(!formIsOpen);

  const handleEditExpense = (expenseData: IAddExpense) => {
    setFormIsOpen(true);
    setExpense(expenseData);
  };

  const handleBack = () => navigate('/expenses');

  // NOTE: ALl USE EFFECTS
  useEffect(() => {
    fetchSuppliers();

    if (id) fetchExpenseDocument(id);
    else setIsReadyExpenseDocument(true);

    dispatch(
      getBudgets({
        page: 1,
        perPage: 20,
        paginate: 'false',
        filter: '',
        idUser: user?.id || 0,
        isSeller: ['subscriber', 'maintainer'].includes(role),
        status: 'approved',
        type: 1,
        expenses: 'true',
        from: '',
        to: '',
      }),
    );

    return () => {
      dispatch(clearBudgets());
    };
  }, []);

  useEffect(() => {
    if (!formIsOpen) setExpense(initialExpense);
  }, [formIsOpen]);

  useEffect(() => {
    if (isReadySuppliers && isReadyExpenseDocument) setIsLoading(false);
  }, [isReadySuppliers, isReadyExpenseDocument]);

  return {
    // NOTE: PAGE LOAD STATUS
    isLoading,
    isError,
    // NOTE: DATA
    suppliers,
    expenses,
    expense,
    budgetItems,
    total: expenses.reduce((prev, sum) => prev + sum.total, 0),
    // NOTE: ACTIONS
    sendExpenseDocument,
    addExpense,
    editExpense,
    deleteExpense,
    // NOTE: HANDLE FUNCTIONS
    handleToggleForm,
    handleEditExpense,
    handleBack,
    // NOTE: FORM STATES
    formIsOpen,
    control,
    expenseFile,
    setExpenseFile,
    handleSubmit,
  };
}
