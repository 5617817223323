import { DefaultTFuncReturn } from 'i18next';

// @MUI Components
import { Chip, Stack, SxProps, Typography } from '@mui/material';

// @Interfaces and Types
import { ThemeColor } from '../models';
interface IProps {
  title: DefaultTFuncReturn | string | number;
  text: DefaultTFuncReturn | string | number;
  color?: ThemeColor;
  variant?: 'outlined' | 'filled';
  sx?: SxProps;
}

/**
 * @component
 * @subcategory Global
 *
 * @description
 * This component is a custom stack that displays a title and a chip with a given text.
 * #### Example
 * ```jsx
 * return (
 *  <CustomStack title="Title" text="Text" color="primary" variant="filled" />
 * )
 * ```
 *
 * @property {DefaultTFuncReturn | string | number} title The title to be displayed.
 * @property {DefaultTFuncReturn | string | number} text The text to be displayed on the chip.
 * @property {ThemeColor} color The color of the chip. Defaults to 'secondary'.
 * @property {"outlined" | "filled"} [variant] The variant of the chip. Can be 'outlined' or 'filled'. Defaults to 'outlined'.
 * @property {SxProps} [sx] Additional styles to be applied to the stack.
 *
 * @returns {JSX.Element} A stack with a title and a chip.
 * @author CML Exports Front-End Developers
 */
export default function CustomStack({ title, text, color = 'secondary', variant = 'outlined', sx = {} }: IProps) {
  return (
    <Stack direction='column' sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', ...sx }}>
      <Typography sx={{ color: '#474747', fontSize: '0.95rem' }}>{title}</Typography>
      <Chip color={color} variant={variant} sx={{ fontSize: '1rem' }} label={text} />
    </Stack>
  );
}
