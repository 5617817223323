import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { AxiosError } from 'axios';
import sweetalert from 'sweetalert2';

// @Services
import getCategories from '../services/getCategories';

// @Utils
import { axiosEstimates } from '../../../utils/api';

// @Interfaces and Types
import { ServiceCategoryModel } from '../../../models/categories';

export function useServiceCategory() {
  // NOTE: PERMISSIONS
  const [permissions, setPermissions] = useState<string[]>([]);
  // NOTE: STATUS
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [tableIsLoading, setTableIsLoading] = useState(true);

  // NOTE: FILTERS
  const [filter, setFilter] = useState('');
  // NOTE: DATA
  const [serviceCategory, setServiceCategory] = useState<ServiceCategoryModel | undefined>();
  const [serviceCategories, setServiceCategories] = useState<ServiceCategoryModel[]>([]);
  const [addCategoryTitle, setAddCategoryTitle] = useState({ name: '', ESname: '' });

  const { t } = useTranslation(['common']);

  // NOTE: ALL ACTIONS
  const getServiceCategory = async (fromFilters?: boolean) => {
    try {
      !fromFilters ? setIsLoading(true) : setTableIsLoading(true);

      const data = await getCategories(filter);

      const serviceTypes: ServiceCategoryModel[] = data.getTransportation.map(type => ({
        id: type.id,
        name: type.name,
        nameEN: type.nameEN,
        canEdit: type.canEdit,
        username: type.username,
      }));

      setServiceCategories(serviceTypes);
      setPermissions(Object.keys(data.permissions));
      setAddCategoryTitle(data.permissions.add_service_category);
    } catch (err) {
      console.error(err);
    } finally {
      setTimeout(() => setIsLoading(false), 300);
      setTableIsLoading(false);
    }
  };

  const deleteCategory = async (id: number) => {
    sweetalert
      .fire({
        icon: 'warning',
        title: `${t('general.areYouSure')}`,
        showConfirmButton: true,
        showCancelButton: true,
        cancelButtonColor: '#d33',
        confirmButtonColor: '#3085d6',
        cancelButtonText: t('general.cancel', { ns: 'common' }) || '',
        confirmButtonText: t('general.confirm', { ns: 'common' }) || '',
      })
      .then(async action => {
        if (action.isConfirmed) {
          try {
            await axiosEstimates.delete('transportation/' + id);
            setServiceCategories(services => services.filter(service => service.id !== id));
          } catch (error) {
            if (error instanceof AxiosError) {
              if (error.response?.status === 409) {
                sweetalert.fire(
                  `${t('errors.services.deleteTitle')}`,
                  `${t('errors.services.deleteRelation')}`,
                  'error',
                );
              }
            }
          }
        }
      });
  };

  // NOTE: ALL HANDLE FUNCTIONS
  const handleShow = (render: boolean, serviceCategory?: ServiceCategoryModel) => {
    setShow(render);
    setServiceCategory(serviceCategory);
  };

  const handleFilter = (value: string) => {
    setFilter(value);
  };

  const handleAddCategory = async () => {
    await getServiceCategory();
    setShow(false);
  };

  const handleEditCategory = async () => {
    await getServiceCategory();
    setShow(false);
  };

  // NOTE: ALL USE EFFECTS
  useEffect(() => {
    getServiceCategory(true);
  }, [filter]);

  return {
    // NOTE: PERMISSIONS
    permissions,
    // NOTE: STATUS
    show,
    isLoading,
    tableIsLoading,
    // NOTE: FILTERS
    filter,
    // NOTE: DATA
    serviceCategory,
    serviceCategories,
    addCategoryTitle,
    // NOTE: ALL ACTIONS
    deleteCategory,
    // NOTE: ALL HANDLE FUNCTIONS
    handleShow,
    handleFilter,
    handleAddCategory,
    handleEditCategory,
  };
}
