import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';

// @Redux
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/reducers';
import { useAppDispatch } from '../../../../redux/store';
import { getServiceTypes } from '../../../../redux/slices/services/actions-async';

// @Services
import getBudgetById from '../../services/getBudgetById';
import getBudgetsRequestedItems from '../../services/getBudgetsRequestedItems';
import editServiceBudget from '../../services/editServiceBudget';
import createServiceBudget from '../../services/createServiceBudget';

// @Utils
import { AxiosError } from 'axios';
import CustomSwalAlert from '../../../../utils/CustomSwalAlert';
import { initialValues, schemaValidation } from './utils/schema-validation.util';

// @Models
import {
  ISelectInput,
  IServicesOfSuppliersAdd,
  IServicesSupplier,
  IGetBudgets,
  IAdapterServicesOfSuppliersForm,
  IShipment,
} from '../../../../models';
import updateBudgetStatus from '../../services/updateBudgetStatus';
import { CustomFiles, FileUploaded } from '../../../../models/CustomFiles';

interface IBudgetItems extends ISelectInput<number> {
  data?: IGetBudgets;
}

export default function useServicesBudget() {
  // NOTE: FORM STATUS
  const [disabled, setDisabled] = useState(false);
  const [isEditing, setIdEditing] = useState(false);
  const [serviceFormIsOpen, setServiceFormIsOpen] = useState(false);
  // NOTE: FORM DATA
  const [budgets, setBudgets] = useState<IBudgetItems[]>([]);
  const [shipments, setShipments] = useState<IShipment[]>([]);
  const [services, setServices] = useState<IServicesSupplier[]>([]);
  const [service, setService] = useState<Partial<IServicesSupplier> | undefined>();
  // NOTE: FILES
  const [uploadFileOne, setUploadFileOne] = useState<CustomFiles>(null);
  const [uploadFileTwo, setUploadFileTwo] = useState<CustomFiles>(null);
  const [uploadFileThree, setUploadFileThree] = useState<CustomFiles>(null);
  const [filesUploaded, setFilesUploaded] = useState<FileUploaded[]>([
    {
      file: uploadFileOne,
      setFile: setUploadFileOne,
      filesViewParams: { id: 1, model: 'suppliers' },
    },
    {
      file: uploadFileTwo,
      setFile: setUploadFileTwo,
      filesViewParams: { id: 2, model: 'suppliers' },
    },
    {
      file: uploadFileThree,
      setFile: setUploadFileThree,
      filesViewParams: { id: 3, model: 'suppliers' },
    },
  ]);

  const { control, handleSubmit, setValue, reset, watch } = useForm<IServicesOfSuppliersAdd>({
    defaultValues: initialValues,
    resolver: yupResolver(schemaValidation),
  });

  const idBudget = watch('idBudget');

  const { t } = useTranslation('common');

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { idSupplier, idSupplierBudget } = useParams();

  const {
    countries: { countryItems, conutryItemsES },
  } = useSelector((state: RootState) => state);

  // NOTE: ALL ACTIONS
  const totalCost = services.reduce((accumulator, currentValue) => accumulator + (currentValue.cost ?? 0), 0);

  const findBudgetById = async () => {
    if (!idSupplierBudget) return;

    setIdEditing(true);

    try {
      const budgetData = await getBudgetById(idSupplierBudget);

      if (!budgetData?.budget) throw new Error("This budget aren't exists");

      if (budgetData.budgetFiles && budgetData?.budgetFiles?.length > 0) {
        setUploadFileOne(budgetData.budgetFiles[0]);
        setUploadFileTwo(budgetData.budgetFiles[1]);
        setUploadFileThree(budgetData.budgetFiles[2]);
      }

      setServices(budgetData.budget.servicesSuppliers);

      reset({
        countryDestiny: budgetData.budget.countryDestiny,
        countryOrigin: budgetData.budget.countryOrigin,
        comission: budgetData.budget.comission,
        expDate: budgetData.budget.expDate,
        quotationCode: budgetData.budget.quotationCode,
        transportTime: budgetData.budget.transportTime,
        via: budgetData.budget.via,
      });

      setValue('countryDestiny', budgetData.budget.countryDestiny);
      setValue('countryOrigin', budgetData.budget.countryOrigin);
      setValue('transportTime', budgetData.budget.transportTime);
    } catch (error) {
      console.error(error);
      CustomSwalAlert('Budget not found', "This budget aren't exists", 'error', false, () => window.location.reload());
    }
  };

  // SUCCESS: Create or edit a service budget
  const sendServiceBudget = async (data: IServicesOfSuppliersAdd) => {
    if (!idSupplier) return null;

    const toastId = toast.loading(`${t('toast.saved')}`);

    try {
      // Filtrar archivos que solo sean de tipo Archivo
      const files = [];

      if (uploadFileOne instanceof File) files.push(uploadFileOne);
      if (uploadFileTwo instanceof File) files.push(uploadFileTwo);
      if (uploadFileThree instanceof File) files.push(uploadFileThree);

      const uploadFiles: File[] = files.filter(file => file && file);
      // END

      if (idSupplierBudget) {
        // Edit service budget
        const body = { ...data, services };

        const serviceBudgetUpdated = await editServiceBudget(idSupplierBudget, body, uploadFiles);

        if (serviceBudgetUpdated?.relationship) {
          CustomSwalAlert(
            'Algunos servicios no se pudieron eliminar',
            'Esto se debe a que ya estan relacionado con cotización',
            'warning',
            false,
          );
        }
        // funcionalidad aun no terminada
        // if (serviceBudgetUpdated?.existBudget) {
        //   Swal.fire({
        //     title: t('alert.attention', { ns: 'common' }) || '',
        //     text: t('alert.supplierEdit', { ns: 'common' }) || '',
        //     icon: 'warning',
        //     showCancelButton: true,
        //     confirmButtonText: t('alert.confirmButton', { ns: 'common' }) || '',
        //     confirmButtonColor: '#56CA00',
        //     cancelButtonColor: '#FF4C51',
        //     cancelButtonText: t('general.cancel', { ns: 'common' }) || '',
        //   }).then(result => {
        //     /* Read more about isConfirmed, isDenied below */
        //     if (result.isConfirmed) {
        //       (async () => {
        //         const toastId = toast.loading(t('toast.saved'));
        //         setTimeout(() => {
        //           toast.dismiss(toastId);
        //           toast.success(t('toast.success'));
        //         }, 1000);
        //       })().catch(() => {
        //         toast.error(t('toast.error'));
        //       });
        //     } else if (result.isDenied) {
        //       (async () => {
        //         const toastId = toast.loading(t('toast.saved'));
        //         setTimeout(() => {
        //           toast.dismiss(toastId);
        //           toast.success(t('toast.success'));
        //         }, 1000);
        //       })().catch(() => {
        //         toast.error(t('toast.error'));
        //       });
        //     }
        //   });
        // }

        toast.dismiss(toastId);
        toast.success(`${t('toast.success2')}`);
        navigate(`/suppliers/details/${idSupplier}`);
      } else {
        // Create new service budget
        const servicesData: Omit<IAdapterServicesOfSuppliersForm, 'idSupplier'>[] = services.map(service => ({
          idService: service.idService || 0,
          idShipment: service.idShipment,
          transportation: service.idTransportation,
          cost: parseFloat(service?.cost?.toFixed(2) || '0'),
          salePrice: parseFloat(service?.salePrice?.toFixed(2) || '0'),
          comission: service.comission || 0,
          countryOrigin: data.countryOrigin,
          via: data.via,
          countryDestiny: data.countryDestiny,
          transportTime: data.transportTime || 0,
          quotationCode: data.quotationCode,
          expDate: data.expDate,
          saleType: service.saleType || 3,
          type: service.type,
        }));

        const body = {
          services: servicesData,
          idBudget: data.idBudget === -1 ? null : data.idBudget,
          idSupplier,
        };

        const create = await createServiceBudget(body, uploadFiles);
        if (create.status === 200) {
          if (idBudget !== -1) {
            CustomSwalAlert(
              t('suppliers.serviceBudgetSaved'),
              t('suppliers.initializeQuote'),
              'warning',
              true,
              async () => await updateBudgetStatus(idBudget),
            );
          }

          toast.dismiss(toastId);
          toast.success(`${t('toast.success2')}`);

          navigate(`/suppliers/details/${idSupplier}`);
        } else {
          toast.dismiss(toastId);
          toast.error(`${t('toast.error')}`);
        }
      }
    } catch (error) {
      toast.dismiss(toastId);

      if (error instanceof AxiosError) {
        if (error.response?.status === 409) return toast.error('El Folio ya esta registrado para este proveedor');
      } else return toast.error(`${t('toast.error')}`);
    }
  };

  const getBudgetsRequestedData = async () => {
    const budgetsData = await getBudgetsRequestedItems(idSupplier || '');
    setBudgets([{ value: -1, label: t('general.none') }, ...budgetsData]);
  };

  // NOTE: ALL HANDLE FUNCTIONS
  const handleGoBack = () => navigate(-1);

  const handleToggleForm = (value?: Partial<IServicesSupplier>) => {
    setService(value);
    setServiceFormIsOpen(!serviceFormIsOpen);
  };

  const handleAddService = (newService: Omit<IServicesSupplier, 'id'>) => {
    setServices([...services, { id: services.length + 1, ...newService }]);
    setServiceFormIsOpen(false);
  };

  const handleEditService = (editService: IServicesSupplier) => {
    const newServices = services.map(service => (service.id === editService.id ? editService : service));

    setServices(newServices);
    setServiceFormIsOpen(false);
  };

  const handleRemoveService = (id: number) => setServices(services.filter(service => service.id !== id));

  // NOTE: ALL USE EFFECTS
  useEffect(() => {
    if (idSupplier) getBudgetsRequestedData();

    dispatch(getServiceTypes());

    if (idSupplierBudget) findBudgetById();
  }, []);

  useEffect(() => {
    const budget = budgets.find(budget => budget.value === idBudget);

    if (budget) {
      setValue('countryOrigin', budget?.data?.countryOrigin || '');
      setValue('countryDestiny', budget?.data?.countryDestiny || '');
      setDisabled(budget?.data !== undefined);
      setShipments(budget?.data?.shipments || []);
    }
  }, [idBudget]);

  useEffect(() => {
    const totalFiles: CustomFiles[] = [];

    if (uploadFileOne) totalFiles.push(uploadFileOne);
    if (uploadFileTwo) totalFiles.push(uploadFileTwo);
    if (uploadFileThree) totalFiles.push(uploadFileThree);

    setFilesUploaded(filesUploaded.map((file, i) => ({ ...file, file: totalFiles[i] })));
  }, [uploadFileOne, uploadFileTwo, uploadFileThree]);

  return {
    // NOTE: STATUS
    disabled,
    isEditing,
    serviceFormIsOpen,
    // NOTE: DATA
    budgets,
    services,
    service,
    shipments,
    countryItems,
    conutryItemsES,
    // NOTE: FORM DATA
    filesUploaded,
    control,
    onSubmit: handleSubmit(sendServiceBudget),
    // NOTE: ACTIONS
    totalCost,
    // NOTE: HANDLE FUNCTIONS
    handleAddService,
    handleEditService,
    handleRemoveService,
    handleToggleForm,
    handleGoBack,
  };
}
