import { useTranslation } from 'react-i18next';

// @MUI Components
import {
  Card,
  CardHeader,
  Typography,
  CardContent,
  Divider,
  IconButton,
  Box,
  // Button
} from '@mui/material';

// @MUI Icons
import AddIcon from '@mui/icons-material/Add';
import CreditCardIcon from '@mui/icons-material/Payment';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';

// @Utils
import { formatNumber } from '../../../utils';

// @Components
import DataBox from '../../../components/DataBox';

// @Interfaces and Types
import { ExpenseType } from '../types';

interface IProps {
  data: ExpenseType;
  handlePayExpense: (id: string, amount: number, date: Date | string) => void;
}

export default function CardExpense({ data, handlePayExpense }: IProps) {
  const {
    id,
    total,
    code,
    otherCharges,
    iva,
    subTotal,
    date,
    supplierName,
    // Custom data
    // payments,
  } = data;

  const { t } = useTranslation('common');

  return (
    <Card
      sx={{
        mb: 5,
        mx: 'auto',
        width: { xs: '80%', sm: '60%', md: '88%', lg: '80%', xl: '65%' },
        boxShadow: '0 0 6px 2px #00000025',
      }}
    >
      <CardHeader
        avatar={<PaidOutlinedIcon color='success' sx={{ fontSize: '2.5rem' }} />}
        sx={{
          p: 1,
          m: '0 !important',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderBottom: 'thin solid #00000025',
        }}
        title={
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography variant='h4' sx={{ color: ({ palette }) => palette.success.main, fontSize: '1.5rem' }}>
              {`$${formatNumber(total || 0)}`}
            </Typography>

            {/* <Button
              variant='outlined'
              color='primary'
              sx={{
                p: 0,
                borderWidth: 2.5,
                fontWeight: 600,
                ':hover': {
                  color: ({ palette }) => palette.primary.contrastText,
                  backgroundColor: ({ palette }) => palette.primary.main,
                },
              }}
            >
              {payments.length}
            </Button> */}
          </Box>
        }
      />

      <CardContent sx={{ pb: '16px !important', m: 0, display: 'flex', flexDirection: 'column', gap: 0.25 }}>
        <DataBox title={t('general.supplier')} text={supplierName} sx={{ justifyContent: 'space-between' }} />
        <DataBox
          title={t('general.date')}
          sx={{ justifyContent: 'space-between' }}
          text={date && `${new Date(date).getMonth() + 1}/${new Date(date).getDate()}/${new Date(date).getFullYear()}`}
        />
        <DataBox title={t('general.invoice')} text={code} sx={{ justifyContent: 'space-between' }} />
        <DataBox
          title={t('expenses.accountalien')}
          text={otherCharges ? t('yes') : t('no')}
          sx={{ justifyContent: 'space-between' }}
        />
        <DataBox title={t('general.subTotal')} text={`$${subTotal}`} sx={{ justifyContent: 'space-between' }} />
        <DataBox title={t('general.iva  ')} text={`$${iva}`} sx={{ justifyContent: 'space-between' }} />
        <DataBox title={t('general.total')} text={`$${subTotal + iva}`} sx={{ justifyContent: 'space-between' }} />

        <Divider sx={{ width: '90%', my: 2, mx: 'auto' }} />

        <IconButton
          color='primary'
          sx={{
            mx: 'auto',
            px: 4,
            width: 'max-content',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-evenly',
            borderRadius: '0.25rem',
            fontWeight: 600,
          }}
          onClick={() => handlePayExpense(id, total, date && new Date(date))}
        >
          <AddIcon />
          <CreditCardIcon />
        </IconButton>
      </CardContent>
    </Card>
  );
}
