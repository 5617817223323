// @Utils
import i18n from '../../../i18n';
import { axiosEstimates } from '../../../utils';

// @Interfaces and Types
import { ITransportationNameRequest } from '../../../models';

const getCategoryItems = async (value: string) => {
  try {
    const response = await axiosEstimates.get<ITransportationNameRequest>(
      `transportation-by-name?name=${value}&language=${i18n.language}`,
      { headers: { 'x-module': 'services' } },
    );

    return response.data.getTransportation;
  } catch (err) {
    console.error(err);
    return null;
  }
};

export const getSupplierCategories = async (value: string, supplierId: number) => {
  try {
    const response = await axiosEstimates.get<ITransportationNameRequest>(
      `supplier-transportations?name=${value}&language=${i18n.language}&supplier=${supplierId}`,
      { headers: { 'x-module': 'services' } },
    );

    return response.data.getTransportation;
  } catch (err) {
    console.error(err);
    return null;
  }
};

export default getCategoryItems;
