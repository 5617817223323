// @Library
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

// @Translations
import { useTranslation } from 'react-i18next';

// @Models
import { ServiceTypeForm } from '../../../models';
import { ServiceCategoryModel } from '../../../models/categories';
import toast from 'react-hot-toast';

// @Utils
import { axiosEstimates } from '../../../utils/api';
import { AxiosError } from 'axios';
interface IParams {
  addState: (data: ServiceCategoryModel) => void;
  editState: (data: ServiceCategoryModel) => void;
  category?: ServiceCategoryModel;
}

const schemaValidation = yup
  .object()
  .shape({
    name: yup.string().required('service.serviceType'),
    nameEN: yup.string().required('service.serviceTypeEN'),
  })
  .required();

export function useServiceCategoryForm({ addState, category, editState }: IParams) {
  const { t } = useTranslation(['common']);

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm<ServiceTypeForm>({
    defaultValues: { name: '', nameEN: '', transportations: [] },
    resolver: yupResolver(schemaValidation),
  });

  const onSubmit = async ({ name, nameEN }: ServiceTypeForm) => {
    try {
      const body = { name, nameEN };

      if (!category) {
        try {
          const response = await axiosEstimates.post<ServiceCategoryModel>('transportation/register', body, {
            headers: {
              'x-module': 'services',
            },
          });
          addState(response.data);
        } catch (error) {
          const err = error as AxiosError;
          if (err.response?.status === 409) {
            toast.error(t((err?.response?.data as { error: string })?.error));
          }
        }
      } else {
        const response = await axiosEstimates.put<ServiceCategoryModel>('transportation/' + category.id, body);
        editState(response.data);
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (category) {
      reset({ name: category.name, nameEN: category.nameEN });
    }
  }, [category]);

  return {
    control,
    onSubmit: handleSubmit(onSubmit),
    errors,
    t,
  };
}
