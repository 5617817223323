import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';

// @Validations
import { categoryValidationSchema } from '../validations';

// @Utils
import { axiosEstimates } from '../../../utils';
import CustomSwalAlert from '../../../utils/CustomSwalAlert';

// @Hooks
import useCategory from './useCategory';
import { useServices } from './useServices';

// @Interfaces and Types
import { CategoryFormValues, Category } from '../def';

export default function useCategoryForm() {
  const [forCreateCategory, setForCreateCategory] = useState(false);
  const [categoryId, setCategoryId] = useState<number | null>(null);

  const { setReloadCategories } = useCategory();
  const { setReloadData, setCateriesForEdit } = useServices();

  const { t } = useTranslation('common');

  const { control, handleSubmit, reset, setValue, clearErrors } = useForm<CategoryFormValues>({
    resolver: yupResolver(categoryValidationSchema),
    defaultValues: { name: '', nameEN: '' },
  });

  const toggleForm = (id?: number, data?: CategoryFormValues) => {
    setForCreateCategory(!forCreateCategory);

    if (id && data) {
      setCategoryId(id);
      reset(data);
    } else {
      setCategoryId(null);
      clearForm();
    }
  };

  const clearForm = () => {
    setValue('name', '');
    setValue('nameEN', '');
    clearErrors();
  };

  const onSubmit = handleSubmit(async data => {
    let message = '';

    try {
      if (categoryId) {
        const { status } = await axiosEstimates.put(`api/services/category/update/${categoryId}`, data);

        setCateriesForEdit((prev: Category[]) => {
          return prev.map((category: Category) => {
            if (category.id === categoryId) {
              return { ...category, ...data };
            }
            return category;
          });
        });

        if (status === 200) message = t('services.alerts.updatedCategory');
      } else {
        const { status } = await axiosEstimates.post('api/services/category', data);
        if (status === 200) message = t('services.alerts.createdCategory');
      }
    } catch (error) {
      console.error(error);
    } finally {
      setCategoryId(null);
      setReloadData(true);
      setForCreateCategory(false);
      setTimeout(() => {
        setReloadCategories(true);
      }, 500);
      clearForm();
      CustomSwalAlert(t('general.success'), message, 'success', false);
    }
  });

  return { control, onSubmit, forCreateCategory, setForCreateCategory, toggleForm, categoryId };
}
