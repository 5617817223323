import * as yup from 'yup';
import { ManifiestoMaritimo } from '../../../../../models';
export const initialValues: ManifiestoMaritimo = {
  expeditionDate: '',
  numberID: '',
  agent: '',
  carrier: '',
  shipmentPort: '',
  arrivalPort: '',
  depeartureDate: '',
  arrivalDate: '',
  trip: '',
  entry: '',
  destiny: '',
  consignee: '',
  content: [],
  contentDescription: '',
};

export const schemaValidation = yup
  .object()
  .shape({
    expeditionDate: yup.string().required('required.general'),
    numberID: yup.string().required('required.general').max(10),
    agent: yup.string().required('required.general'),
    carrier: yup.string().required('required.general'),
    shipmentPort: yup.string().required('required.general'),
    arrivalPort: yup.string().required('required.general'),
    depeartureDate: yup.string().required('required.general'),
    arrivalDate: yup.string().required('required.general'),
    trip: yup.string().required('required.general'),
    entry: yup.string().required('required.general'),
    destiny: yup.string().required('required.general'),
    consignee: yup.string().required('required.general'),
    contentDescription: yup.string().notRequired(),

    content: yup.array().of(
      yup.object().shape({
        cnt: yup
          .string()
          .optional()
          .nullable()
          .transform(value => (isNaN(value) ? value : value)),
        bl: yup
          .string()
          .optional()
          .nullable()
          .transform(value => (isNaN(value) ? value : value)),
        provider: yup
          .string()
          .optional()
          .nullable()
          .transform(value => (isNaN(value) ? value : value)),
        totalPackages: yup
          .number()
          .optional()
          .nullable()
          .transform(value => (isNaN(value) ? null : value)),
        weight: yup
          .number()
          .optional()
          .nullable()
          .transform(value => (isNaN(value) ? null : value)),
        volumen: yup
          .number()
          .optional()
          .nullable()
          .transform(value => (isNaN(value) ? null : value)),
        invoices: yup
          .string()
          .optional()
          .nullable()
          .transform(value => (isNaN(value) ? value : value)),
        contentDescription: yup.string().required('required.general'),
      }),
    ),
  })
  .required();
