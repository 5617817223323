// @Mui
import { Box, Button } from '@mui/material';
import { Icon } from '@iconify/react';
import { DataGrid } from '@mui/x-data-grid';

// @Hooks
import { useSendEmail } from './send-emails.hook';

// @Components
import { SearchField } from '../../../components';
import ContactModal from './components/contacts-modal';
import Pagination from '../../../components/Pagination';
import LocalizedTextMap from '../../../translations/LocalizedESTextMap';

// @Utils
import { defaultColumns } from './utils/view-columns';
import DataGridStyles from '../../../utils/DataGridStyles';

export const SendEmails = () => {
  const {
    t,
    servBySupp,
    handleFilter,
    page,
    pageSize,
    selectRows,
    selectedRows,
    setPage,
    setPageSize,
    open,
    handleShow,
    value,
  } = useSendEmail();

  // No devuelve columnas de tipo de servicio
  const columns = defaultColumns().filter(column => column.field !== 'serviceTypeName');

  return (
    <Box style={{ width: '100%', height: '100%' }}>
      <Box>
        <Box style={{ height: '100%' }}>
          <Box sx={{ p: 1, display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'space-between' }}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
              <SearchField
                value={value}
                handleFilter={handleFilter}
                placeholder={t('general.search', { ns: 'common' })}
              />
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                size='medium'
                sx={{ m: 2 }}
                variant='contained'
                onClick={() => handleShow(!open)}
                disabled={selectedRows.length === 0}
                startIcon={<Icon icon='ri:mail-send-line' />}
              >
                {t('general.continue')}
              </Button>
            </Box>
          </Box>

          <div style={{ height: 400, width: '100%' }}>
            <DataGrid
              rows={servBySupp}
              checkboxSelection
              disableSelectionOnClick
              columns={columns as any}
              page={page}
              pageSize={pageSize}
              localeText={LocalizedTextMap()}
              sx={{ ...DataGridStyles }}
              onSelectionModelChange={newSelection => {
                selectRows(newSelection);
              }}
              selectionModel={selectedRows}
              hideFooter
            />
          </div>
        </Box>

        <Pagination
          count={servBySupp.length}
          setPage={setPage}
          setPageSize={setPageSize}
          page={page}
          pageSize={pageSize}
        />
      </Box>

      <ContactModal
        open={open}
        handleClose={() => handleShow(false)}
        serviceIds={selectedRows}
        cleanServices={() => selectRows([])}
      />
    </Box>
  );
};
