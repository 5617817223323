// @Library
import { Control, useWatch, UseFormSetValue } from 'react-hook-form';
import { useEffect, useState } from 'react';

// @Models
import { IShipmentForm, ISelectInput } from '../../../../models';

// @Components
import TextForm from '../../../../components/TextForm';
import NumberField from '../../../../components/NumberField';
import IntegerField from '../../../../components/FormattedInputs';
import SelectInput from '../../../../components/SelectInput';
import ReactSelectForm from '../../../../components/ReactSelect';

// @Mui
import Grid from '@mui/material/Grid';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';

// @Utils
import { useItems } from '../utils/items';
import { useTranslation } from 'react-i18next';

interface IProps {
  control: Control<IShipmentForm, any>;
  countryItems: ISelectInput<string | null>[];
  setValue: UseFormSetValue<IShipmentForm>;
  service?: IShipmentForm;
  servicesCount: number;
  edit?: boolean;
  count: number;
  budgetType: number;
}

interface ITypeUnit {
  value: string;
  label: string;
  scale: number;
  scaleArea: number;
  size: string;
  weight: string;
  area: string;
}

export function Shipment({ control, countryItems, setValue, service, count, budgetType }: IProps) {
  const { typeUnit, taxationItem } = useItems();

  const [unit, setUnit] = useState<ITypeUnit | undefined>();

  const { t } = useTranslation('common');

  const length = useWatch({ control, name: 'length' });
  const high = useWatch({ control, name: 'high' });
  const width = useWatch({ control, name: 'width' });
  const unitOfMeasurement = useWatch({ control, name: 'unitOfMeasurement' });

  useEffect(() => {
    const weight = length * high * width;

    if (unit) {
      setValue('volumetricWeight', Math.ceil(weight / unit.scale));
      setValue('area', Math.ceil(weight / unit.scaleArea));
    }
  }, [length, high, width]);

  useEffect(() => {
    const unit = typeUnit.find(unit => unit.value === unitOfMeasurement);

    setUnit(unit);
    setValue('high', service?.high || 0);
    setValue('length', service?.length || 0);
    setValue('width', service?.width || 0);
    setValue('physicalWeight', service?.physicalWeight || 0);
  }, [unitOfMeasurement]);

  return (
    <Grid container xs={12}>
      <CardHeader
        title={t('budgetPage.detailssend')}
        titleTypographyProps={{ variant: 'h6' }}
        sx={{ display: 'flex', textAlign: 'center', width: '100%' }}
      />

      <Divider sx={{ margin: 0 }} />

      <Grid container spacing={4} sx={{ my: 1 }}>
        <Grid item xs={12} sm={6}>
          <ReactSelectForm
            control={control}
            name='origin'
            label={t('select.origin')}
            items={countryItems}
            translationFile='validations'
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <ReactSelectForm
            control={control}
            name='destiny'
            label={t('select.destiny')}
            items={countryItems}
            translationFile='validations'
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextForm
            control={control}
            name='addressOrigin'
            label={t('shipment.addressOrigin')}
            translationFile='validations'
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextForm
            control={control}
            name='addressDestiny'
            label={t('shipment.addressDestiny')}
            translationFile='validations'
          />
        </Grid>

        <Grid item xs={8}>
          <TextForm control={control} name='content' label={t('general.content')} translationFile='validations' />
        </Grid>
        <Grid item xs={4}>
          <TextForm control={control} name='serial' label={t('general.reference')} translationFile='validations' />
        </Grid>
        <Grid item xs={12} sm={4}>
          <NumberField control={control} name='insurance' label={`${t('shipment.insurance')}`} />
        </Grid>

        <Grid item xs={12} sm={4}>
          <SelectInput
            control={control}
            name='unitOfMeasurement'
            label={t('shipment.unitOfMeasurement')}
            items={typeUnit}
            disabled={budgetType === 2 && count > 0}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <SelectInput control={control} name='taxationTo' label={t('shipment.taxationTo')} items={countryItems} />
        </Grid>

        <Grid item xs={12} sm={4}>
          <IntegerField
            control={control}
            name='length'
            label={`${t('shipment.length')} (${unit?.size || ''})`}
            disabled={budgetType === 2 && count > 0}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <IntegerField
            control={control}
            name='high'
            label={`${t('shipment.high')} (${unit?.size || ''})`}
            disabled={budgetType === 2 && count > 0}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <IntegerField
            control={control}
            name='width'
            label={`${t('shipment.width')} (${unit?.size || ''})`}
            disabled={budgetType === 2 && count > 0}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <IntegerField
            control={control}
            name='physicalWeight'
            label={`${t('shipment.physicalWeight')} (${unit?.weight || ''})`}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <IntegerField control={control} name='area' label={`${t('shipment.area')} (${unit?.area || ''})`} disabled />
        </Grid>

        <Grid item xs={12} sm={4}>
          <IntegerField
            control={control}
            name='volumetricWeight'
            label={`${t('shipment.volumetricWeight')} (${unit?.weight || ''})`}
            disabled
          />
        </Grid>

        <Grid item xs={12}>
          <TextForm control={control} name='comments' label={t('general.comments')} multiline minRows={4} />
        </Grid>
      </Grid>
    </Grid>
  );
}
