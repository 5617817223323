import { Modal, Box, Typography, Divider } from '@mui/material';
import { useServices } from '../hooks/useServices';
import { useTranslation } from 'react-i18next';

export default function ViewService() {
  const { toggleViewModal, handleView, serviceViewData } = useServices();
  const { t } = useTranslation(['common']);

  return (
    <Modal
      open={toggleViewModal}
      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
      onClose={async () => await handleView()}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          width: { xs: 300, sm: 400 },
          backgroundColor: 'white',
          p: 3,
        }}
      >
        <Typography
          id='modal-modal-title'
          variant='h5'
          fontWeight={'bold'}
          sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 1, mb: 2 }}
        >
          {t('services.details.title')}
        </Typography>

        <Divider sx={{ width: '93.5%', mx: 'auto', my: 2 }} />

        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
          <Typography>
            <span style={{ fontWeight: 'bold' }}>{t('services.details.createdBy')}:</span> {serviceViewData?.createdBy}
          </Typography>
          <Typography>
            <span style={{ fontWeight: 'bold' }}>{t('services.details.email')}:</span> {serviceViewData?.email}
          </Typography>
          <Typography>
            <span style={{ fontWeight: 'bold' }}>{t('services.details.createdAt')}:</span> {serviceViewData?.createdAt}
          </Typography>
          <Typography>
            <span style={{ fontWeight: 'bold' }}>{t('services.details.updatedAt')}:</span> {serviceViewData?.updatedAt}
          </Typography>
        </Box>
      </Box>
    </Modal>
  );
}
