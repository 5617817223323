import { format } from 'date-fns';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

// @MUI Components
import { Box, Collapse, IconButton, Table, TableCell, TableHead, TableRow, Typography, TableBody } from '@mui/material';

// @Icons
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import NoteEditOutline from 'mdi-material-ui/NoteEditOutline';

// @Redux
import { RootState } from '../../../../../../redux/reducers';
import { useSelector } from 'react-redux';
import { Icon } from '@iconify/react';

// @Utils
import { formatNumber } from '../../../../../../utils';

// @Components
import ColumHeader from '../../../../../../components/TableColumnHeader';

// @Interfaces and Types
import { IQuotesOfSuppliers } from '../../../../../../models';
import i18n from '../../../../../../i18n';
interface IProps {
  row: IQuotesOfSuppliers;
  index: number;
  setSelectedRow: any;
  selectedRow: any;
  idSupplier: string;
  permissions: any;
}

export default function Row({ row, index, setSelectedRow, selectedRow, idSupplier, permissions }: IProps) {
  const { user } = useSelector((state: RootState) => state);

  const { t } = useTranslation('common');

  const isRowSelected = selectedRow === index;

  const handleClick = () => setSelectedRow(isRowSelected ? null : index);

  return (
    <Fragment>
      <TableRow>
        <TableCell>
          <IconButton aria-label='expand row' size='small' onClick={handleClick}>
            {isRowSelected ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>

        <TableCell component='th' scope='row' sx={{ textAlign: 'center' }}>
          {row.quotationCode}
        </TableCell>

        <TableCell align='center'>
          {row.countryOrigin}/{row.countryDestiny}
        </TableCell>

        <TableCell align='center'>{row.transportTime}</TableCell>
        <TableCell align='center'>{row.comission}%</TableCell>
        <TableCell align='center'>{format(new Date(row.expDate), 'MM/dd/yyyy')}</TableCell>

        {/* Actions */}
        <TableCell align='center'>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            {permissions.edit_service && (
              <Link to={`/suppliers/${row.id}/budget/${idSupplier}`}>
                <IconButton name='general.edit' title={`${t('general.edit')}`}>
                  <NoteEditOutline fontSize='inherit' color='success' />
                </IconButton>
              </Link>
            )}

            {permissions.service_history && (
              <Link to={`/suppliers/details/history/${row.id}`}>
                <IconButton title={`${t('general.view')} ${t('general.history')}`}>
                  <Icon icon='mdi:clipboard-text-history-outline' height={22} color='#00f0ff' />
                </IconButton>
              </Link>
            )}
          </Box>
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0, margin: '0 auto', width: '90%' }} colSpan={6}>
          <Collapse in={isRowSelected} timeout='auto' unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant='h6' gutterBottom component='div' align='center'>
                {t('general.services')}
              </Typography>

              <Table stickyHeader aria-label='collapsible table' sx={{ ml: 5 }}>
                <TableHead sx={{ backgroundColor: 'primary.main' }}>
                  <ColumHeader
                    headers={[
                      t('general.service'),
                      t('general.cost'),
                      t('general.suggestedprice'),
                      t('general.commission'),
                      t('general.typeSale'),
                    ]}
                  />
                </TableHead>

                {row.services.map(service => (
                  <TableBody key={service.id}>
                    <TableRow>
                      <TableCell align='center'>
                        {i18n.language === 'es' ? service.service : service.serviceEN}
                      </TableCell>
                      <TableCell align='center'>{formatNumber(service.cost)}</TableCell>
                      <TableCell align='center'>{formatNumber(service.salePrice)}</TableCell>
                      <TableCell align='center'>{service.comission}%</TableCell>
                      <TableCell align='center'>{service.saleType ? t(`saleType.${service.saleType}`) : ''}</TableCell>
                    </TableRow>
                  </TableBody>
                ))}
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  );
}
