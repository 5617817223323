import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import moment from 'moment'; // deprecated
import toast from 'react-hot-toast';
import Swal from 'sweetalert2';

// @Redux
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/reducers';

// @Models
import { IClientTabForm } from '../../../models';

// @Utils
import { axiosEstimates } from '../../../utils/api';
import CustomSwalAlert from '../../../utils/CustomSwalAlert';

interface IProps {
  data: IClientTabForm;
  budgetStatus: string;
  idBudget: number;
  handleChange: (newValue: number) => void;
  updateDateOfExpiration(date: string): void;
  budgetHaveBill: boolean;
}

const type = {
  1: '/budgets',
  2: '/ctis',
  3: '/budgets-from-us',
};

export function useClientTab({ data, idBudget, handleChange, updateDateOfExpiration, budgetHaveBill }: IProps) {
  const { control, setValue, handleSubmit } = useForm<IClientTabForm>({
    defaultValues: data,
  });
  const navigate = useNavigate();

  const { t } = useTranslation(['common']);

  const { role } = useSelector((state: RootState) => state.user);

  const today = new Date();

  const expDaysChange = (value: unknown) => {
    let expDays = value as number;
    if (expDays < 0) expDays = 0;

    const date = moment(today).add(expDays, 'days');
    setValue('dateOfExpiration', date.format('YYYY/MM/DD HH:mm:ss'));
    setValue('daysOfExpiration', expDays);
  };

  const expDateChange = (value: unknown) => {
    const date = value as string;
    const startDate = moment(today);
    const endDate = moment(date);
    const diffDays = endDate.diff(startDate, 'days');

    if (!isNaN(diffDays)) setValue('daysOfExpiration', diffDays);

    setValue('dateOfExpiration', date);
  };

  const onSubmit = (data: IClientTabForm) => {
    if (data.daysOfExpiration <= 1) {
      toast.error(t('errors.minExpDay'));
      return null;
    }

    updateDateOfExpiration(data.dateOfExpiration);

    const { dateOfExpiration, incoterms } = data;

    const callback = async () => {
      Swal.fire({
        title: t('general.sure', { ns: 'validations' }) || '',
        text: t('alert.budgetedit', { ns: 'common' }) || '',
        showDenyButton: true,
        icon: 'warning',
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: t('general.save', { ns: 'common' }) || '',
        confirmButtonColor: '#56CA00',
        denyButtonText: t('general.continue', { ns: 'common' }) || '',
        denyButtonColor: '#16B1FF',
        cancelButtonColor: '#FF4C51',
        cancelButtonText: t('general.cancel', { ns: 'common' }) || '',
      }).then(result => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          (async () => {
            const toastId = toast.loading(t('toast.saved'));
            await axiosEstimates.put('budget-data/' + idBudget, { dateOfExpiration, incoterms });
            setTimeout(() => {
              toast.dismiss(toastId);
              toast.success(t('toast.success'));
              navigate(type[data.type as keyof typeof type]);
            }, 1000);
          })().catch(() => {
            toast.error(t('toast.error'));
          });
        } else if (result.isDenied) {
          (async () => {
            const toastId = toast.loading(t('toast.saved'));

            await axiosEstimates.put('budget-data/' + idBudget, { dateOfExpiration, incoterms });

            handleChange(2);

            setTimeout(() => {
              toast.dismiss(toastId);
              toast.success(t('toast.success'));
            }, 1000);
          })().catch(() => {
            toast.error(t('toast.error'));
          });
        }
      });
    };

    if (budgetHaveBill) {
      CustomSwalAlert(t('toast.warning'), t('budget.confirmEdit', { ns: 'validations' }), 'warning', true, callback);
    } else callback();
  };

  useEffect(() => {
    expDateChange(data.dateOfExpiration);
    updateDateOfExpiration(data.dateOfExpiration);
  }, []);

  return { t, control, onSubmit: handleSubmit(onSubmit), role, today, expDaysChange, expDateChange };
}
