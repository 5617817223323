import { useTranslation } from 'react-i18next';

// @MUI Components
import { Tabs, Tab, Box } from '@mui/material';

// @Interfaces and Types
interface IProps {
  currentTab: 'payments' | 'expenses' | 'commissions';
  canSeePayments: boolean;
  canSeeExpenses: boolean;
  canSeeCommissions: boolean;
  handleTabs: (event: React.SyntheticEvent, newValue: 'payments' | 'expenses' | 'commissions') => void;
}

export default function CustomTabs({
  currentTab,
  handleTabs,
  canSeePayments,
  canSeeExpenses,
  canSeeCommissions,
}: IProps) {
  const { t } = useTranslation('common');

  return (
    <Box sx={{ width: '100%' }}>
      <Tabs
        value={currentTab}
        onChange={handleTabs}
        textColor='primary'
        indicatorColor='primary'
        aria-label='payments-list-tabs'
        sx={{ borderBottom: 'thin solid #ccc' }}
      >
        {canSeePayments && <Tab value='payments' label={t('general.payments')} />}
        {canSeeExpenses && <Tab value='expenses' label={t('general.expenses')} />}
        {canSeeCommissions && <Tab value='commissions' label={t('general.commission')} />}
      </Tabs>
    </Box>
  );
}
