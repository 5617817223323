// @Utils
import { axiosEstimates } from '../../../utils';

// @Interfaces and Types
import { IServiceCategoryRequest } from '../../../models/categories';

const getCategories = async (filter: string) => {
  const response = await axiosEstimates.get<IServiceCategoryRequest>(`/transportation-by-name?name=${filter}`, {
    headers: { 'x-module': 'services' },
  });

  const { status, statusText, data } = response;

  if (status === 200) return data;
  else if (status === 401) throw response.data;
  else throw new Error(`${status} - ${statusText || 'Unexpected error'} `);
};

export default getCategories;
