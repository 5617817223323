import { useTranslation } from 'react-i18next';

// @Hooks
import { useTypeServiceForm } from './typeservice-form.hook';

// @MUI Components
import { Box, Button, Grid } from '@mui/material';

// @Components
import { TextForm, MultipleTransportationFilter } from '../../../components';

// @Interfaces and Types
import { ServiceTypeModel } from '../../../models';
interface IProps {
  handleRender: (render: boolean) => void;
  addState: (data: ServiceTypeModel) => void;
  editState: (data: ServiceTypeModel) => void;
  service?: ServiceTypeModel;
}

export function ServiceTypeForm({ handleRender, addState, service, editState }: IProps) {
  const { onSubmit, control } = useTypeServiceForm({ addState, service, editState });

  const { t } = useTranslation('common');

  return (
    <form onSubmit={onSubmit}>
      <Grid container sx={{ display: 'flex', justifyContent: { xs: 'center', md: 'space-between' }, gap: 3 }}>
        <Grid item xs={12} sm={8} md={4}>
          <MultipleTransportationFilter
            fullWidth
            control={control}
            name='transportations'
            label={t('general.category')}
          />
        </Grid>

        <Grid item xs={12} sm={8} md={3.5}>
          <TextForm
            fullWidth
            control={control}
            name='name'
            label={t('services.titletype')}
            translationFile='validations'
          />
        </Grid>

        <Grid item xs={12} sm={8} md={3.5}>
          <TextForm
            fullWidth
            control={control}
            name='nameEN'
            label={t('services.typeEN')}
            translationFile='validations'
          />
        </Grid>

        <Box sx={{ width: '100%', display: 'flex', justifyContent: { xs: 'center', md: 'flex-end' } }}>
          <Button type='button' sx={{ mr: 3 }} variant='contained' color='error' onClick={() => handleRender(false)}>
            {t('general.cancel')}
          </Button>

          <Button type='submit' variant='contained' color='info'>
            {t('general.save')}
          </Button>
        </Box>
      </Grid>
    </form>
  );
}
