import * as yup from 'yup';
import { CFFCaptureData } from '../../../models/Models';

export const defaultCCFValues: CFFCaptureData = {
  customerPayments: '',
  condition: '',
  deliveryby: {
    name: '',
    DUI: '',
  },
  receivedby: {
    name: '',
    DUI: '',
  },
};
export const CCFCreateToValidationsSchema = yup.object().shape({
  customerPayments: yup.string().required('general.required'),
  condition: yup.string().required('general.required'),
  deliveryby: yup
    .object()
    .shape({
      name: yup.string().required('general.required'),
      DUI: yup.string().required('general.required'),
    })
    .required('general.required'),
  receivedby: yup
    .object()
    .shape({
      name: yup.string().required('general.required'),
      DUI: yup.string().required('general.required'),
    })
    .required('general.required'),
});

export const CCFCreateConditionValidationSchema = yup.object().shape({
  condition: yup.string().required('general.required'),
});
