import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// @Icon imports
import { Group } from 'grommet-icons';
import HomeOutline from 'mdi-material-ui/HomeOutline';
import FileDocumentOutline from 'mdi-material-ui/FileDocumentOutline';
import FileDocument from 'mdi-material-ui/FileDocument';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import DifferenceOutlinedIcon from '@mui/icons-material/DifferenceOutlined';
// import RequestQuoteOutlinedIcon from '@mui/icons-material/RequestQuoteOutlined';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import HandymanOutlinedIcon from '@mui/icons-material/HandymanOutlined';
import CalculateOutlinedIcon from '@mui/icons-material/CalculateOutlined';
import SettingsSuggestOutlinedIcon from '@mui/icons-material/SettingsSuggestOutlined';

// @Redux
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/reducers';
import { useAppDispatch } from '../../../redux/store';
import { initialNotifications } from '../../../redux/slices/notifications';

// @Interfaces
import { Item } from '../../../models/items';

// @Utils
import { socket } from '../../../utils/sockets.util';
import { axiosEstimates } from '../../../utils/api';

export const useNavigation = () => {
  const [permissions, setPermissions] = useState<string[]>([]);

  const { role, user, idCRM } = useSelector((state: RootState) => state.user);
  const { requestsCount } = useSelector((state: RootState) => state.notifications);
  const notificationsState = useSelector((state: RootState) => state.notifications);

  const { t } = useTranslation(['common']);

  const dispatch = useAppDispatch();

  const handleModules = async () => {
    const res = await axiosEstimates.get('api/userData/navigation');
    setPermissions(res.data);
  };

  const getServicesCount = async () => {
    const { data } = await axiosEstimates.get<{ requestsCount: number }>(
      `budget-requeted/count?role=${role}&idUser=${user?.id}`,
      { headers: { 'x-module': 'requested_quotes' } },
    );
    dispatch(initialNotifications({ requestsCount: data.requestsCount }));
  };

  useEffect(() => {
    if (idCRM) {
      handleModules();
      getServicesCount();

      socket.on('request:count', async () => {
        getServicesCount();
      });

      return () => {
        socket.off('request:count');
        // dispatch(clearNotifications());
      };
    }
  }, [idCRM]);

  const items: Item[] = [
    {
      title: `${t('general.home')}`,
      icon: HomeOutline,
      notRender: !permissions.includes('home'),
      path: '/',
    },
    {
      title: `${t('title.send')}`,
      icon: MailOutlineIcon,
      path: '/email',
      notRender: !permissions.includes('blast_mail'),
    },
    // {
    //   sectionTitle: 'Apps & Pages',
    // },
    {
      title: `${t('general.request')}`,
      icon: DifferenceOutlinedIcon,
      path: '/requests',
      badgeContent: `${requestsCount}`,
      notRender: !permissions.includes('requested_quotes'),
      badgeColor: 'warning',
    },
    {
      title: `${t('general.budget')}`,
      icon: FileDocumentOutline,
      path: '/budgets',
      notRender: !permissions.includes('sv_quotes'),
    },
    {
      title: `${t('general.budgetFromUS')}`,
      icon: FileDocument,
      notRender: !permissions.includes('usa_cml_quotes') && !permissions.includes('usa_ctis_quotes'),
      children: [
        { title: 'CML', path: '/budgets-from-us', notRender: !permissions.includes('usa_cml_quotes') },
        { title: 'CTIS', path: '/ctis', notRender: !permissions.includes('usa_ctis_quotes') },
      ],
    },

    {
      title: `${t('general.invoices')}`,
      icon: RequestQuoteIcon,
      // icon: RequestQuoteOutlinedIcon,
      path: '/invoices',
      notRender: !permissions.includes('cml_sv_invoices') && !permissions.includes('cml_usa_invoices'),
      children: [
        { title: 'CML SV', path: '/invoices', notRender: !permissions.includes('cml_sv_invoices') },
        { title: 'CML US', path: '/invoices/us-cml', notRender: !permissions.includes('cml_usa_invoices') },
        { title: 'CML CTIS', path: '/invoices/ctis', notRender: !permissions.includes('ctis_invoices') },
      ],
    },
    // {
    //   title: `${t('general.usaInvoice')}`,
    //   icon: RequestQuoteIcon,
    //   notRender: role !== 'admin',
    //   path: '/invoices/us-cml',
    // },
    {
      title: `${t('general.expenses')}`,
      icon: CalculateOutlinedIcon,
      path: '/expenses',
      notRender: !permissions.includes('expenses'),
    },
    {
      title: `${t('general.services')}`,
      icon: SettingsSuggestOutlinedIcon,
      path: '/services',
      notRender: !permissions.includes('services'),
    },
    {
      title: `${t('general.suppliers')}`,
      icon: Group,
      path: '/suppliers',
      notRender: !permissions.includes('suppliers'),
    },
    {
      title: `${t('general.settings')}`,
      icon: HandymanOutlinedIcon,
      notRender: !permissions.includes('design_options') && !permissions.includes('tax_folios'), // Corrección aquí
      children: [
        {
          title: `${t('general.designOptions')}`,
          path: '/pdfSettings',
          notRender: !permissions.includes('design_options'),
        },
        {
          title: `${t('quoteSetting.title')}`,
          path: '/invoiceSettings',
          notRender: !permissions.includes('tax_folios'),
        },
      ],
    },
    /*     {
      title: `${t('general.designOptions')}`,
      icon: HandymanOutlinedIcon,
      path: '/pdfSettings',
      notRender: role !== 'admin',
    }, */
  ];

  return { items, permissions };
};
