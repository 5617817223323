// @Models
import { IShipmentEdit, IShipmentFormEdit } from '../../../../models';

export function addShipmentAdapter(shipment: IShipmentFormEdit): IShipmentEdit | null {
  if (!shipment.id) {
    return null;
  }
  return {
    id: shipment.id,
    shipmentId: shipment?.shipmentId || null,
    origin: shipment.origin,
    destiny: shipment.destiny,
    addressOrigin: shipment.addressOrigin,
    addressDestiny: shipment.addressDestiny,
    content: shipment.content,
    unitOfMeasurement: shipment.unitOfMeasurement,
    length: shipment.length,
    high: shipment.high,
    width: shipment.width,
    physicalWeight: shipment.physicalWeight,
    volumetricWeight: shipment.volumetricWeight,
    incoterms: shipment.incoterms,
    area: shipment.area,
    comments: shipment.comments,
    serial: shipment.serial,
    services: shipment.services,
    subTotal: shipment.services.reduce((prev, serv) => prev + serv.price, 0),
    ivaTotal: shipment.services.reduce((prev, serv) => prev + serv.amountOfIva, 0),
    total: shipment.services.reduce((prev, serv) => prev + serv.amountOfService, 0),
    insurance: shipment.insurance,
    taxationTo: shipment.taxationTo,
    idServiceType: shipment.serviceType,
    idTransportation: shipment.transportationId,
  };
}

export function editShipmentAdapter(shipment?: IShipmentEdit): IShipmentFormEdit | undefined {
  if (!shipment) {
    return undefined;
  }
  return {
    id: shipment.id,
    shipmentId: shipment.shipmentId,
    origin: shipment.origin,
    destiny: shipment.destiny,
    addressOrigin: shipment.addressOrigin,
    addressDestiny: shipment.addressDestiny,
    content: shipment.content,
    unitOfMeasurement: shipment.unitOfMeasurement,
    length: shipment.length,
    high: shipment.high,
    width: shipment.width,
    physicalWeight: shipment.physicalWeight,
    volumetricWeight: shipment.volumetricWeight,
    incoterms: shipment.incoterms,
    area: shipment.area,
    comments: shipment.comments,
    serial: shipment.serial,
    // amountOfIva: 0,
    // amountOfService: 0,
    // ivaCharge: false,
    // price: 0,
    // priceCost: 0,
    // serviceAmount: 0,
    // serviceId: null,
    // serviceName: '',
    serviceType: shipment.idServiceType,
    transportationId: shipment.idTransportation,
    services: shipment.services,
    // commission: 0,
    // priceCostWithoutVat: 0,
    insurance: shipment.insurance,
    taxationTo: shipment.taxationTo,
  };
}
