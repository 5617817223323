import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

// @Services
import getInvoice from './services/getInvoice';
import getCustomer from './services/getCustomer';
import getDocumentExpense from './services/getDocumentExpense';
import getInvoicePayments from './services/getInvoicePayments';
import sendPaymentFile from './services/sendPaymentFile';
import sendPayment from './services/sendPayment';
import editPayment from './services/editPayment';
import deletePayment from './services/deletePayment';
import getPaymentFile from './services/getPaymentFile';

// @Utils
import { schemaPayments } from './utils/schemaPayments';
import CustomSwalAlert from '../../utils/CustomSwalAlert';
import { initialIsError } from '../../utils/initialCommonStates';

// @Interfaces and Types
import { ExpenseDocument, PaymentType, PaymentTypeType, TabsType } from './types';
import { CustomFiles } from '../../models/CustomFiles';

// @Initial States
import {
  initialInvoiceData,
  initialLoadingProcess,
  initialPaymentsData,
  initialPayment,
  initialExpensesData,
  // initialExpense,
  initialUser,
} from './utils/initialStates';

export default function useInvoice() {
  // NOTE: PERMISSIONS
  const [permissions, setPermissions] = useState<string[]>([]);
  // NOTE: PAGINATION
  const [page, setPage] = useState(1);
  // NOTE: TABS
  const [currentTab, setCurrentTab] = useState<TabsType>('payments');
  // NOTE: SEARCH
  const [search, setSearch] = useState('');
  // NOTE: PAYMENTS LIST DROPDOWN
  const [paymentsIsOpen, setPaymentsIsOpen] = useState(false);
  // NOTE: STATUS
  const [isError, setIsError] = useState(initialIsError);
  const [isLoading, setIsLoading] = useState(true);
  const [loadingProcess, setLoadingProcess] = useState(initialLoadingProcess);
  // NOTE: DATA
  const [user, setUser] = useState(initialUser);
  const [invoiceData, setInvoiceData] = useState(initialInvoiceData);
  const [addPaymentTitle, setAddPaymentTitle] = useState({ name: '', ESname: '' });
  const [payments, setPayments] = useState(initialPaymentsData); // Current payments per page
  const [paymentsData, setPaymentsData] = useState(initialPaymentsData); // All payments
  const [paymentsFound, setPaymentsFound] = useState(initialPaymentsData); // Payments found
  const [addExpenseTitle, setAddExpenseTitle] = useState({ name: '', ESname: '' });
  const [expenses, setExpenses] = useState(initialExpensesData); // Current expenses per page
  const [expensesData, setExpensesData] = useState(initialExpensesData); // All expenses
  const [expensesFound, setExpensesFound] = useState(initialExpensesData); // Expenses found
  // NOTE: FORM
  const [isEditing, setIsEditing] = useState(false);
  const [formIsOpen, setFormIsOpen] = useState(false);
  const [paymentFile, setPaymentFile] = useState<CustomFiles>(null);
  const {
    control,
    reset,
    handleSubmit,
    watch,
    setValue,
    getValues,
    setError,
    formState: { errors },
  } = useForm<PaymentType>({
    defaultValues: initialPayment,
    resolver: yupResolver(schemaPayments),
  });

  const watchReceivedAmount = watch('receivedAmount');
  const watchLimitAmount = watch('limitAmount');

  const { t } = useTranslation('common');

  const { id } = useParams();

  const queryParams = new URLSearchParams(useLocation().search);

  const navigate = useNavigate();

  const PAGE_SIZE = 4;

  // NOTE: ALL ACTIONS
  // SUCCESS: MOSTRAR ALERTA DE WARNING PARA LAS VALIDACIONES MAS COMUNES
  const showSwalAlert = (title: string) => {
    return CustomSwalAlert(t('toast.warning'), title, 'warning', false);
  };

  // SUCCESS: OBTENER DATOS DE LA FACTURA/INVOICE/BILL
  const getInvoiceData = async () => {
    setIsLoading(true);

    try {
      if (!id) throw t('general.invoiceNotFound');

      // Req bill data
      const newInvoiceData = await getInvoice(id);

      if (newInvoiceData?.permissions.add_payment) setAddPaymentTitle(newInvoiceData.permissions.add_payment);
      if (newInvoiceData?.permissions.add_expense) setAddExpenseTitle(newInvoiceData.permissions.add_expense);

      setPermissions(Object.keys(newInvoiceData.permissions));

      setUser({ ...user, name: newInvoiceData.nameGeneratedBy });

      // Request bill customer data
      const customer = await getCustomer(newInvoiceData.budgetId);

      newInvoiceData.phone = customer.phone;
      newInvoiceData.email = customer.email;

      // Set total to pay
      newInvoiceData.totalToPay = newInvoiceData.totalSales;

      setInvoiceData({ ...invoiceData, ...newInvoiceData });

      // Request all payments
      const invoicePayments = await getInvoicePayments(id);

      setPaymentsData(invoicePayments);

      // EXPENSES' COMPLETE FIELDS "code, date, otherCharges AND supplierName"
      setExpensesData(
        newInvoiceData.expenses.map(expense => {
          getDocumentExpense(expense.idDocument)
            .then(({ expenseDocument }: { expenseDocument: ExpenseDocument }) => {
              if (expenseDocument) {
                expense.code = expenseDocument?.code || 'No code';
                expense.date = expenseDocument?.date || 'No date';
                expense.otherCharges = expenseDocument?.otherCharges;
                expense.supplierName = expenseDocument?.supplier.name;
                // Custom data
                // expense.payments = invoicePayments.filter(payment => Number(payment.id) === Number(expense.id));
              }
            })
            .catch(err => {
              console.error(err);

              setIsError({
                status: err.status || 400,
                kind: err.kind || err.typeError || 'unexpected',
                title: err.error || err.title || t('general.error'),
                des: err.message || err.description || err.response.data.message || t('general.errorUnexpected'),
              });
            });

          return expense;
        }),
      );
    } catch (err: any) {
      console.error(err);

      setIsError({
        status: err.status || 400,
        kind: err.kind || err.typeError || 'unexpected',
        title: err.error || err.title || t('general.error'),
        des: err.message || err.description || err.response.data.message || t('general.errorUnexpected'),
      });
    } finally {
      setLoadingProcess({ paymentsData: true, expensesData: true, invoiceData: true });
    }
  };

  // SUCCESS: VALIDATE PAYMENT FORM'S FIELDS
  const validateFields = () => {
    const { paymentMethod, bank, depositAccount, paymentDate, reference, receivedAmount } = getValues();

    // Essential validations
    if (invoiceData.totalToPay && Math.sign(invoiceData.totalToPay) !== 1) {
      return showSwalAlert(t('errors.payments.limitExceded'));
    }

    if (!paymentMethod) return showSwalAlert(t('errors.payments.paymentMethod'));

    if (paymentMethod !== 'cash') {
      if (!bank) {
        setError('bank', { type: 'required', message: 'errors.payments.bankRequired' });
        return showSwalAlert(t('errors.payments.bankRequired'));
      }
      if (!depositAccount) {
        setError('depositAccount', { type: 'required', message: 'errors.payments.depositAccountRequired' });
        return showSwalAlert(t('errors.payments.depositAccountRequired'));
      }
    }

    if (depositAccount && `${depositAccount}`.length !== 4) {
      setError('depositAccount', { type: 'validate', message: 'errors.payments.depositAccountInvalid' });
      return showSwalAlert(t('errors.payments.depositAccountInvalid'));
    }

    if (!paymentDate) return showSwalAlert(t('errors.require.date'));
    if (!reference) return showSwalAlert(t('errors.payments.referenceRequired'));
    if (!receivedAmount) return showSwalAlert(t('errors.payments.amountRequired'));

    if (receivedAmount > invoiceData.totalToPay) return showSwalAlert(t('errors.payments.amountLimit'));
  };

  // SUCCESS: CREATE OR EDIT A NEW PAYMENT
  const createPayment: SubmitHandler<PaymentType> = async data => {
    try {
      if (!id) throw t('general.invoiceNotFound');

      if (data.paymentMethod !== 'cash') {
        // Validate Bank
        if (!data.bank) {
          setError('bank', { message: 'errors.payments.bankRequired' });
          return showSwalAlert(t('errors.payments.bankRequired'));
        }

        // Validate Deposit account
        if (!data.depositAccount) {
          setError('depositAccount', { message: 'errors.payments.depositAccountRequired' });
          return showSwalAlert(t('errors.payments.depositAccountRequired'));
        }

        // Validate file
        if (!paymentFile && data.paymentMethod !== 'cash') return showSwalAlert(t('errors.payments.fileRequired'));
      }

      if (data.receivedAmount > watchLimitAmount) return showSwalAlert(t('errors.payments.amountLimit'));

      // if (!(paymentFile instanceof File && data.paymentMethod !== 'cash')) {
      //   return showSwalAlert(t('errors.payments.fileRequired'));
      // }

      // // Just validate file format
      // if (
      //   !paymentFile.type.includes('pdf') &&
      //   !paymentFile.type.includes('jpeg') &&
      //   !paymentFile.type.includes('jpg') &&
      //   !paymentFile.type.includes('png')
      // ) {
      //   return showSwalAlert(t('errors.payments.file'));
      // }

      // DATA PAYMENT
      const newPayment: PaymentType = { ...data, billId: Number(id), paymentDate: new Date(data.paymentDate) };

      let response: { data: PaymentType; message: string };

      isEditing ? (response = await editPayment(newPayment)) : (response = await sendPayment(newPayment));

      let haveFile = false;

      // SEND PDF OR IMAGE
      if (paymentFile && typeof paymentFile !== 'string') {
        const sendFileResponse = await sendPaymentFile(`${response.data.id}`, paymentFile as File);

        if (!sendFileResponse) {
          CustomSwalAlert(t('general.error'), t('errors.payments.fileError'), 'error', false);
        } else haveFile = true;
      } else if (typeof paymentFile === 'string') haveFile = true;

      response.data.user = user;
      response.data.haveFile = haveFile;

      setFormIsOpen(false);
      CustomSwalAlert(t('general.success'), t(response.message), 'success', false);

      if (isEditing) {
        setPaymentsData(paymentsData.map(payment => (payment.id === response.data.id ? response.data : payment)));
      } else setPaymentsData([...paymentsData, response.data]);
    } catch (error) {
      console.error(error);
    }
  };

  // NOTE: ALL HANDLE FUNCTIONS

  const handleToggleForm = () => setFormIsOpen(!formIsOpen);
  const handleTabs = (e: React.SyntheticEvent, newValue: TabsType) => {
    setCurrentTab(newValue);
  };
  const handlePage: (e: any, newPage: number) => void = (e: any, newPage: number) => setPage(newPage);
  const handleSearch = (e: any) => (e ? setSearch(e.target.value) : setSearch(''));

  const handlePayExpense = (id: string, amount: number, date: Date | string) => {
    setIsEditing(false);
    setFormIsOpen(true);

    setValue('id', Number(id));
    setValue('type', 'expense');
    setValue('paymentDate', date);

    const expensePayment = paymentsData.find(payment => payment.expenseId === id);

    if (expensePayment) {
      const expensePaymentTotal = paymentsData
        .filter(payment => payment.expenseId === id)
        .map(payment => payment.receivedAmount)
        .reduce((prev, current) => prev + current);

      setValue('receivedAmount', amount - expensePaymentTotal);
      setValue('limitAmount', amount - expensePaymentTotal);
    } else {
      setValue('receivedAmount', amount);
      setValue('limitAmount', amount);
    }

    setValue('expenseId', id);
  };

  // SUCCESS: SET PAYMENTS TO SHOW ON CURRENT PAGE
  const handlePagination = () => {
    const ITEMS_PER_PAGE: number = (page - 1) * PAGE_SIZE;

    if (!paymentsData.length) setPayments(initialPaymentsData);
    if (paymentsData?.length) {
      setPayments(paymentsData.slice(ITEMS_PER_PAGE, PAGE_SIZE + ITEMS_PER_PAGE));
    }

    if (!expensesData.length) setExpenses(initialExpensesData);
    if (expensesData?.length) {
      setExpenses(expensesData.slice(ITEMS_PER_PAGE, PAGE_SIZE + ITEMS_PER_PAGE));
    }
  };

  // SUCCESS: CALCULATE LIMIT AMOUNT PAYMENTS
  const handleLimitAmount = () => {
    if (!paymentsData?.length) {
      setInvoiceData({ ...invoiceData, totalPaid: 0, totalToPay: invoiceData.totalSales });
    } else {
      const totalPaymentsAmount =
        paymentsData
          .map(payment => payment.type === 'income' && payment.receivedAmount)
          .reduce((prev, next) => (prev || 0) + (next || 0)) || 0;

      if (isEditing) {
        // Obtenemos el monto del pago que estamos editando para sumarlo a "Por pagar/Total to pay"
        const paymentToEditAmount = paymentsData.find(payment => payment.id === getValues('id'));
        if (paymentToEditAmount && paymentToEditAmount.type !== 'expense') {
          setValue('limitAmount', invoiceData.totalSales - totalPaymentsAmount + paymentToEditAmount?.receivedAmount);
        }
      }

      setInvoiceData({
        ...invoiceData,
        totalPaid: totalPaymentsAmount,
        totalToPay: invoiceData.totalSales - totalPaymentsAmount,
      });
    }
  };

  // SUCCESS: OPEN FORM WITH PAYMENT'S INFORMATION TO EDIT
  const handleEditPayment = async (paymentId: number | undefined) => {
    if (!paymentId) return;

    const paymentToEdit = paymentsData.find(payment => payment.id === paymentId);

    if (!paymentToEdit) return;

    setValue('id', paymentToEdit.id);
    setValue('type', paymentToEdit.type);
    setValue('paymentMethod', paymentToEdit.paymentMethod);
    setValue('bank', paymentToEdit.bank);
    setValue('depositAccount', paymentToEdit.depositAccount);
    setValue('paymentDate', paymentToEdit.paymentDate);
    setValue('reference', paymentToEdit.reference);
    setValue('receivedAmount', paymentToEdit.receivedAmount);

    if (paymentToEdit.type === 'expense') {
      const expense = expensesData.find(expense => expense.id === paymentToEdit.expenseId);
      const expensePayments = paymentsData.filter(payment => payment.expenseId === expense?.id);

      const siblingPayments = expensePayments
        .filter(payment => payment.id !== paymentToEdit.id)
        .map(payment => payment.receivedAmount);

      const siblingPaymentsTotal =
        siblingPayments.length > 1 ? siblingPayments.reduce((prev, current) => prev + current) : siblingPayments[0];

      if (expense) setValue('limitAmount', expense?.total - (siblingPaymentsTotal || 0));
    }

    const paymentToEditFile = await getPaymentFile(Number(paymentToEdit.id));

    setPaymentFile(paymentToEditFile);

    setValue('user', paymentToEdit.user);

    setIsEditing(true);
    setFormIsOpen(true);
  };

  const handlePreviewFiles = async (paymentId: number) => {
    if (paymentId) return navigate(`/invoices/${id}/paymentFile/${paymentId}`);
  };

  // SUCCESS: DELETE A PAYMENT
  const handleDeletePayment = async (paymentId: number | undefined, type: PaymentTypeType) => {
    if (!paymentId) return;

    CustomSwalAlert(
      t('general.areYouSure'),
      type === 'income' ? t('general.deletePayment') : t('general.deleteExpense'),
      'question',
      true,
      async () => {
        const isDeleted = await deletePayment(paymentId);

        if (isDeleted) setPaymentsData(paymentsData.filter(payment => payment.id !== paymentId && payment));

        CustomSwalAlert(
          t('general.success'),
          type === 'income' ? t('general.paymentDeleted') : t('general.expenseDeleted'),
          'success',
          false,
        );
      },
    );
  };

  // NOTE: ALL USE EFFECTS
  useEffect(() => {
    getInvoiceData();
  }, []);

  useEffect(() => {
    if (permissions.includes('add_payment')) setCurrentTab('payments');
    else if (permissions.includes('add_expense')) setCurrentTab('expenses');
    else setCurrentTab('commissions');
  }, [permissions]);

  useEffect(() => {
    if (!formIsOpen) {
      reset();
      setPaymentFile(null);
      setIsEditing(false);
    } else !getValues('paymentDate') && setValue('paymentDate', new Date());
  }, [formIsOpen]);

  useEffect(() => {
    if (Object.keys(errors).length) validateFields();
  }, [errors]);

  useEffect(() => {
    handleLimitAmount();
  }, [paymentsData, isEditing]);

  useEffect(() => {
    handlePagination();
  }, [paymentsData, expensesData, page]);

  useEffect(() => {
    if ((loadingProcess.invoiceData && loadingProcess.expensesData && loadingProcess.paymentsData) || isError) {
      setIsLoading(false);

      if (queryParams.get('logsOpen')) {
        setPaymentsIsOpen(Boolean(queryParams.get('logsOpen')));

        const $logsListY = document.getElementById('logs-list')?.getBoundingClientRect().y;
        if ($logsListY) window.scrollTo({ behavior: 'smooth', top: $logsListY + 80 });
      }
    }
  }, [loadingProcess, isError]);

  useEffect(() => {
    if (search) {
      if (currentTab === 'payments') {
        const payments = paymentsData.filter(payment => {
          return payment.reference?.toLowerCase()?.includes(search.toLowerCase()) ? payment : false;
        });

        setPaymentsFound(payments);
      } else setPaymentsFound(initialPaymentsData);
    }

    if (currentTab === 'expenses') {
      const expenses = expensesData.filter(expense => {
        return expense.code?.toLowerCase()?.includes(search.toLowerCase()) ? expense : false;
      });

      setExpensesFound(expenses);
    } else setExpensesFound(initialExpensesData);

    handleLimitAmount();
  }, [search]);

  return {
    // NOTE: PERMISSIONS
    permissions,
    // NOTE: TRANSLATIONS
    t,
    // NOTE: PAGINATION
    page,
    PAGE_SIZE,
    // NOTE: TABS
    currentTab,
    // NOTE: SEARCH
    search,
    // NOTE: PAYMENTS LIST DROPDOWN
    paymentsIsOpen,
    setPaymentsIsOpen,
    // NOTE: STATUS
    isError,
    isLoading,
    // NOTE: DATA
    invoiceData,
    addPaymentTitle,
    payments,
    paymentsData,
    paymentsFound,
    addExpenseTitle,
    expenses,
    expensesData,
    expensesFound,
    // NOTE: FORMS TO ADD PAYMENT/EXPENSE
    control,
    setValue,
    formIsOpen,
    paymentFile,
    setPaymentFile,
    watchReceivedAmount,
    watchLimitAmount,
    // NOTE: ACTIONS
    createPayment,
    // NOTE: HANDLE FUNCTIONS
    handleTabs,
    handlePage,
    handleSubmit,
    handleSearch,
    handleToggleForm,
    handlePayExpense,
    handleEditPayment,
    handlePreviewFiles,
    handleDeletePayment,
  };
}
