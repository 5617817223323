import { useState } from 'react';

// @Mui
// import { DataGrid } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
// import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';

// @Icons
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import NoteEditOutline from 'mdi-material-ui/NoteEditOutline';

// @Library
import { NumericFormat } from 'react-number-format';

// @Models
import { IShipment } from '../../../../models';

// @Utils
// import { defaultColumns } from '../utils/service-columns';
import { useTranslation } from 'react-i18next';
import { CardContent, Grid } from '@mui/material';
import ColumHeader from '../../../../components/TableColumnHeader';
import { TrashCanOutline } from 'mdi-material-ui';
import { FormatNumber } from '../../../../utils';

interface IProps {
  services: IShipment[];
  deleteService: (index: number) => void;
  renderForm: (service?: IShipment) => void;
  ivaTotal: number;
  total: number;
  subTotal: number;
}

interface ITable {
  rows: IShipment[];
  handleShow: (service?: IShipment) => void;
  deleteService: (index: number) => void;
}

const formatNumber = (number: number) => {
  const config = { minimumFractionDigits: 2, maximumFractionDigits: 2 };
  return Intl.NumberFormat('en-US', config).format(number);
};

function CollapsibleTable({ rows, deleteService, handleShow }: ITable) {
  const [open, setOpen] = useState<number | null>(null);

  const { t, i18n } = useTranslation('common');

  return (
    <TableContainer component={Paper} sx={{ maxHeight: 400, minHeight: 400 }}>
      <Table stickyHeader aria-label='collapsible table'>
        <TableHead sx={{ backgroundColor: 'primary.main' }}>
          <ColumHeader
            headers={[
              '',
              t('general.content'),
              `${t('general.origin')}/${t('general.destiny')}`,
              t('general.subTotal'),
              t('general.ivaTotal'),
              t('general.total'),
              t('general.actions'),
            ]}
          />
        </TableHead>

        {rows.length > 0 ? (
          <TableBody>
            {rows.map(row => (
              <>
                <TableRow>
                  <TableCell>
                    {open === row.id ? (
                      <IconButton aria-label='expand row' size='small' onClick={() => setOpen(null)}>
                        <KeyboardArrowUpIcon />
                      </IconButton>
                    ) : (
                      <IconButton aria-label='expand row' size='small' onClick={() => setOpen(row.id)}>
                        <KeyboardArrowDownIcon />
                      </IconButton>
                    )}
                  </TableCell>

                  <TableCell component='th' scope='row'>
                    {row.content}
                  </TableCell>
                  <TableCell align='center'>
                    {row.origin}/{row.destiny}
                  </TableCell>
                  <TableCell align='right'>{formatNumber(row.subTotal)}</TableCell>
                  <TableCell align='right'>{formatNumber(row.ivaTotal)}</TableCell>
                  <TableCell align='right'>{formatNumber(row.total)}</TableCell>

                  {/* actions */}
                  <TableCell align='center'>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                      <IconButton title={`${t('general.edit')}`} name='general.edit' onClick={() => handleShow(row)}>
                        <NoteEditOutline color='success' fontSize='inherit' />
                      </IconButton>

                      <IconButton title='delete' name='delete' onClick={() => deleteService(row.id)}>
                        <TrashCanOutline color='error' fontSize='inherit' />
                      </IconButton>
                    </Box>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open === row.id} timeout='auto' unmountOnExit>
                      <Box sx={{ margin: 1 }}>
                        <Typography variant='h6' gutterBottom component='div' align='center'>
                          {t('general.service')}
                        </Typography>

                        <Table stickyHeader aria-label='collapsible table' sx={{ ml: 5 }}>
                          <TableHead sx={{ backgroundColor: 'primary.main' }}>
                            <ColumHeader
                              headers={[
                                t('general.service'),
                                t('general.quantity'),
                                t('general.typeSale'),
                                t('general.unitPrice'),
                                t('general.subTotal'),
                                t('general.iva'),
                                t('general.total'),
                              ]}
                            />
                          </TableHead>
                          {row.services.map((service, index) => (
                            <TableBody key={index}>
                              <TableRow>
                                <TableCell align='center'>
                                  {i18n.language === 'es' ? service.serviceName : service.serviceNameEN}
                                </TableCell>
                                <TableCell align='center'>{service.serviceAmount}</TableCell>
                                <TableCell align='right'>{t(`saleType.${service.typeSale}`)}</TableCell>
                                <TableCell align='right'>{formatNumber(service.priceCost)}</TableCell>
                                <TableCell align='right'>{formatNumber(service.price)}</TableCell>
                                <TableCell align='right'>{formatNumber(service.amountOfIva)}</TableCell>
                                <TableCell align='right'>{formatNumber(service.amountOfService)}</TableCell>
                              </TableRow>
                            </TableBody>
                          ))}
                        </Table>
                      </Box>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </>
            ))}
          </TableBody>
        ) : (
          <TableBody>
            <TableRow>
              <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
                <Box
                  sx={{
                    display: 'flex',
                    textAlign: ' center',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: '#F7F7F7',
                    height: 300,
                  }}
                >
                  <Typography sx={{ textAlign: 'center' }} gutterBottom component='div'>
                    {t('general.noRows', { ns: 'validations' })}
                  </Typography>
                </Box>
              </TableCell>
            </TableRow>
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );
}

export function ServiceTable({ services, subTotal, ivaTotal, total, deleteService, renderForm }: IProps) {
  // const columns = defaultColumns(deleteService, renderForm);
  const { t } = useTranslation('common');

  return (
    <Box>
      <CardContent>
        <Box sx={{ p: 5, display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'flex-end' }}>
          <Button
            variant='contained'
            onClick={() => renderForm()}
            startIcon={<i className='fa-sharp fa-solid fa-plus' />}
          >
            {t('add.general')}
          </Button>
        </Box>

        <Divider sx={{ m: 0 }} />

        <Box>
          <CollapsibleTable rows={services} deleteService={deleteService} handleShow={renderForm} />
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mt: 2 }}>
          <Grid container spacing={4} sx={{ mt: 1 }}>
            <Grid item xs={4} sx={{ display: 'grid', justifyContent: 'center' }}>
              <InputLabel>Subtotal</InputLabel>

              <NumericFormat
                sx={{ '& input': { textAlign: 'end' } }}
                name='subTotal'
                value={FormatNumber(subTotal)}
                customInput={TextField}
                thousandSeparator
                decimalScale={2}
                disabled
              />
            </Grid>

            <Grid item xs={4} sx={{ display: 'grid', justifyContent: 'center' }}>
              <InputLabel>{t('general.iva')}</InputLabel>

              <NumericFormat
                sx={{ '& input': { textAlign: 'end' } }}
                name='iva'
                value={FormatNumber(ivaTotal)}
                customInput={TextField}
                thousandSeparator
                decimalScale={2}
                disabled
              />
            </Grid>

            <Grid item xs={4} sx={{ display: 'grid', justifyContent: 'center' }}>
              <InputLabel>Total</InputLabel>

              <NumericFormat
                sx={{ '& input': { textAlign: 'end' } }}
                name='total'
                value={FormatNumber(total)}
                customInput={TextField}
                thousandSeparator
                decimalScale={2}
                disabled
              />
            </Grid>
          </Grid>
        </Box>
      </CardContent>
    </Box>
  );
}
