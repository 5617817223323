import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// @Actions
import { getUser } from '../user/ActionsAsync';

// @AsyncThunk
// type GenericAsyncThunk = AsyncThunk<unknown, unknown, any>;
// type PendingAction = ReturnType<GenericAsyncThunk['pending']>;
// type RejectedAction = ReturnType<GenericAsyncThunk['rejected']>;

const initialState = { requestsCount: 0 };

export const notificationSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    initialNotifications: (state, action: PayloadAction<{ requestsCount: number }>) => {
      state.requestsCount = action.payload.requestsCount;
    },
    increaseRequests: state => {
      state.requestsCount = state.requestsCount + 1;
    },
    decreaseRequests: state => {
      if (state.requestsCount > 0) {
        state.requestsCount = state.requestsCount - 1;
      }
    },
    modifyRequest: (state, action: PayloadAction<{ count: number }>) => {
      if (action.payload.count > 0) {
        state.requestsCount = action.payload.count;
      }
    },
    clearNotifications: () => initialState,
  },
  extraReducers: builder => {
    // builder.addCase(getUser.fulfilled, (state, action) => {
    //   state.requestsCount = action.payload.requestCount;
    // });
    // builder.addCase(getUser.rejected, (state, action) => {
    //   state.requestsCount = initialState.requestsCount;
    // });
  },
});

export const { decreaseRequests, increaseRequests, initialNotifications, modifyRequest, clearNotifications } =
  notificationSlice.actions;

export default notificationSlice.reducer;
