// @Library
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';
import { EditorState } from 'draft-js';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';

// @MUI components
import { createFilterOptions } from '@mui/material/Autocomplete';

// @Redux
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/reducers';

// @Utils
import { axiosEstimates } from '../../utils/api';
import { getBudget } from '../Fomularios Temporales Guias/services';

const filter = createFilterOptions();

export function useSendBudget() {
  const [title, setTitle] = useState('');
  const [emailTo, setEmailTo] = useState<string[]>([]);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [isSending, setIsSending] = useState(false);

  const { user } = useSelector((state: RootState) => state.user);

  const menuItemsArr: string[] = [];

  const { id } = useParams();

  const [params] = useSearchParams();

  const navigate = useNavigate();

  const { t } = useTranslation(['common']);

  useEffect(() => {
    handleGet();
  }, []);

  const handleGet = async () => {
    setTitle(`${t('toast.loading')}`);

    try {
      if (id) {
        const { budget, customer } = await getBudget(id);
        setTitle(`${budget.code} - ${customer.name}`);
        setEmailTo([customer.email]);
      }
    } catch (error) {}
  };

  const addNewOption = (options: string[], params: any): string[] => {
    const filtered = filter(options, params);
    const { inputValue } = params;
    const isExisting = options.some(option => inputValue === option);

    if (inputValue !== '' && !isExisting) filtered.push(inputValue);

    // @ts-ignore
    return filtered;
  };

  const handleAddEmail = (email: string[]) => {
    setEmailTo(email);
  };

  const handleEditorChange = (value: EditorState) => {
    setEditorState(value);
  };

  const handleSendMail = async (action: string) => {
    if (!user || !id) return null;

    if (!(emailTo.length > 0)) return toast.error(t('send.emailRequire'));

    const country = user.person?.country_alpha2code;

    setIsSending(true);

    try {
      await axiosEstimates.post('/v2/customer/send-to-customer', {
        action,
        emails: emailTo,
        country,
        idBudget: parseInt(id),
      });

      toast.success(t('toast.sent'));

      navigate(`/${params.get('lastRoute')}` || '/budgets');
    } catch (error) {
      toast.error(t('general.error', { ns: 'common' }));
    } finally {
      setIsSending(false);
    }
  };

  return {
    t,
    emailTo,
    addNewOption,
    handleAddEmail,
    menuItemsArr,
    editorState,
    handleEditorChange,
    handleSendMail,
    title,
    isSending,
  };
}
