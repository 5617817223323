// @Libraries
import { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { useFieldArray, useForm } from 'react-hook-form';
import { useLocation, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';

// @Models
import { BL } from '../../../../models';

// @Hooks
import { useDocument } from '../../hooks';

// @Redux
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/reducers';

// @Utils
import { axiosEstimates } from '../../../../utils';
import { initialValues, schemaValidation } from './utils/validationSchema';
import { DOCUMENT_TYPE_OBJ } from '../../utils';

const maxLength = 255;

const useBL = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const [counter, setCounter] = useState('');
  const [documentId, setDocumentId] = useState<number | null>(null);
  const [isSigning, setIsSigning] = useState<boolean>(false);
  const [canEdit, setCanEdit] = useState<boolean>(false);
  const { budget, documents, handleNavigatePrint, handleFormChange, handleFormChange2 } = useDocument();
  const [currentData, setCurrentData] = useState<object | null>(null);

  const { control, handleSubmit, reset, watch, getValues } = useForm<BL>({
    defaultValues: initialValues,
    resolver: yupResolver(schemaValidation),
    reValidateMode: 'onChange',
  });

  useEffect(() => {
    getValues();
    const current = JSON.stringify(currentData);
    const updated = JSON.stringify(getValues());
    if (current !== updated) {
      handleFormChange();
    } else {
      handleFormChange2();
    }
  }, [watch()]);

  const {
    user: { role },
  } = useSelector((state: RootState) => state);

  useEffect(() => {
    if (role === 'admin' || role === 'editor') {
      setCanEdit(true);
    }
  }, []);

  const {
    fields: contentFields,
    append: contentAppend,
    remove: contentRemove,
  } = useFieldArray({ control, name: 'content' });

  if (contentFields.length === 0) {
    contentAppend({
      marks: '',
      packages: null,
      grossWeight: null,
      measurement: ' ',
      description: '',
    });
  }

  const {
    fields: chargesFields,
    append: chargesAppend,
    remove: chargesRemove,
  } = useFieldArray({ control, name: 'charges' });

  if (chargesFields.length === 0) {
    chargesAppend({
      description: '',
      prepaid: null,
      collect: null,
    });
  }

  useEffect(() => {
    setCurrentData(getValues());
    handleData();
  }, [budget]);

  const handleData = async () => {
    const data = budget;
    if (data) {
      const document = documents.find(document => document.document_types.name === DOCUMENT_TYPE_OBJ.BL);
      if (document?.isSigning) {
        setIsSigning(document.isSigning);
      }

      if (!document) {
        const dataBL = {
          // expeditionDate: data.createdAt,
          // invoice: data.code,
          shipper: {
            name: 'CML EXPORTS AND MOVING SOLUTIONS INC',
            email: 'operations@cmlexports.com',
            phone: '(904) 295 4450',
            direction: '112 Bartram Oaks Walk #600877 Jacksonville, FL 32259 US',
          },
          consignee: {
            name: data.customer.name,
            email: data.customer.email,
            phone: data.customer.phone,
            direction: data.customer.address,
          },
        };
        reset(dataBL);
        setDocumentId(null);
      } else {
        setCounter(document.comment || '');
        const dataBl: BL = {
          blNumber: document.blNumber || '',
          originalNumber: document.originalNumber || '',
          placeOfReceipt: document.placeOfReceipt || '',
          notifyname: document.employee || '',
          notifyaddress: document.Addressee || '',
          container: document.comment || '',
          vessel: document.vessel || '',
          portOfLoading: document.loadingPort || '',
          portOfDischarge: document.dischargePort || '',
          placeOfDelivery: document.placeOfDelivery || '',
          shipper: {
            direction: document.shipperPerson?.address || '',
            email: document.shipperPerson?.email || '',
            name: document.shipperPerson?.name || '',
            phone: document.shipperPerson?.phone || '',
          },
          consignee: {
            name: document.consignedToPerson?.name || '',
            email: document.consignedToPerson?.email || '',
            phone: document.consignedToPerson?.phone || '',
            direction: document.consignedToPerson?.address || '',
          },
          charges: document.documentCharges.map(charge => ({
            description: charge.description,
            prepaid: Number(charge.prepaidCharges) || null,
            collect: Number(charge.chargesReceivable) || null,
          })),
          content: document.documentContent.map(content => ({
            marks: content.number || null,
            packages: content.bl_amount || null,
            description: content.description || '',
            grossWeight: content.grossWeight || null,
            measurement: content.medidas || null,
          })),
          notify: '',
          expeditionDate: document.expeditionDate ?? '',
        };
        reset(dataBl);
        setDocumentId(document.id);
      }
    }
  };

  function handleChange(event: { target: { value: React.SetStateAction<string> } }) {
    setCounter(event.target.value);
  }

  const signingDocument = async (data: BL) => {
    try {
      const charges = data.charges.map(charge => ({
        description: charge.description,
        prepaidCharges: `${charge.prepaid}`,
        chargesReceivable: `${charge.collect}`,
      }));
      const content = data.content.map(content => ({
        number: content.marks,
        bl_amount: content.packages,
        description: content.description,
        grossWeight: content.grossWeight,
        medidas: content.measurement,
      }));

      data.container = counter;

      const dataPorte = {
        isSigning: true,
        budgetId: parseInt(id || '0'),
        strategy: DOCUMENT_TYPE_OBJ.BL,
        blNumber: data.blNumber,
        originalNumber: data.originalNumber,
        placeOfReceipt: data.placeOfReceipt,
        // notificar a
        employee: data.notifyname,
        Addressee: data.notifyaddress,
        comment: data.container,
        vessel: data.vessel,
        loadingPort: data.portOfLoading,
        dischargePort: data.portOfDischarge,
        placeOfDelivery: data.placeOfDelivery,
        documentContent: content,
        documentCharges: charges,
        expeditionDate: data.expeditionDate,
        shipperPerson: {
          name: data.shipper.name,
          address: data.shipper.direction,
          email: data.shipper.email,
          phone: data.shipper.phone,
        },
        consignedToPerson: {
          name: data.consignee.name,
          address: data.consignee.direction,
          email: data.consignee.email,
          phone: data.consignee.phone,
        },
        conveyors: {
          accountingInformation: '1',
        },
      };

      let document: any = null;
      if (!documentId) {
        const axiosResponse = await axiosEstimates.post('/api/document', dataPorte);
        document = axiosResponse.data.data;
      } else {
        const axiosResponse = await axiosEstimates.put('/api/document', { ...dataPorte, id: documentId });
        document = axiosResponse.data.data;
      }

      if (document) {
        handleNavigatePrint(document.id, location.pathname + '&value=4');

        Swal.fire({
          icon: 'success',
          title: t('alert.success', { ns: 'common' }) || '',
          text: t('alert.supplier.success', { ns: 'common' }) || '',
          showCloseButton: true,
        });
      }
    } catch (err) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: t('alert.somethingerror', { ns: 'common' }) || '',
        showCloseButton: true,
      });
    }
  };

  const onSubmit = async (data: BL) => {
    try {
      const charges = data.charges.map(charge => ({
        description: charge.description,
        prepaidCharges: `${charge.prepaid}`,
        chargesReceivable: `${charge.collect}`,
      }));
      const content = data.content.map(content => ({
        number: content.marks,
        bl_amount: content.packages,
        description: content.description,
        grossWeight: content.grossWeight,
        medidas: content.measurement,
      }));

      data.container = counter;

      const dataPorte = {
        isSigning,
        budgetId: parseInt(id || '0'),
        strategy: DOCUMENT_TYPE_OBJ.BL,
        blNumber: data.blNumber,
        originalNumber: data.originalNumber,
        placeOfReceipt: data.placeOfReceipt,
        // notificar a
        employee: data.notifyname,
        Addressee: data.notifyaddress,
        comment: data.container,
        vessel: data.vessel,
        loadingPort: data.portOfLoading,
        dischargePort: data.portOfDischarge,
        placeOfDelivery: data.placeOfDelivery,
        documentContent: content,
        documentCharges: charges,
        expeditionDate: data.expeditionDate,
        shipperPerson: {
          name: data.shipper.name,
          address: data.shipper.direction,
          email: data.shipper.email,
          phone: data.shipper.phone,
        },
        consignedToPerson: {
          name: data.consignee.name,
          address: data.consignee.direction,
          email: data.consignee.email,
          phone: data.consignee.phone,
        },
        conveyors: {
          accountingInformation: '1',
        },
      };

      let document: any = null;
      if (!documentId) {
        const axiosResponse = await axiosEstimates.post('/api/document', dataPorte);
        document = axiosResponse.data.data;
      } else {
        const axiosResponse = await axiosEstimates.put('/api/document', { ...dataPorte, id: documentId });
        document = axiosResponse.data.data;
      }

      if (document) {
        handleNavigatePrint(document.id, location.pathname);

        Swal.fire({
          icon: 'success',
          title: t('alert.success', { ns: 'common' }) || '',
          text: t('alert.supplier.success', { ns: 'common' }) || '',
          showCloseButton: true,
        });
      }
    } catch (err) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: t('alert.somethingerror', { ns: 'common' }) || '',
        showCloseButton: true,
      });
    }
  };

  return {
    t,
    counter,
    control,
    maxLength,
    handleChange,
    onSubmit: handleSubmit(onSubmit, err => console.error(err)),
    contentFields,
    contentAppend,
    contentRemove,
    chargesFields,
    chargesAppend,
    chargesRemove,
    documentId,
    navigate: handleNavigatePrint,
    setIsSigning,
    isSigning,
    // eslint-disable-next-line no-console
    signingDocument: handleSubmit(signingDocument),
    canEdit,
  };
};

export default useBL;
