import { useTranslation } from 'react-i18next';

// @MUI Components
import {
  CircularProgress,
  Grid,
  Card,
  Box,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  TableCell,
  TableRow,
  Typography,
  IconButton,
  useMediaQuery,
} from '@mui/material';

import { BackspaceOutline, Printer } from 'mdi-material-ui';

// @Hooks
import { useBudgetView } from './use-budget-view.hook';

// @Utils
import { formatNumber } from '../../utils/format-number.util';

// @Components
import TableColap from './components/table-colap';
import ButtonForm from '../../components/ButtonForm';
import TableComponent from '../../components/TableContainer';
interface IRowProps {
  row: any;
}

const Row = ({ row }: IRowProps) => {
  return (
    <TableRow>
      <TableCell>
        <Typography noWrap sx={{ textTransform: 'capitalize', textAlign: 'center' }}>
          {formatNumber(row.receivable)}
        </Typography>
      </TableCell>

      <TableCell>
        <Typography noWrap sx={{ textTransform: 'capitalize', textAlign: 'center' }}>
          {formatNumber(row.toPay)}
        </Typography>
      </TableCell>

      <TableCell>
        <Typography noWrap sx={{ textTransform: 'capitalize', textAlign: 'center' }}>
          {formatNumber(row.receivable - row.toPay)}
        </Typography>
      </TableCell>

      <TableCell>
        <Typography noWrap sx={{ textTransform: 'capitalize', textAlign: 'center' }}>
          {formatNumber(row.commision)}
        </Typography>
      </TableCell>

      <TableCell>
        <Typography noWrap sx={{ textTransform: 'capitalize', textAlign: 'center' }}>
          {formatNumber(row.receivable - row.toPay - row.commision)}
        </Typography>
      </TableCell>
    </TableRow>
  );
};

export function BudgetView() {
  const { budget, handleback, handlePrint, totalData, client, budgetCommission } = useBudgetView();

  const isMobile = useMediaQuery('(max-width:600px)');

  const { t } = useTranslation(['common']);

  const calculateCommision = (value: number) => {
    const comision = budgetCommission?.commission;
    if (!comision) return 0;
    if (comision.type_amount === 1) return value * comision.amount;
    return comision.amount;
  };

  const _newTotalData = {
    ...totalData,
    benefit: totalData.receivable - totalData.toPay,
  };

  const _newTotalData2 = {
    ..._newTotalData,
    commission: calculateCommision(_newTotalData.receivable - (_newTotalData?.toPay ?? 0)),
  };

  const newTotalData = {
    ..._newTotalData2,
    utility: _newTotalData2.benefit - _newTotalData2.commision,
  };

  if (!budget) {
    return (
      <Card sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        <CircularProgress />
      </Card>
    );
  }

  return (
    <Grid container sx={{ width: '90%', ml: 'auto', mr: { xs: 0.5, md: 'auto' }, zIndex: 1 }}>
      <Grid item xs={12} sx={{ mx: 'auto' }}>
        <Card sx={{ width: '100%' }}>
          <CardHeader
            title={`${budget.budget.code} - ${client}`}
            sx={{ display: 'flex', textAlign: 'center' }}
            titleTypographyProps={{ variant: 'h6' }}
          />

          <Divider sx={{ margin: 0 }} />

          <CardContent>
            <TableColap rows={budget.suppliers} />

            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <TableComponent
                Row={Row}
                data={[{ ...newTotalData }]}
                headers={[
                  t('general.receivable', { ns: 'common' }),
                  t('general.toPay', { ns: 'common' }),
                  t('general.profit', { ns: 'common' }),
                  t('general.commision', { ns: 'common' }),
                  t('general.finalUtility', { ns: 'common' }),
                ]}
              />
            </Box>

            <CardActions sx={{ justifyContent: 'center', margin: '1rem 0' }}>
              {isMobile ? (
                <>
                  <IconButton onClick={handleback} color='error'>
                    <BackspaceOutline />
                  </IconButton>
                  <IconButton onClick={handlePrint} color='primary'>
                    <Printer />
                  </IconButton>
                </>
              ) : (
                <>
                  <ButtonForm
                    variant='outlined'
                    color='error'
                    label={t('general.back')}
                    sx={{ mr: 12 }}
                    onClick={handleback}
                  />
                  <ButtonForm label={t('general.navCot', { ns: 'common' })} sx={{ mr: 2 }} onClick={handlePrint} />
                </>
              )}
            </CardActions>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
