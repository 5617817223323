import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';

// @Utils
import { axiosEstimates } from '../../../utils/api';

// @Interfaces and Types
import { IQuotesOfSuppliers, ISuppliersFiles } from '../../../models';

// @Initial states
const initialPageSize = 5;
const initialBudgets: IQuotesOfSuppliers[] = [];

export default function useTabBudgets() {
  const [page, setPage] = useState(0);
  const [show, setShow] = useState(false);
  const [pageSize, setPageSize] = useState(initialPageSize);
  const [searchBudget, setSearchBudget] = useState('');
  const [budgets, setBudgets] = useState(initialBudgets);
  const [budgetsFiles, setBudgetsFiles] = useState<ISuppliersFiles[]>([]);
  const [budgetsFounds, setBudgetsFounds] = useState(initialBudgets);

  const { id } = useParams();

  const currentBudgets = () => {
    if (searchBudget) {
      return budgetsFounds.filter((b, i) => i >= pageSize * page && i < pageSize * (page + 1));
    } else {
      return budgets.filter((b, i) => i >= pageSize * page && i < pageSize * (page + 1));
    }
  };

  // NOTE: ALL ACTIONS
  const getSupplierBudgetsServices = async () => {
    try {
      const budgetsServices = await axiosEstimates.post<IQuotesOfSuppliers[]>(
        `supplier-services/${id}`,
        {
          serviceName: '',
        },
        {
          headers: { 'x-module': 'services' },
        },
      );

      setBudgets(budgetsServices.data);
    } catch (err) {
      console.error(err);
    }
  };

  const getSuppliersFiles = async () => {
    try {
      const getSuppliersFile = await axiosEstimates.get<ISuppliersFiles[]>('files-suppliers');
      setBudgetsFiles(getSuppliersFile.data);
    } catch (err) {
      console.error(err);
    }
  };

  const searchBudgetByInvoice = () => {
    if (!searchBudget) return setBudgetsFounds(initialBudgets);

    if (budgets.length) {
      setBudgetsFounds(budgets.filter(budget => (budget.quotationCode.includes(searchBudget) ? budget : null)));
    }
  };

  const suppliersHasDocuments = (id: number) => {
    const hasDocuments = budgetsFiles.find(item => item.id === id);
    return hasDocuments?.hasFile === 1;
  };

  // NOTE: ALL HANDLE FUNCTIONS
  const handleClose = () => setShow(false);

  const handleSearchBudgets = (text: string) => setSearchBudget(text);

  // NOTE: ALL USE EFFECTS
  useEffect(() => {
    getSupplierBudgetsServices();
    getSuppliersFiles();
  }, []);

  useEffect(() => {
    searchBudgetByInvoice();
  }, [searchBudget]);

  return {
    // NOTE: DATA
    budgets,
    budgetsFounds,
    searchBudget,
    currentBudgets,
    // NOTE: FORM STATUS
    show,
    // NOTE: PAGINATION
    page,
    setPage,
    pageSize,
    setPageSize,
    // NOTE: ACTIONS
    suppliersHasDocuments,
    // NOTE: HANDLE FUNCTIONS
    handleClose,
    handleSearchBudgets,
  };
}
