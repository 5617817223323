import { useTranslation } from 'react-i18next';

// @Mui
import Box from '@mui/material/Box';

// @Hooks
import { useServiceStep } from './use-shipments-tab.hook';

// @Views
import { ServiceTable } from './components/service-table.component';
// import { ServiceForm } from '../service-form/service-form.view';
import { ServiceForm } from '../../budget-form/service-form/service-form.view';
import { ShipmentForm } from '../../budget-form/shipment-form/shipment-form.view';

// @Components
import ButtonForm from '../../../components/ButtonForm';

// @Models
import { IServicesTab, IClientTabForm } from '../../../models';

interface IProps {
  data: IServicesTab;
  customer: IClientTabForm;
  budgetStatus: string;
  idBudget: number;
  tabChange: (newValue: number) => void;
  budgetHaveBill: boolean;
}

export function ServiceStep({ data, budgetStatus, idBudget, customer, tabChange, budgetHaveBill }: IProps) {
  const {
    services,
    renderForm,
    handleRenderForm,
    handleAddService,
    handleEditService,
    handleDeleteService,
    ivatotal,
    total,
    service,
    subTotal,
    user,
    handleEdit,
    handleReject,
    handleEditRequest,
    budgetType,
  } = useServiceStep({ data, budgetStatus, idBudget, customer, tabChange, budgetHaveBill });

  const { t } = useTranslation();

  return (
    <>
      {budgetStatus === 'request' ? (
        <ShipmentForm shipments={services} isUpdate handleEdit={handleEditRequest} />
      ) : (
        <Box>
          {renderForm ? (
            <ServiceForm
              addService={handleAddService}
              editService={handleEditService}
              service={service}
              handleRenderForm={handleRenderForm}
              customerCommission={data.customerCommission}
              previousService={services[0]}
              servicesCount={services.length}
              edit
              status={budgetStatus}
              budgetType={budgetType}
            />
          ) : (
            <>
              <ServiceTable
                services={services}
                subTotal={subTotal}
                ivaTotal={ivatotal}
                total={total}
                deleteService={handleDeleteService}
                renderForm={handleRenderForm}
              />

              <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
                {user.role === 'admin' && budgetStatus !== 'request' && (
                  <ButtonForm
                    label={t('general.rechazar', { ns: 'common' })}
                    color='error'
                    onClick={handleReject}
                    disabled={budgetStatus === 'active'}
                  />
                )}
                <ButtonForm
                  label={
                    user.role === 'admin' &&
                    !['active', 'request', 'initialized', 'toBeConfirmed'].includes(budgetStatus)
                      ? t('general.approve', { ns: 'common' })
                      : t('general.save', { ns: 'common' })
                  }
                  onClick={handleEdit}
                  disabled={
                    !(
                      services.length > 0 &&
                      (budgetStatus === 'request' ? true : !services.find(service => service.total <= 0))
                    )
                  }
                />
              </Box>
            </>
          )}
        </Box>
      )}
    </>
  );
}
