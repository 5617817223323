// @Libraries
import { useTranslation } from 'react-i18next';

// @Views
import { DisplayStep, StepperView } from '../views';

// @Material UI
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';

// @Components
import { LanguageDropdown } from '../../../components';

// @Context
import { CustomerInteractionProvider } from '../context';

export function CustomerInteractionContainer() {
  const { t } = useTranslation(['common']);

  return (
    <CustomerInteractionProvider>
      <Box
        sx={{
          width: '100%',
          height: '100%',
          backgroundColor: theme => theme.palette.background.default,
          padding: 0,
          pt: 5,
          margin: 0,
        }}
      >
        <Box>
          <Typography sx={{ textAlign: 'center' }} variant='h5'>
            {t('general.budget', { ns: 'common' })}
          </Typography>

          <Box sx={{ position: 'absolute', top: 10, right: 80 }}>
            <LanguageDropdown />
          </Box>

          <Box>
            <Box sx={{ mt: 5 }}>
              <StepperView />
            </Box>
            <DisplayStep />
          </Box>
        </Box>
      </Box>
    </CustomerInteractionProvider>
  );
}
