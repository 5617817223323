// @Utils
import { axiosEstimates } from '../../../utils';

// @Interfaces and Types

const getDocumentExpense = async (id: string) => {
  const response = await axiosEstimates.get(`/expenses/${id}`);
  const { status, statusText, data } = response;

  if (status === 200) return data;
  else throw new Error(`${status} - ${statusText || 'Error al guardar el pago'} `);
};

export default getDocumentExpense;
