import { useTranslation } from 'react-i18next';

// @MUI Components
import { CardContent, Button, Grid, Box, Divider } from '@mui/material';

// @MUI Icons
import AddIcon from '@mui/icons-material/Add';

// @Components
import { SearchField } from '../../../components';
import { TypeServicesTable } from '../../../views/services/typeServiceTable/service-table.view';
import { ServiceTypeForm } from '../../../views/services/typeServices-form/typeservice-form.view';
import EmptyData from '../../../components/EmptyData';
import { ServiceTypeModel } from '../../../models';

interface IProps {
  typeServicesData: {
    permissions: string[];
    show: boolean;
    tableIsLoading: boolean;
    filter: string;
    serviceType: ServiceTypeModel | undefined;
    serviceTypes: ServiceTypeModel[];
    addTypeServicesTitle: { name: string; ESname: string };
    deleteService: (id: number) => Promise<void>;
    handleShow: (render: boolean, serviceType?: ServiceTypeModel) => void;
    handleFilter: (value: string) => void;
    handleAddService: (serviceType: ServiceTypeModel) => void;
    handleEditServiceType: (newService: ServiceTypeModel) => void;
  };
}

export const ServiceType = ({ typeServicesData }: IProps) => {
  const {
    // NOTE: PERMISSIONS
    permissions,
    // NOTE: STATUS
    show,
    tableIsLoading,
    // NOTE: FILTERS
    filter,
    // NOTE: DATA
    serviceType,
    serviceTypes,
    addTypeServicesTitle,
    // NOTE: ACTIONS
    deleteService,
    // NOTE: HANDLE FUNCTIONS
    handleShow,
    handleFilter,
    handleAddService,
    handleEditServiceType,
  } = typeServicesData;

  const { t, i18n } = useTranslation('common');

  return (
    <CardContent>
      {show ? null : (
        <Grid container sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', rowGap: 4 }}>
          <Grid
            item
            xs={12}
            sm
            md={8}
            lg={6}
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              gap: 3,
            }}
          >
            <Box sx={{ width: { sx: '100%', lg: '75%', xl: '65%' } }}>
              <SearchField value={filter} handleFilter={handleFilter} placeholder={t('general.search')} />
            </Box>
          </Grid>

          {permissions.includes('add_service_type') && (
            <Grid
              item
              xs={12}
              sm
              sx={{ width: '100%', display: 'flex', justifyContent: { xs: 'center', sm: 'flex-end' }, pr: 2 }}
            >
              <Button size='large' variant='contained' onClick={() => handleShow(true)}>
                <AddIcon />
                {i18n.language === 'es' ? addTypeServicesTitle.ESname : addTypeServicesTitle.name}
              </Button>
            </Grid>
          )}
        </Grid>
      )}

      {(permissions.includes('add_service_type') || permissions.includes('edit_service_type')) && show && (
        <ServiceTypeForm
          service={serviceType}
          handleRender={handleShow}
          addState={handleAddService}
          editState={handleEditServiceType}
        />
      )}

      {serviceTypes.length ? (
        <TypeServicesTable
          t={t}
          tableIsLoading={tableIsLoading}
          services={serviceTypes}
          handleDelete={deleteService}
          handleEdit={handleShow}
          permissions={permissions}
        />
      ) : (
        <>
          <Divider sx={{ mt: 5 }} />
          <EmptyData sx={{ mt: 5 }} />
        </>
      )}
    </CardContent>
  );
};
