import { CircleOutline, RecordCircleOutline } from 'mdi-material-ui';

export const MenuLockedIcon = () => (
  <RecordCircleOutline
    sx={{
      fontSize: '1.25rem',
      pointerEvents: 'none',
      transition: 'opacity .25s ease-in-out',
    }}
  />
);

export const MenuUnlockedIcon = () => (
  <CircleOutline
    sx={{
      fontSize: '1.25rem',
      pointerEvents: 'none',
      transition: 'opacity .25s ease-in-out',
    }}
  />
);
