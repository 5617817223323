import { useTranslation } from 'react-i18next';

// @MUI Components
import { Plus } from 'mdi-material-ui';
import { Box, Grid, Button, Switch, FormControlLabel, Typography } from '@mui/material';

// @Hooks
import useSuppliers from '../../../../hooks/useSuppliers';
import useTabServices from '../../../../hooks/useTabServices';

// @Components
import Row from './Row';
import { SearchField } from '../../../../../../components';
import EmptyData from '../../../../../../components/EmptyData';
import Pagination from '../../../../../../components/Pagination';
import TableComponent from '../../../../../../components/TableContainer';

// @Interfaces and Types

export default function TabServices() {
  const {
    // NOTE: PAGINATION
    page,
    setPage,
    pageSize,
    setPageSize,
    // NOTE: STATUS
    selectedRow,
    setSelectedRow,
    // NOTE: FILTERS
    expDate,
    setExpDate,
    serviceName,
    // NOTE: DATA
    idSupplier,
    supplierServices,
    // NOTE: ACTIONS
    currentSupplierServices,
    // NOTE: HANDLE FUNCTIONS
    handleFilter,
    handleNavigate,
  } = useTabServices();

  const { permissions } = useSuppliers();

  const { t } = useTranslation('common');

  if (!idSupplier) return <EmptyData />;

  return (
    <Grid container>
      <Grid
        container
        sx={{
          mt: { xs: 2, sm: 0 },
          mb: 4,
          width: '100%',
          gap: { xs: 4, sm: 0 },
          display: 'flex',
          justifyContent: { xs: 'center', sm: 'space-between' },
        }}
      >
        <Grid item xs={12} sm={3} sx={{ display: 'flex' }}>
          <FormControlLabel
            control={<Switch checked={expDate} onChange={() => setExpDate(!expDate)} />}
            label={t('general.validity')}
          />
        </Grid>

        <Grid item xs={12} sm={6} sx={{ display: 'flex' }}>
          <SearchField value={serviceName} handleFilter={handleFilter} placeholder={t('general.search')} />
        </Grid>

        {permissions.add_service && (
          <Grid
            item
            xs={12}
            sm={3}
            sx={{ width: 'max-content', display: 'flex', justifyContent: { xs: 'center', sm: 'end' } }}
          >
            <Button onClick={handleNavigate} variant='contained'>
              <Plus />
              <Typography sx={{ '@media (max-width: 600px)': { display: 'none' } }}>{t('budgetPage.add')}</Typography>
            </Button>
          </Grid>
        )}
      </Grid>

      <Grid item xs={12} sx={{ textAlign: 'center' }}>
        {currentSupplierServices?.length ? (
          <TableComponent
            Row={Row}
            props={{ idSupplier, selectedRow, setSelectedRow, permissions }}
            data={currentSupplierServices}
            headers={[
              '',
              t('general.invoice'),
              `${t('general.origin')}/${t('general.destiny')}`,
              t('general.transportTime'),
              t('general.commission'),
              t('general.validity'),
              t('general.actions'),
            ]}
          />
        ) : (
          <EmptyData sx={{ my: 10 }} />
        )}

        <Pagination
          count={supplierServices.length}
          page={page}
          setPage={setPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
        />
      </Grid>
    </Grid>
  );
}
