// @Redux
import { RootState } from '../redux/reducers';
import { useSelector } from 'react-redux';

// @Router
import { Navigate } from 'react-router-dom';

// @Components
import { LayoutComponent } from '../layouts/LayoutComponent';

type IWrapperRouteProps = {
  component: React.ReactNode;
  isPublic?: boolean;
};

export const WrapperRoute = ({ component, isPublic = false }: IWrapperRouteProps) => {
  const { isAuthenticated } = useSelector((state: RootState) => state.user);
  const auth = isAuthenticated; // determine if authorized, from context or however you're doing it

  if (isPublic) return auth ? <Navigate to='/' /> : <>{component}</>;

  return <>{auth ? <LayoutComponent>{component}</LayoutComponent> : <Navigate to='/login' />}</>;
};
