import store from '../redux/store';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

const lang = window.localStorage.getItem('lang') || 'es';

const axiosSystem = axios.create({
  baseURL: process.env.REACT_APP_CML_API,
  headers: {
    'X-localization': lang,
    'x-time-zone': Intl.DateTimeFormat().resolvedOptions().timeZone,
  },
});

axiosSystem.interceptors.request.use((config: AxiosRequestConfig) => {
  return config;
});

axiosSystem.interceptors.response.use((response: AxiosResponse) => {
  return response;
});

/* ---- CRM ---- */
const axiosCRM = axios.create({
  baseURL: process.env.REACT_APP_CRM_API,
});

axiosCRM.interceptors.request.use((config: AxiosRequestConfig) => {
  return config;
});

axiosCRM.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response.status === 502) {
      return axios.request(error.config);
    }
    return Promise.reject(error);
  },
);

/* ---- Estimates ---- */
const axiosEstimates = axios.create({
  baseURL: process.env.REACT_APP_ESTIMATES_API,
  headers: {
    // 'X-localization': lang,
    'x-time-zone': Intl.DateTimeFormat().resolvedOptions().timeZone,
    Authorization: '', // provide an actual string value here
  },
});

axiosEstimates.interceptors.request.use((config: AxiosRequestConfig) => {
  const state = store.getState();
  const idCRM = state?.user?.idCRM;
  const lang = state?.user?.language || 'es';
  const idExports = state?.user?.user?.id;
  const accesToken = state?.user?.access_token;

  if (idCRM && idExports && config.headers) {
    config.headers['x-id-user'] = idCRM;
    config.headers['x-id-exports'] = idExports;
    config.headers['X-localization'] = lang;
    config.headers.Authorization = accesToken;
  }
  return config;
});

axiosEstimates.interceptors.response.use((response: AxiosResponse) => {
  return response;
});

export { axiosSystem, axiosCRM, axiosEstimates };
