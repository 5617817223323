import { useTranslation } from 'react-i18next';

// @MUI Components
import { Card, CardHeader, Button, Divider, CardContent, Box, Grid } from '@mui/material';

// @MUI Icons
import AddIcon from '@mui/icons-material/Add';

// @Hooks
import useSettings from './hooks/usePdfSettings';

// @Components
import Row from './components/Row';
import TableComponent from '../../components/TableContainer';
import EmptyData from '../../components/EmptyData';
import Pagination from '../../components/Pagination';

export function PdfSettings() {
  const {
    // NOTE: PERMISSIONS
    canAdd,
    canEdit,
    canDelete,
    // NOTE: DATA
    configuration,
    addTitle,
    editTitle,
    deleteTitle,
    // NOTE: ALL HANDLE FUNCTIONS
    handleNavigate,
    handleEdit,
    handleDelete,
    // NOTE: PAGINATION
    totalData,
    pageSize,
    setPageSize,
    page,
    setPage,
  } = useSettings();

  const { i18n, t } = useTranslation('common');

  return (
    <Grid container sx={{ width: '90%', ml: 'auto', mr: { xs: 0.5, md: 'auto' }, zIndex: 1 }}>
      <Card sx={{ width: '100%', zIndex: 1 }}>
        <CardContent sx={{ padding: 6 }}>
          <CardHeader title={t('general.designOptions')} sx={{ display: 'flex', textAlign: 'center' }} />

          <Divider />

          {canAdd && (
            <Box sx={{ my: 2.5, width: '100%', display: 'flex', justifyContent: { xs: 'center', sm: 'flex-end' } }}>
              <Button size='medium' variant='contained' onClick={handleNavigate}>
                <AddIcon />
                {i18n.language === 'es' ? addTitle.ESname : addTitle.name}
              </Button>
            </Box>
          )}

          {configuration.length ? (
            <>
              <TableComponent
                Row={Row}
                data={configuration}
                headers={[t('general.name'), t('general.createdBy'), t('general.actions')]}
                props={{
                  deleteTitle: i18n.language === 'es' ? deleteTitle.ESname : deleteTitle.name,
                  editTitle: i18n.language === 'es' ? editTitle.ESname : editTitle.name,
                  canDelete,
                  canEdit,
                  handleEdit,
                  handleDelete,
                }}
              />
              <Pagination
                count={totalData}
                page={page}
                pageSize={pageSize}
                setPage={setPage}
                setPageSize={setPageSize}
                limitPagination={[5]}
              />
            </>
          ) : (
            <>
              <Divider />
              <EmptyData sx={{ mt: 5, mb: 10 }} />
            </>
          )}
        </CardContent>
      </Card>
    </Grid>
  );
}
