// @Library
import { AsyncThunkPayloadCreator, createAsyncThunk } from '@reduxjs/toolkit';

// @Utils
import { axiosEstimates } from '../../../utils/api';

// @Models
import { IGetRequestBudgets, IGetBudgetAnalysis, statusCountType } from '../../../models';

interface IGetParams {
  page: number;
  perPage: number;
  paginate?: string;
  filter: string;
  filterCustomer?: number | null;
  isSeller: boolean;
  idUser: number;
  status: string;
  type: 1 | 2 | 3;
  expenses?: string;
  to: string;
  from: string;
}

// !--- INIT HANDLER ---!
const handleGetBudgets: AsyncThunkPayloadCreator<IGetRequestBudgets, IGetParams, {}> = async (
  payload,
  { rejectWithValue },
) => {
  try {
    const modules = ['sv_quotes', 'usa_ctis_quotes', 'usa_cml_quotes'];

    const response = await axiosEstimates.get<IGetRequestBudgets>(
      `budget?page=${payload.page}&perPage=${payload.perPage}&paginate=${payload?.paginate || ''}&filterValue=${
        payload.filter
      }&isSeller=${payload.isSeller}&idUser=${payload.idUser}&status=${payload.status}&type=${payload.type}&expenses=${
        payload?.expenses || ''
      }&to=${payload.to}&from=${payload.from}&customerFilter=${payload.filterCustomer}`,
      {
        headers: { 'x-module': payload.status === 'request' ? 'requested_quotes' : modules[payload.type - 1] },
      },
    );
    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
};

const handleGetBudgetStatusCount: AsyncThunkPayloadCreator<statusCountType[], { type: 1 | 2 | 3 }, {}> = async (
  payload,
  { rejectWithValue },
) => {
  try {
    const response = await axiosEstimates.get<statusCountType[]>(`budget-status/count?type=${payload.type}`);

    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
};

const handlegetBudgetAnalysis: AsyncThunkPayloadCreator<IGetBudgetAnalysis, number, {}> = async (
  payload,
  { rejectWithValue },
) => {
  try {
    const response = await axiosEstimates.get<IGetBudgetAnalysis>(`budget-analysis/${payload}`);
    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
};

const handleGetAllBudgets = async () => {
  const response = await axiosEstimates.get('all-budgets');
  return response.data;
};
// !--- END HANDLER ---!
export const getBudgets = createAsyncThunk('budget/getBudgets', handleGetBudgets);
export const getAllBudgets = createAsyncThunk('all-budgets', handleGetAllBudgets);

export const getBudgetStatusCount = createAsyncThunk('budget/getBudgetStatusCount', handleGetBudgetStatusCount);

export const getBudgetAnalysis = createAsyncThunk('budget/getBudgetAnalysis', handlegetBudgetAnalysis);
