import { IGetRequestBudgets } from '../../../models';
import { axiosEstimates } from '../../../utils';

interface GerBudgetsParams {
  page: number;
  perPage: number;
  filter: string;
  isSeller: boolean;
  idUser: number;
  status: string;
  type: 1 | 2 | 3;
  to: string;
  from: string;
}

export async function getBudgets(params: GerBudgetsParams): Promise<IGetRequestBudgets> {
  const searchParams = new URLSearchParams(params as any);

  const res = await axiosEstimates.get<IGetRequestBudgets>(`budget?${searchParams.toString()}`, {
    headers: {
      'x-module': 'sv_quotes',
    },
  });

  return res.data;
}

interface UpdateStatusBudgetsParams {
  ids: number[];
  status: string;
  idUser: number;
}

export async function updateManyStatusBudgets(params: UpdateStatusBudgetsParams): Promise<void> {
  await axiosEstimates.put('v2/budget-update-status/many', params);
}
