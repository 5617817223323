import { useTranslation } from 'react-i18next';

// @Hooks

// @MUI Components
import { Box, Button, Grid } from '@mui/material';

// @Components
import { TextForm } from '../../../components';

// @Interfaces and Types
import { useServiceCategoryForm } from './serviceCategory-form.hook';
import { ServiceCategoryModel } from '../../../models/categories';

interface IProps {
  handleRender: (render: boolean) => void;
  addState: (data: ServiceCategoryModel) => void;
  editState: (data: ServiceCategoryModel) => void;
  category?: ServiceCategoryModel;
}

export function ServiceCategoryForm({ handleRender, addState, category, editState }: IProps) {
  const { onSubmit, control } = useServiceCategoryForm({ addState, category, editState });

  const { t } = useTranslation('common');

  return (
    <form onSubmit={onSubmit}>
      <Grid container sx={{ display: 'flex', justifyContent: { xs: 'center', md: 'space-between' }, gap: 3 }}>
        <Grid item xs={12} sm={5.5}>
          <TextForm
            fullWidth
            control={control}
            name='name'
            label={t('services.categoryEs')}
            translationFile='validations'
          />
        </Grid>

        <Grid item xs={12} sm={5.5}>
          <TextForm
            fullWidth
            control={control}
            name='nameEN'
            label={t('services.categoryEn')}
            translationFile='validations'
          />
        </Grid>

        <Box sx={{ width: '100%', display: 'flex', justifyContent: { xs: 'center', md: 'flex-end' } }}>
          <Button type='button' sx={{ mr: 3 }} variant='contained' color='error' onClick={() => handleRender(false)}>
            {t('general.cancel')}
          </Button>
          <Button type='submit' variant='contained' color='info'>
            {t('general.save')}
          </Button>
        </Box>
      </Grid>
    </form>
  );
}
