// @Utils
import { axiosEstimates } from '../../../utils';
import { socket } from '../../../utils/sockets.util';

const updateBudgetStatus = async (idBudget: number) => {
  try {
    await axiosEstimates.post('budget/status/', { id: idBudget, status: 'initialized' });
    socket.emit('requestCount');
  } catch (err) {
    console.error(err);
  }
};

export default updateBudgetStatus;
