import { DefaultTFuncReturn } from 'i18next';

// @MUI Components
import { Stack, Chip, Typography, SxProps } from '@mui/material';

// @Interfaces and Types
import { ThemeColor } from '../models';

/**

 * @component
 * @subcategory Global
 *
 * @description
 * A custom MUI component that displays a title and text in a stylized container.
 * #### Example
 * ```jsx
 * import Mushroom from './Mushroom';
 *
 * const MyComponent = () => {
 *   return (
 *     <Mushroom title="My Title" text="My text content" color="primary" />
 *   );
 * };
 * ```
 *
 * @property {DefaultTFuncReturn | string} title - The title of the mushroom component.
 * @property {DefaultTFuncReturn | string | number} text - The text content of the mushroom component.
 * @property {ThemeColor} [color='success'] - The color of the mushroom component. Can be one of 'primary', 'secondary', 'error', 'info', 'success', or 'warning'.
 * @property {SxProps} [sx] - An optional style object to apply to the mushroom component.
 *
 * @returns {JSX.Element} The mushroom component.
 * @author CML Exports Front-End Developers
 */
const Mushroom = ({
  title,
  text,
  color = 'success',
  sx,
}: {
  title: DefaultTFuncReturn | string;
  text: DefaultTFuncReturn | string | number;
  color?: ThemeColor;
  sx?: SxProps;
}) => (
  <Stack
    direction='column'
    sx={{ width: 140, display: 'flex', alignItems: 'center', justifyContent: 'space-between', ...sx }}
  >
    <Chip color={color} label={title} sx={{ width: '90%', py: 0, fontWeight: 600, fontSize: '0.90rem' }} />
    <Typography
      title={`${text}`}
      sx={{
        p: 0.5,
        width: '75%',
        fontWeight: 600,
        color: '#3f3f3f',
        fontSize: { sm: '0.90rem', md: '0.95rem', lg: '1rem' },
        textAlign: 'center',
        borderRadius: '0 0 40% 40%',
        border: ({ palette }) => `2px solid ${palette[color].main}`,
        transform: 'translateY(-1px)',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}
    >
      {text}
    </Typography>
  </Stack>
);

export default Mushroom;
