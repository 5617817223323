import CustomInput from '../../../components/CustomInput';
import { Drawer, Typography, Box, Button, Autocomplete, TextField, IconButton } from '@mui/material';
import useTypeServiceForm from '../hooks/useTypeServiceForm';
import { useTranslation } from 'react-i18next';
import { Close } from 'mdi-material-ui';
import { useServices } from '../hooks/useServices';
import useCategory from '../hooks/useCategory';

export default function ServiceTypeForm() {
  const { control, onSubmit, Controller } = useTypeServiceForm();

  const { t } = useTranslation(['common']);

  const { toggleTypeForm, handleToggleTypeForm } = useServices();

  const { categories } = useCategory();

  return (
    <Drawer
      open={toggleTypeForm}
      onClose={handleToggleTypeForm}
      variant='temporary'
      anchor='right'
      sx={{
        '& .MuiDrawer-paper': {
          boxSizing: 'border-box',
          width: { xs: 300, sm: 400 },
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: ({ spacing }) => spacing(3, 4),
          backgroundColor: ({ palette }) => palette.background.default,
        }}
      >
        <Typography variant='h6'>{t('services.actions.edit')}</Typography>
        <IconButton onClick={() => handleToggleTypeForm()}>
          <Close />
        </IconButton>
      </Box>
      <Box
        component={'form'}
        onSubmit={onSubmit}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 2,
          padding: ({ spacing }) => spacing(3, 4),
        }}
      >
        <CustomInput control={control} name='name' label={`${t('services.form.name_es')}`} />
        <CustomInput control={control} name='nameEN' label={`${t('services.form.name_en')}`} />

        <Controller
          name='categories'
          control={control}
          render={({ field }) => (
            <Autocomplete
              multiple
              value={
                field.name === 'categories'
                  ? field.value
                    ? field.value.map(id => categories.find(category => category.id === id))
                    : []
                  : []
              }
              fullWidth
              options={categories}
              onChange={(_, values) => {
                const ids = values.map(value => value?.id);
                field.onChange(ids);
              }}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              getOptionLabel={option => {
                return option?.name ?? '';
              }}
              renderInput={params => <TextField {...params} label={`${t('services.table.category')}`} />}
            />
          )}
        />

        <Button variant='contained' color='primary' type='submit' sx={{ mt: 2 }}>
          {t('general.save')}
        </Button>
      </Box>
    </Drawer>
  );
}
