import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// @Models
import { IServicesOfSuppliers } from '../../../models';

// @Utils
import { axiosEstimates } from '../../../utils/api';

export const useSendEmail = () => {
  const [servBySupp, setServBySupp] = useState<IServicesOfSuppliers[]>([]);
  const [value, setValue] = useState('');
  const [pageSize, setPageSize] = useState(5);
  const [page, setPage] = useState(0);
  const [selectedRows, setSelectedRows] = useState([]);
  const [open, setOpen] = useState(false);

  const { t } = useTranslation(['common']);

  const handleShow = (render: boolean) => {
    setOpen(render);
  };

  const consultSBS = (value: string) => {
    (async () => {
      try {
        const filterValue = value;

        const sbs = await axiosEstimates.get<IServicesOfSuppliers[]>('allsuppliers', {
          params: { filterValue },
          headers: { 'x-module': 'blast_mail' },
        });
        setServBySupp(sbs.data);
      } catch {
        setServBySupp([]);
      }
    })();
  };

  useEffect(() => {
    consultSBS(value);
  }, [value]);

  const handleFilter = (value: string) => {
    setValue(value);
  };

  const selectRows = (newSelection: any) => {
    setSelectedRows(newSelection);
  };

  return {
    servBySupp,
    t,
    handleFilter,
    page,
    pageSize,
    selectRows,
    selectedRows,
    setPage,
    setPageSize,
    open,
    handleShow,
    value,
  };
};
