import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

// @Redux
import { RootState } from '../../redux/reducers';
import { useAppDispatch } from '../../redux/store';
import { reset, setType } from '../../redux/slices/budget-form';
import { getTransportations } from '../../redux/slices/transportation/actions-async';
import { getServiceTypes } from '../../redux/slices/services/actions-async';

export function useBudgetForm() {
  const {
    budgetForm: { currentStep, steps, type },
  } = useSelector((state: RootState) => state);

  const [searchParams] = useSearchParams();

  const dispatch = useAppDispatch();

  useEffect(() => {
    const type = searchParams.get('type');

    if (type) dispatch(setType({ type: parseInt(type) }));
    dispatch(getTransportations());
    dispatch(getServiceTypes());
    return () => {
      dispatch(reset());
    };
  }, []);

  return {
    currentStep,
    steps,
    type,
  };
}
