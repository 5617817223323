import { useState } from 'react';
import { useTranslation } from 'react-i18next';

// @MUI Components
import { IconButton, TableCell, TableRow, Typography, Box, CardHeader, Divider } from '@mui/material';

// @Icons
import { PencilOutline } from 'mdi-material-ui';
import DeleteOutline from 'mdi-material-ui/DeleteOutline';

// @Components
import Pagination from '../../../components/Pagination';
import TableComponent from '../../../components/TableContainer';

// @Models
import { ServiceCategoryModel } from '../../../models/categories';
import Loader from '../../../components/Loader';

interface IProps {
  t: any;
  tableIsLoading: boolean;
  categories: ServiceCategoryModel[];
  permissions: string[];
  handleEdit: (render: boolean, data: ServiceCategoryModel) => void;
  handleDelete: (id: number) => void;
}

interface IRowProps {
  row: any;
  canEdit: boolean;
  canDelete: boolean;
  i18n: any;
  handleEdit: (render: boolean, data: ServiceCategoryModel) => void;
  handleDelete: (id: number) => void;
}

const Row = ({
  row,
  // canEdit,
  canDelete,
  handleEdit,
  handleDelete,
  i18n,
}: IRowProps) => {
  const { t } = useTranslation(['common']);

  return (
    <TableRow>
      <TableCell>
        <Typography
          noWrap
          sx={{
            textTransform: 'capitalize',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          {i18n.language === 'es' ? row.name : row.nameEN !== null ? row.nameEN : row.name}
          <Typography component='span' sx={{ fontSize: '0.8rem', color: 'text.secondary' }}>
            {row.username}
          </Typography>
        </Typography>
      </TableCell>

      <TableCell align='center'>
        {row.canEdit && (
          <IconButton color='info' onClick={() => handleEdit(true, row)} title={`${t('general.edit')}`}>
            <PencilOutline fontSize='medium' />
          </IconButton>
        )}

        {canDelete && (
          <IconButton color='error' onClick={() => handleDelete(row.id)} title={`${t('general.delete')}`}>
            <DeleteOutline fontSize='medium' />
          </IconButton>
        )}
      </TableCell>
    </TableRow>
  );
};

export const ServiceCategoryTable = ({
  t,
  tableIsLoading,
  categories,
  permissions,
  handleEdit,
  handleDelete,
}: IProps) => {
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(5);

  const { i18n } = useTranslation();

  if (tableIsLoading) return <Loader />;

  return (
    <Box sx={{ display: 'block', height: 500, width: '100%', mb: 20 }}>
      <CardHeader sx={{ display: 'flex', textAlign: 'center' }} titleTypographyProps={{ variant: 'h6' }} />

      <Divider sx={{ margin: 0 }} />

      <TableComponent
        Row={Row}
        data={categories.slice(page * pageSize, (page + 1) * pageSize)}
        headers={[t('general.category'), t('general.actions')]}
        props={{
          canEdit: permissions.includes('edit_service_category'),
          canDelete: permissions.includes('delete_service_category'),
          handleEdit,
          handleDelete,
          i18n,
        }}
      />

      <Pagination
        count={categories.length}
        setPage={setPage}
        setPageSize={setPageSize}
        page={page}
        pageSize={pageSize}
      />
    </Box>
  );
};
