// @MUI Components
import { Card, Grid, CardContent, Button, Divider } from '@mui/material';

// @Hooks
import useForm from '../hooks/useForm';

// @Components
import { EditorWrapper, TextForm, SelectInput, TymEditor } from '../../../components';
import FileUpload from '../../../components/FileUploader/FileUpload';
import CustomSwalAlert from '../../../utils/CustomSwalAlert';

import { useNavigation } from '../../../utils';
import i18n from '../../../i18n';

const pdfType = [
  { value: 10, label: 'Ninguna', labelEN: 'None', permissionValue: '' },
  { value: 1, label: 'Nueva Cotizacion', labelEN: 'New Quote', permissionValue: 'sv_quotes' },
  { value: 2, label: 'Cotizacion US - CTIS', labelEN: 'Quote US - CTIS', permissionValue: 'usa_ctis_quotes' },
  { value: 3, label: 'Cotizacion US - CML', labelEN: 'Quote US - CML', permissionValue: 'usa_cml_quotes' },
];

export const Form = () => {
  const {
    control,
    handleSubmit,
    t,
    handleNavigate,
    imgSrc,
    setImgSrc,
    imgSign,
    setImgSign,
    imgStamp,
    setImgStamp,
    bankRef,
    headerRef,
    termsRef,
    headerENRef,
    termsENRef,
    bankENRef,
    contents,
    setContent,
    lenguage,
    formChange,
  } = useForm();

  const handleCancel = () => {
    if (formChange) {
      CustomSwalAlert(
        t('pdfSettings.alertTitle', { ns: 'common' }) || '',
        t('pdfSettings.alertText', { ns: 'common' }) || '',
        'question',
        true,
        async () => handleNavigate(),
      );
    } else handleNavigate();
  };

  const { permissions } = useNavigation();

  const arrayFilter = permissions.filter(
    (item: string) => item === 'sv_quotes' || item === 'usa_ctis_quotes' || item === 'usa_cml_quotes',
  );

  const newItems = pdfType.filter(item => arrayFilter.includes(item.permissionValue));

  const filesCaption =
    i18n.language === 'es'
      ? 'Dimenciones 340 x 170 píxeles Peso max 2mb'
      : 'Dimensions  340 x 170 pixels max Size max 2mb';

  return (
    <Grid container spacing={4} sx={{ width: '90%', ml: 'auto', mr: { xs: 0.5, md: 'auto' }, zIndex: 1 }}>
      <Card sx={{ width: '100%', mb: 20 }}>
        <CardContent sx={{ m: { xs: 2, sm: 4 } }}>
          <Grid item xs={12} sx={{ mb: 5, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <FileUpload
              title={t('general.uploadLogo')}
              file={imgSrc}
              setFile={setImgSrc}
              caption={filesCaption}
              maxMbSize={2}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: { xs: 4, md: 6 },
              flexDirection: { xs: 'column', md: 'row' },
            }}
          >
            <TextForm control={control} name='name' label={t('general.name', { ns: 'common' })} />

            <SelectInput
              disabled={newItems.length === 0}
              control={control}
              name='view'
              label={t('settings.type', { ns: 'common' })}
              items={newItems}
            />
          </Grid>

          <br />

          <Grid item xs={12}>
            <EditorWrapper>
              <TymEditor
                label={t('pdfSettings.haeder', { ns: 'common' })}
                ref={lenguage === 'es' ? headerRef : headerENRef}
                value={lenguage === 'es' ? contents.header : contents.headerEN}
                onChange={value => setContent(lenguage === 'es' ? 'header' : 'headerEN', value)}
              />
            </EditorWrapper>
          </Grid>

          <br />

          <Grid item xs={12} sm={12}>
            <TymEditor
              label={t('pdfSettings.terms', { ns: 'common' })}
              ref={lenguage === 'es' ? termsRef : termsENRef}
              value={lenguage === 'es' ? contents.terms : contents.termsEN}
              onChange={value => setContent(lenguage === 'es' ? 'terms' : 'termsEN', value)}
            />
          </Grid>

          <br />

          <Grid item xs={12} sm={12}>
            <TymEditor
              label={t('pdfSettings.bank', { ns: 'common' })}
              ref={lenguage === 'es' ? bankRef : bankENRef}
              value={lenguage === 'es' ? contents.bank : contents.bankEN}
              onChange={value => setContent(lenguage === 'es' ? 'bank' : 'bankEN', value)}
            />
          </Grid>

          <br />

          <Grid
            item
            xs={12}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: { xs: 4, md: 6 },
              flexDirection: { xs: 'column', md: 'row' },
            }}
          >
            {/* Sign Image */}
            <FileUpload
              title={t('general.uploadSign')}
              file={imgSign}
              setFile={setImgSign}
              caption={filesCaption}
              maxMbSize={2}
            />

            {/* Stamp Image */}
            <FileUpload
              title={t('general.uploadStamp')}
              file={imgStamp}
              setFile={setImgStamp}
              caption={filesCaption}
              maxMbSize={2}
            />
          </Grid>

          <Divider sx={{ width: '80%', my: 5, mx: 'auto' }} />

          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button variant='contained' sx={{ mr: 3.5 }} onClick={handleSubmit}>
              {t('budgetGuide.saveDocumentButtonTittle', { ns: 'common' })}
            </Button>

            <Button type='reset' variant='contained' color='error' onClick={handleCancel}>
              {t('budgetGuide.changeWarning.cancelButton', { ns: 'common' })}
            </Button>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};
