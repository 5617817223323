import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';

// @Hooks
import { useServices } from '../hooks/useServices';

// @Utils
import { axiosEstimates } from '../../../utils';
import CustomSwalAlert from '../../../utils/CustomSwalAlert';

export default function useDeleteService() {
  const { setReloadData } = useServices();

  const { t } = useTranslation(['common']);

  const handleDelete = (id: number) => {
    CustomSwalAlert(t('toast.warning'), t('services.alerts.confirmDeleteService'), 'warning', true, () => {
      axiosEstimates
        .delete(`api/services/${id}`)
        .then(() => {
          CustomSwalAlert(t('general.success'), t('services.alerts.deletedService'), 'success', false);
          setReloadData(true);
        })
        .catch(error => {
          if (error instanceof AxiosError) {
            CustomSwalAlert('Error', `${t(error.response?.data.error)}`, 'error', false);
          }
        });
    });
  };

  return { handleDelete };
}
