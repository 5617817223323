import { useTranslation } from 'react-i18next';

// * MUI
import { Box, Button, Switch } from '@mui/material';

// * COMPONENTS
import { SearchField } from '../../../components';
import { useServices } from '../hooks/useServices';

export default function ServicesPageHader() {
  const { handleSearch, filter, handleStatus, handleToggleForm, permissions } = useServices();

  const { t } = useTranslation(['common']);

  return (
    <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: '1rem' }}>
      <Box>
        <SearchField
          handleFilter={handleSearch}
          placeholder={t('services.form.search')}
          value={filter.serviceFilter!}
        />
      </Box>
      {permissions.includes('add_service') && (
        <Box>
          <Button variant='contained' color='primary' onClick={handleToggleForm}>
            {t('services.btn.add')}
          </Button>
        </Box>
      )}
      <Box>
        <Switch
          color='primary'
          name='status'
          checked={filter.status === 'active'}
          inputProps={{ 'aria-label': 'controlled' }}
          onChange={e => handleStatus(e.target.checked ? 'active' : 'inactive')}
        />
      </Box>
    </Box>
  );
}
