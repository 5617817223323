import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';

// @MUI Components
import es from 'date-fns/locale/es';
import { FormControl, TextField } from '@mui/material/';

// @MUI Datepickers
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider, DesktopDatePicker, MobileDatePicker } from '@mui/x-date-pickers/';

// @Utils
import { breakpoints } from '../../../utils';

// @Interfaces and Types
interface IProps {
  name: string;
  control: any;
}

export default function DateInput({ name, control }: IProps) {
  const { sm } = breakpoints;

  const { t } = useTranslation('common');

  const currentLanguage = i18next.language;

  const ResposiveDatePicker = window.innerWidth < sm ? MobileDatePicker : DesktopDatePicker;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange, ...othersFields } }) => (
        <FormControl fullWidth>
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={currentLanguage === 'es' ? es : undefined}>
            <ResposiveDatePicker
              value={value}
              label={t('general.date')}
              inputFormat='MM/dd/yyyy'
              views={['year', 'month', 'day']}
              onChange={(date: any | null) => {
                if (!isNaN(date) && date instanceof Date) onChange(date.toISOString());
              }}
              renderInput={params => (
                <TextField value={value} {...params} {...othersFields} inputProps={{ ...params.inputProps }} />
              )}
            />
          </LocalizationProvider>
        </FormControl>
      )}
    />
  );
}
