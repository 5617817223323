// @Library
import { useWatch, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// @Mui
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

// @Components
import { ReactSelectForm, TextForm, SelectInput, IntegerField, ButtonForm } from '../../../components';

// @Models
import { TypeUnit, ShipmentCreate } from '../models';

// @Utils
import { typeUnit } from '../utils';
import { defaultShipmentValues, shipmentCreateDtoValidationSchema } from '../validations';

// @Hooks
import { useCustomerInteraction, useRedux } from '../hooks';

export function ShipmentDetailsView() {
  const { t } = useTranslation();
  const { countryItems } = useRedux();
  const { handleBack, countries, selectedServices, handleCreateBudget } = useCustomerInteraction();
  const [unit, setUnit] = useState<TypeUnit | undefined>();

  const {
    control,
    setValue,
    handleSubmit,
    formState: { isValid },
  } = useForm<ShipmentCreate>({
    defaultValues: {
      ...defaultShipmentValues,
      ...countries,
    },
    resolver: yupResolver(shipmentCreateDtoValidationSchema),
    // mode: 'onChange',
  });

  const length = useWatch({ control, name: 'length' });
  const high = useWatch({ control, name: 'height' });
  const width = useWatch({ control, name: 'width' });
  const unitOfMeasurement = useWatch({ control, name: 'unitMeasurement' });

  useEffect(() => {
    const weight = length * high * width;
    // const unit = typeUnit.find(unit => unit.value === unitOfMeasurement);
    if (unit) {
      setValue('weightVol', Math.ceil(weight / unit.scale));
      setValue('area', Math.ceil(weight / unit.scaleArea));
    }
  }, [length, high, width]);

  useEffect(() => {
    // setValue('unitOfMeasurement', unitOfMeasurement);
    const unit = typeUnit.find(unit => unit.value === unitOfMeasurement);
    setUnit(unit);
    setValue('height', 0);
    setValue('length', 0);
    setValue('width', 0);
    setValue('weightPhysical', 0);
  }, [unitOfMeasurement]);

  const onSubmit = async (data: ShipmentCreate) => {
    data.budgetDetails = selectedServices.map(service => ({
      idServicesSuppliers: service.id,
      amountOfIva: service.saleType === 3 ? service.salePrice * 0.13 : 0,
      amountOfSubtotal: service.salePrice,
      amountOfTotal: service.salePrice + (service.saleType === 3 ? service.salePrice * 0.13 : 0),
      commission: service.comission,
      idUser: service.idUser,
      isCrating: false,
      quantity: 1,
      taxation: 0,
      typeSale: service.saleType,
      unitPrice: service.salePrice,
    }));

    await handleCreateBudget(data);
  };

  return (
    <Box>
      <Divider sx={{ margin: 0 }} />
      <Grid container spacing={5} sx={{ my: 1 }}>
        <Grid item xs={12} sm={6}>
          <ReactSelectForm
            control={control}
            name='countryOrigin'
            label={t('select.origin', { ns: 'common' })}
            items={countryItems}
            disabled
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <ReactSelectForm
            control={control}
            name='countryDestiny'
            label={t('select.destiny', { ns: 'common' })}
            items={countryItems}
            disabled
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextForm control={control} name='addressOrigin' label={t('shipment.addressOrigin', { ns: 'common' })} />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextForm control={control} name='addressDestiny' label={t('shipment.addressDestiny', { ns: 'common' })} />
        </Grid>

        <Grid item xs={12}>
          <TextForm control={control} name='content' label={t('general.content', { ns: 'common' })} />
        </Grid>

        {/* <Grid item xs={12} sm={4}>
          <NumberField control={control} name='insurance' label={`${t('shipment.insurance', { ns: 'common' })}`} />
        </Grid> */}

        <Grid item xs={12} sm={3}>
          <SelectInput
            control={control}
            name='unitMeasurement'
            label={t('shipment.unitOfMeasurement', { ns: 'common' })}
            items={typeUnit}
          />
        </Grid>

        <Grid item xs={12} sm={3}>
          <IntegerField
            control={control}
            name='length'
            label={`${t('shipment.length', { ns: 'common' })} (${unit?.size || ''})`}
          />
        </Grid>

        <Grid item xs={12} sm={3}>
          <IntegerField
            control={control}
            name='height'
            label={`${t('shipment.high', { ns: 'common' })} (${unit?.size || ''})`}
          />
        </Grid>

        <Grid item xs={12} sm={3}>
          <IntegerField
            control={control}
            name='width'
            label={`${t('shipment.width', { ns: 'common' })} (${unit?.size || ''})`}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <IntegerField
            control={control}
            name='weightPhysical'
            label={`${t('shipment.physicalWeight', { ns: 'common' })} (${unit?.weight || ''})`}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <IntegerField
            control={control}
            name='area'
            label={`${t('shipment.area', { ns: 'common' })} (${unit?.area || ''})`}
            disabled
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <IntegerField
            control={control}
            name='weightVol'
            label={`${t('shipment.volumetricWeight', { ns: 'common' })} (${unit?.weight || ''})`}
            disabled
          />
        </Grid>

        <Grid item xs={12}>
          <TextForm
            control={control}
            name='comments'
            label={t('general.comments', { ns: 'common' })}
            multiline
            minRows={4}
          />
        </Grid>
      </Grid>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-around',
          alignItems: 'center',
          p: 2,
        }}
      >
        <ButtonForm label={t('general.back', { ns: 'common' })} color='error' onClick={handleBack} />
        <ButtonForm
          label={t('general.send', { ns: 'common' })}
          color='info'
          onClick={handleSubmit(onSubmit)}
          disabled={!isValid}
        />
      </Box>
    </Box>
  );
}
