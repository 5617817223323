import { Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

// @Mui
import Grid from '@mui/material/Grid';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import List from '@mui/material/List';

// @Models
import { SendForm } from '../models';

// @Components
import { MultipleServicesTypeFilter, MultipleTransportationFilter } from '../../../../components';
import { ISelectInput } from '../../../../models';

interface Props {
  control: Control<SendForm, any>;
  handleAddEmail: (email: string[]) => void;
  addNewOption: (options: string[], params: any) => string[];
  menuItemsArr: string[];
  emailTo: string[];
  transportations: ISelectInput<number>[];
}

export function SupplierFilter({
  control,
  addNewOption,
  handleAddEmail,
  emailTo,
  menuItemsArr,
  transportations,
}: Props) {
  const { t } = useTranslation(['common']);

  return (
    <Grid container sx={{ margin: '1rem 0' }}>
      <Grid item xs={12} sx={{ display: 'flex', gap: 4, alignItems: 'center', justifyContent: 'space-between' }}>
        <Box sx={{ width: '100%' }}>
          <MultipleServicesTypeFilter
            control={control}
            watcherValue={[]}
            name='serviceTypes'
            label={t('services.type')}
            categoriesId={transportations.map(category => category.value)}
          />
        </Box>

        <Box sx={{ width: '100%' }}>
          <MultipleTransportationFilter control={control} name='transportTypes' label={t('general.category')} />
        </Box>
      </Grid>

      <br />

      <Grid item xs={12}>
        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ justifyContent: 'flex-start' }}>
            <InputLabel sx={{ mt: 4, mb: 1, fontSize: '0.975rem' }} htmlFor='email-to-select'>
              {t('general.sendTo')}
            </InputLabel>
          </Box>

          <Autocomplete
            multiple
            freeSolo
            value={emailTo}
            clearIcon={false}
            filterSelectedOptions
            options={menuItemsArr}
            onChange={(_, item: any) => handleAddEmail(item)}
            ListboxComponent={List}
            filterOptions={addNewOption}
            getOptionLabel={option => option}
            sx={{
              width: '100%',
              '& .MuiOutlinedInput-root': { p: 0 },
              '& .MuiAutocomplete-endAdornment': { display: 'none' },
            }}
            renderInput={params => <TextField {...params} />}
          />
        </Box>
      </Grid>
    </Grid>
  );
}
