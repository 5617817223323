// @MUI Components
import { Menu, MenuItem } from '@mui/material';

// @Types
import { MenuOptionsProps } from '../types';

/**
 * @component
 * @category Budget
 *
 * @description
 * A menu component that provides navigation options for budget pages.
 *
 * #### Example
 * ```jsx
 * return(
 * <MenuOptions
 *   id={1}
 *   navigate={(path) => console.log(path)}
 *   menuOptionsAnchorEl={null}
 *   setMenuOptionsAnchorEl={(el) => console.log(el)}
 *   firstOptionText="Option 1"
 *   secondOptionText="Option 2"
 *   navigationFirstOptionText="nav1"
 *   navigationSecondOptionText="nav2"
 *   navigationThirdOptionPath="path3"
 *   navigationFourthOptionPath="path4"
 *   isSecondOptionDisabled={false}
 *   onCallBackFirstButtonClick={() => console.log('First button clicked')}
 *   onCallBackSecondButtonClick={() => console.log('Second button clicked')}
 * />
 * )
 * ```
 *
 * @property {number} id The ID of the budget page.
 * @property {boolean} [hasDocument] Indicates if has document.
 * @property {function} [navigate] A function to navigate to a new path.
 * @property {Element} [menuOptionsAnchorEl] The anchor element of the menu.
 * @property {function} setMenuOptionsAnchorEl A function to set the anchor element of the menu.
 * @property {string} firstOptionText The text of the first menu option.
 * @property {string} secondOptionText The text of the second menu option.
 * @property {string} [navigationFirstOptionText] The text of the first navigation option.
 * @property {string} [navigationSecondOptionText] The text of the second navigation option.
 * @property {string} [navigationThirdOptionPath] The path of the third navigation option.
 * @property {string} [navigationFourthOptionPath] The path of the fourth navigation option.
 * @property {boolean} [isSecondOptionDisabled] Whether the second option is disabled.
 * @property {function} [onCallBackFirstButtonClick] A callback function for the first button click.
 * @property {function} [onCallBackSecondButtonClick] A callback function for the second button click.
 *
 * @return {JSX.Element} A JSX element representing the menu component.
 * @author CML Exports Front-End Developers
 */
export const MenuOptions: React.FC<MenuOptionsProps> = ({
  id,
  navigate,
  menuOptionsAnchorEl,
  setMenuOptionsAnchorEl,
  firstOptionText,
  secondOptionText,
  navigationFirstOptionText,
  navigationSecondOptionText,
  navigationThirdOptionPath,
  navigationFourthOptionPath,
  isSecondOptionDisabled,
  onCallBackFirstButtonClick,
  onCallBackSecondButtonClick,
}) => {
  const open = Boolean(menuOptionsAnchorEl);

  const handleClose = () => setMenuOptionsAnchorEl(null);

  const firstOption = () => {
    if (onCallBackFirstButtonClick) onCallBackFirstButtonClick();
    if (onCallBackFirstButtonClick) return;
    handleClose();
    if (navigate) navigate(`${navigationFirstOptionText}/${id}${slashFirst ? '/' : null}${navigationSecondOptionText}`);
  };
  const secondOption = () => {
    if (onCallBackSecondButtonClick) onCallBackSecondButtonClick();
    if (onCallBackSecondButtonClick) return;
    handleClose();
    if (navigate) navigate(`${navigationThirdOptionPath}/${id}${slash ? '/' : null}${navigationFourthOptionPath}`);
  };
  const slashFirst = navigationSecondOptionText;
  const slash = navigationFourthOptionPath;

  return (
    <Menu anchorEl={menuOptionsAnchorEl} open={open} onClose={handleClose}>
      <MenuItem onClick={firstOption}>{firstOptionText}</MenuItem>
      <MenuItem onClick={secondOption} disabled={isSecondOptionDisabled}>
        {secondOptionText}
      </MenuItem>
    </Menu>
  );
};
