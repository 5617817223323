import { MutableRefObject, useRef } from 'react';
import { Editor as Tym } from '@tinymce/tinymce-react';

// @MUI Components
import Typography from '@mui/material/Typography';

// @Interfaces and Types
interface IProps {
  label: string;
  ref?: MutableRefObject<any>;
  value?: string;
  onChange: (value: string) => void;
  resize?: boolean;
  height?: string | number | undefined;
}

/**
 * @component
 * @subcategory Global
 *
 * @description
 * The TymEditor component is a reusable component that provides a TinyMCE editor.
 * It uses the `@tinymce/tinymce-react` library for the editor and `@mui/material` for UI components.
 *
 * #### Example
 * ```
 * return(
 * <TymEditor
 *   label="Editor"
 *   value="Initial value"
 *   onChange={(value) => console.log(value)}
 *   resize={true}
 *   height={500}
 * />
 * )
 * ```
 *
 * @property {string} label The label for the editor
 * @property {MutableRefObject<any>} [ref] The callback function for when the editor value changes
 * @property {string} [value=''] The initial value for the editor
 * @property {function} onChange The callback function for when the editor value changes
 * @property {boolean} [resize] Whether the editor should be resizable
 * @property {number | string} [height=500] The height of the editor
 * @property {Object} props The additional component props
 *
 * @return {JSX.Element} A JSX element representing the TymEditor component.
 * @author CML Exports Front-End Developers
 */
export function TymEditor({ label, value = '', onChange, resize, height = 500 }: IProps) {
  // const editorRef = ref;
  const editorRef = useRef(null) as MutableRefObject<any>;

  return (
    <div>
      <Typography variant='h6' sx={{ ml: 2, mb: 1 }}>
        {label}
      </Typography>

      <Tym
        apiKey={process.env.REACT_APP_TINY_KEY}
        onInit={(evt, editor) => (editorRef.current = editor)}
        initialValue={value}
        onKeyPress={() => {
          if (editorRef.current) {
            onChange(editorRef.current.getContent());
          }
        }}
        onKeyDown={() => {
          if (editorRef.current) {
            onChange(editorRef.current.getContent());
          }
        }}
        onKeyUp={() => {
          if (editorRef.current) {
            onChange(editorRef.current.getContent());
          }
        }}
        onChange={() => {
          if (editorRef.current) {
            onChange(editorRef.current.getContent());
          }
        }}
        init={{
          height,
          resize,
          menubar: true,
          branding: false,
          plugins: [
            // 'autolink link image charmap print preview anchor',
            // 'searchreplace visualblocks code fullscreen',
            // 'paste code help wordcount',
            'code',
            'table',
            'link',
            'image',
            'insertdatetime',
            'media',
            'charmap',
            'table',
            'charmap',
            'autolink',
          ],
          toolbar:
            'undo redo | formatselect | ' +
            'bold italic backcolor | alignleft aligncenter ' +
            'alignright alignjustify | bullist numlist outdent indent | ' +
            'table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol' +
            'removeformat | help | ' +
            'insertdatetime  |' +
            'numlist' +
            'code' +
            'image' +
            'table',
          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
          table_toolbar:
            'tableprops tabledelete | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol',
          insertdatetime_dateformat: '%d-%m-%Y',
          advlist_number_styles: 'lower-alpha',
          image_list: [
            { title: 'My image 1', value: 'https://www.example.com/my1.gif' },
            { title: 'My image 2', value: 'http://www.moxiecode.com/my2.gif' },
          ],
          charmap: [[0x2615, 'morning coffee']],
        }}
      />
    </div>
  );
}
