import React from 'react';
import ReactDOM from 'react-dom/client';

// @Redux
import store from './redux/store';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { ThemeProvider } from '@mui/material/styles';
import { customTheme } from './utils/customTheme.util';

// @Translations
import './i18n';

// @Components
import { App } from './App';

// @Styled
import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root')!);
const persist = persistStore(store);

root.render(
  <React.StrictMode>
    <PersistGate persistor={persist}>
      <Provider store={store}>
        <ThemeProvider theme={customTheme}>
          <App />
        </ThemeProvider>
      </Provider>
    </PersistGate>
  </React.StrictMode>,
);
