// @Imports Librairies
import { createContext, useState, ReactNode, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

// @Services
import { getBudget, getDocumentsByBudget } from '../services';

// @Models
import { DocumentContextModel, GetBudgetData, Document } from '../models';

// @Utils
import { getTransportations } from '../utils';

interface Props {
  children: ReactNode;
}

const defaultContext: DocumentContextModel = {
  documents: [],
  budget: undefined,
  transportations: [],
  loading: true,
  error: false,
  handleNavigatePrint: () => {},
  formChanged: false,
  handleFormChange: () => {},
  handleFormChange2: () => {},
};

export const DocumentContext = createContext<DocumentContextModel>(defaultContext);

export const DocumentProvider = ({ children }: Props) => {
  const [documents, setDocuments] = useState<Document[]>([]);
  const [budget, setBudget] = useState<GetBudgetData | undefined>(undefined);
  const [transportations, setTransportations] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean | string>(false);

  const [formChanged, setFormChanged] = useState<boolean>(false);

  const navigate = useNavigate();

  const { id } = useParams<{ id: string }>();

  const handleGetBudget = async () => {
    setLoading(true);
    try {
      if (id) {
        const response = await getBudget(id);
        setBudget(response);
        setTransportations(getTransportations(response.budget));
      }

      setLoading(false);
    } catch (error: any) {
      setError(error.response.data.data.message || true);
      setLoading(false);
    }
  };

  const handleGetDocuments = async () => {
    setLoading(true);

    try {
      if (id) {
        const response = await getDocumentsByBudget(id);
        setDocuments(response);
      }

      setLoading(false);
    } catch (error: any) {
      setError(error.response.data.data.message || true);
      setLoading(false);
    }
  };

  const handleFormChange = () => {
    setFormChanged(true);
  };

  const handleFormChange2 = () => {
    setFormChanged(false);
  };

  const handleNavigatePrint = (id: string | number, backUrl: string) => {
    // navigate('/documents/print/' + id + '?budgetId=' + budget?.budget.id + '&backUrl=' + backUrl);
    navigate(`/documents/print/${id}?budgetId=${budget?.budget.id}&backUrl=${backUrl}`);
  };

  useEffect(() => {
    handleGetBudget();
    handleGetDocuments();

    return () => {
      setDocuments([]);
      setBudget(undefined);
      setTransportations([]);
      setLoading(false);
      setError(false);
      setFormChanged(false);
    };
  }, []);

  const value: DocumentContextModel = {
    documents,
    budget,
    transportations,
    loading,
    error,
    handleNavigatePrint,
    formChanged,
    handleFormChange,
    handleFormChange2,
  };

  return <DocumentContext.Provider value={value}>{children}</DocumentContext.Provider>;
};
