/* eslint-disable unused-imports/no-unused-imports */
import { Button, Card, Grid, Typography, IconButton } from '@mui/material';
import React from 'react';
import { TextForm, DatePicker } from '../../../../components';
import useManifiestoMaritimo from './use-ManifiestoMaritimo';
import { useLocation } from 'react-router-dom';
import CancelIcon from '@mui/icons-material/Cancel';
import { useTranslation } from 'react-i18next';

interface CharacterCounterProps {
  value: string;
  maxLength: number;
}

const ManifiestoMaritimoForm = () => {
  const { t, i18n } = useTranslation('common');

  const location = useLocation();

  const { control, contentFields, contentAppend, contentRemove, onSubmit, navigate, documentId } =
    useManifiestoMaritimo();

  return (
    <Grid container spacing={2} rowGap={2}>
      <Grid item xs={12} sm={6}>
        <DatePicker control={control} name='expeditionDate' label={t('pdfs.expeditionDate', { ns: 'placeholder' })} />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextForm
          translationFile='validations'
          rules={{ maxLength: 10 }}
          control={control}
          label={t('general.id')}
          name='numberID'
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextForm
          translationFile='validations'
          control={control}
          label={t('pdfs.agent', { ns: 'placeholder' })}
          name='agent'
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextForm
          translationFile='validations'
          control={control}
          label={t('pdfs.carrier', { ns: 'placeholder' })}
          name='carrier'
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextForm
          translationFile='validations'
          control={control}
          label={t('BL.portOfLoading', { ns: 'placeholder' })}
          name='shipmentPort'
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextForm
          translationFile='validations'
          control={control}
          label={t('BL.portOfDischarge', { ns: 'placeholder' })}
          name='arrivalPort'
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <DatePicker control={control} name='depeartureDate' label={t('pdfs.departure', { ns: 'placeholder' })} />
      </Grid>

      <Grid item xs={12} sm={6}>
        <DatePicker control={control} name='arrivalDate' label={t('pdfs.arrival', { ns: 'placeholder' })} />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextForm
          translationFile='validations'
          control={control}
          label={t('pdfs.trip', { ns: 'placeholder' })}
          name='trip'
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextForm
          translationFile='validations'
          control={control}
          label={t('pdfs.entry', { ns: 'placeholder' })}
          name='entry'
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextForm translationFile='validations' control={control} label={t('general.destiny')} name='destiny' />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextForm
          translationFile='validations'
          control={control}
          label={t('pdfs.consignatario', { ns: 'placeholder' })}
          name='consignee'
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextForm
          translationFile='validations'
          control={control}
          label={t('pdfs.contentDescription', { ns: 'placeholder' })}
          name='contentDescription'
        />
      </Grid>

      <Grid item xs={12}>
        <Grid container columnSpacing={1} rowSpacing={1}>
          <Grid item xs={12} sx={{ textAlign: 'center' }}>
            <Typography sx={{ textAlign: 'center' }} variant={'subtitle1'}>
              {t('general.content')}
            </Typography>
          </Grid>

          {contentFields.map((field, index) => {
            return (
              <Grid key={field.id} item xs={12}>
                <Card variant='outlined' sx={{ p: 5, position: 'relative' }}>
                  <Grid container columnSpacing={2} rowSpacing={4}>
                    <Grid item xs={12} sm={6} md={3}>
                      <TextForm
                        translationFile='validations'
                        control={control}
                        label={t('pdfs.cnt', { ns: 'placeholder' })}
                        name={`content.${index}.cnt`}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={3}>
                      <TextForm
                        translationFile='validations'
                        control={control}
                        label={t('pdfs.bl', { ns: 'placeholder' })}
                        name={`content.${index}.bl`}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={3}>
                      <TextForm
                        translationFile='validations'
                        control={control}
                        label={t('pdfs.provider', { ns: 'placeholder' })}
                        name={`content.${index}.provider`}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={3}>
                      <TextForm
                        translationFile='validations'
                        control={control}
                        label={t('pdfs.totalPackages', { ns: 'placeholder' })}
                        name={`content.${index}.totalPackages`}
                      />
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <TextForm
                        translationFile='validations'
                        control={control}
                        label={t('pdfs.weight', { ns: 'placeholder' })}
                        name={`content.${index}.weight`}
                      />
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <TextForm
                        translationFile='validations'
                        control={control}
                        label={t('pdfs.volumen', { ns: 'placeholder' })}
                        name={`content.${index}.volumen`}
                      />
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <TextForm
                        translationFile='validations'
                        control={control}
                        label={t('pdfs.invoices', { ns: 'placeholder' })}
                        name={`content.${index}.invoices`}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextForm
                        translationFile='validations'
                        control={control}
                        label={t('pdfs.contentProduct', { ns: 'placeholder' })}
                        name={`content.${index}.contentDescription`}
                      />
                    </Grid>

                    {index > 0 && (
                      <Grid item xs={12}>
                        <IconButton
                          onClick={() => contentRemove(index)}
                          color='error'
                          sx={{
                            position: 'absolute',
                            bottom: 0,
                            left: 0,
                            fontSize: '16px',
                            paddingLeft: '32px',
                            paddingTop: '4px',
                          }}
                        >
                          <CancelIcon />
                          {t('general.deleteContent')}
                        </IconButton>
                      </Grid>
                    )}
                  </Grid>
                </Card>
              </Grid>
            );
          })}
          <Grid item xs={12} sx={{ textAlign: 'center' }}>
            <Button
              sx={{ color: '#00ef14', margin: 'auto' }}
              disabled={contentFields.length >= 6}
              type='button'
              onClick={() => {
                if (contentFields.length < 6) {
                  contentAppend({
                    cnt: '',
                    bl: '',
                    provider: '',
                    totalPackages: null,
                    weight: null,
                    volumen: null,
                    invoices: '',
                    contentDescription: '',
                  });
                }
              }}
            >
              <i className='fa-solid fa-circle-plus text-xl text-green-500 mr-2' />
              <p>{t('airGuide.addline', { ns: 'placeholder' })}</p>
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        item
        xs={12}
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          justifyContent: 'center',
          alignItems: 'center',
          gap: 2,
        }}
      >
        <Button variant='contained' onClick={onSubmit}>
          {documentId === null
            ? i18n.language === 'es'
              ? 'Guardar'
              : 'Save'
            : t('budgetGuide.saveDocumentButtonTittle')}
        </Button>

        {documentId !== null && (
          <Button
            variant='contained'
            onClick={() => navigate(documentId, location.pathname + '&value=5')}
            color='secondary'
          >
            {t('budgetGuide.viewDocumentButtonTittle')}
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

export default ManifiestoMaritimoForm;
