import { Budget } from '../models';

export function getTransportations(budget: Budget): string[] {
  const { shipments } = budget;

  const transportations: string[] = [];
  shipments.forEach(shipment => {
    shipment.budgetDetails.forEach(budgetDetail => {
      const serviceType = budgetDetail.servicesSuppliers.service.serviceTypes;
      const hasTransportations = serviceType.serviceTypesToTransportation;

      if (hasTransportations) {
        hasTransportations.forEach(serviceTypesToTransportation => {
          const transportation = serviceTypesToTransportation.transportation;
          if (!transportations.includes(transportation.name)) {
            transportations.push(transportation.name);
          }
        });
      }
    });
  });

  return transportations;
}
