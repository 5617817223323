// @Utils
import { axiosEstimates } from '../../../utils';

// @Interfaces and Types
import { IServicesOfSuppliersAdd } from '../../../models';

const sendServiceBudget = async (idSupplierBudget: string, data: IServicesOfSuppliersAdd, files: File[]) => {
  try {
    const response = await axiosEstimates.put<{ relationship: boolean }>(`supplier-budget/${idSupplierBudget}`, data);

    if (files && files.length) {
      const fileData = new FormData();

      fileData.set('quotationCode', data.quotationCode);
      files.forEach((file, i) => fileData.set(`file-${i + 1}`, file));

      await axiosEstimates.put(`suppliers-files/${idSupplierBudget}`, fileData);
    }

    return response.data;
  } catch (err) {
    console.error(err);
  }
};

export default sendServiceBudget;
