import Select, { components } from 'react-select';
import { Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/reducers';
import { useTranslation } from 'react-i18next';

// @Views
import { FormActions } from '../form-actions/form-actions.view';

// @Hooks
import { useCustomerForm } from './use-customer-form.hook';
import { Box, Card, CardContent, FormHelperText, Divider, Grid, InputLabel, TextField } from '@mui/material';

export function CustomerForm() {
  const { control, errors, onSubmit, customers } = useCustomerForm();

  const {
    transportation: { permissions },
  } = useSelector((state: RootState) => state);

  const { t } = useTranslation(['common']);

  const NoOptionsMessage = (props: any) => {
    return (
      <components.NoOptionsMessage {...props}>
        <span> {t('customerInteraction.error.noCustomer')} </span>
      </components.NoOptionsMessage>
    );
  };

  return (
    <Box sx={{ p: 2 }}>
      <Card>
        <CardContent>
          <Grid container spacing={3} sx={{ p: 2, display: 'flex' }}>
            <Grid item xs={12}>
              <InputLabel htmlFor='customer'>{t('general.customer', { ns: 'common' })}</InputLabel>

              <Controller
                name='customerId'
                control={control}
                rules={{ required: { value: true, message: 'errors.required' } }}
                render={({ field: { value, onChange, onBlur, ref } }) => (
                  <Select
                    ref={ref}
                    onBlur={onBlur}
                    id='customerId'
                    options={customers}
                    components={{ NoOptionsMessage }}
                    onChange={options => onChange(options?.value)}
                    placeholder={t('select.customer', { ns: 'common' })}
                    value={customers?.find(customer => customer.value === value)}
                  />
                )}
              />
              {errors.customerId && (
                <FormHelperText sx={{ color: 'error.main' }}>{t(`${errors.customerId.message}`)}</FormHelperText>
              )}
            </Grid>

            <Divider />

            <Grid item xs={6} sm={4}>
              <InputLabel htmlFor='identifier'>{t('general.identifier', { ns: 'common' })}</InputLabel>

              <Controller
                name='identifier'
                control={control}
                rules={{ required: { value: false, message: 'errors.required' } }}
                render={({ field }) => <TextField {...field} size='small' fullWidth disabled />}
              />
              {errors.identifier && (
                <FormHelperText sx={{ color: 'error.main' }}>{t(`${errors.identifier.message}`)}</FormHelperText>
              )}
            </Grid>

            <Grid item xs={6} sm={4}>
              <InputLabel htmlFor='phone'>{t('general.phone', { ns: 'common' })}</InputLabel>

              <Controller
                name='phone'
                control={control}
                rules={{ required: { value: false, message: 'errors.required' } }}
                render={({ field }) => <TextField {...field} size='small' fullWidth disabled />}
              />
              {errors.phone && (
                <FormHelperText sx={{ color: 'error.main' }}>{t(`${errors.phone.message}`)}</FormHelperText>
              )}
            </Grid>

            <Grid item xs={12} sm={4}>
              <InputLabel htmlFor='email'>{t('general.email', { ns: 'common' })}</InputLabel>

              <Controller
                name='email'
                control={control}
                rules={{ required: { value: false, message: 'errors.required' } }}
                render={({ field }) => <TextField disabled fullWidth size='small' {...field} />}
              />
              {errors.email && (
                <FormHelperText sx={{ color: 'error.main' }}>{t(`${errors.email.message}`)}</FormHelperText>
              )}
            </Grid>

            <Grid item xs={6} sm={4}>
              <InputLabel htmlFor='country'>{t('general.country', { ns: 'common' })}</InputLabel>

              <Controller
                name='country'
                control={control}
                rules={{ required: { value: false, message: 'errors.required' } }}
                render={({ field }) => <TextField {...field} size='small' fullWidth disabled />}
              />
              {errors.country && (
                <FormHelperText sx={{ color: 'error.main' }}>{t(`${errors.country.message}`)}</FormHelperText>
              )}
            </Grid>

            <Grid item xs={6} sm={4}>
              <InputLabel htmlFor='postalCode'>{t('general.postalcode', { ns: 'common' })}</InputLabel>

              <Controller
                name='postalCode'
                control={control}
                rules={{ required: { value: false, message: 'errors.required' } }}
                render={({ field }) => <TextField {...field} size='small' fullWidth disabled />}
              />
              {errors.postalCode && (
                <FormHelperText sx={{ color: 'error.main' }}>{t(`${errors.postalCode.message}`)}</FormHelperText>
              )}
            </Grid>

            <Grid item xs={6} sm={4}>
              <InputLabel htmlFor='state'>{t('general.state', { ns: 'common' })}</InputLabel>

              <Controller
                name='state'
                control={control}
                rules={{ required: { value: false, message: 'errors.required' } }}
                render={({ field }) => <TextField {...field} size='small' fullWidth disabled />}
              />
              {errors.state && (
                <FormHelperText sx={{ color: 'error.main' }}>{t(`${errors.state.message}`)}</FormHelperText>
              )}
            </Grid>

            <Grid item xs={6} sm={4}>
              <InputLabel htmlFor='code'>{t('general.city', { ns: 'common' })}</InputLabel>

              <Controller
                name='city'
                control={control}
                rules={{ required: { value: false, message: 'errors.required' } }}
                render={({ field }) => <TextField {...field} size='small' fullWidth disabled />}
              />
              {errors.city && (
                <FormHelperText sx={{ color: 'error.main' }}>{t(`${errors.city.message}`)}</FormHelperText>
              )}
            </Grid>

            <Grid item xs={12} sm={8}>
              <InputLabel htmlFor='address'>{t('general.address', { ns: 'common' })}</InputLabel>

              <Controller
                name='address'
                control={control}
                rules={{ required: { value: false, message: 'errors.required' } }}
                render={({ field }) => <TextField {...field} size='small' fullWidth disabled />}
              />
              {errors.address && (
                <FormHelperText sx={{ color: 'error.main' }}>{t(`${errors.address.message}`)}</FormHelperText>
              )}
            </Grid>

            {permissions.see_commission && (
              <Grid item xs={6} sm={4}>
                <InputLabel htmlFor='commission'>{t('general.commission', { ns: 'common' })}</InputLabel>
                <Controller
                  name='commission'
                  control={control}
                  rules={{ required: { value: false, message: 'errors.required' } }}
                  render={({ field }) => (
                    <TextField {...field} size='small' fullWidth placeholder='Commission' type='number' />
                  )}
                />
                {errors.commission && (
                  <FormHelperText sx={{ color: 'error.main' }}>{t(`${errors.commission.message}`)}</FormHelperText>
                )}
              </Grid>
            )}
          </Grid>
        </CardContent>
        <FormActions nextCallback={onSubmit} />
      </Card>
    </Box>
  );
}
