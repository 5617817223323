import { useState, useEffect } from 'react';
import { ServiceType } from '../def';
import { axiosEstimates } from '../../../utils';
import { useServices } from './useServices';

export default function useServiceType() {
  const [serviceTypes, setServiceTypes] = useState<ServiceType[]>([]);
  const [loadingServiceTypes, setLoadingServiceTypes] = useState<boolean>(true);
  const [reloadServiceTypes, setReloadServiceTypes] = useState(false);

  const { setSeviceTypeDataList, reloadData } = useServices();

  useEffect(() => {
    if ((!serviceTypes.length && loadingServiceTypes) || reloadServiceTypes || reloadData) {
      axiosEstimates
        .get<{
          serviceTypes: ServiceType[];
          permission: any;
        }>('api/services/types', {
          headers: { 'x-module': 'services' },
        })
        .then(response => {
          setServiceTypes(response.data.serviceTypes);
        })
        .catch(error => {
          console.error(error);
        })
        .finally(() => {
          setLoadingServiceTypes(false);
          setReloadServiceTypes(false);
        });
    }
  }, [serviceTypes, loadingServiceTypes, reloadServiceTypes, reloadData]);

  useEffect(() => {
    setSeviceTypeDataList(serviceTypes);
  }, [serviceTypes]);
  return {
    serviceTypes,
    setReloadServiceTypes,
  };
}
