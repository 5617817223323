import { useState, createContext, ReactNode, useEffect } from 'react';
import { axiosEstimates } from '../../utils';
import {
  ServicesContextValues,
  ServicesResponse,
  ServiceFilter,
  ServiceFormValues,
  Category,
  TypeServiceFormValues,
  ServiceType,
  ServiceViewProps,
} from './def';

export const ServicesContext = createContext({} as ServicesContextValues);

export default function ServicesProvider({ children }: { children: ReactNode }) {
  const [servicesData, setServicesData] = useState<ServicesResponse>();
  const [serviceViewData, setServiceViewData] = useState<ServiceViewProps>();
  const [categoryDataList, setCategoryDataList] = useState<Category[]>([]);
  const [seviceTypeDataList, setSeviceTypeDataList] = useState<ServiceType[]>([]);
  const [categoriesForEdit, setCateriesForEdit] = useState<Category[]>([]);
  const [permissions, setPermissions] = useState<string[]>([]);
  const [loadingData, setLoadingData] = useState<boolean>(true);
  const [reloadData, setReloadData] = useState<boolean>(false);
  const [toggleForm, setToggleForm] = useState<boolean>(false);
  const [toggleTypeForm, setToggleTypeForm] = useState<boolean>(false);
  const [toggleCategoryForm, setToggleCategoryForm] = useState<boolean>(false);
  const [toggleViewModal, setToggleViewModal] = useState<boolean>(false);
  const [actionsDropdown, setActionsDropdown] = useState<boolean>(false);
  const [serviceId, setServiceId] = useState<number>();
  const [defaultValues, setDefaultValues] = useState<ServiceFormValues>({
    categoryId: [],
    serviceType: 0,
    serviceName: '',
    serviceNameEN: '',
  });
  const [defaultServiceTypeValues, setDefaultServiceTypeValues] = useState<TypeServiceFormValues>({
    id: 0,
    name: '',
    nameEN: '',
    categories: [],
  });
  const [categoriesOptions, setCategoriesOptions] = useState<Category[]>([]);

  const [filter, setFilter] = useState<ServiceFilter>({
    page: 1,
    status: 'active',
    pageSize: 5,
    serviceFilter: '',
  });

  // * HANDLERS
  const handlePage = (pageNum: number) => {
    setFilter({ ...filter, page: pageNum });
    setReloadData(true);
  };
  const handlePageSize = (pageSizeNum: number) => {
    setFilter({ ...filter, pageSize: pageSizeNum });
    setReloadData(true);
  };
  const handleSearch = (value: string) => {
    setFilter({ ...filter, serviceFilter: value });
    setReloadData(true);
  };
  const handleStatus = (value: 'active' | 'inactive') => {
    setFilter({ ...filter, status: value });
    setReloadData(true);
  };

  const handleToggleForm = () => {
    setToggleForm(!toggleForm);
  };

  const handleToggleTypeForm = () => {
    setToggleTypeForm(!toggleTypeForm);
  };

  const handleToggleCategoryForm = (data?: Category[]) => {
    setToggleCategoryForm(!toggleCategoryForm);
    if (data) setCateriesForEdit(data);
  };

  const handleToggleActionsDropdown = () => {
    setActionsDropdown(!actionsDropdown);
  };

  const handleEdit = (id: number, data: ServiceFormValues) => {
    setServiceId(id);
    handleToggleForm();
    setDefaultValues(data);
  };

  const handleView = async (id?: number) => {
    setToggleViewModal(true);

    if (id) {
      try {
        const res = axiosEstimates.get<ServiceViewProps>('api/services/details/' + id);
        setServiceViewData((await res).data);
      } catch (error) {
        console.error(error);
      }
    } else {
      setToggleViewModal(false);
    }
  };

  useEffect(() => {
    if ((!servicesData && loadingData) || reloadData) {
      axiosEstimates
        .get<{
          services: ServicesResponse;
          permissions: any;
        }>('api/services/list', {
          params: filter,
          headers: { 'x-module': 'services' },
        })
        .then(res => {
          setServicesData(res.data.services);
          setPermissions(Object.keys(res.data.permissions));
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          setLoadingData(false);
          setReloadData(false);
        });
    }
  }, [servicesData, loadingData, reloadData]);

  useEffect(() => {
    if (!toggleForm) setServiceId(undefined);
  }, [toggleForm]);

  return (
    <ServicesContext.Provider
      value={{
        servicesData,
        loadingData,
        filter,
        handlePage,
        handlePageSize,
        handleSearch,
        handleStatus,
        setReloadData,
        handleToggleForm,
        toggleForm,
        defaultValues,
        setDefaultValues,
        serviceId,
        setServiceId,
        handleEdit,
        categoryDataList,
        setCategoryDataList,
        toggleTypeForm,
        toggleCategoryForm,
        handleToggleTypeForm,
        handleToggleCategoryForm,
        actionsDropdown,
        handleToggleActionsDropdown,
        defaultServiceTypeValues,
        setDefaultServiceTypeValues,
        categoriesOptions,
        setCategoriesOptions,
        seviceTypeDataList,
        setSeviceTypeDataList,
        handleView,
        serviceViewData,
        toggleViewModal,
        categoriesForEdit,
        setCateriesForEdit,
        permissions,
        reloadData,
      }}
    >
      {children}
    </ServicesContext.Provider>
  );
}
