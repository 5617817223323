import { useTranslation } from 'react-i18next';

// @MUI Components
import {
  CircularProgress,
  Grid,
  Card,
  Box,
  CardHeader,
  CardContent,
  Divider,
  TableCell,
  TableRow,
  Typography,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  Chip,
  Button,
} from '@mui/material';

// @Hooks

// @Utils
import { formatNumber } from '../../utils/format-number.util';

// @Hooks
import { useBudgetComissionView } from './use-budget-commission-view.hook';

// @Components
import TableComponent from '../../components/TableContainer';

// @Interfaces and Types
import { InvoiceType } from '../Invoice/types';
import chipItem from '../Budget/interfaces/chipItem';
interface IRowProps {
  row: any;
}

const Row = ({ row }: IRowProps) => {
  return (
    <TableRow>
      <TableCell>
        <Typography noWrap sx={{ textTransform: 'capitalize', textAlign: 'center' }}>
          {formatNumber(row.receivable)}
        </Typography>
      </TableCell>

      <TableCell>
        <Typography noWrap sx={{ textTransform: 'capitalize', textAlign: 'center' }}>
          {formatNumber(row.toPay)}
        </Typography>
      </TableCell>

      <TableCell>
        <Typography noWrap sx={{ textTransform: 'capitalize', textAlign: 'center' }}>
          {formatNumber(row.receivable - row.toPay)}
        </Typography>
      </TableCell>

      <TableCell>
        <Typography noWrap sx={{ textTransform: 'capitalize', textAlign: 'center' }}>
          {formatNumber(row.commission)}
        </Typography>
      </TableCell>

      <TableCell>
        <Typography noWrap sx={{ textTransform: 'capitalize', textAlign: 'center' }}>
          {formatNumber(row.receivable - row.toPay - row.commision)}
        </Typography>
      </TableCell>
    </TableRow>
  );
};

interface IProps {
  budgetId?: number;
  invoiceData?: InvoiceType;
  permissions: string[];
}

export function BudgetCommissionsView({ budgetId, invoiceData, permissions }: IProps) {
  const { budget, budgetCommission, client, totalData, createEarningsDetail } = useBudgetComissionView(
    budgetId?.toString(),
  );

  const { t } = useTranslation(['common']);

  const statusItem: { value: number; color: chipItem['color']; label: chipItem['label'] }[] = [
    { value: 1, color: 'info', label: `${t('general.pending')}` }, // Pendiente
    { value: 2, color: 'error', label: `${t('general.cancelled')}` }, // Cancelado
    { value: 3, color: 'success', label: `${t('general.approved')}` }, // Aprobado
    { value: 4, color: 'info', label: `${t('general.paid')}` }, // Pagado
  ];

  const status = (document?: { status: number }) => statusItem?.find(status => status.value === document?.status);

  if (!budget) {
    return (
      <Card sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        <CircularProgress />
      </Card>
    );
  }

  const getTotalExpenses = () => {
    if (invoiceData?.expenses?.length) {
      const totalExpenses = invoiceData?.expenses?.map(expense => (expense?.total ? expense.total : 0));
      const calculateTotal = totalExpenses?.reduce((prevNumber, currentNumber) => currentNumber + prevNumber) ?? 0;

      return calculateTotal;
    } else return 0;
  };

  // const calculateCommision = (value: number) => {
  //   const comision = budgetCommission?.commission;
  //   if (!comision) return 0;
  //   if (comision.type_amount === 1) return value * comision.amount;
  //   return comision.amount;
  // };

  const calculateCommision = (value: number | null): number => {
    const comision = budgetCommission?.commission;

    // Check if comision is null or comision.type_amount is not numeric
    if (!comision || typeof comision.type_amount !== 'number') return 0;

    // Check if value is null or not numeric
    if (value === null || typeof value !== 'number') return 0;

    if (comision.type_amount === 1) return value * comision.amount;
    return comision.amount;
  };
  const _newTotalData = {
    ...totalData,
    benefit: totalData.receivable - totalData.toPay,
    toPay: budgetCommission?.budgetExpense?.reduce((p, c) => {
      return (
        p +
        c?.expenses?.reduce((previousValue, currentValue) => {
          return previousValue + (currentValue?.subTotal ?? 0);
        }, 0)
      );
    }, 0),
    receivable: (invoiceData?.subTotal ?? 0) + getTotalExpenses(),
  };

  const _newTotalData2 = {
    ..._newTotalData,
    commission: calculateCommision(_newTotalData.receivable - (_newTotalData?.toPay ?? 0)),
  };

  const newTotalData = {
    ..._newTotalData2,
    utility: _newTotalData2.benefit - _newTotalData2.commision,
  };

  return (
    <Grid container>
      <Grid item xs={12} sx={{ mx: 'auto' }}>
        <Card>
          <CardHeader
            title={`${budget.budget.code} - ${client}`}
            sx={{ display: 'flex', textAlign: 'center' }}
            titleTypographyProps={{ variant: 'h6' }}
          />

          <Divider sx={{ margin: 0 }} />

          <CardContent>
            <TableContainer component={Paper} sx={{ boxShadow: 'none' }}>
              <Table aria-label='collapsible table' sx={{ boxShadow: 'none' }}>
                <TableHead sx={{ backgroundColor: 'primary.main' }}>
                  <TableRow sx={{ backgroundColor: 'primary.main' }}>
                    <TableCell
                      sx={{
                        color: '#fff',
                        fontWeight: 'bold',
                        backgroundColor: 'primary.main',
                        borderBlockColor: 'primary.main',
                      }}
                      align={'center'}
                    >
                      {t('general.invoice', { ns: 'common' })}
                    </TableCell>
                    <TableCell
                      sx={{
                        color: '#fff',
                        fontWeight: 'bold',
                        backgroundColor: 'primary.main',
                        borderBlockColor: 'primary.main',
                      }}
                      align={'center'}
                    >
                      {t('general.supplier', { ns: 'common' })}
                    </TableCell>
                    <TableCell
                      sx={{
                        color: '#fff',
                        fontWeight: 'bold',
                        backgroundColor: 'primary.main',
                        borderBlockColor: 'primary.main',
                      }}
                      align={'center'}
                    >
                      {t('general.status', { ns: 'common' })}
                    </TableCell>
                    <TableCell
                      sx={{
                        color: '#fff',
                        fontWeight: 'bold',
                        backgroundColor: 'primary.main',
                        borderBlockColor: 'primary.main',
                      }}
                      align={'center'}
                    >
                      {t('general.total', { ns: 'common' })}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(budgetCommission?.budgetExpense?.length ?? 0) > 0 ? (
                    budgetCommission?.budgetExpense?.map(budgeExpense => {
                      return (
                        <TableRow key={budgeExpense.id} sx={{ backgroundColor: '#FFF' }}>
                          <TableCell style={{ paddingBottom: 1, paddingTop: 1 }}>
                            <Box
                              sx={{
                                display: 'flex',
                                textAlign: ' center',
                                justifyContent: 'center',
                                alignItems: 'center',
                                backgroundColor: '#FFF',
                              }}
                            >
                              {budgeExpense.code}
                            </Box>
                          </TableCell>
                          <TableCell style={{ paddingBottom: 1, paddingTop: 1 }}>
                            <Box
                              sx={{
                                display: 'flex',
                                textAlign: ' center',
                                justifyContent: 'center',
                                alignItems: 'center',
                                backgroundColor: '#FFF',
                              }}
                            >
                              {budgeExpense?.suppliers?.name}
                            </Box>
                          </TableCell>
                          <TableCell style={{ paddingBottom: 1, paddingTop: 1 }}>
                            <Box
                              sx={{
                                display: 'flex',
                                textAlign: ' center',
                                justifyContent: 'center',
                                alignItems: 'center',
                                backgroundColor: '#FFF',
                              }}
                            >
                              <Chip
                                size='medium'
                                label={status(budgeExpense)?.label || ''}
                                color={status(budgeExpense)?.color}
                                sx={{ textTransform: 'capitalize', padding: '0.5rem', margin: '0 0.5rem' }}
                              />
                            </Box>
                          </TableCell>
                          <TableCell style={{ paddingBottom: 1, paddingTop: 1 }}>
                            <Box
                              sx={{
                                display: 'flex',
                                textAlign: ' center',
                                justifyContent: 'center',
                                alignItems: 'center',
                                backgroundColor: '#FFF',
                              }}
                            >
                              {budgeExpense?.expenses?.reduce((previousValue, currentValue) => {
                                return previousValue + currentValue.subTotal;
                              }, 0)}
                            </Box>
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow sx={{ boxShadow: 'none' }}>
                      <TableCell style={{ padding: 0, boxShadow: 'none' }} colSpan={4}>
                        <Box
                          sx={{
                            display: 'flex',
                            textAlign: 'center',
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: '#FFF',
                          }}
                        >
                          <Typography sx={{ textAlign: 'center' }} gutterBottom component='div'>
                            {t('general.noRows', { ns: 'validations' })}
                          </Typography>
                        </Box>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>

            <Divider sx={{ margin: 0 }} />

            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: '10px',
                backgroundColor: '#FFF',
                boxShadow: 'none',
              }}
            >
              {budgetCommission && budgetCommission.budgetExpense && budgetCommission.budgetExpense?.length > 0 && (
                <TableComponent
                  Row={Row}
                  data={[{ ...newTotalData }]}
                  headers={[
                    t('general.receivable', { ns: 'common' }),
                    t('general.toPay', { ns: 'common' }),
                    t('general.profit', { ns: 'common' }),
                    t('general.commision', { ns: 'common' }),
                    t('general.finalUtility', { ns: 'common' }),
                  ]}
                />
              )}
            </Box>

            {permissions.includes('generate_commission') && (
              <>
                <Divider sx={{ margin: 0 }} />

                <Button
                  size='large'
                  color='primary'
                  variant='contained'
                  onClick={() =>
                    createEarningsDetail({
                      payable: newTotalData?.toPay ?? 0,
                      receivable: newTotalData.receivable,
                      earnings: newTotalData.benefit,
                      commission: newTotalData.commision,
                      utilities: newTotalData.utility,
                      budgetId: budgetId ?? 0,
                    })
                  }
                  sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                >
                  <Typography>{t('Buttons.save')}</Typography>
                </Button>
              </>
            )}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
