import { DefaultTFuncReturn } from 'i18next';
import { useTranslation } from 'react-i18next';

// @MUI Components
import { Box, Button, Typography } from '@mui/material';

// @Icons
import { DeleteOutline } from 'mdi-material-ui';

// @Hooks
import useFileUpload from './hooks/useFileUpload';

// @Interfaces and types
import { FileUploaded } from '../../models/CustomFiles';
interface IProps {
  title?: string | DefaultTFuncReturn;
  filesUploaded: FileUploaded[];
  maxFiles?: number;
  formatsAllowed?: string[];
}

/**
 * @component
 * @subcategory Global
 *
 * @description
 * A component that allows users to upload multiple files with restrictions.
 * #### Example
 * ```
 * return(
 *  <FileUploadMultiple title="Upload Files" filesUploaded={filesUploaded} maxFiles={1} formatsAllowed={[".jpg", ".png", ".pdf"]} />
 * )
 * ```
 *
 * @property {string | DefaultTFuncReturn} [title] A title to be displayed above the uploader.
 * @property {FileUploaded[]} filesUploaded An array of files that have been uploaded.
 * @property {number} [maxFiles=1] The maximum number of files allowed to be uploaded.
 * @property {string[]} [formatsAllowed] An array of allowed file formats.
 *
 * @return {JSX.Element}
 * @author CML Exports Front-End Developers
 */
export default function FileUploadMultiple({ title, maxFiles = 1, filesUploaded, formatsAllowed }: IProps) {
  const {
    // NOTE: STATUS
    haveFiles,
    // NOTE: ACTIONS
    getRootProps,
    getInputProps,
    getFileName,
    getFileSize,
    getPreviewFile,
    // NOTE: ALL HANDLE FUNCTIONS
    handleRemoveFile,
  } = useFileUpload(filesUploaded, maxFiles, formatsAllowed);

  const { t } = useTranslation('common');

  // If filesUploaded has content show all files icon, name, size and deleteButton

  return (
    <Box sx={{ width: '100%' }}>
      <Box
        {...getRootProps({ className: 'dropzone' })}
        sx={{
          py: 1,
          px: 4,
          mx: 'auto',
          width: { xs: '100%', sm: 'max-content' },
          borderRadius: '2px',
          border: ({ palette }) => `2.5px dashed ${palette.primary.main}`,
          cursor: 'pointer',
          overflow: 'hidden',
          transition: '300ms ease',
          transitionProperty: 'transform background',
          ':hover': { background: '#70707010', transform: 'scale(1.01)' },
        }}
      >
        <input {...getInputProps()} />

        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Typography variant='h5' sx={{ fontSize: '1.25rem' }}>
            {title || t('uploader.titleMultiple')}
          </Typography>

          <Typography variant='body2' sx={{ color: '#999', fontSize: '0.85rem' }}>
            {`${maxFiles} ${t('uploader.max')}`}
          </Typography>

          <Box sx={{ width: 200, height: 200, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <img alt='Upload img' src='/assets/upload.png' width='100%' height='auto' />
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
            <Typography color='textSecondary'>{t('uploader.descriptionMultiple')}</Typography>
            <Button>{t('uploader.system')}</Button>
          </Box>
        </Box>
      </Box>

      <br />

      {haveFiles && (
        <Box
          sx={{
            p: 1,
            width: '100%',
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            alignItems: 'center',
            gap: 4,
            border: 'thin solid #ccc',
            borderRadius: '0.25rem',
            background: '#f1f1f1',
          }}
        >
          {filesUploaded.map((data, i) => (
            <Box
              key={i}
              sx={{
                width: { xs: '100%', sm: '33.3333%' },
                overflow: 'hidden',
                display: 'flex',
                placeItems: 'center',
                flexDirection: 'column',
              }}
            >
              {data.file ? (
                <>
                  <Typography
                    noWrap
                    sx={{
                      width: { xs: '80%', sm: '100%' },
                      textOverflow: 'ellipsis',
                      textAlign: 'center',
                      overflow: 'hidden',
                    }}
                  >
                    {getFileName(data.file)}
                  </Typography>

                  <Box
                    sx={{
                      my: 2,
                      width: 200,
                      height: 160,
                      display: 'flex',
                      flexDirection: 'column',
                      placeItems: 'center',
                    }}
                  >
                    {filesUploaded[i] && getPreviewFile(data.file, data.filesViewParams)}
                  </Box>

                  {getFileSize(data.file) && (
                    <Typography variant='body2' fontWeight={'bold'} sx={{ mb: 2, textTransform: 'uppercase' }}>
                      {getFileSize(data.file)}
                    </Typography>
                  )}

                  <Button
                    size='small'
                    color='error'
                    variant='outlined'
                    sx={{ width: '50%' }}
                    onClick={() => handleRemoveFile(data.setFile)}
                  >
                    <DeleteOutline fontSize='medium' />
                  </Button>
                </>
              ) : (
                <Box
                  sx={{
                    my: 2,
                    width: 200,
                    height: 160,
                    display: 'flex',
                    flexDirection: 'column',
                    placeItems: 'center',
                  }}
                >
                  <img alt='Upload img' src='/assets/upload.png' width='100%' height='auto' />
                </Box>
              )}
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
}
