import * as yup from 'yup';
import { BL } from '../../../../../models';
export const initialValues: BL = {
  shipper: {
    name: '',
    email: '',
    phone: '',
    direction: '',
  },
  consignee: {
    name: '',
    email: '',
    phone: '',
    direction: '',
  },
  expeditionDate: '',
  notify: '', // select,
  blNumber: '',
  notifyname: '',
  notifyaddress: '',
  originalNumber: '',
  placeOfReceipt: '',
  vessel: '',
  portOfLoading: '',
  portOfDischarge: '',
  placeOfDelivery: '',
  container: '',
  content: [],
  charges: [],
};

export const schemaValidation = yup
  .object()
  .shape({
    shipper: yup.object().shape({
      name: yup.string().required('required.general'),
      direction: yup.string().required('required.general'),
      email: yup.string().notRequired(),
      // phone: yup.number().typeError('required.general').required('required.general'),
      phone: yup.string().notRequired(),
    }),
    consignee: yup.object().shape({
      name: yup.string().required('required.general'),
      direction: yup.string().required('required.general'),
      email: yup.string().notRequired(),
      // phone: yup.number().typeError('required.general').required('required.general'),
      phone: yup.string().notRequired(),
    }),
    expeditionDate: yup.string().required('required.general'),
    blNumber: yup.string().required('required.general'),
    notifyname: yup.string().required('required.general'),
    notifyaddress: yup.string().required('required.general'),
    originalNumber: yup.string().required('required.general'),
    placeOfReceipt: yup.string().required('required.general'),
    vessel: yup.string().required('required.general'),
    portOfLoading: yup.string().required('required.general'),
    portOfDischarge: yup.string().required('required.general'),
    placeOfDelivery: yup.string().required('required.general'),
    content: yup.array().of(
      yup.object().shape({
        description: yup.string().required('required.general'),
        marks: yup.string().optional().notRequired().nullable(),
        packages: yup.string().optional().notRequired().nullable(),
        grossWeight: yup.string().optional().nullable().notRequired(),
        measurement: yup.string().notRequired().optional().nullable(),
      }),
    ),
    charges: yup.array().of(
      yup.object().shape({
        description: yup.string().optional().nullable(),
        prepaid: yup
          .number()
          .optional()
          .nullable()
          .transform((value, original) => (original === '' ? null : value))
          .typeError('general.number'),
        collect: yup
          .number()
          .optional()
          .nullable()
          .transform((value, original) => (original === '' ? null : value))
          .typeError('general.number'),
      }),
    ),
  })
  .required();
