/**
 * @class System's utils
 * @category Utils
 */
export class SystemUtils {
  /**
   * Get the system's enviroment
   * @returns Return the current enviroment.
   */
  static isDevelop(): boolean {
    return process.env.NODE_ENV === 'development';
  }
}
