// ** MUI Components
import { Grid, Box, Button, Typography } from '@mui/material';

/**
 * @component
 * @subcategory Global
 *
 * @description
 * A 404 error page component that displays a "Page Not Found" message and a button to go back to the home page.
 * #### Example
 * ```
 * return (
 *  <Error404 />
 * )
 * ```
 *
 * @returns {JSX.Element} A 404 error page component that displays a "Page Not Found" message and a button to go back to the home page.
 * @author CML Exports Front-End Developers
 */
export default function Error404() {
  return (
    <Grid
      container
      sx={{
        width: '90%',
        height: '92vh',
        ml: 'auto',
        mx: 'auto',
        zIndex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
      }}
    >
      <Grid
        item
        xs={12}
        sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}
      >
        <Box textAlign={'center'}>
          <Typography variant='h1'>404</Typography>
          <Typography variant='h5' sx={{ mb: 1, fontSize: '1.5rem !important' }}>
            Page Not Found ⚠️
          </Typography>
          <Typography variant='body2'>We couldn&prime;t find the page you are looking for.</Typography>
        </Box>

        <Box component={'img'} sx={{ width: 'auto', height: { xs: 450, md: 487 }, mb: 4 }} src='/assets/404.png' />

        <Button href='/' variant='contained' sx={{ px: 5.5 }}>
          Back to Home
        </Button>
      </Grid>
    </Grid>
  );
}
