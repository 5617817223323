import * as yup from 'yup';
import { ShipmentCreate } from '../models';

export const defaultShipmentValues: ShipmentCreate = {
  countryDestiny: '',
  countryOrigin: '',
  addressDestiny: '',
  addressOrigin: '',
  area: 0,
  content: '',
  height: 0,
  length: 0,
  weightPhysical: 0,
  unitMeasurement: 'cmkg',
  weightVol: 0,
  width: 0,
  incometers: '',
  comments: '',
  budgetDetails: [],
};

export const shipmentCreateDtoValidationSchema = yup.object().shape({
  content: yup.string().required('required.general'),
  countryOrigin: yup.string().required('required.general'),
  countryDestiny: yup.string().required('required.general'),
  addressOrigin: yup.string().required('required.general'),
  addressDestiny: yup.string().required('required.general'),
  unitMeasurement: yup.string().required('required.general'),
  length: yup.number().required('required.general'),
  width: yup.number().required('required.general'),
  height: yup.number().required('required.general'),
  weightVol: yup.number().required('required.general'),
  weightPhysical: yup.number().required('required.general'),
  incometers: yup.string().optional(),
  comments: yup.string().nullable(),
  area: yup.number().required('required.general'),
});
