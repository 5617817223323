import { Controller } from 'react-hook-form';
import { DefaultTFuncReturn } from 'i18next';
import { useTranslation } from 'react-i18next';

// @MUI Components
import { FormControl, FormHelperText, SxProps, TextField } from '@mui/material';

// @Interfaces and Types
interface IProps {
  control: any;
  name: string;
  label?: string | DefaultTFuncReturn;
  disabled?: boolean;
  ns?: string;
  sx?: SxProps;
}

/**
 * @component
 * @subcategory Global
 *
 * @description
 * CustomInput component that wraps the Material-UI TextField component and integrates it with react-hook-form.
 * #### Example
 * ```
 * return (
 *  <CustomInput control={control} name="firstName" label="First Name" rules={{ required: 'First Name is required' }} />
 * )
 * ```
 *
 * @property {any} control The control object from react-hook-form.
 * @property {string} name The name of the input field.
 * @property {string|DefaultTFuncReturn} [label] The label of the input field.
 * @property {boolean} [disabled] Whether the input field is disabled or not.
 * @property {string} [ns] The namespace for the translation.
 * @property {SxProps} [sx] The styles for the input field.
 *
 * @returns {JSX.Element} The CustomInput component.
 * @author CML Exports Front-End Developers
 */
export default function CustomInput({ control, name, label, disabled, ns, sx = {} }: IProps) {
  const { t } = useTranslation(ns || 'common');

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, ...othersFields }, fieldState: { error } }) => (
        <FormControl fullWidth>
          <TextField
            label={label}
            value={value}
            autoComplete='off'
            sx={{ width: '100%', ...sx }}
            {...othersFields}
            error={Boolean(error?.message)}
            disabled={disabled}
          />
          <FormHelperText sx={{ color: 'error.main' }}>{t(error?.message || '')}</FormHelperText>
        </FormControl>
      )}
    />
  );
}
