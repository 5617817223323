import { useState } from 'react';

// @MUI Components
import { Box, CardHeader, Divider } from '@mui/material';

// @Components
import Pagination from '../../../components/Pagination';
import TableComponent from '../../../components/TableContainer';
import Row from './Row';

interface IProps {
  t: any;
  data: any;
}

export const CodeSettingTable = ({ t, data }: IProps) => {
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(5);

  return (
    <Box sx={{ display: 'block', height: 500, width: '100%', mb: 20, mt: -2 }}>
      <CardHeader sx={{ display: 'flex', textAlign: 'center' }} titleTypographyProps={{ variant: 'h6' }} />

      <Divider sx={{ margin: 0 }} />

      <TableComponent
        Row={Row}
        data={data?.data?.configCode?.slice(page * pageSize, (page + 1) * pageSize)}
        headers={[t('general.authorization'), t('general.min'), t('general.max'), t('general.authorizationDate')]}
        props={{ t }}
      />

      <Pagination
        count={data?.data?.configCode?.length}
        setPage={setPage}
        setPageSize={setPageSize}
        page={page}
        pageSize={pageSize}
      />
    </Box>
  );
};
