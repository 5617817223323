import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import React from 'react';
import { useTranslation } from 'react-i18next';

// @Mui
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { TableBody } from '@mui/material';

// @Icons
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

// @Models
import { ISupplierWithServices } from '../../../models';
import { formatNumber } from '../../../utils/format-number.util';
import ColumHeader from '../../../components/TableColumnHeader';

import useExpenses from '../../Expenses/useExpenses';

interface IProps {
  rows: ISupplierWithServices[];
}

interface IRow {
  row: ISupplierWithServices;
}

function Row({ row }: IRow) {
  const [open, setOpen] = React.useState(false);

  const { expensesHasDocuments } = useExpenses();

  const { t, i18n } = useTranslation('common');

  const noFilesAlert = () => {
    Swal.fire({
      title: `${t('general.notDocumentAlert')}`,
      text: `${t('general.notDocument')}`,
      icon: 'warning',
      confirmButtonText: 'Ok',
    });
  };

  return (
    <React.Fragment>
      <TableRow>
        <TableCell>
          <IconButton aria-label='expand row' size='small' onClick={() => setOpen(!open)}>
            {open ? <RemoveIcon /> : <AddIcon />}
          </IconButton>
        </TableCell>

        <TableCell align='left' component='th' scope='row'>
          <Typography noWrap title={row.name} sx={{ maxWidth: { xs: '14rem', xm: '16rem', xl: '20rem' } }}>
            {row.name}
          </Typography>
        </TableCell>

        <TableCell align='center'>{formatNumber(row.subTotalReceivable)}</TableCell>

        <TableCell align='right'>{formatNumber(row.subTotalToPay)}</TableCell>

        <TableCell align='center'>
          {formatNumber((row.subTotalReceivable * 100 - row.subTotalToPay * 100) / 100)}
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
          <Collapse in={open} timeout='auto' unmountOnExit>
            <Box sx={{ margin: '1rem 2rem' }}>
              <Typography variant='h6' gutterBottom component='div' align='center'>
                {t('general.details')}
              </Typography>

              <Table aria-label='collapsible table' sx={{ ml: 5, borderCollapse: 'collapse' }}>
                <TableHead sx={{ backgroundColor: 'primary.main' }}>
                  <ColumHeader
                    headers={[
                      t('general.code'),
                      t('general.description'),
                      t('general.receivable'),
                      t('general.toPay'),
                      t('general.benefit'),
                    ]}
                  />
                </TableHead>

                <TableBody>
                  {row.services.map((service, index) => (
                    <TableRow key={index} sx={{ '&:last-child td': { width: '100%' } }}>
                      <TableCell align='left' component='th' scope='row'>
                        {service.code}
                      </TableCell>

                      <Link
                        to={`/filesView/${service.idcode}?model=${
                          service.type === 'service' ? 'suppliers' : 'expenses'
                        }`}
                        style={{ textDecoration: 'none' }}
                        onClick={e => {
                          if (!expensesHasDocuments(service.idcode)) {
                            e.preventDefault();
                            noFilesAlert();
                            return false;
                          }
                        }}
                      >
                        <TableCell align='left' component='th' scope='row' sx={{ width: '10%', textAlign: 'left' }}>
                          <Typography
                            noWrap
                            title={i18n.language === 'es' ? service.name : service.nameEN}
                            sx={{ maxWidth: { xs: '12rem', sm: '18rem', lg: '24rem', xl: '35rem' } }}
                          >
                            {i18n.language === 'es' ? service.name : service.nameEN}
                          </Typography>
                        </TableCell>
                      </Link>

                      <TableCell align='center' component='th' scope='row'>
                        {formatNumber(service.subTotalReceivable)}
                      </TableCell>

                      <TableCell align='right' component='th' scope='row'>
                        {formatNumber(service.subTotalToPay)}
                      </TableCell>

                      <TableCell align='center' component='th' scope='row'>
                        {formatNumber((service.subTotalReceivable * 100 - service.subTotalToPay * 100) / 100)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function CollapsibleBudget({ rows }: IProps) {
  const { t } = useTranslation('common');

  return (
    <>
      <TableContainer component={Paper} sx={{}}>
        <Table aria-label='collapsible table'>
          <TableHead sx={{ backgroundColor: 'primary.main' }}>
            <ColumHeader
              headers={['', t('general.supplier'), t('general.receivable'), t('general.toPay'), t('general.utility')]}
            />
          </TableHead>

          <TableBody>
            {rows.length > 0 ? (
              rows.map((row, index) => <Row key={index} row={row} />)
            ) : (
              <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
                  <Box
                    sx={{
                      display: 'flex',
                      textAlign: ' center',
                      justifyContent: 'center',
                      alignItems: 'center',
                      bgcolor: '#F7F7F7',
                      height: 300,
                    }}
                  >
                    <Typography sx={{ textAlign: 'center' }} gutterBottom component='div'>
                      {t('general.noRows', { ns: 'validations' })}
                    </Typography>
                  </Box>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
