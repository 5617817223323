import { useTranslation } from 'react-i18next';

// @Views
// import { MessageError, MessageLoading } from '../components';
import { ServicesView } from './services.view';
import { ShipmentDetailsView } from './shipment-details.view';

// @Material UI
import Grid from '@mui/material/Grid';
import CardContent from '@mui/material/CardContent';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';

// @Hooks
import { useCustomerInteraction } from '../hooks';

export function DisplayStep() {
  const { currentStep } = useCustomerInteraction();

  const { t } = useTranslation();

  const display = (step: number) => {
    switch (step) {
      case 1:
        return <ServicesView />;
      case 2:
        return <ShipmentDetailsView />;
      default:
    }
  };

  // if (loading) {
  //   return <MessageLoading />;
  // }

  // if (error) {
  //   return <MessageError />;
  // }

  return (
    <Grid container>
      <Grid item xs={12} sx={{ mx: '5rem' }}>
        <Card>
          <CardHeader
            sx={{ display: 'flex', textAlign: 'center' }}
            titleTypographyProps={{ variant: 'h6' }}
            title={t(currentStep === 1 ? 'title.servicepersuppliers' : 'budgetPage.detailssend', { ns: 'common' })}
          />

          {currentStep === 1 ? (
            <CardHeader
              sx={{ display: 'flex', textAlign: 'center' }}
              titleTypographyProps={{ variant: 'h6' }}
              title={t('title.servicepersuppliersSubtittle', { ns: 'common' })}
            />
          ) : (
            <></>
          )}

          <CardContent>{display(currentStep)}</CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
