export const TRANSPORTATIONS_OBJ = Object.freeze({
  GROUND: {
    VALUE: 0,
    LABEL: 'TERRESTRE',
  },
  AIR: {
    VALUE: 2,
    LABEL: 'AEREO',
  },
  MARITIME: {
    VALUE: 1,
    LABEL: 'MARITIMO',
  },
});
