import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Alert, AlertTitle, Card, CardContent } from '@mui/material';
import { cancelEmailMassive } from './services';

export function CancelEmailMassive() {
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const token = searchParams.get('token');
    if (token) {
      cancelEmailMassive(token);
    }
  }, []);

  return (
    <Card sx={{ width: '95%', height: '100%', margin: '2rem auto' }}>
      <CardContent>
        <Alert icon={false} severity='info'>
          <AlertTitle
            sx={{
              fontWeight: 600,
              marginBottom: theme => `${theme.spacing(1)} !important`,
            }}
          >
            Listo!!! Se acaba de desuscribir de la lista de envios de correos.
          </AlertTitle>
          Si desea volver a suscribirse, por favor comuniquese con el administrador del sistema.
        </Alert>
      </CardContent>
    </Card>
  );
}
