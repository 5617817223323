/* eslint-disable indent */
// @Mui
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

// @Interfaces
import { IStep } from '../../../models';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import * as React from 'react';
import { styled } from '@mui/material/styles';
import { Icon } from '@iconify/react';
import { StepIconProps } from '@mui/material/StepIcon';
import useMediaQuery from '@mui/material/useMediaQuery';

interface IProps {
  steps: IStep[];
  currentStep: number;
}
const ColorlibStepIconRoot = styled('div')<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
  zIndex: 1,
  color: '#fff',
  width: 30,
  height: 30,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundImage: 'linear-gradient( 136deg,rgb(22, 177, 255) 50%, rgb(22, 177, 255) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  }),
  ...(ownerState.completed && {
    backgroundImage: 'linear-gradient( 136deg,rgb(86,202,0) 50%, rgb(86,202,0) 100%)',
  }),
}));

function ColorlibStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  const icons: { [index: string]: React.ReactElement } = {
    1: <Icon icon='mdi:shop-location-outline' />,
    2: <Icon icon='mdi:customer-service' />,
    3: <Icon icon='grommet-icons:services' />,
    4: <Icon icon='ci:file-pdf' />,
  };

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

export const StepperU = ({ steps, currentStep }: IProps) => {
  const { t } = useTranslation('common');

  const matches = useMediaQuery('(min-width:600px)');

  return (
    <Box sx={{ width: '100%' }}>
      {/* {stepsDisplay} */}
      {/* Se le resta 1 al currentStep debido a que antes la logica estaba para tailwind */}
      <Stepper activeStep={currentStep - 1} alternativeLabel>
        {steps.map((step, index) => (
          <Step key={index}>
            {matches ? (
              <StepLabel>
                {step.description === 'Informacion vendedor'
                  ? t('budgetPage.steps.seller')
                  : step.description === 'Informacion cliente'
                  ? t('budgetPage.steps.customer')
                  : step.description === 'Servicios'
                  ? t('budgetPage.steps.services')
                  : step.description === 'Completado'
                  ? t('budgetPage.steps.final')
                  : ''}
              </StepLabel>
            ) : (
              <StepLabel StepIconComponent={ColorlibStepIcon}>{step.id}</StepLabel>
            )}
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};
