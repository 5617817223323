import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/reducers';

// @MUI Components
import { Box, TableRow, Typography, TableCell, IconButton, Switch } from '@mui/material';

// @Icons
import { Icon } from '@iconify/react';
import AccountEyeOutline from 'mdi-material-ui/AccountEyeOutline';
import AccountEditOutline from 'mdi-material-ui/AccountEditOutline';

// @Interfaces
interface IProps {
  row: any;
  onDelete: (id: number) => void;
  handleEdit: (id: number) => void;
  toogleNotes: (notes: string) => void;
  onStatus: (id: number, status: string) => void;
  can?: any;
  toggleEditServices: (id: number) => void;
}

const Row = ({ row, handleEdit, onDelete, onStatus, toogleNotes, can, toggleEditServices }: IProps) => {
  const { t } = useTranslation(['common']);

  const { user } = useSelector((state: RootState) => state);

  const editPublic = (createdBy: number, id: number) => {
    if (id === 0) {
      if (user.idCRM === createdBy) {
        return true;
      } else if (user.idCRM === 1) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  return (
    <TableRow>
      <TableCell>
        <Typography noWrap sx={{ textTransform: 'capitalize', display: 'flex', alignItems: 'left', textAlign: 'left' }}>
          {row.taxIdentification}
        </Typography>
      </TableCell>

      <TableCell sx={{ maxWidth: { xs: 200, md: 220, lg: 230, xl: 350 } }}>
        <Typography title={row.name} noWrap sx={{ maxWidth: '100%', textTransform: 'capitalize' }}>
          {row.name}
        </Typography>

        {row.comercialName && (
          <Typography variant='body2' noWrap sx={{ maxWidth: '100%', color: '#999', textTransform: 'capitalize' }}>
            @{row.comercialName}
          </Typography>
        )}
      </TableCell>

      <TableCell>
        <Box
          sx={{
            width: '50%',
            mx: 'auto',
            display: 'flex',
            padding: { xs: 0, sm: 2 },
          }}
        >
          {can?.consult && (
            <Link to={`/suppliers/details/${row.id}`}>
              <IconButton color='success' title={`${t('general.consult')}`}>
                <AccountEyeOutline fontSize='medium' />
              </IconButton>
            </Link>
          )}

          {row.canEdit && can?.edit_service && editPublic(row.createdBy, row.idUser) && (
            <IconButton color='info' onClick={() => handleEdit(row.id)} title={`${t('general.edit')}`}>
              <AccountEditOutline fontSize='medium' />
            </IconButton>
          )}

          {can?.edit_service && !editPublic(row.createdBy, row.idUser) && (
            <IconButton color='warning' onClick={() => toggleEditServices(row.id)} title={`${t('general.edit')}`}>
              <AccountEditOutline fontSize='medium' />
            </IconButton>
          )}

          {can?.delete_provider && editPublic(row.createdBy, row.idUser) && (
            <IconButton onClick={() => onDelete(row.id)} title={`${t('general.delete')}`}>
              <Icon icon='uiw:user-delete' height={17} style={{ color: 'red' }} />
            </IconButton>
          )}

          {can?.change_provider_status && (
            <Switch
              checked={row.status}
              onChange={() => onStatus(row.id, row.status ? 'inactive' : 'active')}
              defaultChecked
              sx={{ scale: '0.8' }}
            />
          )}

          {can?.notes && row.note !== '' && (
            <IconButton onClick={() => toogleNotes(row.note)} title={`${t('general.view')} ${t('general.notes')}`}>
              <Icon icon='gg:notes' height={18} style={{ color: 'darkblue' }} />
            </IconButton>
          )}
          {can?.service_history && editPublic(row.createdBy, row.idUser) && (
            <Link to={`/suppliers/history/${row.id}`}>
              <IconButton title={`${t('general.historyChanges')} `}>
                <Icon icon='mdi:clipboard-text-history-outline' height={22} color='#00f0ff' />
              </IconButton>
            </Link>
          )}
        </Box>
      </TableCell>
    </TableRow>
  );
};

export default Row;
