import Swal from 'sweetalert2';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

// @Material-UI
import { Grid, CardContent, IconButton, Tabs, Tab } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

// @Hooks
import { useDocument } from './hooks';

// @Components
import { DocumentError, DocumentLoading } from './components';
import RenderGroundForms from './GuiasTerrestres/RenderGroundForms';
import RenderFormsMaritimos from './GuiasMaritimas/RenderFormsMaritimos';
import RenderAirGuides from './GuiasAereas/RenderAirGuides';
import TabPanel from '../Service/components/TabPanel';

// @Interfaces and Types
interface BudgetGuidesProps {
  children?: React.ReactNode;
  _status?: number;
}

const RenderForms: React.FC<BudgetGuidesProps> = ({ _status = -1 }) => {
  const [formContainerTabs, setFormContainerTabs] = useState(0);

  const { error, loading, formChanged } = useDocument();

  const { t } = useTranslation(['common']);

  const handleStatus = (value: number) => {
    // if (status === -1) {
    //   setStatus(value);
    //   return;
    // }

    if (formChanged === true) {
      Swal.fire({
        title: t('budgetGuide.changeWarning.tittle') as string,
        text: t('budgetGuide.changeWarning.description') as string,
        icon: 'warning',
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: t('budgetGuide.changeWarning.confirmButton') as string,
        confirmButtonColor: '#56CA00',
        cancelButtonColor: '#FF4C51',
        cancelButtonText: t('budgetGuide.changeWarning.cancelButton') as string,
      }).then(result => {
        if (result.isConfirmed) {
          setFormContainerTabs(value);
          // if (value === 0) navigate('/budgets/guides/' + params.id + '/guia-terreste/carta-porte');
          // if (value === 1) navigate('/budgets/guides/' + params.id + '/guia-maritima/booking');
          // if (value === 2) navigate('/budgets/guides/' + params.id + '/guia-aerea/master');
        }
      });
    } else {
      setFormContainerTabs(value);
      //     if (value === 0) navigate('/budgets/guides/' + params.id + '/guia-terreste/carta-porte');
      // if (value === 1) navigate('/budgets/guides/' + params.id + '/guia-maritima/booking');
      // if (value === 2) navigate('/budgets/guides/' + params.id + '/guia-aerea/master');
    }
  };

  if (loading) return <DocumentLoading />;

  if (error) return <DocumentError error={error} />;

  return (
    <Grid item xs={12} width='100%'>
      <CardContent sx={{ position: 'relative' }}>
        <IconButton href='/budgets/' sx={{ position: 'absolute', top: 0, left: -30 }}>
          <ArrowBackIcon />
        </IconButton>

        <Tabs
          sx={{ borderBottom: 1, borderColor: 'divider' }}
          value={formContainerTabs}
          onChange={(_, value) => setFormContainerTabs(value)}
          aria-label='basic tabs example'
          variant='scrollable'
          scrollButtons
          allowScrollButtonsMobile
        >
          <Tab label={t('budgetGuide.firstTabTittle')} />
          <Tab label={t('budgetGuide.secondTabTittle')} />
          <Tab label={t('budgetGuide.thirdTabTittle')} />
        </Tabs>

        <TabPanel value={formContainerTabs} index={0}>
          <RenderGroundForms valueForm={0} subform={0} />
        </TabPanel>
        <TabPanel value={formContainerTabs} index={1}>
          <RenderFormsMaritimos valueForm={1} subform={0} />
        </TabPanel>
        <TabPanel value={formContainerTabs} index={2}>
          <RenderAirGuides valueForm={2} subform={0} />
        </TabPanel>

        {/* <FormControl>
                <FormLabel>
                  <Typography color={'gray'}> {t('budgetGuide.instructions')} </Typography>
                </FormLabel>

                <RadioGroup
                  row
                  aria-labelledby='demo-row-radio-buttons-group-label'
                  name='row-radio-buttons-group'
                  onChange={event => handleStatus(parseInt(event.target.value))}
                  defaultValue={status}
                >
                  {transportations.includes(TRANSPORTATIONS_OBJ.GROUND.LABEL) && (
                    <FormControlLabel
                      value={TRANSPORTATIONS_OBJ.GROUND.VALUE}
                      control={<Radio />}
                      label={t('budgetGuide.firstTabTittle')}
                    />
                  )}
                  {transportations.includes(TRANSPORTATIONS_OBJ.MARITIME.LABEL) && (
                    <FormControlLabel
                      value={TRANSPORTATIONS_OBJ.MARITIME.VALUE}
                      control={<Radio />}
                      label={t('budgetGuide.secondTabTittle')}
                    />
                  )}
                  {transportations.includes(TRANSPORTATIONS_OBJ.AIR.LABEL) && (
                    <FormControlLabel
                      value={TRANSPORTATIONS_OBJ.AIR.VALUE}
                      control={<Radio />}
                      label={t('budgetGuide.thirdTabTittle')}
                    />
                  )}
                </RadioGroup>
              </FormControl> */}
      </CardContent>
    </Grid>
  );
};

export default RenderForms;
