import React, { useState } from 'react';
import { Box, Tabs, Tab } from '@mui/material';
import CartaPorteForm from './CartaPorte/CartaPorteForm';
import ManifiestoForm from './Manifiesto/ManifiestoForm';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';
import { useDocument } from '../hooks';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

interface FormProps {
  valueForm: number;
  subform: number;
}

const RenderGroundForms = ({ valueForm, subform }: FormProps) => {
  const [groundForm, setGroundForm] = useState(0);

  const { formChanged } = useDocument();

  const { t } = useTranslation(['common']);

  const handleChangeGroundForm = (_: React.SyntheticEvent<Element, Event>, newValue: number) => {
    if (formChanged === true) {
      Swal.fire({
        title: t('budgetGuide.changeWarning.tittle') as string,
        text: t('budgetGuide.changeWarning.description') as string,
        icon: 'warning',
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: t('budgetGuide.changeWarning.confirmButton') as string,
        confirmButtonColor: '#56CA00',
        cancelButtonColor: '#FF4C51',
        cancelButtonText: t('budgetGuide.changeWarning.cancelButton') as string,
      }).then(result => {
        if (result.isConfirmed) {
          // if (newValue === 0) navigate('/budgets/guides/' + params.id + '/guia-terreste' + '/carta-porte');
          // if (newValue === 1) navigate('/budgets/guides/' + params.id + '/guia-terreste' + '/manifiesto');
          setGroundForm(newValue);
        }
      });
    } else {
      // if (newValue === 0) navigate('/budgets/guides/' + params.id + '/guia-terreste' + '/carta-porte');
      // if (newValue === 1) navigate('/budgets/guides/' + params.id + '/guia-terreste' + '/manifiesto');
      setGroundForm(newValue);
    }
  };

  return (
    <TabPanel value={valueForm} index={0}>
      <Tabs
        sx={{ borderBottom: 1, borderColor: 'divider' }}
        value={groundForm}
        onChange={handleChangeGroundForm}
        aria-label='basic tabs example'
      >
        <Tab label={t('budgetGuide.firstTabFirstSubtittle')} />
        <Tab label={t('budgetGuide.firstTabSecondSubtittle')} />
      </Tabs>

      <br />

      <TabPanel value={groundForm} index={0}>
        <CartaPorteForm />
      </TabPanel>
      <TabPanel value={groundForm} index={1}>
        <ManifiestoForm />
      </TabPanel>
    </TabPanel>
  );
};

export default RenderGroundForms;
