import { useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';

// @Mui
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

// @Redux
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/reducers';
import { useAppDispatch } from '../../redux/store';
import { getLogin } from '../../redux/slices/user/ActionsAsync';
import { logginCRM } from '../../redux/slices/user';

export function LoginFromCRM() {
  const [searchParams] = useSearchParams();
  const { user } = useSelector((state: RootState) => state);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (user.user) {
      navigate('/budgets');
    } else {
      const email = searchParams.get('email');
      const password = searchParams.get('password');
      if (email && password) {
        dispatch(getLogin({ email, password }));
        dispatch(logginCRM({ loginCRM: true }));
        navigate('/budgets');
      }
    }
  }, []);
  return (
    <Box
      sx={{
        display: 'flex',
        height: '100vh',
        width: '100vw',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CircularProgress />
    </Box>
  );
}
