import { GetServices, ServiceSupplier, ShipmentCreate } from '../models';
import { axiosEstimates } from '../../../utils/api';

export async function getServices(token: string): Promise<ServiceSupplier[]> {
  const response = await axiosEstimates.get<GetServices>('customer-interaction/services', {
    headers: {
      'x-customer-token': token,
    },
  });

  return response.data.data;
}

export async function createBudget(token: string, data: ShipmentCreate): Promise<void> {
  await axiosEstimates.post('customer-interaction/budget', data, {
    headers: {
      'x-customer-token': token,
    },
  });
}
