import * as yup from 'yup';
import { FFCaptureData } from '../../../models/Models';

export const defaultFFValues: FFCaptureData = {
  accountof: '',
  name: '',
  DUI: '',
  IVAWithheld: 0,
  passport: '',
};
export const FFCreateToValidationsSchema = yup.object().shape({
  name: yup.string().required('general.required'),
  DUI: yup.string().when('passport', {
    is: (passport: string) => !(passport && passport.trim() !== ''),
    then: yup.string().required('general.required'),
    otherwise: yup.string(),
  }),
  IVAWithheld: yup.number().optional(),
});
