// ** MUI Imports
import DialogP from '../../../../components/Dialog';
import { FormServices } from '../utils/editEmail.form';

// @Translations
import { useTranslation } from 'react-i18next';

interface Props {
  open: boolean;
  handleClose: () => void;
}

function EmailModal({ open, handleClose }: Props) {
  const { t } = useTranslation();
  return (
    <DialogP open={open} dialogTitle={t('edit.email', { ns: 'common' })} handleClose={handleClose}>
      {open && <FormServices handleClose={handleClose} />}
    </DialogP>
  );
}

export default EmailModal;
