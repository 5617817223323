import { Controller, Control, FieldValues, FieldPath } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

// @MUI Components
import TextField, { StandardTextFieldProps } from '@mui/material/TextField';
import Skeleton from '@mui/material/Skeleton';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';

// @Interfaces and Types
interface IProps<T extends FieldValues> extends StandardTextFieldProps {
  name: FieldPath<T>;
  control: Control<T, any>;
  loading?: boolean;
  rules?: any;
  inUppercase?: boolean;
  translationFile?: 'common' | 'placeholder' | 'validations';
  onExternalChange?: (value: unknown) => void;
}

/**
 * @component
 * @subcategory Global
 *
 * @description
 * A reusable text form component that integrates with react-hook-form and react-i18next.
 * It provides a simple way to render a text field with validation and translation support.
 *
 * #### Example
 * ```jsx
 * import TextForm from './TextForm';
 *
 * function MyForm() {
 *   return (
 *     <TextForm
 *       name="myField"
 *       control={control}
 *       rules={{ required: true }}
 *       translationFile="common"
 *     />
 *   );
 * }
 * ```
 *
 * @property {string} name The field name.
 * @property {Control<T, any>} control The react-hook-form control.
 * @property {boolean} [loading] Whether the component is loading.
 * @property {any} [rules] The validation rules.
 * @property {boolean} [inUppercase] Whether to render the text in uppercase.
 * @property {'common' | 'placeholder' | 'validations'} [translationFile] The translation file to use.
 * @property {function} [onExternalChange] An external change handler.
 *
 * @return {JSX.Element} A JSX element representing the text form component.
 * @author CML Exports Front-End Developers
 */
export default function TextForm<T extends FieldValues>({
  name,
  translationFile = 'common',
  loading,
  control,
  rules,
  onExternalChange,
  ...props
}: IProps<T>) {
  const { t } = useTranslation(translationFile);

  return (
    <>
      {!loading ? (
        <Controller
          name={name}
          control={control}
          rules={rules}
          render={({ field: { onChange, ...field }, fieldState: { error } }) => (
            <FormControl fullWidth>
              <TextField
                onChange={e => {
                  onChange(e);
                  if (onExternalChange) onExternalChange(e.target?.value || e);
                }}
                {...props}
                {...field}
                error={Boolean(error?.message)}
              />

              <FormHelperText sx={{ color: 'error.main' }}>{t(`${error?.message || ''}`)}</FormHelperText>
            </FormControl>
          )}
        />
      ) : (
        <Skeleton variant='rectangular' height={60} />
      )}
    </>
  );
}
