import { useTranslation } from 'react-i18next';
import { axiosEstimates } from '../../../utils';

// @Redux
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/reducers';
import { useAppDispatch } from '../../../redux/store';
import { backStep } from '../../../redux/slices/budget-form';

// @Mui
import Box from '@mui/material/Box';

// @Components
import ButtonForm from '../../../components/ButtonForm';
import CustomSwalAlert from '../../../utils/CustomSwalAlert';

interface IProps {
  nextCallback: () => void;
  // backCallback: () => void;
}

export function FormActions({ nextCallback }: IProps) {
  const { currentStep, steps, shipments, loading, status } = useSelector((state: RootState) => state.budgetForm);
  const { t } = useTranslation(['common']);

  const type = location.search.split('=')[1];

  const dispatch = useAppDispatch();

  const stepsLength = steps.length;

  const handleNext = (): void => {
    nextCallback();
    // dispatch(nextStep());
  };

  const handleBack = (): void => {
    dispatch(backStep());
  };

  const handleDisable = (): boolean => {
    if (currentStep === stepsLength) {
      return true;
    }
    if (shipments.length < 1 && currentStep === 3 && status !== 'request') {
      return true;
    }

    return false;
  };

  const disable = handleDisable();

  return (
    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-around', alignItems: 'center', p: 2 }}>
      <ButtonForm label={t('general.back')} color='error' onClick={handleBack} disabled={currentStep === 1} />

      <ButtonForm
        label={currentStep < stepsLength - 1 ? t('general.next') : t('general.confirm')}
        onClick={async () => {
          if (currentStep === 3) {
            try {
              await axiosEstimates.get(`/pdfConfig?view=${type}`);
              handleNext();
            } catch (error) {
              CustomSwalAlert(
                'Error',
                'No se ha configurado la vista de impresion para este tipo de cotización',
                'error',
                false,
              );
            }
          } else handleNext();
        }}
        sx={{ ml: 2 }}
        // disabled={currentStep === stepsLength || (shipments.length < 1 && currentStep === 3) || loading.show}
        disabled={disable}
        spinner={loading.show}
        color='info'
      />
    </Box>
  );
}
