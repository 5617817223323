/* eslint-disable no-console */
// @Libraries
import { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { useFieldArray, useForm } from 'react-hook-form';
import Swal from 'sweetalert2';
import { useLocation, useParams } from 'react-router-dom';

// @Redux
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/reducers';

// @Models
import { CartaPorte } from '../../../../models';

// @Hooks
import { useDocument } from '../../hooks';

// @Utils
import { axiosEstimates } from '../../../../utils';
import { initialValues, schemaValidation } from './utils/validationsSchema';
import { DOCUMENT_TYPE_OBJ } from '../../utils';

const useCartaPorte = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const { budget, documents, handleNavigatePrint, handleFormChange, handleFormChange2 } = useDocument();
  const [documentId, setDocumentId] = useState<number | null>(null);
  const [currentData, setCurrentData] = useState<object | null>(null);
  const [isSigning, setIsSigning] = useState<boolean>(false);
  const [canEdit, setCanEdit] = useState<boolean>(false);
  const { control, handleSubmit, reset, watch, getValues } = useForm<CartaPorte>({
    defaultValues: initialValues,
    resolver: yupResolver(schemaValidation),
    reValidateMode: 'onChange',
  });

  useEffect(() => {
    getValues();
    const current = JSON.stringify(currentData);
    const updated = JSON.stringify(getValues());
    if (current !== updated) {
      handleFormChange();
    } else {
      handleFormChange2();
    }
  }, [watch()]);

  const {
    countries: { countryItems },
    user: { role },
  } = useSelector((state: RootState) => state);

  useEffect(() => {
    if (role === 'admin' || role === 'editor') {
      setCanEdit(true);
    }
  }, []);

  const {
    fields: contentFields,
    append: contentAppend,
    remove: contentRemove,
  } = useFieldArray({ control, name: 'content' });

  if (contentFields.length === 0) {
    contentAppend({
      type: '',
      quantity: null,
      decription: '',
      kglb: '',
      grossWeight: null,
      volume: null,
    });
  }

  const {
    fields: chargesFields,
    append: chargesAppend,
    remove: chargesRemove,
  } = useFieldArray({ control, name: 'charges' });

  if (chargesFields.length === 0) {
    chargesAppend({ description: '', collect: null, prepaid: null });
  }

  useEffect(() => {
    setCurrentData(getValues());
    handleData();
  }, [budget]);

  const handleData = async () => {
    const data = budget;

    if (data) {
      const document = documents.find(document => document.document_types.name === DOCUMENT_TYPE_OBJ.CARTA_PORTE);

      if (document?.isSigning) {
        setIsSigning(document.isSigning);
      }

      if (!document) {
        const dataporte = {
          expeditionDate: new Date().toLocaleDateString(),
          // invoice: data.budget.code,
          countryOrigin: data.budget.shipments[0].countryOrigin,
          countryDestiny: data.budget.shipments[0].countryDestiny,
          by: {
            name: data.customer.name,
            direction: data.customer.address,
            email: data.customer.email,
            phone: data.customer.phone,
          },
        };
        reset(dataporte);
        setDocumentId(null);
      } else {
        const dataPorte: CartaPorte = {
          bill: document.airwill_folio || '',
          expeditionDate: document.expeditionDate || '',
          invoice: document.recipient || '',
          countryOrigin: data.budget.shipments[0].countryOrigin,
          countryDestiny: data.budget.shipments[0].countryDestiny,
          reference: document.reference || '',
          consignee: document.consignee || '',
          forwarder: {
            pilot: document.pilot || '',
            van: document.pilotVan || '',
            numberLicense: document.pilotLicenseNumber || '',
            licensePlate: document.conveyors[0]?.referencePlate || '',
            name: document.conveyors[0]?.name || '',
          },
          by: {
            name: document.consigneePerson?.name || '',
            direction: document.consigneePerson?.address || '',
            email: document.consigneePerson?.email || '',
            phone: document.consigneePerson?.phone || '',
          },
          conveyors: document.conveyors.map(conveyor => ({
            name: conveyor.name || '',
            referenceCode: conveyor.referenceCode || '',
            exit: conveyor.exit || '',
            loadingPlace: conveyor.loadingPlace || '',
            referencePlate: conveyor.referencePlate || '',
          })),
          exit: document.conveyors[0]?.exit || '',
          destinyAgent: {
            name: document.destinationAgentPerson?.name || '',
            contact: document.destinationAgentPerson?.phone || '',
          },
          finalDestination: document.pilotFinalDestination || '',
          loadingPlace: document.conveyors[0]?.loadingPlace || '',
          entrance: document.pilotEntry || '',
          notifyto: {
            name: document.notifyToPerson?.name || '',
            contact: document.notifyToPerson?.phone || '',
          },
          referenceCode: document.conveyors[0]?.referenceCode || '',
          shipper: {
            direction: document.forwarderPerson?.address || '',
            name: document.forwarderPerson?.name || '',
            email: document.forwarderPerson?.email || '',
            phone: document.forwarderPerson?.phone || '',
          },
          charges: document.documentCharges.map(charge => ({
            description: charge.description || '',
            collect: Number(charge.chargesReceivable) || 0,
            prepaid: Number(charge.prepaidCharges) || 0,
          })),
          content: document.documentContent.map(content => ({
            type: content.type || '',
            decription: content.description || '',
            kglb: content.kglb || '',
            grossWeight: Number(content.grossPrice) || 0,
            quantity: Number(content.amount) || 0,
            volume: Number(content.volume) || 0,
          })),
        };
        reset(dataPorte);
        setDocumentId(document.id);
      }
    }
  };

  const onSubmit = async (data: CartaPorte) => {
    try {
      const charges = data.charges.map(charge => ({
        description: charge.description,
        prepaidCharges: `${charge.prepaid}`,
        chargesReceivable: `${charge.collect}`,
      }));
      const content = data.content.map(content => ({
        type: content.type || null,
        amount: content.quantity || null,
        description: content.decription,
        kglb: content.kglb,
        grossPrice: content.grossWeight || null,
        volume: content.volume || null,
      }));
      const dataPorte = {
        airwill_folio: data.bill,
        recipient: data.invoice,
        budgetId: parseInt(id || '0'),
        strategy: DOCUMENT_TYPE_OBJ.CARTA_PORTE,
        reference: data.reference,
        expeditionDate: data.expeditionDate,
        consignee: data.consignee,
        pilot: data.forwarder.pilot,
        pilotVan: data.forwarder.van,
        pilotLicenseNumber: data.forwarder.numberLicense,
        documentTypeId: 2,
        pilotEntry: data.entrance,
        pilotFinalDestination: data.finalDestination,
        // informacion del consignee
        consigneePerson: {
          name: data.by.name,
          address: data.by.direction,
          email: data.by.email,
          phone: data.by.phone,
        },
        forwarderPerson: {
          name: data.shipper.name,
          address: data.shipper.direction,
          email: data.shipper.email,
          phone: data.shipper.phone,
        },
        // informacion notificacion
        notifyToPerson: {
          name: data.notifyto.name,
          phone: data.notifyto.contact,
        },
        // informacion del agente
        destinationAgentPerson: {
          name: data.destinyAgent.name,
          phone: data.destinyAgent.contact,
        },
        // informacion de carga y contenido
        conveyors: {
          name: data.forwarder.name,
          referenceCode: data.referenceCode,
          referencePlate: data.forwarder.licensePlate,
          loadingPlace: data.loadingPlace,
          exit: data.exit,
        },
        documentContent: content,
        documentCharges: charges,
        isSigning,
      };

      let document: any = null;

      if (!documentId) {
        const axiosResponse = await axiosEstimates.post('/api/document', dataPorte);
        document = axiosResponse.data.data;
      } else {
        const axiosResponse = await axiosEstimates.put('/api/document', { ...dataPorte, id: documentId });
        document = axiosResponse.data.data;
      }

      if (document) {
        handleNavigatePrint(document.id, location.pathname + '&value=1');

        Swal.fire({
          icon: 'success',
          title: t('alert.success', { ns: 'common' }) || '',
          text: t('alert.supplier.success', { ns: 'common' }) || '',
          showCloseButton: true,
        });
      }
    } catch (err) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: t('alert.somethingerror', { ns: 'common' }) || '',
        showCloseButton: true,
      });
    }
  };

  const signingDocument = async (data: CartaPorte) => {
    try {
      const charges = data.charges.map(charge => ({
        description: charge.description,
        prepaidCharges: `${charge.prepaid}`,
        chargesReceivable: `${charge.collect}`,
      }));
      const content = data.content.map(content => ({
        type: content.type || null,
        amount: content.quantity || null,
        description: content.decription,
        kglb: content.kglb || '',
        grossPrice: content.grossWeight || null,
        volume: content.volume || null,
      }));
      const dataPorte = {
        airwill_folio: data.bill,
        recipient: data.invoice,
        budgetId: parseInt(id || '0'),
        strategy: DOCUMENT_TYPE_OBJ.CARTA_PORTE,
        reference: data.reference,
        expeditionDate: data.expeditionDate,
        consignee: data.consignee,
        pilot: data.forwarder.pilot,
        pilotVan: data.forwarder.van,
        pilotLicenseNumber: data.forwarder.numberLicense,
        documentTypeId: 2,
        pilotEntry: data.entrance,
        pilotFinalDestination: data.finalDestination,
        // informacion del consignee
        consigneePerson: {
          name: data.by.name,
          address: data.by.direction,
          email: data.by.email,
          phone: data.by.phone,
        },
        forwarderPerson: {
          name: data.shipper.name,
          address: data.shipper.direction,
          email: data.shipper.email,
          phone: data.shipper.phone,
        },
        // informacion notificacion
        notifyToPerson: {
          name: data.notifyto.name,
          phone: data.notifyto.contact,
        },
        // informacion del agente
        destinationAgentPerson: {
          name: data.destinyAgent.name,
          phone: data.destinyAgent.contact,
        },
        // informacion de carga y contenido
        conveyors: {
          name: data.forwarder.name,
          referenceCode: data.referenceCode,
          referencePlate: data.forwarder.licensePlate,
          loadingPlace: data.loadingPlace,
          exit: data.exit,
        },
        documentContent: content,
        documentCharges: charges,
        isSigning: true,
      };

      let document: any = null;

      if (!documentId) {
        const axiosResponse = await axiosEstimates.post('/api/document', dataPorte);
        document = axiosResponse.data.data;
      } else {
        const axiosResponse = await axiosEstimates.put('/api/document', { ...dataPorte, id: documentId });
        document = axiosResponse.data.data;
      }

      if (document) {
        handleNavigatePrint(document.id, location.pathname);

        Swal.fire({
          icon: 'success',
          title: t('alert.success', { ns: 'common' }) || '',
          text: t('alert.supplier.success', { ns: 'common' }) || '',
          showCloseButton: true,
        });
      }
    } catch (err) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: t('alert.somethingerror', { ns: 'common' }) || '',
        showCloseButton: true,
      });
    }
  };

  return {
    t,
    control,
    countryItems,
    contentFields,
    contentAppend,
    contentRemove,
    chargesFields,
    chargesAppend,
    chargesRemove,
    onSubmit: handleSubmit(onSubmit),
    documentId,
    navigate: handleNavigatePrint,
    handleFormChange,
    setIsSigning,
    isSigning,
    signingDocument: handleSubmit(signingDocument),
    canEdit,
  };
};

export default useCartaPorte;
