import { Alert, AlertTitle, Card, CardContent, CircularProgress } from '@mui/material';

import { useTranslation } from 'react-i18next';

export function DocumentLoading() {
  const { t } = useTranslation('common');

  return (
    <Card sx={{ width: '100%' }}>
      <CardContent>
        <Alert icon={false} severity='info'>
          <AlertTitle sx={{ fontWeight: 600, marginBottom: theme => `${theme.spacing(1)} !important` }}>
            {t('toast.loading')}
          </AlertTitle>
          <CircularProgress />
        </Alert>
      </CardContent>
    </Card>
  );
}

export function DocumentError({ error }: { error: boolean | string }) {
  const { t } = useTranslation('common');

  return (
    <Card sx={{ width: '100%' }}>
      <CardContent>
        <Alert icon={false} severity='error'>
          <AlertTitle sx={{ fontWeight: 600, marginBottom: theme => `${theme.spacing(1)} !important` }}>
            {typeof error === 'string' ? error : t('toast.error')}
          </AlertTitle>
          {/* {t('document.error.message')} */}
        </Alert>
      </CardContent>
    </Card>
  );
}
