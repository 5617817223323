// @Components
import UserDropdown from './Dropdown/UserMenu';
import LanguageDropdown from './Dropdown/LanguageMenu';

// @MUI Components
import { Box } from '@mui/material';

// @Styled Components
import { AppBar } from './StyledComponents/AppBarStyled';
import { Toolbar } from './StyledComponents/ToolbarStyled';

// @Interfaces and types
interface IProps {
  open: boolean;
  handleDrawerToggle: () => void;
}

/**
 * @component
 * @subcategory Global
 *
 * @description
 * Appbar component that serves as the main navigation bar for the application.
 * #### Example
 * ```
 * const [open, setOpen] = useState(false)
 * const handleDrawerToggle = () => {}
 *
 * return(
 *  <Appbar handleDrawerToggle={handleDrawerToggle} open={open} />
 * )
 * ```
 *
 * @property {boolean} open Whether the drawer is open or not.
 * @property {function} handleDrawerToggle The function to handle the drawer toggle (Without use).
 *
 * @returns {JSX.Element} The Appbar component.
 * @author CML Exports Front-End Developers
 */
export default function Appbar({ handleDrawerToggle, open }: IProps) {
  return (
    <AppBar color='default' className='layout-navbar'>
      <Toolbar className='navbar-content-container'>
        {/* <BurgerMenu handleDrawerToggle={handleDrawerToggle} open={open} /> */}
        <Box />

        <Box>
          <LanguageDropdown />
          <UserDropdown />
        </Box>
      </Toolbar>
    </AppBar>
  );
}
