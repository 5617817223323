// @Library
import { AsyncThunkPayloadCreator, createAsyncThunk } from '@reduxjs/toolkit';

// @Utils
import { axiosEstimates } from '../../../utils/api';

// @Models
import { ISuppliersRequest } from '../../../models';

// !--- INIT HANDLER ---!
const handleGetSuppliers: AsyncThunkPayloadCreator<ISuppliersRequest[], undefined, {}> = async (
  payload,
  { rejectWithValue },
) => {
  try {
    const response = await axiosEstimates.get<ISuppliersRequest[]>('suppliers');
    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
};

const handleGetSuppliersByTransport: AsyncThunkPayloadCreator<ISuppliersRequest[], { id: number }, {}> = async (
  payload,
  { rejectWithValue },
) => {
  try {
    const response = await axiosEstimates.get<ISuppliersRequest[]>('suppliers-by-transport/' + payload.id);
    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
};

// !--- END HANDLER ---!

// !--- INIT THUNKS ---!
export const getSuppliers = createAsyncThunk('suppliers/getAll', handleGetSuppliers);
export const getSuppliersByTransport = createAsyncThunk('suppliers/getByTransport', handleGetSuppliersByTransport);

// !--- END THUNKS ---!
