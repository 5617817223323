// @Utils
import i18n from '../../../i18n';
import { axiosEstimates } from '../../../utils';

// @Interfaces and Types
import { IServicesRequest } from '../../../models';

const getServices = async (value: string) => {
  try {
    const response = await axiosEstimates.post<IServicesRequest[]>('services-by-type', { serviceTypeId: value });

    return response.data;
  } catch (err) {
    console.error(err);
    return null;
  }
};

export default getServices;
