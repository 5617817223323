import * as yup from 'yup';

export const schemaValidation = yup
  .object()
  .shape({
    minFolio: yup.number().typeError('errors.required').required('errors.required'),
    maxFolio: yup
      .number()
      .typeError('errors.required')
      .required('errors.required')
      .test('max', 'El valor máximo no puede ser menor que el valor mínimo', function (value) {
        const minValue: number = this.resolve(yup.ref('minFolio'));
        if (typeof value === 'undefined') {
          return false; // No pasará la validación si value es undefined
        }
        return value > minValue;
      }),
    authorizationDate: yup.date().typeError('errors.required').required('errors.required'),
    authorizationNumber: yup.string().required('errors.required'),
  })
  .required();
