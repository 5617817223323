/* eslint-disable camelcase */
import { AnyAction, AsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import Swal from 'sweetalert2';
// @Models
import { IUserSessionProps } from '../../../models';
// @Constants
import { LOADING_INIT } from '../../../utils/contants';
// @Actions
import { getLogin, getLoginToken, getUser } from './ActionsAsync';
// @AsyncThunk
type GenericAsyncThunk = AsyncThunk<unknown, unknown, any>;
type PendingAction = ReturnType<GenericAsyncThunk['pending']>;
type RejectedAction = ReturnType<GenericAsyncThunk['rejected']>;

const isLoaderAction = (action: AnyAction): action is PendingAction => {
  return action.type.startsWith('user/') && action.type.endsWith('/pending');
};

// const lang = window.localStorage.getItem('lang') as 'en' | 'es' | null;

const initialState: IUserSessionProps = {
  loading: LOADING_INIT,
  errors: {
    error: false,
    text: [],
  },
  access_token: '',
  isAuthenticated: false,
  token_type: '',
  admin_name: '',
  expires_in: 0,
  role: '',
  idCRM: null,
  language: 'es',
  loginCRM: false,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    logginCRM: (state, action: PayloadAction<{ loginCRM: boolean }>) => {
      state.loginCRM = action.payload.loginCRM;
    },
    logout: () => {
      return initialState;
    },
    changeLanguage: (state, action: PayloadAction<{ language: 'es' | 'en' }>) => {
      window.localStorage.setItem('lang', action.payload.language);
      state.language = action.payload.language;
    },
  },
  extraReducers: builder => {
    // Case para usuario logeado
    builder.addCase(getLogin.fulfilled, (state, action) => {
      state.access_token = action.payload.access_token;
      state.user = action.payload.user;
      state.isAuthenticated = true;
      state.expires_in = action.payload.expires_in;
      Swal.fire({
        icon: 'success',
        title: 'Bienvenido',
        text: 'Iniciaste sesión correctamente',
        timer: 2000,
      });
    });
    builder.addCase(getLoginToken.fulfilled, (state, action) => {
      state.access_token = action.payload.access_token;
      state.user = action.payload.user;
      state.isAuthenticated = true;
      state.expires_in = action.payload.expires_in;
      Swal.fire({
        icon: 'success',
        title: 'Bienvenido',
        text: 'Iniciaste sesión correctamente',
        timer: 2000,
      });
    });
    builder.addCase(getUser.fulfilled, (state, action) => {
      state.role = action.payload.role;
      state.idCRM = action.payload.id;
    });
    // Matcher para saber cuando una petición isLoading
    builder.addMatcher(isLoaderAction, (state, action) => {
      state.errors = {
        text: [],
        error: false,
      };

      const actionType = action.type.split('/')[1];

      if (actionType === 'getLogin') {
        state.loading = {
          show: true,
          type: 'button',
        };
      }

      state.loading = {
        show: true,
        type: 'screen',
      };
    });
    // Matcher para saber cuando una petición es rejected
    builder.addMatcher(
      (action): action is RejectedAction => action.type.startsWith('user/') && action.type.endsWith('/rejected'),
      state => {
        state.loading = LOADING_INIT;
        state.errors = {
          text: ['Ocurrió un error al procesar tu solicitud'],
          error: true,
        };
      },
    );
  },
});

export const { logout, changeLanguage, logginCRM } = userSlice.actions;

export default userSlice.reducer;
