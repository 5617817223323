import axios, { AxiosError } from 'axios';
import Swal from 'sweetalert2';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// @Utils
import { axiosEstimates } from '../../../utils/api';

export default function useSuppliers() {
  // NOTE: PERMISSIONS
  const [permissions, setPermissions] = useState<any>({});
  // NOTE: PAGINATION
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  // NOTE: STATUS
  const [check, setCheck] = useState(true);
  const [filter, setFilter] = useState('');
  const [openNotes, setOpenNotes] = useState(false);
  // NOTE: DATA
  const [notes, setNotes] = useState<string | undefined>();
  const [suppliersData, setSuppliersData] = useState<any[]>([]);
  const [openEditServices, setOpenEditServices] = useState(false);
  const [idEditServices, setIdEditServices] = useState<number>();

  const { t } = useTranslation('common');

  const navigate = useNavigate();

  // NOTE: ALL ACTIONS
  const getSuppliers = () => {
    (async () => {
      try {
        const responseGET: any = await axiosEstimates.get(`suppliers?filter=${filter}&status=${check ? 1 : 0}`, {
          headers: { 'x-module': 'suppliers' },
        });

        setSuppliersData(responseGET.data.suppliers);
        setPermissions(responseGET.data.permissions);
      } catch {
        setSuppliersData([]);
      }
    })();
  };

  // NOTE: ALL HANDLE FUNCTIONS
  const handleFilter = (value: string) => {
    setFilter(value);
  };

  const handleCheck = () => {
    setCheck(prev => !prev);
  };

  const handleEdit = (id: any) => navigate(`/suppliers/edit/${id}`);

  const handleDelete = (id: any) => {
    const validateBudgets: any = [''];
    const hasBudgets: any = false;

    const data = { id, status: 'Inactive' };

    Swal.fire({
      icon: 'warning',
      title: `${t('general.areYouSure')}`,
      showConfirmButton: true,
      showCancelButton: true,
      cancelButtonColor: '#d33',
      confirmButtonColor: '#3085d6',
      cancelButtonText: t('general.cancel', { ns: 'common' }) || '',
      confirmButtonText: t('general.confirm', { ns: 'common' }) || '',
    }).then(async action => {
      if (action.isConfirmed) {
        try {
          await axiosEstimates.delete(`supplier/${id}`);
          setSuppliersData(suppliersData.filter(d => d.id !== id));
        } catch (e: unknown) {
          const err = e as AxiosError;

          if (err.response?.status === 409) {
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'Este proveedor tiene datos relacionados por lo que no se puede eliminar',
            });
          }
        }
      }
    });
  };

  const changeStatus = (id: any, supplierStatus: any) => {
    const data = {
      id,
      status: supplierStatus,
    };

    axiosEstimates.post('supplier/status/', data).then(async () => {
      const responseGET: any = await axios.get(`${process.env.REACT_APP_ESTIMATES_API}suppliers`);
      setSuppliersData(responseGET.data);
    });

    getSuppliers();

    if (data.status === 'active') {
      Swal.fire(
        t('general.active', { ns: 'validations' }) || '',
        t('supplier.active', { ns: 'validations' }) || '',
        'success',
      );
    } else {
      Swal.fire(
        t('general.inactive', { ns: 'validations' }) || '',
        t('supplier.inactive', { ns: 'validations' }) || '',
        'success',
      );
    }
  };

  const toggleNotes = (notes?: string) => {
    setOpenNotes(prev => !prev);
    setNotes(notes);
  };

  const toggleEditServices = (id: number) => {
    setOpenEditServices(prev => !prev);
    setIdEditServices(id);
  };

  // NOTE: ALL USE EFFECTS
  useEffect(() => {
    getSuppliers();
  }, [check, filter]);

  return {
    // NOTE: FILTERS
    check,
    filter,
    // NOTE: PAGINATION
    page,
    setPage,
    pageSize,
    setPageSize,
    // NOTE: DATA
    suppliersData,
    // NOTE: NOTES
    notes,
    toggleNotes,
    // NOTE: ALL ACTIONS
    changeStatus,
    // NOTE: ALL HANDLE FUNCTIONS
    handleEdit,
    handleDelete,
    handleCheck,
    handleFilter,
    openNotes,
    permissions,
    toggleEditServices,
    openEditServices,
    idEditServices,
  };
}
