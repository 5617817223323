// @Views
import { SellerForm } from '../seller-form/seller-form.view';
import { CustomerForm } from '../customer-form/customer-form.view';
import { ShipmentForm } from '../shipment-form/shipment-form.view';
import { ServiceStep } from '../service-step/service-step.view';
import { Final } from '../final-step/final-step';
import { Box } from '@mui/material';

// @Redux
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/reducers';
import { axiosEstimates } from '../../../utils/api';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';

interface IProps {
  currentStep: number;
  setIsShowPDF: Dispatch<SetStateAction<boolean>>;
}

export function DisplayStep({ currentStep, setIsShowPDF }: IProps) {
  const [permissions, setPermissions] = useState<string[]>([]); // ['seller', 'customer', 'shipment', 'service'

  const { status } = useSelector((state: RootState) => state.budgetForm);

  const handlePermissions = async () => {
    try {
      const response = await axiosEstimates.get('budgetform', {
        headers: { 'x-module': 'budget_form' },
      });

      setPermissions(Object.keys(response.data.permissions));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    handlePermissions();
  }, []);

  return (
    <Box>
      {currentStep === 1 && <SellerForm permissions={permissions} />}
      {currentStep === 2 && <CustomerForm />}
      {currentStep === 3 && <>{status === 'request' ? <ShipmentForm /> : <ServiceStep />}</>}
      {currentStep === 4 && <Final setIsShowPDF={setIsShowPDF} />}
    </Box>
  );
}
