// @MUI Components
import { Grid, Card } from '@mui/material';

// @Views
import { Comments, FilterToCancel } from '../../views/budgets';

// @Hooks
import useBudget from './useBudget';

// @Components
import Loader from '../../components/Loader';
import { Error401 } from '../../components/Error401';
import StatusOptions from './components/StatusOptions';
import HeaderActions from './components/HeaderActions';
import CollapsibleBudget from './components/CollapsibleBudget';

// @Types
import { IProps } from './types';

/**
 * @category Budget
 *
 * @see Route: "https://estimates.cmlgroupapps.com/budgets"
 *
 * @description
 * A page that displays a list of budgets with filtering and pagination capabilities.
 *
 * #### Example
 * ```
 * return(
 *  <BudgetPage type={1} />
 * )
 * ```
 *
 * @property {number} type The type of budget to display (1: SV, 2: CTIS, 3: USA)
 *
 * @return {JSX.Element} A JSX element representing the BudgetPage component.
 * @author CML Exports Front-End Developers
 */
const BudgetPage = ({ type }: IProps) => {
  const {
    // NOTE: PERMISSIONS
    permissions,
    // NOTE: STATUS
    isLoading,
    isLoadingByFilters,
    isOpenFilter,
    isOpenComments,
    // NOTE: PAGINATION
    count,
    page,
    setPage,
    perPage,
    setPerPage,
    // NOTE: DATA
    idUser,
    isSeller,
    idBudget,
    budgets,
    value,
    setValue,
    status,
    companyByRol,
    cancelManyTitle,
    addBudgetTitle,
    typeFilter,
    // NOTE: UTILS
    control,
    resetField,
    resetState,
    // NOTE: ACTIONS
    cancelStatus,
    setTypeFilter,
    setFilterCustomer,
    // NOTE: HANDLE FUNCTIONS
    handleView,
    handleStatus,
    handleNavigate,
    handleCustomerFilter,
    handleToggleFilter,
    handleToggleComments,
  } = useBudget({ type });

  if (!isLoadingByFilters && !value) {
    if (isLoading) return <Loader />;
    if (!budgets.length && !permissions.length) return <Error401 />;
  }

  return (
    <>
      {(permissions.includes('comments') || permissions.includes('chat_with_provider')) && (
        <Comments open={isOpenComments} toggle={handleToggleComments} idBudget={idBudget} />
      )}

      <FilterToCancel
        idUser={idUser}
        isSeller={isSeller}
        type={type}
        status={status}
        resetState={resetState}
        open={isOpenFilter}
        toggle={handleToggleFilter}
      />

      <Grid container sx={{ width: '90%', ml: 'auto', mr: { xs: 0.5, md: 'auto' }, zIndex: 1 }}>
        <StatusOptions status={status} handleStatus={handleStatus} />

        <Grid item xs={12}>
          <Card sx={{ width: '100%' }}>
            <HeaderActions
              permissions={permissions}
              cancelManyTitle={cancelManyTitle}
              addBudgetTitle={addBudgetTitle}
              status={status}
              value={value}
              setValue={setValue}
              companyByRol={companyByRol}
              typeFilter={typeFilter}
              setTypeFilter={setTypeFilter}
              setFilterCustomer={setFilterCustomer}
              control={control}
              resetField={resetField}
              toggleFilter={handleToggleFilter}
              handleNavigate={handleNavigate}
              handleCustomerFilter={handleCustomerFilter}
            />

            {/* TABLE OF BUDGETS */}
            <CollapsibleBudget
              isSV={type === 1}
              isUSA={type === 3}
              isCTIS={type === 2}
              permissions={permissions}
              isLoading={isLoading}
              // isLoadingByFilters={isLoadingByFilters}
              rowsPerPage={perPage}
              page={page}
              setPage={setPage}
              setRowsPerPage={setPerPage}
              data={budgets}
              count={count || budgets.length}
              handleView={handleView}
              cancelStatus={cancelStatus}
              toggleComments={handleToggleComments}
            />
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default BudgetPage;
