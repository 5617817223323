import toast from 'react-hot-toast';
import { useForm } from 'react-hook-form';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// @MUI Imports
import { DialogActions, Button } from '@mui/material';
import { Icon } from '@iconify/react';
import { DataGrid } from '@mui/x-data-grid';

// @Components
import Pagination from '../../../../components/Pagination';
import TextForm from '../../../../components/TextForm';
import DialogP from '../../../../components/Dialog';

// @Utils
import { Customer } from '../models';
import { GetCustomers, PostSendCustomers } from '../services';
import { defaultColumns } from '../utils/modal-columns';
import LocalizedTextMap from '../../../../translations/LocalizedESTextMap';
import { DataGridStyles } from '../../../../utils';
import { RootState } from '../../../../redux/reducers';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../../redux/store';
import { getCustomers } from '../../../../redux/slices/budget-form/actions-async';

interface Props {
  open: boolean;
  handleClose: () => void;
  serviceIds: number[];
  cleanServices(): void;
}

function ContactModal({ open, handleClose, serviceIds, cleanServices }: Props) {
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [selected, setSelected] = useState<Customer[]>([]);
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(5);
  const [page, setPage] = useState(0);
  const [selectedCustomer, setSelectedCustomer] = useState<number[]>([]);

  const { budgetForm, user } = useSelector((state: RootState) => state);

  const userData = user.user;

  const dispatch = useAppDispatch();

  const { customersData } = budgetForm;

  const { control, getValues, setValue } = useForm();

  const { t } = useTranslation(['common']);

  const columns = defaultColumns();

  useEffect(() => {
    if (open) {
      setValue('comments', '');
      setSelectedCustomer([]);
      setPage(0);
      setPageSize(5);
      handleGet();
      findCustomers();
      if (userData && userData.email !== '') {
        dispatch(getCustomers({ email: userData.email }));
      }
    }
  }, [open]);

  const findCustomers = async () => {
    try {
      const customersFind: Customer[] = [];

      customersData.forEach(cliente => {
        const customer = customers.find(c => c.name === cliente.name);
        if (customer) customersFind.push(customer);
      });
      setSelected(customersFind);
    } catch (error) {
      toast.error(t('general.error', { ns: 'common' }));
    }
  };

  const handleGet = async () => {
    setLoading(true);
    try {
      const response = await GetCustomers(serviceIds);
      setCustomers(response);
    } catch (error) {
      // toast.error(t('email.error.getCustomers', { ns: 'common' }));
    }
    setLoading(false);
  };

  const handleSend = async () => {
    const toastId = toast.loading(t('toast.loading'));

    try {
      await PostSendCustomers(selectedCustomer, getValues('comments'), serviceIds);
      toast.dismiss(toastId);

      toast.success(t('toast.sent'));
      cleanServices();
      handleClose();
    } catch (error) {
      toast.dismiss(toastId);
      toast.error(t('general.error', { ns: 'common' }));
    }
  };

  return (
    <DialogP open={open} handleClose={handleClose}>
      {open && (
        <div style={{ width: '100%' }}>
          <DataGrid
            loading={loading}
            pageSize={pageSize}
            page={page}
            autoHeight
            rows={customers}
            sx={{ ...DataGridStyles }}
            checkboxSelection
            disableSelectionOnClick
            columns={columns}
            hideFooter
            onSelectionModelChange={newSelection => {
              setSelectedCustomer(newSelection as number[]);
            }}
            selectionModel={selectedCustomer}
            localeText={LocalizedTextMap()}
          />
        </div>
      )}

      <Pagination
        count={customers.length}
        setPage={setPage}
        setPageSize={setPageSize}
        page={page}
        limitPagination={[5]}
        pageSize={pageSize}
      />

      <TextForm
        control={control}
        name='comments'
        label={t('general.comments', { ns: 'common' })}
        multiline
        minRows={4}
      />

      <DialogActions>
        <Button
          startIcon={<Icon icon='ri:mail-send-line' />}
          size='medium'
          sx={{ m: 2 }}
          variant='contained'
          onClick={handleSend}
          disabled={selectedCustomer.length === 0}
        >
          {t('general.send')}
        </Button>
      </DialogActions>
    </DialogP>
  );
}

export default ContactModal;
