import { forwardRef } from 'react';
import { NumericFormat, InputAttributes } from 'react-number-format';

interface CustomProps {
  onChange: (event: number) => void;
  name: string;
  value: any;
}

/**
 * @component
 * @subcategory Global
 *
 * @description
 * A custom number format component.
 * #### Example
 * ```
 * return(
 *  <NumberFormatCustom onChange={(event) => console.log(event)} name="example" value={value} />
 * )
 * ```
 *
 * @property {string} name The name of the input field.
 * @property {any} value The current value of the input field.
 * @property {function} onChange A callback function to handle changes.
 *
 * @return {JSX.Element}
 * @author CML Exports Front-End Developers
 */
const NumberFormatCustom = forwardRef<typeof NumericFormat<InputAttributes>, CustomProps>(function NumberFormatCustom(
  { onChange, value, ...other },
  ref,
) {
  return (
    <NumericFormat
      {...other}
      value={value || '0'}
      decimalScale={0}
      getInputRef={ref}
      onValueChange={values => onChange(values.floatValue || 0)}
      // thousandSeparator
      onFocus={e => (e.target.value = '')}
    />
  );
});

export default NumberFormatCustom;
