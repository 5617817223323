import { Card, CardContent, Divider, CardHeader } from '@mui/material';
import { useFolioManagment } from './folio-managment.hook';

export function FolioManagmentView() {
  const { t } = useFolioManagment();
  return (
    <Card sx={{ width: '100%', zIndex: 1 }}>
      <CardContent sx={{ padding: 6 }}>
        <CardHeader title={t('general.invoice')} sx={{ display: 'flex', textAlign: 'center' }} />
        <Divider />
        {/* reedirige a add-expenses-view.page */}
      </CardContent>
    </Card>
  );
}
