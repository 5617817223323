/* eslint-disable no-console */
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';

// @Redux

// @Models
import { FFCaptureData } from '../../models/Models';
import { axiosEstimates } from '../../../../utils';
import Swal from 'sweetalert2';
import { useState } from 'react';
import { IGetBudgets } from '../../../../models';
// @Utils

interface IParams {
  data?: FFCaptureData;
  row?: IGetBudgets;
}

export function useInvoiceForm({ data, row }: IParams) {
  const [dataInput, setDataInput] = useState<FFCaptureData>({
    accountof: '',
    name: '',
    DUI: '',
    IVAWithheld: 0,
    passport: '',
  });
  const handleDataInput = (e: any) => {
    setDataInput(prev => ({
      ...prev,
      [e.name]: e.value,
    }));
  };
  const { t } = useTranslation(['common']);
  const { control, handleSubmit, getValues } = useForm<FFCaptureData>({
    defaultValues: data,
  });
  const { id: budgetId, total } = useParams();
  const navigate = useNavigate();

  const activeSwalModal = (title: string, text: string, callback: () => {}) => {
    // eslint-disable-next-line no-lone-blocks
    {
      Swal.fire({
        title,
        text,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: t('general.cancel', { ns: 'common' }) || '',
        confirmButtonText: t('general.confirm', { ns: 'common' }) || '',
      }).then(result => {
        try {
          if (result.isConfirmed) {
            callback();
            Swal.fire(
              t('general.good', { ns: 'validations' }) || '',
              t('general.approve', { ns: 'validations' }) || '',
              'success',
            );
          } else {
            return null;
          }
        } catch (error) {
          console.error('Error al eliminar la factura', error);
          Swal.fire({
            title: t('general.error', { ns: 'common' }) || '',
            text: t('general.deleteFailed', { ns: 'common' }) || '',
            icon: 'error',
          });
        }
      });
    }
  };
  const activeFFAlert = (callback: () => {}) => {
    activeSwalModal(t('general.sure', { ns: 'validations' }), t('general.ffAlert', { ns: 'validations' }), callback);
  };
  const onSubmit = async (data: any) => {
    const postGenerateBill = async (id: number) => {
      return await axiosEstimates.post(`/api/create-bill/${id}?billType=${1}`, { data });
    };

    await activeFFAlert(async () => {
      if (budgetId !== undefined && budgetId !== null && budgetId !== '') {
        try {
          const response = await postGenerateBill(parseInt(budgetId));
          if (response.data.alertFolio) {
            Swal.fire({
              title: t('general.folioLoad', { ns: 'common' }) || '',
              text: t('general.folioAlert', { ns: 'common' }) || '',
              icon: 'warning',
            });
          }
          if (response.status === 200) window.history.back();
          else {
            Swal.fire({
              title: t('general.error', { ns: 'common' }) || '',
              text: t('general.folioAlert', { ns: 'common' }) || '',
              icon: 'error',
            });
          }
        } catch (error: any) {
          Swal.fire({
            title: t('general.error', { ns: 'common' }) || '',
            text: t(error.response.data.message, { ns: 'common' }) || '',
            icon: 'error',
          });
        }
      }
    });
  };
  const cancel = () => {
    // eslint-disable-next-line no-console
    window.history.back();
  };

  return {
    t,
    control,
    navigate,
    onSubmit,
    cancel,
    setDataInput,
    handleSubmit,
    handleDataInput,
    data,
    row,
    total,
    getValues,
  };
}
