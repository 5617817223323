import * as React from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import { formatNumber } from '../../../../utils/format-number.util';
import { saleType } from '../../../../utils/select-items.util';
import Chip from '@mui/material/Chip';
import ColumHeader from '../../../../components/TableColumnHeader';
import { CardActions } from '@mui/material';
interface chipItem {
  value: string | number;
  label: string;
  color: 'default' | 'success' | 'info' | 'error' | 'primary' | 'secondary' | 'warning';
}
function Row({ row }: any) {
  const [open, setOpen] = React.useState(false);
  const { t } = useTranslation();
  const action: chipItem[] = [
    { value: 'update', color: 'warning', label: `${t('general.update', { ns: 'common' })}` },
    { value: 'create', color: 'info', label: `${t('general.create', { ns: 'common' })}` },
  ];
  const Typesale = saleType.find(type => type.value === row.saleType)?.label || '';
  const Description = action.find(description => description.value === row.description);
  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton aria-label='expand row' size='small' onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component='th' scope='row'>
          {format(new Date(row.updatedAt), 'MM/dd/yyyy - hh:mm:ss a')}
        </TableCell>
        <TableCell align='center'>
          <Chip
            size='medium'
            label={Description?.label || ''}
            color={Description?.color}
            sx={{
              textTransform: 'capitalize',
              padding: '0.5rem',
              margin: '0 0.5rem',
            }}
          />
        </TableCell>
        <TableCell align='center'>{row.userName}</TableCell>
        <TableCell align='center'>{row.quotationCode}</TableCell>
        <TableCell align='center'>
          {row.countryOrigin}/{row.countryDestination}
        </TableCell>
        <TableCell align='center'>{row.comission}%</TableCell>
        <TableCell align='center'>{format(new Date(row.expDate), 'MM/dd/yyyy')}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={open} timeout='auto' unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant='h6' gutterBottom component='div'>
                {t('general.details', { ns: 'common' })}
              </Typography>
              <Table stickyHeader size='small' aria-label='purchases'>
                <TableHead sx={{ backgroundColor: 'primary.main' }}>
                  <ColumHeader
                    headers={[
                      t('general.transportTime', { ns: 'common' }),
                      t('general.service', { ns: 'common' }),
                      t('general.cost', { ns: 'common' }),
                      t('general.typeSale', { ns: 'common' }),
                      t('Via', { ns: 'common' }),
                      t('general.typeService', { ns: 'common' }),
                    ]}
                  />
                </TableHead>
                <TableBody>
                  <TableRow key={row.hid}>
                    <TableCell align='center' component='th' scope='row'>
                      {row.transportTime}
                    </TableCell>
                    <TableCell align='center'>{row.name}</TableCell>
                    <TableCell align='center'>{formatNumber(row.cost)}</TableCell>
                    <TableCell align='center'>{Typesale}</TableCell>
                    <TableCell align='center'>{row.via}</TableCell>
                    <TableCell align='center'>{row.ServiceTypes}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function CollapsibleTable({ history }: any) {
  const { t } = useTranslation();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const rows = history;

  const currentRows = rows.filter((r: any, ind: number) => {
    return ind >= rowsPerPage * page && ind < rowsPerPage * (page + 1);
  });

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <>
      <TableContainer component={Paper} sx={{ maxHeight: 400, minHeight: 400 }}>
        <Table stickyHeader aria-label='collapsible table'>
          <TableHead sx={{ backgroundColor: 'primary.main' }}>
            <ColumHeader
              headers={[
                '',
                t('title.date', { ns: 'common' }),
                t('title.description', { ns: 'common' }),
                t('title.username', { ns: 'common' }),
                t('general.invoice', { ns: 'common' }),
                `${t('general.origin', { ns: 'common' })}/${t('general.destiny', { ns: 'common' })}`,
                t('general.commission', { ns: 'common' }),
                t('general.validity', { ns: 'common' }),
              ]}
            />
          </TableHead>
          <TableBody>
            {currentRows.map((row: { id: any }) => (
              <Row key={row.id} row={row} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <CardActions sx={{ mt: 5 }}>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 50]}
              count={rows.length}
              sx={{ width: '100vw' }}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage={t('filters.rowsPerPage', { ns: 'common' })}
            />
          </TableRow>
        </TableFooter>
      </CardActions>
    </>
  );
}
