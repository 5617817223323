import * as React from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useTranslation } from 'react-i18next';
import { CardActions, Chip } from '@mui/material';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import ColumHeader from '../../../../components/TableColumnHeader';
import { format } from 'date-fns';
import { handleGetSuppliersDate } from '../typeService-history.hook';
import { useEffect, useState } from 'react';

interface chipItem {
  value: string | number;
  label: string;
  color: 'default' | 'success' | 'info' | 'error' | 'primary' | 'secondary' | 'warning';
}

function Row({ row }: any) {
  const [open, setOpen] = useState(false);
  const [transportation, setTransportation] = useState<any>([]);

  const { t } = useTranslation();

  const action: chipItem[] = [
    { value: 'update', color: 'warning', label: `${t('general.update', { ns: 'common' })}` },
    { value: 'create', color: 'info', label: `${t('general.create', { ns: 'common' })}` },
    { value: 'delete', color: 'error', label: `${t('general.delete', { ns: 'common' })}` },
  ];

  const Description = action.find(description => description.value === row.action);

  const handleGetSuppliers = async () => {
    const data = await handleGetSuppliersDate(row.archivedAt)
      .then(res => setTransportation(res))
      // eslint-disable-next-line no-console
      .catch(err => console.error(err));
    return data;
  };

  useEffect(() => {
    handleGetSuppliers();
  }, []);

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton aria-label='expand row' size='small' onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component='th' scope='row'>
          {format(new Date(row?.archivedAt), 'MM/dd/yyyy - hh:mm:ss a')}
        </TableCell>
        <TableCell align='center'>
          <Chip
            size='medium'
            label={Description?.label || ''}
            color={Description?.color}
            sx={{
              textTransform: 'capitalize',
              padding: '0.5rem',
              margin: '0 0.5rem',
            }}
          />
        </TableCell>
        <TableCell align='center' component='th' scope='row'>
          {row?.name}
        </TableCell>
        <TableCell align='right'>{row?.user}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout='auto' unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant='h6' gutterBottom component='div'>
                {t('general.details', { ns: 'common' })}
              </Typography>
              <Table stickyHeader size='small' aria-label='purchases'>
                <TableHead sx={{ backgroundColor: 'primary.main' }}>
                  <ColumHeader
                    headers={[
                      t('title.date', { ns: 'common' }),
                      t('services.type', { ns: 'common' }),
                      t('title.description', { ns: 'common' }),
                    ]}
                  />
                  {/* <TableRow>
                    <TableCell sx={{ color: '#fff', fontWeight: 'bold' }} align='left'>
                      {t('general.name', { ns: 'common' })}
                    </TableCell>
                    <TableCell sx={{ color: '#fff', fontWeight: 'bold' }} align='right'>
                      {t('services.type', { ns: 'common' })}
                    </TableCell>
                    <TableCell sx={{ color: '#fff', fontWeight: 'bold' }} align='right'>
                      {t('general.status', { ns: 'common' })}
                    </TableCell>
                  </TableRow> */}
                </TableHead>
                {transportation?.length > 0 ? (
                  transportation.map((items: any) => (
                    // eslint-disable-next-line react/jsx-key
                    <TableBody>
                      <TableRow key={items?.id}>
                        <TableCell align='center' component='th' scope='row'>
                          {format(new Date(items?.archivedAt), 'MM/dd/yyyy - hh:mm:ss a')}
                        </TableCell>
                        <TableCell align='center'>{items?.idTransportation}</TableCell>
                        <TableCell align='center'>
                          <Chip
                            size='medium'
                            label={action.find(description => description.value === items.action)?.label}
                            color={action.find(description => description.value === items.action)?.color}
                            sx={{
                              textTransform: 'capitalize',
                              padding: '0.5rem',
                              margin: '0 0.5rem',
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  ))
                ) : (
                  <TableBody>
                    <TableRow>
                      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
                        <Box
                          sx={{
                            display: 'flex',
                            textAlign: ' center',
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: '#F7F7F7',
                            height: 300,
                          }}
                        >
                          <Typography sx={{ textAlign: 'center' }} gutterBottom component='div'>
                            {t('general.noRows', { ns: 'validations' })}
                          </Typography>
                        </Box>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function CollapsibleTable({ history }: any) {
  const { t } = useTranslation();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const rows = history;

  const currentRows = rows.filter((r: any, ind: number) => {
    return ind >= rowsPerPage * page && ind < rowsPerPage * (page + 1);
  });

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <>
      <TableContainer component={Paper} sx={{ maxHeight: 400, minHeight: 400 }}>
        <Table stickyHeader aria-label='collapsible table'>
          <TableHead sx={{ backgroundColor: 'primary.main' }}>
            <ColumHeader
              headers={[
                '',
                t('title.date', { ns: 'common' }),
                t('title.description', { ns: 'common' }),
                t('general.name', { ns: 'common' }),
                t('title.username', { ns: 'common' }),
              ]}
            />
          </TableHead>
          <TableBody>
            {currentRows.map((row: { id: any }) => (
              <Row key={row?.id} row={row} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <CardActions sx={{ mt: 5 }}>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 50]}
              count={rows.length}
              sx={{ width: '100vw' }}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage={t('filters.rowsPerPage', { ns: 'common' })}
            />
          </TableRow>
        </TableFooter>
      </CardActions>
    </>
  );
}
