// ** Mui
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

// Mdi
import ChevronRight from 'mdi-material-ui/ChevronRight';

export const MenuItemTextWrapper = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  padding: '1.5px 2.5px',
  justifyContent: 'space-between',
  transition: 'opacity .25s ease-in-out',
}));

export const MenuGroupToggleRightIcon = styled(ChevronRight)(({ theme }) => ({
  color: theme.palette.text.primary,
  transition: 'transform .25s ease-in-out',
}));
