import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

export const MainBoxWrapper = (drawerWidth: number) => {
  return styled(Box, { shouldForwardProp: prop => prop !== 'open' })<{ open?: boolean }>(({ theme, open }) => ({
    flexGrow: 1,
    minWidth: 0,
    display: 'flex',
    flexDirection: 'column',

    [theme.breakpoints.up('sm')]: {
      marginLeft: open ? '-1rem' : '65px',
      transition: theme.transitions.create('margin', {
        easing: open ? theme.transitions.easing.easeOut : theme.transitions.easing.sharp,
        duration: open ? theme.transitions.duration.enteringScreen : theme.transitions.duration.leavingScreen,
      }),
    },
  }));
};

export const ContentWrapper = styled('main')(({ theme }) => ({
  flexGrow: 1,
  width: '100%',
  display: 'flex',
  margin: '1rem -1rem',
  padding: theme.spacing(2, 0),
  transition: 'padding .25s ease-in-out',

  [theme.breakpoints.up('sm')]: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  [theme.breakpoints.up('lg')]: {
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
  },
  [theme.breakpoints.up('xl')]: {
    padding: theme.spacing(2, 6),
  },
}));

export const LayoutWrapper = styled('div')({
  display: 'flex',
  height: '100%',
});
