// @MUI Components
import { TableCell, TableRow, Typography } from '@mui/material';

// @Utils
import { format } from 'date-fns';

// @Models
import { ServiceTypeModel } from '../../../models';

interface IRowProps {
  row: any;
  handleEdit: (render: boolean, data: ServiceTypeModel) => void;
  handleDelete: (id: number) => void;
}

const Row = ({ row }: IRowProps) => {
  return (
    <TableRow>
      <TableCell scope='row'>
        <Typography sx={{ mx: 'auto', textAlign: 'center' }}>{row?.authorizationNumber}</Typography>
      </TableCell>
      <TableCell scope='row'>
        <Typography sx={{ mx: 'auto', textAlign: 'center' }}>{row?.minFolio}</Typography>
      </TableCell>
      <TableCell scope='row'>
        <Typography sx={{ mx: 'auto', textAlign: 'center' }}>{row?.maxFolio}</Typography>
      </TableCell>
      <TableCell scope='row'>
        <Typography sx={{ mx: 'auto', textAlign: 'center' }}>
          {format(new Date(row?.authorizationDate), 'MM/dd/yyyy')}
        </Typography>
      </TableCell>
    </TableRow>
  );
};

export default Row;
