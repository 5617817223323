// @Utils
import { axiosEstimates } from '../../../utils';

// @Interfaces and Types
import { IAdapterServicesOfSuppliersForm } from '../../../models';
interface IData {
  services: Omit<IAdapterServicesOfSuppliersForm, 'idSupplier'>[];
  idBudget: number | null;
  idSupplier: string;
}

const createServiceBudget = async (data: IData, files: File[]) => {
  try {
    const response = await axiosEstimates.post<{ message: string; id: any; quotationCode: string }>(
      'supplier/services/',
      data,
    );

    if (files && files?.length && response.data.id) {
      const fileData = new FormData();

      fileData.set('quotationCode', response.data.quotationCode);
      files.forEach((file, i) => fileData.set(`file-${i + 1}`, file));

      await axiosEstimates.post(`suppliers-files/${response.data.id}`, fileData);
    }
    return response;
  } catch (err: any) {
    return err.response;
  }
};

export default createServiceBudget;
