// ** MUI Imports
import DialogP from '../../../../components/Dialog';
import FrameViewer from '../../../../components/FrameViewer';

// @Translations
import { useTranslation } from 'react-i18next';

interface Props {
  open: boolean;
  handleClose: () => void;
}

function EmailViewModal({ open, handleClose }: Props) {
  const { t } = useTranslation();

  return (
    <DialogP open={open} dialogTitle={t('budgetPage.details', { ns: 'common' })} handleClose={handleClose}>
      <FrameViewer name='email.html' src='/assets/bg-3.png' />
    </DialogP>
  );
}

export default EmailViewModal;
