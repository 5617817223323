// @Models
import { ISellerForm, ICustomerForm, ICreateBudget, IShipment } from '../../../../models';

interface IParams {
  seller: ISellerForm;
  customer: ICustomerForm;
  type: number;
  shipments: IShipment[];
  userId: number;
  amountOfIva: number;
  amountOfSubtotal: number;
  amountOfTotal: number;
  status: 'active' | 'pending' | 'request';
}

export function sendBudgetAdapter({
  seller,
  customer,
  type,
  shipments,
  userId,
  amountOfIva,
  amountOfSubtotal,
  amountOfTotal,
  status,
}: IParams): ICreateBudget | null {
  if (!customer.customerId) {
    return null;
  }

  if (!seller.dateOfExpiration) {
    return null;
  }

  if (!seller.sellerId) {
    return null;
  }

  return {
    status,
    type,
    incoterms: seller.incoterms,
    idSeller: seller.sellerId,
    sellerName: seller.sellerName,
    sellerEmail: seller.sellerEmail,
    sellerAlphanumeric: seller.alphanumeric,
    idCustomer: customer.customerId,
    customer,
    createdBy: userId,
    amountOfIva,
    amountOfSubtotal,
    amountOfTotal,
    expirationDate: new Date(seller.dateOfExpiration).getTime(),
    shipments: shipments.map(shipment => {
      return {
        content: shipment.content,
        countryOrigin: shipment.origin,
        countryDestiny: shipment.destiny,
        addressOrigin: shipment.addressOrigin,
        addressDestiny: shipment.addressDestiny,
        unitMeasurement: shipment.unitOfMeasurement,
        length: shipment.length,
        width: shipment.width,
        height: shipment.high,
        serial: shipment.serial,
        weightVol: shipment.volumetricWeight,
        weightPhysical: shipment.physicalWeight,
        incometers: shipment.incoterms,
        comments: shipment.comments,
        area: shipment.area,
        insurance: shipment.insurance,
        taxationTo: shipment.taxationTo,
        idServiceType: shipment.idServiceType,
        idTransportation: shipment.idTransportation,
        services: shipment.services.map(service => ({
          idService: service.serviceId || 0,
          quantity: service.serviceAmount,
          ivaPerService: service.amountOfIva,
          subtotalPerService: service.price,
          totalPerService: service.amountOfService,
          typeSale: service.typeSale,
          unitPrice: service.priceCost,
          taxation: service.taxation,
          isCrating: service.isCrating,
        })),
      };
    }),
  };
}
