import { ISelectInput } from '../models';

export const comissionOptions = [
  { label: '10%', value: 10 },
  // {
  //   label: '12%',
  //   value: 12,
  // },
  // {
  //   label: '9%',
  //   value: 9,
  // },
  // {
  //   label: '5%',
  //   value: 5,
  // },
  // {
  //   label: '2%',
  //   value: 2,
  // },
  // {
  //   label: '0%',
  //   value: 0,
  // },
];

export const saleType = [
  { label: 'Exento', labelEN: 'Exempt', value: 1 },
  { label: 'No Sujeto', labelEN: 'Not Subject', value: 2 },
  { label: 'Grabado', labelEN: 'Recorded', value: 3 },
];

export const kglb = [
  { label: 'Kg', value: 'Kg' },
  { label: 'Lb', value: 'Lb' },
];

export const documentType = [
  { label: 'DUCA', value: 'DUCA' },
  { label: 'Factura Final', labelEN: 'Final invoice', value: 'FIIN' },
  { label: 'Crédito Fiscal', labelEN: 'Fiscal credit', value: 'FICR' },
  { label: 'Otro', labelEN: 'Other', value: 'OTHER' },
];

export const identificationTypeItems = [
  { value: '1', label: 'NIT' },
  { value: '2', label: 'NRC' },
  { value: '3', label: 'TAX ID' },
  { value: '4', label: 'RFC' },
  { value: '5', label: 'general.other' },
];

export const statusItems: ISelectInput<number>[] = [
  { label: 'Todos', labelEN: 'All', value: 0 },
  { label: 'Pendiente', labelEN: 'Pending', value: 1 },
  { label: 'Cancelado', labelEN: 'Cancelled', value: 2 },
  { label: 'Aprobado', labelEN: 'Approve', value: 3 },
  { label: 'Pagado', labelEN: 'Paid', value: 3 },
];
