import { createSlice, PayloadAction, AnyAction, AsyncThunk } from '@reduxjs/toolkit';

// @Models
import { ITransportationRequest, Loading, ITransportationInitialState } from '../../../models';

// @Actions
import { getTransportations } from './actions-async';

// @AsyncThunk
type GenericAsyncThunk = AsyncThunk<unknown, unknown, any>;
type PendingAction = ReturnType<GenericAsyncThunk['pending']>;
type RejectedAction = ReturnType<GenericAsyncThunk['rejected']>;

const isLoaderAction = (action: AnyAction): action is PendingAction => {
  return action.type.startsWith('user/') && action.type.endsWith('/pending');
};

const LOADING_INIT: Loading = {
  show: false,
  type: 'screen',
};

export const initialState: ITransportationInitialState = {
  loading: LOADING_INIT,
  errors: {
    error: false,
    text: [],
  },
  permissions: undefined,
  transportations: [],
  transportationItemsES: [],
  transportationItemsEN: [],
};

export const transportationSlice = createSlice({
  name: 'transportations',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getTransportations.fulfilled, (state, action: PayloadAction<ITransportationRequest>) => {
      const lang = window.localStorage.getItem('lang') || 'es';
      const isSpanish = lang === 'es';

      state.permissions = action.payload.permissions;
      state.transportations = action.payload.data;
      state.transportationItemsEN = action.payload.data.map(transporte => {
        return {
          value: transporte.id,
          label: transporte.nameEN,
        };
      });
      state.transportationItemsES = action.payload.data.map(transporte => {
        return {
          value: transporte.id,
          label: transporte.name,
        };
      });
    });
    // Matcher para saber cuando una petición isLoading
    builder.addMatcher(isLoaderAction, state => {
      state.errors = {
        text: [],
        error: false,
      };

      state.loading = {
        show: true,
        type: 'screen',
      };
    });
    // Matcher para saber cuando una petición es rejected
    builder.addMatcher(
      (action): action is RejectedAction => action.type.startsWith('user/') && action.type.endsWith('/rejected'),
      (state, action) => {
        state.loading = LOADING_INIT;
        state.errors = {
          text: ['Ocurrió un error al procesar tu solicitud'],
          error: true,
        };
      },
    );
  },
});

// Action creators are generated for each case reducer function
// export const { quotInfo, setServices } = transportationSlice.actions;
export default transportationSlice.reducer;
