import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

// @MUI Components
import { Drawer, Typography, Box, Grid } from '@mui/material';

// @MUI Icons
import Close from 'mdi-material-ui/Close';

// @Utils
import { saleType } from '../../../../../utils/select-items.util';

// @Hooks
import { useServiceSuppliersForm } from '../../../hooks/useServiceSuppliersForm';

// @Components
import SelectWithSearch from '../../../../../components/SelectWithSearch';
import { SelectInput, ButtonForm, NumberField } from '../../../../../components/index';

// @Services
import { getSupplierCategories } from '../../../services/getCategoryItems';
import getServicesTypesItems from '../../../services/getServicesTypesItems';

// @Interfaces and Types
import { IServicesSupplier, IShipment } from '../../../../../models';
import getServices from '../../../services/getServices';
interface IProps {
  open: boolean;
  toggle: () => void;
  handleAddService: (value: Omit<IServicesSupplier, 'id'>) => void;
  handleEditService: (value: IServicesSupplier) => void;
  service?: Partial<IServicesSupplier>;
  shipments: IShipment[];
}

export default function ServiceSuppliersForm({
  handleAddService,
  handleEditService,
  service,
  open,
  toggle,
  shipments,
}: IProps) {
  const {
    // NOTE: ACTIONS
    onSubmit,
    // NOTE: ALL DATA
    control,
    shipmentItems,
    watchIdTransportation,
    watchIdServiceType,
    watchIdService,
    type,
    // NOTE: ALL HANDLE FUNCTIONS
    onChangeTransportations,
    onChangeService,
    onChangeTypeService,
    onChangeShipment,
    onChangeCom,
    onChangeCost,
    onChangeSalePrice,
  } = useServiceSuppliersForm({
    handleAddService,
    handleEditService,
    service,
    open,
    shipments,
  });

  const { idSupplier } = useParams();

  const handleGetCategories = async (...props: any) => {
    const data = await getSupplierCategories(props, Number(idSupplier));

    return data;
  };

  const { t } = useTranslation('common');

  return (
    <Drawer
      open={open}
      anchor='right'
      variant='temporary'
      onClose={toggle}
      ModalProps={{ keepMounted: true }}
      sx={{ '& .MuiDrawer-paper': { width: { xs: 300, sm: 400 } } }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: ({ spacing }) => spacing(3, 4),
          backgroundColor: ({ palette }) => palette.background.default,
        }}
      >
        <Typography variant='h6'>{service?.type === 'SE' ? t('general.service') : t('general.management')}</Typography>
        <Close fontSize='small' onClick={toggle} sx={{ cursor: 'pointer' }} />
      </Box>

      <Box sx={{ padding: '1rem' }}>
        <Grid container spacing={5}>
          {shipmentItems.length > 0 && (
            <Grid item xs={12}>
              <SelectInput
                control={control}
                name='idShipment'
                label={t('general.shipment')}
                items={shipmentItems}
                onExternalChange={onChangeShipment}
              />
            </Grid>
          )}

          <Grid item xs={12}>
            <SelectWithSearch
              control={control}
              name='idTransportation'
              label={t('general.category')}
              getInitialItems
              setInitialItem={watchIdTransportation || ''}
              getItemsFunction={handleGetCategories}
              onExternalChange={onChangeTransportations}
            />
          </Grid>

          <Grid item xs={12}>
            <SelectWithSearch
              control={control}
              name='idServiceType'
              label={t('general.typeService')}
              getInitialItems={Boolean(watchIdTransportation)}
              setInitialItem={(watchIdTransportation && watchIdServiceType) || ''}
              getItemsFunction={getServicesTypesItems}
              functionParams={{ idTransportation: watchIdTransportation }}
              onExternalChange={onChangeTypeService}
              // eslint-disable-next-line no-extra-boolean-cast
              disabled={!Boolean(watchIdTransportation)}
            />
          </Grid>

          <Grid item xs={12}>
            <SelectWithSearch
              control={control}
              name='idService'
              label={t('general.service')}
              getInitialItems={Boolean(watchIdServiceType)}
              setInitialItem={(watchIdServiceType && watchIdService) || ''}
              getItemsFunction={getServices}
              functionParams={watchIdServiceType}
              onExternalChange={onChangeService}
              // eslint-disable-next-line no-extra-boolean-cast
              disabled={!Boolean(watchIdServiceType)}
            />
          </Grid>

          <Grid item xs={12}>
            <NumberField name='cost' control={control} label={t('general.cost')} onExternalChange={onChangeCost} />
          </Grid>

          {type === 'SE' && (
            <Grid item xs={12}>
              <NumberField
                name='comission'
                control={control}
                label={t('general.commission')}
                onExternalChange={onChangeCom}
              />
            </Grid>
          )}

          <Grid item xs={12}>
            <NumberField
              name='salePrice'
              control={control}
              label={type === 'SE' ? t('general.priceWithCommission') : t('general.price')}
              onExternalChange={onChangeSalePrice}
            />
          </Grid>

          <Grid item xs={12}>
            <SelectInput name='saleType' control={control} label={t('general.typeSale')} items={saleType} />
          </Grid>
        </Grid>

        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', my: 10, gap: 2 }}>
          <ButtonForm label={t('general.save')} onClick={onSubmit} sx={{ width: '40%' }} />
          <ButtonForm label={t('general.cancel')} color='error' onClick={() => toggle()} sx={{ width: '40%' }} />
        </Box>
      </Box>
    </Drawer>
  );
}
