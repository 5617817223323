// @Mui
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import { CardHeader, IconButton, Typography } from '@mui/material';
import CardContent from '@mui/material/CardContent';
// import { DataGrid } from '@mui/x-data-grid';

// @Translations
import { useTranslation } from 'react-i18next';

// @Hooks
import CollapsibleTable from './utils/typeServicecollapsible';
import { useHistory } from './typeService-history.hook';
import KeyboardBackspace from '@mui/icons-material/KeyboardBackspace';
import { useNavigate } from 'react-router-dom';

export const TypeServiceHistory = () => {
  const { history, serviceTypes } = useHistory();

  const navigate = useNavigate();

  const { t } = useTranslation(['common']);

  return (
    <Grid container sx={{ width: '90%', ml: 'auto', mr: { xs: 0.5, md: 'auto' }, zIndex: 1 }}>
      <Grid item xs={12}>
        <Card sx={{ width: '100%', mb: 2 }}>
          <IconButton sx={{ display: 'flex', mt: 1 }} color='error' onClick={() => navigate('/services')}>
            <KeyboardBackspace />
          </IconButton>

          <CardHeader title={t('services.type')} />

          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Typography align='center'>{t('general.name', { ns: 'common' })}</Typography>
                <Typography align='center'>{serviceTypes.name}</Typography>
              </Grid>

              <Grid item xs={4}>
                <Typography align='center'>{t('services.type', { ns: 'common' })}</Typography>
                <Typography align='center'>
                  {serviceTypes?.transportation?.reduce(
                    (pre: string, trans: { name: any }) => pre + `${trans.name}, `,
                    '',
                  )}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        <Card>
          <Box sx={{ m: 2 }} />
          <Box
            sx={{
              p: 5,
              display: 'flex',
              flexWrap: 'wrap',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Box sx={{ display: 'flex', gap: 4, justifyContent: 'flex-end' }}>
              <Typography variant='h6' sx={{ textAlign: 'center' }}>
                {t('general.logbook')}
              </Typography>
            </Box>
          </Box>

          <CardContent>
            <CollapsibleTable history={history} />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
