/* eslint-disable no-console */
// @Library
import { useParams, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

// @Redux
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/reducers';
import { useAppDispatch } from '../../redux/store';
import { getBudgetAnalysis } from '../../redux/slices/budgets/actions-async';
import { axiosEstimates, axiosSystem } from '../../utils';
import { CalculatedCommision, IBudgetById } from '../../models';

interface TotalData {
  toPay: number;
  receivable: number;
  benefit: number;
  commision: number;
  utility: number;
}

const initTotalData: TotalData = {
  toPay: 0,
  receivable: 0,
  benefit: 0,
  commision: 0,
  utility: 0,
};

export function useBudgetView(_id?: string) {
  const { id } = useParams<{ id: string }>();
  const [totalData, setTotalData] = useState<TotalData>(initTotalData);
  const { budget, loading } = useSelector((state: RootState) => state.budgets);
  const [client, setClient] = useState<any>();
  const [budgetCommission, setbudgetCommission] = useState<IBudgetById>({
    budget: {
      id: 0,
      incoterms: '',
      canEdit: false,
      code: '',
      status: '',
      idSeller: 0,
      sellerName: '',
      idCustomer: 0,
      customerName: '',
      createdBy: 0,
      amountOfIva: 0,
      amountOfSubtotal: 0,
      amountOfTotal: 0,
      expirationDate: '',
      createdAt: '',
      updatedAt: '',
      servicesCount: 0,
      type: 0,
      idUser: 0,
      haveBill: false,
    },
    seller: {
      id: 0,
      name: '',
      email: '',
    },
    customer: {
      id: 0,
      name: '',
      email: '',
      country: '',
      taxId: '',
      phone: '',
      address: '',
      postalCode: '',
      state: '',
      city: '',
      commission: 0,
    },
    shipments: [],
  });
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getBudgetAnalysis(parseInt(_id || id || '0')));
  }, []);

  useEffect(() => {
    if (budget) {
      const toPay = budget.suppliers.reduce((prev, sup) => prev + sup.subTotalToPay, 0);

      const receivable = budget.suppliers.reduce((prev, sup) => prev + sup.subTotalReceivable, 0);

      // const commision = budget.suppliers.reduce((prev, sup) => prev + sup.totalCommission, 0);

      const num = (receivable * 100 - toPay * 100) / 100;
      const benefit = Math.round((num + Number.EPSILON) * 100) / 100;
      const commision = benefit * 0.1;

      setTotalData({
        toPay,
        receivable,
        benefit,
        commision,
        utility: benefit - commision,
      });
    }
  }, [budget]);

  const handleGet = async () => {
    try {
      const response = await axiosEstimates.get<IBudgetById>('budget/' + id);
      const commision = await axiosSystem.get<CalculatedCommision>('calculate-commission', {
        params: { user_id: response.data.budget.idSeller },
      });
      const iBudgetById = response.data;
      setbudgetCommission({ ...iBudgetById, commission: commision.data });
      setClient(response.data.customer.name);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    handleGet();
  }, []);

  const handleback = () => {
    navigate('/budgets');
  };
  const handlePrint = () => {
    navigate('/budgets/print/' + id);
  };

  return { budgetCommission, budget, loading, handleback, handlePrint, totalData, client };
}
