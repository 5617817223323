// @MUI Components
import { Box, Button, Grid, Typography } from '@mui/material';

// @MUI Icons
import { ArrowLeft } from 'mdi-material-ui';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

// @Hooks
import useInvoice from './useInvoice';

// @Components
import Error from '../../components/Error';
import Loader from '../../components/Loader';
import EmptyData from '../../components/EmptyData';
import PaymentForm from './components/PaymentForm';
import LogsList from './components/LogsList';
import LogsPagination from './components/LogsPagination';
import InvoiceInformation from './components/InvoiceInformation';

export function Invoice() {
  const {
    // NOTE: PERMISSIONS
    permissions,
    // NOTE: TRANSLATIONS
    t,
    // NOTE: PAGINATION
    page,
    PAGE_SIZE,
    // NOTE: TABS
    currentTab,
    // NOTE: SEARCH
    search,
    // NOTE: PAYMENTS LIST DROPDOWN
    paymentsIsOpen,
    setPaymentsIsOpen,
    // NOTE: STATUS
    isError,
    isLoading,
    // NOTE: DATA
    invoiceData,
    addPaymentTitle,
    payments,
    paymentsData,
    paymentsFound,
    addExpenseTitle,
    expenses,
    expensesData,
    expensesFound,
    // NOTE: FORMS TO ADD PAYMENT/EXPENSE
    control,
    setValue,
    formIsOpen,
    paymentFile,
    setPaymentFile,
    watchReceivedAmount,
    watchLimitAmount,
    // NOTE: ACTIONS
    createPayment,
    // NOTE: HANDLE FUNCTIONS
    handleTabs,
    handlePage,
    handleSubmit,
    handleSearch,
    handleToggleForm,
    handlePayExpense,
    handleEditPayment,
    handlePreviewFiles,
    handleDeletePayment,
  } = useInvoice();

  if (isLoading) return <Loader />;

  if (isError) return <Error description={isError?.des || null} />;

  if (!invoiceData) return <EmptyData text={t('general.invoiceNotFound')} />;

  return (
    <Grid container sx={{ width: '90%', ml: 'auto', mr: { xs: 0.5, md: 'auto' }, zIndex: 1 }}>
      <Grid item xs={12}>
        <Grid
          item
          xs={12}
          lg={11.5}
          sx={{ mx: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 7 }}
        >
          <InvoiceInformation data={invoiceData} permissions={permissions} />
        </Grid>

        <Grid item sx={{ display: 'flex', justifyContent: 'center', gap: 3 }}>
          <Button
            size='large'
            color='error'
            variant='contained'
            onClick={() => window.history.back()}
            sx={{ display: 'flex', alignItems: 'center' }}
          >
            <ArrowLeft />
            <Typography>{t('general.back')}</Typography>
          </Button>

          <Button
            size='large'
            color='primary'
            variant='contained'
            onClick={() => setPaymentsIsOpen(!paymentsIsOpen)}
            sx={{ display: 'flex', alignItems: 'center' }}
          >
            <VisibilityIcon />
            <Typography>{t('general.seeLogs')}</Typography>
          </Button>
        </Grid>

        <Box height={70} />

        <Grid
          id='logs-list'
          item
          xs={12}
          lg={11.5}
          sx={{ mx: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}
        >
          <LogsList
            search={search}
            invoiceData={invoiceData}
            setValue={setValue}
            totalToPay={invoiceData.totalToPay}
            addPaymentTitle={addPaymentTitle}
            payments={search ? paymentsFound : payments}
            addExpenseTitle={addExpenseTitle}
            expenses={search ? expensesFound : expenses}
            paymentsIsOpen={paymentsIsOpen}
            currentTab={currentTab}
            permissions={permissions}
            handleTabs={handleTabs}
            handleSearch={handleSearch}
            handleToggleForm={handleToggleForm}
            handlePayExpense={handlePayExpense}
            handleEditPayment={handleEditPayment}
            handlePreviewFiles={handlePreviewFiles}
            handleDeletePayment={handleDeletePayment}
          />
          <Box
            component='figure'
            sx={{ my: 5, width: '85%', height: '1px', background: '#ccc', position: 'relative', bottom: '2.5rem' }}
          >
            <Box
              sx={{
                position: 'absolute',
                top: '-1.25rem',
                left: '50%',
                width: '2.5rem',
                height: '2.5rem',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '50%',
                border: 'thin solid #ccc',
                color: '#929292',
                background: '#f4f4f4',
                transform: 'translateX(-50%)',
              }}
            >
              <Box component='i' />
              <AttachMoneyIcon sx={{ fontSize: '1.5rem' }} />
            </Box>
          </Box>
        </Grid>

        <LogsPagination
          page={page}
          PAGE_SIZE={PAGE_SIZE}
          currentTab={currentTab}
          paymentsIsOpen={paymentsIsOpen}
          paymentsData={search ? paymentsFound : paymentsData}
          expensesData={search ? expensesFound : expensesData}
          handlePage={handlePage}
        />

        {permissions.includes('add_payment') && (
          <PaymentForm
            control={control}
            formIsOpen={formIsOpen}
            paymentFile={paymentFile}
            setPaymentFile={setPaymentFile}
            watchLimitAmount={watchLimitAmount}
            watchReceivedAmount={watchReceivedAmount}
            createPayment={createPayment}
            handleSubmit={handleSubmit}
            handleToggleForm={handleToggleForm}
          />
        )}
      </Grid>
    </Grid>
  );
}
