// @Mui
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import MuiCard, { CardProps } from '@mui/material/Card';
import LanguageDropdown from '../../components/Dropdown/LanguageMenu';

// @Components
import { EditorWrapper } from '../../components';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

// @Libraries
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { EditorState } from 'draft-js';
import { convertToHTML } from 'draft-convert';
import toast from 'react-hot-toast';

// @Utils
import { axiosEstimates } from '../../utils/api';
const Card = styled(MuiCard)<CardProps>(({ theme }) => ({
  [theme.breakpoints.up('sm')]: { width: '28rem' },
}));

export function DiscardBudget() {
  const [token, setToken] = useState<string | null>(null);
  const [valid, setValid] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);
  const [status, setStatus] = useState<boolean | null>(null);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [searchParams] = useSearchParams();

  const { t } = useTranslation('common');

  const validateToken = async (token: string) => {
    const toastId = toast.loading(t('toast.loading'));

    setLoading(true);

    try {
      await axiosEstimates.get('/invoice/validate-token', {
        headers: { 'x-invoice-token': token },
      });
      setValid(true);
    } catch (error) {
      setValid(false);
    }

    toast.dismiss(toastId);
    setLoading(false);
  };

  useEffect(() => {
    setToken(searchParams.get('token'));
    setStatus(searchParams.get('reject') === '1');
    validateToken(searchParams.get('token') || '');
  }, []);

  const handleEditorChange = (value: EditorState) => {
    setEditorState(value);
  };

  const onSubmit = async () => {
    const contentState = convertToHTML(editorState.getCurrentContent());

    if (!token) {
      toast.error('Enlance dañado');
      return null;
    }

    if (contentState === '<p></p>') {
      toast.error(t('required.message'));

      return null;
    }

    const toastId = toast.loading('...enviando');

    try {
      const data = {
        comment: contentState,
        reject: status,
      };

      await axiosEstimates.post('invoice/decline', data, { headers: { 'x-invoice-token': token } });
      toast.dismiss(toastId);
      toast.success('La respuestas fue enviada');
      setTimeout(() => {
        window.close();
      }, 500);
    } catch (error) {
      toast.dismiss(toastId);
      toast.error('El enlance expiró');
    }
  };

  if (loading) {
    return <Box />;
  }

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mr: 2 }}>
        <LanguageDropdown />
      </Box>
      <Box
        sx={{
          display: 'flex',
          maxHeight: '100vh',
          alignItems: 'center',
          justifyContent: 'center',
          padding: 2,
        }}
      >
        <Card sx={{ zIndex: 1, minWidth: '60%' }}>
          <CardContent>
            <Box sx={{ mb: 8, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Box component='img' sx={{ width: 120 }} alt='cml' src={'/assets/400x246-CML-Exports.png'} />
            </Box>
            {valid ? (
              <>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    p: 2,
                  }}
                >
                  <Typography variant='h6'>{t('general.budgetDecline', { ns: 'common' })}</Typography>
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    p: 2,
                  }}
                >
                  <EditorWrapper
                    sx={{
                      minHeight: '10rem',
                      maxHeight: '13rem',
                      width: '100%',
                      overflowY: 'auto',
                    }}
                  >
                    <Editor
                      editorState={editorState}
                      onEditorStateChange={handleEditorChange}
                      toolbarClassName='toolbarClassName'
                      wrapperClassName='wrapperClassName'
                      editorClassName='editorClassName'
                      toolbarHidden
                    />
                  </EditorWrapper>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyItems: 'center',
                    justifyContent: 'center',
                    alignItems: 'center',
                    p: 2,
                  }}
                >
                  <Button sx={{ justifyContent: 'center' }} variant='contained' onClick={onSubmit}>
                    {t('general.send', { ns: 'common' })}
                  </Button>
                </Box>
              </>
            ) : (
              <>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    p: 2,
                  }}
                >
                  <Typography variant='h4' color='error'>
                    {t('general.urlExpired', { ns: 'common' })}
                  </Typography>
                </Box>
              </>
            )}
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
}
