// @Library
import { useState } from 'react';

// @Redux
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../redux/store';
import { addService, editService, deleteService } from '../../../redux/slices/budget-form';
import { postBudget } from '../../../redux/slices/budget-form/actions-async';
import { RootState } from '../../../redux/reducers';

// @Models
import { IShipmentForm, IShipment } from '../../../models';

// @Utils
import { sendBudgetAdapter } from './utils/send-budget.adapter';
import { addShipmentAdapter, editShipmentAdapter } from './utils/add-shipment.adapter';

export function useServiceStep() {
  const [renderForm, setRenderForm] = useState(false);
  const [service, setService] = useState<IShipmentForm | undefined>();

  const dispatch = useAppDispatch();

  const { user } = useSelector((state: RootState) => state.user);
  const { shipments, sellerForm, customerForm, status, type } = useSelector((state: RootState) => state.budgetForm);

  const handleRenderForm = (service?: IShipment) => {
    const shipment = editShipmentAdapter(service);
    setService(shipment);
    setRenderForm(prev => !prev);
  };

  const handleAddService = (service: IShipmentForm) => {
    const shipment = addShipmentAdapter(Object.assign(service, { id: shipments.length + 1 }));
    if (shipment) {
      dispatch(addService(shipment));
      setRenderForm(false);
    }
  };

  const handleEditService = (service: IShipmentForm) => {
    const shipment = addShipmentAdapter(service);
    if (shipment) {
      dispatch(editService(shipment));
      setRenderForm(false);
    }
  };

  const handleDeleteService = (index: number) => {
    dispatch(deleteService(index));
  };

  const handleCreateBudget = () => {
    if (!user) return null;

    const adaptedValues = sendBudgetAdapter({
      seller: sellerForm,
      customer: customerForm,
      shipments,
      amountOfSubtotal: shipments.reduce((prev, serv) => prev + serv.subTotal, 0),
      amountOfIva: shipments.reduce((prev, serv) => prev + serv.ivaTotal, 0),
      amountOfTotal: shipments.reduce((prev, serv) => prev + serv.total, 0),
      userId: user.id,
      status,
      type,
    });
    if (adaptedValues) dispatch(postBudget(adaptedValues));
  };

  return {
    services: shipments,
    renderForm,
    handleRenderForm,
    handleAddService,
    handleEditService,
    handleDeleteService,
    subTotal: shipments.reduce((prev, serv) => prev + serv.subTotal, 0),
    ivatotal: shipments.reduce((prev, serv) => prev + serv.ivaTotal, 0),
    total: shipments.reduce((prev, serv) => prev + serv.total, 0),
    handleCreateBudget,
    service,
    budgetType: type,
  };
}
