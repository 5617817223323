// @Utils
import { axiosEstimates } from '../../../utils';

// @Interfaces and Types
import { PaymentType } from '../types';

const editPayment = async (paymentData: PaymentType) => {
  const response = await axiosEstimates.put(`/api/bill-payments/${paymentData.id}`, paymentData);
  const { statusText, status, data } = response;

  if (status === 200) {
    return {
      data: data.data,
      message: data.data.type.toLowerCase() === 'income' ? 'general.paymentEdited' : 'general.expenseEdited',
    };
  } else throw new Error(`${status} - ${statusText && 'Error al editar el pago'} `);
};

export default editPayment;
