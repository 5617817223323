import { axiosEstimates } from '../../../utils/api';
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

// export const handleGetSuppliersId = async (id: any) => {
//   try {
//     const response = await axiosEstimates.get<any>('/supplier-history/' + id);
//     return response.data;
//   } catch (error) {
//     console.error(error);
//   }
// };

export function useHistory() {
  const [history, setHistory] = useState<any>([]);
  const [servsuup, setServsuup] = useState<any>([]);
  const navigate = useNavigate();
  const { id } = useParams();

  const handleGet = async () => {
    try {
      const response = await axiosEstimates.get<any>('/servsupp-history/' + id);
      setHistory(response.data);
    } catch (error) {
      console.error(error);
    }
  };
  const ActualGet = async () => {
    try {
      const response = await axiosEstimates.get<any>('/supplier-historycode/' + id);
      setServsuup(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    handleGet();
    ActualGet();
  }, []);

  return {
    navigate,
    history,
    servsuup,
  };
}
