import { useTranslation } from 'react-i18next';

// @MUI Components
import { Card, Grid, Box, CardHeader, Divider } from '@mui/material/';

// @Hooks
import { useShipmentForm } from './use-shipment-form.hook';

// @Views
import { FormActions } from '../form-actions/form-actions.view';

// @Components
import Row from './components/Row';
import ServiceForm from './components/ServiceForm/';
import TextForm from '../../../components/TextForm';
import ButtonForm from '../../../components/ButtonForm';
import TableComponent from '../../../components/TableContainer';
import ReactSelectForm from '../../../components/ReactSelect';

// @Models
import { IShipmentEdit } from '../../../models';

import i18n from '../../../i18n';

interface IProps {
  shipments?: IShipmentEdit[];
  isUpdate?: boolean;
  handleEdit?: (shipmets: IShipmentEdit[]) => void;
}

export function ShipmentForm({ shipments, isUpdate, handleEdit }: IProps) {
  const {
    control,
    onSubmit,
    countryItems,
    pickups,
    toggle,
    handleRemovePickup,
    handleAddPickup,
    handleEditPickup,
    pickup,
    open,
    conutryItemsES,
  } = useShipmentForm({ shipments, isUpdate, handleEdit });

  const { t } = useTranslation('common');

  return (
    <>
      <ServiceForm
        open={open}
        toggle={toggle}
        handleAddService={handleAddPickup}
        handleEditService={handleEditPickup}
        service={pickup}
      />

      <Card sx={{ padding: '1rem' }}>
        <Box>
          <CardHeader
            title={t('general.details')}
            titleTypographyProps={{ variant: 'h6' }}
            sx={{ display: 'flex', textAlign: 'center' }}
          />

          <Divider sx={{ margin: 0 }} />

          <Grid container spacing={5} sx={{ my: 1 }}>
            <Grid item xs={12} sm={6}>
              <ReactSelectForm
                control={control}
                name='origin'
                label={t('select.origin')}
                items={i18n.language === 'en' ? countryItems : conutryItemsES}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <ReactSelectForm
                control={control}
                name='destiny'
                label={t('select.destiny')}
                items={i18n.language === 'en' ? countryItems : conutryItemsES}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextForm control={control} name='addressOrigin' label={t('shipment.addressOrigin')} />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextForm control={control} name='addressDestiny' label={t('shipment.addressDestiny')} />
            </Grid>
          </Grid>
        </Box>

        <Divider sx={{ my: '1.5rem', width: '100%' }} />

        <Grid container spacing={5}>
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <ButtonForm label={t('add.service')} onClick={() => toggle()} />
          </Grid>

          <Grid item xs={12}>
            <TableComponent
              Row={Row}
              data={pickups}
              headers={[
                t('general.content'),
                t('shipment.unitOfMeasurement'),
                'LXHXW ',
                t('shipment.insurance'),
                t('shipment.physicalWeight'),
                t('shipment.area'),
                t('shipment.volumetricWeight'),
                t('general.actions'),
              ]}
              props={{ handleView: toggle, handleRemoveService: handleRemovePickup }}
            />
          </Grid>
        </Grid>

        <FormActions nextCallback={onSubmit} />
      </Card>
    </>
  );
}
