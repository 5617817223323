import { useState } from 'react';
import { useTranslation } from 'react-i18next';

// @MUI Components
import { Box, Table, TableBody, TableContainer, TableHead, Paper } from '@mui/material';

// @Utils
import ColumHeader from '../../../components/TableColumnHeader';

// @Components
import Row from './Row';
import Pagination from '../../../components/Pagination';

// @Models
import { IDocumentExpense } from '../../../models/expenses/expenses-form.model';
import EmptyData from '../../../components/EmptyData';

interface IProps {
  rows: IDocumentExpense[];
  changeStatus: (id: number, status: number) => void;
  expensesHasDocuments: (id: number) => boolean;
  canEdit?: boolean;
  canAprove?: boolean;
  canCancel?: boolean;
}

export default function ExpensesTable({
  rows,
  changeStatus,
  expensesHasDocuments,
  canEdit,
  canAprove,
  canCancel,
}: IProps) {
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(5);
  const [selectedRow, setSelectedRow] = useState(null);

  const { t } = useTranslation();

  if (!rows) return <EmptyData />;

  return (
    <Box>
      <TableContainer component={Paper} sx={{ height: 500 }}>
        <Table stickyHeader aria-label='collapsible table'>
          <TableHead sx={{ backgroundColor: 'primary.main' }}>
            <ColumHeader
              headers={[
                '',
                t('general.invoice', { ns: 'common' }),
                t('general.customer', { ns: 'common' }),
                t('budgetPage.value', { ns: 'common' }),
                t('general.status', { ns: 'common' }),
              ]}
            />
          </TableHead>

          <TableBody>
            {rows.slice(page * pageSize, (page + 1) * pageSize).map((row, index) => (
              <Row
                key={row.id}
                row={row}
                index={index}
                changeStatus={changeStatus}
                selectedRow={selectedRow}
                setSelectedRow={setSelectedRow}
                expensesHasDocuments={expensesHasDocuments}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Pagination count={rows.length} setPage={setPage} setPageSize={setPageSize} page={page} pageSize={pageSize} />
    </Box>
  );
}
