import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { socket } from '../../../utils/sockets.util';

// ** MUI Imports
import { Grid, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

// ** Store & Actions Imports
import { useSelector } from 'react-redux';
import {
  sendMsg,
  selectChat,
  fetchUserProfile,
  fetchChatsContacts,
  removeSelectedChat,
} from '../../../redux/slices/supplier-chat';
import { useAppDispatch } from '../../../redux/store';

// ** Types
import { RootState } from '../../../redux/reducers';
import { StatusObjType, StatusType } from '../../../models';

// ** Utils Imports
import { getInitials, formatDateToMonthShort } from '../../../utils';

// ** Components
import SidebarLeft from './components/SidebarLeft';
import ChatContent from './components/ChatContent';

const AppChat = () => {
  // ** States
  const [userStatus, setUserStatus] = useState<StatusType>('online');
  const [leftSidebarOpen, setLeftSidebarOpen] = useState<boolean>(false);
  const [userProfileLeftOpen, setUserProfileLeftOpen] = useState<boolean>(false);
  const [userProfileRightOpen, setUserProfileRightOpen] = useState<boolean>(false);

  // ** Hooks
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const hidden = useMediaQuery(theme.breakpoints.down('lg'));

  const store = useSelector((state: RootState) => state.supplierChat);
  const idUser = useSelector((state: RootState) => state.user.idCRM);

  const { id: idBudget } = useParams();

  // ** Vars
  const smAbove = useMediaQuery(theme.breakpoints.up('sm'));
  const sidebarWidth = smAbove ? 370 : 300;
  const mdAbove = useMediaQuery(theme.breakpoints.up('md'));
  // const { skin, appBar, footer, layout, navHidden } = settings;
  const statusObj: StatusObjType = {
    busy: 'error',
    away: 'warning',
    online: 'success',
    offline: 'secondary',
  };

  useEffect(() => {
    if (idBudget) {
      dispatch(fetchUserProfile());
      dispatch(fetchChatsContacts({ idBudget: parseInt(idBudget) }));
    }
  }, []);

  useEffect(() => {
    socket.on('new-message-supplier:success', () => {
      dispatch(fetchUserProfile());
      dispatch(fetchChatsContacts({ idBudget: parseInt(idBudget || '0') }));
    });

    return () => {
      socket.off('new-message-supplier:success');
    };
  }, []);

  const handleLeftSidebarToggle = () => setLeftSidebarOpen(!leftSidebarOpen);
  const handleUserProfileLeftSidebarToggle = () => setUserProfileLeftOpen(!userProfileLeftOpen);
  const handleUserProfileRightSidebarToggle = () => setUserProfileRightOpen(!userProfileRightOpen);

  const calculateAppHeight = () => {
    return `(1px + ${theme.spacing(6)} * 2)`;
  };

  const onSelectChat = (id: number) => selectChat({ id, idBudget: parseInt(idBudget || '0') });

  if (!idUser) {
    return <Box />;
  }

  return (
    <Grid container sx={{ width: '90%', ml: 'auto', mr: { xs: 0.5, md: 'auto' }, zIndex: 1 }}>
      <Grid
        item
        xs={12}
        className='app-chat'
        sx={{
          width: '100%',
          display: 'flex',
          borderRadius: 1,
          overflow: 'hidden',
          position: 'relative',
          backgroundColor: 'background.paper',
          boxShadow: 6,
          height: `calc(100vh - ${calculateAppHeight()})`,
          border: `1px solid ${theme.palette.divider}`,
        }}
      >
        <SidebarLeft
          store={store}
          hidden={hidden}
          mdAbove={mdAbove}
          dispatch={dispatch}
          statusObj={statusObj}
          userStatus={userStatus}
          selectChat={onSelectChat}
          getInitials={getInitials}
          sidebarWidth={sidebarWidth}
          setUserStatus={setUserStatus}
          leftSidebarOpen={leftSidebarOpen}
          removeSelectedChat={removeSelectedChat}
          userProfileLeftOpen={userProfileLeftOpen}
          formatDateToMonthShort={formatDateToMonthShort}
          handleLeftSidebarToggle={handleLeftSidebarToggle}
          handleUserProfileLeftSidebarToggle={handleUserProfileLeftSidebarToggle}
        />
        <ChatContent
          idUser={idUser}
          idBudget={parseInt(idBudget || '0')}
          store={store}
          hidden={hidden}
          sendMsg={sendMsg}
          mdAbove={mdAbove}
          dispatch={dispatch}
          statusObj={statusObj}
          getInitials={getInitials}
          sidebarWidth={sidebarWidth}
          userProfileRightOpen={userProfileRightOpen}
          handleLeftSidebarToggle={handleLeftSidebarToggle}
          handleUserProfileRightSidebarToggle={handleUserProfileRightSidebarToggle}
        />
      </Grid>
    </Grid>
  );
};

export default AppChat;
