const DataGridStyles: any = {
  '& .gridHeader': {
    backgroundColor: 'primary.main',
    color: '#fff',
  },
  '& .MuiDataGrid-columnHeaderTitleContainer': {
    backgroundColor: 'primary.main',
  },
  '& .MuiDataGrid-columnHeaderTitleContainer .Mui-checked': {
    color: 'white !important',
  },
  '& .css-10mb48g-MuiDataGrid-columnHeaders': {
    backgroundColor: 'primary.main',
  },
  '& .css-10mb48g-MuiDataGrid-columnHeaders .Mui-checked': {
    color: 'white !important',
  },
  '& .css-1jbbcbn-MuiDataGrid-columnHeaderTitle': {
    fontWeight: 700,
  },
  '& .Mui-checked': {
    color: '#fff',
  },
  '& .MuiDataGrid-columnHeader': {
    backgroundColor: '#16B1FF',
    color: '#fff',
  },
  maxHeight: 400,
};

export default DataGridStyles;
