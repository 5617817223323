// @Libraries
import { useFieldArray, useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
import { DOCUMENT_TYPE_OBJ } from '../../utils';

// @Redux
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/reducers';

// @Models
import { ManifiestoMaritimo } from '../../../../models';

// @Hooks
import { useDocument } from '../../hooks';

// @Utils
import { axiosEstimates } from '../../../../utils';
import { initialValues, schemaValidation } from './utils/validationSchema';

const useManifiestoMaritimo = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [counter, setCounter] = useState('');
  const { budget, documents, handleNavigatePrint, handleFormChange, handleFormChange2 } = useDocument();
  const [currentData, setCurrentData] = useState<object | null>(null);
  const [documentId, setDocumentId] = useState<number | null>(null);
  const { id } = useParams<{ id: string }>();
  const maxLength = 255;

  function handleChange(event: { target: { value: React.SetStateAction<string> } }) {
    setCounter(event.target.value);
  }

  const { control, handleSubmit, reset, watch, getValues } = useForm<ManifiestoMaritimo>({
    defaultValues: initialValues,
    resolver: yupResolver(schemaValidation),
    reValidateMode: 'onChange',
  });

  useEffect(() => {
    getValues();
    const current = JSON.stringify(currentData);
    const updated = JSON.stringify(getValues());
    if (current !== updated) {
      handleFormChange();
    } else {
      handleFormChange2();
    }
  }, [watch()]);

  const {
    countries: { countryItems },
  } = useSelector((state: RootState) => state);

  const {
    fields: contentFields,
    append: contentAppend,
    remove: contentRemove,
  } = useFieldArray({ control, name: 'content' });

  if (contentFields.length === 0) {
    contentAppend({
      cnt: '',
      bl: '',
      provider: '',
      totalPackages: null,
      weight: null,
      volumen: null,
      invoices: '',
      contentDescription: '',
    });
  }

  useEffect(() => {
    setCurrentData(getValues());
    handleData();
  }, [budget]);

  const handleData = async () => {
    const data = budget;
    if (data) {
      const document = documents.find(
        document => document.document_types.name === DOCUMENT_TYPE_OBJ.MANIFIESTO_MARITIMO,
      );
      if (!document) {
        const dataManifiesto = {
          expeditionDate: new Date().toLocaleDateString(),
          invoiceoperation: data.budget.code,
          shipper: 0, // Supplier select
          nameshipper: 'CML EXPORTS AND MOVING SOLUTIONS INC',
          addressshipper: '112 Bartram Oaks Walk #600877 Jacksonville, FL 32259 US',
          mailshipper: 'operations@cmlexports.com',
          phoneshipper: '(904) 295 4450',
          consignee: data.customer.name,
          addressconsignee: data.customer.address,
          mailconsignee: data.customer.email,
          phoneconsignee: data.customer.phone,
        };
        reset(dataManifiesto);
        setDocumentId(null);
      } else {
        const dataManifiesto: ManifiestoMaritimo = {
          expeditionDate: document.expeditionDate || '',
          numberID: document.reference || '',
          agent: document.agent || '',
          carrier: document.conveyors[0]?.name || '',
          content: document.documentContent.map(content => ({
            cnt: content.number || '',
            contentDescription: content.description || '',
            provider: content.recipient || '',
            weight: Number(content.grossWeight) || null,
            volumen: Number(content.volume) || null,
            bl: content.bl || '',
            invoices: content.hawb || '',
            totalPackages: Number(content.total) || null,
          })),
          shipmentPort: document.loadingPort || '',
          arrivalPort: document.dischargePort || '',
          depeartureDate: document.exit || '',
          arrivalDate: document.arrival || '',
          trip: document.journey || '',
          entry: document.pilotEntry || '',
          destiny: document.pilotFinalDestination || '',
          consignee: data.customer.name || '',
          contentDescription: document.comments || '',
        };
        reset(dataManifiesto);
        setDocumentId(document.id);
      }
    }
  };

  const onSubmit = async (data: ManifiestoMaritimo) => {
    try {
      const content = data.content.map(content => ({
        number: `${content.cnt}`,
        bl: `${content.bl}`,
        recipient: content.provider,
        total: `${content.totalPackages}`,
        grossWeight: `${content.weight}`,
        volume: content.volumen,
        description: content.contentDescription,
        hawb: content.invoices,
      }));

      const manifestData = {
        budgetId: parseInt(id || '0'),
        expeditionDate: data.expeditionDate,
        strategy: DOCUMENT_TYPE_OBJ.MANIFIESTO_MARITIMO,
        reference: data.numberID,
        agent: data.agent,
        loadingPort: data.shipmentPort,
        dischargePort: data.arrivalPort,
        exit: data.depeartureDate,
        arrival: data.arrivalDate,
        pilotEntry: data.entry,
        pilotFinalDestination: data.destiny,
        journey: data.trip,
        consignee: data.consignee,
        comments: data.contentDescription,
        conveyors: {
          name: data.carrier,
        },
        documentContent: content,
      };
      let document: any = null;

      if (!documentId) {
        const axiosResponse = await axiosEstimates.post('/api/document', manifestData);
        document = axiosResponse.data.data;
      } else {
        const axiosResponse = await axiosEstimates.put('/api/document', { ...manifestData, id: documentId });
        document = axiosResponse.data.data;
      }
      if (document) {
        handleNavigatePrint(document.id, location.pathname + '&value=5');
        Swal.fire({
          icon: 'success',
          title: t('alert.success', { ns: 'common' }) || '',
          text: t('alert.supplier.success', { ns: 'common' }) || '',
          showCloseButton: true,
        });
      }
    } catch (err) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: t('alert.somethingerror', { ns: 'common' }) || '',
        showCloseButton: true,
      });
    }
  };

  return {
    t,
    control,
    countryItems,
    contentFields,
    contentAppend,
    contentRemove,
    counter,
    maxLength,
    handleChange,
    documentId,
    navigate: handleNavigatePrint,
    // eslint-disable-next-line no-console
    onSubmit: handleSubmit(onSubmit),
    Controller,
  };
};

export default useManifiestoMaritimo;
