import * as React from 'react';
import { useTranslation } from 'react-i18next';

// @MUI Components
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { IconButton } from '@mui/material';
import { PencilOutline } from 'mdi-material-ui';

// @Hooks
import { useServices } from '../hooks/useServices';

// @Interfaces and Types
import { Service } from '../def';

export default function EditBtn({ row }: { row: Service }) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const { t } = useTranslation(['common']);

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { handleEdit, handleToggleTypeForm, handleToggleCategoryForm, setDefaultServiceTypeValues } = useServices();

  return (
    <div>
      <IconButton
        id='demo-positioned-button'
        aria-controls={open ? 'demo-positioned-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{ p: 0 }}
      >
        <PencilOutline fontSize='medium' color='primary' />
      </IconButton>

      <Menu
        id='demo-positioned-menu'
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        aria-labelledby='demo-positioned-button'
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        <MenuItem
          onClick={() => {
            handleClose();
            handleEdit(row.id, {
              categoryId: row.type.categories.map(c => c.id),
              serviceType: row.type.id,
              serviceName: row.name,
              serviceNameEN: row.nameEN,
            });
          }}
        >
          {t('services.table.service')}
        </MenuItem>

        <MenuItem
          onClick={() => {
            handleClose();
            handleToggleTypeForm();
            setDefaultServiceTypeValues({
              id: row.type.id,
              name: row.type.name,
              nameEN: row.type.nameEN,
              categories: row.type.categories.map(c => c.id),
            });
          }}
        >
          {t('services.table.typeService')}
        </MenuItem>

        <MenuItem
          onClick={() => {
            handleClose();
            handleToggleCategoryForm(row.type.categories);
          }}
        >
          {t('services.table.category')}
        </MenuItem>
      </Menu>
    </div>
  );
}
