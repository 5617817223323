import toast from 'react-hot-toast';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

// @MUI Components
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import LanguageDropdown from '../../components/Dropdown/LanguageMenu';

// @Utils
import { axiosEstimates } from '../../utils/api';

// @Components
import Loader from '../../components/Loader';

/**
 * @component
 * @subcategory Pages
 *
 * @see Route: "https://estimates.cmlgroupapps.com/active"
 *
 * @description
 * ActiveBudget component is responsible for validating an invoice token and activating a budget.
 *
 * #### Example
 * ```jsx
 * return(
 * <ActiveBudget />
 * )
 * ```
 *
 * @return {JSX.Element} A JSX element representing the ActiveBudget component
 * @author CML Exports Front-End Developers
 */
export default function ActiveBudget() {
  const [valid, setValid] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);
  const [token, setToken] = useState<string | null>(null);
  const [idBudget, setIdBudget] = useState<string | null>(null);

  const [searchParams] = useSearchParams();

  const { t } = useTranslation('common');

  const validateToken = async (token: string) => {
    const toastId = toast.loading(t('toast.loading'));

    setLoading(true);

    try {
      const response = await axiosEstimates.get<{ data: { idBudget: number } }>('/invoice/validate-token', {
        headers: { 'x-invoice-token': token },
      });
      setIdBudget(response.data.data.idBudget.toString());
      setValid(true);
    } catch (error) {
      setValid(false);
    }

    toast.dismiss(toastId);
    setLoading(false);
  };

  const onSubmit = async () => {
    if (!token) {
      toast.error('Enlance dañado');
      return null;
    }
    const toastId = toast.loading('...enviando');
    try {
      await axiosEstimates.post('/budget/active/' + idBudget, { headers: { 'x-invoice-token': token } });
      toast.dismiss(toastId);
      toast.success('La respuestas fue enviada');
      setTimeout(() => {
        window.close();
      }, 2000);
    } catch (error) {
      toast.dismiss(toastId);
      toast.error('El enlance expiró');
    }
  };

  useEffect(() => {
    setToken(searchParams.get('token'));
    validateToken(searchParams.get('token') || '');

    if (idBudget && token) onSubmit();
  }, [idBudget]);

  if (loading) return <Loader />;

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mr: 2 }}>
        <LanguageDropdown />
      </Box>

      <Box sx={{ display: 'flex', maxHeight: '100vh', alignItems: 'center', justifyContent: 'center', padding: 2 }}>
        <Card sx={{ width: { sm: '28rem' }, zIndex: 1, minWidth: '60%' }}>
          <CardContent>
            <Box sx={{ mb: 8, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Box component='img' sx={{ width: 120 }} alt='cml' src={'/assets/400x246-CML-Exports.png'} />
            </Box>

            {valid ? (
              <>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', p: 2 }} />
              </>
            ) : (
              <>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', p: 2 }}>
                  <Typography variant='h4' color='error'>
                    {t('general.urlExpired')}
                  </Typography>
                </Box>
              </>
            )}
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
}
