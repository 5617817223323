import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

// @Mui
import { Button, Grid, Card, CardHeader, CardContent, Divider } from '@mui/material';

// @Hooks
import { useBudgetPrint } from './use-budget-print.hook';

export function BudgetPrint() {
  const { budget } = useBudgetPrint();

  const { id } = useParams();

  const [params] = useSearchParams();
  const v2 = params.get('previous') || '';
  const ismakeBudget = params.get('makeBudget') || '';

  const { t, i18n } = useTranslation('common');

  const src = `${process.env.REACT_APP_ESTIMATES_API}pdf/download/budget${v2}?id=${id}&language=${i18n.language}&makeBudget=${ismakeBudget}`;
  console.log(src);

  const redirect = () => {
    if (budget?.budget.type === 1) return '/budgets';
    if (budget?.budget.type === 2) return '/ctis';
    return '/budgets-from-us';
  };

  const navigate = useNavigate();

  return (
    <Grid container sx={{ width: '90%', ml: 'auto', mr: { xs: 0.5, md: 'auto' }, zIndex: 1 }}>
      <Grid item xs={12} sx={{ mx: 'auto' }}>
        <Card sx={{ pb: 2.5, width: '100%', height: '145vh', overflowY: 'scroll' }}>
          <CardHeader
            titleTypographyProps={{ variant: 'h6' }}
            sx={{ display: 'flex', textAlign: 'center' }}
            title={`${budget?.budget.code} - ${budget?.customer.name}`}
          />

          <Divider sx={{ margin: 0 }} />

          <CardContent sx={{ height: '90%' }}>
            <iframe src={src} style={{ display: 'block', width: '100%', height: '100%' }} />
          </CardContent>

          <Grid
            item
            xs={12}
            sx={{
              p: 2,
              display: 'flex',
              justifyItems: 'center',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Button variant='contained' onClick={() => navigate(redirect())}>
              {t('general.close')}
            </Button>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
}
