import { useState } from 'react';
import ReactSelect from 'react-select';
import { useTranslation } from 'react-i18next';
import { Controller, Control, FieldValues, FieldPath } from 'react-hook-form';

// @MUI Components
import InputLabel from '@mui/material/InputLabel';
import { SelectProps } from '@mui/material/Select';
import Skeleton from '@mui/material/Skeleton';
import FormHelperText from '@mui/material/FormHelperText';
import { Box } from '@mui/material';

// @Interfaces and types
import { ISelectInput } from '../models';
interface IProps<T extends FieldValues> extends SelectProps {
  name: FieldPath<T>;
  control: Control<T, any>;
  label: string;
  translationFile?: 'common' | 'placeholder' | 'validations';
  items: ISelectInput<string | number | null>[];
  loading?: boolean;
  rules?: any;
  onExternalChange?: (value: any) => void;
}

/**
 * @component
 * @subcategory Global
 *
 * @description
 * Custom select component using Material-UI and React Hook Form. It allows to select an option from a list and it's integrated with react-i18next for translations.
 *
 * #### Example
 * ```jsx
 * return(
 * <ReactSelectForm
 *   name="example"
 *   control={control}
 *   label="Example"
 *   items={[
 *     { label: 'Option 1', value: 1 },
 *     { label: 'Option 2', value: 2 },
 *     { label: 'Option 3', value: 3 },
 *   ]}
 *   onExternalChange={(value) => console.log(value)}
 * />
 * )
 * ```
 *
 * @property {FieldPath<T>} name The name of the field in the form.
 * @property {Control<T, any>} control The control object from React Hook Form.
 * @property {string} label The label of the select.
 * @property {ISelectInput<string | number | null>[]} items The list of options to select.
 * @property {boolean} [loading] A flag to indicate if the select is loading.
 * @property {any} [rules] (Without use).
 * @property {'common' | 'placeholder' | 'validations'} [translationFile] The translation file to use.
 * @property {function} [onExternalChange] A callback function to be called when the value changes.
 * @property {boolean} disabled A flag to indicate if the select is disabled.
 *
 * @return {JSX.Element} The custom select component.
 * @author CML Exports Front-End Developers
 */
export default function ReactSelectForm<T extends FieldValues>({
  name,
  control,
  label,
  items,
  loading,
  translationFile = 'common',
  onExternalChange,
  disabled,
}: IProps<T>) {
  const { t } = useTranslation(translationFile);

  const [isFocused, setIsFocused] = useState(false);

  return (
    <>
      {!loading ? (
        <Box sx={{ position: 'relative' }}>
          <Controller
            name={name}
            control={control}
            render={({ field: { value, onChange, onBlur, ref }, fieldState: { error } }) => (
              <>
                <ReactSelect
                  isDisabled={disabled}
                  placeholder={`${t(label)}`}
                  value={items?.find(item => item.value === value) || null}
                  onChange={option => {
                    onChange(option?.value);
                    setIsFocused(true);

                    if (onExternalChange) onExternalChange(option?.value);
                  }}
                  styles={{
                    placeholder: (provided, state) => ({
                      ...provided,
                      display: state.isFocused ? 'block' : 'none',
                      transition: 'all 0.3s ease-out',
                    }),
                    control: provided => ({
                      ...provided,
                      width: '100%',
                      height: '56px',
                      borderRadius: '4px',
                      borderColor: error ? '#f44336' : isFocused ? '#16B1FF' : '#BDBDBD',
                      borderWidth: isFocused ? '2px' : '1px',
                      boxShadow: 'none',
                      '&:hover': {
                        borderColor: error ? '#f44336' : isFocused ? '#16B1FF' : '#000000',
                        borderWidth: isFocused ? '2px' : '1px',
                      },
                    }),
                    indicatorSeparator: provided => ({
                      ...provided,
                      display: 'none',
                    }),
                    dropdownIndicator: provided => ({
                      ...provided,
                      color: error ? '#f44336' : '#BDBDBD',
                    }),
                    menu: provided => ({
                      ...provided,
                      marginTop: '8px',
                      borderRadius: '4px',
                      zIndex: 9999,
                      border: '1px solid #BDBDBD',
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isFocused ? '#F5F5F5' : 'inherit',
                      color: state.isFocused ? '#000000' : 'inherit',
                      cursor: 'pointer',
                      '&:active': {
                        backgroundColor: '#F5F5F5',
                        color: '#000000',
                      },
                    }),
                  }}
                  options={items}
                  onBlur={() => {
                    setIsFocused(false);
                    if (value === null) onBlur();
                  }}
                  onFocus={() => setIsFocused(true)}
                  ref={ref}
                />
                <InputLabel
                  htmlFor={name}
                  className={value != null || isFocused ? 'ReactSelect__value-container--has-value' : ''}
                  sx={{
                    py: 0,
                    px: 1,
                    top: '12px',
                    left: '4px',
                    position: 'absolute',
                    fontSize: '16px',
                    backgroundColor: '#fff',
                    pointerEvents: 'none',
                    lineHeight: value != null ? '20px' : '30px',
                    color: error ? '#f44336' : isFocused ? '#16B1FF' : '#6B6B6B',
                    transition: 'all 0.2s ease-out',
                    '&.ReactSelect__value-container--has-value': {
                      transform: 'translateY(-20px) translateX(6px) scale(0.8)',
                    },
                  }}
                >
                  {label}
                </InputLabel>
                {error?.message && (
                  <FormHelperText sx={{ pl: 2, color: 'error.main' }}>{t(`${error?.message || ''}`)}</FormHelperText>
                )}
              </>
            )}
          />
        </Box>
      ) : (
        <Skeleton variant='rectangular' animation='wave' height={60} />
      )}
    </>
  );
}
