import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

export function useInvoiceSettings() {
  const [value, setvalue] = useState<number>(1);

  const { t } = useTranslation(['common']);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setvalue(newValue);
  };

  useEffect(() => {
    if (value) {
      handleChange({} as any, value);
    }
  }, [value]);

  return {
    t,
    handleChange,
    value,
  };
}
