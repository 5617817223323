import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

// ** MUI Imports
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Download from 'mdi-material-ui/Download';
import FileDocumentOutline from 'mdi-material-ui/FileDocumentOutline';

interface IFiles {
  filename: string;
  url: string;
}

interface IProps {
  files: IFiles[];
}

/**
 * @component
 * @subcategory Global
 *
 * @description
 * A component that renders a list of files with their names and a download button for each one.
 * #### Example
 * ```
 * return(
 *  <FileDownloader files={files} />
 * )
 * ```
 *
 * @property {IFiles[]} files An array of file objects, where each object contains a filename and a url.
 *
 * @return {JSX.Element}
 * @author CML Exports Front-End Developers
 */
const FileDownloader = ({ files }: IProps) => {
  const renderFilePreview = (file: IFiles) => {
    //   // const extensions = ['png', 'jpg', 'jpeg'];
    //   // const extension = file?.filename.split('.')[1];

    //   /* if (extensions.includes(extension)) {
    //     return (
    //       <CustomAvatar
    //         alt='User Image'
    //         src={file.url}
    //         variant='rounded'
    //         sx={{ width: 40, height: 40, margin: '0 auto' }}
    //       />
    //     );
    //   } else {
    //   } */
    return <FileDocumentOutline />;
  };

  const { i18n } = useTranslation();

  const fileList = files?.map((file, index) => (
    <Grid item xs={12} sm={12} md={12} key={index}>
      <Box sx={{ display: 'flex', justifyContent: 'left' }}>
        {renderFilePreview(file)}
        <Typography className='file-name' sx={{ ml: 2, mr: 2 }} noWrap>
          {file?.filename}
        </Typography>
        <IconButton
          sx={{ top: -5 }}
          onClick={() => {
            const w = window.open(file?.url, '_blank');
            w && w.focus();
          }}
        >
          <Download fontSize='small' />
        </IconButton>
      </Box>
    </Grid>
  ));

  return (
    <Fragment>
      {files?.length > 0 && (
        <>
          <Typography variant='h6' noWrap>
            {i18n.language === 'es' ? 'Archivos guardados' : 'Saved files'}
          </Typography>

          <Grid container spacing={3} sx={{ border: '1px solid #BDBDBD', borderRadius: '0.5rem', margin: '1rem auto' }}>
            {fileList}
          </Grid>
        </>
      )}
    </Fragment>
  );
};

export default FileDownloader;
