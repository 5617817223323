// import { Controller, useFormContext } from 'react-hook-form';
import { Controller, Control, FieldValues, FieldPath } from 'react-hook-form';

// ** MUI Imports
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

interface IProps<T extends FieldValues> extends CheckboxProps {
  name: FieldPath<T>;
  label: string;
  control: Control<T, any>;
  onExternalChange?: (value: unknown) => void;
}

/**
 * @component
 * @subcategory Global
 *
 * @description
 * A customizable input component with error handling and label.
 * #### Example
 * <CheckboxForm
 *   label="Label name"
 *   name="name-checkout"
 *   control={control}
 * />
 *
 * @property {string} name The name of the checkbox field.
 * @property {string} label The label of the checkbox field.
 * @property {string} [size='small'] The size of the checkbox field.
 * @property {Control} control The control object from react-hook-form.
 * @property {function} [onExternalChange] The variant of the checkbox field.
 * @property {object} props Another props.
 *
 * @returns {JSX.Element} A Custom Checkbox component.
 * @author CML Exports Front-End Developers
 */
export function CheckboxForm<T extends FieldValues>({
  name,
  label,
  size = 'small',
  control,
  onExternalChange,
  ...props
}: IProps<T>) {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, onChange, ...field } }) => (
        <FormControlLabel
          control={
            <Checkbox
              size={size}
              checked={value}
              onChange={event => {
                if (onExternalChange) {
                  onExternalChange(event.target?.value || event);
                } else {
                  onChange(event);
                }
              }}
              {...field}
              {...props}
            />
          }
          label={label}
        />
      )}
    />
  );
}
