// @MUI Components
import { SxProps, Table, TableBody, TableContainer, TableHead } from '@mui/material';

// @Components
import CustomTableRow from './TableCustomRow';
import ColumHeader from './TableColumnHeader';

// @Interfaces
interface IProps {
  Row: any;
  data: any[];
  props?: any;
  headers: any[];
  sx?: SxProps;
  autoHeight?: boolean;
}

/**
 * @component
 * @subcategory Global
 *
 * @description
 * A reusable table component for displaying data in a table format.
 * The component accepts various props to customize its behavior and appearance.
 *
 * #### Example
 * ```tsx
 * return(
 * <TableComponent
 *   Row={CustomTableRow}
 *   data={[{ id: 1, name: 'John' }, { id: 2, name: 'Jane' }]}
 *   headers={['ID', 'Name']}
 *   props={{ foo: 'bar' }}
 *   sx={{ maxHeight: 500 }}
 *   autoHeight={true}
 * />
 * )
 * ```
 *
 * @property {React.ComponentType<any>} Row The row component to use for each data item.
 * @property {any[]} data The data to display in the table.
 * @property {any[]} headers The headers to display in the table.
 * @property {SxProps} [sx] Additional styles for the table container.
 * @property {boolean} [autoHeight] Whether to set the table height to auto.
 * @property {any} [props] Additional props to pass to the row component.
 *
 * @return {JSX.Element} The TableComponent.
 * @author CML Exports Front-End Developers
 */
const TableComponent = ({ Row, data, headers, props, sx = {}, autoHeight }: IProps) => {
  return (
    <TableContainer sx={{ height: autoHeight ? 'auto' : 500, boxShadow: 'none', backgroundColor: 'white', ...sx }}>
      <Table stickyHeader aria-label='sticky table'>
        <TableHead>
          <ColumHeader headers={headers} />
        </TableHead>

        <TableBody sx={{ boxShadow: 'none', backgroundColor: 'white' }}>
          {data?.length > 0 ? (
            data.map(row => <Row key={row.id} index={row.id} row={row} {...props} />)
          ) : (
            <CustomTableRow headers={headers} />
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableComponent;
