import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';

// @Mui
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import { Tab, Tabs } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import KeyboardBackspace from '@mui/icons-material/KeyboardBackspace';

import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';

// @Models
import { Document } from '../Fomularios Temporales Guias/models';
import { getDocumentsByBudget } from '../Fomularios Temporales Guias/services';
import { DOCUMENTS_ORDERED, DOCUMENT_TYPE_OBJ } from '../Fomularios Temporales Guias/utils';

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export function DocumentPrint() {
  // NOTE: DATA
  const [documentData, setDocumentData] = useState<Document | null>(null);
  const [documentTitle, setDocumentTitle] = useState<string | null>(null);
  const [searchParams] = useSearchParams();
  // NOTE: TABS DATA
  const valuePrint = Number(searchParams.get('value')) || null;
  const [tab, setTab] = useState(valuePrint || 0);
  const [hasDocument, setHasDocument] = useState<any>({});

  const { id } = useParams();

  const { t } = useTranslation('common');

  const navigate = useNavigate();

  const [src, setSrc] = useState(
    `${process.env.REACT_APP_ESTIMATES_API}generate-pdf?documentId=${id}&strategy=by-document-id`,
  );

  const getDocumentData = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_ESTIMATES_API}api/document/${searchParams.get('budgetId')}`,
      );

      if (!response.ok) throw new Error(`HTTP error! status:${response.status}`);

      const { data } = await response.json();

      const [document] = data.filter((value: { id: string | number }) => String(value.id) === id && value);

      setDocumentData(document);
    } catch (err) {
      console.error(err);
    }
  };

  const getAnotherDocuments = async () => {
    const budgetID = searchParams.get('budgetId');

    if (budgetID) {
      const data = await getDocumentsByBudget(budgetID);

      if (data && data.length) {
        const documentsFounded: any = {};

        DOCUMENTS_ORDERED.forEach((document, i) => {
          const documentFounded = data.find(el => {
            return document === el.document_types.name;
          });

          if (documentFounded && valuePrint === null) {
            setTab(i);
            documentsFounded[document] = documentFounded.id;
          }
        });

        setHasDocument(documentsFounded);
      }
    }
  };

  const handleChangeTab = (_: any, newTab: number) => setTab(newTab);

  useEffect(() => {
    getDocumentData();
    getAnotherDocuments();
  }, []);

  useEffect(() => {
    if (documentData) {
      setDocumentTitle(
        `${documentData?.budget?.code} - ${
          documentData?.consigneePerson?.name || documentData?.consignedToPerson?.name || documentData?.consignee
        }`,
      );
    }
  }, [documentData]);

  useEffect(() => {
    setSrc(
      `${process.env.REACT_APP_ESTIMATES_API}generate-pdf?documentId=${
        hasDocument[DOCUMENTS_ORDERED[tab]] || id
      }&strategy=by-document-id`,
    );
  }, [tab]);

  return (
    <Grid container sx={{ width: '90%', ml: 'auto', mr: { xs: 0.5, md: 'auto' }, zIndex: 1 }}>
      <Tabs
        value={tab}
        onChange={handleChangeTab}
        aria-label='basic tabs example'
        variant='scrollable'
        scrollButtons
        allowScrollButtonsMobile
      >
        <Tab
          {...a11yProps(1)}
          label={t('budgetGuide.firstTabFirstSubtittle')}
          sx={{ display: hasDocument[DOCUMENT_TYPE_OBJ.CARTA_PORTE] ? 'block' : 'none' }}
        />
        <Tab
          {...a11yProps(2)}
          label={t('budgetGuide.firstTabSecondSubtittle')}
          sx={{ display: hasDocument[DOCUMENT_TYPE_OBJ.MANIFIESTO] ? 'block' : 'none' }}
        />
        <Tab
          {...a11yProps(3)}
          label={t('budgetGuide.secondTabFirstSubtittle')}
          sx={{ display: hasDocument[DOCUMENT_TYPE_OBJ.BOOKING] ? 'block' : 'none' }}
        />
        <Tab
          {...a11yProps(4)}
          label={t('budgetGuide.secondTabSecondSubtittle')}
          sx={{ display: hasDocument[DOCUMENT_TYPE_OBJ.BL] ? 'block' : 'none' }}
        />
        <Tab
          {...a11yProps(5)}
          label={t('budgetGuide.secondTabThirdSubtittle')}
          sx={{ display: hasDocument[DOCUMENT_TYPE_OBJ.MANIFIESTO_MARITIMO] ? 'block' : 'none' }}
        />
        <Tab
          {...a11yProps(6)}
          label={t('budgetGuide.thirdTabFirstSubtittle')}
          sx={{ display: hasDocument[DOCUMENT_TYPE_OBJ.AIR_GUIDE] ? 'block' : 'none' }}
        />
        <Tab
          {...a11yProps(7)}
          label={t('budgetGuide.thirdTabSecondSubtittle')}
          sx={{ display: hasDocument[DOCUMENT_TYPE_OBJ.GA_RESERVAS] ? 'block' : 'none' }}
        />
        <Tab
          {...a11yProps(8)}
          label={t('budgetGuide.thirdTabThirdSubtittle')}
          sx={{ display: hasDocument[DOCUMENT_TYPE_OBJ.GA_MANIFIESTO] ? 'block' : 'none' }}
        />
      </Tabs>

      <Grid item xs={12} sx={{ mx: 'auto' }}>
        <Card sx={{ height: '95vh' }}>
          <IconButton
            color='error'
            sx={{ display: 'flex', mt: 1 }}
            onClick={() => navigate(searchParams.get('backUrl') as string)}
          >
            <KeyboardBackspace />
          </IconButton>

          <CardHeader
            title={documentTitle}
            titleTypographyProps={{ variant: 'h6' }}
            sx={{ display: 'flex', textAlign: 'center', textTransform: 'uppercase' }}
          />

          <Divider sx={{ margin: 0 }} />

          <CardContent sx={{ height: '90%' }}>
            {src && <iframe src={src} style={{ display: 'block', width: '100%', height: '100%' }} />}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
