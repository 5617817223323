import { useTranslation } from 'react-i18next';

// @Components
import Row from './Row';
import TableComponent from '../../../../components/TableContainer';

// @Interfaces and Types
interface IProps {
  suppliersData: any[];
  page: number;
  pageSize: number;
  handleEdit: (id: any) => void;
  onDelete: (id: any) => void;
  onStatus: (id: any, supplierStatus: any) => void;
  toogleNotes: (notes?: string) => void;
  can?: any;
  toggleEditServices: (id: number) => void;
}

export default function SuppliersTable({
  suppliersData,
  page,
  pageSize,
  handleEdit,
  onDelete,
  onStatus,
  toogleNotes,
  can,
  toggleEditServices,
}: IProps) {
  const { t } = useTranslation('common');

  return (
    <TableComponent
      Row={Row}
      headers={['ID', t('general.name'), t('general.actions')]}
      data={suppliersData.slice(page * pageSize, (page + 1) * pageSize)}
      props={{ handleEdit, onDelete, onStatus, toogleNotes, can, toggleEditServices }}
    />
  );
}
