/* eslint-disable indent */
import { useTranslation } from 'react-i18next';

// @Mui
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { Box } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

// @Hooks
import { useCustomerInteraction } from '../hooks';

// @Components
import { ColorlibStepIcon } from '../components';

export const StepperView = () => {
  const { steps, currentStep } = useCustomerInteraction();

  const matches = useMediaQuery('(min-width:600px)');

  const { t } = useTranslation();

  return (
    <Box sx={{ width: '100%' }}>
      {/* {stepsDisplay} */}
      {/* Se le resta 1 al currentStep debido a que antes la logica estaba para tailwind */}
      <Stepper activeStep={currentStep - 1} alternativeLabel>
        {steps.map((step, index) => (
          <Step key={index}>
            {matches ? (
              <StepLabel>
                {step.id === 1
                  ? t('customerInteraction.steps.services', { ns: 'common' })
                  : t('customerInteraction.steps.shipmentDetails', { ns: 'common' })}
              </StepLabel>
            ) : (
              <StepLabel StepIconComponent={ColorlibStepIcon}>{step.id}</StepLabel>
            )}
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};
