import { Card, Grid } from '@mui/material';

import { GuidesContainer } from '../Fomularios Temporales Guias/container';
interface BudgetGuidesProps {
  children?: React.ReactNode;
}
const BudgetGuides: React.FC<BudgetGuidesProps> = ({ children }) => {
  return (
    <Grid container sx={{ width: '90%', ml: 'auto', mr: { xs: 0.2, md: 'auto' }, zIndex: 1 }}>
      <Card sx={{ width: '100%', height: '100%' }}>
        <GuidesContainer>{children}</GuidesContainer>
      </Card>
    </Grid>
  );
};

export default BudgetGuides;
