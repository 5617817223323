import { TextForm, DatePicker } from '../../../components';
import { Button, Grid } from '@mui/material';

export const FormInvoice = ({ control, t, isFirst, handleSubmit, handleShow }: any) => {
  return (
    <form>
      <Grid container spacing={4} sx={{ mt: 1 }}>
        <Grid item xs={6} sm={2.5}>
          <TextForm name='minFolio' control={control} label={t('general.min')} disabled={!isFirst} />
        </Grid>

        <Grid item xs={6} sm={2.5}>
          <TextForm name='maxFolio' control={control} label={t('general.max')} />
        </Grid>

        <Grid item xs={6} sm={3}>
          <DatePicker control={control} label={t('general.authorizationDate')} name='authorizationDate' />
        </Grid>

        <Grid item xs={6} sm={4}>
          <TextForm name='authorizationNumber' control={control} label={t('general.authorization')} />
        </Grid>

        <Grid item xs={12} sx={{ display: 'flex', justifyContent: { xs: 'center', sm: 'flex-start' }, gap: 4 }}>
          <Button type='button' variant='contained' color='error' onClick={() => handleShow(false)}>
            {t('general.cancel', { ns: 'common' })}
          </Button>
          <Button type='submit' variant='contained' color='info' onClick={handleSubmit}>
            {t('general.save', { ns: 'common' })}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
