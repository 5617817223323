import { useState } from 'react';

// ** MUI Imports
import Badge from '@mui/material/Badge';
import Button from '@mui/material/Button';
import MuiAvatar from '@mui/material/Avatar';
// import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Box, { BoxProps } from '@mui/material/Box';

// ** Icons Imports
import MenuIcon from 'mdi-material-ui/Menu';
// import Magnify from 'mdi-material-ui/Magnify';
// import PhoneOutline from 'mdi-material-ui/PhoneOutline';
// import VideoOutline from 'mdi-material-ui/VideoOutline';
// import DotsVertical from 'mdi-material-ui/DotsVertical';

// ** Custom Components Import
import ChatLog from './ChatLog';
import SendMsgForm from './SendMsgForm';
import { CustomAvatar } from '../../../../components';
import UserProfileRight from './UserProfileRight';
import { RequestContent } from './request-content';

// ** Types
import { ChatContentType } from '../../../../models';
import { useTranslation } from 'react-i18next';

// ** Styled Components
const ChatWrapperStartChat = styled(Box)<BoxProps>(({ theme }) => ({
  flexGrow: 1,
  height: '100%',
  display: 'flex',
  borderRadius: 1,
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  backgroundColor: theme.palette.action.hover,
}));

const ChatContent = (props: ChatContentType) => {
  // ** Props
  const {
    idUser,
    idBudget,
    store,
    hidden,
    sendMsg,
    mdAbove,
    dispatch,
    statusObj,
    getInitials,
    sidebarWidth,
    userProfileRightOpen,
    handleLeftSidebarToggle,
    handleUserProfileRightSidebarToggle,
  } = props;
  const { t } = useTranslation(['common']);
  // ** State
  const [open, setOpen] = useState<boolean>(false);

  /*   const open = Boolean(anchorEl);

  const handleClick = (event: SyntheticEvent) => {
    setAnchorEl(event.currentTarget as HTMLElement);
  };
  const handleClose = () => {
    setAnchorEl(null);
  }; */

  const handleToggle = () => {
    setOpen(prev => !prev);
  };

  const handleStartConversation = () => {
    if (!mdAbove) {
      handleLeftSidebarToggle();
    }
  };

  const renderContent = () => {
    if (store) {
      const selectedChat = store?.selectedChat;
      if (!selectedChat) {
        return (
          <></>
          // <ChatWrapperStartChat
          //   sx={{
          //     ...(mdAbove ? { borderTopLeftRadius: 0, borderBottomLeftRadius: 0 } : {}),
          //   }}
          // >
          //   <MuiAvatar
          //     sx={{
          //       mb: 6,
          //       pt: 8,
          //       pb: 7,
          //       px: 7.5,
          //       width: 110,
          //       height: 110,
          //       backgroundColor: 'background.paper',
          //       boxShadow: theme => theme.shadows[3],
          //     }}
          //   >
          //     <MessageOutline sx={{ fontSize: '3.125rem' }} />
          //   </MuiAvatar>
          //   <Box
          //     onClick={handleStartConversation}
          //     sx={{
          //       py: 2,
          //       px: 6,
          //       borderRadius: 5,
          //       backgroundColor: 'background.paper',
          //       boxShadow: theme => theme.shadows[3],
          //       cursor: mdAbove ? 'default' : 'pointer',
          //     }}
          //   >
          //     <Typography sx={{ fontWeight: 500, fontSize: '1.125rem', lineHeight: 'normal' }}>
          //       {t('general.startConversation')}
          //     </Typography>
          //   </Box>
          // </ChatWrapperStartChat>
        );
      } else {
        return (
          <>
            <RequestContent idBudget={idBudget} open={open} toggle={handleToggle} />

            <Box
              sx={{
                flexGrow: 1,
                width: '100%',
                height: '100%',
                backgroundColor: theme => theme.palette.action.hover,
              }}
            >
              <Box
                sx={{
                  py: 3,
                  px: 5,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  borderBottom: theme => `1px solid ${theme.palette.divider}`,
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  {mdAbove ? null : (
                    <IconButton onClick={handleLeftSidebarToggle} sx={{ mr: 2 }}>
                      <MenuIcon />
                    </IconButton>
                  )}
                  <Box
                    onClick={handleUserProfileRightSidebarToggle}
                    sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                  >
                    <Badge
                      overlap='circular'
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      sx={{ mr: 3 }}
                      badgeContent={
                        <Box
                          component='span'
                          sx={{
                            width: 8,
                            height: 8,
                            borderRadius: '50%',
                            // color: `${statusObj[selectedChat.contact.status]}.main`,
                            boxShadow: theme => `0 0 0 2px ${theme.palette.background.paper}`,
                            // backgroundColor: `${statusObj[selectedChat.contact.status]}.main`,
                          }}
                        />
                      }
                    >
                      {selectedChat.contact.avatar ? (
                        <MuiAvatar
                          src={selectedChat.contact.avatar}
                          alt={selectedChat.contact.fullName}
                          sx={{ width: '2.375rem', height: '2.375rem' }}
                        />
                      ) : (
                        <CustomAvatar
                          skin='light'
                          color={selectedChat.contact.avatarColor}
                          sx={{ width: '2.375rem', height: '2.375rem', fontSize: '1rem' }}
                        >
                          {getInitials(selectedChat?.contact?.fullName)}
                        </CustomAvatar>
                      )}
                    </Badge>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      <Typography sx={{ fontWeight: 500, fontSize: '0.875rem' }}>
                        {selectedChat.contact.fullName}
                      </Typography>
                      {/* <Typography variant='caption' sx={{ color: 'text.disabled' }}>
                      {selectedChat.contact.role}
                    </Typography> */}
                    </Box>
                  </Box>
                </Box>

                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Button size='medium' sx={{ color: 'text.info' }} onClick={handleToggle}>
                    Ver Peticion
                  </Button>
                </Box>
              </Box>

              {selectedChat && store.userProfile ? (
                <ChatLog hidden={hidden} data={{ ...selectedChat, userContact: store.userProfile }} idUser={idUser} />
              ) : null}

              <SendMsgForm store={store} dispatch={dispatch} sendMsg={sendMsg} idUser={idUser} />

              <UserProfileRight
                store={store}
                hidden={hidden}
                statusObj={statusObj}
                getInitials={getInitials}
                sidebarWidth={sidebarWidth}
                userProfileRightOpen={userProfileRightOpen}
                handleUserProfileRightSidebarToggle={handleUserProfileRightSidebarToggle}
              />
            </Box>
          </>
        );
      }
    } else {
      return null;
    }
  };

  return renderContent();
};

export default ChatContent;
