import { useNavigate } from 'react-router-dom';

// @MUI Components
import { Box, Button, Typography } from '@mui/material/';

/**
 * @component
 * @subcategory Global
 *
 * @description
 * This is a custom 401 error page component. It displays a message indicating that the user is not authorized to access the page and provides a button to navigate back to the home page.
 *
 * #### Example
 * ```jsx
 * return(
 * <Error401 />
 * )
 * ```
 *
 * @return {JSX.Element} A JSX element containing the 401 error page.
 * @author CML Exports Front-End Developers
 */
export const Error401 = () => {
  const navigate = useNavigate();

  return (
    <Box
      className='content-center'
      sx={{
        width: '90%',
        mt: 20,
        ml: 'auto',
        mr: { xs: 0.5, md: 'auto' },
        zIndex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
      }}
    >
      <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
        <Typography variant='h1'>401</Typography>
        <Typography variant='h5' sx={{ mb: 1, fontSize: '1.5rem !important' }}>
          You are not authorized! 🔐
        </Typography>

        <Typography variant='body2' fontWeight={600} sx={{ py: 2, opacity: 0.65, color: '#474747' }}>
          You don&prime;t have permission to access this page. Go Home!
        </Typography>
      </Box>

      <Button component='a' variant='contained' sx={{ px: 5.5, mt: 2 }} onClick={() => navigate('/')}>
        Back to Home
      </Button>
    </Box>
  );
};
