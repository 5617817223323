import { Card, Button, Grid, TextField, CardContent, Typography, IconButton } from '@mui/material';
import { TextForm, DatePicker } from '../../../../components';
import useBL from './use-BL';
import CancelIcon from '@mui/icons-material/Cancel';
import { useLocation } from 'react-router-dom';
import CustomSwalAlert from '../../../../utils/CustomSwalAlert';
import { useTranslation } from 'react-i18next';

interface CharacterCounterProps {
  value: string;
  maxLength: number;
}
function CharacterCounter({ value, maxLength }: CharacterCounterProps): JSX.Element {
  const count = value.length;
  return (
    <p>
      {count}/{maxLength}
    </p>
  );
}

const BLForm = () => {
  const location = useLocation();
  const {
    control,
    maxLength,
    handleChange,
    contentFields,
    contentAppend,
    contentRemove,
    counter,
    chargesFields,
    chargesAppend,
    onSubmit,
    chargesRemove,
    documentId,
    navigate,
    setIsSigning,
    isSigning,
    signingDocument,
    canEdit,
  } = useBL();

  const { t, i18n } = useTranslation('common');

  const signinAlert = () => {
    CustomSwalAlert(t('signStamp.title') || '', t('signStamp.text') || '', 'question', true, signingDocument);
  };

  return (
    <Grid container spacing={2} rowGap={2}>
      <Grid item xs={12} sm={4}>
        <TextForm
          translationFile='validations'
          control={control}
          label={t('pdfs.blNumber', { ns: 'placeholder' })}
          name='blNumber'
          disabled={isSigning}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextForm
          translationFile='validations'
          control={control}
          label={t('pdfs.originalNumber', { ns: 'placeholder' })}
          name='originalNumber'
          disabled={isSigning}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextForm
          translationFile='validations'
          control={control}
          label={t('pdfs.placeOfReceipt', { ns: 'placeholder' })}
          name='placeOfReceipt'
          disabled={isSigning}
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <DatePicker
          control={control}
          label={t('pdfs.expeditionDate', { ns: 'placeholder' })}
          name={'expeditionDate'}
          disabled={isSigning}
        />
      </Grid>
      <Grid item xs={12} sm={4} />

      <Grid item xs={12} sm={6}>
        <Card variant={'outlined'}>
          <CardContent>
            <Grid container columnSpacing={5} rowSpacing={5}>
              <Grid item xs={12}>
                <Typography variant={'subtitle1'}>{t('pdfs.consignee', { ns: 'placeholder' })}</Typography>
              </Grid>
              <Grid item xs={12}>
                <TextForm
                  translationFile='validations'
                  control={control}
                  label={t('airGuide.name', { ns: 'placeholder' })}
                  name='consignee.name'
                  disabled={isSigning}
                />
              </Grid>
              <Grid item xs={12}>
                <TextForm
                  translationFile='validations'
                  control={control}
                  label={t('airGuide.address', { ns: 'placeholder' })}
                  name='consignee.direction'
                  disabled={isSigning}
                />
              </Grid>
              <Grid item xs={12}>
                <TextForm
                  translationFile='validations'
                  control={control}
                  label={t('airGuide.email', { ns: 'placeholder' })}
                  name='consignee.email'
                  disabled={isSigning}
                />
              </Grid>
              <Grid item xs={12}>
                <TextForm
                  translationFile='validations'
                  control={control}
                  label={t('airGuide.phone', { ns: 'placeholder' })}
                  name='consignee.phone'
                  disabled={isSigning}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12} sm={6}>
        <Card variant={'outlined'}>
          <CardContent>
            <Grid container columnSpacing={5} rowSpacing={5}>
              <Grid item xs={12}>
                <Typography variant='subtitle1'>{t('airGuide.shipper', { ns: 'placeholder' })}</Typography>
              </Grid>
              <Grid item xs={12}>
                <TextForm
                  translationFile='validations'
                  control={control}
                  label={t('airGuide.name', { ns: 'placeholder' })}
                  name='shipper.name'
                  disabled={isSigning}
                />
              </Grid>

              <Grid item xs={12}>
                <TextForm
                  translationFile='validations'
                  control={control}
                  label={t('airGuide.address', { ns: 'placeholder' })}
                  name='shipper.direction'
                  disabled={isSigning}
                />
              </Grid>

              <Grid item xs={12}>
                <TextForm
                  translationFile='validations'
                  control={control}
                  label={t('airGuide.email', { ns: 'placeholder' })}
                  name='shipper.email'
                  disabled={isSigning}
                />
              </Grid>

              <Grid item xs={12}>
                <TextForm
                  translationFile='validations'
                  control={control}
                  label={t('airGuide.phone', { ns: 'placeholder' })}
                  name='shipper.phone'
                  disabled={isSigning}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12} sx={{ textAlign: 'center' }}>
        <Typography variant={'subtitle1'}> {t('pdfs.notify', { ns: 'placeholder' })} </Typography>
      </Grid>

      <Grid container spacing={2} rowGap={2} sx={{ marginLeft: 0.5 }}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextForm
                translationFile='validations'
                control={control}
                label={t('airGuide.name', { ns: 'placeholder' })}
                name='notifyname'
                disabled={isSigning}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextForm
                translationFile='validations'
                control={control}
                label={t('general.address')}
                name='notifyaddress'
                disabled={isSigning}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <>
            <TextField
              fullWidth
              id='container'
              label={t('pdfs.container', { ns: 'placeholder' })}
              multiline
              rows={4.2}
              value={counter}
              onChange={handleChange}
              inputProps={{ maxLength }}
              disabled={isSigning}
            />
            <CharacterCounter value={counter} maxLength={maxLength} />
          </>
        </Grid>
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <TextForm
          translationFile='validations'
          control={control}
          label={t('pdfs.vessel', { ns: 'placeholder' })}
          name='vessel'
          disabled={isSigning}
        />
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <TextForm
          translationFile='validations'
          control={control}
          label={t('pdfs.portOfLoading', { ns: 'placeholder' })}
          name='portOfLoading'
          disabled={isSigning}
        />
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <TextForm
          translationFile='validations'
          control={control}
          label={t('pdfs.portOfDischarge', { ns: 'placeholder' })}
          name='portOfDischarge'
          disabled={isSigning}
        />
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <TextForm
          translationFile='validations'
          control={control}
          label={t('pdfs.placeOfDelivery', { ns: 'placeholder' })}
          name='placeOfDelivery'
          disabled={isSigning}
        />
      </Grid>

      <Grid item xs={12}>
        <Grid container columnSpacing={5} rowSpacing={5}>
          <Grid item xs={12} sx={{ textAlign: 'center' }}>
            <Typography variant='subtitle1'>{t('general.content')}</Typography>
          </Grid>

          {contentFields.map((field, index) => (
            <Grid key={field.id} item xs={12}>
              <Card variant='outlined' sx={{ p: 5, position: 'relative' }}>
                <Grid container columnSpacing={5} rowSpacing={5}>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextForm
                      translationFile='validations'
                      control={control}
                      label={t('BL.marks', { ns: 'placeholder' })}
                      name={`content.${index}.marks`}
                      disabled={isSigning}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextForm
                      translationFile='validations'
                      control={control}
                      label={t('BL.packages', { ns: 'placeholder' })}
                      name={`content.${index}.packages`}
                      disabled={isSigning}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextForm
                      translationFile='validations'
                      control={control}
                      label={t('pdfs.grossWeight', { ns: 'placeholder' })}
                      name={`content.${index}.grossWeight`}
                      disabled={isSigning}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextForm
                      translationFile='validations'
                      control={control}
                      label={t('BL.measurement', { ns: 'placeholder' })}
                      name={`content.${index}.measurement`}
                      disabled={isSigning}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextForm
                      translationFile='validations'
                      control={control}
                      label={t('pdfs.description', { ns: 'placeholder' })}
                      name={`content.${index}.description`}
                      disabled={isSigning}
                    />
                  </Grid>
                  {index > 0 && (
                    <Grid item xs={12}>
                      <IconButton
                        onClick={() => {
                          contentRemove(index);
                        }}
                        color='error'
                        disabled={isSigning}
                        sx={{
                          position: 'absolute',
                          bottom: 0,
                          left: 0,
                          fontSize: '16px',
                          paddingLeft: '32px',
                          paddingTop: '4px',
                        }}
                      >
                        <CancelIcon />
                        {t('general.deleteContent')}
                      </IconButton>
                    </Grid>
                  )}
                </Grid>
              </Card>
            </Grid>
          ))}
          <Grid item xs={12} sx={{ textAlign: 'center' }}>
            <Button
              sx={{ color: '#00ef14', margin: 'auto' }}
              type='button'
              disabled={contentFields.length >= 9 || isSigning}
              onClick={() => {
                contentAppend({
                  marks: '',
                  packages: null,
                  grossWeight: null,
                  measurement: ' ',
                  description: '',
                });
              }}
            >
              <i className='fa-solid fa-circle-plus text-xl text-green-500 mr-2' />
              <p>{t('airGuide.addline', { ns: 'placeholder' })}</p>
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container columnSpacing={5} rowSpacing={5}>
          <Grid item xs={12} sx={{ textAlign: 'center' }}>
            <Typography variant='subtitle1'>{t('pdfs.charges.charges', { ns: 'placeholder' })}</Typography>
          </Grid>

          {chargesFields.map((field, index) => (
            <Grid key={field.id} item xs={12}>
              <Card variant='outlined' sx={{ p: 5, position: 'relative' }}>
                <Grid container columnSpacing={5} rowSpacing={5}>
                  <Grid item xs={12} sm={4}>
                    <TextForm
                      translationFile='validations'
                      control={control}
                      label={t('pdfs.description', { ns: 'placeholder' })}
                      name={`charges.${index}.description`}
                      disabled={isSigning}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <TextForm
                      translationFile='validations'
                      control={control}
                      label={t('pdfs.charges.prepaid', { ns: 'placeholder' })}
                      name={`charges.${index}.prepaid`}
                      disabled={isSigning}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <TextForm
                      translationFile='validations'
                      control={control}
                      label={t('pdfs.charges.collect', { ns: 'placeholder' })}
                      name={`charges.${index}.collect`}
                      disabled={isSigning}
                    />
                  </Grid>

                  {index > 0 && (
                    <Grid item xs={12}>
                      <IconButton
                        onClick={() => {
                          chargesRemove(index);
                        }}
                        color='error'
                        disabled={isSigning}
                        sx={{
                          position: 'absolute',
                          bottom: 0,
                          left: 0,
                          fontSize: '16px',
                          paddingLeft: '32px',
                          paddingTop: '4px',
                        }}
                      >
                        <CancelIcon />
                        {t('general.deleteCharge')}
                      </IconButton>
                    </Grid>
                  )}
                </Grid>
              </Card>
            </Grid>
          ))}

          <Grid item xs={12} sx={{ textAlign: 'center' }}>
            <Button
              sx={{ color: '#00ef14', margin: 'auto' }}
              type='button'
              disabled={chargesFields.length > 4 || isSigning}
              onClick={() => {
                chargesAppend({ description: '', prepaid: null, collect: null });
              }}
            >
              <i className='fa-solid fa-circle-plus text-xl text-green-500 mr-2' />
              <p>{t('airGuide.addCharges', { ns: 'placeholder' })}</p>
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        item
        xs={12}
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          justifyContent: 'center',
          alignItems: 'center',
          gap: 2,
        }}
      >
        <Button disabled={isSigning} variant='contained' onClick={onSubmit}>
          {documentId === null
            ? i18n.language === 'es'
              ? 'Guardar'
              : 'Save'
            : t('budgetGuide.saveDocumentButtonTittle')}{' '}
        </Button>

        {documentId !== null && (
          <Button
            disabled={isSigning}
            variant='contained'
            sx={{ backgroundColor: '#04ec2b' }}
            onClick={() => signinAlert()}
            color='success'
          >
            {t('signStamp.btn')}
          </Button>
        )}

        {documentId !== null && (
          <Button
            variant='contained'
            onClick={() => navigate(documentId, location.pathname + '&value=4')}
            color='secondary'
          >
            {t('budgetGuide.viewDocumentButtonTittle')}
          </Button>
        )}
      </Grid>
    </Grid>
  );
};
export default BLForm;
