import { useTranslation } from 'react-i18next';

// @MUI Imports
import Typography from '@mui/material/Typography';

// @Models
import { ServiceSupplier } from '../models';

// @Utils
import { formatNumber } from '../../../utils/format-number.util';

interface RenderCell {
  row: ServiceSupplier;
}

export const defaultColumns = () => {
  const { t, i18n } = useTranslation(['common']);

  return [
    {
      flex: 0.25,
      field: 'serviceName',
      minWidth: 250,
      headerName: t(i18n.language === 'es' ? 'services.name' : 'services.nameEN'),
      renderCell: ({ row }: RenderCell) => {
        return (
          <Typography noWrap variant='body2' sx={{ justifyContent: 'center', textAlign: 'center' }}>
            {i18n.language === 'es' ? row.service.name : row.service.name_EN}
          </Typography>
        );
      },
    },
    {
      flex: 0.2,
      minWidth: 140,
      field: 'price',
      headerName: 'Subtotal',
      renderCell: ({ row }: RenderCell) => {
        return (
          <Typography
            noWrap
            sx={{
              textTransform: 'capitalize',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
            }}
          >
            {formatNumber(row.salePrice)}
          </Typography>
        );
      },
    },
    {
      flex: 0.2,
      minWidth: 140,
      field: 'amountOfIva',
      headerName: t('general.iva'),
      renderCell: ({ row }: RenderCell) => {
        return (
          <Typography
            noWrap
            sx={{
              textTransform: 'capitalize',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
            }}
          >
            {formatNumber(row.saleType === 3 ? row.salePrice * 0.13 : 0)}
          </Typography>
        );
      },
    },
    {
      flex: 0.2,
      minWidth: 140,
      field: 'amountOfService',
      headerName: i18n.language === 'es' ? 'Total Estimado' : 'Estimate Total',
      renderCell: ({ row }: RenderCell) => {
        const iva = row.saleType === 3 ? row.salePrice * 0.13 : 0;
        return (
          <Typography
            noWrap
            sx={{
              textTransform: 'capitalize',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
            }}
          >
            {formatNumber(row.salePrice + iva)}
          </Typography>
        );
      },
    },
  ];
};
