// @MUI Components
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@mui/material';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  border: '0.15rem solid',
  borderColor: alpha(theme.palette.info.main, 1),
  width: '100%',
  display: 'flex',
  [theme.breakpoints.up('sm')]: {
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 1),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(3)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));

interface IProps {
  value: string;
  handleFilter(value: string): void;
  placeholder: string;
}

/**
 * @component
 * @subcategory Global
 *
 * @description
 * Custom search component using Material-UI. It allows to search for a specific value and it's integrated with react-i18next for translations.
 *
 * #### Example
 * ```jsx
 * return(
 * <SearchField
 *   value={searchValue}
 *   handleFilter={handleFilter}
 *   placeholder="Search..."
 * />
 * )
 * ```
 *
 * @property {string} value The current value of the search input.
 * @property {function} handleFilter A callback function to be called when the value changes.
 * @property {string} placeholder The placeholder text for the search input.
 *
 * @return {JSX.Element} The custom search component.
 * @author CML Exports Front-End Developers
 */
export function SearchField({ value, handleFilter, placeholder }: IProps) {
  return (
    <Search sx={{ display: 'flex', justifyContent: 'space-between', height: '40px' }}>
      <Box sx={{ width: '100%', paddingTop: 1, paddingBottom: 1, display: 'flex', alignItems: 'center' }}>
        <SearchIconWrapper>
          <SearchIcon />
        </SearchIconWrapper>

        <StyledInputBase
          placeholder={placeholder}
          inputProps={{ 'aria-label': 'search' }}
          value={value || ''}
          onChange={event => handleFilter(event.target.value)}
          sx={{ width: '100%' }}
        />
      </Box>

      <IconButton onClick={() => handleFilter('')}>
        <CloseIcon />
      </IconButton>
    </Search>
  );
}
