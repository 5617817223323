// @Library
import { AsyncThunkPayloadCreator, createAsyncThunk } from '@reduxjs/toolkit';

// @Utils
import { axiosEstimates } from '../../../utils/api';

// @Models
import { IServicesRequest, IServiceTypesRequest, IGetServicesByTypeBody } from '../../../models';

// !--- INIT HANDLER ---!
const handleGetServices: AsyncThunkPayloadCreator<IServicesRequest[], undefined, {}> = async (
  payload,
  { rejectWithValue },
) => {
  try {
    const response = await axiosEstimates.get<IServicesRequest[]>('services');
    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
};

const handleGetServicesBySupplier: AsyncThunkPayloadCreator<IServicesRequest[], { id: number }, {}> = async (
  payload,
  { rejectWithValue },
) => {
  try {
    const response = await axiosEstimates.get<IServicesRequest[]>('services-by-supplier/' + payload.id);
    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
};

const handleGetServicesByType: AsyncThunkPayloadCreator<IServicesRequest[], IGetServicesByTypeBody, {}> = async (
  payload,
  { rejectWithValue },
) => {
  try {
    const response = await axiosEstimates.post<IServicesRequest[]>('services-by-type', payload);
    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
};

const handleGetServiceTypes: AsyncThunkPayloadCreator<IServiceTypesRequest[], undefined, {}> = async (
  payload,
  { rejectWithValue },
) => {
  try {
    const response = await axiosEstimates.get<{ getServiceType: IServiceTypesRequest[]; permissions: any }>(
      'servicetypes',
      { headers: { 'x-module': 'services' } },
    );

    return response.data.getServiceType;
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
};
// !--- END HANDLER ---!

// !--- INIT THUNKS ---!
export const getServices = createAsyncThunk('services/getAll', handleGetServices);
export const getServicesBySupplier = createAsyncThunk('services/getBySupplier', handleGetServicesBySupplier);
export const getServiceTypes = createAsyncThunk('services/getTypes', handleGetServiceTypes);
export const getServicesByType = createAsyncThunk('services/getServiceByType', handleGetServicesByType);

// !--- END THUNKS ---!
