import * as yup from 'yup';
import { Booking } from '../../../../../models';
export const initialValues: Booking = {
  referenceCode: '',
  expeditionDate: '',
  consignee: '', // select
  notify: '', // select
  forwarder: '', // select
  contact: '', // select
  agent: '', // select
  phone: '',
  shipline: '',
  reference: '',
  quotation: '',
  voyage: '',
  billOfLading: '',
  via: '',
  departure: '',
  arrival: '',
  origin: '',
  destination: '',
  employee: '', // select
  hazardous: false,
  comments: '',
  content: [],
  charges: [],
  by: {
    name: '',
    email: '',
    phone: '',
    direction: '',
  },
  shipper: {
    name: '',
    email: '',
    phone: '',
    direction: '',
  },
};

export const schemaValidation = yup
  .object()
  .shape({
    //
    referenceCode: yup.string().required('required.general'),
    expeditionDate: yup.string().required('required.general'),
    by: yup.object().shape({
      name: yup.string().required('required.general'),
      direction: yup.string().required('required.general'),
      email: yup.string().notRequired(),
      // phone: yup.number().typeError('required.general').required('errors.require.by.phone'),
      phone: yup.string().notRequired(),
    }),
    shipper: yup.object().shape({
      name: yup.string().required('required.general'),
      direction: yup.string().required('required.general'),
      email: yup.string().notRequired(),
      phone: yup.string().notRequired(),
    }),
    contact: yup.string().notRequired(), // select
    agent: yup.string().required('required.general'), // select
    phone: yup.string().notRequired(),
    shipline: yup.string().required('required.general'),
    reference: yup.string().required('required.general'),
    quotation: yup.string().required('required.general'),
    billOfLading: yup.string().required('required.general'),
    voyage: yup.string().required('required.general'),
    via: yup.string().required('required.general'),
    departure: yup.string().required('required.general'),
    arrival: yup.string().required('required.general'),
    origin: yup.string().required('required.general'),
    destination: yup.string().required('required.general'),
    employee: yup.string().required('required.general'), // select
    comments: yup.string().notRequired(),
    hazardous: yup.boolean().required('required.general'),
    content: yup.array().of(
      yup.object().shape({
        type: yup.string(),
        quantity: yup
          .number()
          .optional()
          .nullable()
          .transform(value => (isNaN(value) ? null : value)),
        description: yup.string().required('required.general'),
        grossWeight: yup
          .number()
          .optional()
          .nullable()
          .transform(value => (isNaN(value) ? null : value)),
        volume: yup
          .number()
          .optional()
          .nullable()
          .transform(value => (isNaN(value) ? null : value)),
      }),
    ),
    charges: yup.array().of(
      yup.object().shape({
        description: yup.string().notRequired(),
        prepaid: yup.number().notRequired().nullable().default(null),
        collect: yup
          .number()
          .notRequired()
          .nullable()
          .default(null)
          .transform(value => (isNaN(value) ? null : value)),
      }),
    ),
  })
  .required();
