import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

// @MUI Imports
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

// @Icons Imports
import { Icon } from '@iconify/react';

// @Models
import { IServicesOfSuppliers, IServiceBudgetForm } from '../../../../models';

// @Utils
import { formatNumber } from '../../../../utils/format-number.util';

interface RenderCell {
  row: IServicesOfSuppliers;
}

export const defaultColumns = (select: (value: IServicesOfSuppliers) => void, services: IServiceBudgetForm[]) => {
  const { t } = useTranslation(['common']);
  return [
    {
      flex: 0.15,
      minWidth: 120,
      sortable: false,
      field: 'actions',
      headerName: t('general.actions'),
      headerClassName: 'gridHeader',
      headerAlign: 'center',
      align: 'right',
      renderCell: ({ row }: RenderCell) => {
        const service = services.find(service => service.serviceId === row.id);
        return (
          <Box sx={{ display: 'flex', alignItems: 'center', padding: 2 }}>
            <IconButton
              onClick={() => select(row)}
              color='success'
              disabled={Boolean(service)}
              title={`${t('general.select')}`}
            >
              <Icon icon='material-symbols:playlist-add-check-circle-outline' />
            </IconButton>
          </Box>
        );
      },
    },
    {
      flex: 0.11,
      minWidth: 130,
      field: 'quotationCode',
      headerName: t('general.code'),
      headerClassName: 'gridHeader',
      headerAlign: 'center',
      renderCell: ({ row }: RenderCell) => {
        const { quotationCode } = row;

        return (
          <Typography
            noWrap
            component='a'
            variant='body2'
            sx={{
              fontWeight: 600,
              color: 'text.primary',
              textDecoration: 'none',
            }}
          >
            {quotationCode}
          </Typography>
        );
      },
    },
    {
      flex: 0.2,
      minWidth: 140,
      field: 'transportTime',
      headerName: 'T.T',
      headerClassName: 'gridHeader',
      headerAlign: 'center',
      renderCell: ({ row }: RenderCell) => {
        return (
          <Typography
            noWrap
            sx={{
              textTransform: 'capitalize',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {row.transportTime}
          </Typography>
        );
      },
    },
    {
      flex: 0.35,
      minWidth: 240,
      maxWidth: 240,
      field: 'service',
      headerName: t('general.service'),
      headerClassName: 'gridHeader',
      headerAlign: 'center',
      renderCell: ({ row }: RenderCell) => {
        return (
          <Typography
            sx={{
              textTransform: 'capitalize',
              display: 'flex',
              alignItems: 'center',
            }}
            noWrap
          >
            <Box
              title={i18n.language === 'es' ? row.service : row.serviceEN !== null ? row.serviceEN : row.service}
              sx={{
                overflow: 'hidden',
                maxWidth: '100%',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {i18n.language === 'es' ? row.service : row.serviceEN !== null ? row.serviceEN : row.service}
            </Box>
          </Typography>
        );
      },
    },
    {
      flex: 0.2,
      minWidth: 140,
      field: 'cost',
      headerName: t('general.price'),
      headerClassName: 'gridHeader',
      headerAlign: 'center',
      align: 'right',
      renderCell: ({ row }: RenderCell) => {
        return (
          <Typography
            noWrap
            sx={{
              textTransform: 'capitalize',
              display: 'flex',
              alignItems: 'left',
            }}
          >
            {formatNumber(row.cost)}
          </Typography>
        );
      },
    },
  ];
};
