// @Library
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export function useDesignOptions() {
  const [value, setValue] = useState<number>(0);
  const { t } = useTranslation();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return { value, handleChange, t };
}
