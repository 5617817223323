import { useTranslation } from 'react-i18next';

// @MUI Imports
import Typography from '@mui/material/Typography';

// @Models
import { IServicesOfSuppliers } from '../../../../models';
import { formatNumber } from '../../../../utils/format-number.util';

interface RenderCell {
  row: IServicesOfSuppliers;
}

export const defaultColumns = () => {
  const { t } = useTranslation('common');

  const ivaItems = [
    { value: 1, label: t('massiveEmail.table.tax.Noaplica') },
    { value: 2, label: t('massiveEmail.table.tax.Noaplica') },
    { value: 3, label: t('massiveEmail.table.tax.Siaplica') },
  ];

  return [
    {
      flex: 0.2,
      minWidth: 200,
      field: 'originDestiny',
      headerAlign: 'center',
      headerName: `${t('general.origin', { ns: 'common' })}/${t('general.destiny', { ns: 'common' })}`,
      headerClassName: 'gridHeader',
      renderCell: ({ row }: RenderCell) => {
        return (
          <Typography noWrap sx={{ display: 'flex', textTransform: 'capitalize', alignItems: 'center' }}>
            {row.countryOrigin}/{row.countryDestiny}
          </Typography>
        );
      },
    },
    {
      flex: 0.2,
      minWidth: 200,
      field: 'service',
      headerAlign: 'center',
      headerName: t('general.service'),
      headerClassName: 'gridHeader',
      renderCell: ({ row }: RenderCell) => {
        return (
          <Typography
            title={row.service}
            noWrap
            sx={{ display: 'flex', textTransform: 'capitalize', alignItems: 'center' }}
          >
            {row.service}
          </Typography>
        );
      },
    },
    {
      flex: 0.2,
      minWidth: 200,
      field: 'serviceTypeName',
      headerName: t('services.type'),
      headerClassName: 'gridHeader',
      headerAlign: 'center',
      renderCell: ({ row }: RenderCell) => {
        console.log(row);
        return (
          <Typography title={row.serviceTypeName} noWrap sx={{ display: 'flex', alignItems: 'center' }}>
            {row.serviceTypeName}
            {/* {t(`massiveEmail.table.typeOfService.${row.serviceTypeName.replace(/\s+/g, '')}`)} */}
          </Typography>
        );
      },
    },
    {
      flex: 0.2,
      minWidth: 140,
      field: 'category',
      align: 'left',
      headerAlign: 'center',
      headerName: t('general.category'),
      headerClassName: 'gridHeader',
      renderCell: ({ row }: RenderCell) => {
        return (
          <Typography
            title={row.serviceType.reduce((pre, trans) => pre + `${trans.name}, `, '')}
            noWrap
            sx={{ display: 'flex', textTransform: 'capitalize', alignItems: 'center' }}
          >
            {row.serviceType.reduce((pre, trans) => pre + `${trans.name}, `, '')}
            {/* {row.serviceType
              .reduce((pre, trans) => pre + `${t(`massiveEmail.table.category.${trans.name.toLowerCase()}`)}, `, '')
              .slice(0, -2) // Elimina la coma al final de la última palabra
              .toUpperCase()} */}
          </Typography>
        );
      },
    },
    {
      flex: 0.15,
      minWidth: 120,
      field: 'price',
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'gridHeader',
      headerName: t('general.suggestedprice'),
      renderCell: ({ row }: RenderCell) => {
        const iva = row.saleType === 3 ? 0.13 : 0;
        const price = row.salePrice + row.salePrice * iva;
        return (
          <Typography noWrap sx={{ display: 'flex', textTransform: 'capitalize', alignItems: 'center' }}>
            {formatNumber(price)}
          </Typography>
        );
      },
    },
    {
      flex: 0.15,
      minWidth: 120,
      field: 'iva',
      headerName: t('general.iva'),
      headerClassName: 'gridHeader',
      align: 'center',
      headerAlign: 'center',
      renderCell: ({ row }: RenderCell) => {
        const iva = ivaItems.find(iva => iva.value === row.saleType);
        return (
          <Typography noWrap sx={{ display: 'flex', textTransform: 'capitalize', alignItems: 'center' }}>
            {iva?.label}
            {/* {t(`massiveEmail.table.tax.${iva?.label.replace(/\s+/g, '')}`)} */}
          </Typography>
        );
      },
    },
    {
      flex: 0.15,
      minWidth: 120,
      field: 'expDate',
      align: 'center',
      headerAlign: 'center',
      headerName: t('budgetPage.expDate'),
      headerClassName: 'gridHeader',
      renderCell: ({ row }: RenderCell) => {
        const date = new Date(row.expDate);
        return (
          <Typography noWrap sx={{ display: 'flex', textTransform: 'capitalize', alignItems: 'center' }}>
            {date.toLocaleDateString()}
          </Typography>
        );
      },
    },
    {
      flex: 0.01,
      minWidth: 10,
    },
  ];
};
