import Swal from 'sweetalert2';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { Fragment, useCallback, useEffect, useState } from 'react';

// @Mui
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { Chip, TableBody } from '@mui/material';
import { Icon } from '@iconify/react';

// @Icons
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import NoteEditOutline from 'mdi-material-ui/NoteEditOutline';
import Printer from 'mdi-material-ui/Printer';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import EmailEdit from 'mdi-material-ui/EmailEdit';
import CloseCircleOutline from 'mdi-material-ui/CloseCircleOutline';
import Comment from 'mdi-material-ui/Comment';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import InputIcon from '@mui/icons-material/Input';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import ChatIcon from '@mui/icons-material/Chat';
import DoneAllIcon from '@mui/icons-material/DoneAll';

// @Redux
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/reducers';

// @Services
import { getDocumentsByBudget } from '../../Fomularios Temporales Guias/services';

// @Utils
import statusItems from '../utils/statusItems';
import { axiosEstimates, socket } from '../../../utils';
import CustomSwalAlert from '../../../utils/CustomSwalAlert';
import { formatNumber } from '../../../utils/format-number.util';

// @Components
import { MenuOptions } from './MenuOptions';
import ColumHeader from '../../../components/TableColumnHeader';
import { colorsObject } from '../../../components/StyledComponents/RadioStyled';

// @Interfaces and types
import IRow from '../interfaces/row';
import { ShowMenuOptionsProps } from '../types';
import RequestData from '../interfaces/requestData';
import { DOCUMENTS_ORDERED } from '../../Fomularios Temporales Guias/utils';

/**
 * @component BudgetPage
 * @subcategory Global
 *
 * @description
 * This component is responsible for rendering the budget page, which includes a table with budget information and actions.
 *
 * #### Example
 * ```tsx
 * return(
 * <Row
 *   isSV={true}
 *   isUSA={false}
 *   isCTIS={false}
 *   index={0}
 *   idBudget={1}
 *   permissions={['check_in', 'edit', 'edit_request', 'cancel', 'consult_details', 'send', 'print', 'comments']}
 *   canEdit={true}
 *   row={{
 *     id: 1,
 *     customerName: 'John Doe',
 *     code: 'BUD-001',
 *     amountOfTotal: 1000,
 *     utility: 200,
 *     status: 'active',
 *     sellerName: 'Jane Doe',
 *     createdAt: '2022-01-01T00:00:00.000Z',
 *     expirationDate: '2022-12-31T00:00:00.000Z',
 *     hasPreviousDocument: false,
 *     hasDocument: true,
 *     haveBill: false,
 *   }}
 *   selectedRow={null}
 *   setSelectedRow={() => {}}
 *   handleView={() => {}}
 *   cancelStatus={() => {}}
 *   toggleComments={() => {}}
 *   header={['seller', 'createdOn', 'expDate', 'moreActions']}
 * />
 * )
 * ```
 *
 * @property {boolean} isSV Indicates if the user is a seller.
 * @property {boolean} isUSA Indicates if the budget is from USA.
 * @property {boolean} isCTIS Indicates if the budget is from CTIS.
 * @property {number} index The index of the row.
 * @property {number} idBudget The id of the budget.
 * @property {string[]} permissions The permissions of the user.
 * @property {boolean} canEdit Indicates if the user can edit the budget.
 * @property {IGetBudgets} row The budget information.
 * @property {number | null} selectedRow The selected row.
 * @property {function} setSelectedRow The function to set the selected row.
 * @property {function} handleView The function to handle the view action.
 * @property {function} cancelStatus The function to handle the cancel status action.
 * @property {function} toggleComments The function to handle the toggle comments action.
 * @property {string[]} header The header of the table.
 *
 * @return {JSX.Element} The BudgetPage component.
 * @author CML Exports Front-End Developers
 */
export default function Row({
  isSV,
  isUSA,
  isCTIS,
  index,
  idBudget,
  permissions,
  canEdit,
  row,
  selectedRow,
  setSelectedRow,
  handleView,
  cancelStatus,
  toggleComments,
  header,
}: IRow) {
  const [menuOptionsAnchorEl, setMenuOptionsAnchorEl] = useState<ShowMenuOptionsProps>(null);
  const [hasComments, setHasComments] = useState<boolean | null | undefined>();
  const [commentsStatus, setCommentsStatus] = useState('');
  const [showInvoiceOptions, setShowInvoiceOptions] = useState(false);
  const [showSendOptions, setShowSendOptions] = useState(false);
  const [showPDFOptions, setShowPDFOptions] = useState(false);
  const [showPrintOptions, setShowPrintOptions] = useState(false);
  const [hasAnyPDF, setHasAnyPDF] = useState<boolean | number>(false);

  // Boolean specifying if a row is open.
  const isRowSelected = selectedRow === index;

  const { t } = useTranslation('common');

  const location = useLocation();
  const navigate = useNavigate();

  const { user } = useSelector((state: RootState) => state);

  // Status' label and value.
  const status = statusItems.find(status => status.value === row.status);

  // Gets the previous URL
  const getBackUrl = () => {
    if (isCTIS) return '/ctis';
    else if (isUSA) return '/budgets-from-us';
    else return '/budgets';
  };

  // Verify config depending the budget type.
  const verifyConfig = async () => {
    let budgetType: number | null = null;

    if (location.pathname === '/budgets') budgetType = 1;
    if (location.pathname === '/ctis') budgetType = 2;
    if (location.pathname === '/budgets-from-us') budgetType = 3;

    await axiosEstimates.get(`/pdfConfig?view=${budgetType}`);
  };

  // NOTE: HAS PERMISSION FUNCTIONS FOR BUDGETS ACTIONS

  const hasPermissionToBillSV = () => {
    if (!permissions.includes('check_in')) return false;
    else if (location.pathname !== '/budgets') return false;
    else if (row.status !== 'approved' && row.status !== 'toBeInvoiced') return false;
    else return true;
  };

  const hasPermissionToBillUSA = () => {
    if (!permissions.includes('check_in')) return false;
    else if (location.pathname !== '/budgets-from-us') return false;
    else if (row.status !== 'approved' && row.status !== 'toBeInvoiced') return false;
    else return true;
  };

  const hasPermissionFinalize = (typeUser: string | undefined, haveBill: boolean) => {
    if (typeUser === 'alliance') return true;
    else return haveBill;
  };

  const hasPermissionToBillCTIS = () => {
    if (!permissions.includes('check_in')) return false;
    else if (location.pathname !== '/ctis') return false;
    else if (row.status !== 'approved' && row.status !== 'toBeInvoiced') return false;
    else return true;
  };

  const hasPermissionToPrint = () => {
    if (!permissions.includes('print')) return false;
    else if (
      row.status !== 'active' &&
      row.status !== 'approved' &&
      row.status !== 'finished' &&
      row.status !== 'rejected'
    ) {
      return false;
    } else if (location.pathname === '/requests') return false;
    else return true;
  };

  const hasPermissionToEdit = () => {
    // High priority
    if (row.status === 'approved') return user?.role?.includes('admin');
    else {
      // Normal priority
      if (!canEdit) return false;
      else if (!permissions.includes('edit') && !permissions.includes('edit_request')) return false;
      else if (['pending'].includes(row.status) && !['admin'].includes(user.role)) return false;
      else if (['cancel', 'finished'].includes(row.status)) return false;
      else return true;
    }
  };

  // NOTE: HAS PERMISSION FUNCTIONS FOR SELLERS ACTIONS
  const hasPermissionToConsult = () => {
    if (!permissions.includes('consult_details')) return false;
    else if (row.status === 'request' || row.status === 'cancel') return false;
    else return true;
  };

  const hasPermissionToCancel = () => {
    if (!permissions.includes('cancel')) return false;
    else if (!['active', 'pending', 'initialized', 'request'].includes(row.status)) return false;
    else return true;
  };

  const validateExpDate = (date: string) => {
    const expDate = new Date(date).getTime();
    const today = new Date().getTime();

    return expDate < today;
  };

  const handleClick = () => setSelectedRow(isRowSelected ? null : index);

  const openPdf = (id: number) => {
    if (window.innerWidth < 800) {
      const url = `${process.env.REACT_APP_ESTIMATES_API}pdf/download/budget?id=${id}`;

      window.open(url);
    }
  };

  const onHandlerButtonClick = async (id: number, total: number, type: 'FF' | 'CCF') => {
    navigate(`/invoices/form-${type}/${id}/${total}`);
  };

  const handleSendButton = (e: React.MouseEvent<HTMLElement>) => {
    setMenuOptionsAnchorEl(e.currentTarget);
    setShowInvoiceOptions(false);
    setShowPDFOptions(false);
    setShowPrintOptions(false);
    setShowSendOptions(true);
  };
  const handlePrintButton = (e: React.MouseEvent<HTMLElement>) => {
    setMenuOptionsAnchorEl(e.currentTarget);
    setShowInvoiceOptions(false);
    setShowPrintOptions(true);
    setShowSendOptions(false);
    setShowPDFOptions(false);
  };

  const handleInvoiceOrCreditButton = (e: React.MouseEvent<HTMLElement>) => {
    setMenuOptionsAnchorEl(e.currentTarget);
    setShowPDFOptions(false);
    setShowSendOptions(false);
    setShowPrintOptions(false);
    setShowInvoiceOptions(true);
  };

  const handlePDFOptions = (e: React.MouseEvent<HTMLElement>) => {
    setMenuOptionsAnchorEl(e.currentTarget);
    setShowInvoiceOptions(false);
    setShowSendOptions(false);
    setShowPrintOptions(false);
    setShowPDFOptions(true);
  };

  const handlePrint = async (id: number, previous: boolean = false, ismakeBudget: boolean = false) => {
    const pathPrevious = previous ? '?previous=v2' : '';
    const makeBudget = ismakeBudget ? '?makeBudget=true' : '';
    try {
      await verifyConfig();
      navigate('/budgets/print/' + id + pathPrevious + makeBudget);
    } catch (error) {
      console.error(error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'No tiene opción de diseño para este documento',
      });
    }
  };

  const verifyHasComments = async (idBudget: Number) => {
    try {
      const result = await axiosEstimates.get<RequestData>('comments/' + idBudget);

      if (!result?.data?.comments?.length) return null;

      const lastComment = result.data.comments[result.data.comments.length - 1];

      if (lastComment.idUser === user.idCRM) return true;
      else return false;
    } catch (error) {
      console.error(error);
    }
  };

  // Manejador de facturación US y CTIS
  const handleUSInvoice = async (id: number) => {
    const billType = location.pathname === '/budgets-from-us' ? 3 : 4;
    const res = await axiosEstimates.post(`/api/create-bill/${id}?billType=${billType}`);
    return res.data;
  };

  const activeBudget = async (id: number, text: string) => {
    const body = { text };
    const res = await axiosEstimates.post(`/budget/send-email-reopen/${id}`, body);
    return res.data;
  };

  const handleClickInvoiceUS = async (id: number) => {
    CustomSwalAlert(
      `${t('general.sure', { ns: 'validations' })}`,
      `${t('general.cmlInvoiceAlert', { ns: 'validations' })}`,
      'info',
      true,
      async () => {
        try {
          await handleUSInvoice(id);

          Swal.fire(
            t('general.good', { ns: 'validations' }) || '',
            t('general.approve', { ns: 'validations' }) || '',
            'success',
          );
        } catch (error) {
          console.error(error);
          Swal.fire('Error', '', 'error');
        }
      },
    );
  };

  // Recordar comentarios
  const cachedVerifyHasComments = useCallback(() => verifyHasComments(row.id), [row.id]);

  const getLastRoute = () => location.pathname.substring(1);

  const handleActiveBudget = async (id: number) => {
    await Swal.fire({
      text: t('budget.open', { ns: 'validations' }) || '',
      icon: 'warning',
      padding: '0 2rem 1rem',
      input: 'textarea',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: t('general.cancel', { ns: 'common' }) || '',
      confirmButtonText: t('general.send', { ns: 'common' }) || '',
    }).then(result => {
      if (result.isConfirmed) {
        const text = result.value;
        activeBudget(id, text);
        Swal.fire(
          t('general.good', { ns: 'validations' }) || '',
          t('budget.activeSuccess', { ns: 'validations' }) || '',
          'success',
        );
      }
    });
  };

  const handleAlertRegenerateBudget = async (id: number) => {
    await Swal.fire({
      text: t('budget.aprovved', { ns: 'validations' }) || '',
      icon: 'warning',
      padding: '0 2rem 1rem',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: t('no', { ns: 'common' }) || '',
      confirmButtonText: t('yes', { ns: 'common' }) || '',
    }).then(result => {
      if (result.isConfirmed) {
        handlePrint(id, false, true);
      } else {
        handlePrint(id);
      }
    });
  };

  const handleRegenerateBudget = async (id: number) => {
    if (row.status === 'approved') handleAlertRegenerateBudget(id);
    else {
      if (row.status === 'finished') handlePrint(id, false, false);
      else handlePrint(id);
    }
  };

  const handleGetDocuments = async (id: string) => {
    try {
      const docs = await getDocumentsByBudget(id);

      if (docs && docs.length) {
        let documentFoundedId: false | number = false;

        DOCUMENTS_ORDERED.some(type => {
          const result = docs.find(document => document.document_types.name === type);

          if (result) {
            documentFoundedId = result.id;
            return true;
          } else return false;
        });

        setHasAnyPDF(documentFoundedId);
      } else setHasAnyPDF(false);
    } catch (error: any) {
      setHasAnyPDF(false);
      console.error(error);
    }
  };

  // NOTE: ALL USE EFFECTS
  useEffect(() => {
    cachedVerifyHasComments().then(result => setHasComments(result));
  }, []);

  useEffect(() => {
    if (hasComments === true) setCommentsStatus('#56CA00');
    if (hasComments === false) setCommentsStatus('#df4848');
    if (hasComments === null || hasComments === undefined) setCommentsStatus('#ababab');
  }, [hasComments]);

  useEffect(() => {
    if (idBudget) handleGetDocuments(`${idBudget}`);
  }, [idBudget]);

  useEffect(() => {
    socket.on('newComment:success', () => cachedVerifyHasComments().then(result => setHasComments(result)));

    return () => {
      socket.off('newComment:success');
    };
  }, [idBudget]);

  useEffect(() => {
    socket.on('hasComments', idBudget => {
      if (idBudget === row.id) return setHasComments(true);
    });

    return () => {
      socket.off('hasComments');
    };
  }, []);

  return (
    <Fragment>
      <TableRow>
        <TableCell>
          <IconButton aria-label='expand row' size='small' onClick={handleClick}>
            {isRowSelected ? <RemoveIcon /> : <AddIcon />}
          </IconButton>
        </TableCell>

        <TableCell align='left' component='th' scope='row' onClick={() => openPdf(row.id)}>
          <Typography noWrap title={row.customerName} sx={{ maxWidth: { xs: '14rem', lg: '16rem', xl: '18rem' } }}>
            {row.customerName}
          </Typography>
        </TableCell>

        <TableCell align='center'>{row.code}</TableCell>

        {permissions.includes('see_value') && <TableCell align='right'>{formatNumber(row.amountOfTotal)}</TableCell>}

        {permissions.includes('see_utility') && <TableCell align='right'>{formatNumber(row.utility || 0)}</TableCell>}

        <TableCell align='center'>
          <Chip
            size='medium'
            label={t(status?.label || '')}
            sx={{
              textTransform: 'capitalize',
              padding: '0.5rem',
              margin: '0 0.5rem',
              color: '#fff',
              background: colorsObject[status?.value || 'inactive'],
            }}
          />
        </TableCell>

        {/* ALL BUDGET ACTIONS */}
        <TableCell align='right'>
          <Box sx={{ minHeight: '4.2rem', display: 'flex', justifyContent: 'right', paddingRight: 3 }}>
            {/* SEE COMMENTS */}
            {permissions.includes('comments') && (
              <IconButton
                sx={{ color: commentsStatus }}
                title={`${t('general.comments')}`}
                onClick={() => toggleComments(row.id)}
              >
                <Comment />
              </IconButton>
            )}

            {/* EDIT BUTTON */}
            {hasPermissionToEdit() && (
              <IconButton
                title={`${t('general.edit')}`}
                name='general.edit'
                color='success'
                onClick={() => navigate('/budgets/edit/' + row.id)}
              >
                <NoteEditOutline fontSize='inherit' />
              </IconButton>
            )}

            {/* PRINT BUTTON */}
            {hasPermissionToPrint() && (
              <IconButton
                title={`${t('general.print')}`}
                name='general.edit'
                color='success'
                onClick={!row.hasPreviousDocument ? () => handleRegenerateBudget(row.id) : handlePrintButton}
              >
                <Printer fontSize='inherit' />
              </IconButton>
            )}
            {showPrintOptions && (
              <MenuOptions
                id={row.id}
                navigate={navigate}
                isSecondOptionDisabled={!row.hasPreviousDocument}
                menuOptionsAnchorEl={menuOptionsAnchorEl}
                setMenuOptionsAnchorEl={setMenuOptionsAnchorEl}
                onCallBackFirstButtonClick={() => handlePrint(row.id)}
                onCallBackSecondButtonClick={() => handlePrint(row.id, true)}
                firstOptionText={`${t('general.currentDocument')}`}
                secondOptionText={`${t('general.previousDocument')}`}
              />
            )}

            {/* SEND EMAIL BUTTON (ONLY FOR BUDGETS APPROVED) */}
            {permissions.includes('send') && !isCTIS && ['approved'].includes(row.status) && (
              <IconButton
                color='success'
                name='general.send'
                onClick={handleSendButton}
                sx={{ position: 'relative' }}
                title={`${t('general.send')}`}
                // disabled={row.createdBy !== idUser && row.idSeller !== idUser}
              >
                <EmailEdit fontSize='inherit' />
              </IconButton>
            )}

            {showSendOptions && (
              <MenuOptions
                id={row.id}
                navigate={navigate}
                isSecondOptionDisabled={!row.hasDocument}
                menuOptionsAnchorEl={menuOptionsAnchorEl}
                setMenuOptionsAnchorEl={setMenuOptionsAnchorEl}
                firstOptionText={`${t('title.quotation')}`}
                secondOptionText={`${t('general.documentTitle')}`}
                navigationFirstOptionText='/budgets/send'
                navigationSecondOptionText={`?action=sendBudget&lastRoute=${getLastRoute()}`}
                navigationThirdOptionPath='/budgets/send'
                navigationFourthOptionPath={`?action=sendDocument&lastRoute=${getLastRoute()}`}
              />
            )}

            {/* SEND EMAIL BUTTON (ONLY FOR BUDGETS CTIS APPROVED) */}
            {permissions.includes('send') && isCTIS && ['approved'].includes(row.status) && (
              <IconButton
                color='success'
                name='general.send'
                sx={{ position: 'relative' }}
                title={`${t('general.send')}`}
                onClick={() => navigate(`/budgets/send/${row.id}?action=sendBudget&lastRoute=${getLastRoute()}`)}
                // disabled={row.createdBy !== idUser && row.idSeller !== idUser}
              >
                <EmailEdit fontSize='inherit' />
              </IconButton>
            )}

            {/* SEND EMAIL BUTTON (BUDGETS) */}
            {permissions.includes('send') && ['active', 'toBeConfirmed', 'finished'].includes(row.status) && (
              <IconButton
                color='success'
                name='general.send'
                sx={{ position: 'relative' }}
                title={`${t('general.send')}`}
                onClick={() => navigate(`/budgets/send/${row.id}/?lastRoute=${getLastRoute()}`)}
                disabled={!row.hasDocument && validateExpDate(row.expirationDate)}
              >
                <EmailEdit fontSize='inherit' />
              </IconButton>
            )}

            {/* SEND EMAIL BUTTON (SUPPLIERS) */}
            {permissions.includes('send') && row.status === 'request' && (
              <IconButton
                color='info'
                name='general.edit'
                title={`${t('general.send')}`}
                onClick={() => navigate('/suppliers/send/' + row.id)}
              >
                <EmailEdit fontSize='inherit' />
              </IconButton>
            )}

            {showInvoiceOptions && (
              <MenuOptions
                id={row.id}
                onCallBackFirstButtonClick={() => idBudget && onHandlerButtonClick(idBudget, row.amountOfTotal, 'FF')}
                onCallBackSecondButtonClick={() => idBudget && onHandlerButtonClick(idBudget, row.amountOfTotal, 'CCF')}
                navigate={navigate}
                menuOptionsAnchorEl={menuOptionsAnchorEl}
                setMenuOptionsAnchorEl={setMenuOptionsAnchorEl}
                firstOptionText={`${t('general.ff')}`}
                secondOptionText={`${t('general.ccf')}`}
                navigationFirstOptionText='/invoices/ff'
                navigationThirdOptionPath='/invoices/creditfiscal'
              />
            )}

            {/* BUTTON TO BILL A BUDGET (ONLY FOR FF OR/AND CCF) */}
            {hasPermissionToBillSV() && !row.haveBill && (
              <IconButton
                onClick={handleInvoiceOrCreditButton}
                title={`${t('general.invoiceBtn')}`}
                // disabled={row.haveBill}
              >
                <ReceiptLongIcon />
              </IconButton>
            )}

            {/* BUTTON TO BILL A BUDGET (ONLY FOR USA) */}
            {(hasPermissionToBillUSA() || hasPermissionToBillCTIS()) && !row.haveBill && (
              <IconButton
                onClick={() => handleClickInvoiceUS(row.id)}
                title={`${t('general.invoiceBtn')}`}
                // disabled={row.haveBill}
              >
                <ReceiptLongIcon />
              </IconButton>
            )}
          </Box>
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse in={isRowSelected} timeout='auto' unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant='h6' gutterBottom component='div' align='center'>
                {t('general.details')}
              </Typography>

              {/* BUDGET DETAILS TABLE */}
              <Table stickyHeader aria-label='collapsible table'>
                <TableHead sx={{ backgroundColor: 'primary.main' }}>
                  <ColumHeader
                    headers={[
                      t('budgetPage.seller', { ns: 'common' }),
                      t('general.createdOn', { ns: 'common' }),
                      t('budgetPage.expDate', { ns: 'common' }),
                      [t('budgetPage.moreactions', { ns: 'common' }), 'right'],
                    ]}
                  />
                </TableHead>

                {/* SELLER ROW */}
                <TableBody>
                  <TableRow>
                    {/* NAME */}
                    <TableCell align='center'>{row.sellerName}</TableCell>

                    {/* CREATED AT */}
                    <TableCell align='center'>{format(new Date(row.createdAt), 'MM/dd/yyyy')}</TableCell>

                    {/* EXPIRATION DATE */}
                    <TableCell align='center'>{format(new Date(row.expirationDate), 'MM/dd/yyyy')}</TableCell>

                    {/* ACTIONS */}
                    <TableCell
                      align='right'
                      sx={{ minHeight: '4rem', display: 'flex', justifyContent: 'right', pr: 3 }}
                    >
                      {hasPermissionToConsult() && handleView && (
                        <IconButton
                          title={`${t('general.detailBtn')}`}
                          size='small'
                          color='info'
                          onClick={() => handleView(row.id)}
                        >
                          <Icon icon='fluent:document-table-search-24-regular' height={25} />
                        </IconButton>
                      )}

                      {permissions.includes('initialize') && cancelStatus && row.status === 'request' && (
                        <IconButton
                          color='warning'
                          title={`${t('general.initBudget')}`}
                          onClick={() => cancelStatus(row.id, 'initialized')}
                        >
                          <InputIcon />
                        </IconButton>
                      )}

                      {hasPermissionToCancel() && cancelStatus && (
                        <IconButton
                          title={`${t('general.cancel')}`}
                          name='general.edit'
                          color='error'
                          onClick={() => cancelStatus(row.id, 'cancel')}
                        >
                          <CloseCircleOutline fontSize='inherit' />
                        </IconButton>
                      )}

                      {permissions.includes('approve') && row.status === 'active' && (
                        <IconButton
                          name='general.edit'
                          title={`${t('general.approved')}`}
                          color={row.status !== 'active' ? 'secondary' : 'success'}
                          onClick={() => cancelStatus && cancelStatus(row.id, 'approved')}
                        >
                          <CheckCircleOutlineIcon fontSize='inherit' />
                        </IconButton>
                      )}

                      {permissions.includes('finalized') &&
                        row.status === 'approved' &&
                        hasPermissionFinalize(row.typeUser, row.haveBill) &&
                        cancelStatus && (
                          <IconButton
                            name='general.finished'
                            title={`${t('general.finished')}`}
                            onClick={() => cancelStatus(row.id, 'finished')}
                          >
                            <DoneAllIcon fontSize='inherit' />
                          </IconButton>
                          // eslint-disable-next-line indent
                        )}

                      {permissions.includes('chat_with_provider') && (
                        <IconButton
                          title={`${t('general.chatBtn')}`}
                          onClick={() => navigate('/suppliers/chat/' + row.id)}
                        >
                          <ChatIcon fontSize='inherit' />
                        </IconButton>
                      )}

                      {permissions.includes('cargo_documents') &&
                        ['approved', 'finished'].includes(row.status) &&
                        !isCTIS && (
                          <>
                            <IconButton onClick={handlePDFOptions} title={`${t('general.pdfBtn')}`}>
                              <PictureAsPdfIcon />
                            </IconButton>

                            {showPDFOptions && (
                              <MenuOptions
                                id={row.id}
                                navigate={navigate}
                                hasDocument={row.hasDocument}
                                menuOptionsAnchorEl={menuOptionsAnchorEl}
                                setMenuOptionsAnchorEl={setMenuOptionsAnchorEl}
                                firstOptionText={`${t('general.generate')}`}
                                secondOptionText={`${t('general.consult')}`}
                                onCallBackFirstButtonClick={() => navigate(`/budgets/guides/${row.id}`)}
                                onCallBackSecondButtonClick={() =>
                                  navigate(`/documents/print/${hasAnyPDF}?budgetId=${row.id}&backUrl=${getBackUrl()}`)
                                }
                                isSecondOptionDisabled={!hasAnyPDF}
                              />
                            )}
                          </>
                          // eslint-disable-next-line indent
                        )}

                      {!row.haveBill && permissions.includes('open_quote') && ['approved'].includes(row.status) && (
                        <IconButton
                          onClick={() => handleActiveBudget(row.id)}
                          title={`${t('budgetPage.requestOpenQuote')}`}
                        >
                          <SettingsBackupRestoreIcon />
                        </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  );
}
