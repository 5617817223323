import es from 'date-fns/locale/es';
import { useTranslation } from 'react-i18next';
import { registerLocale } from 'react-datepicker';
import i18n from '../../../../i18n';

// @MUI Components
import { Card, Box, Grid, Divider, InputLabel, Typography } from '@mui/material';

// @Hooks
import useServicesBudget from './useServicesBudget';

// @Utils
import { formatNumber } from '../../../../utils/format-number.util';

// @Components
import Row from './components/Row';
import ServiceSuppliersForm from './components/ServiceSuppliersForm';
import { DatePicker, ReactSelectForm } from '../../../../components';
import TextForm from '../../../../components/TextForm';
import ButtonForm from '../../../../components/ButtonForm';
import TableComponent from '../../../../components/TableContainer';
import FileUploadMultiple from '../../../../components/FileUploader/FileUploadMultiple';

registerLocale('es', es);

export default function ServicesBudget() {
  const {
    // NOTE: STATUS
    disabled,
    isEditing,
    serviceFormIsOpen,
    // NOTE: DATA
    budgets,
    services,
    service,
    shipments,
    countryItems,
    conutryItemsES,
    // NOTE: FORM DATA
    filesUploaded,
    control,
    onSubmit,
    // NOTE: ACTIONS
    totalCost,
    // NOTE: HANDLE FUNCTIONS
    handleAddService,
    handleEditService,
    handleRemoveService,
    handleToggleForm,
    handleGoBack,
  } = useServicesBudget();

  const { t } = useTranslation('common');

  return (
    <>
      <Grid container sx={{ width: '90%', ml: 'auto', mr: { xs: 0.25, md: 'auto' }, zIndex: 1 }}>
        <Card
          sx={{
            p: 4,
            width: '100%',
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          {!isEditing && (
            <Grid item xs={12} sx={{ width: '100%', zIndex: 5 }}>
              <InputLabel>{t('general.selectBudget')}</InputLabel>

              <ReactSelectForm
                label=''
                name='idBudget'
                items={budgets}
                control={control}
                disabled={services.length > 0}
              />
              <Typography variant='body1' sx={{ fontSize: '12px', marginTop: '2px', color: 'red' }}>
                {t('general.selectSupplierBudget')}
              </Typography>
            </Grid>
          )}

          <Divider sx={{ my: '1.5rem', width: '100%' }} />

          <Grid container spacing={5}>
            <Grid item xs={12}>
              <Typography variant='h5' align='center'>
                {t('general.details')}
              </Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
              <ReactSelectForm
                control={control}
                name='countryOrigin'
                label={t('general.origin')}
                items={i18n.language === 'en' ? countryItems : conutryItemsES}
                disabled={disabled}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <ReactSelectForm
                control={control}
                name='countryDestiny'
                label={t('general.destiny')}
                items={i18n.language === 'en' ? countryItems : conutryItemsES}
                disabled={disabled}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextForm
                type='number'
                control={control}
                name='transportTime'
                label={t('general.transitTime')}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextForm
                value=''
                control={control}
                name='quotationCode'
                label={t('general.invoice')}
                disabled={isEditing}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <DatePicker control={control} name='expDate' label={t('budgetPage.expDate')} minDate={new Date()} />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextForm control={control} name='via' label='Via' value='' />
            </Grid>

            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', width: { xs: 20 } }}>
              <FileUploadMultiple
                maxFiles={3}
                filesUploaded={filesUploaded}
                formatsAllowed={['.jpg', '.pdf', '.png']}
              />
            </Grid>
          </Grid>

          <Divider sx={{ my: '2.5rem', width: '100%' }} />

          <Grid container spacing={5}>
            <Grid
              item
              xs={12}
              sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, justifyContent: 'space-between' }}
            >
              <Typography variant='h6'>
                {t('general.total')}: {formatNumber(totalCost)}
              </Typography>

              <Box sx={{ display: 'flex', gap: 2 }}>
                <ButtonForm label={t('add.service')} onClick={() => handleToggleForm({ type: 'SE' })} />
                <ButtonForm label={t('add.management')} onClick={() => handleToggleForm({ type: 'MA' })} />
              </Box>
            </Grid>

            <Grid item xs={12}>
              <TableComponent
                Row={Row}
                data={services}
                headers={[
                  t('general.service'),
                  t('general.typeService'),
                  t('general.cost'),
                  t('general.priceWithCommission'),
                  t('general.typeSale'),
                  t('budgetPage.actions'),
                ]}
                props={{ handleRemoveService, handleView: handleToggleForm }}
              />
            </Grid>
          </Grid>

          <Divider sx={{ my: '1.5rem', width: '100%' }} />

          <Box
            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: 4, my: 2, mx: 'auto' }}
          >
            <ButtonForm size='large' color='error' label={t('general.back')} onClick={handleGoBack} />
            <ButtonForm size='large' label={t('general.save')} onClick={onSubmit} disabled={!(services.length > 0)} />
          </Box>
        </Card>
      </Grid>

      {/* Services suppliers form */}
      <ServiceSuppliersForm
        handleAddService={handleAddService}
        handleEditService={handleEditService}
        open={serviceFormIsOpen}
        toggle={handleToggleForm}
        service={service}
        shipments={shipments}
      />
    </>
  );
}
