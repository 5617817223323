/**
 * @Category Utils
 * @param {String} title The title to truncate.
 * @param {Number} maxLength Max length of the text.
 * @returns Return a text truncated with ellipsis at the end.
 */
export const truncateTitle = (title: string, maxLength: number) => {
  if (title.length <= maxLength) {
    return title;
  } else {
    return title.substring(0, maxLength) + '...';
  }
};
