import * as yup from 'yup';
import { GAManifiesto } from '../../../../../models';
export const initialValues: GAManifiesto = {
  arrival: '',
  invoice: '',
  date: '',
  nameshipper: '',
  addressshipper: '',
  mailshipper: '',
  phoneshipper: '',
  nameconsignee: '',
  addressconsignee: '',
  mailconsignee: '',
  phoneconsignee: '',
  origin: '',
  destination: '',
  content: [],
};

export const schemaValidation = yup
  .object()
  .shape({
    arrival: yup.string().required('required.general'),
    invoice: yup.string().required('required.general'),
    // shipper: yup.string().required('required.general'),
    // consignee: yup.string().required('required.general'),
    origin: yup.string().required('required.general'),
    destination: yup.string().required('required.general'),

    content: yup.array().of(
      yup.object().shape({
        hawb: yup.string().required('required.general'),
        carrier: yup.string().optional(),
        consignee: yup.string().required('required.general'),
        quantity: yup
          .number()
          .optional()
          .nullable()
          .transform(value => (isNaN(value) ? null : value)),
        weight: yup
          .number()
          .optional()
          .nullable()
          .transform(value => (isNaN(value) ? null : value)),
      }),
    ),
  })
  .required();
