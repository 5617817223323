import IChipItem from '../interfaces/chipItem';

/**
 * @Category Budget
 * @subcategory Utils
 *
 * @description
 * Gives an array of statusItems with the properties value, color and label.
 *
 * #### Example
 * ```
 * import statusItems from "../utils/statusItems.ts"
 *
 * statusItems.map(item => (item.value === "pending") && item);
 * ```
 *
 * @returns {IChipItem[]} An array with all the status items
 * @author CML Exports Front-End Developers
 */
const statusItems: IChipItem[] = [
  { value: 'all', color: 'secondary', label: 'general.all' },
  { value: 'active', color: 'success', label: 'general.active' },
  { value: 'pending', color: 'info', label: 'general.pending' },
  { value: 'cancel', color: 'error', label: 'general.cancelled' },
  { value: 'review', color: 'primary', label: 'general.review' },
  { value: 'request', color: 'warning', label: 'general.request' },
  { value: 'initialized', color: 'warning', label: 'general.initialized' },
  { value: 'approved', color: 'success', label: 'general.approved' },
  { value: 'rejected', color: 'error', label: 'general.rejected' },
  { value: 'toBeConfirmed', color: 'info', label: 'general.toBeConfirmed' },
  { value: 'toBeInvoiced', color: 'success', label: 'general.toBeInvoiced' },
  { value: 'finished', color: 'success', label: 'general.finished' },
];

export default statusItems;
