// @Imports
import { useEffect, useState } from 'react';
import { Controller, Control, FieldValues, FieldPath } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

// @Mui
import { Popper, styled } from '@mui/material';
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';
import TextField, { StandardTextFieldProps } from '@mui/material/TextField';
import Skeleton from '@mui/material/Skeleton';
import CircularProgress from '@mui/material/CircularProgress';

// @Utils
import i18n from '../i18n';

import { ISelectInput } from '../models';

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    maxHeight: 225,
    boxSizing: 'border-box',
    '& ul': { padding: 0, margin: 0 },
  },
});

// @Interfaces and Types
interface Props<T extends FieldValues> extends StandardTextFieldProps {
  name: FieldPath<T>;
  control: Control<T, any>;
  getInitialItems?: boolean;
  setInitialItem?: number | '';
  getItemsFunction: any;
  functionParams?: any;
  loading?: boolean;
  onExternalChange?: (value: any) => void;
}

const initialSelectOption: ISelectInput<number> = { label: '', labelEN: '', value: 0 };

/**
 * @component
 * @subcategory Global
 *
 * @description
 * A Select with searching filter.
 * #### Example
 * ```
 * return (
 *  <SelectWithSearch name="category" loading={isLoading} control={control} getInitialItems setInitialItem={3} getItemsFunction={fetchCategories} functionParams={{ typeOfCategories: "business" }} />
 * )
 * ```
 *
 * @property {FieldPath<T>} name A path in the form values.
 * @property {boolean} [loading] A boolean indicating whether the component is in a loading state.
 * @property {Control<T, any>} control An object from react-hook-form to control the form.
 * @property {boolean} [getInitialItems] A boolean indicating whether to get initial items.
 * @property {number | ""} [setInitialItem] A value to set as the initial selected item.
 * @property {function} getItemsFunction A function to fetch items.
 * @property {any} [functionParams] Parameters to pass to the getItemsFunction.
 * @property {function} [onExternalChange] A callback function to call when the selected item changes.
 * @property {object} props Additional props to pass to the underlying TextField component.
 *
 * @return {JSX.Element} Return a Select input with searching input and a list of items to pick up depending of the 'getItemsFunction' results and the filter of search.
 * @author CML Exports Front-End Developers
 */
export default function SelectWithSearch<T extends FieldValues>({
  name,
  loading,
  control,
  getInitialItems,
  setInitialItem,
  getItemsFunction,
  functionParams,
  onExternalChange,
  ...props
}: Props<T>) {
  const [spinner, setSpinner] = useState(false);
  const [lastFunctionParams, setLastFunctionParams] = useState<any>(undefined);
  const [options, setOptions] = useState<ISelectInput<number>[]>([]);
  const [selectOption, setSelectOption] = useState<ISelectInput<number>>(initialSelectOption);

  const { t } = useTranslation(['common']);

  const handleFilter = async (value: string) => {
    setSpinner(true);

    try {
      if (functionParams !== lastFunctionParams) {
        const getItems = await getItemsFunction(functionParams || value);

        if (getItems?.length && getItems instanceof Array) {
          const items: ISelectInput<number>[] = getItems.map(category => ({
            label: category.name,
            labelEN: category.nameEN,
            value: category.id,
          }));

          const newItems: ISelectInput<number>[] = [];

          for (const item of items) {
            newItems.push(item);
          }

          setOptions(newItems);
        } else setOptions([]);
      }
    } catch (error) {
      setOptions([]);
    } finally {
      setSpinner(false);
      if (functionParams) setLastFunctionParams(functionParams);
    }
  };

  useEffect(() => {
    if (getInitialItems) {
      getItemsFunction(functionParams || '').then((items: any) => {
        if (items) {
          setOptions(items.map((item: any) => ({ label: item.name, labelEN: item.nameEN, value: item.id })));
        }
      });
    }
  }, [getInitialItems, functionParams]);

  useEffect(() => {
    if (setInitialItem && options.length) {
      const initialOption = options.find(opt => opt.value === setInitialItem);
      initialOption && setSelectOption(initialOption);
    } else setSelectOption(initialSelectOption);
  }, [setInitialItem, options]);

  return (
    <>
      {!loading ? (
        <Controller
          control={control}
          name={name}
          render={({ field: { value, onChange, ...otherField }, fieldState: { error } }) => (
            <Autocomplete
              freeSolo
              openOnFocus
              disablePortal
              clearOnEscape
              disabled={options.length === 0}
              PopperComponent={StyledPopper}
              loading={spinner}
              options={options}
              getOptionLabel={opt =>
                typeof opt === 'string' ? opt : i18n.language === 'es' ? opt.label : opt.labelEN || opt.label
              }
              value={selectOption || ''}
              onInputChange={(_, value) => handleFilter(value)}
              onChange={(_, item: any) => {
                onChange(item);
                if (onExternalChange) onExternalChange(item);
                setSelectOption(item);
                handleFilter('');
              }}
              {...otherField}
              renderInput={params => (
                <TextField
                  {...params}
                  {...props}
                  error={Boolean(error?.message)}
                  helperText={(!options.length && 'No options') || t(error?.message || '')}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {spinner ? <CircularProgress color='inherit' size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
          )}
        />
      ) : (
        <Skeleton variant='rectangular' height={60} />
      )}
    </>
  );
}
