import * as yup from 'yup';
import { GAReservas } from '../../../../../models';

export const initialValues: GAReservas = {
  invoiceOperation: '',
  masterGuide: '',
  hawbGuide: '',
  nameshipper: '',
  addressshipper: '',
  mailshipper: '',
  phoneshipper: '',
  nameconsignee: '',
  addressconsignee: '',
  mailconsignee: '',
  phoneconsignee: '',
  shipmenDetails: [],
};

export const schemaValidation = yup
  .object()
  .shape({
    masterGuide: yup.string().required('required.general'),
    hawbGuide: yup.string().required('required.general'),
    // shipper: yup.string().required('required.general'),
    // consignee: yup.string().required('required.general'),
    shipmenDetails: yup.array().of(
      yup.object().shape({
        agentInOrigin: yup.string().required('required.general'),
        product: yup.string().required('required.general'),
        weight: yup.string().required('required.general'),
        destination: yup.string().required('required.general'),
        dimessions: yup.string().required('required.general'),
        numOfPieces: yup.number().typeError('required.general').required('required.general'),
        paymentDate: yup.string().required('required.general'),
        deadline: yup.string().required('required.general'),
        dateOfFlight: yup.string().required('required.general'),
      }),
    ),
  })
  .required();
