import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// @Hooks
import { useSuppliersForm } from './use-supplier-form.hook';

// @Utils
import { identificationTypeItems } from '../../../utils/select-items.util';

// @MUI Components
import { Alert, Snackbar, Card, Box, Button, Grid, CardHeader, CardContent } from '@mui/material';

// @Components
import {
  TextForm,
  SelectInput,
  MultipleTransportationFilter,
  MultipleServicesTypeFilter,
  CheckboxForm,
} from '../../../components';

export const SuppliersForm = () => {
  const { t } = useTranslation(['common']);

  const {
    control,
    // errors,
    onSubmit,
    countries,
    emailAppend,
    phoneAppend,
    countryAppend,
    emailFields,
    phoneFields,
    countryFields,
    emailRemove,
    phoneRemove,
    countryRemove,
    handleClose,
    alert,
    onUpdate,
    isUpdate,
    categories,
    serviceTypes,
    maxLength,
    permissions,
  } = useSuppliersForm();

  const navigate = useNavigate();

  return (
    <Grid container sx={{ width: '90%', ml: 'auto', mr: { xs: 0.25, md: 'auto' }, zIndex: 1 }}>
      <Snackbar
        open={alert.open}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={handleClose} severity={alert.type === 'success' ? 'success' : 'error'} sx={{ width: '100%' }}>
          {alert.content}
        </Alert>
      </Snackbar>

      <Card sx={{ width: '100%' }}>
        <CardHeader
          sx={{ mb: 2, display: 'flex', alignItems: 'center', justifyContent: 'flex-end', textAlign: 'center' }}
          title={isUpdate ? t('edit.suppliers', { ns: 'common' }) : t('add.supplier', { ns: 'common' })}
        />

        <CardContent>
          <Box>
            <form onSubmit={isUpdate ? onUpdate : onSubmit}>
              <Grid container spacing={3} sx={{ display: 'flex' }}>
                <Grid item xs={12}>
                  <TextForm name='name' control={control} label={t('general.fiscalName', { ns: 'common' })} />
                </Grid>

                <Grid item xs={12}>
                  <TextForm
                    name='comercialName'
                    control={control}
                    label={t('general.comercialName', { ns: 'common' })}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <SelectInput
                    name='identificationType'
                    control={control}
                    label={t('general.identifyType', { ns: 'common' })}
                    items={identificationTypeItems}
                  />
                </Grid>

                <Grid item xs={12} sm={8}>
                  <TextForm
                    name='taxIdentification'
                    control={control}
                    label={t('general.identificationNumber', { ns: 'common' })}
                  />
                </Grid>

                <Grid item xs={12}>
                  {emailFields.map((field, index) => {
                    return (
                      <Box sx={{ display: 'legacy' }} key={field.id}>
                        <Grid sx={{ mt: 2 }} item xs={12}>
                          <TextForm
                            name={`email.${index}.value`}
                            control={control}
                            label={t('general.email', { ns: 'common' })}
                            InputProps={{
                              endAdornment:
                                index > 0 ? (
                                  <Button type='button' onClick={() => emailRemove(index)}>
                                    <i className='fa-solid fa-square-minus text-xl text-red-500' />
                                  </Button>
                                ) : null,
                            }}
                          />
                        </Grid>
                      </Box>
                    );
                  })}

                  <Button
                    type='button'
                    onClick={() => {
                      emailAppend({
                        value: '',
                      });
                    }}
                  >
                    <i className='fa-solid fa-circle-plus text-xl text-green-500 mr-2' />
                    <p>{t('add.email', { ns: 'common' })}</p>
                  </Button>
                </Grid>

                <Grid item xs={12}>
                  {phoneFields.map((field, index) => {
                    return (
                      <Box key={field.id}>
                        <Grid sx={{ mt: 2 }} item xs={12}>
                          <TextForm
                            name={`phone.${index}.value`}
                            control={control}
                            label={t('general.phone', { ns: 'common' })}
                            type='number'
                            InputProps={{
                              endAdornment:
                                index > 0 ? (
                                  <Button type='button' onClick={() => phoneRemove(index)}>
                                    <i className='fa-solid fa-square-minus text-xl text-red-500' />
                                  </Button>
                                ) : null,
                            }}
                          />
                        </Grid>
                      </Box>
                    );
                  })}
                  <Button
                    type='button'
                    onClick={() => {
                      phoneAppend({
                        value: '',
                      });
                    }}
                  >
                    <i className='fa-solid fa-circle-plus text-xl text-green-500 mr-2' />
                    <p>{t('add.phone', { ns: 'common' })}</p>
                  </Button>
                </Grid>

                <Grid item xs={12}>
                  {countryFields.map((field, index) => {
                    return (
                      <Box key={field.id}>
                        <Grid sx={{ mt: 2 }} item xs={12}>
                          <SelectInput
                            name={`country.${index}.value`}
                            control={control}
                            label={t('general.country', { ns: 'common' })}
                            items={countries}
                          />
                        </Grid>
                        <Grid sx={{ mt: 2 }} item xs={12}>
                          <TextForm
                            name={`address.${index}.value`}
                            control={control}
                            label={t('general.address', { ns: 'common' })}
                            InputProps={{
                              endAdornment:
                                index > 0 ? (
                                  <Button type='button' onClick={() => countryRemove(index)}>
                                    <i className='fa-solid fa-square-minus text-xl text-red-500' />
                                  </Button>
                                ) : null,
                            }}
                          />
                        </Grid>
                      </Box>
                    );
                  })}
                  <Button
                    type='button'
                    onClick={() => {
                      countryAppend({
                        value: '',
                      });
                    }}
                  >
                    <i className='fa-solid fa-circle-plus text-xl text-green-500 mr-2' />
                    <p>{t('add.address', { ns: 'common' })}</p>
                  </Button>
                </Grid>

                <Grid item xs={12}>
                  <MultipleTransportationFilter
                    control={control}
                    name='categories'
                    label={t('general.category', { ns: 'common' })}
                    watcherValue={categories.map(category => category.value)}
                  />
                </Grid>

                <Grid item xs={12}>
                  <MultipleServicesTypeFilter
                    control={control}
                    name='serviceTypes'
                    watcherValue={[]}
                    label={t('services.type', { ns: 'common' })}
                    categoriesId={categories.map(category => category.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextForm
                    name='notes'
                    control={control}
                    label={t('general.notes', { ns: 'common' })}
                    multiline
                    rows={4.2}
                    inputProps={{ maxLength }}
                  />
                </Grid>
                {permissions?.edit_public_suppliers && (
                  <Grid item xs={5} sx={{ margin: 'auto 0' }}>
                    <CheckboxForm control={control} name='isPublic' label={t('general.makeItPublic')} size='medium' />
                  </Grid>
                )}
              </Grid>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button sx={{ mr: 2, mt: 4 }} variant='contained' color='error' onClick={() => navigate('/suppliers')}>
                  {t('general.cancel', { ns: 'common' })}
                </Button>
                <Button sx={{ ml: 2, mt: 4 }} variant='contained' type='submit'>
                  {t('general.save', { ns: 'common' })}
                </Button>
              </Box>
            </form>
          </Box>
        </CardContent>
      </Card>
    </Grid>
  );
};
