import { createSlice, PayloadAction, AnyAction, AsyncThunk } from '@reduxjs/toolkit';

// @Actions
import { getCountries } from './actions-async';

// @Models
import {
  Loading,
  IApiErrorProps,
  ICountryModel,
  TCountryCustomLabel,
  ICountryRequest,
  ISelectInput,
} from '../../../models';

// @AsyncThunk
type GenericAsyncThunk = AsyncThunk<unknown, unknown, any>;
type PendingAction = ReturnType<GenericAsyncThunk['pending']>;
type RejectedAction = ReturnType<GenericAsyncThunk['rejected']>;

interface ICountriesInitialState {
  loading: Loading;
  errors: IApiErrorProps;
  countries: ICountryModel[];
  customLabel: TCountryCustomLabel;
  countryItems: ISelectInput<string>[];
  conutryItemsES: ISelectInput<string>[];
}

const isLoaderAction = (action: AnyAction): action is PendingAction => {
  return action.type.startsWith('user/') && action.type.endsWith('/pending');
};

const LOADING_INIT: Loading = {
  show: false,
  type: 'screen',
};

const initialState: ICountriesInitialState = {
  loading: LOADING_INIT,
  errors: {
    error: false,
    text: [],
  },
  countries: [],
  customLabel: [],
  countryItems: [],
  conutryItemsES: [],
};

export const countriesSlice = createSlice({
  name: 'countries',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getCountries.fulfilled, (state, action: PayloadAction<ICountryRequest[]>) => {
      state.countries = action.payload.map(country => {
        return {
          id: country.id,
          name: country.name,
          alpha2Code: country.alpha2Code,
          nameEn: country.name_en,
          transName: country.trans_name,
          taxation: country.taxation,
        };
      });

      const countryItemsEN: ISelectInput<string>[] = [];
      const conutryItemsES: ISelectInput<string>[] = [];
      action.payload.forEach(country => {
        if (country.status === 1) {
          countryItemsEN.push({
            value: country.alpha2Code,
            label: country.name_en,
          });

          conutryItemsES.push({
            value: country.alpha2Code,
            label: country.name,
          });
        }
      });

      state.customLabel = countryItemsEN.map(country => country.value);
      state.countryItems = countryItemsEN;
      state.conutryItemsES = conutryItemsES;
    });
    // Matcher para saber cuando una petición isLoading
    builder.addMatcher(isLoaderAction, state => {
      state.errors = {
        text: [],
        error: false,
      };

      state.loading = {
        show: true,
        type: 'screen',
      };
    });
    // Matcher para saber cuando una petición es rejected
    builder.addMatcher(
      (action): action is RejectedAction => action.type.startsWith('user/') && action.type.endsWith('/rejected'),
      state => {
        state.loading = LOADING_INIT;
        state.errors = {
          text: ['Ocurrió un error al procesar tu solicitud'],
          error: true,
        };
      },
    );
  },
});

export default countriesSlice.reducer;
