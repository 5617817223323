// Se usa Object.freeze para que el objeto no pueda ser modificado
// https://developer.mozilla.org/es/docs/Web/JavaScript/Referencia/Objetos_globales/Object/freeze

export const DOCUMENT_TYPE_OBJ = Object.freeze({
  AIR_GUIDE: 'air-guide',
  GA_MANIFIESTO: 'ga-manifiesto',
  GA_RESERVAS: 'ga-reservas',
  BL: 'bl',
  BOOKING: 'booking',
  CARTA_PORTE: 'carta-porte',
  MANIFIESTO: 'manifiesto',
  MANIFIESTO_MARITIMO: 'manifiesto-maritimo',
});

export const DOCUMENTS_ORDERED = [
  'carta-porte',
  'manifiesto',
  'booking',
  'bl',
  'manifiesto-maritimo',
  'air-guide',
  'ga-reservas',
  'ga-manifiesto',
];
