import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
// @Models
import { ILoginProps } from '../../models';
// @Redux
import { getLogin, getLoginToken } from '../../redux/slices/user/ActionsAsync';
import { useAppDispatch } from '../../redux/store';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/reducers';
// import { logginCRM } from '../../redux/slices/user';
// @Translations
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormHelperText from '@mui/material/FormHelperText';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import EyeOutline from 'mdi-material-ui/EyeOutline';
import EyeOffOutline from 'mdi-material-ui/EyeOffOutline';
import MuiCard, { CardProps } from '@mui/material/Card';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { CardContent, CircularProgress } from '@mui/material';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { useNavigate, useSearchParams } from 'react-router-dom';
import { SystemUtils } from '../../utils';

const DefaultValues = {
  email: 'admin@cmlexports.com',
  password: 'Abc.123',
};

const schema = yup.object().shape({
  email: yup.string().email().required('errors.require.Email'),
  password: yup.string().min(5).required('errors.require.Password'),
});

const Card = styled(MuiCard)<CardProps>(({ theme }) => ({
  [theme.breakpoints.up('sm')]: { width: '28rem' },
}));

export const LoginPage = () => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const { user } = useSelector((state: RootState) => state);
  const { t } = useTranslation(['common']);
  const dispatch = useAppDispatch();
  const token = searchParams.get('token');
  const navigate = useNavigate();

  useEffect(() => {
    if (user.user) {
      navigate('/budgets');
    } else {
      if (token) {
        dispatch(getLoginToken({ token }));
      } else {
        if (SystemUtils.isDevelop()) return;
        window.location.href = process.env.REACT_APP_AUTH_FRONT || '';
      }
    }
  }, []);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<ILoginProps>({ defaultValues: DefaultValues, resolver: yupResolver(schema) });

  const onSubmit = ({ email, password }: ILoginProps) => {
    dispatch(getLogin({ email, password }));
    // eslint-disable-next-line spaced-comment
    //dispatch(logginCRM({ loginCRM: false }));
  };

  return (
    <Box
      sx={{
        display: 'flex',
        minHeight: '100vh',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 2,
      }}
    >
      <Card sx={{ zIndex: 1 }}>
        <CardContent sx={{ padding: 6, margin: 'auto' }}>
          <Box sx={{ mb: 8, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Box component='img' sx={{ width: 120 }} alt='cml' src={'/assets/400x246-CML-Exports.png'} />
          </Box>
          <Box>
            {user.loading.show && (
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <CircularProgress />
              </Box>
            )}
          </Box>
          {SystemUtils.isDevelop() && (
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormControl fullWidth sx={{ mb: 4 }}>
                <Controller
                  name='email'
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { value, onChange, onBlur } }) => (
                    <TextField
                      autoFocus
                      label='Email'
                      value={value}
                      onBlur={onBlur}
                      onChange={onChange}
                      error={Boolean(errors.email)}
                      placeholder='admin@materio.com'
                    />
                  )}
                />
                {errors.email && (
                  <FormHelperText sx={{ color: 'error.main' }}>{t(`${errors.email.message}`)}</FormHelperText>
                )}
              </FormControl>
              <FormControl fullWidth>
                <InputLabel htmlFor='auth-login-v2-password' error={Boolean(errors.password)}>
                  Password
                </InputLabel>
                <Controller
                  name='password'
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { value, onChange, onBlur } }) => (
                    <OutlinedInput
                      value={value}
                      onBlur={onBlur}
                      label='Password'
                      onChange={onChange}
                      id='auth-login-v2-password'
                      error={Boolean(errors.password)}
                      type={showPassword ? 'text' : 'password'}
                      endAdornment={
                        <InputAdornment position='end'>
                          <IconButton
                            edge='end'
                            onMouseDown={e => e.preventDefault()}
                            onClick={() => setShowPassword(!showPassword)}
                          >
                            {showPassword ? <EyeOutline /> : <EyeOffOutline />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  )}
                />
                {errors.password && (
                  <FormHelperText sx={{ color: 'error.main' }} id=''>
                    {t(`${errors.password.message}`)}
                  </FormHelperText>
                )}
              </FormControl>
              <Button fullWidth size='large' type='submit' variant='contained' sx={{ mt: 5, marginBottom: 7 }}>
                Login
              </Button>
            </form>
          )}
        </CardContent>
      </Card>
    </Box>
  );
};
