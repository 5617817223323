// @Hooks
import { useServiceStep } from './use-service-step.hook';

// @Views
import { ServiceTable } from './components/service-table.component';
import { FormActions } from '../form-actions/form-actions.view';
import { ServiceForm } from '../service-form/service-form.view';
import { Card, CardContent, Box } from '@mui/material';

export function ServiceStep() {
  const {
    services,
    renderForm,
    handleRenderForm,
    handleAddService,
    handleEditService,
    handleDeleteService,
    ivatotal,
    total,
    handleCreateBudget,
    service,
    subTotal,
    budgetType,
  } = useServiceStep();

  return (
    <Card sx={{ width: '100%', p: 2 }}>
      <CardContent>
        {renderForm ? (
          <ServiceForm
            addService={handleAddService}
            editService={handleEditService}
            service={service}
            servicesCount={services.length + 1}
            previousService={services[0]}
            handleRenderForm={handleRenderForm}
            budgetType={budgetType}
          />
        ) : (
          <>
            <ServiceTable
              services={services}
              subTotal={subTotal}
              ivaTotal={ivatotal}
              total={total}
              deleteService={handleDeleteService}
              renderForm={handleRenderForm}
            />
            <FormActions nextCallback={handleCreateBudget} />
          </>
        )}
      </CardContent>
    </Card>
  );
}
