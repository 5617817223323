import { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

// @MUI Components
import { Typography } from '@mui/material';

// @Icons
import FileDocumentOutline from 'mdi-material-ui/FileDocumentOutline';

// @Utils
import CustomSwalAlert from '../../../utils/CustomSwalAlert';
import { getFileFormat } from '../../../utils/getFileFormat';

// @Interfaces and types
import { CustomFiles, FileUploaded, modelsType } from '../../../models/CustomFiles';

/**
 * @category Hooks
 * @subcategory CustomHooks
 *
 * @description
 * A custom hook for handling file uploads with React.
 * #### Example
 * ```
 * const fileUploadData = useFileUpload(filesUploaded, 3, [".jpg", ".png"])
 * ```
 *
 * @property {FileUploaded[]} filesUploaded - An array of uploaded files.
 * @property {number} maxFiles - The maximum number of files allowed.
 * @property {string[]} [formatsAllowed=['.jpg', '.gif', '.png', '.webp', '.pdf']] - The allowed file formats.
 *
 * @returns {Object} An object containing the status, actions, and handle functions for file uploads.
 */
export default function useFileUpload(
  filesUploaded: FileUploaded[],
  maxFiles: number,
  formatsAllowed: string[] = ['.jpg', '.gif', '.png', '.webp', '.pdf'],
) {
  const [haveFiles, setHaveFiles] = useState(false);

  const { t } = useTranslation('common');

  // NOTE: ALL ACTIONS
  // SUCCESS: VERIFY IF EXIST ANY FILE
  const verifyHaveFiles = () => {
    if (filesUploaded.length) {
      const files = filesUploaded.map(data => data.file).filter(file => file);
      files.length ? setHaveFiles(true) : setHaveFiles(false);
    } else setHaveFiles(false);
  };

  // SUCCESS: VERIFY FILE FORMAT
  const verifyFileFormat = (file: File) => {
    const format = getFileFormat({ mimetype: file.type, filename: file.name });

    if (format && formatsAllowed.includes(format)) return file;
    else {
      return CustomSwalAlert(
        t('uploader.errors.title'),
        `${t('uploader.errors.formatNotAllowed')} "${formatsAllowed.join(' - ')}"`,
        'error',
        false,
      );
    }
  };

  // SUCCESS: DROPZONE FUNCTION
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (filesDropped: File[]) => {
      if (filesDropped.length > maxFiles) {
        return CustomSwalAlert(
          t('uploader.errors.title'),
          `${t('uploader.errors.maxFiles')} ${maxFiles}`,
          'error',
          false,
        );
      }

      let i = 0;
      while (i < maxFiles) {
        if (!filesUploaded[i].file) {
          const fileAllowed = verifyFileFormat(filesDropped[0]);

          if (fileAllowed) {
            filesUploaded[i].setFile(fileAllowed);
            filesDropped.shift();
          }
        }

        i++;
      }
    },
  });

  // SUCCESS: GET FILE NAME
  const getFileName = (file: CustomFiles) => {
    if (file) return file instanceof File ? file.name : file.fileName;
  };

  // SUCCESS: GET FILE TOTAL SIZE
  const getFileSize = (file: CustomFiles) => {
    if (file instanceof File) {
      return Math.round(file.size / 100) / 10 > 1000
        ? `${(Math.round(file.size / 100) / 10000).toFixed(1)} mb`
        : `${(Math.round(file.size / 100) / 10).toFixed(1)} kb`;
    }
  };

  // SUCCESS: GET ENDPOINT FOR PREVIEW FILE
  const getPreviewFileViewParams = (params?: { id?: string | number; model?: modelsType }) => {
    if (!params || (!params?.id && !params?.model)) return {};

    const { id, model } = params;

    return { href: `/filesView/${id}?model=${model}` };
  };

  // SUCCESS: GET PREVIEW ICON OR IMAGE
  const getPreviewFile = (file: CustomFiles, params?: { id?: string | number; model?: modelsType }) => {
    if (file instanceof File) {
      return file.type.includes('pdf') ? (
        <FileDocumentOutline sx={{ width: 100, height: 80 }} />
      ) : (
        <img width={'100%'} height={'100%'} src={URL.createObjectURL(file as any)} alt={file.name} />
      );
    } else {
      return file?.fileName.includes('pdf') ? (
        <Typography
          component={'a'}
          {...getPreviewFileViewParams(params)}
          sx={{ cursor: 'pointer', color: '#6d6d6d', ':hover': { color: '#313131' }, transition: '300ms ease color' }}
        >
          <FileDocumentOutline sx={{ width: 100, height: 80 }} />
        </Typography>
      ) : (
        <img width={'100%'} height={'100%'} src={file?.src} />
      );
    }
  };

  // NOTE: ALL HANDLE FUNCTIONS
  const handleRemoveFile = (setFile: FileUploaded['setFile']) => setFile(null);

  // NOTE: ALL USE EFFECTS

  useEffect(() => {
    verifyHaveFiles();
  }, [filesUploaded]);

  return {
    // NOTE: STATUS
    haveFiles,
    // NOTE: ACTIONS
    getRootProps,
    getInputProps,
    getFileName,
    getFileSize,
    getPreviewFile,
    // NOTE: HANDLE FUNCTIONS
    handleRemoveFile,
  };
}
