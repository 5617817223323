// @Library
import { useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment'; // deprecated
import toast from 'react-hot-toast';

// @Translations
import { useTranslation } from 'react-i18next';
// @Redux
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/reducers';
// @Models
import {
  IServicesOfSuppliers,
  IShipmentForm,
  IServiceBudgetForm,
  IAddServiceInBudget,
  IShipment,
} from '../../../models';

// @Utils
import { axiosEstimates } from '../../../utils/api';
import { useItems } from './utils/items';
import { initialValues, schemaValidation } from './utils/schema-validation';

interface IParams {
  addService: (service: IShipmentForm) => void;
  editService: (service: IShipmentForm) => void;
  service?: IShipmentForm;
  previousService?: IShipment;
  customerCommission?: number;
  budgetType: number;
}

export function useServiceForm({ addService, editService, service, previousService, customerCommission }: IParams) {
  // el dispatch(getTransportations()) se dispara en el hook del componente de pagina
  const {
    transportation: { transportationItemsES, transportationItemsEN },
    services: { serviceTypesItemsES, serviceTypesItemsEN },
    countries: { countryItems, countries, conutryItemsES },
    budgetForm: {
      sellerForm,
      customerForm: { commission },
    },
    user: { role },
  } = useSelector((state: RootState) => state);

  // @State
  const [suppliers, setSuppliers] = useState<IServicesOfSuppliers[]>([]);
  const [services, setServices] = useState<IServiceBudgetForm[]>([]);
  const [rowId, setRowId] = useState<number | null>(null);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [selectService, setSelectService] = useState<IAddServiceInBudget | undefined>();
  const [selectedServices, setSelectedServices] = useState<IAddServiceInBudget[] | undefined>();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);

  // @Utils
  const { t } = useTranslation(['common']);
  const { typeUnit } = useItems();

  const {
    control,
    setValue,
    handleSubmit,
    getValues,
    // formState: { errors },
  } = useForm<IShipmentForm>({
    defaultValues: service || initialValues,
    resolver: yupResolver(schemaValidation),
  });

  const transportationId = useWatch({
    control,
    name: 'transportationId',
  });

  const serviceTypeId = useWatch({
    control,
    name: 'serviceType',
  });

  const origin = useWatch({
    control,
    name: 'origin',
  });

  const destiny = useWatch({
    control,
    name: 'destiny',
  });

  const taxationTo = useWatch({
    control,
    name: 'taxationTo',
  });

  const getSuppliers = async () => {
    try {
      const body = {
        origin,
        destiny,
        commission: customerCommission || commission,
        transportationId,
        serviceTypeId,
      };
      const response = await axiosEstimates.post<IServicesOfSuppliers[]>('services-of-suppliers', body, {
        headers: {
          'x-module': 'services',
        },
      });

      setSuppliers(response.data);
    } catch (error) {
      setSuppliers([]);
    }
  };
  // funcionalidad aun no implementada
  // const getServices = async (id: number) => {
  //   try {
  //     const response = await axiosEstimates.get<IServicesOfSuppliers[]>('services-of-suppliers/' + id);

  //     setSuppliers(response.data);
  //   } catch (error) {
  //     setSuppliers([]);
  //   }
  // };

  useEffect(() => {
    if (origin !== '' && destiny !== '') {
      getSuppliers();
    }
  }, [origin, destiny, transportationId, serviceTypeId]);

  useEffect(() => {
    if (!service) {
      setValue('taxationTo', sellerForm?.country || '');
    }
    setValue('origin', previousService?.origin || '');
    setValue('destiny', previousService?.destiny || '');
    setValue('addressOrigin', previousService?.addressOrigin || '');
    setValue('addressDestiny', previousService?.addressDestiny || '');
    setServices(getValues('services'));
  }, []);

  const resetFilters = () => {
    setValue('transportationId', null);
    setValue('serviceType', null);
  };

  const handleSelectSupplier = (service: IServicesOfSuppliers) => {
    const startDate = moment(new Date());
    const endDate = moment(service.expDate);
    const diffDays = endDate.diff(startDate, 'days');
    if (diffDays < 0) {
      toast.error(t('errors.serviceExpired'));
      return null;
    }

    const country = countries.find(country => country.alpha2Code === taxationTo);

    setRowId(service.id);
    setOpen(true);
    setIsEdit(false);
    setSelectedServices(undefined);
    setSelectService({
      ivaCharge: service.ivaCharge,
      serviceId: service.id,
      serviceName: service.service,
      serviceNameEN: service.serviceEN || '',
      priceCost: service.cost,
      serviceAmount: 1,
      typeSale: service.saleType,
      type: service.type,
      amountOfIva: 0,
      amountOfService: 0,
      price: 0,
      commission: service.comission,
      priceCostWithoutCommission: service.priceCostWithoutVat,
      countryTaxation: country?.taxation || 0,
      isCrating: false,
    });
  };

  const handleSelectService = (service: IAddServiceInBudget, isEdit?: boolean) => {
    setRowId(service.id || null);
    setIsEdit(isEdit || false);
    setOpen(true);
    const country = countries.find(country => country.alpha2Code === taxationTo);

    if (country) {
      service.countryTaxation = service.countryTaxation === 0 ? country?.taxation : service.countryTaxation;
    }
    setSelectService(service);
  };

  const calculateCrating = () => {
    const unit = getValues('unitOfMeasurement');

    const inlb = typeUnit.find(type => type.value === unit);
    const length = getValues('length') + 4;
    const high = getValues('high') + 4;
    const width = getValues('width') + 4;
    if (inlb) {
      const area = (length * high * width) / inlb.scaleArea;
      return Math.ceil(area);
    }

    return 0;
  };

  const handleAddServices = (data: IAddServiceInBudget) => {
    if (data.serviceId) {
      const service: IServiceBudgetForm = {
        id: services.length + 1,
        serviceId: data.serviceId,
        serviceName: data.serviceName,
        serviceNameEN: data.serviceNameEN || '',
        amountOfIva: data.amountOfIva,
        serviceAmount: data.serviceAmount,
        amountOfService: data.amountOfService,
        price: data.price,
        priceCost: data.priceCost,
        ivaCharge: data.ivaCharge,
        commission: data.commission,
        typeSale: data.typeSale,
        type: data.type,
        // country: data.country || '',
        taxation: data.countryTaxation,
        priceCostWithoutVat: data.priceCostWithoutCommission,
        isCrating: data.isCrating,
      };

      setServices([...services, service]);

      // Reset
      setRowId(null);
      setSelectService(undefined);
      setOpen(false);
    }
  };

  const handleEditServices = (data: IAddServiceInBudget) => {
    if (data.id) {
      const service: IServiceBudgetForm = {
        id: data.id,
        serviceId: data.serviceId,
        type: data.type,
        serviceName: data.serviceName,
        serviceNameEN: data.serviceNameEN || '',
        amountOfIva: data.amountOfIva,
        serviceAmount: data.serviceAmount,
        amountOfService: data.amountOfService,
        price: data.price,
        priceCost: data.priceCost,
        ivaCharge: data.ivaCharge,
        commission: data.commission,
        typeSale: data.typeSale,
        // country: data.country || '',
        taxation: data.countryTaxation,
        priceCostWithoutVat: data.priceCostWithoutCommission,
        isCrating: data.isCrating,
      };

      setServices(services => {
        return services.map(serv => {
          if (serv.id === service.id) return service;

          return serv;
        });
      });

      // Reset
      setRowId(null);
      setSelectService(undefined);
      setOpen(false);
    }
  };

  const handleDeleteService = (id: number) => {
    const newServices = services.filter(service => service.id !== id);
    setServices(
      newServices.map((service, index) => {
        return Object.assign(service, { id: index + 1 });
      }),
    );
  };

  const onSubmit = (data: IShipmentForm) => {
    if (!service) {
      addService(Object.assign(data, { services }));
    } else {
      editService(Object.assign(data, { services }));
    }
  };

  const onError = (errors: any) => {
    for (const key in errors) {
      toast.error(t(`errors.require.${key}`));
    }
  };
  const selectRows = (selectionArray: number[]) => {
    setSelectedRows(selectionArray);
    const servicesOfSuppliers = suppliers.filter(supplier => selectionArray.includes(supplier.id));

    const country = countries.find(country => country.alpha2Code === taxationTo);
    const countryIva = country?.taxation || 0;

    const selectedServices: IAddServiceInBudget[] = servicesOfSuppliers.map(service => ({
      ivaCharge: service.ivaCharge,
      serviceId: service.id,
      serviceName: service.service,
      serviceNameEN: service.serviceEN || '',
      priceCost: service.cost,
      serviceAmount: 1,
      type: service.type,
      typeSale: service.saleType,
      amountOfIva: service.ivaCharge ? service.cost * 1 * (countryIva / 100) : 0,
      amountOfService: service.cost * 1 + (service.ivaCharge ? service.cost * 1 * (countryIva / 100) : 0),
      price: service.cost * 1,
      countryTaxation: country?.taxation || 0,
      priceCostWithoutCommission: service.priceCostWithoutVat,
      commission: service.comission,
      isCrating: false,
    }));

    setSelectedServices(selectedServices);
  };

  const handleAddSelectServices = () => {
    if (selectedServices) {
      const addService = selectedServices.map((service, index) => ({
        ...service,
        id: services.length + index + 1,
        taxation: service.countryTaxation,
        priceCostWithoutVat: service.priceCostWithoutCommission,
      }));
      setServices([...services, ...addService]);
      setSelectedRows([]);
    }
  };

  return {
    control,
    serviceTypesItemsEN,
    serviceTypesItemsES,
    transportationItemsES,
    transportationItemsEN,
    onSubmit: handleSubmit(onSubmit, onError),
    t,
    countryItems,
    suppliers,
    handleSelectSupplier,
    rowId,
    services,
    handleAddServices,
    handleDeleteService,
    countries,
    origin,
    destiny,
    selectService,
    setValue,
    selectedRows,
    selectRows,
    handleAddSelectServices,
    handleEditServices,
    role,
    isEdit,
    handleSelectService,
    open,
    setOpen,
    resetFilters,
    typeUnit: getValues('unitOfMeasurement'),
    calculateCrating,
    conutryItemsES,
  };
}
