import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useState, useEffect, useCallback } from 'react';

// @MUI Components
import { Box, Grid, Skeleton } from '@mui/material';

// @Components
import { ButtonForm, EditorWrapper, TymEditor } from '../../../../components';

// @Services
import { getContent, sendEmails } from '../services';

interface Props {
  open: boolean;
  toggle(): void;
  selectedShipments: number[];
  emailTo: string[];
  idBudget: string;
  navigate: (path: string) => void;
}

export function SendModal({ emailTo, open, selectedShipments, toggle, idBudget, navigate }: Props) {
  const [editorState, setEditorState] = useState({ content: '' });
  const [isLoadingContent, setIsLoadingContent] = useState(true);

  const editor = { content: '' };

  const { t } = useTranslation(['common']);

  const handleFetch = async () => {
    try {
      if (open) {
        const content = await getContent(selectedShipments, idBudget);
        editor.content = content;
        setEditorState({ content });
      }
    } catch (error) {
      setEditorState(editor);
      setIsLoadingContent(false);
    } finally {
      setIsLoadingContent(false);
    }
  };

  const handleEditorChange = useCallback((value: string) => {
    editor.content = value;
    setEditorState(editor);
  }, []);

  const handleSend = async () => {
    const toastId = toast.loading(t('toast.loading'));

    const content = editorState.content;
    try {
      await sendEmails(emailTo, content, parseInt(idBudget));
      toast.dismiss(toastId);
      toast.success(t('toast.sent'));
      navigate('/requests');
    } catch (error) {
      toast.dismiss(toastId);
      toast.error(t('general.error', { ns: 'common' }));
    }
  };

  const handleBack = () => {
    editor.content = '';
    setEditorState(editor);
    toggle();
  };

  useEffect(() => {
    if (open) handleFetch();

    return () => {
      setEditorState({ content: '' });
    };
  }, [open]);

  return (
    <Grid container sx={{ width: '90%', minHeight: '80vh', ml: 'auto', mr: { xs: 0.5, md: 'auto' }, pb: 4, zIndex: 1 }}>
      <Box sx={{ width: '100%', mt: 4, display: 'flex', flexDirection: 'column', gap: 4 }}>
        <Box sx={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', overflow: 'hidden' }}>
          {isLoadingContent ? (
            <Skeleton sx={{ width: '100%', height: '100%' }} />
          ) : (
            <EditorWrapper>
              <TymEditor
                label={''}
                onChange={value => handleEditorChange(value)}
                value={editorState.content}
                resize={false}
              />
            </EditorWrapper>
          )}
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
          <ButtonForm label={t('general.back')} onClick={handleBack} color='error' />
          <ButtonForm label={t('general.send')} onClick={handleSend} color='success' />
        </Box>
      </Box>
    </Grid>
  );
}
