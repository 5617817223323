import { format } from 'date-fns';
import { useState, useEffect, Fragment } from 'react';
import { useTranslation } from 'react-i18next';

// @MUI Components
import {
  Box,
  Chip,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

// @Icons
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

// @Utils
import { axiosEstimates } from '../../../../../utils';

// @Components
import ColumHeader from '../../../../../components/TableColumnHeader';

// @Interfaces and Types
interface chipItem {
  value: string | number;
  label: string;
  color: 'default' | 'success' | 'info' | 'error' | 'primary' | 'secondary' | 'warning';
}

export default function Row({ row }: any) {
  const [open, setOpen] = useState(false);
  const [suppliers, setSuppliers] = useState<any>([]);

  const { t } = useTranslation('common');

  const statusItem: chipItem[] = [
    { value: 1, color: 'success', label: `${t('general.active')}` },
    { value: 0, color: 'error', label: `${t('general.cancelled')}` },
  ];

  const action: chipItem[] = [
    { value: 'update', color: 'warning', label: `${t('general.update')}` },
    { value: 'create', color: 'info', label: `${t('general.create')}` },
  ];

  const description = action.find(description => description.value === row.description);

  const status = statusItem.find(status => status.value === row.status);

  const handleGetSuppliersDate = async (date: any) => {
    try {
      const body = { date };
      const response = await axiosEstimates.post<any>('/supplier-historydate', body);

      return response.data;
    } catch (error) {
      console.error(error);
    }
  };

  const handleGetSuppliers = async () => {
    const data = await handleGetSuppliersDate(row.updatedAt)
      .then(res => setSuppliers(res))
      .catch(err => console.error(err));

    return data;
  };

  useEffect(() => {
    handleGetSuppliers();
  }, []);

  return (
    <Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton aria-label='expand row' size='small' onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>

        <TableCell component='th' scope='row' align='center'>
          {format(new Date(row.updatedAt), 'MM/dd/yyyy - hh:mm:ss a')}
        </TableCell>

        <TableCell align='center' component='th' scope='row'>
          <Typography noWrap title={row.name} sx={{ maxWidth: { xs: '12rem', sm: '16rem', xl: '18rem' } }}>
            {row.name}
          </Typography>
        </TableCell>

        <TableCell align='center'>
          <Chip
            size='medium'
            label={description?.label || ''}
            color={description?.color}
            sx={{ p: '0.5rem', m: '0 0.5rem', textTransform: 'capitalize' }}
          />
        </TableCell>

        <TableCell align='center'>
          <Chip
            size='medium'
            label={status?.label || ''}
            color={status?.color}
            sx={{ p: '0.5rem', m: '0 0.5rem', textTransform: 'capitalize' }}
          />
        </TableCell>

        <TableCell align='center'>{row.userName}</TableCell>
      </TableRow>

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout='auto' unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant='h6' gutterBottom component='div'>
                {t('general.details')}
              </Typography>

              <Table stickyHeader size='small' aria-label='purchases'>
                <TableHead sx={{ backgroundColor: 'primary.main' }}>
                  <ColumHeader
                    headers={[t('general.email'), t('general.phone'), t('general.country'), t('general.address')]}
                  />
                </TableHead>

                {suppliers.length > 0 ? (
                  suppliers.map((items: any) => (
                    <TableBody key={items.id}>
                      <TableRow>
                        <TableCell align='center'>{items.email}</TableCell>
                        <TableCell align='center'>{items.phone}</TableCell>
                        <TableCell align='center'>{items.country}</TableCell>
                        <TableCell align='center'>{items.address}</TableCell>
                      </TableRow>
                    </TableBody>
                  ))
                ) : (
                  <TableBody>
                    <TableRow>
                      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
                        <Box
                          sx={{
                            height: 300,
                            display: 'flex',
                            textAlign: ' center',
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: '#F7F7F7',
                          }}
                        >
                          <Typography sx={{ textAlign: 'center' }} gutterBottom component='div'>
                            {t('general.noRows', { ns: 'validations' })}
                          </Typography>
                        </Box>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  );
}
