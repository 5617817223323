import { useState, Fragment, useEffect } from 'react';

// @MUI Components
import { Collapse, ListItem, Typography, Box, ListItemIcon, ListItemButton, List } from '@mui/material/';

// @MUI Icons
import CircleOutline from 'mdi-material-ui/CircleOutline';

// @Component
import NavLink from '../NavLink/NavLink';

// @Styled
import { MenuGroupToggleRightIcon, MenuItemTextWrapper } from './styled.component';

// @Interface
import { Item } from '../../../models/items';

function NavGroup({
  item,
  show,
  openGroup,
  setOpenGroup,
}: {
  item: Item;
  show: boolean;
  openGroup: string;
  setOpenGroup: any;
}) {
  const children = item?.children?.filter(subItem => (subItem.notRender ? null : subItem));

  const toggleOpen = () => setOpenGroup(item.title);

  useEffect(() => {
    !show && setOpenGroup('');
  }, [show]);

  return (
    <div>
      <Fragment>
        <ListItem
          disablePadding
          className='nav-group'
          onClick={toggleOpen}
          sx={{ mt: 0, px: '0 !important', flexDirection: 'column' }}
        >
          <ListItemButton
            sx={{
              width: '100%',
              borderTopRightRadius: 100,
              borderBottomRightRadius: 100,
              transition: 'padding .25s ease-in-out',
            }}
          >
            <ListItemIcon sx={{ color: 'text.primary', transition: 'margin .25s ease-in-out' }}>
              {item.icon === undefined ? <CircleOutline /> : <item.icon />}
            </ListItemIcon>

            <MenuItemTextWrapper>
              {show && <Typography>{item.title}</Typography>}
              <Box className='menu-item-meta' sx={{ ml: 3, display: 'flex', alignItems: 'center' }}>
                {show && <MenuGroupToggleRightIcon />}
              </Box>
            </MenuItemTextWrapper>
          </ListItemButton>

          <Collapse
            in={openGroup === item.title}
            component='ul'
            onClick={e => e.stopPropagation()}
            sx={{ pl: 3.5, my: 0, width: '100%', transition: 'all .25s ease-in-out' }}
          >
            <List>
              {children?.map((child: any, index) => (
                <NavLink key={index} item={child} open={openGroup === item.title} group />
              ))}
            </List>
          </Collapse>
        </ListItem>
      </Fragment>
    </div>
  );
}

export default NavGroup;
