import * as yup from 'yup';
import { IServicesOfSuppliersAdd } from '../../../../../models';

export const initialValues: IServicesOfSuppliersAdd = {
  comission: 10,
  countryDestiny: '',
  countryOrigin: '',
  expDate: '',
  idBudget: -1,
  quotationCode: '',
  transportTime: 0,
  via: '',
};

export const schemaValidation = yup
  .object()
  .shape({
    comission: yup.number().typeError('errors.require.comission').required('errors.require.comission'),
    countryOrigin: yup.string().required('errors.require.countryOrigin'),
    countryDestiny: yup.string().required('errors.require.countryDestiny'),
    transportTime: yup
      .number()
      .positive('errors.positive')
      .typeError('errors.require.transportTime')
      .required('errors.require.transportTime'),
    quotationCode: yup.string().required('errors.require.quotationCode'),
    expDate: yup.string().required('errors.require.expDate'),
  })
  .required();
