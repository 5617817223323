import { useTranslation } from 'react-i18next';

// @MUI Components
import { Box, Grid, Pagination, Typography } from '@mui/material';

// @Interfaces and Types
import { ExpenseType, PaymentType } from '../types';

interface IProps {
  page: number;
  PAGE_SIZE: number;
  currentTab: 'payments' | 'expenses' | 'commissions';
  paymentsIsOpen: boolean;
  paymentsData: PaymentType[] | [];
  expensesData: ExpenseType[] | [];
  handlePage: (e: any, newPage: number) => void;
}

export default function LogsPagination({
  page,
  PAGE_SIZE,
  currentTab,
  paymentsIsOpen,
  paymentsData,
  expensesData,
  handlePage,
}: IProps) {
  const { t } = useTranslation('common');

  return (
    <Grid
      container
      item
      lg={11.5}
      sx={{
        mt: { xs: -10, sm: -20 },
        mx: 'auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: { xs: 'center', sm: 'space-between' },
        gap: { xs: 2, sm: 0 },
        opacity: paymentsIsOpen ? 1 : 0,
        transition: '300ms ease opacity',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 0.5 }}>
        <Typography>{currentTab === 'payments' ? t('general.payments') : t('general.expenses')}</Typography>

        <Typography sx={{ fontWeight: 'bold' }}>
          {currentTab === 'payments' ? paymentsData.length : expensesData.length}
        </Typography>
      </Box>

      <Pagination
        page={page}
        color='primary'
        onChange={handlePage}
        count={Math.ceil((currentTab === 'payments' ? paymentsData.length : expensesData.length) / PAGE_SIZE)}
      />
    </Grid>
  );
}
