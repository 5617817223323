// @Utils
import { axiosEstimates } from '../../../utils';

// @Interfaces and Types
import { IGetServicesResponse } from '../../../models';

const getServices = async (filter: string, check: boolean) => {
  const endpoint = `services?filter=${filter}&status=${check ? 'active' : 'inactive'}`;
  const response = await axiosEstimates.get<IGetServicesResponse>(endpoint, {
    headers: { 'x-module': 'services' },
  });

  const { status, message, data } = response.data;

  if (status === 200) return data;
  else if (status === 401) throw response.data;
  else throw new Error(`${status} - ${message || 'Unexpected error'} `);
};

export default getServices;
