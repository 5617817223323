import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, Control, FieldValues, FieldPath } from 'react-hook-form';

// @MUI Components
import Autocomplete from '@mui/material/Autocomplete';
import TextField, { StandardTextFieldProps } from '@mui/material/TextField';
import Skeleton from '@mui/material/Skeleton';
import CircularProgress from '@mui/material/CircularProgress';

// @Utils
import { axiosEstimates } from '../utils/api';

// @Interfaces and Types
import { ISelectInput, ITransportationRequest } from '../models';
interface Props<T extends FieldValues> extends StandardTextFieldProps {
  name: FieldPath<T>;
  control: Control<T, any>;
  loading?: boolean;
}

/**
 *
 * @component
 * @subcategory Global
 *
 * @description
 * The TransportationFilter component is a reusable component that provides an autocomplete input field for filtering transportation options. It uses the `react-hook-form` library for form state management and the `@mui/material` library for UI components.
 *
 * #### Example
 * ```
 * return(
 * <TransportationFilter
 *   name="transportation"
 *   control={control}
 *   loading={false}
 * />
 * )
 * ```
 *
 * @property {string} name The field name
 * @property {Control} control The react-hook-form control
 * @property {boolean} [loading=false] Whether the component is loading
 * @property {Object} props Additional props
 *
 * @return {JSX.Element} A JSX element representing the TransportationFilter component.
 * @author CML Exports Front-End Developers
 */
function TransportationFilter<T extends FieldValues>({ name, loading, control, ...props }: Props<T>) {
  const [options, setOptions] = useState<ISelectInput<string>[]>([]);
  const [spinner, setSpinner] = useState<boolean>(false);

  const { t } = useTranslation(['common']);

  const handleFilter = async (value: string) => {
    setSpinner(true);
    try {
      const response = await axiosEstimates.get<ITransportationRequest>('transportation-by-name?name=' + value);

      const items: ISelectInput<string>[] = response.data.data.map(type => ({ label: type.name, value: type.name }));

      /* const newItem: ISelectInput<string>[] = [{ label: `${t('add.general')} ${value}`, value }]; */

      setOptions(items);
      setSpinner(false);
    } catch (error) {
      setOptions([]);
      setSpinner(false);
    }
  };

  useEffect(() => {
    handleFilter('');
  }, []);

  return (
    <>
      {!loading ? (
        <Controller
          control={control}
          name={name}
          render={({ field: { value, onChange, ...otherField }, fieldState: { error } }) => (
            <Autocomplete
              freeSolo
              loading={spinner}
              options={options}
              value={value}
              onInputChange={async (event, value) => {
                handleFilter(value);
              }}
              onChange={(event, item: any) => {
                onChange(item?.value || '');
              }}
              {...otherField}
              renderInput={params => (
                <TextField
                  {...params}
                  {...props}
                  error={Boolean(error?.message)}
                  helperText={t(`${error?.message || ''}`)}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {spinner ? <CircularProgress color='inherit' size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
          )}
        />
      ) : (
        <Skeleton variant='rectangular' height={60} />
      )}
    </>
  );
}
export default TransportationFilter;
