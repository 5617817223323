// @Libraries
import { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { useFieldArray, useForm } from 'react-hook-form';
import Swal from 'sweetalert2';
import { useLocation, useParams } from 'react-router-dom';

// @Models
import { GAReservas } from '../../../../models';

// @Hooks
import { useDocument } from '../../hooks';

// @Utils
import { axiosEstimates } from '../../../../utils';
import { initialValues, schemaValidation } from './utils/validationSchema';
import { DOCUMENT_TYPE_OBJ } from '../../utils';

const useGAReservas = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const [documentId, setDocumentId] = useState<number | null>(null);
  const { budget, documents, handleNavigatePrint, handleFormChange, handleFormChange2 } = useDocument();
  const [currentData, setCurrentData] = useState<object | null>(null);

  const { control, handleSubmit, reset, watch, getValues } = useForm<GAReservas>({
    defaultValues: initialValues,
    resolver: yupResolver(schemaValidation),
    reValidateMode: 'onChange',
  });

  useEffect(() => {
    getValues();
    const current = JSON.stringify(currentData);
    const updated = JSON.stringify(getValues());
    if (current !== updated) {
      handleFormChange();
    } else {
      handleFormChange2();
    }
  }, [watch()]);

  useEffect(() => {
    setCurrentData(getValues());
    getBudgetId();
  }, [budget]);

  const {
    fields: shipmenDetailsFields,
    append: shipmenDetailsAppend,
    remove: shipmenDetailsRemove,
  } = useFieldArray({ control, name: 'shipmenDetails' });

  if (shipmenDetailsFields.length === 0) {
    shipmenDetailsAppend({
      agentInOrigin: '',
      product: '',
      weight: '',
      destination: '',
      dimessions: '',
      numOfPieces: null,
      paymentDate: '',
      deadline: '',
      dateOfFlight: '',
    });
  }
  const getBudgetId = async () => {
    const data = budget;
    if (data) {
      const document = documents.find(document => document.document_types.name === DOCUMENT_TYPE_OBJ.GA_RESERVAS);
      if (!document) {
        const dataReservas = {
          expeditionDate: new Date().toLocaleDateString(),
          invoiceOperation: data.budget.code,
          nameshipper: 'CML EXPORTS AND MOVING SOLUTIONS INC',
          addressshipper: '112 Bartram Oaks Walk #600877 Jacksonville, FL 32259 US',
          mailshipper: 'operations@cmlexports.com',
          phoneshipper: '(904) 295 4450',
          nameconsignee: data.customer.name,
          addressconsignee: data.customer.address,
          mailconsignee: data.customer.email,
          phoneconsignee: data.customer.phone,
        };
        reset(dataReservas);
        setDocumentId(null);
      } else {
        const documentShipmentDetails = document.document_shipment_details.map(content => ({
          agentInOrigin: content.agentInOrigin || '',
          product: content.product || '',
          weight: content.weight || '',
          destination: content.destination || '',
          dimessions: content.dimensions || '',
          numOfPieces: Number(content.numberOfPieces) || 0,
          paymentDate: content.paymentDate || '',
          deadline: content.deadline || '',
          dateOfFlight: content.dateOfFlight || '',
        }));
        const dataReservas: GAReservas = {
          invoiceOperation: data.budget.code,
          masterGuide: document.masterGuide || '',
          hawbGuide: document.hawb_guide || '',
          shipmenDetails: documentShipmentDetails,
          nameshipper: document.shipperPerson?.name || '',
          addressshipper: document.shipperPerson?.address || '',
          mailshipper: document.shipperPerson?.email || '',
          phoneshipper: document.shipperPerson?.phone || '',
          nameconsignee: document.consigneePerson?.name || '',
          addressconsignee: document.consigneePerson?.address || '',
          mailconsignee: document.consigneePerson?.email || '',
          phoneconsignee: document.consigneePerson?.phone || '',
        };
        reset(dataReservas);
        setDocumentId(document.id);
      }
    }
  };

  const onSubmit = async (data: GAReservas) => {
    try {
      const documentShipmentDetails = data.shipmenDetails.map(content => ({
        agentInOrigin: content.agentInOrigin,
        product: content.product,
        weight: content.weight,
        destination: content.destination,
        dimensions: content.dimessions,
        numberOfPieces: `${content.numOfPieces}`,
        paymentDate: content.paymentDate,
        deadline: content.deadline,
        dateOfFlight: content.dateOfFlight,
      }));
      const dataPorte = {
        budgetId: parseInt(id || '0'),
        strategy: DOCUMENT_TYPE_OBJ.GA_RESERVAS,
        masterGuide: data.masterGuide,
        hawb_guide: data.hawbGuide,
        documentShipmentDetails,
        consigneePerson: {
          name: data.nameconsignee,
          address: data.addressconsignee,
          email: data.mailconsignee,
          phone: data.phoneconsignee,
        },
        shipperPerson: {
          name: data.nameshipper,
          address: data.addressshipper,
          email: data.mailshipper,
          phone: data.phoneshipper,
        },
        conveyors: {
          accountingInformation: '1',
        },
      };

      let document: any = null;

      if (!documentId) {
        const axiosResponse = await axiosEstimates.post('/api/document', dataPorte);
        document = axiosResponse.data.data;
      } else {
        const axiosResponse = await axiosEstimates.put('/api/document', { ...dataPorte, id: documentId });
        document = axiosResponse.data.data;
      }

      if (document) {
        handleNavigatePrint(document.id, location.pathname + '&value=7');
        Swal.fire({
          icon: 'success',
          title: t('alert.success', { ns: 'common' }) || '',
          text: t('alert.supplier.success', { ns: 'common' }) || '',
          showCloseButton: true,
        });
      }
    } catch (err) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: t('alert.somethingerror', { ns: 'common' }) || '',
        showCloseButton: true,
      });
    }
  };
  return {
    t,
    control,
    shipmenDetailsFields,
    shipmenDetailsAppend,
    shipmenDetailsRemove,
    onSubmit: handleSubmit(onSubmit, error => console.error(error)),
    navigate: handleNavigatePrint,
    documentId,
  };
};

export default useGAReservas;
