import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

// @Mui
import { createFilterOptions } from '@mui/material/Autocomplete';

// @Services
import { getShipments, getSupplierByIds } from '../services';

// @Models
import { Shipment, SendForm } from '../models';
import { ISelectInput, ServiceTypesSelect } from '../../../../models';

const filter = createFilterOptions();

export function useContainer() {
  const [open, setOpen] = useState(false);
  const [emailTo, setEmailTo] = useState<string[]>([]);
  const [shipments, setShipments] = useState<Shipment[]>([]);
  const [copyShipments, setCopyShipments] = useState<Shipment[]>([]);
  const [selectedShipments, setSelectedShipments] = useState<number[]>([]);
  const [menuItemsArr, setMenuItemsArr] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | boolean>(false);

  const { id } = useParams();

  const navigate = useNavigate();

  const { control, handleSubmit, setValue } = useForm<SendForm>({
    defaultValues: {
      serviceTypes: [],
      transportTypes: [],
      emails: [],
      shipments: [],
    },
  });
  const { t } = useTranslation(['common']);

  const serviceTypes = useWatch({ control, name: 'serviceTypes' });
  const transportations = useWatch({ control, name: 'transportTypes' });

  useEffect(() => {
    handleGetData();
  }, []);

  useEffect(() => {
    const serviceTypeIds: number[] = serviceTypes.map(type => type.value);
    const transportationIds: number[] = transportations.map(t => t.value);
    handleGetSupplierByIds(serviceTypeIds, transportationIds);
    const newShipments = copyShipments.filter(shipment => serviceTypeIds.includes(shipment.idServiceType));
    setShipments(newShipments);
  }, [serviceTypes, transportations]);

  const handleGetData = async () => {
    setLoading(true);

    try {
      if (id) {
        const shipments = await getShipments(id);

        if (typeof shipments === 'string') throw shipments;

        const serviceTypeIds: number[] = shipments.map(shipment => shipment.idServiceType);
        const transportationIds: number[] = shipments.map(shipment => shipment.idTransportation);

        setShipments(shipments);
        setCopyShipments(shipments);

        await handleGetSupplierByIds(serviceTypeIds, transportationIds);

        const serviceTypes: ServiceTypesSelect[] = shipments
          .map(shipment => ({
            value: shipment.serviceType.id,
            label: shipment.serviceType.name,
            category: 0,
          }))
          .filter(
            (serviceType, index, self) =>
              index === self.findIndex(t => t.value === serviceType.value && t.label === serviceType.label),
          );

        const transportations: ISelectInput<number>[] = shipments
          .map(shipment => ({
            label: shipment.transportation.name,
            value: shipment.transportation.id,
          }))
          .filter(
            (transportation, index, self) =>
              index === self.findIndex(t => t.value === transportation.value && t.label === transportation.label),
          );

        setValue('serviceTypes', serviceTypes);
        setValue('transportTypes', transportations);
      }
    } catch (error: any) {
      setError(typeof error === 'string' ? error : error.statusText || error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleGetSupplierByIds = async (serviceTypeIds: number[], transportationIds: number[]) => {
    try {
      const suppliers = await getSupplierByIds(serviceTypeIds, transportationIds);
      const menuItems: string[] = [];
      for (const supplier of suppliers) {
        for (const details of supplier.suppliersDetails) {
          menuItems.push(details.email);
        }
      }
      setMenuItemsArr(menuItems);
    } catch (error) {
      setMenuItemsArr([]);
    }
  };

  const handleSendMail = () => {
    setOpen(true);
  };

  const addNewOption = (options: string[], params: any): string[] => {
    const filtered = filter(options, params);

    const { inputValue } = params;

    const isExisting = options.some(option => inputValue === option);

    if (inputValue !== '' && !isExisting) {
      filtered.push(inputValue);
    }
    // @ts-ignore
    return filtered;
  };

  const handleAddEmail = (email: string[]) => {
    setEmailTo(email);
  };

  const handleSelectShipment = (ids: number[]) => {
    setSelectedShipments(ids);
  };

  return {
    t,
    loading,
    error,
    control,
    addNewOption,
    emailTo,
    handleAddEmail,
    menuItemsArr,
    shipments,
    handleSelectShipment,
    selectedShipments,
    handleSendMail: handleSubmit(handleSendMail),
    open,
    id,
    toggle: () => setOpen(prev => !prev),
    navigate,
    transportations,
  };
}
