import { ReactElement } from 'react';

// @Mui
import { styled } from '@mui/material/styles';
import { StepIconProps } from '@mui/material/StepIcon';
import { Icon } from '@iconify/react';

const ColorlibStepIconRoot = styled('div')<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
  zIndex: 1,
  color: '#fff',
  width: 30,
  height: 30,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundImage: 'linear-gradient( 136deg,rgb(22, 177, 255) 50%, rgb(22, 177, 255) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  }),
  ...(ownerState.completed && {
    backgroundImage: 'linear-gradient( 136deg,rgb(86,202,0) 50%, rgb(86,202,0) 100%)',
  }),
}));

export function ColorlibStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  const icons: { [index: string]: ReactElement } = {
    1: <Icon icon='mdi:shop-location-outline' />,
    2: <Icon icon='mdi:customer-service' />,
    3: <Icon icon='grommet-icons:services' />,
    4: <Icon icon='ci:file-pdf' />,
  };

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}
