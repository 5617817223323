// @Libraries
import { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFieldArray, useForm } from 'react-hook-form';
import Swal from 'sweetalert2';

// @Models
import { GAManifiesto } from '../../../../models';

// @Hooks
import { useDocument } from '../../hooks';
// @Redux
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/reducers';
// @Utils
import { axiosEstimates } from '../../../../utils';
import { initialValues, schemaValidation } from './utils/validationSchema';
import { DOCUMENT_TYPE_OBJ } from '../../utils';

const useGAManifiesto = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const [documentId, setDocumentId] = useState<number | null>(null);
  const { budget, documents, handleNavigatePrint, handleFormChange, handleFormChange2 } = useDocument();
  const [currentData, setCurrentData] = useState<object | null>(null);
  const {
    countries: { countryItems },
  } = useSelector((state: RootState) => state);

  const { control, handleSubmit, reset, watch, getValues } = useForm<GAManifiesto>({
    defaultValues: initialValues,
    resolver: yupResolver(schemaValidation),
    reValidateMode: 'onChange',
  });

  useEffect(() => {
    getValues();
    const current = JSON.stringify(currentData);
    const updated = JSON.stringify(getValues());
    if (current !== updated) {
      handleFormChange();
    } else {
      handleFormChange2();
    }
  }, [watch()]);

  const {
    fields: contentFields,
    append: contentAppend,
    remove: contentRemove,
  } = useFieldArray({ control, name: 'content' });

  if (contentFields.length === 0) {
    contentAppend({
      hawb: '',
      carrier: '',
      consignee: '',
      quantity: null,
      weight: null,
    });
  }

  useEffect(() => {
    setCurrentData(getValues());
    handleData();
  }, [budget]);

  const handleData = async () => {
    const data = budget;
    if (data) {
      const document = documents.find(document => document.document_types.name === DOCUMENT_TYPE_OBJ.GA_MANIFIESTO);
      if (!document) {
        const dataManifiesto = {
          invoice: data.budget.code,
          date: new Date().toLocaleDateString(),
          origin: data.budget.shipments[0].countryOrigin,
          destination: data.budget.shipments[0].countryDestiny,
          nameshipper: 'CML EXPORTS AND MOVING SOLUTIONS INC',
          addressshipper: '112 Bartram Oaks Walk #600877 Jacksonville, FL 32259 US',
          mailshipper: 'operations@cmlexports.com',
          phoneshipper: '(904) 295 4450',
          nameconsignee: data.customer.name,
          addressconsignee: data.customer.address,
          mailconsignee: data.customer.email,
          phoneconsignee: data.customer.phone,
        };
        reset(dataManifiesto);
        setDocumentId(null);
      } else {
        const dataManifiesto: GAManifiesto = {
          invoice: data.budget.code,
          date: document.expeditionDate || '',
          arrival: document.arrival || '',
          origin: data.budget.shipments[0].countryOrigin || '',
          destination: data.budget.shipments[0].countryDestiny || '',
          nameshipper: document.shipperPerson?.name || '',
          addressshipper: document.shipperPerson?.address || '',
          mailshipper: document.shipperPerson?.email || '',
          phoneshipper: document.shipperPerson?.phone || '',
          nameconsignee: document.consigneePerson?.name || '',
          addressconsignee: document.consigneePerson?.address || '',
          mailconsignee: document.consigneePerson?.email || '',
          phoneconsignee: document.consigneePerson?.phone || '',
          content: document.documentContent.map(content => ({
            hawb: content.hawb || '',
            weight: Number(content.grossWeight) || 0,
            carrier: content.airline || '',
            consignee: content.recipient || '',
            quantity: Number(content.amount) || 0,
          })),
        };
        reset(dataManifiesto);
        setDocumentId(document.id);
      }
    }
  };

  const onSubmit = async (data: GAManifiesto) => {
    try {
      const content = data.content.map(content => ({
        hawb: content.hawb,
        grossWeight: `${content.weight}`,
        airline: content.carrier,
        recipient: content.consignee,
        amount: content.quantity,
      }));
      const dataPorte = {
        budgetId: parseInt(id || '0'),
        strategy: DOCUMENT_TYPE_OBJ.GA_MANIFIESTO,
        reference: data.invoice,
        expeditionDate: data.date,
        arrival: data.arrival,
        documentContent: content,
        consigneePerson: {
          name: data.nameconsignee,
          address: data.addressconsignee,
          email: data.mailconsignee,
          phone: data.phoneconsignee,
        },
        shipperPerson: {
          name: data.nameshipper,
          address: data.addressshipper,
          email: data.mailshipper,
          phone: data.phoneshipper,
        },
      };

      let document: any = null;

      if (!documentId) {
        const axiosResponse = await axiosEstimates.post('/api/document', dataPorte);
        document = axiosResponse.data.data;
      } else {
        const axiosResponse = await axiosEstimates.put('/api/document', { ...dataPorte, id: documentId });
        document = axiosResponse.data.data;
      }

      if (document) {
        handleNavigatePrint(document.id, location.pathname + '&value=8');
        Swal.fire({
          icon: 'success',
          title: t('alert.success', { ns: 'common' }) || '',
          text: t('alert.supplier.success', { ns: 'common' }) || '',
          showCloseButton: true,
        });
      }
    } catch (err) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: t('alert.somethingerror', { ns: 'common' }) || '',
        showCloseButton: true,
      });
    }
  };
  return {
    t,
    onSubmit: handleSubmit(onSubmit, error => {
      console.error(error);
    }),
    countryItems,
    control,
    contentFields,
    contentAppend,
    contentRemove,
    navigate: handleNavigatePrint,
    documentId,
  };
};

export default useGAManifiesto;
