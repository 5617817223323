import { useState, useEffect } from 'react';
import { Category } from '../def';
import { axiosEstimates } from '../../../utils';
import { useServices } from './useServices';

export default function useCategory() {
  const [categories, setCategories] = useState<Category[]>([]);
  const [reloadCategories, setReloadCategories] = useState(false);
  const [loadingCategories, setLoadingCategories] = useState(true);

  const { setCategoryDataList } = useServices();

  useEffect(() => {
    if ((!categories.length && loadingCategories) || reloadCategories) {
      axiosEstimates
        .get<{
          categories: Category[];
          permission: any;
        }>('api/services/category', {
          headers: { 'x-module': 'services' },
        })
        .then(response => {
          setCategories(response.data.categories);
        })
        .catch(error => {
          console.error(error);
        })
        .finally(() => {
          setLoadingCategories(false);
          setReloadCategories(false);
        });
    }
  }, [categories, loadingCategories, reloadCategories]);

  useEffect(() => {
    setCategoryDataList(categories);
  }, [categories]);

  return {
    categories,
    setLoadingCategories,
    setReloadCategories,
    setCategories,
  };
}
