// @Utils
import { axiosEstimates } from '../../../utils/api';

/**
 * @category Services
 * @description Service for get the invoices' stats.
 *
 * @async
 * @method
 *
 * @param {number} idUser User id
 * @param {string[]} billsType An array with the bills' type to consider.
 * @param {Date | string} [firstDate] First date to search.
 * @param {Date | string} [lastDate] Last date to search.
 *
 * @return {object} Return an object with the invoices' stats total amounts.
 * @author CML Exports Front-End Developers
 * @example getInvoicesStats(1, ["cml", "ctis"])
 */
async function getInvoicesStats(
  idUser: number,
  billsType: string[],
  firstDate?: Date | string,
  lastDate?: Date | string,
) {
  const endPoint = `api/get-bills-stats?billsType=${billsType}&firstDate=${firstDate}&lastDate=${lastDate}&idUser=${idUser}`;
  const res = await axiosEstimates.get(endPoint, { headers: { 'x-module': 'home' } });

  return res.data.data;
}

export default getInvoicesStats;
