import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { SyntheticEvent } from 'react-draft-wysiwyg';
import { SubmitHandler } from 'react-hook-form';

// @MUI Components
import { Box, Button, Divider, Grid, Drawer, Typography, Chip } from '@mui/material';

// @MUI Icons
import Close from 'mdi-material-ui/Close';

// @Utils
import { formatNumber } from '../../../utils';

// @Components
import DateInput from './DateInput';
import CustomInput from '../../../components/CustomInput';
import CustomSelect from '../../../components/CustomSelect';
import FileUpload from '../../../components/FileUploader/FileUpload';
import { NumberField } from '../../../components';

// @Interfaces and Types
import { PaymentType } from '../types';
import { CustomFiles } from '../../../models/CustomFiles';
import i18n from '../../../i18n';

interface IProps {
  control: any;
  formIsOpen: boolean;
  paymentFile: CustomFiles;
  setPaymentFile: Dispatch<SetStateAction<CustomFiles>>;
  watchLimitAmount: number;
  watchReceivedAmount: number;
  createPayment: SubmitHandler<PaymentType>;
  handleSubmit: any;
  handleToggleForm: (e: SyntheticEvent) => void;
}

export default function PaymentForm({
  control,
  formIsOpen,
  paymentFile,
  setPaymentFile,
  watchLimitAmount,
  watchReceivedAmount,
  createPayment,
  handleSubmit,
  handleToggleForm,
}: IProps) {
  const { t } = useTranslation('common');

  const getStatusAmount: () => 'primary' | 'error' | 'success' = () => {
    if (Math.round(watchReceivedAmount) === Math.round(watchLimitAmount)) return 'primary';
    else if (Math.round(watchReceivedAmount) > Math.round(watchLimitAmount)) return 'error';
    else return 'success';
  };

  return (
    <Drawer
      open={formIsOpen}
      anchor='right'
      variant='temporary'
      onClose={handleToggleForm}
      ModalProps={{ keepMounted: true }}
      sx={{ '& .MuiDrawer-paper': { width: { xs: 300, sm: 400 } } }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: ({ spacing }) => spacing(3, 4),
          backgroundColor: ({ palette }) => palette.background.default,
        }}
      >
        <Typography variant='h6'>{t('general.addPayment')}</Typography>
        <Close fontSize='small' onClick={handleToggleForm} sx={{ cursor: 'pointer' }} />
      </Box>

      <Box component={'form'} sx={{ padding: '1rem' }}>
        <Grid container item spacing={1} xs={12} sx={{ display: 'flex', gap: 2 }}>
          <CustomSelect
            control={control}
            name='paymentMethod'
            label={t('general.paymentMethod')}
            options={[
              { text: t('general.transfer'), value: 'bank-transfer' },
              { text: t('general.check'), value: 'cheque' },
              { text: t('general.card'), value: 'credit-card' },
              { text: t('general.cash'), value: 'cash' },
            ]}
          />
          <CustomInput name='bank' label={t('general.bank')} control={control} />
          <CustomInput name='depositAccount' label={t('general.depositAccount')} control={control} />
          <DateInput name='paymentDate' control={control} />
          <CustomInput name='reference' label={t('general.reference')} control={control} />
          <NumberField name='receivedAmount' label={t('expenses.amount')} control={control} />
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexWrap: 'wrap',
              alignItems: 'center',
              rowGap: 1,
              justifyContent: 'space-between',
            }}
          >
            <Box sx={{ mx: 'auto', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
              <Typography>{t('general.quantity')}</Typography>
              <Chip
                variant='outlined'
                color={getStatusAmount()}
                label={`$${formatNumber(watchReceivedAmount)}`}
                sx={{ borderWidth: '2px', fontSize: '1.15rem' }}
              />
            </Box>

            <Box sx={{ mx: 'auto', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
              <Typography>{t('general.limit')}</Typography>
              <Chip
                color='primary'
                label={`$${formatNumber(watchLimitAmount)}`}
                sx={{ borderWidth: '2px', fontSize: '1.15rem' }}
              />
            </Box>
          </Box>
        </Grid>

        <Divider sx={{ my: 2 }} />

        <FileUpload
          title={t('uploader.pdfOrImage')}
          file={paymentFile}
          setFile={setPaymentFile}
          maxMbSize={10}
          caption={
            i18n.language === 'es'
              ? 'Solo admite extensiones JPG, PNG, PDF, peso máximo de 10mb'
              : 'Only supports JPG, PNG, PDF extensions, maximum size of 10mb'
          }
        />

        <Divider sx={{ mt: 2, mb: 2 }} />

        <Box sx={{ my: 2, display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 2 }}>
          <Button sx={{ m: 2 }} variant='contained' color='error' onClick={e => handleToggleForm(e)}>
            {t('general.cancel')}
          </Button>

          <Button sx={{ m: 2 }} variant='contained' onClick={handleSubmit(createPayment)}>
            {t('general.save')}
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
}
