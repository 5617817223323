import { useSearchParams } from 'react-router-dom';
import i18next from 'i18next';
import { useEffect, useState } from 'react';
import { useForm, useWatch, useController } from 'react-hook-form';
import moment from 'moment'; // deprecated
import 'moment/locale/es';

// @Redux
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/reducers';
import { setSeller, setSellerForm, nextStep } from '../../../redux/slices/budget-form';
import { getSellers } from '../../../redux/slices/budget-form/actions-async';
import { useAppDispatch } from '../../../redux/store';

// @Models
import { ISelectInput, ISellerForm } from '../../../models';
import { axiosEstimates } from '../../../utils';
interface IProps {
  permissions: string[];
}

export function useSellerForm({ permissions }: IProps) {
  const [typeBudget, setTypeBudget] = useState<ISelectInput<number>[]>([]);
  const [dateRerequired, setDateRequired] = useState<boolean>(true);

  const {
    budgetForm,
    user,
    countries: { customLabel },
  } = useSelector((state: RootState) => state);

  const currentLanguage = i18next.language;

  const dispatch = useAppDispatch();

  const { sellerForm, sellers, sellersData } = budgetForm;

  const dataUser = user.user;

  const today = new Date();
  const isReseller = true;

  const [searchParams] = useSearchParams();

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<ISellerForm>({
    defaultValues: sellerForm,
  });

  useController({
    name: 'sellerId',
    control,
    rules: { required: 'required.general' },
  });

  useController({
    name: 'dateOfExpiration',
    control,
    rules: {
      required: { value: true, message: 'errors.required' },
    },
  });

  const sellerId = useWatch({ control, name: 'sellerId' });
  const expDays = useWatch({ control, name: 'daysOfExpiration' });
  const expDate = useWatch({ control, name: 'dateOfExpiration' });

  const onSubmit = (data: ISellerForm): void => {
    dispatch(setSellerForm(data));
    dispatch(nextStep());
  };

  const sellersItems = sellersData
    .filter(seller => {
      if (permissions.includes('view_all')) {
        return true;
      } else if (permissions.includes('view_all_group')) {
        return seller.user_id === user.user?.id || seller.id === user.user?.id;
      } else if (permissions.includes('view_own')) {
        return seller.id === user.user?.id;
      }
      return false;
    })
    .map(seller => ({
      label: seller.person?.name || '',
      value: seller.id,
    }));

  useEffect(() => {
    if (isReseller) dispatch(getSellers());
    else {
      if (dataUser?.person) {
        dispatch(setSeller({ label: dataUser.person.name, value: dataUser.id }));
        setValue('alphanumeric', dataUser.alphanumeric);
        setValue('sellerId', dataUser.id);
        setValue('email', dataUser.email);
        setValue('sellerName', dataUser.person.name);
        setValue('country', dataUser.person.country_alpha2code);
        setValue('sellerEmail', dataUser.email);
      }
    }
  }, []);

  useEffect(() => {
    const seller = sellersData.find(seller => seller.id === sellerId);

    if (seller) {
      setValue('alphanumeric', seller.alphanumeric);
      setValue('country', seller.person?.country_alpha2code || '');
      setValue('email', seller.email);
      setValue('sellerName', seller.person?.name || '');
      setValue('sellerEmail', seller.email);
    }
  }, [sellerId]);

  useEffect(() => {
    const startDate = moment(today);
    const endDate = moment(expDate);
    const diffDays = endDate.diff(startDate, 'days');

    if (!isNaN(diffDays)) {
      setValue('daysOfExpiration', diffDays + 1);
      setDateRequired(false);
    } else if (isNaN(diffDays)) {
      setValue('daysOfExpiration', 0);
      setDateRequired(true);
    }
  }, [expDate]);

  useEffect(() => {
    if (expDays > 0) {
      const date = moment(today).add(expDays, 'days');
      setValue('dateOfExpiration', date.format('YYYY/MM/DD HH:mm:ss'));
    } else setValue('dateOfExpiration', null);
  }, [expDays]);

  useEffect(() => {
    if (!searchParams.get('option')) {
      setTypeBudget([{ value: 0, label: '' }]);

      axiosEstimates
        .get('budget?type=1&status=request', {
          headers: {
            'x-module': 'requested_quotes',
          },
        })
        .then(res => {
          const permissions = Object.keys(res.data.permissions);
          const canRequest = permissions.some(p => p.match('view'));

          const typeBudget = [
            { value: 1, label: 'budgetPage.types.new' },
            { value: 2, label: 'budgetPage.types.request' },
          ].filter(t => (!canRequest ? t.value === 1 : t));

          setTypeBudget(typeBudget);
        })
        .catch(() => {
          console.error('Cannot get reques permissions');
        });
    }

    // El option es basicamente un util para cuando se quiere autocompletar el tipo de budget a crear dejando ese tipo como unica opcion.
    if (searchParams.get('option') === '1') {
      setValue('budgetType', 1);
      setTypeBudget([{ value: 1, label: 'budgetPage.types.new' }]);
    }

    if (searchParams.get('option') === '2') {
      setValue('budgetType', 2);
      setTypeBudget([{ value: 2, label: 'budgetPage.types.request' }]);
    }
  }, [searchParams]);

  return {
    control,
    errors,
    sellers,
    expDays,
    currentLanguage,
    onSubmit: handleSubmit(onSubmit),
    today,
    disabledSelect: !isReseller,
    customLabel,
    typeBudget,
    dateRerequired,
    sellersItems,
  };
}
