import * as yup from 'yup';
import { IShipmentForm } from '../../../../models';

export const initialValues: IShipmentForm = {
  id: null,
  taxationTo: '',
  // serviceId: null,
  transportationId: null,
  origin: '',
  destiny: '',
  // supplierId: null,
  // serviceName: '',
  serviceType: null,
  // serviceAmount: 0,
  // priceCost: 0,
  // porcentGain: null,
  // iva: 0,
  // sellerEarns: null,
  // price: 0,
  // amountOfIva: 0,
  // amountOfService: 0,
  addressOrigin: '',
  addressDestiny: '',
  content: '',
  unitOfMeasurement: 'cmkg',
  length: 0,
  high: 0,
  width: 0,
  area: 0,
  physicalWeight: 0,
  volumetricWeight: 0,
  incoterms: '',
  comments: '',
  serial: '',
  // ivaCharge: false,
  services: [],
  insurance: 0,
};

export const schemaValidation = yup
  .object()
  .shape({
    origin: yup.string().required('required.origin'),
    destiny: yup.string().required('required.destiny'),
    addressOrigin: yup.string().required('required.addressOrigin').max(250, 'errors.maxLength'),
    addressDestiny: yup.string().required('required.addressDestiny').max(250, 'errors.maxLength'),
    content: yup.string().required('required.content').max(250, 'errors.maxLength').matches(/\w/, 'errors.required'),
    // serviceAmount: yup.number().typeError('errors.required'),
  })
  .required();
