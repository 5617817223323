import { Button, Card, CardContent, Grid, IconButton, Typography } from '@mui/material';
import { TextForm, DatePicker, SelectInput } from '../../../../components';
import useGAManifiesto from './use-GAManifiesto';
import { useLocation } from 'react-router-dom';
import CancelIcon from '@mui/icons-material/Cancel';
import { useTranslation } from 'react-i18next';

const GAManifiestoForm = () => {
  const { control, contentFields, contentAppend, countryItems, contentRemove, onSubmit, documentId, navigate } =
    useGAManifiesto();

  const location = useLocation();

  const { t, i18n } = useTranslation('common');

  return (
    <Card>
      <CardContent>
        <Grid container spacing={2} rowGap={2}>
          <Grid item xs={12}>
            <TextForm
              translationFile='validations'
              control={control}
              label={t('BL.invoiceairwill', { ns: 'placeholder' })}
              name='invoice'
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <DatePicker control={control} name='date' label={t('airGuide.date', { ns: 'placeholder' })} />
          </Grid>

          <Grid item xs={12} sm={6}>
            <DatePicker control={control} name='arrival' label={t('BL.arrival', { ns: 'placeholder' })} />
          </Grid>

          <Grid item xs={12} sm={6}>
            <SelectInput
              disabled
              control={control}
              name='origin'
              label={t('pdfs.countryOrigin', { ns: 'placeholder' })}
              items={countryItems}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <SelectInput
              disabled
              control={control}
              name='destination'
              label={t('pdfs.countryDestiny', { ns: 'placeholder' })}
              items={countryItems}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography sx={{ textAlign: 'center' }} variant={'subtitle1'}>
              {t('airGuide.shipper', { ns: 'placeholder' })}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography sx={{ textAlign: 'center' }} variant={'subtitle1'}>
              {t('pdfs.consignatario', { ns: 'placeholder' })}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextForm
              translationFile='validations'
              control={control}
              label={t('airGuide.name', { ns: 'placeholder' })}
              name='nameshipper'
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextForm
              translationFile='validations'
              control={control}
              label={t('airGuide.name', { ns: 'placeholder' })}
              name='nameconsignee'
              // disabled
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextForm
              translationFile='validations'
              control={control}
              label={t('airGuide.address', { ns: 'placeholder' })}
              name='addressshipper'
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextForm
              translationFile='validations'
              control={control}
              label={t('airGuide.address', { ns: 'placeholder' })}
              name='addressconsignee'
              // disabled
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextForm
              translationFile='validations'
              control={control}
              label={t('airGuide.email', { ns: 'placeholder' })}
              name='mailshipper'
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextForm
              translationFile='validations'
              control={control}
              label={t('airGuide.email', { ns: 'placeholder' })}
              name='mailconsignee'
              // disabled
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextForm
              translationFile='validations'
              control={control}
              label={t('airGuide.phone', { ns: 'placeholder' })}
              name='phoneshipper'
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextForm
              translationFile='validations'
              control={control}
              label={t('airGuide.phone', { ns: 'placeholder' })}
              name='phoneconsignee'
              // disabled
            />
          </Grid>

          <Grid item xs={12}>
            <Grid container columnSpacing={1} rowSpacing={1}>
              <Grid item xs={12} sx={{ textAlign: 'center' }}>
                <Typography sx={{ textAlign: 'center' }} variant={'subtitle1'}>
                  {t('general.content')}
                </Typography>
              </Grid>

              {contentFields.map((field, index) => (
                <Grid key={field.id} item xs={12}>
                  <Card variant='outlined' sx={{ p: 5, position: 'relative' }}>
                    <Grid container columnSpacing={2} rowSpacing={4}>
                      <Grid item xs={12} sm={6} md={4}>
                        <TextForm
                          translationFile='validations'
                          control={control}
                          label={t('BL.hawb', { ns: 'placeholder' })}
                          name={`content.${index}.hawb`}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6} md={4}>
                        <TextForm
                          translationFile='validations'
                          control={control}
                          label={t('airGuide.quantity', { ns: 'placeholder' })}
                          name={`content.${index}.quantity`}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6} md={4}>
                        <TextForm
                          translationFile='validations'
                          control={control}
                          label={t('airGuide.weight', { ns: 'placeholder' })}
                          name={`content.${index}.weight`}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <TextForm
                          translationFile='validations'
                          control={control}
                          label={t('BL.carrier', { ns: 'placeholder' })}
                          name={`content.${index}.carrier`}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <TextForm
                          translationFile='validations'
                          control={control}
                          label={t('airGuide.consignee', { ns: 'placeholder' })}
                          name={`content.${index}.consignee`}
                        />
                      </Grid>

                      {index > 0 && (
                        <Grid item xs={12}>
                          <IconButton
                            onClick={() => {
                              contentRemove(index);
                            }}
                            color='error'
                            sx={{
                              position: 'absolute',
                              bottom: 0,
                              left: 0,
                              fontSize: '16px',
                              paddingLeft: '32px',
                              paddingTop: '4px',
                            }}
                          >
                            <CancelIcon />
                            {t('general.deleteContent')}
                          </IconButton>
                        </Grid>
                      )}
                    </Grid>
                  </Card>
                </Grid>
              ))}

              <Grid item xs={12} sx={{ textAlign: 'center' }}>
                <Button
                  sx={{ color: '#00ef14', margin: 'auto' }}
                  type='button'
                  disabled={contentFields.length >= 8}
                  onClick={() => {
                    contentAppend({
                      hawb: '',
                      carrier: '',
                      consignee: '',
                      quantity: null,
                      weight: null,
                    });
                  }}
                >
                  <i className='fa-solid fa-circle-plus text-xl text-green-500 mr-2' />
                  <p>{t('airGuide.addline', { ns: 'placeholder' })}</p>
                </Button>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'row' },
              justifyContent: 'center',
              alignItems: 'center',
              gap: 2,
            }}
          >
            <Button variant='contained' onClick={onSubmit}>
              {documentId === null
                ? i18n.language === 'es'
                  ? 'Guardar'
                  : 'Save'
                : t('budgetGuide.saveDocumentButtonTittle')}
            </Button>

            {documentId !== null && (
              <Button
                variant='contained'
                onClick={() => navigate(documentId, location.pathname + '&value=8')}
                color='secondary'
              >
                {t('budgetGuide.viewDocumentButtonTittle')}
              </Button>
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default GAManifiestoForm;
