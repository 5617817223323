// @Models
import { IShipment, IShipmentService, IShipmentRequestForm } from '../../../../models';

interface IParams {
  shipment: IShipmentRequestForm;
  pickups: IShipmentService[];
}

export function shipmentAdapter({ shipment, pickups }: IParams): IShipment[] {
  const shipments: IShipment[] = pickups.map(pickup => ({
    ...shipment,
    ...pickup,
    incoterms: '',
    ivaTotal: 0,
    subTotal: 0,
    total: 0,
    services: [],
  }));

  return shipments;
}
