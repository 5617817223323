import { useTranslation } from 'react-i18next';

// @MUI Imports
import { Box, SxProps, Typography } from '@mui/material/';

// @Icon Imports
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';

// @Interfaces and Types
import { DefaultTFuncReturn } from 'i18next';
interface IProps {
  text?: string | DefaultTFuncReturn;
  icon?: any;
  sx?: SxProps;
}

/**
 * A reusable EmptyData component that displays a message when there is no data to show. It can be customized with a text and an icon.
 *
 * @component
 * @subcategory Global
 *
 * @description
 * #### Example
 * ```tsx
 * import { EmptyData } from './EmptyData';
 *
 * return(
 *  <EmptyData text="No data found" icon={<ContentPasteSearchIcon />} />
 * )
 * ```
 *
 * @property {string | DefaultTFuncReturn} [text] The text to show.
 * @property {any} [icon=undefined] An icon to show.
 * @property {SxProps} [sx={}] Extra sx props.
 *
 * @return {JSX.Element} Returns a Box component that contains an icon and a text.
 * @author CML Exports Front-End Developers
 */
export default function EmptyData({ text, icon = undefined, sx = {} }: IProps) {
  const { t } = useTranslation(['common']);

  return (
    <Box
      sx={{
        p: 5,
        width: '90%',
        ml: 'auto',
        mr: { xs: 0.5, md: 'auto' },
        zIndex: 1,
        mt: '25vh',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        ...sx,
      }}
    >
      {icon || <ContentPasteSearchIcon sx={{ color: '#afafaf', fontSize: '4rem' }} />}
      <br />
      <Typography variant='h2' sx={{ textAlign: 'center', fontSize: '1.4rem', color: '#afafaf' }}>
        {text || t('general.noRows', { ns: 'validations' })}
      </Typography>
    </Box>
  );
}
