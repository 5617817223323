import { useTranslation } from 'react-i18next';

// * HOOKS
import { useServices } from '../hooks/useServices';

// * MUI
import { Box } from '@mui/material';

// * COMPONENTS
import ServiceItem from './ServiceItem';
import Loader from '../../../components/Loader';
import Pagination from '../../../components/Pagination';
import TableComponent from '../../../components/TableContainer';

export default function ServicesList() {
  const { servicesData, loadingData, handlePage, handlePageSize } = useServices();

  const { t } = useTranslation(['common']);

  return (
    <Box>
      {loadingData ? (
        <Loader text={`${t('services.loader')}`} />
      ) : (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            gap: 5,
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          {servicesData && (
            <>
              <TableComponent
                Row={ServiceItem}
                data={servicesData.services}
                headers={[
                  `${t('services.table.category')}`,
                  `${t('services.table.typeService')}`,
                  `${t('services.table.service')}`,
                  `${t('services.table.actions')}`,
                ]}
              />

              <Pagination
                count={servicesData.total}
                page={servicesData.page - 1}
                pageSize={servicesData.pageSize}
                setPage={(pageNum: number) => handlePage(pageNum + 1)}
                setPageSize={handlePageSize}
              />
            </>
          )}
        </Box>
      )}
    </Box>
  );
}
