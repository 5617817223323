import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// @Utils
import CustomSwalAlert from '../../utils/CustomSwalAlert';

// @Services
import { updateStatusInvoice, getInvoices, getBillsCustomers } from './service/http';

// @Interfaces and Types
import { IBillResponse } from './models/Models';

// @Initial states
const initialFiltersForm = { typeFilter: 1, customerFilter: 0 };

export default function useInvoices() {
  // NOTE: PERMISSIONS
  const [permissions, setPermissions] = useState<string[]>([]);
  // NOTE: STATUS
  const [noRows, setNoRows] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  // NOTE: PAGINATION
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(9);
  // NOTE: FILTERS AND SEARCH
  const [typeFilter, setTypeFilter] = useState(1);
  const [searchValue, setSearchValue] = useState('');
  // NOTE: DATA
  const [totalBills, setTotalBills] = useState(0);
  const [billType, setBillType] = useState<number[]>([]); // 1 = FF - 2 = CFF - 3 = CML(USA)
  const [customerItems, setCustomerItems] = useState([]);
  const [invoiceData, setInvoicesData] = useState<IBillResponse['billsData']>([]);

  const { control } = useForm({ defaultValues: initialFiltersForm });

  const { t } = useTranslation('common');

  const location = useLocation();

  // NOTE: ALL ACTIONS
  const getInvoicesData = async (folioCode?: string, idCustomer?: number) => {
    try {
      if (!billType.length) return;

      setIsLoading(true);

      const invoiceData: IBillResponse = await getInvoices(billType, page, folioCode, idCustomer);

      setPermissions(Object.keys(invoiceData.permissions));

      const invoiceDataMapped = invoiceData.billsData.map(bill => {
        if (!bill.payments.length) return bill;

        const totalPaymentsAmount =
          bill.payments
            .map(payment => (payment.type === 'income' ? payment.receivedAmount : 0))
            .reduce((prev, next) => (prev || 0) + (next || 0)) || 0;

        bill.totalPaid = totalPaymentsAmount;
        bill.totalToPay = bill.totalAmount - totalPaymentsAmount;

        return bill;
      });

      !invoiceDataMapped.length ? setNoRows(true) : setNoRows(false);

      setInvoicesData(invoiceDataMapped);

      if (typeof invoiceData.totalBills !== 'number') setTotalBills(0);
      else setTotalBills(invoiceData.totalBills);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const getCustomersWithBills = async () => {
    const customersWithBill = await getBillsCustomers(billType);
    setCustomerItems(customersWithBill);
  };

  // NOTE: HANDLE FUNCTIONS
  const handleSetPage = (newPage: number) => setPage(newPage);

  const handleCustomerFilter = (value: number) => getInvoicesData(undefined, value);

  const handleCancel = async (id: number, hasPayments: boolean) => {
    if (hasPayments && permissions.includes('cancel')) {
      CustomSwalAlert(t('general.notAllowed'), t('general.noCancelable'), 'error', false);
    } else {
      CustomSwalAlert(t('general.confirmDelete'), t('general.areYouSure'), 'warning', true, async () => {
        try {
          const response = await updateStatusInvoice(id);

          if (response === 'Success') {
            CustomSwalAlert(t('general.success'), t('general.invoiceCanceled'), 'success', false);
            getInvoicesData();
          } else throw response;
        } catch (error) {
          console.error('Error al eliminar la factura', error);
          CustomSwalAlert(t('general.error'), t('general.deleteFailed'), 'error', false);
        }
      });
    }
  };

  // NOTE: ALL USE EFFECTS
  useEffect(() => {
    setPage(1);
    if (location.pathname === '/invoices') setBillType([1, 2]);
    if (location.pathname === '/invoices/us-cml') setBillType([3]);
    if (location.pathname === '/invoices/ctis') setBillType([4]);
  }, [location.pathname]);

  useEffect(() => {
    getCustomersWithBills();
  }, [billType]);

  useEffect(() => {
    if (billType) getInvoicesData(searchValue);
  }, [page, pageSize, billType]);

  // useEffect(() => {
  //   setSearchValue('');
  // }, [page]);

  useEffect(() => {
    if (searchValue) getInvoicesData(searchValue, undefined);
    else getInvoicesData();
  }, [searchValue]);

  return {
    // NOTE: PERMISSIONS
    permissions,
    // NOTE: STATUS
    isLoading,
    noRows,
    // NOTE: FORM CONTROL
    control,
    // NOTE: DATA
    invoiceData,
    // NOTE: PAGINATION
    page,
    pageSize,
    setPageSize,
    totalBills,
    // NOTE: FILTERS
    typeFilter,
    setTypeFilter,
    customerItems,
    searchValue,
    setSearchValue,
    // NOTE: HANDLE FUNCTIONS
    handleSetPage,
    handleCancel,
    handleCustomerFilter,
  };
}
