import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';

// @Hooks
import { useFileView } from './useFilesView';

// @MUI Components
import { Card, Grid, Box, IconButton, Pagination } from '@mui/material';
import { ArrowLeft } from 'mdi-material-ui';

// @Components
import EmptyData from '../../components/EmptyData';

export const FileView = () => {
  const {
    // NOTE: DATA
    savedFiles,
    currentFile,
    filesPerPage,
    // NOTE: PAGINATION
    page,
    // NOTE: HANDLE FUNCTIONS
    handleBack,
    handleChangePage,
  } = useFileView();

  return (
    <Grid container sx={{ width: '90%', ml: 'auto', mr: { xs: 0.5, md: 'auto' }, zIndex: 1 }}>
      <Card
        sx={{
          p: '2%',
          mb: 20,
          width: '100%',
          display: 'flex',
          flexWrap: 'wrap',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <IconButton sx={{ display: 'flex', mt: 1 }} color='error' onClick={handleBack}>
          <ArrowLeft />
        </IconButton>

        <Grid container spacing={2}>
          {currentFile.length ? (
            currentFile.map(file => (
              <Grid item xs={12} key={file.fileName} sx={{ display: 'flex', justifyContent: 'center' }}>
                <Card sx={{ width: '100%', height: '650px', display: 'flex', justifyContent: 'center' }}>
                  <DocViewer
                    documents={[
                      {
                        fileData: file.src,
                        uri: file.src,
                        fileName: file.fileName,
                        fileType: file.fileName.split('.').pop(),
                      },
                    ]}
                    pluginRenderers={DocViewerRenderers}
                  />
                </Card>
              </Grid>
            ))
          ) : (
            <EmptyData />
          )}
        </Grid>

        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
          <Pagination count={Math.ceil(savedFiles.length / filesPerPage)} page={page} onChange={handleChangePage} />
        </Box>
      </Card>
    </Grid>
  );
};
