import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import { IGetBudgets } from '../../../../models';
// import { Chip } from '@mui/material';
import { format } from 'date-fns';

interface RenderCell {
  row: IGetBudgets;
}

interface chipItem {
  value: string | number;
  label: string;
  color: 'default' | 'success' | 'info' | 'error' | 'primary' | 'secondary' | 'warning';
}

export const defaultColumns = () => {
  const { t } = useTranslation(['common']);
  const statusItem: chipItem[] = [
    {
      value: 'active',
      color: 'success',
      label: `${t('general.active')}`,
    },
    {
      value: 'pending',
      color: 'info',
      label: `${t('general.pending')}`,
    },
    {
      value: 'cancel',
      color: 'error',
      label: `${t('general.cancelled')}`,
    },
    {
      value: 'review',
      color: 'primary',
      label: `${t('general.review')}`,
    },
    {
      value: 'request',
      color: 'warning',
      label: `${t('general.request')}`,
    },
    {
      value: 'initialized',
      color: 'warning',
      label: `${t('general.initialized')}`,
    },
    {
      value: 'approved',
      color: 'success',
      label: `${t('general.approved')}`,
    },
    {
      value: 'rejected',
      color: 'error',
      label: `${t('general.rejected')}`,
    },
    {
      value: 'toBeConfirmed',
      color: 'info',
      label: `${t('general.toBeConfirmed')}`,
    },
    {
      value: 'finished',
      color: 'success',
      label: `${t('general.finished')}`,
    },
  ];
  return [
    {
      field: 'customer',
      flex: 0.8,
      headerName: `${t('budgetPage.customer')}`,
      renderCell: ({ row }: RenderCell) => {
        return (
          <Typography
            noWrap
            component='a'
            variant='body2'
            sx={{
              fontWeight: 600,
              color: 'text.primary',
              textDecoration: 'none',
            }}
          >
            {row.customerName}
          </Typography>
        );
      },
    },
    {
      field: 'code',
      headerName: `${t('budgetPage.code')}`,
      renderCell: ({ row }: RenderCell) => {
        return (
          <Typography
            noWrap
            sx={{
              // textTransform: 'capitalize',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {row.code}
          </Typography>
        );
      },
    },
    {
      field: 'value',
      headerName: `${t('budgetPage.expDate')}`,
      renderCell: ({ row }: RenderCell) => {
        return (
          <Typography
            noWrap
            sx={{
              // textTransform: 'capitalize',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {format(new Date(row.expirationDate), 'MM/dd/yyyy')}
          </Typography>
        );
      },
    },
    // {
    //   field: 'status',
    //   flex: 0.3,
    //   headerName: `${t('budgetPage.status')}`,
    //   renderCell: ({ row }: RenderCell) => {
    //     const status = statusItem.find(status => status.value === row.status);

    //     return (
    //       <Typography
    //         noWrap
    //         sx={{
    //           // textTransform: 'capitalize',
    //           display: 'flex',
    //           alignItems: 'center',
    //         }}
    //       >
    //         <Chip
    //           size='medium'
    //           label={status?.label || ''}
    //           color={status?.color}
    //           sx={{
    //             textTransform: 'capitalize',
    //             padding: '0.5rem',
    //             margin: '0 0.5rem',
    //           }}
    //         />
    //       </Typography>
    //     );
    //   },
    // },
  ];
};
