import { UseFormSetValue } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// @MUI Components
import { Box, Button, InputAdornment, TextField, Typography } from '@mui/material';

// @MUI Icons
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';

// @Interfaces and Types
import { PaymentType } from '../types';
interface IProps {
  search: string;
  setValue: UseFormSetValue<PaymentType>;
  totalToPay: number;
  currentTab: 'payments' | 'expenses' | 'commissions';
  addPaymentTitle: { name: string; ESname: string };
  addExpenseTitle: { name: string; ESname: string };
  handleToggleForm: () => void;
  handleSearch: (e?: any) => void;
}

export default function ActionsBar({
  search,
  setValue,
  totalToPay,
  currentTab,
  addPaymentTitle,
  addExpenseTitle,
  handleToggleForm,
  handleSearch,
}: IProps) {
  const { t, i18n } = useTranslation('common');

  const navigate = useNavigate();

  const getTitle = () => {
    if (currentTab === 'payments') {
      return i18n.language === 'es' ? addPaymentTitle.ESname : addPaymentTitle.name;
    } else {
      return i18n.language === 'es' ? addExpenseTitle.ESname : addExpenseTitle.name;
    }
  };

  const cleanSearch = () => {
    handleSearch();
    document.getElementById('search-field')?.focus();
  };

  return (
    <Box
      sx={{
        p: 4,
        width: '100%',
        display: 'flex',
        gap: { xs: 4, sm: 0 },
        alignItems: 'center',
        justifyContent: 'space-between',
        flexDirection: { xs: 'column', sm: 'row' },
        fieldset: {
          borderColor: ({ palette }) => `${palette.primary.main} !important`,
          borderWidth: '2px',
        },
      }}
    >
      <TextField
        id='search-field'
        value={search}
        onChange={handleSearch}
        autoComplete='off'
        label={currentTab === 'payments' ? t('general.searchPaymentsReference') : t('general.searchExpensesInvoice')}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end' onClick={cleanSearch} sx={{ cursor: 'pointer' }}>
              <CloseIcon sx={{ fontSize: '1.6rem' }} />
            </InputAdornment>
          ),
          startAdornment: (
            <InputAdornment position='start'>
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />

      {(currentTab === 'payments' || currentTab === 'expenses') && (
        <Button
          size='large'
          color='primary'
          variant='contained'
          disabled={currentTab === 'payments' && Math.sign(totalToPay) !== 1}
          onClick={() => {
            if (currentTab === 'payments') {
              setValue('limitAmount', totalToPay);
              handleToggleForm();
            } else navigate('/expenses/create');
          }}
          sx={{ width: { xs: 300, sm: 'auto' }, py: { xs: 1.5, sm: 'auto' } }}
        >
          <AddIcon />

          <Typography sx={{ textTransform: 'capitalize' }}>{getTitle()}</Typography>
        </Button>
      )}
    </Box>
  );
}
