// @Utils
import { axiosEstimates } from '../../../utils';

// @Interfaces and Types
import { ISupplierBudget } from '../../../models';

const getBudgetById = async (idSupplierBudget: string) => {
  try {
    const response = await axiosEstimates.get<{ budget: ISupplierBudget }>(`supplier-budget/${idSupplierBudget}`);
    const fileResponse = await axiosEstimates.get(`/file-suppliers/${idSupplierBudget}`);

    const budget = response.data.budget;

    const data = { budget, budgetFiles: fileResponse.data };

    return data;
  } catch (err) {
    console.error(err);
    return null;
  }
};

export default getBudgetById;
