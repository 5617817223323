// @MUI Components
import { Card, CardHeader, Button, Divider, Grid, Box, useTheme, useMediaQuery } from '@mui/material';

// @MUI Icons
import AddIcon from '@mui/icons-material/Add';

// @Hooks
import useExpenses from './useExpenses';

// @Components
import { SearchField } from '../../components';
import ExpensesTable from './components/ExpensesTable';
import StatusRadioButtons from './components/StatusRadioButtons';

export function Expenses() {
  const {
    t,
    expenses,
    createExpense,
    handleFilter,
    filter,
    handleStatus,
    changeStatus,
    expensesHasDocuments,
    permissions,
  } = useExpenses();

  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <Grid container sx={{ width: '90%', ml: 'auto', mr: { xs: 0.5, md: 'auto' }, zIndex: 1 }}>
      <StatusRadioButtons handleStatus={handleStatus} />

      <Card sx={{ width: '100%', pt: 3 }}>
        <CardHeader title={t('general.expenses')} sx={{ display: 'flex', textAlign: 'center' }} />

        <Divider sx={{ py: 1.5 }} />

        <Grid container gap={2} sx={{ p: 2.5, display: 'flex', justifyContent: 'space-between' }}>
          <Grid item xs={9} sm={5} md={6}>
            <Box sx={{ display: 'flex', gap: 4, justifyContent: 'flex-start' }}>
              <SearchField
                value={filter}
                handleFilter={handleFilter}
                placeholder={t('general.search', { ns: 'common' })}
              />
            </Box>
          </Grid>

          <Grid item xs={2} sm={4} sx={{ display: 'flex', justifyContent: { xs: 'center', sm: 'flex-end' } }}>
            {permissions && permissions.add && (
              <Button size='medium' variant='contained' onClick={createExpense}>
                <AddIcon />
                {matches && t('expenses.title')}
              </Button>
            )}
          </Grid>
        </Grid>

        <ExpensesTable
          rows={expenses}
          changeStatus={changeStatus}
          expensesHasDocuments={expensesHasDocuments}
          canEdit={permissions && permissions.edit && true}
          canAprove={permissions && permissions.aprove && true}
          canCancel={permissions && permissions.cancel && true}
        />
      </Card>
    </Grid>
  );
}
