import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

// @Validations
import { serviceTypeValidationSchema } from '../validations';

// @Hooks
import { useServices } from './useServices';

import { axiosEstimates } from '../../../utils';
import CustomSwalAlert from '../../../utils/CustomSwalAlert';

// @Interfaces and Types
import { TypeServiceFormValues } from '../def';

export default function useTypeServiceForm() {
  const { defaultServiceTypeValues, handleToggleTypeForm, setReloadData } = useServices();

  const { handleSubmit, control, setValue, reset } = useForm<TypeServiceFormValues>({
    resolver: yupResolver(serviceTypeValidationSchema),
    defaultValues: defaultServiceTypeValues,
  });

  const { t } = useTranslation('common');

  const onSubmit = handleSubmit(async data => {
    try {
      axiosEstimates.put('api/service/type/update', data);
      handleToggleTypeForm();
      reset();
      CustomSwalAlert(t('general.success'), t('services.alerts.updatedTypeService'), 'success', false);
    } catch (err) {
      console.log(err);
    } finally {
      setTimeout(() => setReloadData(true), 300);
    }
  });

  const setDefaultValues = (data: TypeServiceFormValues) => {
    setValue('id', data.id);
    setValue('name', data.name);
    setValue('nameEN', data.nameEN);
    setValue('categories', data.categories);
  };

  useEffect(() => {
    setDefaultValues(defaultServiceTypeValues);
  }, [defaultServiceTypeValues]);

  return { onSubmit, control, Controller };
}
