// @MUI Components
import { Box, Typography, IconButton, TableCell, TableRow } from '@mui/material';

// @MUI Icons
import DeleteOutline from 'mdi-material-ui/DeleteOutline';
import PencilOutline from 'mdi-material-ui/PencilOutline';

// @Utils
import { formatNumber } from '../../../utils/format-number.util';

// @Models
import { IAddExpense } from '../../../models';

interface IRowProps {
  handleEdit: (value?: IAddExpense) => void;
  handleDelete: (id: number) => void;
  row: any;
}

const Row = ({ row, handleEdit, handleDelete }: IRowProps) => {
  return (
    <TableRow>
      <TableCell>
        <Box sx={{ display: 'flex', alignItems: 'center', padding: 2 }}>
          <IconButton onClick={() => handleEdit(row)}>
            <PencilOutline fontSize='medium' />
          </IconButton>
          <IconButton onClick={() => handleDelete(row.id)}>
            <DeleteOutline fontSize='medium' />
          </IconButton>
        </Box>
      </TableCell>

      <TableCell>
        <Typography noWrap variant='body2'>
          {row.description}
        </Typography>
      </TableCell>

      <TableCell>
        <Typography noWrap sx={{ textTransform: 'capitalize', display: 'flex', alignItems: 'center' }}>
          {row.quantity}
        </Typography>
      </TableCell>

      <TableCell>
        <Typography noWrap sx={{ textTransform: 'capitalize', display: 'flex', alignItems: 'left' }}>
          {formatNumber(row.unitPrice)}
        </Typography>
      </TableCell>

      <TableCell>
        <Typography noWrap sx={{ textTransform: 'capitalize', display: 'flex', alignItems: 'center' }}>
          {formatNumber(row.subTotal)}
        </Typography>
      </TableCell>

      <TableCell>
        <Typography noWrap sx={{ textTransform: 'capitalize', display: 'flex', alignItems: 'center' }}>
          {formatNumber(row.iva)}
        </Typography>
      </TableCell>

      <TableCell>
        <Typography noWrap sx={{ textTransform: 'capitalize', display: 'flex', alignItems: 'center' }}>
          {formatNumber(row.ivaRet)}
        </Typography>
      </TableCell>

      <TableCell>
        <Typography noWrap sx={{ textTransform: 'capitalize', display: 'flex', alignItems: 'center' }}>
          {formatNumber(row.total)}
        </Typography>
      </TableCell>
    </TableRow>
  );
};

export default Row;
