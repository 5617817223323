import { useTranslation } from 'react-i18next';

// @MUI Components
import { Box, Grid, Card, Button, useTheme, useMediaQuery } from '@mui/material/';
import { Plus } from 'mdi-material-ui';

// @Hooks
import { useRequestedBudgets } from './use-requested-budgets.hook';

// @Components
import { SearchField } from '../../components';
import { Comments } from '../../views/budgets/comments/comments.view';
import CollapsibleBudget from '../Budget/components/CollapsibleBudget';

// @Utils
// import { socket } from '../../utils/sockets.util';

export function RequestedBudgets() {
  const {
    // NOTE: PERMISSIONS
    permissions,
    // NOTE: PAGINATION
    page,
    perPage,
    setPage,
    setPerPage,
    count,
    // NOTE: STATUS
    isLoading,
    // NOTE: DATA
    idBudget,
    budgets,
    value,
    // NOTE: HANDLE FUNCTIONS
    handleFilter,
    toggleComments,
    openComments,
    changeStatus,
  } = useRequestedBudgets();

  const { t, i18n } = useTranslation('common');

  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      {permissions.includes('comments') && <Comments open={openComments} toggle={toggleComments} idBudget={idBudget} />}

      <Grid container sx={{ width: '90%', ml: 'auto', mr: { xs: 0.5, md: 'auto' }, zIndex: 1 }}>
        <Grid item xs={12}>
          <Card sx={{ width: '100%' }}>
            <Box
              sx={{ p: 5, display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'space-between' }}
            >
              <Box sx={{ display: 'flex', gap: 4, justifyContent: 'flex-end' }}>
                <SearchField value={value} handleFilter={handleFilter} placeholder={t('general.search')} />
              </Box>

              {permissions.includes('add') && (
                <Button
                  variant='contained'
                  href={'budgets/create/?type=1&option=2'}
                  startIcon={!matches && <Plus sx={{ scale: '1.2' }} />}
                >
                  {!matches ? i18n.language === 'es' ? 'Agregar' : 'Add' : <Plus sx={{ scale: '1.2' }} />}
                </Button>
              )}
            </Box>

            <CollapsibleBudget
              isSV={false}
              isUSA={false}
              isCTIS={false}
              permissions={permissions}
              data={budgets}
              count={count}
              isLoading={isLoading}
              rowsPerPage={perPage}
              page={page}
              setPage={setPage}
              setRowsPerPage={setPerPage}
              toggleComments={toggleComments}
              cancelStatus={changeStatus}
            />
          </Card>
        </Grid>
      </Grid>
    </>
  );
}
