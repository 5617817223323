// @Utils
import { axiosEstimates } from '../../../utils';

// @Interfaces and Types
import { suppliersResponseType } from '../types';
interface IProps {
  (filter?: string, status?: number | string): Promise<any>;
}

const getSuppliers: IProps = async (filter?: string, status?: number | string) => {
  try {
    const endpoint = `suppliers?filter=${filter || ''}&status=${status || 1}`;

    const { data } = await axiosEstimates.get<suppliersResponseType>(endpoint, {
      headers: {
        'x-module': 'suppliers',
      },
    });

    const suppliers = data.suppliers.map(supplier => ({ value: supplier.id, label: supplier.name }));

    return suppliers;
  } catch (err) {
    console.error(err);
  }
};

export default getSuppliers;
