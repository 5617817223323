import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// @Mui
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import { IconButton, Typography } from '@mui/material';
import KeyboardBackspace from '@mui/icons-material/KeyboardBackspace';

// @Hooks
import { useHistory } from './useSupplierServicesHistory';

// @Utils
import ServicesupplierTable from './components/supplierhistory.table';
import CollapsibleTable from './components/supplier-service.collapsible';

/**
 * @component SupplierServiceHistory
 * @subcategory Pages
 *
 * @returns {JSX} This component return a Box with buttons
 * @author CML Exports Front-End Developers
 */
export const SuppServHistory = () => {
  const { t }: any = useTranslation(['common']);

  const { history, servsuup } = useHistory();

  const navigate = useNavigate();

  return (
    <Box style={{ width: '100%' }}>
      <Grid item xs={12}>
        <Card sx={{ mb: 2 }}>
          <IconButton sx={{ display: 'flex', mt: 1 }} color='error' onClick={() => navigate('/suppliers')}>
            <KeyboardBackspace />
          </IconButton>
          <CardHeader title={t('general.services')} />
          <CardContent>
            <ServicesupplierTable data={servsuup} />
          </CardContent>
        </Card>
        <Card>
          <Box sx={{ m: 2 }} />
          <Box
            sx={{
              p: 5,
              display: 'flex',
              flexWrap: 'wrap',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Box sx={{ display: 'flex', gap: 4, justifyContent: 'flex-end' }}>
              <Typography variant='h5' sx={{ textAlign: 'center' }}>
                {' '}
                {t('general.logbook')}
              </Typography>
            </Box>
          </Box>
          <CardContent>
            <CollapsibleTable history={history} />
          </CardContent>
        </Card>
      </Grid>
    </Box>
  );
};
