import { useState, useEffect } from 'react';
import { axiosEstimates } from '../../../utils';
import { useServices } from './useServices';

export default function useStatus(serviceId: number) {
  const [status, setStatus] = useState<'active' | 'inactive'>();
  const { setReloadData } = useServices();

  const handleStatus = (value: 'active' | 'inactive') => {
    setStatus(value);
  };

  useEffect(() => {
    if (serviceId && status) {
      axiosEstimates
        .patch(`api/services/status/${serviceId}`, { status })
        .then(response => {
          setStatus(response.data.status);
          setReloadData(true);
        })
        .catch(error => {
          console.error(error);
        });
    }
  }, [serviceId, status]);

  return { status, handleStatus };
}
