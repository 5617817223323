import { useTranslation } from 'react-i18next';

// @MUI Components
import { Card, Divider, Grid } from '@mui/material';

// @Components
import CustomTabs from './CustomTabs';
import ActionsBar from './ActionsBar';
import CardExpense from './CardExpense';
import CardPayment from './CardPayment';
import EmptyData from '../../../components/EmptyData';
import { BudgetCommissionsView } from '../../BudgetView/budget-commissions';

// @Interfaces and Types
import { UseFormSetValue } from 'react-hook-form';
import { ExpenseType, InvoiceType, PaymentType, PaymentTypeType, TabsType } from '../types';

interface IProps {
  invoiceData?: InvoiceType;
  setValue: UseFormSetValue<PaymentType>;
  search: string;
  totalToPay: number;
  addPaymentTitle: { name: string; ESname: string };
  payments: PaymentType[] | [];
  addExpenseTitle: { name: string; ESname: string };
  expenses: ExpenseType[] | [];
  paymentsIsOpen: boolean;
  currentTab: TabsType;
  permissions: string[];
  handleTabs: (event: React.SyntheticEvent, newValue: TabsType) => void;
  handleSearch: (e: any) => void;
  handleToggleForm: () => void;
  handlePayExpense: (id: string, amount: number, date: Date | string) => void;
  handleEditPayment: (id: number | undefined) => void;
  handlePreviewFiles: (paymentId: number) => void;
  handleDeletePayment: (id: number | undefined, type: PaymentTypeType) => Promise<void>;
}

export default function LogsList({
  invoiceData,
  setValue,
  search,
  totalToPay,
  addPaymentTitle,
  payments,
  addExpenseTitle,
  expenses,
  paymentsIsOpen,
  currentTab,
  permissions,
  handleTabs,
  handleSearch,
  handleToggleForm,
  handlePayExpense,
  handleEditPayment,
  handlePreviewFiles,
  handleDeletePayment,
}: IProps) {
  const { t } = useTranslation('common');

  return (
    <Card
      sx={{
        width: '100%',
        height: paymentsIsOpen ? { xs: 1800, sm: 1700, md: 1000 } : 0,
        transition: '0.75s ease height',
        overflow: 'hidden',
      }}
    >
      <CustomTabs
        currentTab={currentTab}
        handleTabs={handleTabs}
        canSeePayments={permissions.includes('add_payment')}
        canSeeExpenses={permissions.includes('add_expense')}
        canSeeCommissions={permissions.includes('generate_commission')}
      />

      {currentTab !== 'commissions' && (
        <>
          <ActionsBar
            setValue={setValue}
            search={search}
            totalToPay={totalToPay}
            currentTab={currentTab}
            addPaymentTitle={addPaymentTitle}
            addExpenseTitle={addExpenseTitle}
            handleToggleForm={handleToggleForm}
            handleSearch={handleSearch}
          />

          <Divider sx={{ mt: 1, mb: 6 }} />
        </>
      )}

      {/* Payments */}
      {currentTab === 'payments' && (
        <>
          {!payments || !payments?.length ? (
            <EmptyData text={t('general.paymentsNotFound')} />
          ) : (
            <Grid container item xs={12}>
              {payments.map((payment, i) => (
                <Grid key={i} item xs={12} md={6}>
                  <CardPayment
                    data={payment}
                    handleEditPayment={handleEditPayment}
                    handlePreviewFiles={handlePreviewFiles}
                    handleDeletePayment={handleDeletePayment}
                  />
                </Grid>
              ))}
            </Grid>
          )}
        </>
      )}

      {/* Expenses */}
      {currentTab === 'expenses' && (
        <>
          {!expenses || !expenses?.length ? (
            <EmptyData text={t('general.expensesNotFound')} />
          ) : (
            <Grid container item xs={12}>
              {expenses.map((expense, i) => (
                <Grid key={i} item xs={12} md={6}>
                  <CardExpense data={expense} handlePayExpense={handlePayExpense} />
                </Grid>
              ))}
            </Grid>
          )}
        </>
      )}

      {/* Conmissions */}
      {currentTab === 'commissions' && (
        <BudgetCommissionsView budgetId={invoiceData?.budgetId} invoiceData={invoiceData} permissions={permissions} />
      )}
    </Card>
  );
}
