// @Redux
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/reducers';

export const useRedux = () => {
  const {
    countries: { countries, countryItems },
  } = useSelector((state: RootState) => state);

  return { countries, countryItems };
};
