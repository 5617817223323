import { useEffect, useState } from 'react';

export type Frame = {
  name: string;
  src: string;
};

/**
 * @component
 * @subcategory Global
 *
 * @description
 * A component that displays a frame based on the file type.
 * #### Example
 * ```
 * return(
 *  <FrameViewer name="example.jpg" src="https://example.com/image.jpg" />
 * )
 * ```
 *
 * @property {string} name The name of the file.
 * @property {string} src The source URL of the file.
 *
 * @return {JSX.Element}
 * @author CML Exports Front-End Developers
 */
export default function FrameViewer({ name, src }: Frame) {
  const [type, setType] = useState(1); // 1: iframe, 2: img

  useEffect(() => {
    const extensions = ['png', 'jpg', 'jpeg'];
    const extension = name.split('.')[1];

    if (extensions.includes(extension)) {
      setType(2);
    } else setType(1);
  }, [name]);

  return (
    <>
      {type === 1 ? (
        <embed
          src={src}
          type='application/pdf'
          width='100%'
          style={{ display: 'flex', width: '100%', height: '100%' }}
        />
      ) : (
        <img src={src} />
      )}
    </>
  );
}
