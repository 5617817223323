import { useTranslation } from 'react-i18next';
import { useState, SyntheticEvent, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import toast from 'react-hot-toast';

import { axiosEstimates } from '../../utils/api';
import { socket } from '../../utils/sockets.util';
import { SupplierChat, ChatsObj, Config } from '../../models';
import { CustomFiles } from '../../models/CustomFiles';

export function useSupplierChat() {
  // ** State
  // const [token, setToken] = useState<string | null>(null);
  const [idBudget, setIdBudget] = useState<number | null>(null);
  const [chat, setChat] = useState<ChatsObj | null>(null);
  const [idSupplier, setIdSupplier] = useState<number | null>(null);
  const [config, setConfig] = useState<Config | null>(null);
  const [msg, setMsg] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const [file, setFile] = useState<CustomFiles>(null);
  const [openFileUploader, setOpenFileUploader] = useState(false);

  const { t } = useTranslation('common');

  const handleToggle = () => {
    setOpen(prev => !prev);
  };

  const validateToken = async (token: string, idBudget: string) => {
    const budgetId = parseInt(idBudget);
    try {
      toast.loading(t('toast.loading'), { id: 'loading' });
      const responseToken = await axiosEstimates.get<{ idSupplier: number }>('/supplier-chat-validate-token', {
        headers: { 'x-supplier-token': token },
      });

      const idSupplier = responseToken.data.idSupplier;

      const response = await axiosEstimates.get<{ supplier: SupplierChat; config: Config }>(
        `/supplier-chat/${budgetId}/${idSupplier}`,
      );

      const supplier = response.data.supplier;
      setConfig(response.data.config);

      setIdSupplier(idSupplier);
      setIdBudget(budgetId);
      setChat({
        chat: supplier.supplierChats.map(chat => ({
          message: chat.content,
          username: chat.username,
          senderId: chat.idUser,
          time: chat.created_at,
          isFile: chat.isFile,
          filename: chat.filename || '',
          url: chat.url || '',
        })),
        id: budgetId,
        unseenMsgs: 0,
        userId: supplier.id,
      });
    } catch (error) {
      setIdSupplier(null);
    }
    toast.dismiss();
  };

  useEffect(() => {
    validateToken(searchParams.get('token') || '', searchParams.get('idBudget') || '');
  }, []);

  useEffect(() => {
    socket.on('new-message-supplier:success', () => {
      validateToken(searchParams.get('token') || '', searchParams.get('idBudget') || '');
    });

    return () => {
      socket.off('new-message-supplier:success');
    };
  }, [idBudget]);

  const handleSendMsg = (e: SyntheticEvent) => {
    e.preventDefault();

    if (!idBudget) return null;
    if (!idSupplier) return null;

    const data = {
      idBudget,
      idUser: null,
      content: msg,
      idSupplier,
    };
    socket.emit('new-message-supplier', data);

    setMsg('');
  };

  const handleSendFile = async () => {
    if (file) {
      let filename;
      if ('name' in file) {
        // file es de tipo File
        filename = file.name;
      } else if ('fileName' in file) {
        // file es de tipo fileBase64
        filename = file.fileName;
      }
      const data = {
        idBudget,
        idUser: null,
        idSupplier,
        file: { buffer: file, filename },
      };

      socket.emit('new-file-supplier', data);
      setOpenFileUploader(prev => !prev);
      setFile(null);
    }
  };

  return {
    msg,
    handleSendMsg,
    setMsg,
    chat,
    open,
    handleToggle,
    idBudget,
    handleSendFile,
    config,
    openFileUploader,
    setOpenFileUploader,
    file,
    setFile,
  };
}
