import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

// @Utils
import { axiosEstimates } from '../../../../utils/api';

export default function useHistory() {
  const [suppliersData, setSuppliersData] = useState<any>([]);
  // const [suppDetails, setSuppDetails] = useState<any>([]);

  const { id } = useParams();

  const handleGet = async () => {
    try {
      const response = await axiosEstimates.get<any>('/supplier-history/' + id);
      setSuppliersData(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  // const ActualGet = async () => {
  //   try {
  //     const supplierGET: any = await axios.get(`${process.env.REACT_APP_ESTIMATES_API}supplierdetails/${id}`);

  //     if (!supplierGET?.data?.message) setSuppDetails(supplierGET.data.suppliersDetails);
  //     else setSuppDetails([]);
  //   } catch {
  //     setSuppDetails([]);
  //   }
  // };

  useEffect(() => {
    handleGet();
    // ActualGet();
  }, []);

  return {
    suppliersData,
  };
}
