import * as yup from 'yup';
import { AirGuide } from '../../../../../models';
export const initialValues: AirGuide = {
  referenceCode: '',
  invoiceoperation: '',
  expeditionDate: '',
  issuedBy: '',
  nameshipper: '',
  addressshipper: '',
  mailshipper: '',
  phoneshipper: '',
  nameconsignee: '',
  addressconsignee: '',
  mailconsignee: '',
  phoneconsignee: '',
  iCarriername: '',
  iCarriercity: '',
  accountingInformation: '',
  agentIATACode: '',
  agentAccountNo: '',
  airpotOfDeparture: '',
  referenceNumber: '',
  optionalShippingInformation: '',
  firstTo: '',
  byFirstCarrier: '',
  routingAndDestinitation: '',
  secondTo: '',
  secondBy: '',
  thirdTo: '',
  thirdBy: '',
  currency: '',
  chgs: '',
  prepaid: null,
  collect: null,
  declaredValueForCarriage: '',
  declaredValueForCustoms: '',
  airpotOfDestination: '',
  requestedFlight: '',
  date: '',
  amountOfInsurance: null,
  handlingInformation: '',
  weightCharge: null,
  secondPrepaid: null,
  secondCollect: null,
  valuationCharge: null,
  fuelServices: null,
  security: null,
  tax: null,
  totalOtherChargesDueAgent: null,
  totalOtherChargesDueCarrier: null,
  totalPrepaid: null,
  totalCollect: null,
  carriesrsuse: '',
  chargesatdestinations: '',
  totalCollectCharges: null,
  content: [],
};

export const schemaValidation = yup
  .object()
  .shape({
    referenceCode: yup.string().required('required.general'),
    expeditionDate: yup.string().required('required.general'),
    issuedBy: yup.string().required('required.general'),
    // shipper: yup.number().typeError('required.general').required('required.general'), // Supplier select
    nameshipper: yup.string().required('required.general'),
    addressshipper: yup.string().required('required.general'),
    mailshipper: yup.string().notRequired(),
    phoneshipper: yup.string().notRequired(),
    // consignee: yup.number().typeError('required.general').required('required.general'), // Customer select
    nameconsignee: yup.string().required('required.general'),
    addressconsignee: yup.string().required('required.general'),
    mailconsignee: yup.string().notRequired(),
    phoneconsignee: yup.string().notRequired(),
    agentIATACode: yup.string().notRequired(),
    agentAccountNo: yup.string().required('required.general'),
    // accountingInformation: yup.string().max(255, 'errors.max.255').required('required.general'),
    airpotOfDeparture: yup.string().required('required.general'),
    referenceNumber: yup.string().required('required.general'),
    optionalShippingInformation: yup.string().required('required.general'),
    firstTo: yup.string().required('required.general'),
    byFirstCarrier: yup.string().required('required.general'),
    routingAndDestinitation: yup.string().notRequired(),
    secondTo: yup.string().notRequired(),
    secondBy: yup.string().notRequired(),
    thirdTo: yup.string().notRequired(),
    thirdBy: yup.string().notRequired(),
    currency: yup.string().required('required.general'),
    chgs: yup.string().notRequired(),
    prepaid: yup.number().typeError('required.general').required('required.general'),
    collect: yup.number().typeError('required.general').required('required.general'),
    declaredValueForCarriage: yup.string().required('required.general'),
    declaredValueForCustoms: yup.string().required('required.general'),
    airpotOfDestination: yup.string().required('required.general'),
    requestedFlight: yup.string().required('required.general'),
    date: yup.string().required('required.general'),
    amountOfInsurance: yup.number().typeError('required.general').required('required.general'),
    handlingInformation: yup.string().notRequired(),
    weightCharge: yup.number().typeError('required.general').required('required.general'),
    secondPrepaid: yup.number().typeError('required.general').required('required.general'),
    secondCollect: yup.number().typeError('required.general').required('required.general'),
    valuationCharge: yup.number().typeError('required.general').required('required.general'),
    fuelServices: yup.number().typeError('required.general').required('required.general'),
    security: yup.number().typeError('required.general').required('required.general'),
    tax: yup.number().typeError('required.general').required('required.general'),
    totalOtherChargesDueAgent: yup.number().typeError('required.general').required('required.general'),
    totalOtherChargesDueCarrier: yup.number().typeError('required.general').required('required.general'),
    totalPrepaid: yup.number().typeError('required.general').required('required.general'),
    totalCollect: yup.number().typeError('required.general').required('required.general'),
    totalCollectCharges: yup.number().typeError('required.general').required('required.general'),
    content: yup.array().of(
      yup.object().shape({
        quantity: yup
          .number()
          .notRequired()
          .nullable()
          .transform(value => (isNaN(value) ? null : value)),
        grossWeight: yup
          .number()
          .notRequired()
          .nullable()
          .transform(value => (isNaN(value) ? null : value)),
        kglb: yup.string(),
        itemNumber: yup
          .number()
          .notRequired()
          .nullable()
          .transform(value => (isNaN(value) ? null : value)),
        rateClass: yup
          .number()
          .notRequired()
          .nullable()
          .transform(value => (isNaN(value) ? null : value)),
        chargeableWeight: yup
          .number()
          .notRequired()
          .nullable()
          .transform(value => (isNaN(value) ? null : value)),
        rate: yup
          .number()
          .notRequired()
          .nullable()
          .transform(value => (isNaN(value) ? null : value)),
        total: yup
          .number()
          .notRequired()
          .nullable()
          .transform(value => (isNaN(value) ? null : value)),
        description: yup.string().required('required.general').max(50, 'general.maxContent'),
      }),
    ),
  })
  .required();
