import i18next from 'i18next';
import { Controller, Control, FieldValues, FieldPath } from 'react-hook-form';

// @Mui
import es from 'date-fns/locale/es';
import FormControl from '@mui/material/FormControl';
import TextField, { StandardTextFieldProps } from '@mui/material/TextField';

// @MUI Datepickers
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider, DesktopDatePicker, MobileDatePicker } from '@mui/x-date-pickers/';

// @Utils
import { breakpoints } from '../utils/customTheme.util';

// @Interfaces
interface IProps<T extends FieldValues> extends StandardTextFieldProps {
  name: FieldPath<T>;
  control: Control<T, any>;
  minDate?: string | number | Date;
  maxDate?: string | number | Date;
  onExternalChange?: (value: unknown) => void;
}

/**
 * @component
 * @subcategory Global
 *
 * @description
 * Custom date picker component using Material-UI, react-hook-form, and date-fns.
 * This component supports both desktop and mobile views, and allows for minDate and maxDate restrictions.
 * #### Example
 * ```tsx
 * <DatePicker
 *   name="date"
 *   control={control}
 *   label="Date"
 *   minDate={new Date()}
 *   onExternalChange={handleDateChange}
 * />
 * ```
 *
 * @property {FieldPath<T>} name The name of the field in the form.
 * @property {Control<T, any>} control The control object from react-hook-form.
 * @property {string} label The label for the date picker.
 * @property {string | number | Date} minDate The minimum selectable date.
 * @property {string | number | Date} maxDate The maximum selectable date.
 * @property {function} onExternalChange Callback function for when the date is changed.
 * @property {object} props Another props
 *
 * @return {JSX.Element} The date picker component.
 * @author CML Exports Front-End Developers
 */
export default function DatePicker<T extends FieldValues>({
  name,
  control,
  label,
  minDate,
  maxDate,
  onExternalChange,
  ...props
}: IProps<T>) {
  const { sm } = breakpoints;

  const currentLanguage = i18next.language;

  const ResposiveDatePicker = window.innerWidth < sm ? MobileDatePicker : DesktopDatePicker;

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, ref, onChange, ...field }, fieldState: { error } }) => (
        <FormControl fullWidth>
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={currentLanguage === 'es' ? es : undefined}>
            <ResposiveDatePicker
              label={label}
              value={value}
              minDate={minDate}
              maxDate={maxDate}
              inputFormat='MM/dd/yyyy'
              views={['year', 'month', 'day']}
              onChange={(date: any | null) => {
                if (!isNaN(date) && date instanceof Date) {
                  onExternalChange ? onExternalChange(date.toISOString()) : onChange(date.toISOString());
                }
              }}
              ref={ref}
              renderInput={params => (
                <TextField
                  {...params}
                  {...field}
                  {...props}
                  error={Boolean(error?.message)}
                  inputProps={{ ...params.inputProps }}
                />
              )}
            />
          </LocalizationProvider>
        </FormControl>
      )}
    />
  );
}
