/* eslint-disable indent */
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// @MUI Components
import {
  Box,
  Grid,
  Card,
  CardHeader,
  Typography,
  CardContent,
  LinearProgress,
  Chip,
  Divider,
  useMediaQuery,
  Theme,
} from '@mui/material';

// @Utils
import { formatNumber } from '../../../utils';

// @Components
import DataBox from '../../../components/DataBox';
import Mushroom from '../../../components/Mushroom';

// @Interfaces and Types
import { InvoiceType } from '../types';
interface IProps {
  data: InvoiceType | null;
  permissions: string[];
}

export default function InvoiceInformation({ data, permissions }: IProps) {
  if (!data) return null;

  const {
    id,
    folio,
    nitDui,
    customerName,
    nameGeneratedBy,
    address,
    email,
    phone,
    payCondition,
    budgetId,
    billTypeId,
    billTypeName,
    iva,
    subTotal,
    // totalAmount,
    // totalExempt,
    totalPaid,
    totalToPay,
    // totalNotSubject,
    // totalTaxed,
    totalSales,
    expenses,
    // payments,
    createAt,
    // updateAt,
  } = data;

  const date = createAt ? new Date(createAt) : new Date();

  const { t } = useTranslation('common');

  const mobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const getTotalExpenses = () => {
    if (expenses.length) {
      const totalExpenses = expenses.map(expense => (expense?.total ? expense.total : 0));
      const calculateTotal = totalExpenses.reduce((prevNumber, currentNumber) => currentNumber + prevNumber);

      return calculateTotal;
    } else return 0;
  };

  const params = useSearchParams();

  return (
    <Card sx={{ width: '100%' }}>
      <CardHeader
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
        title={
          <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', justifyContent: 'space-between' }}>
            <Typography variant='h6' sx={{ width: { xs: '100%', sm: 'auto' } }}>
              {customerName}
            </Typography>

            <Box
              sx={{
                width: { xs: '100%', sm: 'auto' },
                gap: 1,
                display: 'flex',
                alignItems: 'center',
                justifyContent: { xs: 'space-between', sm: 'flex-start' },
                color: 'text.secondary',
              }}
            >
              <Typography component='span' sx={{ fontWeight: 600 }}>
                {params[0].get('type') === 'sv' ? t(`general.${billTypeName}`) : t('general.bill')}:
              </Typography>

              <Typography
                href={`/invoices/print/${id}/${billTypeName}/${billTypeId}/${budgetId}/${billTypeName}`}
                component='a'
                sx={{
                  px: 1,
                  textDecoration: 'none',
                  color: ({ palette }) => palette.info.main,
                  borderRadius: '0.5rem',
                  ':hover': {
                    color: ({ palette }) => palette.info.contrastText,
                    background: ({ palette }) => palette.info.main,
                  },
                  border: ({ palette }) => `thin solid ${palette.info.main}`,
                  transitionProperty: 'color background',
                  transition: '300ms ease',
                  pointerEvents: permissions.includes('print') ? 'all' : 'none',
                  cursor: permissions.includes('print') ? 'pointer' : 'default',
                }}
              >
                {folio || 'PDF'}
              </Typography>
            </Box>
          </Box>
        }
      />

      {/* CARD'S FOOTER AND BODY */}
      <CardContent sx={{ p: 0, borderTop: 'thin solid #cfcfcf' }}>
        <Grid container item xs>
          {/* CARD'S BODY */}
          <Grid container item xs={12} rowSpacing={1} sx={{ p: 2, borderBottom: 'thin solid #cfcfcf' }}>
            <Grid
              item
              xs={12}
              sx={{
                display: 'grid',
                alignItems: 'center',
                gridTemplateColumns: { xs: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)' },
              }}
            >
              <DataBox
                title={t('general.generatedBy')}
                text={nameGeneratedBy}
                sx={{ justifyContent: { xs: 'space-between', md: 'start' } }}
              />

              <DataBox
                title={t('general.payCondition')}
                text={
                  billTypeName === 'ff'
                    ? t('general.cashCount')
                    : payCondition === 'Crédito'
                    ? t('general.credit')
                    : payCondition
                }
                sx={{ justifyContent: { xs: 'space-between', md: 'start' } }}
              />
              <DataBox
                title={t('general.date')}
                text={`${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`}
                sx={{ justifyContent: { xs: 'space-between', md: 'start' } }}
              />
            </Grid>

            <Divider sx={{ mt: 0.5, width: '100%', display: { xs: 'block', md: 'none' } }} />

            <Grid
              item
              xs={12}
              sx={{
                display: 'grid',
                alignItems: 'center',
                gridTemplateColumns: { xs: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)' },
              }}
            >
              <DataBox text={nitDui} title={'NIT/DUI'} sx={{ justifyContent: { xs: 'space-between', md: 'start' } }} />
              <DataBox
                text={phone}
                title={t('general.phone')}
                sx={{ justifyContent: { xs: 'space-between', md: 'start' } }}
              />
              <DataBox
                text={email}
                title={t('general.mail')}
                sx={{ justifyContent: { xs: 'space-between', md: 'start' } }}
              />
            </Grid>

            <Divider sx={{ mt: 0.5, width: '100%', display: { xs: 'block', md: 'none' } }} />

            <Grid item xs={12}>
              <DataBox
                text={address}
                title={t('general.address')}
                sx={{
                  flexDirection: { xs: 'column', sm: 'row' },
                  justifyContent: { xs: 'space-between', md: 'start' },
                }}
                capitalize
              />
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            rowSpacing={1}
            sx={{
              py: { xs: 2, sm: 4 },
              px: 2,
              display: 'flex',
              gap: { xs: 0.5, sm: 0 },
              alignItems: { xs: 'stretch', sm: 'center' },
              justifyContent: 'space-between',
              flexDirection: { xs: 'column', sm: 'row' },
            }}
          >
            {mobile ? (
              <>
                <DataBox
                  direction='row'
                  title={t('general.subTotal')}
                  text={`$${formatNumber(subTotal)}`}
                  color='secondary'
                  sx={{ justifyContent: 'space-between' }}
                />
                <DataBox
                  direction='row'
                  title={t('general.iva')}
                  text={`$${formatNumber(iva)}`}
                  color='secondary'
                  sx={{ justifyContent: 'space-between' }}
                />
                <DataBox
                  direction='row'
                  title={t('general.total')}
                  text={`$${formatNumber(subTotal + iva)}`}
                  color='secondary'
                  sx={{ justifyContent: 'space-between' }}
                />
                <DataBox
                  direction='row'
                  title={t('expenses.accountalien')}
                  text={`$${formatNumber(getTotalExpenses())}`}
                  color='secondary'
                  sx={{ justifyContent: 'space-between' }}
                />
                <DataBox
                  direction='row'
                  title={t('general.totalToPay')}
                  text={`$${formatNumber(subTotal + iva + getTotalExpenses())}`}
                  color='secondary'
                  sx={{ justifyContent: 'space-between' }}
                />
              </>
            ) : (
              <>
                <Mushroom
                  title={t('general.subTotal')}
                  text={`$${formatNumber(subTotal)}`}
                  color='secondary'
                  sx={{ width: 200, textTransform: 'capitalize' }}
                />
                <Mushroom
                  title={t('general.iva')}
                  text={`$${formatNumber(iva)}`}
                  color='secondary'
                  sx={{ width: 200, textTransform: 'capitalize' }}
                />
                <Mushroom
                  title={t('general.total')}
                  text={`$${formatNumber(subTotal + iva)}`}
                  color='secondary'
                  sx={{ width: 200, textTransform: 'capitalize' }}
                />
                <Mushroom
                  title={t('expenses.accountalien')}
                  text={`$${formatNumber(getTotalExpenses())}`}
                  color='secondary'
                  sx={{ width: 200, textTransform: 'capitalize', overflow: 'hidden' }}
                />
                <Mushroom
                  title={t('general.totalToPay')}
                  text={`$${formatNumber(subTotal + iva + getTotalExpenses())}`}
                  color='secondary'
                  sx={{ width: 200, textTransform: 'capitalize' }}
                />
              </>
            )}
          </Grid>

          {/* CARD'S FOOTER */}
          <Grid item xs sx={{ p: 2, borderTop: 'thin solid #cfcfcf' }}>
            <Box sx={{ mb: 1, gap: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography variant='h2' sx={{ fontSize: '1.2rem' }}>
                {t('general.paymentProgress')}
              </Typography>

              <Chip
                sx={{
                  py: 0,
                  px: 1,
                  fontWeight: 600,
                  fontSize: '1rem',
                  overflow: 'hidden',
                  position: 'relative',
                  ':hover::before': {
                    opacity: 1,
                  },
                  '::before': {
                    opacity: 0,
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    content: `"${100 - Math.round((totalPaid / totalSales || 0) * 100)}%"`,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    position: 'absolute',
                    backgroundColor: ({ palette }) => palette.error.main,
                    transition: '150ms ease opacity',
                  },
                }}
                color='success'
                label={`${Math.round((totalPaid / totalSales || 0) * 100)}%`}
              />
            </Box>

            <LinearProgress
              color='success'
              variant='determinate'
              value={Math.round((totalPaid / totalSales || 0) * 100)}
              sx={{
                mb: 2,
                height: 10,
                background: theme => theme.palette.error.light,
                borderRadius: 2,
                '& .MuiLinearProgress-bar': { borderRadius: 2 },
              }}
            />

            <Box
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', sm: 'row' },
                gap: { xs: 1.5, sm: 0 },
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Mushroom
                title={t('general.totalAmount')}
                text={`$${formatNumber(totalSales)}`}
                color='info'
                sx={{ width: 200, textTransform: 'capitalize' }}
              />
              <Mushroom
                title={t('general.paid')}
                text={`$${formatNumber(totalPaid)}`}
                sx={{ width: 200, textTransform: 'capitalize' }}
              />
              <Mushroom
                title={t('general.toPay')}
                text={`$${formatNumber(totalToPay)}`}
                color='error'
                sx={{ width: 200, textTransform: 'capitalize' }}
              />
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
