import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

// @Utils
import { formatNumber } from '../../utils';
import CustomSwalAlert from '../../utils/CustomSwalAlert';

// @MUI Icons
import ReceiptIcon from '@mui/icons-material/Receipt';
import PaymentIcon from '@mui/icons-material/Payment';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';

// @Services
import getInvoicesStats from './services/getInvoicesStats';

// @Interfaces and Types
import { CardStatsType, StatsType } from './types';

// @Initial States
const initialStatsData: CardStatsType[] = [
  {
    title: 'general.invoices',
    AvatarIcon: ReceiptIcon,
    stats: 0,
    color: 'secondary',
  },
  {
    title: 'general.paid',
    AvatarIcon: PriceCheckIcon,
    stats: 0,
    color: 'info',
  },
  {
    title: 'general.payable',
    AvatarIcon: PaymentIcon,
    stats: 0,
    color: 'warning',
  },
];

const initialFiltersStats = {
  firstDate: '',
  lastDate: '',
  billsType: ['ff', 'ccf', 'cml', 'ctis'],
};

export default function useHomePage() {
  const [isLoading, setIsLoading] = useState(true);
  const [statsData, setStatsData] = useState(initialStatsData);

  const { control, getValues, setValue, resetField, watch } = useForm({
    defaultValues: initialFiltersStats,
  });

  const watchBillsType = watch('billsType');
  const watchFirstDate = watch('firstDate');
  const watchLastDate = watch('lastDate');

  const { t } = useTranslation('common');

  const { idCRM, role } = useSelector((state: any) => state.user);

  // NOTE: ALL ACTIONS
  const getStatsData = async (firstDate?: Date | string, lastDate?: Date | string) => {
    try {
      setIsLoading(true);

      const billsType = getValues('billsType');

      const stats: StatsType = await getInvoicesStats(idCRM, billsType, firstDate, lastDate);

      const newStatsData = initialStatsData;

      newStatsData[0].stats = `$${formatNumber(stats.totalAmount)}`;
      newStatsData[1].stats = `$${formatNumber(stats.totalPaid)}`;
      newStatsData[2].stats = `$${formatNumber(stats.totalPayable)}`;

      setStatsData(newStatsData);
    } catch (err) {
    } finally {
      setIsLoading(false);
    }
  };

  const verifyInputs = () => {
    let existValue = false;

    if (watchBillsType || watchFirstDate || watchLastDate) existValue = true;

    return !existValue;
  };

  // NOTE: HANDLE FUNCTIONS
  const handleGetStats = async () => {
    const { billsType, firstDate, lastDate } = getValues();

    if (!billsType || !billsType.length) {
      return CustomSwalAlert(t('toast.warning'), t('errors.require.billType'), 'warning', false);
    }

    if (!firstDate || !lastDate) {
      return CustomSwalAlert(t('toast.warning'), t('errors.require.date'), 'warning', false);
    }

    if (new Date(firstDate).getTime() > new Date(lastDate).getTime()) {
      return CustomSwalAlert(t('toast.warning'), t('errors.dateRangeInvalid'), 'warning', false);
    }

    getStatsData(firstDate, lastDate);
  };

  const handleClearStatsDates = () => {
    setValue('billsType', []);
    resetField('firstDate');
    resetField('lastDate');
    getStatsData();
  };

  // NOTE: ALL USE EFFECTS
  useEffect(() => {
    idCRM && getStatsData();
  }, [idCRM]);

  return {
    // NOTE: STATUS
    isLoading,
    actionsDisabled: verifyInputs(),
    // NOTE: FORM CONTROL
    control,
    // NOTE: DATA
    statsData,
    // NOTE: HANDLE FUNCTIONS
    handleGetStats,
    handleClearStatsDates,
  };
}
