import { Button, Card, Grid, TextField, Typography, IconButton } from '@mui/material';
import { TextForm, DatePicker, SelectInput } from '../../../../components';
import useAirGuide from './use-AirGuide';
import { Controller } from 'react-hook-form';
import { kglb } from '../../../../utils/select-items.util';
import { useLocation } from 'react-router-dom';
import CancelIcon from '@mui/icons-material/Cancel';
import { useTranslation } from 'react-i18next';

interface CharacterCounterProps {
  value: string;
  maxLength: number;
}

function CharacterCounter({ value, maxLength }: CharacterCounterProps): JSX.Element {
  const count = value.length;
  return (
    <p>
      {count}/{maxLength}
    </p>
  );
}

const AirGuideForm = () => {
  const {
    control,
    // countryItems,
    contentFields,
    contentAppend,
    contentRemove,
    counter,
    maxLength,
    handleChange,
    onSubmit,
    documentId,
    navigate,
  } = useAirGuide();

  const location = useLocation();

  const { t, i18n } = useTranslation('common');

  return (
    <Grid container spacing={2} rowGap={2}>
      <Grid item xs={12} sm={6} md={3}>
        <TextForm
          translationFile='validations'
          control={control}
          label={t('airGuide.referenceCode', { ns: 'placeholder' })}
          name='referenceCode'
        />
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <TextForm
          translationFile='validations'
          control={control}
          label={t('airGuide.invoiceOperation', { ns: 'placeholder' })}
          name='invoiceoperation'
        />
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <DatePicker control={control} name='expeditionDate' label={t('airGuide.date', { ns: 'placeholder' })} />
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <TextForm
          translationFile='validations'
          control={control}
          label={t('airGuide.issuedBy', { ns: 'placeholder' })}
          name='issuedBy'
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <Typography sx={{ textAlign: 'center' }} variant={'subtitle1'}>
          {t('airGuide.shipper', { ns: 'placeholder' })}
        </Typography>
      </Grid>

      <Grid item xs={12} sm={6}>
        <Typography sx={{ textAlign: 'center' }} variant={'subtitle1'}>
          {t('pdfs.consignatario', { ns: 'placeholder' })}
        </Typography>
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextForm
          translationFile='validations'
          control={control}
          label={t('airGuide.name', { ns: 'placeholder' })}
          name='nameshipper'
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextForm
          translationFile='validations'
          control={control}
          label={t('airGuide.name', { ns: 'placeholder' })}
          name='nameconsignee'
          // disabled
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextForm
          translationFile='validations'
          control={control}
          label={t('airGuide.address', { ns: 'placeholder' })}
          name='addressshipper'
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextForm
          translationFile='validations'
          control={control}
          label={t('airGuide.address', { ns: 'placeholder' })}
          name='addressconsignee'
          // disabled
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextForm
          translationFile='validations'
          control={control}
          label={t('airGuide.email', { ns: 'placeholder' })}
          name='mailshipper'
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextForm
          translationFile='validations'
          control={control}
          label={t('airGuide.email', { ns: 'placeholder' })}
          name='mailconsignee'
          // disabled
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextForm
          translationFile='validations'
          control={control}
          label={t('airGuide.phone', { ns: 'placeholder' })}
          name='phoneshipper'
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextForm
          translationFile='validations'
          control={control}
          label={t('airGuide.phone', { ns: 'placeholder' })}
          name='phoneconsignee'
          // disabled
        />
      </Grid>

      <Grid item xs={12} sx={{ textAlign: 'center' }}>
        <Typography variant={'subtitle1'}>{t('airGuide.carrier', { ns: 'placeholder' })} </Typography>
      </Grid>

      <Grid container spacing={2} rowGap={2} sx={{ marginLeft: 0.5 }}>
        <Grid item xs={12} sm={6}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextForm
                translationFile='validations'
                control={control}
                label={t('airGuide.name', { ns: 'placeholder' })}
                name='iCarriername'
              />
            </Grid>

            <Grid item xs={12}>
              <TextForm
                translationFile='validations'
                control={control}
                label={t('airGuide.city', { ns: 'placeholder' })}
                name='iCarriercity'
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Controller
            name='accountingInformation'
            control={control}
            render={({ field }) => (
              <>
                <TextField
                  fullWidth
                  label={t('airGuide.accountingInformation', { ns: 'placeholder' })}
                  multiline
                  rows={4.2}
                  value={counter}
                  onChange={handleChange}
                  inputProps={{ maxLength }}
                />
                <CharacterCounter value={counter} maxLength={maxLength} />
              </>
            )}
          />
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Typography sx={{ textAlign: 'center' }} variant={'subtitle1'}>
          {t('airGuide.issuingCarrier', { ns: 'placeholder' })}
        </Typography>
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <TextForm
          translationFile='validations'
          control={control}
          label={t('airGuide.agentIATACode', { ns: 'placeholder' })}
          name='agentIATACode'
        />
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <TextForm
          translationFile='validations'
          control={control}
          label={t('airGuide.agentAccountNo', { ns: 'placeholder' })}
          name='agentAccountNo'
        />
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <TextForm
          translationFile='validations'
          control={control}
          label={t('airGuide.airpotOfDeparture', { ns: 'placeholder' })}
          name='airpotOfDeparture'
        />
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <TextForm
          translationFile='validations'
          control={control}
          label={t('airGuide.referenceNumber', { ns: 'placeholder' })}
          name='referenceNumber'
        />
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <TextForm
          translationFile='validations'
          control={control}
          label={t('airGuide.optionalShippingInformation', { ns: 'placeholder' })}
          name='optionalShippingInformation'
        />
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <TextForm
          translationFile='validations'
          control={control}
          label={t('airGuide.firstTo', { ns: 'placeholder' })}
          name='firstTo'
        />
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <TextForm
          translationFile='validations'
          control={control}
          label={t('airGuide.byFirstCarrier', { ns: 'placeholder' })}
          name='byFirstCarrier'
        />
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <TextForm
          translationFile='validations'
          control={control}
          label={t('airGuide.routingAndDestinitation', { ns: 'placeholder' })}
          name='routingAndDestinitation'
        />
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <TextForm
          translationFile='validations'
          control={control}
          label={t('airGuide.secondTo', { ns: 'placeholder' })}
          name='secondTo'
        />
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <TextForm
          translationFile='validations'
          control={control}
          label={t('airGuide.secondBy', { ns: 'placeholder' })}
          name='secondBy'
        />
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <TextForm
          translationFile='validations'
          control={control}
          label={t('airGuide.thirdTo', { ns: 'placeholder' })}
          name='thirdTo'
        />
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <TextForm
          translationFile='validations'
          control={control}
          label={t('airGuide.thirdBy', { ns: 'placeholder' })}
          name='thirdBy'
        />
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <TextForm
          translationFile='validations'
          control={control}
          label={t('airGuide.currency', { ns: 'placeholder' })}
          name='currency'
        />
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <TextForm
          translationFile='validations'
          control={control}
          label={t('airGuide.chgs', { ns: 'placeholder' })}
          name='chgs'
        />
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <TextForm
          translationFile='validations'
          control={control}
          label={t('airGuide.prepaid', { ns: 'placeholder' })}
          name='prepaid'
        />
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <TextForm
          translationFile='validations'
          control={control}
          label={t('airGuide.collect', { ns: 'placeholder' })}
          name='collect'
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <TextForm
          translationFile='validations'
          control={control}
          label={t('airGuide.airpotOfDestination', { ns: 'placeholder' })}
          name='airpotOfDestination'
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <TextForm
          translationFile='validations'
          control={control}
          label={t('airGuide.requestedFlight', { ns: 'placeholder' })}
          name='requestedFlight'
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <TextForm
          translationFile='validations'
          control={control}
          label={t('airGuide.handlingInformation', { ns: 'placeholder' })}
          name='handlingInformation'
        />
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <DatePicker control={control} name='date' label={t('airGuide.date', { ns: 'placeholder' })} />
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <TextForm
          translationFile='validations'
          control={control}
          label={t('airGuide.declaredValueForCarriage', { ns: 'placeholder' })}
          name='declaredValueForCarriage'
        />
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <TextForm
          translationFile='validations'
          control={control}
          label={t('airGuide.declaredValueForCustoms', { ns: 'placeholder' })}
          name='declaredValueForCustoms'
        />
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <TextForm
          translationFile='validations'
          control={control}
          label={t('airGuide.amountOfInsurance', { ns: 'placeholder' })}
          name='amountOfInsurance'
        />
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <TextForm
          translationFile='validations'
          control={control}
          label={t('airGuide.weightCharge', { ns: 'placeholder' })}
          name='weightCharge'
        />
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <TextForm
          translationFile='validations'
          control={control}
          label={t('airGuide.secondPrepaid', { ns: 'placeholder' })}
          name='secondPrepaid'
        />
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <TextForm
          translationFile='validations'
          control={control}
          label={t('airGuide.secondCollect', { ns: 'placeholder' })}
          name='secondCollect'
        />
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <TextForm
          translationFile='validations'
          control={control}
          label={t('airGuide.valuationCharge', { ns: 'placeholder' })}
          name='valuationCharge'
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <TextForm
          translationFile='validations'
          control={control}
          label={t('airGuide.security', { ns: 'placeholder' })}
          name='security'
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <TextForm
          translationFile='validations'
          control={control}
          label={t('airGuide.tax', { ns: 'placeholder' })}
          name='tax'
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <TextForm
          translationFile='validations'
          control={control}
          label={t('airGuide.fuelServices', { ns: 'placeholder' })}
          name='fuelServices'
        />
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <TextForm
          translationFile='validations'
          control={control}
          label={t('airGuide.totalOtherChargesDueAgent', { ns: 'placeholder' })}
          name='totalOtherChargesDueAgent'
        />
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <TextForm
          translationFile='validations'
          control={control}
          label={t('airGuide.totalOtherChargesDueCarrier', { ns: 'placeholder' })}
          name='totalOtherChargesDueCarrier'
        />
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <TextForm
          translationFile='validations'
          control={control}
          label={t('airGuide.totalPrepaid', { ns: 'placeholder' })}
          name='totalPrepaid'
        />
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <TextForm
          translationFile='validations'
          control={control}
          label={t('airGuide.totalCollect', { ns: 'placeholder' })}
          name='totalCollect'
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <TextForm
          translationFile='validations'
          control={control}
          label={t('airGuide.carriesrsuse', { ns: 'placeholder' })}
          name='carriesrsuse'
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <TextForm
          translationFile='validations'
          control={control}
          label={t('airGuide.chargesatdestinations', { ns: 'placeholder' })}
          name='chargesatdestinations'
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <TextForm
          translationFile='validations'
          control={control}
          label={t('airGuide.totalCollectCharges', { ns: 'placeholder' })}
          name='totalCollectCharges'
        />
      </Grid>

      <Grid item xs={12}>
        <Grid container columnSpacing={1} rowSpacing={1}>
          <Grid item xs={12} sx={{ textAlign: 'center' }}>
            <Typography sx={{ textAlign: 'center' }} variant={'subtitle1'}>
              {t('general.content')}
            </Typography>
          </Grid>

          {contentFields.map((field, index) => {
            return (
              <Grid key={field.id} item xs={12}>
                <Card variant='outlined' sx={{ p: 5, position: 'relative' }}>
                  <Grid container columnSpacing={2} rowSpacing={4}>
                    <Grid item xs={12} sm={6} md={3}>
                      <TextForm
                        translationFile='validations'
                        control={control}
                        label={t('airGuide.quantity', { ns: 'placeholder' })}
                        name={`content.${index}.quantity`}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={3}>
                      <TextForm
                        translationFile='validations'
                        control={control}
                        label={t('airGuide.itemNumber', { ns: 'placeholder' })}
                        name={`content.${index}.itemNumber`}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={3}>
                      <TextForm
                        translationFile='validations'
                        control={control}
                        label={t('airGuide.grossWeight', { ns: 'placeholder' })}
                        name={`content.${index}.grossWeight`}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={3}>
                      <SelectInput
                        control={control}
                        label={t('airGuide.kglb', { ns: 'placeholder' })}
                        name={`content.${index}.kglb`}
                        items={kglb}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={3}>
                      <TextForm
                        translationFile='validations'
                        control={control}
                        label={t('airGuide.chargeableWeight', { ns: 'placeholder' })}
                        name={`content.${index}.chargeableWeight`}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={3}>
                      <TextForm
                        translationFile='validations'
                        control={control}
                        label={t('airGuide.rateClass', { ns: 'placeholder' })}
                        name={`content.${index}.rateClass`}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={3}>
                      <TextForm
                        translationFile='validations'
                        control={control}
                        label={t('airGuide.rate', { ns: 'placeholder' })}
                        name={`content.${index}.rate`}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={3}>
                      <TextForm
                        translationFile='validations'
                        control={control}
                        label={t('airGuide.total', { ns: 'placeholder' })}
                        name={`content.${index}.total`}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextForm
                        translationFile='validations'
                        control={control}
                        label={t('airGuide.description', { ns: 'placeholder' })}
                        name={`content.${index}.description`}
                      />
                    </Grid>

                    {index > 0 && (
                      <Grid item xs={12}>
                        <IconButton
                          onClick={() => {
                            contentRemove(index);
                          }}
                          color='error'
                          sx={{
                            position: 'absolute',
                            bottom: 0,
                            left: 0,
                            fontSize: '16px',
                            paddingLeft: '32px',
                            paddingTop: '4px',
                          }}
                        >
                          <CancelIcon />
                          {t('general.deleteContent')}
                        </IconButton>
                      </Grid>
                    )}
                  </Grid>
                </Card>
              </Grid>
            );
          })}
          <Grid item xs={12} sx={{ textAlign: 'center' }}>
            <Button
              sx={{ color: '#00ef14', margin: 'auto' }}
              type='button'
              disabled={contentFields.length >= 5}
              onClick={() => {
                contentAppend({
                  quantity: null,
                  grossWeight: null,
                  kglb: '',
                  itemNumber: null,
                  rateClass: null,
                  chargeableWeight: null,
                  rate: null,
                  total: null,
                  description: '',
                });
              }}
            >
              <i className='fa-solid fa-circle-plus text-xl text-green-500 mr-2' />
              <p>{t('airGuide.addline', { ns: 'placeholder' })}</p>
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        item
        xs={12}
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          justifyContent: 'center',
          alignItems: 'center',
          gap: 2,
        }}
      >
        <Button variant='contained' onClick={onSubmit}>
          {documentId === null
            ? i18n.language === 'es'
              ? 'Guardar'
              : 'Save'
            : t('budgetGuide.saveDocumentButtonTittle')}
        </Button>

        {documentId !== null && (
          <Button
            variant='contained'
            onClick={() => navigate(documentId, location.pathname + '&value=6')}
            color='secondary'
          >
            {t('budgetGuide.viewDocumentButtonTittle')}
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

export default AirGuideForm;
