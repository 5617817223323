import { Fragment, useState } from 'react';

// ** MUI Imports
import MuiMenu from '@mui/material/Menu';
import MuiMenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';

// ** Icons Imports
import Translate from '../../assets/translate.png';
import { useTranslation } from 'react-i18next';

// @Redux
import { changeLanguage } from '../../redux/slices/user';
import { useAppDispatch } from '../../redux/store';

const layout = 'vertical';
const direction = 'ltr';

/**
 * @component
 * @subcategory Global
 *
 * @description
 * LanguageDropdown component description
 * #### Example
 * ````
 * return (
 *   <LanguageDropdown />
 * )
 * ```
 *
 * @return {JSX.Element} LanguageDropdown component that allows the user to change the language of the application
 * @author CML Exports Front-End Developers
 */
const LanguageDropdown = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  const dispatch = useAppDispatch();

  const { i18n } = useTranslation();

  const Menu = styled(MuiMenu)(({ theme }) => ({
    '& .MuiMenu-paper': {
      border: `1px solid ${theme.palette.divider}`,
    },
  }));

  // Styled MenuItem component
  const MenuItem = styled(MuiMenuItem)(({ theme }) => ({
    '&:focus': {
      backgroundColor: theme.palette.primary.light,
    },
  }));

  const handleLangDropdownOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLangDropdownClose = () => {
    setAnchorEl(null);
  };

  const handleLangItemClick = (lang: 'es' | 'en') => {
    i18n.changeLanguage(lang);
    dispatch(changeLanguage({ language: lang }));
    handleLangDropdownClose();
  };

  return (
    <Fragment>
      <IconButton
        color='inherit'
        aria-haspopup='true'
        aria-controls='customized-menu'
        onClick={handleLangDropdownOpen}
        sx={layout === 'vertical' ? { mr: 0.75 } : { mx: 0.75 }}
      >
        <img src={Translate} alt='Translate google icon' width={28} height={28} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleLangDropdownClose}
        sx={{ '& .MuiMenu-paper': { mt: 4, minWidth: 130 } }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: direction === 'ltr' ? 'right' : 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: direction === 'ltr' ? 'right' : 'left',
        }}
      >
        <MenuItem
          sx={{ py: 2 }}
          selected={i18n.language === 'en'}
          onClick={() => {
            handleLangItemClick('en');
          }}
        >
          English
        </MenuItem>
        <MenuItem
          sx={{ py: 2 }}
          selected={i18n.language === 'es'}
          onClick={() => {
            handleLangItemClick('es');
          }}
        >
          Español
        </MenuItem>
      </Menu>
    </Fragment>
  );
};

export default LanguageDropdown;
