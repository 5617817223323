import i18n from '../../../i18n';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

// @Services
import { getInvoice } from '../service/http';

// @MUI Components
import { Grid, Card, CardHeader, CardContent, Divider, IconButton } from '@mui/material/';

// @MUI Icons
import KeyboardBackspace from '@mui/icons-material/KeyboardBackspace';

// @Utils
import getBillType from '../../../utils/getBillType';

// @Interfaces and Types
import { IBill } from '../models/Models';

export default function InvoicesPrint() {
  const [thisInvoice, setThisInvoice] = useState<IBill>();

  const { id, billType, budgetType, budgetId, templete } = useParams();

  const { t } = useTranslation('common');

  const navigate = useNavigate();

  const src =
    budgetType === '3' || budgetType === '4'
      ? `${
          process.env.REACT_APP_ESTIMATES_API
        }pdf/download/budget?id=${budgetId}&template=${templete}&isInvoice=${true}&language=${
          i18n.language
        }&makeBudget=${true}`
      : `${process.env.REACT_APP_ESTIMATES_API}generate-pdf?documentId=${id}&strategy=${
          billType === 'ccf' ? 'cff' : billType
        }&language=${i18n.language}&makeBudget=${true}`;

  const getInvoiceData = async () => {
    if (!id || !budgetType) return;

    const invoiceData = await getInvoice(id, budgetType);

    setThisInvoice(invoiceData);
  };

  const getLastRoute = () => {
    if (getBillType(budgetType) === 'CML US') return '/invoices/us-cml';
    else if (getBillType(budgetType) === 'CTIS') return '/invoices/ctis';
    else return '/invoices';
  };

  useEffect(() => {
    getInvoiceData();
  }, [id]);

  return (
    <Grid container sx={{ width: '90%', ml: 'auto', mr: { xs: 0.5, md: 'auto' }, zIndex: 1 }}>
      <Grid item xs={12} sx={{ mx: 'auto' }}>
        <Card sx={{ height: '95vh' }}>
          <IconButton
            sx={{ display: 'flex', width: 'max-content', mt: 1, ml: 2 }}
            onClick={() => navigate(getLastRoute())}
            color='error'
          >
            <KeyboardBackspace />
          </IconButton>

          <CardHeader
            title={`${
              getBillType(budgetType) === 'CML US' || getBillType(budgetType) === 'CTIS'
                ? t('general.bill')
                : getBillType(budgetType)
            } ${[1, 2].includes(Number(budgetType)) ? thisInvoice?.folio ?? '' : ''} - ${
              thisInvoice?.budgetCode ?? ''
            } - ${thisInvoice?.customerName ?? ''}`}
            sx={{ display: 'flex', textAlign: 'center' }}
            titleTypographyProps={{ variant: 'h6' }}
          />

          <Divider sx={{ margin: 0 }} />

          <CardContent sx={{ height: '90%' }}>
            <iframe src={src} style={{ display: 'block', width: '100%', height: '100%' }} />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
