import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';

// @Models
import { IServiceForm, IGetServices } from '../../../models';

// @Utils
import { axiosEstimates } from '../../../utils/api';

// @Redux
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../redux/store';
import { getServiceTypes } from '../../../redux/slices/services/actions-async';
import { RootState } from '../../../redux/reducers';

interface IParams {
  addState: (data: IGetServices) => void;
  editState: (data: IGetServices) => void;
  service?: IGetServices;
}

const schemaValidation = yup
  .object()
  .shape({
    serviceName: yup.string().required('service.serviceName'),
    serviceNameEN: yup.string().required('service.serviceNameEN'),
    idServiceType: yup.number().typeError('service.serviceType').required('service.serviceType'),
  })
  .required();

export function useServiceForm({ addState, service, editState }: IParams) {
  const dispatch = useAppDispatch();

  const { t } = useTranslation(['common']);

  const { serviceTypesItemsEN, serviceTypesItemsES } = useSelector((state: RootState) => state.services);

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm<IServiceForm>({
    defaultValues: {
      serviceName: '',
      serviceNameEN: '',
      idServiceType: null,
    },
    resolver: yupResolver(schemaValidation),
  });

  useEffect(() => {
    dispatch(getServiceTypes());

    if (service) {
      reset({ serviceName: service.name, serviceNameEN: service.nameEN, idServiceType: service.idServiceType });
    }
  }, [service]);

  const onSubmit = async (data: IServiceForm) => {
    try {
      const body = { name: data.serviceName, nameEN: data.serviceNameEN, idServiceType: data.idServiceType };
      if (!service) {
        const response = await axiosEstimates.post<IGetServices>('services', body);
        addState(response.data);
      } else {
        const response = await axiosEstimates.put<IGetServices>('services/' + service.id, body);
        editState(response.data);
      }
    } catch (error) {
      const err = error as AxiosError;
      if (err.response?.status === 409) {
        toast.error(t((err?.response?.data as { error: string })?.error));
      }
    }
  };

  return {
    control,
    onSubmit: handleSubmit(onSubmit),
    errors,
    serviceTypesItemsEN,
    serviceTypesItemsES,
  };
}
