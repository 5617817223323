/**
 * @Category Utils
 * @param {String | Number} ref The bill type's ref, this can be a number 1-4 or a String such as "cml" or "ctis".
 * @returns Return the type of the bill this can be "FF", "CCF", "CTIS", "CML US" or "CML SV".
 */
const getBillType = (ref: any) => {
  if (ref === '1' || ref === 1 || ref === 'cml') return 'FF';
  else if (ref === '2' || ref === 2 || ref === 'cml') return 'CCF';
  else if (ref === '3' || ref === 3 || ref === 'cml') return 'CML US';
  else if (ref === '4' || ref === 4 || ref === 'ctis') return 'CTIS';
  else return 'CML SV';
};

export default getBillType;
