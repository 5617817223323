import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { Box, Grid, Card, CardContent, Button, Typography } from '@mui/material';
import { Icon } from '@iconify/react';

// @Redux
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/reducers';
// import { useAppDispatch } from '../../../redux/store';
// import { increaseRequests } from '../../../redux/slices/notifications';

// @Utils
import { socket } from '../../../utils/sockets.util';

const redirect = (value: number) => {
  if (value === 1) return '/budgets';
  if (value === 2) return '/ctis';
  return '/budgets-from-us';
};

export const Final = ({ setIsShowPDF }: { setIsShowPDF: Dispatch<SetStateAction<boolean>> }) => {
  const [src, setSrc] = useState('');

  const navigate = useNavigate();

  const { t, i18n } = useTranslation('common');

  const { type, newBudget } = useSelector((state: RootState) => state.budgetForm);

  useEffect(() => {
    const toastId = newBudget?.status === 'active' ? toast.loading(t('toast.loading')) : undefined;

    const timer = setTimeout(() => {
      if (newBudget?.status === 'active') {
        setSrc(
          `${process.env.REACT_APP_ESTIMATES_API}pdf/download/budget?id=${newBudget.id}&language=${i18n.language}`,
        );
        setIsShowPDF(true);
        toast.dismiss(toastId);
      }
    }, 2000);

    if (newBudget?.status === 'request') socket.emit('requestCount');

    return () => {
      clearTimeout(timer);
      toast.dismiss(toastId);
    };
  }, []);

  if (!newBudget) {
    return (
      <Box sx={{ p: 2 }}>
        <Card>
          <Box>
            <svg className='checkmark' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 52 52'>
              <circle className='checkmark__circle' cx='26' cy='26' r='25' fill='none' />
              <path className='checkmark__check' fill='none' d='M14.1 27.2l7.1 7.2 16.7-16.8' />
            </svg>
          </Box>

          <div>{t('general.error')}</div>
          <div>{t('budgetPage.quotationFailed')}</div>
        </Card>
      </Box>
    );
  }

  return (
    <Box sx={{ p: 2, width: '100%' }}>
      <Card>
        <Grid container spacing={3} sx={{ p: 2, display: 'flex' }}>
          {newBudget.status === 'active' ? (
            <CardContent sx={{ m: 2, height: '80vh', width: '100%' }}>
              {src && <iframe src={src} style={{ display: 'block', width: '100%', height: '100%' }} />}
            </CardContent>
          ) : (
            <Grid item xs={12}>
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', p: 2 }}>
                <Icon width='60' height='60' icon='zondicons:checkmark-outline' color='#198754' />
              </Box>

              <Box
                sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', p: 2 }}
              >
                <Typography>{t('general.congrats')}</Typography>
                <Typography sx={{ textAlign: 'center' }}>{t('budgetPage.quotationCreated')}</Typography>
              </Box>

              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', p: 2 }}>
                <Typography variant='h4'>{newBudget.code}</Typography>
              </Box>
            </Grid>
          )}

          <Grid item xs={12}>
            <Box sx={{ display: 'flex', justifyItems: 'center', justifyContent: 'center', alignItems: 'center', p: 2 }}>
              <Button sx={{ justifyContent: 'center' }} variant='contained' onClick={() => navigate(redirect(type))}>
                {t('general.close')}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Card>
    </Box>
  );
};
