import { useTranslation } from 'react-i18next';

// @MUI Components
import { Box, TableCell, TableRow, Typography } from '@mui/material';

/**
 * @component
 * @subcategory Global
 *
 * @description
 * A reusable table row component for displaying data in a table format.
 * The component accepts various props to customize its behavior and appearance.
 *
 * #### Example
 * ```tsx
 * return(
 * <CustomTableRow headers={['ID', 'Name']} />
 * )
 * ```
 *
 * @property {any[]} headers The headers to display in the table row.
 *
 * @return {JSX.Element} The CustomTableRow component.
 * @author CML Exports Front-End Developers
 */
const CustomTableRow = ({ headers }: { headers: any[] }) => {
  const { t } = useTranslation(['common']);

  return (
    <TableRow sx={{ boxShadow: 'none', backgroundColor: 'white' }}>
      <TableCell style={{ padding: 0 }} colSpan={headers.length}>
        <Box sx={{ display: 'flex', textAlign: 'center', justifyContent: 'center', alignItems: 'center' }}>
          <Typography sx={{ textAlign: 'center' }} gutterBottom component='div'>
            {t('general.noRows', { ns: 'validations' })}
          </Typography>
        </Box>
      </TableCell>
    </TableRow>
  );
};

export default CustomTableRow;
