// @MUI Components
import { Box, Typography, TableRow, TableCell, IconButton } from '@mui/material';

// @MUI Icons
import DeleteOutline from 'mdi-material-ui/DeleteOutline';
import PencilOutline from 'mdi-material-ui/PencilOutline';

// @Utils
import { saleType } from '../../../../../utils/select-items.util';
import { formatNumber } from '../../../../../utils/format-number.util';

// @Interfaces and types
import { IServicesSupplier } from '../../../../../models';
import i18n from '../../../../../i18n';
interface IRowProps {
  handleRemoveService: (value: number) => void;
  handleView: (value: IServicesSupplier) => void;
  row: any;
}

const Row = ({ row, handleRemoveService, handleView }: IRowProps) => {
  const getSaleType = (value: number): string => {
    const saleTypeFound = saleType.find(type => type.value === value);

    return i18n.language === 'es' ? saleTypeFound?.label || '' : saleTypeFound?.labelEN || saleTypeFound?.label || '';
  };

  return (
    <TableRow>
      <TableCell>
        <Typography
          noWrap
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textTransform: 'capitalize' }}
        >
          {i18n.language === 'es' ? row.serviceName : row.serviceNameEN}
        </Typography>
      </TableCell>

      <TableCell>
        <Typography
          noWrap
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textTransform: 'capitalize' }}
        >
          {i18n.language === 'es' ? row.serviceTypeName : row.serviceTypeNameEN}
        </Typography>
      </TableCell>

      <TableCell>
        <Typography
          noWrap
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textTransform: 'capitalize' }}
        >
          {formatNumber(row.cost || 0)}
        </Typography>
      </TableCell>

      <TableCell>
        <Typography
          noWrap
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textTransform: 'capitalize' }}
        >
          {formatNumber(row.salePrice || 0)}
        </Typography>
      </TableCell>

      <TableCell>
        <Typography
          noWrap
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textTransform: 'capitalize' }}
        >
          {getSaleType(row.saleType || 0)}
        </Typography>
      </TableCell>

      <TableCell align='center'>
        <Box sx={{ width: { xs: 68, md: '100%' } }}>
          <IconButton size='small' color='info' onClick={() => handleView(row)}>
            <PencilOutline />
          </IconButton>

          <IconButton size='small' color='error' onClick={() => handleRemoveService(row.id)}>
            <DeleteOutline />
          </IconButton>
        </Box>
      </TableCell>
    </TableRow>
  );
};

export default Row;
