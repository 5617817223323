import { useSearchParams } from 'react-router-dom';

// @Mui
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Autocomplete,
  TextField,
  Box,
  InputLabel,
  List,
} from '@mui/material/';

// @Hooks
import { useSendBudget } from './useSendBudget';

// @Components
import ButtonForm from '../../components/ButtonForm';
import Loader from '../../components/Loader';

export function SendBudget() {
  const { t, emailTo, addNewOption, menuItemsArr, handleAddEmail, handleSendMail, title, isSending } = useSendBudget();

  const [params] = useSearchParams();

  return (
    <Grid container sx={{ width: '90%', ml: 'auto', mr: { xs: 0.5, md: 'auto' }, zIndex: 1 }}>
      <Card sx={{ width: '100%', mx: 'auto', height: '95vh' }}>
        {isSending ? (
          <Loader text={t('general.sending')} />
        ) : (
          <>
            <CardHeader
              sx={{ display: 'flex', textAlign: 'center' }}
              title={t(
                params.get('action') && params.get('action') === 'sendDocument'
                  ? 'budgetPage.sendDocument'
                  : 'budgetPage.sendQuotation',
                { ns: 'common' },
              )}
              subheader={title}
              titleTypographyProps={{ variant: 'h6' }}
            />

            <Divider sx={{ margin: 0 }} />

            <CardContent sx={{ height: '90%', gap: 10 }}>
              <Box sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                <Box>
                  <InputLabel sx={{ mr: 3, fontSize: '0.875rem' }} htmlFor='email-to-select'>
                    {t('general.sendTo')}
                  </InputLabel>
                </Box>

                <Autocomplete
                  multiple
                  freeSolo
                  value={emailTo}
                  clearIcon={false}
                  filterSelectedOptions
                  options={menuItemsArr}
                  onChange={(e, item) => handleAddEmail(item)}
                  ListboxComponent={List}
                  filterOptions={addNewOption}
                  getOptionLabel={option => option}
                  sx={{
                    width: '100%',
                    '& .MuiOutlinedInput-root': { p: 0 },
                    '& .MuiAutocomplete-endAdornment': { display: 'none' },
                  }}
                  renderInput={params => <TextField {...params} />}
                />
              </Box>

              <Box sx={{ padding: '1rem 0', display: 'flex', justifyContent: 'space-around' }}>
                <ButtonForm label={t('general.back')} onClick={() => history.back()} color='error' />
                <ButtonForm
                  label={t('general.send')}
                  color='success'
                  onClick={() => handleSendMail(params.get('action') || 'sendBudget')}
                />
              </Box>
            </CardContent>
          </>
        )}
      </Card>
    </Grid>
  );
}
