// @Utils
import { axiosEstimates } from '../../../utils';

// @Interfaces and Types
import { IGetBudgets, ISelectInput } from '../../../models';
interface IBudgetItems extends ISelectInput<number> {
  data?: IGetBudgets;
}

export default async function getBudgetsRequestedItems(idSupplier: String) {
  try {
    const response = await axiosEstimates.get<{ budgets: IGetBudgets[] }>(
      `budget-requeted-items?idSupplier=${idSupplier}`,
      {
        headers: {
          'x-module': 'requested_quotes',
        },
      },
    );

    const items: IBudgetItems[] = response.data.budgets.map(budget => ({
      label: `${budget.code} - ${budget.customerName}`,
      value: budget.id,
      data: budget,
    }));

    return items;
  } catch (error) {
    return [];
  }
}
