// @MUI Components
import { Box, Button, Divider, Grid, Drawer, Typography } from '@mui/material';

// @Components
import TextForm from '../../../components/TextForm';
import NumberField from '../../../components/NumberField';
import SelectInput from '../../../components/SelectInput';

// @Icons Imports
import Close from 'mdi-material-ui/Close';

// @Hooks
import useExpenseForm from '../hooks/useExpenseForm';

// @utils
import { saleType } from '../../../utils/select-items.util';

// @Interfaces and Types
import { IAddExpense } from '../../../models';
interface IProps {
  formIsOpen: boolean;
  handleToggleForm: () => void;
  addExpense: (value: Omit<IAddExpense, 'id' | 'idExpense'>) => void;
  editExpense: (value: IAddExpense) => void;
  expense?: IAddExpense;
}

export default function FormExpenses({
  formIsOpen: open,
  handleToggleForm: toggle,
  addExpense: handleAdd,
  editExpense: handleEdit,
  expense,
}: IProps) {
  const { control, t, onSubmit } = useExpenseForm({ open, handleAdd, handleEdit, expense });

  return (
    <Drawer
      open={open}
      anchor='right'
      variant='temporary'
      onClose={toggle}
      ModalProps={{ keepMounted: true }}
      sx={{ '& .MuiDrawer-paper': { width: { xs: 300, sm: 400 } } }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: ({ spacing }) => spacing(3, 4),
          backgroundColor: ({ palette }) => palette.background.default,
        }}
      >
        <Typography variant='h6'>{t('general.expenses', { ns: 'common' })}</Typography>
        <Close fontSize='small' onClick={toggle} sx={{ cursor: 'pointer' }} />
      </Box>

      <Box sx={{ padding: '1rem' }}>
        <Grid container spacing={1} sx={{ display: 'flex' }}>
          <Grid item xs={12}>
            <SelectInput name='type' control={control} label={t('expenses.type', { ns: 'common' })} items={saleType} />
          </Grid>
          <Grid item xs={12}>
            <TextForm name='description' control={control} label={t('title.description', { ns: 'common' })} />
          </Grid>
          <Grid item xs={12}>
            <TextForm name='quantity' control={control} label={t('general.quantity', { ns: 'common' })} type='number' />
          </Grid>
          <Grid item xs={12}>
            <NumberField name='unitPrice' control={control} label={t('general.unitary', { ns: 'common' })} />
          </Grid>
          <Grid item xs={12}>
            <NumberField name='subTotal' control={control} label={t('expenses.amount', { ns: 'common' })} />
          </Grid>
          <Grid item xs={12}>
            <NumberField name='iva' control={control} label={t('general.iva', { ns: 'common' })} />
          </Grid>
          <Grid item xs={12}>
            <NumberField name='ivaRet' control={control} label={t('expenses.retention', { ns: 'common' })} />
          </Grid>
          <Grid item xs={12}>
            <NumberField name='isrRet' control={control} label={t('expenses.retentionISR', { ns: 'common' })} />
          </Grid>
          <Grid item xs={12}>
            <NumberField name='total' control={control} label={t('general.total', { ns: 'common' })} />
          </Grid>
        </Grid>

        <Divider sx={{ my: 2 }} />

        <Box sx={{ display: 'flex', alignItems: 'center', my: 2, gap: 2 }}>
          <Button sx={{ m: 2 }} variant='contained' color='error' onClick={toggle}>
            {t('general.cancel', { ns: 'common' })}
          </Button>

          <Button sx={{ m: 2 }} variant='contained' onClick={onSubmit}>
            {t('general.save', { ns: 'common' })}
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
}
