import axios from 'axios';
import { useLocation, useParams } from 'react-router-dom';
import { SyntheticEvent } from 'react-draft-wysiwyg';
import { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// @MUI Components
import { Box, Tabs, Tab, Card, CardContent, CardHeader, Grid } from '@mui/material/';

// @Hooks
import useSuppliers from '../../hooks/useSuppliers';

// @Redux
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/reducers';

// @Components
import TabInformation from './components/TabInformation';
import TabBudgets from './components/TabBudgets/TabBudgets';
import TabServices from './components/TabServices/TabServices';

// @Interfaces and Types
import { ISupplier } from '../../../../models/SupplierModel';
interface Supplier extends ISupplier {
  supplierData?: any;
}

interface TabPanelProps {
  children?: ReactNode;
  index: number;
  value: number;
}

function TabPanel({ children, value, index, ...other }: TabPanelProps) {
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ m: { xs: 1, sm: 6 } }}>{children}</Box>}
    </div>
  );
}

function tabProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const DefaultSupplier = {
  id: [1],
  idSupplier: 1,
  name: 'Default Supplier',
  status: 'Active',
  phone: ['1233'],
  country: ['VE'],
  address: ['DIR 1'],
  email: ['email@email.com'],
};

export const Details = () => {
  const [supplierData, setSupplier] = useState<Supplier>(DefaultSupplier);

  const { id } = useParams();

  const { t } = useTranslation('common');

  const { user } = useSelector((state: RootState) => state);

  const { permissions } = useSuppliers();

  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);

  // Accede a los valores de los parámetros de consulta
  const query = queryParams.get('ctg');

  const [value, setValue] = useState(query === 'true' ? 1 : 0);

  const handleChange = (e: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    (async () => {
      const supplierGET: any = await axios.get(`${process.env.REACT_APP_ESTIMATES_API}supplier/${id}`);
      setSupplier(supplierGET.data);
    })();
  }, []);

  return (
    <Grid container sx={{ width: '90%', ml: 'auto', mr: { xs: 0.5, md: 'auto' }, zIndex: 1 }}>
      <Card sx={{ width: '100%' }}>
        <CardHeader title={supplierData.name} sx={{ mt: 2, ml: 2, textAlign: 'center' }} />

        <CardContent>
          <Tabs value={value} onChange={handleChange} aria-label='basic tabs example'>
            {permissions && permissions.consult_information && <Tab label={t('title.informacion')} {...tabProps(0)} />}
            {permissions && permissions.consult_quote && <Tab label={t('title.quotation')} {...tabProps(1)} />}
            {permissions && permissions.consult_services && (
              <Tab label={t('title.services')} {...tabProps(2)} disabled={user.role === 'subscriber'} />
            )}
          </Tabs>

          {permissions && permissions.consult_information && (
            <TabPanel value={value} index={0}>
              <TabInformation />
            </TabPanel>
          )}
          {permissions && permissions.consult_quote && (
            <TabPanel value={value} index={1}>
              <TabBudgets />
            </TabPanel>
          )}
          {permissions && permissions.consult_services && (
            <TabPanel value={value} index={2}>
              <TabServices />
            </TabPanel>
          )}
        </CardContent>
      </Card>
    </Grid>
  );
};
