import { Controller } from 'react-hook-form';
import { DefaultTFuncReturn } from 'i18next';
import { useTranslation } from 'react-i18next';

// @MUI Components
import { Box, FormControl, InputLabel, Select, MenuItem, FormHelperText } from '@mui/material';

interface IProps {
  control: any;
  name: string;
  label: string | DefaultTFuncReturn;
  options: {
    text: string | number | DefaultTFuncReturn;
    label?: string | number | DefaultTFuncReturn;
    value: string | number;
  }[];
  onExternalChange?: (value: unknown) => void;
}

/**
 *
 * @component
 * @subcategory Global
 *
 * @description
 * A customizable select component with internationalization support.
 * This component uses `react-hook-form` and `react-i18next` to provide a flexible and localized select input.
 * #### Example
 * ```
 * import CustomSelect from './CustomSelect';
 *
 * const options = [
 *   { text: 'Option 1', value: 'option1' },
 *   { text: 'Option 2', value: 'option2' },
 * ];
 *
 * const AnotherComponent = () => {
 *   return (
 *     <CustomSelect control={control} name="mySelect" label="Select an option" options={options} onExternalChange={(value) => console.log(value)} />
 *   );
 * };
 * ```
 *
 * @property {any} control The `react-hook-form` control object
 * @property {string} name The name of the select input
 * @property {string | DefaultTFuncReturn} label The label of the select input
 * @property {object[]} options The options for the select input
 * @property {string | number | DefaultTFuncReturn} options.text Option's text.
 * @property {string | number | DefaultTFuncReturn} [options.label] Option's label.
 * @property {string | number} options.value Option's value.
 * @property {function} [onExternalChange] An optional callback function to be called when the select value changes
 *
 * @return {JSX.Element} A customizable select component with internationalization support
 * @author CML Exports Front-End Developers
 */
export default function CustomSelect({ control, name, label, options, onExternalChange }: IProps) {
  const { t } = useTranslation('common');

  return (
    <Box sx={{ width: '100%' }}>
      <Controller
        name={name}
        control={control}
        render={({ field: { value, ...othersFields }, fieldState: { error } }) => (
          <FormControl fullWidth>
            <InputLabel error={Boolean(error?.message)}>{label}</InputLabel>

            <Select label={label} value={value} {...othersFields} error={Boolean(error?.message)}>
              {options.length &&
                options.map(op => (
                  <MenuItem key={op.value} value={op.value}>
                    {op.text || op.label}
                  </MenuItem>
                ))}
            </Select>

            <FormHelperText sx={{ color: 'error.main' }}>{t(error?.message || '')}</FormHelperText>
          </FormControl>
        )}
      />
    </Box>
  );
}
