import { useTranslation } from 'react-i18next';

// * HOOKS
import { useServices } from '../hooks/useServices';
import useServiceForm from '../hooks/useSeviceForm';
import useCategoryForm from '../hooks/useCategoryForm';

// * MUI
import { Plus, Minus, Close } from 'mdi-material-ui';
import { Drawer, Box, Typography, Autocomplete, TextField, Button, Chip, IconButton } from '@mui/material';

// * COMPONENTS
import CategoryForm from './CategoryForm';
import CustomInput from '../../../components/CustomInput';

export default function ServicesForm() {
  const { t } = useTranslation(['common']);

  const {
    forCreateCategory,
    control: categoryControl,
    onSubmit: onSubmitCategory,
    toggleForm: toggleCategoryForm,
  } = useCategoryForm();

  const { Controller, onSubmit, control, clearForm, setForCreateServiceType, forCreateServiceType } = useServiceForm();

  const { toggleForm, handleToggleForm, categoryDataList, seviceTypeDataList, serviceId } = useServices();

  return (
    <Drawer
      open={toggleForm}
      anchor='right'
      onClose={() => {
        clearForm();
        handleToggleForm();
        setForCreateServiceType(false);
      }}
      sx={{
        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: { xs: 300, sm: 400 } },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: ({ spacing }) => spacing(3, 4),
          backgroundColor: ({ palette }) => palette.background.default,
        }}
      >
        <Typography variant='h6'>{serviceId ? t('services.actions.edit') : t('services.actions.create')}</Typography>
        <IconButton
          onClick={() => {
            clearForm();
            handleToggleForm();
            setForCreateServiceType(false);
          }}
        >
          <Close />
        </IconButton>
      </Box>

      <Box
        sx={{ p: 4, display: 'flex', flexDirection: 'column', gap: 3 }}
        component={'form'}
        method='post'
        onSubmit={onSubmit}
      >
        <CustomInput name='serviceName' control={control} label={`${t('services.form.name_es')}`} ns='validations' />
        <CustomInput name='serviceNameEN' control={control} label={`${t('services.form.name_en')}`} ns='validations' />

        <Box sx={{ display: 'flex', gap: 1 }}>
          <Box sx={{ flex: 1 }}>
            {forCreateServiceType ? (
              <>
                <CustomInput name='serviceType.name' control={control} label={`${t('services.form.name_es')}`} />
                <br />
                <br />
                <CustomInput name='serviceType.nameEN' control={control} label={`${t('services.form.name_en')}`} />
              </>
            ) : (
              <Controller
                name='serviceType'
                control={control}
                render={({ field, fieldState }) => (
                  <Box>
                    <Autocomplete
                      fullWidth
                      value={field.name === 'serviceType' ? seviceTypeDataList.find(i => i.id === field.value) : null}
                      options={seviceTypeDataList}
                      getOptionLabel={option => option.name}
                      ChipProps={{ key: Number(field.value) }}
                      onChange={(_, value) => field.onChange(value?.id)}
                      renderInput={params => {
                        return (
                          <TextField
                            {...params}
                            label={t('services.table.typeService', { ns: 'common' })}
                            error={!!fieldState.error}
                            helperText={
                              fieldState.error ? t(`${fieldState.error.message}`, { ns: 'validations' }) : null
                            }
                          />
                        );
                      }}
                    />
                  </Box>
                )}
              />
            )}
          </Box>

          <Box>
            {forCreateServiceType ? (
              <Button
                variant='contained'
                color='primary'
                sx={{ width: '100%' }}
                onClick={() => setForCreateServiceType(false)}
              >
                <Minus />
              </Button>
            ) : (
              <Button
                variant='contained'
                color='primary'
                sx={{ width: '100%' }}
                onClick={() => setForCreateServiceType(true)}
              >
                <Plus />
              </Button>
            )}
          </Box>
        </Box>

        <Box sx={{ display: 'flex', gap: 1 }}>
          <Box sx={{ flex: 1 }}>
            <Controller
              name='categoryId'
              control={control}
              render={({ field, fieldState }) => (
                <Box>
                  <Autocomplete
                    multiple
                    value={
                      field.name === 'categoryId'
                        ? field.value
                          ? field.value.map(id => categoryDataList.find(category => category.id === id))
                          : []
                        : []
                    }
                    fullWidth
                    options={categoryDataList}
                    renderTags={(value, getTagProps) =>
                      value.map((option, i) => (
                        <Chip label={option?.name} {...getTagProps({ index: i })} key={option?.id ?? i} />
                      ))
                    }
                    isOptionEqualToValue={(option, value) => option?.id === value?.id}
                    getOptionLabel={option => {
                      return option?.name ?? '';
                    }}
                    onChange={(_, values) => {
                      const ids = values.map(value => value?.id);
                      field.onChange(ids);
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label={`${t('services.table.category')}`}
                        error={!!fieldState.error}
                        helperText={fieldState.error ? t(`${fieldState.error.message}`, { ns: 'validations' }) : null}
                      />
                    )}
                  />
                </Box>
              )}
            />
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'start' }}>
            <Button variant='contained' color='primary' sx={{ width: '100%' }} onClick={() => toggleCategoryForm()}>
              <Plus />
            </Button>
          </Box>
        </Box>

        <Box>
          <Button variant='contained' type='submit' color='primary'>
            {t('general.save')}
          </Button>
        </Box>
      </Box>

      <CategoryForm
        control={categoryControl}
        onSubmit={onSubmitCategory}
        forCreateCategory={forCreateCategory}
        toggleForm={toggleCategoryForm}
      />
    </Drawer>
  );
}
