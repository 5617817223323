import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// @MUI Components
import { Grid, Card, CardHeader, Divider, CardContent, IconButton } from '@mui/material';

// @MUI Components
import { KeyboardBackspace } from 'mdi-material-ui';

// @Services
import getPaymentFile from '../services/getPaymentFile';

// @Components
import Loader from '../../../components/Loader';
import EmptyData from '../../../components/EmptyData';

// @Interfaces and Types
type FileDataType = { src: string; fileName: string; typeFile: string };

// @Initial States
const initialFileData: FileDataType = { src: '', fileName: '', typeFile: '' };

export default function PreviewFile() {
  const [isLoading, setIsLoading] = useState(true);
  const [fileData, setFileData] = useState(initialFileData);

  const { t } = useTranslation('common');

  const { id, paymentId } = useParams();

  useEffect(() => {
    getPaymentFile(Number(paymentId))
      .then(file => {
        setFileData(file);
        setIsLoading(false);
      })
      .catch(err => console.error(err));
  }, []);

  if (isLoading) return <Loader />;

  return (
    <Grid container>
      <Grid item xs={12} sx={{ mx: 'auto' }}>
        <Card sx={{ height: '95vh' }}>
          <IconButton href={`/invoices/${id}/?logsOpen="true"`} sx={{ m: 1 }} color='error'>
            <KeyboardBackspace />
          </IconButton>

          {fileData ? (
            <>
              <CardHeader
                title={fileData.fileName}
                sx={{ display: 'flex', p: 1, pl: 5, textAlign: 'center', border: 'thin solid #b1b1b1' }}
                titleTypographyProps={{ variant: 'h6' }}
              />

              <Divider sx={{ margin: 0 }} />

              <CardContent sx={{ height: '90%' }}>
                {fileData?.typeFile?.includes('pdf') ? (
                  <embed src={fileData.src} style={{ display: 'block', width: '100%', height: '100%' }} />
                ) : (
                  <img src={fileData.src} style={{ display: 'block', width: '100%', height: '100%' }} />
                )}
              </CardContent>
            </>
          ) : (
            <EmptyData text={t('general.fileNotFound')} />
          )}
        </Card>
      </Grid>
    </Grid>
  );
}
