import { Supplier, GetSupplierByIDS } from '../models';
import { axiosEstimates } from '../../../../utils/api';

export async function getSupplierByIds(serviceTypeIds: number[], transportationIds: number[]): Promise<Supplier[]> {
  const response = await axiosEstimates.post<GetSupplierByIDS>(
    '/api/v2/supplier-by-ids',
    {
      serviceTypeIds,
      transportationIds,
    },
    {
      headers: { 'x-module': 'suppliers' },
    },
  );

  return response.data.data;
}

export async function getContent(shipmentIds: number[], idBudget: string): Promise<string> {
  const response = await axiosEstimates.post<{ initialContent: string }>('/suppliers/get-content/' + idBudget, {
    shipmentIds,
  });

  return response.data.initialContent;
}

export async function sendEmails(emails: string[], content: string, idBudget: number): Promise<void> {
  await axiosEstimates.post('/suppliers/send-emails', { emails, content, idBudget });
}
