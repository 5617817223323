import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// @MUI Components
import {
  Box,
  Card,
  CardHeader,
  Typography,
  CardContent,
  LinearProgress,
  Chip,
  Grid,
  IconButton,
  useMediaQuery,
  useTheme,
} from '@mui/material';

// @Utils
import { FormatNumber } from '../../../utils';
import { truncateTitle } from '../../../utils/truncate-title';
import { FromDateStringToMDYString } from '../../../utils/date-to-string';

// @Icons
import Printer from 'mdi-material-ui/Printer';
import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { EmailEdit } from 'mdi-material-ui';

// @Components
import CustomStack from '../../../components/CustomStack';

// @Interfaces and Types
import { IBill } from '../models/Models';
interface IProps {
  data: IBill;
  canPrint: boolean;
  canSend: boolean;
  canCancel: boolean;
  canPreview: boolean;
  handleCancel: (id: number, hasPayments: boolean) => void;
}

export default function InvoiceCard({ data, canPrint, canSend, canCancel, canPreview, handleCancel }: IProps) {
  const { t } = useTranslation('common');

  const navigate = useNavigate();

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const template = () => {
    if (location.pathname === '/invoices/us-cml') return 'cml';
    else if (location.pathname === '/invoices/ctis') return 'ctis';
    else return 'sv';
  };

  const typeOfBill = (data: any) => (data.billTypeId === 1 ? 'FF' : data.billTypeId === 2 ? 'CCF' : t('general.bill'));

  const handlePrintClick = (id: number, billType: string, budgetType: number, budgetId: number) => {
    navigate(`/invoices/print/${id}/${billType}/${budgetType}/${budgetId}/${template()}`);
  };

  const calculatePaymentProgress: () => string = () => {
    return `${Math.round((data.totalPaid / data.totalAmount || 0) * 100)}%`;
  };

  return (
    <Grid item xs={12}>
      <Card sx={{ padding: '0.2em' }}>
        <CardHeader
          sx={{ m: 0, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}
          title={
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant='h6' sx={{ fontWeight: 600, fontSize: 'small' }}>
                {truncateTitle(data.customerName, isMobile ? 18 : 25)}
              </Typography>

              <Box sx={{ ml: 'auto', color: 'text.secondary', fontWeight: 600, fontSize: 'small' }}>
                {`${typeOfBill(data)} ${data.folio}`}
              </Box>
            </Box>
          }
          subheader={
            <Grid>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
                <Typography sx={{ mr: 1, fontWeight: 600, fontSize: 'small' }}>{t('general.generatedBy')}</Typography>
                <Typography sx={{ color: 'text.secondary', fontSize: 'small' }}>{data.nameGeneratedBy}</Typography>
              </Box>

              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                  <Typography sx={{ mr: 1, fontWeight: 600, fontSize: 'small' }}>{t('general.date')}</Typography>
                  <Typography sx={{ color: 'text.secondary', fontSize: 'small' }}>
                    {FromDateStringToMDYString(data.createAt)}
                  </Typography>
                </Box>

                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                  {data.status === 'active' ? (
                    <Chip label={t('general.active')} color='success' />
                  ) : (
                    <Chip label={t('general.cancelled')} color='error' />
                  )}
                </Box>
              </Box>
            </Grid>
          }
        />

        <CardContent sx={{ borderTop: 'thin solid #000000025' }}>
          <Box sx={{ mb: 1, gap: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography variant='caption'>Payment Progress</Typography>
            <Typography variant='caption'>{calculatePaymentProgress()}</Typography>
          </Box>

          <LinearProgress
            color='success'
            variant='determinate'
            value={Math.round((data.totalPaid / data.totalAmount || 0) * 100)}
            sx={{
              mb: 3.5,
              height: 8,
              borderRadius: 2,
              background: '#ff000050',
              '& .MuiLinearProgress-bar': { borderRadius: 2 },
            }}
          />

          {/* NOTE: TOTAL AMOUNTS */}
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              rowGap: 2,
              columnGap: 2,
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CustomStack
              title={t('general.amountOfSubtotal')}
              text={`$${FormatNumber(data.subTotal)}`}
              color='secondary'
              variant={'outlined'}
            />

            <CustomStack
              title={t('general.taxCard')}
              text={`$${data.iva && FormatNumber(data.iva)}`}
              color='secondary'
              variant={'outlined'}
            />

            <CustomStack
              title={t('general.total')}
              text={`$${FormatNumber(data.totalAmount)}`}
              color='success'
              variant={'outlined'}
            />
          </Box>

          {/* NOTE: ACTIONS */}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: canPrint && canSend && canCancel && canPreview ? 'space-between' : 'center',
              gap: canPrint && canSend && canCancel && canPreview ? 0 : 15,
              marginTop: '14px',
            }}
          >
            {canPrint && (
              <IconButton
                sx={{ color: 'green' }}
                onClick={() => handlePrintClick(data.id, data.billTypeName, data.billTypeId, data.budgetId)}
              >
                <Printer fontSize='inherit' />
              </IconButton>
            )}

            {canSend && (
              <IconButton
                color='success'
                title={`${t('general.send')}`}
                name='general.send'
                disabled={data.status === 'canceled'}
                onClick={() => navigate(`/invoice/send/${data.id}/${data.billTypeId}?action=shareInvoice`)}
              >
                <EmailEdit fontSize='inherit' />
              </IconButton>
            )}

            {canCancel && (
              <IconButton
                sx={{ color: 'red' }}
                disabled={data.status === 'canceled'}
                onClick={() => handleCancel(data.id, data.payments.length !== 0)}
              >
                <DoNotDisturbAltIcon fontSize='inherit' />
              </IconButton>
            )}

            {canPreview && (
              <IconButton
                sx={{ color: '#16B1FF' }}
                disabled={data.status === 'canceled'}
                onClick={() => navigate(`/invoices/${data.id}/?type=${template()}`)}
              >
                <VisibilityIcon fontSize='inherit' />
              </IconButton>
            )}
          </Box>
        </CardContent>
      </Card>
    </Grid>
  );
}
