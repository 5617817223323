import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import { registerLocale } from 'react-datepicker';
import es from 'date-fns/locale/es';
import Moment from 'react-moment';
import 'react-datepicker/dist/react-datepicker.css';

// @Mui
import { Box, Card, CardContent, Grid, InputLabel, TextField, FormHelperText, Typography } from '@mui/material';

// @Hooks
import { useSellerForm } from './use-seller-form.hook';

// @Views
import { FormActions } from '../form-actions/form-actions.view';

// @Components
import { SelectInput, DatePicker, ReactSelectForm } from '../../../components';
import { useItems } from '../service-form/utils/items';

registerLocale('es', es);

interface IProps {
  permissions: string[];
}
export const SellerForm = ({ permissions }: IProps) => {
  const { incotermsItem } = useItems();

  const {
    control,
    errors,
    onSubmit,
    sellers,
    expDays,
    today,
    disabledSelect,
    typeBudget,
    dateRerequired,
    sellersItems,
  } = useSellerForm({ permissions });

  const { t } = useTranslation('common');

  return (
    <Box sx={{ p: 2 }}>
      <Card>
        <CardContent sx={{ m: 2 }}>
          <Grid container spacing={3} sx={{ p: 2, display: 'flex' }}>
            <Grid item xs={12}>
              <ReactSelectForm
                control={control}
                items={sellersItems}
                label={t('budgetPage.seller')}
                name='sellerId'
                translationFile='validations'
              />
            </Grid>

            <Grid item xs={6}>
              <InputLabel htmlFor='daysOfExpiration'>{t('budgetPage.expDays')}</InputLabel>

              <Controller
                name='daysOfExpiration'
                control={control}
                rules={{
                  required: { value: true, message: 'errors.required' },
                  validate: value => value !== 0 || 'date.expirationDay',
                }}
                render={({ field }) => (
                  <TextField
                    required
                    {...field}
                    size='medium'
                    value={field.value}
                    fullWidth
                    error={Boolean(errors.dateOfExpiration)}
                    type='number'
                    placeholder='Días de Expiración'
                    onClick={e => {
                      const target = e.target as HTMLInputElement;
                      field.onChange(target.value);
                    }}
                  />
                )}
              />
              {errors.daysOfExpiration && (
                <FormHelperText sx={{ color: 'error.main' }}>
                  {t(`${errors.daysOfExpiration.message}`, { ns: 'validations' })}
                </FormHelperText>
              )}
            </Grid>

            <Grid item xs={6} sx={{ mt: 2.9 }}>
              <DatePicker
                control={control}
                minDate={new Date()}
                name='dateOfExpiration'
                error={Boolean(errors.dateOfExpiration)}
              />
              {errors.dateOfExpiration && (
                <FormHelperText sx={{ color: 'error.main' }}>
                  {t(`${errors.dateOfExpiration.message}`, { ns: 'common' })}
                </FormHelperText>
              )}

              {dateRerequired ? (
                ''
              ) : (
                <small>
                  {t('budgetPage.expDate')}:{' '}
                  <Moment fromNow ago add={{ days: expDays || undefined }}>
                    {today}
                  </Moment>{' '}
                  | <Typography variant={'caption'}> {today.toLocaleDateString()} </Typography>
                </small>
              )}
            </Grid>

            <Grid item xs={12}>
              <InputLabel htmlFor='budgetType'>{t('budgetPage.type')}</InputLabel>
              <SelectInput control={control} name='budgetType' items={typeBudget} label='' size='small' />
            </Grid>

            <Grid item xs={12}>
              <InputLabel htmlFor='incoterms'>Incoterms</InputLabel>
              <SelectInput control={control} name='incoterms' items={incotermsItem} label='' size='small' />
            </Grid>
          </Grid>
        </CardContent>

        <FormActions nextCallback={onSubmit} />
      </Card>
    </Box>
  );
};
