// @Utils
import { axiosEstimates } from '../../../utils';

// @Interfaces and Types
import { IExpensesForm } from '../../../models';
import { expensesResponseType } from '../types';

const createExpenses = async (data: IExpensesForm, expenseDocumentFile: File | null) => {
  try {
    const response = await axiosEstimates.post<expensesResponseType>('expenses', data);

    if (expenseDocumentFile) {
      const formData = new FormData();
      formData.append('expenseDocumentFile', expenseDocumentFile);

      await axiosEstimates.post(`expenses-files/${response.data.id}?code=${response.data.code}`, formData);
    }

    return response;
  } catch (err) {
    console.error(err);
  }
};

export default createExpenses;
