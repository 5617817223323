import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

// @MUI Components
import { Box, Collapse, TableCell, TableRow, Chip, IconButton } from '@mui/material';

// @MUI Icons
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

// @Utils
import { formatNumber } from '../../../utils/format-number.util';

// @Components
import SubRow from './SubRow';

// @Models
import { IDocumentExpense } from '../../../models/expenses/expenses-form.model';

interface IRow {
  index: number;
  setSelectedRow: any;
  selectedRow: any;
  row: IDocumentExpense;
  changeStatus: (id: number, status: number) => void;
  expensesHasDocuments: (id: number) => boolean;
  canEdit?: boolean;
  canAprove?: boolean;
  canCancel?: boolean;
}

interface chipItem {
  value: string | number;
  label: string;
  color: 'default' | 'success' | 'info' | 'error' | 'primary' | 'secondary' | 'warning';
}

export default function Row({
  row,
  index,
  setSelectedRow,
  selectedRow,
  changeStatus,
  expensesHasDocuments,
  canEdit,
  canAprove,
  canCancel,
}: IRow) {
  const { t } = useTranslation();

  const totalValue = row.documentExpenses
    .map(d => d.expenses.map(e => e.subTotal))
    .flat()
    .reduce((a, b) => a + b);

  const isRowSelected = selectedRow === index;

  const handleClick = () => setSelectedRow(isRowSelected ? null : index);

  const statusBudgetItem: chipItem[] = [
    {
      value: 'active',
      color: 'success',
      label: `${t('general.active', { ns: 'common' })}`,
    },
    {
      value: 'pending',
      color: 'info',
      label: `${t('general.pending', { ns: 'common' })}`,
    },
    {
      value: 'cancel',
      color: 'error',
      label: `${t('general.cancelled', { ns: 'common' })}`,
    },
    {
      value: 'review',
      color: 'primary',
      label: `${t('general.review', { ns: 'common' })}`,
    },
    {
      value: 'request',
      color: 'warning',
      label: `${t('general.request', { ns: 'common' })}`,
    },
    {
      value: 'initialized',
      color: 'warning',
      label: `${t('general.initialized', { ns: 'common' })}`,
    },
    {
      value: 'approved',
      color: 'success',
      label: `${t('general.approved', { ns: 'common' })}`,
    },
    {
      value: 'rejected',
      color: 'error',
      label: `${t('general.rejected', { ns: 'common' })}`,
    },
    {
      value: 'toBeConfirmed',
      color: 'info',
      label: `${t('general.toBeConfirmed', { ns: 'common' })}`,
    },
    {
      value: 'toBeInvoiced',
      color: 'success',
      label: `${t('general.approved', { ns: 'common' })}`,
    },
    {
      value: 'finished',
      color: 'success',
      label: `${t('general.finished', { ns: 'common' })}`,
    },
  ];

  const statusBudget = statusBudgetItem.find(status => status.value === row.status);

  return (
    <Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton aria-label='expand row' size='small' onClick={handleClick}>
            {isRowSelected ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>

        <TableCell align='center'>{row.code}</TableCell>

        <TableCell align='center'>{row.entities.find(entity => entity.type === 'CU')?.name || ''}</TableCell>

        <TableCell align='center'>{formatNumber(totalValue)}</TableCell>

        <TableCell align='center'>
          <Chip
            size='medium'
            label={statusBudget?.label || ''}
            color={statusBudget?.color}
            sx={{ textTransform: 'capitalize', padding: '0.5rem', margin: '0 0.5rem' }}
          />
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell style={{ padding: '0', width: '100%', background: '#f3f3f3' }} colSpan={5}>
          <Collapse in={isRowSelected} timeout='auto' unmountOnExit>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              {row.documentExpenses.map((document, index) => (
                <SubRow
                  row={document}
                  key={index}
                  changeStatus={changeStatus}
                  expensesHasDocuments={expensesHasDocuments}
                  canEdit={canEdit && row.canEdit}
                  canAprove
                  canCancel
                />
              ))}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  );
}
