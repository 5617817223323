import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

// @Models
import { IShipmentService, ISelectInput } from '../../../../../models';

// @Utils
import { useItems } from '../../../service-form/utils/items';

interface IParams {
  handleAddService: (value: Omit<IShipmentService, 'id'>) => void;
  handleEditService: (value: IShipmentService) => void;
  service?: IShipmentService;
  open: boolean;
}

const initialValues: Omit<IShipmentService, 'id'> = {
  area: 0,
  comments: '',
  content: '',
  high: 0,
  insurance: 0,
  length: 0,
  physicalWeight: 0,
  unitOfMeasurement: 'cmkg',
  volumetricWeight: 0,
  serial: '',
  width: 0,
  idServiceType: null,
  idTransportation: null,
};

interface ITypeUnit {
  value: string;
  label: string;
  scale: number;
  scaleArea: number;
  size: string;
  weight: string;
  area: string;
}

export const schemaValidation = yup
  .object()
  .shape({
    idTransportation: yup.number().typeError('errors.categoryTypeError').required('errors.required').nullable(),
    idServiceType: yup.number().typeError('errors.serviceTypeTypeError').required('errors.required').nullable(),
    content: yup.string().required('errors.required').max(250, 'errors.maxLength').matches(/\w/, 'errors.required'),
  })
  .required();

export function useServiceForm({ handleAddService, handleEditService, service, open }: IParams) {
  const [unit, setUnit] = useState<ITypeUnit | undefined>();

  const { t } = useTranslation();

  const { typeUnit } = useItems();

  const { control, watch, handleSubmit, setValue, reset } = useForm<Omit<IShipmentService, 'id'>>({
    defaultValues: initialValues,
    resolver: yupResolver(schemaValidation),
  });

  const length = watch('length');
  const high = watch('high');
  const width = watch('width');
  const unitOfMeasurement = watch('unitOfMeasurement');
  const watchIdTransportation = watch('idTransportation');
  const watchIdServiceType = watch('idServiceType');

  const onSubmit = (data: Omit<IShipmentService, 'id'>) => {
    if (service) handleEditService({ ...data, id: service.id });
    else handleAddService(data);
  };

  const onChangeTransportations = async (transportationData: ISelectInput<number> | number) => {
    if (!transportationData) return;

    const idTransportation = typeof transportationData === 'number' ? transportationData : transportationData.value;

    setValue('idTransportation', idTransportation);
    setValue('idServiceType', null);
  };

  const onChangeTypeService = async (typeServiceData: ISelectInput<number> | number) => {
    if (!typeServiceData) return;

    const typeServiceId = typeof typeServiceData === 'number' ? typeServiceData : typeServiceData.value;

    setValue('idServiceType', typeServiceId);
  };

  useEffect(() => {
    if (service) {
      reset(service);

      if (service.idTransportation) {
        onChangeTransportations(service.idTransportation);
        if (service.idServiceType) onChangeTypeService(service.idServiceType);
      }
    } else reset(initialValues);

    if (!open) {
      reset(initialValues);
      onChangeTransportations(0);
      onChangeTypeService(0);
    }
  }, [service, open]);

  useEffect(() => {
    const weight = length * high * width;

    if (unit) {
      setValue('volumetricWeight', Math.ceil(weight / unit.scale));
      setValue('area', Math.ceil(weight / unit.scaleArea));
    }
  }, [length, high, width]);

  useEffect(() => {
    const unit = typeUnit.find(unit => unit.value === unitOfMeasurement);

    setUnit(unit);
    setValue('high', service?.high || 0);
    setValue('length', service?.length || 0);
    setValue('width', service?.width || 0);
    setValue('physicalWeight', service?.physicalWeight || 0);
  }, [unitOfMeasurement]);

  return {
    control,
    onSubmit: handleSubmit(onSubmit),
    t,
    unit,
    typeUnit,
    onChangeTransportations,
    onChangeTypeService,
    watchIdTransportation,
    watchIdServiceType,
  };
}
