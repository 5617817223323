// import axios from 'axios';
import { useFieldArray, useForm, useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import sweetalert2 from 'sweetalert2';
// @Translations
import { useTranslation } from 'react-i18next';

import { useNavigate, useParams } from 'react-router';
import * as yup from 'yup';
// import { ISupplierForm } from '../../../models';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  ISupplierForm,
  ISupplierRequest,
  ICreateSupplierDetails,
  ISelectInput,
  ServiceTypesSelect,
} from '../../../models';
import { axiosEstimates } from '../../../utils/api';

interface CharacterCounterProps {
  value: string;
  maxLength: number;
}

const preLoadFields: ISupplierForm = {
  name: '',
  identificationType: 1,
  comercialName: '',
  notes: '',
  taxIdentification: '',
  categories: [],
  serviceTypes: [],
  address: [{ id: 0, value: '' }],
  country: [{ id: 0, value: '' }],
  email: [{ id: 0, value: '' }],
  phone: [{ id: 0, value: 'null' }],
  isPublic: false,
};
export const useSuppliersForm = () => {
  const [counter, setCounter] = React.useState('');
  const [alert, setAlert] = React.useState({ open: false, type: 'Info', content: '' });

  const navigate = useNavigate();

  const countries = useSelector((state: any) => state.countries.countryItems);
  // NOTE: PERMISSIONS
  const [permissions, setPermissions] = React.useState<any>({});

  const { id } = useParams();

  const { t } = useTranslation(['common']);

  const maxLength = 200;
  const idDets: any[] = [];
  const countriesVals: any[] = [];
  const isUpdate = !!id;

  function CharacterCounter({ value, maxLength }: CharacterCounterProps): JSX.Element {
    const count = value.length;
    return (
      <p>
        {count}/{maxLength}
      </p>
    );
  }

  function handleChange(event: { target: { value: React.SetStateAction<string> } }) {
    setCounter(event.target.value);
  }

  const schemaValidation = yup.object().shape({
    name: yup.string().required(t('errors.required') as string),
    categories: yup.array().required('errors.required').min(1, 'errors.required'),
    serviceTypes: yup.array().required('errors.required').min(1, 'errors.required'),
    identificationType: yup
      .number()
      .typeError(t('errors.required') as string)
      .required(t('errors.required') as string),
    taxIdentification: yup.string().required(t('errors.required') as string),
    address: yup.array().of(
      yup.object().shape({
        value: yup.string().required(t('errors.required') as string),
      }),
    ),
    country: yup.array().of(
      yup.object().shape({
        value: yup.string().required(t('errors.required') as string),
      }),
    ),
    email: yup.array().of(
      yup.object().shape({
        value: yup
          .string()
          .email(t('errors.pattern') as string)
          .required(t('errors.required') as string),
      }),
    ),
    phone: yup.array().of(
      yup.object().shape({
        value: yup
          .string()
          .matches(/^(\d{10,15})$/, t('errors.pattern') as string)
          .required(t('errors.required') as string),
      }),
    ),
  });

  const {
    control,
    formState: { errors },
    reset,
    handleSubmit,
  } = useForm<ISupplierForm>({
    defaultValues: preLoadFields,
    resolver: yupResolver(schemaValidation),
  });

  const categories = useWatch({ control, name: 'categories' });

  const serviceTypes = useWatch({ control, name: 'serviceTypes' });

  // NOTE: ALL ACTIONS
  const getPermissions = () => {
    (async () => {
      try {
        const responseGET: any = await axiosEstimates.get('suppliers', {
          headers: { 'x-module': 'suppliers' },
        });
        setPermissions(responseGET.data.permissions);
      } catch {
        console.error('Error');
      }
    })();
  };

  const getSuppliers = () => {
    (async () => {
      const responseGET = await axiosEstimates.get<ISupplierRequest>(`supplierdetails/${id}`, {
        headers: { 'x-module': 'services' },
      });
      const residDetails = responseGET.data.suppliersDetails;
      residDetails.forEach(element => {
        idDets.push(element.id);
      });
      if (!responseGET?.data?.message) {
        const arrEmails: any = [];
        const arrPhones: any = [];
        const arrCountries: any = [];
        const arrAddresses: any = [];
        const details = responseGET.data.suppliersDetails;

        details.forEach((e: any) => {
          if (e.email) {
            arrEmails.push({
              id: e.id,
              value: e.email,
            });
          }
          if (e.phone) {
            arrPhones.push({
              id: e.id,
              value: e.phone,
            });
          }
          if (e.country) {
            arrCountries.push({
              id: e.id,
              value: e.country,
            });
          }
          if (e.address) {
            arrAddresses.push({
              id: e.id,
              value: e.address,
            });
          }
        });
        arrCountries.forEach((element: any) => {
          countriesVals.push(countries.find((value: any) => value.value === element.value));
        });

        const data = responseGET.data;

        const categories: ISelectInput<number>[] = [];
        const serviceTypes: ServiceTypesSelect[] = [];

        data.transportation.forEach(category => {
          categories.push({
            label: category.name,
            labelEN: category.nameEN,
            value: category.id,
          });
        });

        data.service_types.forEach(type => {
          serviceTypes.push({
            label: type.name,
            labelEN: type.nameEN,
            value: type.id,
            category: 1,
          });
        });
        reset({
          name: responseGET.data.name,
          comercialName: responseGET.data.comercialName,
          notes: responseGET.data.notes,
          email: arrEmails,
          phone: arrPhones,
          country: arrCountries,
          address: arrAddresses,
          categories,
          serviceTypes,
          identificationType: data.identificationType,
          taxIdentification: data.taxIdentification,
          isPublic: data.idUser === 0,
        });
      }
    })();
  };

  useEffect(() => {
    if (isUpdate) {
      getSuppliers();
    }
    reset(preLoadFields);
  }, [t]);

  useEffect(() => {
    getPermissions();
  }, []);

  /* React.useEffect(() => {
    const serviceTypes = getValues('serviceTypes');
    if (serviceTypes.length > 0) {
      const categoriesId = categories.map(category => category.value);
      const newServiceTypes = serviceTypes.filter(type => type.category && categoriesId.includes(type.category));

      setValue('serviceTypes', newServiceTypes);
    }
  }, []); */

  const { fields: emailFields, append: emailAppend, remove: emailRemove } = useFieldArray({ control, name: 'email' });
  const { fields: phoneFields, append: phoneAppend, remove: phoneRemove } = useFieldArray({ control, name: 'phone' });
  const {
    fields: countryFields,
    append: countryAppend,
    remove: countryRemove,
  } = useFieldArray({ control, name: 'country' });

  if (emailFields.length === 0) {
    emailAppend({
      value: '',
    });
  }
  if (phoneFields.length === 0) {
    phoneAppend({
      value: '',
    });
  }
  if (countryFields.length === 0) {
    countryAppend({
      value: '',
    });
  }
  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    if (alert.type === 'success') {
      navigate('/suppliers');
      setAlert({ open: false, type: 'info', content: '' });
    } else if (alert.type === 'error') {
      setAlert({ open: false, type: 'info', content: '' });
    }
  };

  const beforeSubmit = (datos: ISupplierForm) => {
    const data = {
      name: datos.name,
      notes: datos.notes,
      comercialName: datos.comercialName,
      identificationType: datos.identificationType,
      taxIdentification: datos.taxIdentification,
      email: datos.email.map(email => email.value),
      phone: datos.phone.map(phone => phone.value),
      country: datos.country.map(country => country.value),
      address: datos.address.map(address => address.value),
      serviceTypes: datos.serviceTypes.map(type => type.value),
      categories: datos.categories.map(category => category.value),
      isPublic: datos.isPublic,
    };
    onSubmit(data);
  };

  const onSubmit = (data: any) => {
    axiosEstimates
      .post('suppliers', data)
      .then(() => {
        sweetalert2.fire(`${t('alert.supplier.success')}`, `${t('alert.supplier.successMessage')}`, 'success');
        navigate('/suppliers');
      })
      .catch(error => {
        setAlert({ open: true, type: 'error', content: error.response.data.message });
      });
  };

  const onUpdate = async (data: ISupplierForm) => {
    /* Obtener el array con mas items */
    const length: number[] = [data.address.length, data.country.length, data.email.length, data.phone.length];
    const max = Math.max(...length);

    const details: ICreateSupplierDetails[] = [];

    for (let i = 0; i < max; i++) {
      details.push({
        id: data.address[i]?.id || data.country[i]?.id || data.email[i]?.id || data.phone[i]?.id || null,
        address: data.address[i]?.value || null,
        country: data.country[i]?.value || null,
        email: data.email[i]?.value || null,
        phone: data.phone[i]?.value || null,
      });
    }

    try {
      await axiosEstimates.post(
        'supplierdetails',
        {
          id,
          name: data.name,
          notes: data.notes,
          comercialName: data.comercialName,
          identificationType: data.identificationType,
          taxIdentification: data.taxIdentification,
          supplierDetails: details,
          serviceTypes: data.serviceTypes.map(type => type.value),
          categories: data.categories.map(category => category.value),
          isPublic: data.isPublic,
        },
        { headers: { 'x-module': 'services' } },
      );

      setAlert({ open: true, type: 'success', content: 'Proveedor registrado con exito' });
      navigate('/suppliers');
    } catch (error: any) {
      setAlert({ open: true, type: 'error', content: error.response.data.message });
    }
  };

  return {
    control,
    errors,
    t,
    onSubmit: handleSubmit(beforeSubmit),
    // eslint-disable-next-line no-console
    onUpdate: handleSubmit(onUpdate),
    countries,
    emailAppend,
    phoneAppend,
    countryAppend,
    emailFields,
    phoneFields,
    countryFields,
    emailRemove,
    phoneRemove,
    countryRemove,
    handleClose,
    alert,
    isUpdate,
    categories,
    serviceTypes,
    CharacterCounter,
    counter,
    handleChange,
    maxLength,
    permissions,
  };
};
