import { ReactNode, useState, useEffect } from 'react';

// @MUI Components
import { Tabs, Tab, Box } from '@mui/material/';

// @Interfaces and Types
import { TabOption } from '../models/common';

interface TabsProps {
  tabs: TabOption[];
  tab?: number;
}

interface TabPanelProps {
  children?: ReactNode;
  index: number;
  value: number;
}

function TabPanel({ children, value, index, ...other }: TabPanelProps) {
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

/**
 * @component
 * @subcategory Global
 *
 * @description
 * A customizable tabs component with different skins and colors.
 *
 * Example
 * ```jsx
 * return(
 *  <CustomTabs tabs={tabs} tab={1} />
 * )
 * ```
 *
 * @property {TabOption[]} tabs An array of tab options.
 * @property {number} [tab] The initial selected tab.
 *
 * @returns {JSX.Element} A custom Tabs component.
 * @author CML Exports Front-End Developers
 */
export default function CustomTabs({ tabs, tab }: TabsProps) {
  const [value, setValue] = useState(1);

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (tab) handleChange({} as any, tab);
  }, [tab]);

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange}>
          {tabs.map(
            (tab, index) =>
              !tab.notRender && <Tab label={tab.label} key={index} {...a11yProps(index)} value={tab.id} />,
          )}
        </Tabs>
      </Box>

      {tabs.map((tab, index) => (
        <TabPanel value={value} index={tab.id} key={index}>
          {!tab.notRender && tab.content}
        </TabPanel>
      ))}
    </>
  );
}
