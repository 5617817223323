import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import ES from 'date-fns/locale/es';

// @MUI Components
import { Box, Button, DialogActions, TextField, CardContent, IconButton, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

// @MUI icons
import Eraser from 'mdi-material-ui/Eraser';

// @Components
import Dialog from '../../../components/Dialog';
import Pagination from '../../../components/Pagination';

// @Utils
import { defaultColumns } from './utils';
import { DataGridStyles } from '../../../utils';
import LocalizedTextMap from '../../../translations/LocalizedESTextMap';

// @Services
import { getBudgets, updateManyStatusBudgets } from '../services';

// @Models
import { IGetBudgets } from '../../../models';

interface Props {
  open: boolean;
  toggle: () => void;
  status: string;
  type: 1 | 2 | 3;
  idUser: number;
  isSeller: boolean;
  resetState: () => void;
}

export function FilterToCancel({ open, toggle, status, type, idUser, isSeller, resetState }: Props) {
  const [count, setCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [budgets, setBudgets] = useState<IGetBudgets[]>([]);
  const [selected, setSelected] = useState<number[]>([]);
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const { t, i18n } = useTranslation(['common']);

  const columns = defaultColumns();
  const currentLanguage = i18n.language;

  useEffect(() => {
    if (!open) {
      setStartDate(null);
      setEndDate(null);
    }
  }, [open]);

  useEffect(() => {
    if (open) getBudgetsData();
  }, [page, pageSize, open, endDate]);

  const getBudgetsData = async () => {
    try {
      setLoading(true);

      const { budgets, pagination } = await getBudgets({
        filter: '',
        idUser,
        page: page + 1,
        perPage: pageSize,
        status,
        isSeller,
        type,
        from: startDate?.toISOString() || '',
        to: endDate?.toISOString() || '',
      });
      setBudgets(budgets);
      setCount(pagination.count);

      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleDate = (date: any | null, type: 'from' | 'to') => {
    if (date === null) {
      if (type === 'from') setStartDate(null);
      else setEndDate(null);
    }

    if (!isNaN(date) && date instanceof Date) {
      if (type === 'from') setStartDate(date);
      else setEndDate(date);
    }
  };

  const handleCancel = async () => {
    try {
      await updateManyStatusBudgets({
        ids: selected,
        status: 'cancel',
        idUser,
      });
      toggle();
      resetState();
    } catch (error) {}
  };

  return (
    <Dialog open={open} handleClose={() => toggle()} dialogTitle=''>
      <CardContent>
        <Box sx={{ textAlign: 'center', width: '100%' }}>
          <Typography variant='body1' sx={{ fontWeight: 800 }}>
            {status === 'all'
              ? `${t('filterToCancel.title', { ns: 'common' })}`
              : `${t('filterToCancel.title', { ns: 'common' })} ${t(`filterToCancel.status.${status}`, {
                  ns: 'common',
                })}`}
          </Typography>
        </Box>

        <Box
          sx={{
            p: 5,
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'wrap',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
            gap: 1,
          }}
        >
          <Box>
            <Typography variant='subtitle2'>{t('filterToCancel.instruction', { ns: 'common' })}</Typography>
          </Box>

          <Box sx={{ display: 'flex', gap: 4, justifyContent: 'flex-end' }}>
            <Box sx={{ display: 'flex', gap: 4, justifyContent: 'flex-end' }}>
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={currentLanguage === 'es' ? ES : undefined}
              >
                <DatePicker
                  renderInput={params => (
                    <div style={{ position: 'relative', display: 'inline-block' }}>
                      <TextField {...params} />

                      <IconButton
                        style={{ position: 'absolute', top: '.5rem', margin: 'auto', right: '2rem' }}
                        onClick={() => handleDate(null, 'from')}
                      >
                        <Eraser />
                      </IconButton>
                    </div>
                  )}
                  value={startDate}
                  onChange={(date: any) => handleDate(date, 'from')}
                  label={t('general.from', { ns: 'common' })}
                  views={['year', 'month', 'day']}
                  inputFormat='MM/dd/yyyy'
                />
              </LocalizationProvider>
            </Box>

            <Box sx={{ display: 'flex', gap: 4, justifyContent: 'flex-end' }}>
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={currentLanguage === 'es' ? ES : undefined}
              >
                <DatePicker
                  renderInput={params => (
                    <div style={{ position: 'relative', display: 'inline-block' }}>
                      <TextField {...params} />

                      <IconButton
                        style={{ position: 'absolute', top: '.5rem', margin: 'auto', right: '2rem' }}
                        onClick={() => handleDate(null, 'to')}
                      >
                        <Eraser />
                      </IconButton>
                    </div>
                  )}
                  componentsProps={{ actionBar: { actions: ['clear'] } }}
                  value={endDate}
                  onChange={(date: any) => handleDate(date, 'to')}
                  label={t('general.to', { ns: 'common' })}
                  views={['year', 'month', 'day']}
                  inputFormat='MM/dd/yyyy'
                />
              </LocalizationProvider>
            </Box>
          </Box>
        </Box>

        <Box sx={{ height: 350, width: '100%', overflowY: 'auto' }}>
          <DataGrid
            loading={loading}
            page={page}
            pageSize={pageSize}
            autoHeight
            rows={budgets}
            sx={{ ...DataGridStyles }}
            checkboxSelection
            disableSelectionOnClick
            columns={columns}
            hideFooter
            onSelectionModelChange={newSelection => setSelected(newSelection as number[])}
            selectionModel={selected}
            localeText={LocalizedTextMap()}
          />
        </Box>

        <Pagination count={count} setPage={setPage} setPageSize={setPageSize} page={page} pageSize={pageSize} />

        <DialogActions>
          <Button
            size='medium'
            sx={{ m: 2, backgroundColor: '#fc3030' }}
            variant='contained'
            onClick={handleCancel}
            disabled={selected.length === 0}
          >
            {t('general.cancel')}
          </Button>
        </DialogActions>
      </CardContent>
    </Dialog>
  );
}
