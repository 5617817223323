// @Libraries
import { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { useFieldArray, useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';

// @Redux
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/reducers';

// @Models
import { Booking } from '../../../../models';

// @Hooks
import { useDocument } from '../../hooks';

// @Utils
import { axiosEstimates } from '../../../../utils';
import { initialValues, schemaValidation } from './utils/validationSchema';
import { DOCUMENT_TYPE_OBJ } from '../../utils';

const useBooking = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const { budget, documents, handleNavigatePrint, handleFormChange, handleFormChange2 } = useDocument();
  const [currentData, setCurrentData] = useState<object | null>(null);
  const [documentId, setDocumentId] = useState<number | null>(null);

  const { control, handleSubmit, reset, watch, getValues } = useForm<Booking>({
    defaultValues: initialValues,
    resolver: yupResolver(schemaValidation),
    reValidateMode: 'onChange',
  });

  useEffect(() => {
    getValues();
    const current = JSON.stringify(currentData);
    const updated = JSON.stringify(getValues());
    if (current !== updated) {
      handleFormChange();
    } else {
      handleFormChange2();
    }
  }, [watch()]);

  const {
    countries: { countryItems },
  } = useSelector((state: RootState) => state);

  const {
    fields: contentFields,
    append: contentAppend,
    remove: contentRemove,
  } = useFieldArray({ control, name: 'content' });

  if (contentFields.length === 0) {
    contentAppend({
      type: '',
      quantity: null,
      description: '',
      grossWeight: null,
      volume: null,
    });
  }

  const {
    fields: chargesFields,
    append: chargesAppend,
    remove: chargesRemove,
  } = useFieldArray({ control, name: 'charges' });

  if (chargesFields.length === 0) {
    chargesAppend({
      description: '',
      collect: null,
      prepaid: null,
    });
  }

  useEffect(() => {
    setCurrentData(getValues());
    handleData();
  }, [budget]);

  const handleData = async () => {
    const data = budget;
    if (data) {
      const document = documents.find(document => document.document_types.name === DOCUMENT_TYPE_OBJ.BOOKING);

      if (!document) {
        const dataBooking = {
          expeditionDate: new Date().toLocaleDateString(),
          referenceCode: budget.budget.code || '',
          // quotation: data.budget.code,
          origin: data.budget.shipments[0].countryOrigin,
          destination: data.budget.shipments[0].countryDestiny,
          by: {
            name: data.customer.name,
            direction: data.customer.address,
            email: data.customer.email,
            phone: data.customer.phone,
          },
        };
        reset(dataBooking);
        setDocumentId(null);
      } else {
        const dataBooking: Booking = {
          reference: document.reference || '',
          expeditionDate: document.expeditionDate || '',
          agent: document.agent || '',
          phone: document.phone || '',
          quotation: document.quotation || '',
          shipline: document.loadLine || '',
          billOfLading: document.dischargePort || '',
          voyage: document.journey || '',
          via: document.via || '',
          employee: document.employee || '',
          departure: document.exit || '',
          arrival: document.arrival || '',
          comments: document.comments || '',
          hazardous: document.isDangerous || false,
          shipper: {
            name: document.forwarderPerson?.name || '',
            direction: document.forwarderPerson?.address || '',
            email: document.forwarderPerson?.email || '',
            phone: document.forwarderPerson?.phone || '',
          },
          by: {
            name: document.consigneePerson?.name || '',
            direction: document.consigneePerson?.address || '',
            email: document.consigneePerson?.email || '',
            phone: document.consigneePerson?.phone || '',
          },
          content: document.documentContent.map(content => ({
            type: content.type || '',
            quantity: content.amount || 0,
            description: content.description || '',
            grossWeight: content.grossPrice || 0,
            volume: content.volume || 0,
          })),
          charges: document.documentCharges.map(charge => ({
            description: charge.description || '',
            prepaid: Number(charge.prepaidCharges) || 0,
            collect: Number(charge.chargesReceivable) || 0,
          })),
          referenceCode: budget.budget.code || '',
          consignee: '',
          contact: document.contact || '',
          forwarder: document.forwarder || '',
          notify: document.notify || '',
          origin: data.budget.shipments[0].countryOrigin,
          destination: data.budget.shipments[0].countryDestiny,
        };
        reset(dataBooking);
        setDocumentId(document.id);
      }
    }
  };

  const onSubmit = async (data: Booking) => {
    try {
      const charges = data.charges.map(charge => ({
        description: charge.description,
        prepaidCharges: `${charge.prepaid}`,
        chargesReceivable: `${charge.collect}`,
      }));
      const content = data.content.map(content => ({
        type: content.type,
        amount: content.quantity,
        description: content.description,
        grossPrice: content.grossWeight,
        volume: content.volume,
      }));

      const dataPorte = {
        budgetId: parseInt(id || '0'),
        recipient: data.quotation,
        quotation: data.referenceCode,
        reference: data.reference,
        notify: data.notify,
        forwarder: data.forwarder,
        strategy: DOCUMENT_TYPE_OBJ.BOOKING,
        expeditionDate: data.expeditionDate,
        contact: data.contact,
        agent: data.agent,
        phone: data.phone,
        loadLine: data.shipline,
        dischargePort: data.billOfLading,
        journey: data.voyage,
        via: data.via,
        employee: data.employee,
        exit: data.departure,
        arrival: data.arrival,
        comments: data.comments,
        isDangerous: data.hazardous,
        documentContent: content,
        documentCharges: charges,
        forwarderPerson: {
          name: data.shipper.name,
          address: data.shipper.direction,
          email: data.shipper.email,
          phone: data.shipper.phone,
        },
        consigneePerson: {
          name: data.by.name,
          address: data.by.direction,
          email: data.by.email,
          phone: data.by.phone,
        },
      };

      let document: any = null;

      if (!documentId) {
        const axiosResponse = await axiosEstimates.post('/api/document', dataPorte);
        document = axiosResponse.data.data;
      } else {
        const axiosResponse = await axiosEstimates.put('/api/document', { ...dataPorte, id: documentId });
        document = axiosResponse.data.data;
      }

      if (document) {
        handleNavigatePrint(document.id, location.pathname + '&value=3');
        Swal.fire({
          icon: 'success',
          title: t('alert.success', { ns: 'common' }) || '',
          text: t('alert.supplier.success', { ns: 'common' }) || '',
          showCloseButton: true,
        });
      }
    } catch (err) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: t('alert.somethingerror', { ns: 'common' }) || '',
        showCloseButton: true,
      });
    }
  };

  return {
    t,
    control,
    countryItems,
    contentFields,
    contentAppend,
    contentRemove,
    chargesFields,
    chargesAppend,
    chargesRemove,
    // eslint-disable-next-line no-console
    onSubmit: handleSubmit(onSubmit),
    documentId,
    navigate: handleNavigatePrint,
  };
};

export default useBooking;
