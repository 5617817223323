// @Library
import { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';

// @Redux
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/reducers';
import { useAppDispatch } from '../../redux/store';
import { getBudgets } from '../../redux/slices/budgets/actions-async';
import { clearBudgets } from '../../redux/slices/budgets';

// @Utils
import { axiosEstimates } from '../../utils/api';
import { decreaseRequests, increaseRequests } from '../../redux/slices/notifications';

export function useRequestedBudgets() {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [value, setValue] = useState('');
  const [openComments, setOpenComments] = useState(false);

  // Cambiar despues
  const [idBudget, setIdBudget] = useState<number | undefined>();

  const { t } = useTranslation(['common']);

  const dispatch = useAppDispatch();

  const {
    budgets: { budgets, pagination, loading, permissions },
    user: { user, role },
  } = useSelector((state: RootState) => state);

  const handleFilter = (value: string) => {
    setValue(value);
  };

  const toggleComments = (idBudget?: number) => {
    setOpenComments(prev => !prev);
    setIdBudget(idBudget);
  };

  const changeStatus = (id: number, budgetStatus: string) => {
    Swal.fire({
      title:
        budgetStatus === 'cancel'
          ? t('general.sure', { ns: 'validations' }) || ''
          : t('budget.init', { ns: 'validations' }) || '',
      text:
        budgetStatus === 'cancel'
          ? t('delete.budget', { ns: 'validations' }) || ''
          : t('budget.initialize', { ns: 'validations' }) || '',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: `${t('general.confirm')}`,
      cancelButtonText: 'No',
    }).then(async result => {
      if (result.isConfirmed) {
        await axiosEstimates.post('budget/status/', {
          id,
          // status: 'initialized',
          status: budgetStatus,
        });
      }
      dispatch(
        getBudgets({
          page,
          perPage,
          filter: value,
          idUser: user?.id || 0,
          isSeller: ['subscriber'].includes(role),
          status: 'request',
          type: 1,
          from: '',
          to: '',
        }),
      );
      if (budgetStatus === 'cancel') dispatch(decreaseRequests());
      else dispatch(increaseRequests());
    });
  };

  useEffect(() => {
    dispatch(
      getBudgets({
        page,
        perPage,
        filter: value,
        idUser: user?.id || 0,
        isSeller: ['subscriber'].includes(role),
        status: 'request',
        type: 1,
        from: '',
        to: '',
      }),
    );

    return () => {
      dispatch(clearBudgets());
    };
  }, [page, perPage, value]);

  return {
    // NOTE: PERMISSIONS
    permissions: Object.keys(permissions),
    // NOTE: PAGINATION
    page,
    perPage,
    setPage,
    setPerPage,
    count: pagination?.count || 0,
    // NOTE: STATUS
    isLoading: loading.show,
    // NOTE: DATA
    idBudget,
    budgets,
    value,
    // NOTE: HANDLE FUNCTIONS
    handleFilter,
    toggleComments,
    openComments,
    changeStatus,
  };
}
