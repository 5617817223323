import { useTranslation } from 'react-i18next';
import { ElementType } from 'react';

// @MUI Components
import { styled } from '@mui/material/styles';
import { Card, Box, Typography, Grid } from '@mui/material/';
import MuiAvatar, { AvatarProps } from '@mui/material/Avatar';

// @Styled Avatar component
const Avatar = styled(MuiAvatar)<AvatarProps>(({ theme }) => ({
  width: 44,
  height: 44,
  boxShadow: theme.shadows[3],
  marginRight: theme.spacing(2.75),
  backgroundColor: theme.palette.background.paper,
  '& svg': {
    fontSize: '1.75rem',
  },
}));

// @Interfaces and Types
interface IProps {
  statsData: {
    title: string;
    stats: string | number;
    AvatarIcon: ElementType;
    color?: 'primary' | 'secondary' | 'error' | 'warning' | 'info' | 'success';
  };
}

/**
 * @component
 * @subcategory Global
 *
 * @description
 * A card for show stats.
 * #### Examples
 * ```
 * return(
 *  <CardStats statsData={{title:"Amount", stats: "250", AvatarIcon: <DollarIcon />, color: "success" }} />
 * )
 * ```
 *
 * @property {object} statsData Object with the stats information to show.
 * @property {string} statsData.title Title of the card
 * @property {string|number} statsData.stats Value of the card
 * @property {ElementType} statsData.AvatarIcon Icon of the card
 * @property {'primary' | 'secondary' | 'error' | 'warning' | 'info' | 'success'} [statsData.color=primary] Color of the card
 *
 * @return {JSX} Return a CardStats component whose content is a title, value, icon and color.
 * @author CML Exports Front-End Developers
 */
export const CardStats = ({ statsData }: IProps) => {
  const { title, AvatarIcon, stats, color = 'primary' } = statsData;

  const { t } = useTranslation('common');

  return (
    <Grid item sx={{ minWidth: { xs: 300, sm: 250 } }}>
      <Card
        sx={{
          p: 2,
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          boxShadow: 'none',
          border: '2px solid #dddddd',
          borderRadius: '3rem',
          backgroundColor: 'white !important',
        }}
      >
        <Avatar
          variant='rounded'
          sx={{
            color: `${color}.main`,
            boxShadow: 'none',
            borderRadius: '2rem',
            border: '2px solid #dddddd',
            background: '#F4F5FA',
          }}
        >
          <AvatarIcon />
        </Avatar>

        <Box sx={{ display: 'flex', gap: 0.5, justifyContent: 'center', flexDirection: 'column' }}>
          <Typography variant='caption' sx={{ fontSize: '0.9rem', color: '#afafaf' }}>
            {t(title)}
          </Typography>

          <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
            <Typography variant='h6' sx={{ mr: 1, fontWeight: 600, lineHeight: 1.05, fontSize: '1rem' }}>
              {stats}
            </Typography>
          </Box>
        </Box>
      </Card>
    </Grid>
  );
};
