import { Controller, Control, FieldValues, FieldPath } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

// ** Mui
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectProps } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Skeleton from '@mui/material/Skeleton';
import FormHelperText from '@mui/material/FormHelperText';

// @Interfaces and types
import { ISelectInput } from '../models';

interface IProps<T extends FieldValues> extends SelectProps {
  name: FieldPath<T>;
  control: Control<T, any>;
  label: string;
  translationFile?: 'common' | 'placeholder' | 'validations';
  items: ISelectInput<string | number | null>[];
  loading?: boolean;
  rules?: any;
  onExternalChange?: (value: any) => void;
}

/**
 * @component
 * @subcategory Global
 *
 * @description
 * The SelectInput component is a customizable, global component for selecting options from a dropdown list. It supports internationalization, loading state, and external change handling. This component is built using React, TypeScript, and Material UI, and it utilizes the react-hook-form library for form handling. It accepts various props such as name, control, label, items, rules, loading, size, onExternalChange, and disabled, and it returns a JSX.Element.
 *
 * #### Example
 * ```jsx
 * return(
 * <SelectInput
 *   name="example"
 *   control={control}
 *   label="Example"
 *   items={[
 *     { value: 'option1', label: 'Option 1' },
 *     { value: 'option2', label: 'Option 2' },
 *   ]}
 * />
 * )
 * ```
 *
 * @property {FieldPath<T>} name The name of the field.
 * @property {Control<T, any>} control The control of the form.
 * @property {string} label The label of the field.
 * @property {ISelectInput<string | number | null>[]} items The items of the select.
 * @property {any} [rules] The rules of the field.
 * @property {boolean} [loading] The loading state of the field.
 * @property {string} [size="medium"] The size of the field.
 * @property {function} [onExternalChange] The function to call when the value changes.
 * @property {boolean} [disabled] The disabled state of the field.
 * @property {IProps<T>} props Additional props.
 *
 * @return {JSX.Element} The select input component.
 * @author CML Exports Front-End Developers
 */
export default function SelectInput<T extends FieldValues>({
  name,
  control,
  label,
  items,
  rules,
  loading,
  size = 'medium',
  onExternalChange,
  disabled,
  ...props
}: IProps<T>) {
  const { t, i18n } = useTranslation(['common']);

  return (
    <>
      {!loading ? (
        <Controller
          name={name}
          control={control}
          rules={rules}
          render={({ field: { value, onChange, ...othersField }, fieldState: { error } }) => (
            <>
              <FormControl fullWidth error={Boolean(error?.message)} disabled={disabled} size={size}>
                <InputLabel>{label}</InputLabel>
                <Select
                  label={label}
                  defaultValue=''
                  value={value || ''}
                  error={Boolean(error)}
                  onChange={(value: { target: { value: unknown } }) => {
                    onChange(value);
                    if (onExternalChange) onExternalChange(value.target.value);
                  }}
                  {...othersField}
                  {...props}
                >
                  {items.map((item, index) => (
                    <MenuItem value={item.value || ''} key={index}>
                      {i18n.language === 'es' ? t(item.label) : t(item?.labelEN || item.label)}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText sx={{ color: 'error.main' }}>{t(`${error?.message || ''}`)}</FormHelperText>
              </FormControl>
            </>
          )}
        />
      ) : (
        <Skeleton variant='rectangular' animation='wave' height={0} />
      )}
    </>
  );
}
