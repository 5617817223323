import { useState } from 'react';

// @Mui
// import { DataGrid } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
// import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';

// @Icons
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import NoteEditOutline from 'mdi-material-ui/NoteEditOutline';
import DeleteOutline from 'mdi-material-ui/DeleteOutline';

// @Library
import { NumericFormat } from 'react-number-format';

// @Models
import { IShipmentEdit } from '../../../../models';

// @Utils
// import { defaultColumns } from '../utils/service-columns';
import { useTranslation } from 'react-i18next';
import ColumHeader from '../../../../components/TableColumnHeader';
import { FormatNumber } from '../../../../utils';
import { saleType } from '../../../../utils/select-items.util';

interface IProps {
  services: IShipmentEdit[];
  deleteService: (index: number) => void;
  renderForm: (service?: IShipmentEdit) => void;
  ivaTotal: number;
  total: number;
  subTotal: number;
}

interface ITable {
  rows: IShipmentEdit[];
  handleShow: (service?: IShipmentEdit) => void;
  deleteService: (index: number) => void;
}

const formatNumber = (number: number) => {
  const config = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };
  return Intl.NumberFormat('en-US', config).format(number);
};

function CollapsibleTable({ rows, deleteService, handleShow }: ITable) {
  const [open, setOpen] = useState<number | null>(null);

  const { t, i18n } = useTranslation();

  return (
    <TableContainer component={Paper} sx={{ maxHeight: 400, minHeight: 400 }}>
      <Table stickyHeader aria-label='collapsible table'>
        <TableHead sx={{ backgroundColor: 'primary.main' }}>
          <ColumHeader
            headers={[
              '',
              t('general.content', { ns: 'common' }),
              `${t('general.origin', { ns: 'common' })}/${t('general.destiny', { ns: 'common' })}`,
              t('general.subTotal', { ns: 'common' }),
              t('general.ivaTotal', { ns: 'common' }),
              t('general.total', { ns: 'common' }),
              t('general.actions', { ns: 'common' }),
            ]}
          />
        </TableHead>

        {rows.length > 0 ? (
          <TableBody>
            {rows.map(row => (
              <>
                <TableRow>
                  <TableCell>
                    {open === row.id ? (
                      <IconButton aria-label='expand row' size='small' onClick={() => setOpen(null)}>
                        <KeyboardArrowUpIcon />
                      </IconButton>
                    ) : (
                      <IconButton aria-label='expand row' size='small' onClick={() => setOpen(row.id)}>
                        <KeyboardArrowDownIcon />
                      </IconButton>
                    )}
                  </TableCell>

                  <TableCell component='th' scope='row'>
                    {row.content}
                  </TableCell>

                  <TableCell align='center'>
                    {row.origin}/{row.destiny}
                  </TableCell>

                  <TableCell align='right'>{formatNumber(row.subTotal)}</TableCell>
                  <TableCell align='right'>{formatNumber(row.ivaTotal)}</TableCell>
                  <TableCell align='right'>{formatNumber(row.total)}</TableCell>

                  {/* actions */}
                  <TableCell align='center'>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <IconButton
                        title={`${t('general.edit', { ns: 'common' })}`}
                        name='general.edit'
                        onClick={() => handleShow(row)}
                      >
                        <NoteEditOutline color='success' fontSize='inherit' />
                      </IconButton>

                      <IconButton
                        color='error'
                        title={`${t('general.delete', { ns: 'common' })}`}
                        name='delete'
                        onClick={() => deleteService(row.id)}
                      >
                        <DeleteOutline fontSize='medium' />
                      </IconButton>
                    </Box>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open === row.id} timeout='auto' unmountOnExit>
                      <Box sx={{ margin: 1 }}>
                        <Typography variant='h6' gutterBottom component='div' align='center'>
                          {t('general.service', { ns: 'common' })}
                        </Typography>

                        <Table stickyHeader aria-label='collapsible table' sx={{ ml: 5 }}>
                          <TableHead sx={{ backgroundColor: 'primary.main' }}>
                            <ColumHeader
                              headers={[
                                t('general.service', { ns: 'common' }),
                                t('general.quantity', { ns: 'common' }),
                                t('general.typeSale', { ns: 'common' }),
                                t('general.subTotal', { ns: 'common' }),
                                t('general.iva', { ns: 'common' }),
                                t('general.total', { ns: 'common' }),
                              ]}
                            />
                          </TableHead>

                          {row.services.map((service, index) => (
                            <TableBody key={index}>
                              <TableRow>
                                <TableCell align='center'>
                                  {i18n.language === 'es' ? service.serviceName : service.serviceNameEN}
                                </TableCell>
                                <TableCell align='center'>{service.serviceAmount}</TableCell>
                                <TableCell align='center'>
                                  {i18n.language === 'es'
                                    ? saleType.find(type => type.value === service.typeSale)?.label
                                    : saleType.find(type => type.value === service.typeSale)?.labelEN}
                                </TableCell>
                                <TableCell align='right'>{formatNumber(service.price)}</TableCell>
                                <TableCell align='right'>{formatNumber(service.amountOfIva)}</TableCell>
                                <TableCell align='right'>{formatNumber(service.amountOfService)}</TableCell>
                              </TableRow>
                            </TableBody>
                          ))}
                        </Table>
                      </Box>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </>
            ))}
          </TableBody>
        ) : (
          <TableBody>
            <TableRow>
              <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
                <Box
                  sx={{
                    display: 'flex',
                    textAlign: ' center',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: '#F7F7F7',
                    height: 300,
                  }}
                >
                  <Typography sx={{ textAlign: 'center' }} gutterBottom component='div'>
                    {t('general.noRows', { ns: 'validations' })}
                  </Typography>
                </Box>
              </TableCell>
            </TableRow>
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );
}

export function ServiceTable({ services, subTotal, ivaTotal, total, deleteService, renderForm }: IProps) {
  // const columns = defaultColumns(deleteService, renderForm);
  const { t } = useTranslation();

  return (
    <Box sx={{ mb: 5 }}>
      <Box
        sx={{
          p: 5,
          display: 'flex',
          flexWrap: 'wrap',
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}
      >
        <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', gap: 4 }}>
          <Button
            onClick={() => renderForm()}
            variant='contained'
            startIcon={<i className='fa-sharp fa-solid fa-plus' />}
          >
            {t('add.general', { ns: 'common' })}
          </Button>
        </Box>
      </Box>
      <Divider />

      <Box style={{ width: '100%' }}>
        <CollapsibleTable rows={services} deleteService={deleteService} handleShow={renderForm} />
      </Box>

      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Grid container spacing={1} sx={{ mt: 3 }}>
          <Grid item xs={4} sx={{ display: 'grid', justifyContent: 'center' }}>
            <InputLabel>Subtotal</InputLabel>

            <NumericFormat
              sx={{ '& input': { textAlign: 'end' } }}
              name='subTotal'
              value={FormatNumber(subTotal)}
              customInput={TextField}
              thousandSeparator
              decimalScale={2}
              disabled
            />
          </Grid>
          <Grid item xs={4} sx={{ display: 'grid', justifyContent: 'center' }}>
            <InputLabel>{t('general.iva', { ns: 'common' })}</InputLabel>

            <NumericFormat
              sx={{ '& input': { textAlign: 'end' } }}
              name='iva'
              value={FormatNumber(ivaTotal)}
              customInput={TextField}
              thousandSeparator
              decimalScale={2}
              disabled
            />
          </Grid>
          <Grid item xs={4} sx={{ display: 'grid', justifyContent: 'center' }}>
            <InputLabel>Total</InputLabel>

            <NumericFormat
              sx={{ '& input': { textAlign: 'end' } }}
              name='total'
              value={formatNumber(total)}
              customInput={TextField}
              thousandSeparator
              decimalScale={2}
              disabled
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
