import { useTranslation } from 'react-i18next';

// @MUI Components
import { Card, CardContent, CardHeader, Divider, Grid } from '@mui/material';

// @Hooks
import useSuppliers from './hooks/useSuppliers';

// @Components
import Actions from './Components/Actions';
import EmptyData from '../../components/EmptyData';
import { Notes } from './supplier-notes/notes.view';
import Pagination from '../../components/Pagination';
import SuppliersTable from './Components/SuppliersTable/SuppliersTable';
import { EditServices } from './Components/SuppliersEditServices/edits.view';

export default function Suppliers() {
  const {
    // NOTE: FILTERS
    check,
    filter,
    // NOTE: PAGINATION
    page,
    setPage,
    pageSize,
    setPageSize,
    // NOTE: DATA
    suppliersData,
    // NOTE: NOTES
    notes,
    toggleNotes,
    // NOTE: ALL ACTIONS
    changeStatus,
    // NOTE: ALL HANDLE FUNCTIONS
    handleEdit,
    handleDelete,
    handleCheck,
    handleFilter,
    openNotes,
    permissions,
    toggleEditServices,
    openEditServices,
    idEditServices,
  } = useSuppliers();

  const { t } = useTranslation('common');

  return (
    <Grid container sx={{ width: '90%', ml: 'auto', mr: { xs: 0.5, md: 'auto' }, zIndex: 1 }}>
      <Card sx={{ width: '100%' }}>
        <CardContent sx={{ margin: { xs: 1, sm: 6 } }}>
          <CardHeader title={t('title.suppliers')} sx={{ display: 'flex', textAlign: 'center' }} />

          <EditServices
            open={openEditServices}
            toggle={() => toggleEditServices(0)}
            id={idEditServices === undefined ? 0 : idEditServices}
          />

          <Divider sx={{ mb: 4, mr: 2 }} />

          <Actions
            filter={filter}
            handleFilter={handleFilter}
            check={check}
            handleCheck={handleCheck}
            canAdd={permissions.add_provider}
          />

          {suppliersData.length ? (
            <SuppliersTable
              suppliersData={suppliersData}
              page={page}
              pageSize={pageSize}
              handleEdit={handleEdit}
              onDelete={handleDelete}
              onStatus={changeStatus}
              toogleNotes={toggleNotes}
              can={permissions}
              toggleEditServices={toggleEditServices}
            />
          ) : (
            <>
              <Divider />
              <EmptyData sx={{ my: 10 }} />
            </>
          )}

          <Notes open={openNotes} toggle={toggleNotes} notes={notes} />

          <Pagination
            count={suppliersData.length}
            setPage={setPage}
            setPageSize={setPageSize}
            page={page}
            pageSize={pageSize}
          />
        </CardContent>
      </Card>
    </Grid>
  );
}
