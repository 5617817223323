import sweetalert from 'sweetalert2';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { AxiosError } from 'axios';

// @Redux
// @Utils
import { axiosEstimates } from '../../../utils/api';
import { initialIsError } from '../../../utils/initialCommonStates';

// @Services
import getServices from '../services/getServices';

// @Models
import { IGetServices } from '../../../models';

export function useService() {
  // NOTE: PERMISSIONS
  const [permissions, setPermissions] = useState<string[]>([]);
  // NOTE: STATUS
  const [isError, setIsError] = useState(initialIsError);
  const [isLoading, setIsLoading] = useState(true);
  const [tableIsLoading, setTableIsLoading] = useState(true);
  const [show, setShow] = useState(false);
  const [check, setCheck] = useState(true);
  // NOTE: FILTER
  const [filter, setFilter] = useState('');
  // NOTE: DATA
  const [services, setServices] = useState<IGetServices[]>([]);
  const [service, setService] = useState<IGetServices | undefined>();
  const [addServiceTitle, setAddServiceTitle] = useState({ name: '', ESname: '' });

  const { t } = useTranslation('common');

  // NOTE: ALL ACTIONS
  const getService = async (fromFilters?: boolean) => {
    try {
      !fromFilters ? setIsLoading(true) : setTableIsLoading(true);

      const servicesData = await getServices(filter, check);

      setServices(servicesData.services);
      setPermissions(Object.keys(servicesData.permissions));
      setAddServiceTitle(servicesData.permissions.add_service);
    } catch (err: any) {
      setIsError({
        status: err.status || 400,
        kind: err.kind || err.typeError || 'unexpected',
        title: err.error || err.title || t('general.error'),
        des: err.message || err.description || err.response.data.message || t('general.errorUnexpected'),
      });
    } finally {
      setTimeout(() => setIsLoading(false), 300);
      setTableIsLoading(false);
    }
  };

  const onStatus = (id: number, supplierStatus: 'active' | 'inactive') => {
    const data = { id, status: supplierStatus };

    axiosEstimates
      .post('services/status', data)
      .then(() => getService())
      .catch(error => console.error(error));
  };

  const deleteService = async (id: number) => {
    sweetalert
      .fire({
        icon: 'warning',
        title: `${t('general.areYouSure')}`,
        showConfirmButton: true,
        showCancelButton: true,
        cancelButtonColor: '#d33',
        confirmButtonColor: '#3085d6',
        cancelButtonText: t('general.cancel', { ns: 'common' }) || '',
        confirmButtonText: t('general.confirm', { ns: 'common' }) || '',
      })
      .then(async action => {
        if (action.isConfirmed) {
          try {
            const response = await axiosEstimates.delete(`services/${id}`);

            if (response.status === 200 || response.status === 204) {
              setServices(services => services.filter(service => service.id !== id));
            } else {
              sweetalert.fire(`${t('errors.services.deleteTitle')}`, `${t('errors.services.deleteRelation')}`, 'error');
            }
          } catch (error: any) {
            if (error instanceof AxiosError) {
              if (error.response?.status === 409) {
                sweetalert.fire(
                  `${t('errors.services.deleteTitle')}`,
                  `${t('errors.services.deleteRelation')}`,
                  'error',
                );
              }
            }
          } finally {
            setIsLoading(false);
          }
        }
      });
  };

  // NOTE: ALL HANDLE FUNCTIONS
  const handleShow = (render: boolean, service?: IGetServices) => {
    setShow(render);
    setService(service);
  };

  const handleAddService = (service: IGetServices) => {
    // setServices(services => [service, ...services]);
    getService();
    setShow(false);
  };

  const handleEditService = (editService: IGetServices) => {
    const serv = services.filter(service => service.id !== editService.id);
    setServices([editService, ...serv]);
    setShow(false);
  };

  const handleFilter = (value: string) => setFilter(value);

  const handleToggleCheck = () => setCheck(!check);

  // NOTE: ALL USE EFFECTS
  useEffect(() => {
    getService();
  }, []);

  useEffect(() => {
    getService(true);
  }, [filter, check]);

  return {
    // NOTE: PERMISSIONS
    permissions,
    // NOTE: STATUS
    isError,
    isLoading,
    tableIsLoading,
    show,
    check,
    // NOTE: FILTER
    filter,
    // NOTE: DATA
    addServiceTitle,
    services,
    service,
    // NOTE: ACTIONS
    onStatus,
    deleteService,
    // NOTE: HANDLE FUNCTIONS
    handleShow,
    handleAddService,
    handleEditService,
    handleFilter,
    handleToggleCheck,
  };
}
