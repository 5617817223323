import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

// @MUI Components
import { Box, TableRow, TableCell, IconButton, Typography } from '@mui/material';

// @MUI Icons
import DeleteOutline from 'mdi-material-ui/DeleteOutline';
import PencilOutline from 'mdi-material-ui/PencilOutline';

// @Components
import Pagination from '../../../../components/Pagination';
import TableComponent from '../../../../components/TableContainer';

// @Utils
import { formatNumber } from '../../../../utils/format-number.util';

// @Models
import { IServiceBudgetForm, IAddServiceInBudget } from '../../../../models';

interface IRowProps {
  deleteService: (index: number) => void;
  handleSelect: (row: IAddServiceInBudget, isEdit: boolean) => void;
  row: IServiceBudgetForm;
}

const Row = ({ row, deleteService, handleSelect }: IRowProps) => {
  const { t } = useTranslation(['common']);

  return (
    <TableRow>
      <TableCell>
        <Box sx={{ display: 'flex', alignItems: 'center', padding: 2 }}>
          <IconButton
            onClick={() =>
              handleSelect(
                {
                  id: row.id,
                  priceCost: row.priceCost,
                  ivaCharge: row.typeSale === 3,
                  serviceName: row.serviceName,
                  serviceNameEN: row.serviceNameEN || '',
                  type: row.type,
                  typeSale: row.typeSale,
                  serviceId: row.serviceId,
                  commission: row.commission,
                  serviceAmount: row.serviceAmount,
                  priceCostWithoutCommission: row.priceCostWithoutVat,
                  amountOfIva: row.amountOfIva,
                  amountOfService: row.amountOfService,
                  price: row.price,
                  countryTaxation: row.taxation,
                  isCrating: row.isCrating,
                },
                true,
              )
            }
            title={`${t('general.edit')}`}
          >
            <PencilOutline fontSize='medium' />
          </IconButton>

          <IconButton onClick={() => deleteService(row.id || 0)} title={`${t('general.delete')}`}>
            <DeleteOutline fontSize='medium' />
          </IconButton>
        </Box>
      </TableCell>

      <TableCell>
        <Typography noWrap variant='body2'>
          {i18n.language === 'es' ? row.serviceName : row.serviceNameEN !== null ? row.serviceNameEN : row.serviceName}
        </Typography>
      </TableCell>

      <TableCell>
        <Typography noWrap sx={{ textTransform: 'capitalize', display: 'flex', alignItems: 'center' }}>
          {row.serviceAmount}
        </Typography>
      </TableCell>

      {/* <TableCell>
        <Typography noWrap sx={{ textTransform: 'capitalize', display: 'flex', alignItems: 'center' }}>
          {formatNumber(row.priceCost)}
        </Typography>
      </TableCell> */}

      <TableCell>
        <Typography noWrap sx={{ textTransform: 'capitalize', display: 'flex', alignItems: 'center' }}>
          {formatNumber(row.price)}
        </Typography>
      </TableCell>

      <TableCell>
        <Typography noWrap sx={{ textTransform: 'capitalize', display: 'flex', alignItems: 'center' }}>
          {formatNumber(row.amountOfIva)}
        </Typography>
      </TableCell>

      <TableCell>
        <Typography noWrap sx={{ textTransform: 'capitalize', display: 'flex', alignItems: 'center' }}>
          {formatNumber(row.amountOfService)}
        </Typography>
      </TableCell>
    </TableRow>
  );
};

interface IProps {
  services: IServiceBudgetForm[];
  handleDeleteService: (index: number) => void;
  handleSelect: (row: IAddServiceInBudget, isEdit: boolean) => void;
}

export function TableServices({ services, handleDeleteService, handleSelect }: IProps) {
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);

  const { t } = useTranslation(['common']);

  return (
    <Box sx={{ width: '100%', my: 5 }}>
      <TableComponent
        Row={Row}
        data={services}
        headers={[
          t('general.actions'),
          t('services.name'),
          t('general.quantity'),
          // t('general.unitPrice'),
          'Subtotal',
          t('general.iva'),
          'Total',
        ]}
        props={{ deleteService: handleDeleteService, handleSelect }}
      />

      <Pagination count={services.length} setPage={setPage} setPageSize={setPageSize} page={page} pageSize={pageSize} />
    </Box>
  );
}
