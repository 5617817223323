import { styled } from '@mui/material/styles';
import { Radio } from '@mui/material';

export const colorsObject = {
  all: '#909090',
  active: '#56CA00',
  approved: '#56ca00',
  inactive: '#909090',
  pending: '#1681FF',
  review: '#473dc5',
  request: '#FFB400',
  cancel: '#FF4C51',
  initialized: '#FFB400',
  rejected: '#FF4C51',
  toBeConfirmed: '#9B59B6',
  toBeInvoiced: '#368db6',
  finished: '#f06b2e',
};

interface CustomStyles {
  checkedcolor: keyof typeof colorsObject;
}

export const CustomRadio = styled(Radio)(({ checkedcolor }: CustomStyles) => ({
  color: colorsObject.inactive,
  '&.Mui-checked': {
    color: colorsObject[checkedcolor] || colorsObject.inactive,
  },
}));
