import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

// @Redux
import { useAppDispatch } from '../../../redux/store';
import { getServiceTypes } from '../../../redux/slices/services/actions-async';
import { getTransportations } from '../../../redux/slices/transportation/actions-async';

// @Utils
import { axiosEstimates } from '../../../utils/api';

// @Models
import { IQuotesOfSuppliers } from '../../../models';

export default function useTabServices() {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [selectedRow, setSelectedRow] = useState(null);
  const [expDate, setExpDate] = useState(true);
  const [serviceName, setServiceName] = useState('');
  const [supplierServices, setSupplierServices] = useState<IQuotesOfSuppliers[]>([]);

  const { id: idSupplier } = useParams();

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  // NOTE: ACTIONS
  const currentSupplierServices = supplierServices.filter((r, i) => {
    return i >= pageSize * page && i < pageSize * (page + 1);
  });

  const getSupplierBudgetsServices = async () => {
    try {
      const supplierServices = await axiosEstimates.post<IQuotesOfSuppliers[]>(
        `supplier-services/${idSupplier}`,
        {
          expDate,
          serviceName,
        },
        {
          headers: { 'x-module': 'services' },
        },
      );

      setSupplierServices(supplierServices.data);
    } catch (err) {
      console.error(err);
    }
  };

  // NOTE: ALL HANDLE FUNCTIONS
  const handleNavigate = () => navigate(`/suppliers/budget/${idSupplier}`);

  const handleFilter = (value: string) => setServiceName(value);

  useEffect(() => {
    getSupplierBudgetsServices();

    dispatch(getTransportations());
    dispatch(getServiceTypes());
  }, [expDate, serviceName]);

  return {
    // NOTE: PAGINATION
    page,
    setPage,
    pageSize,
    setPageSize,
    // NOTE: STATUS
    selectedRow,
    setSelectedRow,
    // NOTE: FILTERS
    expDate,
    setExpDate,
    serviceName,
    // NOTE: DATA
    idSupplier,
    supplierServices,
    // NOTE: ACTIONS
    currentSupplierServices,
    // NOTE: HANDLE FUNCTIONS
    handleFilter,
    handleNavigate,
  };
}
