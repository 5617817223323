// @ Imports
import { forwardRef } from 'react';
import { NumericFormat, InputAttributes } from 'react-number-format';
import { Control, FieldValues, FieldPath } from 'react-hook-form';
// import { useTranslation } from 'react-i18next';

// ** MUI Imports
import { StandardTextFieldProps } from '@mui/material/TextField';

// @Components
import TextForm from './TextForm';

interface CustomProps {
  onChange: (event: number) => void;
  name: string;
  value: any;
}

interface IProps<T extends FieldValues> extends StandardTextFieldProps {
  name: FieldPath<T>;
  control: Control<T, any>;
  loading?: boolean;
  onExternalChange?: (value: unknown) => void;
}

const NumberFormatCustom = forwardRef<typeof NumericFormat<InputAttributes>, CustomProps>(function NumberFormatCustom(
  props,
  ref,
) {
  const { onChange, value, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      thousandSeparator
      value={value || ''}
      decimalScale={2}
      fixedDecimalScale
      onValueChange={values => onChange(values?.floatValue || 0)}
      // onFocus={e => {
      //   e.target.value = '';
      // }}
    />
  );
});

/**
 * @component
 * @subcategory Global
 *
 * @description
 * A formatted input component that uses react-number-format to render a numeric input field
 * with thousand separators and a fixed decimal scale.
 * #### Example
 * ```
 * import FormattedInputs from './formatted-inputs.component';
 *
 * const MyForm = () => {
 *   return (
 *     <FormattedInputs name="myInput" control={control} onChange={(value) => console.log(value)} />
 *   );
 * };
 * ```
 *
 * @property {IProps<T>} props The component props.
 *
 * @property {FieldPath<T>} props.name The input field name.
 * @property {Control<T, any>} props.control The react-hook-form control.
 * @property {boolean} [props.loading] Whether the input is loading.
 * @property {function} [props.onExternalChange] An external change handler.
 *
 * @return {JSX.Element} A formatted input field with numeric formatting.
 * @author CML Exports Front-End Developers
 */
export default function FormattedInputs<T extends FieldValues>(props: IProps<T>) {
  return (
    <TextForm
      InputProps={{ inputComponent: NumberFormatCustom as any, inputMode: 'numeric' }}
      placeholder='0.00'
      {...props}
    />
  );
}
