import { useTranslation } from 'react-i18next';

// @MUI Components
import {
  CardActions,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableFooter,
  TablePagination,
} from '@mui/material';

// @Components
import ColumHeader from '../../../../../components/TableColumnHeader';
import { useState } from 'react';
import Row from './Row';

// @Interfaces and Types
interface IProps {
  suppliersData: any;
}

export default function SuppliersHistoryTable({ suppliersData }: IProps) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const { t } = useTranslation('common');

  const currentRows = suppliersData.filter((supplier: any, i: number) => {
    return i >= rowsPerPage * page && i < rowsPerPage * (page + 1);
  });

  const handleChangePage = (e: unknown, newPage: number) => setPage(newPage);

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <TableContainer component={Paper} sx={{ maxHeight: 400, minHeight: 400 }}>
        <Table stickyHeader aria-label='collapsible table'>
          <TableHead sx={{ backgroundColor: 'primary.main' }}>
            <ColumHeader
              headers={[
                '',
                t('title.date'),
                t('general.name'),
                t('title.description'),
                t('general.status'),
                t('title.username'),
              ]}
            />
          </TableHead>

          <TableBody>
            {currentRows.map((row: { id: any }) => (
              <Row key={row.id} row={row} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <CardActions sx={{ mt: 5 }}>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 50]}
              count={suppliersData.length}
              sx={{ width: '100vw' }}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage={t('filters.rowsPerPage')}
            />
          </TableRow>
        </TableFooter>
      </CardActions>
    </>
  );
}
