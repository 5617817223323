import { useEffect } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

// @Models
import { ICountryModel, IAddServiceInBudget, ISelectInput } from '../../../../models';

// @Mui
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import CardActions from '@mui/material/CardActions';
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';

// ** Icons Imports
import Close from 'mdi-material-ui/Close';

// @Components
import { ButtonForm, NumberField, CheckboxForm } from '../../../../components';

const Header = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(3, 4),
  justifyContent: 'space-between',
  backgroundColor: theme.palette.background.default,
}));

interface IProps {
  destiny: string;
  origin: string;
  countries: ICountryModel[];
  countryItems: ISelectInput<string>[];
  service?: IAddServiceInBudget;
  handleAddService: (data: IAddServiceInBudget) => void;
  handleEditService: (data: IAddServiceInBudget) => void;
  rowId: number | null;
  role: string;
  isEdit: boolean;
  open: boolean;
  toggle: () => void;
  typeUnit: 'cmkg' | 'inlb';
  calculateCrating(): number;
  budgetType: number;
}

const defaultValues: IAddServiceInBudget = {
  // country: '',
  countryTaxation: 0,
  type: 'SE',
  ivaCharge: false,
  serviceId: null,
  serviceName: '',
  serviceNameEN: '',
  priceCost: 0,
  serviceAmount: 0,
  typeSale: 0,
  amountOfIva: 0,
  amountOfService: 0,
  price: 0,
  commission: 0,
  priceCostWithoutCommission: 0,
  isCrating: false,
};

function AddServices({
  // destiny,
  // origin,
  // countryItems,
  // countries,
  service,
  handleAddService,
  handleEditService,
  rowId,
  role,
  isEdit,
  open,
  typeUnit,
  calculateCrating,
  toggle,
  budgetType,
}: IProps) {
  const { control, setValue, getValues, reset, handleSubmit } = useForm<IAddServiceInBudget>({
    defaultValues,
  });

  const { t } = useTranslation(['common']);

  const serviceAmount = useWatch({ control, name: 'serviceAmount' });
  const serviceId = useWatch({ control, name: 'serviceId' });
  const total = useWatch({ control, name: 'amountOfService' });
  const isCrating = useWatch({ control, name: 'isCrating' });

  const calculateTotal = (priceCost: number) => {
    const ivaCharge = getValues('ivaCharge');
    const countryIva = getValues('countryTaxation') || 0;
    const isCrating = getValues('isCrating');

    const com = getValues('commission') || 16;
    let amountOfSubtotal = 0;

    if (isCrating && typeUnit === 'inlb') {
      const area = calculateCrating();
      amountOfSubtotal = priceCost * serviceAmount * area;
    } else amountOfSubtotal = priceCost * serviceAmount;

    const iva = ivaCharge ? amountOfSubtotal * (countryIva / 100) : 0;
    const total = amountOfSubtotal + iva;

    setValue('commission', parseFloat(com.toFixed(2)));
    setValue('priceCost', parseFloat(priceCost.toFixed(2)));
    setValue('price', parseFloat(amountOfSubtotal.toFixed(2)));
    setValue('amountOfIva', parseFloat(iva.toFixed(2)));
    setValue('amountOfService', parseFloat(total.toFixed(2)));
  };

  const onChangeTaxation = (value: unknown) => {
    const taxation = value as number;

    setValue('countryTaxation', taxation);
    calculateTotal(getValues('priceCost'));
  };

  const onChangePriceCost = (value: unknown) => {
    const priceCost = value as number;
    calculateTotal(priceCost);
  };

  const onChangeCom = (value: unknown) => {
    const com = value as number;
    const priceCostWithoutCommission = getValues('priceCostWithoutCommission');
    const cost = priceCostWithoutCommission * (1 + com / 100);
    calculateTotal(cost);
  };

  const onSubmit = (data: IAddServiceInBudget) => {
    if (!isEdit) handleAddService(data);
    else handleEditService(Object.assign(data, { id: service?.id }));
  };

  useEffect(() => {
    if (serviceId) {
      const cost = getValues('priceCost');
      calculateTotal(cost);
    }
  }, [serviceId, serviceAmount, isCrating]);

  useEffect(() => {
    if (service) {
      setValue('ivaCharge', service.ivaCharge);
      setValue('serviceId', service.serviceId);
      setValue('serviceName', service.serviceName);
      setValue('serviceNameEN', service.serviceNameEN);
      setValue('priceCostWithoutCommission', service.priceCostWithoutCommission);
      setValue('priceCost', service.priceCost);
      setValue('serviceAmount', service.serviceAmount);
      setValue('type', service.type);
      setValue('typeSale', service.typeSale);
      setValue('commission', service.commission);
      setValue('countryTaxation', service.countryTaxation);
      setValue('isCrating', service.isCrating);
      calculateTotal(service.priceCost);
    } else {
      reset(defaultValues);
    }
  }, [service, open]);

  return (
    <Drawer
      open={open}
      anchor='right'
      variant='temporary'
      onClose={toggle}
      ModalProps={{ keepMounted: true }}
      sx={{ '& .MuiDrawer-paper': { width: { xs: 300, sm: 400 } } }}
    >
      <Header>
        <Typography variant='h6'>{t('general.service', { ns: 'common' })}</Typography>
        <Close fontSize='small' onClick={toggle} sx={{ cursor: 'pointer' }} />
      </Header>

      <Grid container spacing={5} sx={{ my: 1, padding: '0rem 1rem' }}>
        {getValues('ivaCharge') && !['subscriber', 'agentExternal'].includes(role) && (
          <Grid item xs={12}>
            <NumberField
              control={control}
              name='countryTaxation'
              label={t('general.taxation')}
              onChange={onChangeTaxation}
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <NumberField control={control} name='serviceAmount' label={t('general.quantity')} />
        </Grid>

        {['admin'].includes(role) && (
          <>
            <Grid item xs={12}>
              <NumberField control={control} name='priceCostWithoutCommission' label={t('general.cost')} disabled />
            </Grid>

            {getValues('type') === 'SE' && (
              <Grid item xs={12}>
                <NumberField
                  control={control}
                  name='commission'
                  label={t('general.commission')}
                  onExternalChange={onChangeCom}
                />
              </Grid>
            )}
          </>
        )}

        {['admin'].includes(role) && (
          <Grid item xs={typeUnit === 'inlb' ? 7 : 12}>
            <NumberField
              control={control}
              name='priceCost'
              label={t('general.priceWithCommission', { ns: 'common' })}
              onExternalChange={onChangePriceCost}
              disabled={['subscriber'].includes(role)}
            />
          </Grid>
        )}

        {typeUnit === 'inlb' && budgetType === 2 && (
          <Grid item xs={5} sx={{ margin: 'auto 0' }}>
            <CheckboxForm control={control} name='isCrating' label='isCrating' size='medium' />
          </Grid>
        )}

        <Grid item xs={12}>
          <NumberField control={control} name='price' label={t('general.amountOfSubtotal')} disabled />
        </Grid>

        <Grid item xs={12}>
          <NumberField control={control} name='amountOfIva' label={t('general.iva')} disabled />
        </Grid>

        <Grid item xs={12}>
          <NumberField control={control} name='amountOfService' label='Total' disabled />

          <Typography variant='body1' sx={{ fontSize: '12px', marginTop: '2px', color: 'red' }}>
            {t('general.changeSupplier')}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <CardActions sx={{ justifyContent: 'end' }}>
            <ButtonForm
              label={t('general.continue', { ns: 'common' })}
              onClick={handleSubmit(onSubmit)}
              disabled={Boolean(!rowId) || total <= 0}
            />
          </CardActions>
        </Grid>
      </Grid>
    </Drawer>
  );
}

export default AddServices;
