import { Control, FieldValues, FieldPath } from 'react-hook-form';

// @MUI Components
import { StandardTextFieldProps } from '@mui/material/TextField';

// @Components
import NumberFormatCustom from './NumericFormat';
import TextForm from './TextForm';

// @Interfaces and Types
interface IProps<T extends FieldValues> extends StandardTextFieldProps {
  name: FieldPath<T>;
  control: Control<T, any>;
  loading?: boolean;
  onExternalChange?: (value: unknown) => void;
}

/**
 * @component
 * @subcategory Global
 *
 * @description
 * A component that formats inputs based on the provided props.
 * #### Example
 * ```
 * return(
 * <FormattedInputs name="example" control={control} loading={loading} onExternalChange={(value) => console.log(value)} />
 * )
 * ```
 *
 * @property {FieldPath<T>} name The name of the input field.
 * @property {Control<T, any>} control The control object from react-hook-form.
 * @property {boolean} [loading] A boolean indicating whether the input is loading.
 * @property {function} [onExternalChange] A callback function to handle external changes.
 *
 * @return {JSX.Element}
 * @author CML Exports Front-End Developers
 */
export default function FormattedInputs<T extends FieldValues>(props: IProps<T>) {
  return (
    <TextForm
      InputProps={{
        inputComponent: NumberFormatCustom as any,
        inputMode: 'numeric',
      }}
      placeholder='0'
      {...props}
    />
  );
}
