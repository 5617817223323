import { styled } from '@mui/material';
import MuiSwipeableDrawer, { SwipeableDrawerProps } from '@mui/material/SwipeableDrawer';

export const CustomSwipeableDrawer = styled(MuiSwipeableDrawer)<SwipeableDrawerProps>({
  overflowX: 'hidden',
  transition: 'width .25s ease-in-out',
  position: 'absolute',
  '& ul': {
    listStyle: 'none',
  },
  '& .MuiListItem-gutters': {
    paddingLeft: 4,
    paddingRight: 4,
  },
  '& .MuiDrawer-paper': {
    left: 'unset',
    right: 'unset',
    overflowX: 'hidden',
    transition: 'width .25s ease-in-out, box-shadow .25s ease-in-out',
  },
  '.layout-vertical-nav &': {
    '& .ps__thumb-y': {
      width: 4,
      // backgroundColor: perfectScrollbarThumbBgColor(),
    },
    '&:hover, &:focus, &.ps--clicking': {
      backgroundColor: 'transparent !important',
      '& .ps__thumb-y': {
        width: 6,
      },
    },
  },
});
