import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// @MUI Components
import { Grid, Box, Button, FormControlLabel, Switch } from '@mui/material';

// @Icons
import AddIcon from '@mui/icons-material/Add';

// @Components
import { SearchField } from '../../../components';
import i18n from '../../../i18n';

// @Interfaces and Types
interface IProps {
  filter: string;
  handleFilter: (value: string) => void;
  check: boolean;
  handleCheck: () => void;
  canAdd: any;
}

export default function Actions({ filter, handleFilter, check, handleCheck, canAdd = false }: IProps) {
  const { t } = useTranslation('common');

  const navigate = useNavigate();

  return (
    <Grid
      container
      sx={{
        mb: 5,
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexDirection: { xs: 'column', md: 'row' },
        rowGap: 4,
      }}
    >
      <Grid
        item
        xs
        md={8}
        lg={6}
        sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: 3, ml: -1 }}
      >
        <Box sx={{ width: { sx: '100%', lg: '75%', xl: '65%' } }}>
          <SearchField value={filter} handleFilter={handleFilter} placeholder={t('general.search', { ns: 'common' })} />
        </Box>

        <FormControlLabel
          control={<Switch checked={check} onChange={handleCheck} defaultChecked />}
          label={t('general.status', { ns: 'common' })}
        />
      </Grid>

      {Boolean(canAdd) && (
        <Grid item xs sx={{ width: '100%', display: 'flex', justifyContent: { xs: 'center', md: 'flex-end' }, pr: 2 }}>
          <Button size='large' variant='contained' onClick={() => navigate('/suppliers/create')}>
            <AddIcon />
            {i18n.language === 'es' ? canAdd.ESname : canAdd.name}
          </Button>
        </Grid>
      )}
    </Grid>
  );
}
