import { ReactNode, useEffect, useState } from 'react';

// @MUI Components
import { Box } from '@mui/material/';

// @Interfaces
interface TabPanelProps {
  children?: ReactNode;
  index: number;
  value: number;
}

export default function TabPanel({ children, value, index }: TabPanelProps) {
  const [show, setShow] = useState<boolean>(value === index);
  const [changing, setChanging] = useState<boolean>(false);

  useEffect(() => {
    setChanging(true);

    setTimeout(() => setShow(value === index), 200);
  }, [value]);

  useEffect(() => {
    if (show) setChanging(false);
  }, [show]);

  return (
    <Box
      role='tabpanel'
      hidden={!show}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      sx={{ m: 3, opacity: changing ? 0 : 1, transition: '250ms ease opacity' }}
    >
      {children}
    </Box>
  );
}
