// @Library
import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import toast from 'react-hot-toast';
import * as yup from 'yup';

// @Redux
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../redux/store';
// import { nextStep } from '../../../redux/slices/budget-form';
import { RootState } from '../../../redux/reducers';
import { postBudget } from '../../../redux/slices/budget-form/actions-async';

// @Models
import { IShipmentService, IShipmentRequestForm, IShipmentEdit } from '../../../models';

// @Utils
// import { schemaValidation } from '../service-form/utils/schema-validation';
import { sendBudgetAdapter } from '../service-step/utils/send-budget.adapter';
import { shipmentAdapter } from './utils/shipment.adapter';

const initialValues: IShipmentRequestForm = {
  addressDestiny: '',
  addressOrigin: '',
  taxationTo: '',
  origin: '',
  destiny: '',
};
interface IParams {
  shipments?: IShipmentEdit[];
  isUpdate?: boolean;
  handleEdit?: (shipmets: IShipmentEdit[]) => void;
}

export const schemaValidation = yup
  .object()
  .shape({
    origin: yup.string().required('errors.required'),
    destiny: yup.string().required('errors.required'),
    addressOrigin: yup.string().required('errors.required').max(250, 'errors.maxLength'),
    addressDestiny: yup.string().required('errors.required').max(250, 'errors.maxLength'),
  })
  .required();

export function useShipmentForm({ shipments, isUpdate, handleEdit }: IParams) {
  const {
    budgetForm: { sellerForm, customerForm, status, type, sellers },
    countries: { countryItems, conutryItemsES },
    user: { user },
  } = useSelector((state: RootState) => state);
  const dispatch = useAppDispatch();
  const [pickups, setPickups] = useState<IShipmentService[]>([]);
  const [pageSize, setPageSize] = useState<number>(10);
  const [open, setOpen] = useState<boolean>(false);
  const [pickup, setPickup] = useState<IShipmentService | undefined>();

  const { control, handleSubmit, setValue } = useForm<IShipmentRequestForm>({
    defaultValues: initialValues,
    resolver: yupResolver(schemaValidation),
  });

  useEffect(() => {
    if (shipments && isUpdate) {
      const shipment = shipments[0];

      setValue('origin', shipment?.origin || '');
      setValue('destiny', shipment?.destiny || '');
      setValue('addressOrigin', shipment?.addressOrigin || '');
      setValue('addressDestiny', shipment?.addressDestiny || '');

      const pickups: IShipmentService[] = shipments.map((shipment, index) => ({
        id: index + 1,
        shipmentId: shipment.shipmentId,
        area: shipment.area,
        content: shipment.content,
        insurance: shipment.insurance,
        high: shipment.high,
        physicalWeight: shipment.physicalWeight,
        length: shipment.length,
        unitOfMeasurement: shipment.unitOfMeasurement,
        volumetricWeight: shipment.volumetricWeight,
        width: shipment.width,
        comments: shipment.comments,
        serial: shipment.serial,
        idServiceType: shipment.idServiceType,
        idTransportation: shipment.idTransportation,
      }));
      setPickups(pickups);
    }
  }, []);

  const handleAddPickup = (newPickup: Omit<IShipmentService, 'id'>) => {
    setPickups([...pickups, { id: pickups.length + 1, shipmentId: null, ...newPickup }]);
    setOpen(false);
  };

  const handleEditPickup = (editPickup: IShipmentService) => {
    const newPickups = pickups.map(pickup => {
      if (pickup.id === editPickup.id) {
        return editPickup;
      }
      return pickup;
    });

    setPickups(newPickups);
    setOpen(false);
  };

  const handleRemovePickup = (id: number) => {
    const newPickups = pickups.filter(pickup => pickup.id !== id);
    setPickups(newPickups);
  };

  const toggle = (value?: IShipmentService) => {
    setOpen(!open);
    setPickup(value);
  };

  const onSubmit = (data: IShipmentRequestForm) => {
    if (!user) return null;

    if (pickups.length === 0) {
      toast.error('You must enter a shipment');
      return null;
    }

    if (shipments && isUpdate) {
      const adapterShipments: IShipmentEdit[] = pickups.map(pickup => ({
        ...data,
        ...pickup,
        shipmentId: pickup.shipmentId || null,
        incoterms: '',
        ivaTotal: 0,
        subTotal: 0,
        total: 0,
        services: [],
      }));
      if (handleEdit) handleEdit(adapterShipments);
    } else {
      const shipments = shipmentAdapter({ shipment: data, pickups });
      const adaptedValues = sendBudgetAdapter({
        type,
        seller: sellerForm,
        customer: customerForm,
        shipments,
        amountOfSubtotal: 0,
        amountOfIva: 0,
        amountOfTotal: 0,
        userId: user.id,
        status,
      });
      if (adaptedValues) dispatch(postBudget(adaptedValues));
    }
  };

  return {
    control,
    onSubmit: handleSubmit(onSubmit),
    countryItems,
    setValue,
    pickups,
    pageSize,
    setPageSize,
    open,
    toggle,
    handleAddPickup,
    handleEditPickup,
    handleRemovePickup,
    pickup,
    conutryItemsES,
  };
}
