import toast from 'react-hot-toast';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

// @MUI Components
import { styled } from '@mui/material/styles';
import { Box, List, Typography, Button, ListItem, IconButton, SxProps } from '@mui/material/';

// @Icons
import Close from 'mdi-material-ui/Close';
import FileDocumentOutline from 'mdi-material-ui/FileDocumentOutline';

// @Components
import DeleteOutline from 'mdi-material-ui/DeleteOutline';
import { DefaultTFuncReturn } from 'i18next';

// Styled component for the upload image inside the dropzone area
const Img = styled('img')(({ theme }) => ({
  [theme.breakpoints.up('md')]: { marginRight: theme.spacing(15.75) },
  [theme.breakpoints.down('md')]: { marginBottom: theme.spacing(4) },
  [theme.breakpoints.down('sm')]: { width: 160 },
}));

// @Interfaces and Type
export interface IProps {
  files: File[];
  setFiles: React.Dispatch<React.SetStateAction<File[]>>;
  allowedText?: DefaultTFuncReturn | string;
  sx?: SxProps;
}

type FileProp = {
  name: string;
  type: string;
  size: number;
};

/**
 * @component
 * @subcategory Global
 *
 * @description
 * A component that allows users to upload files with restrictions.
 * #### Example
 * ```
 * return(
 *  <FileUploaderRestrictions files={files} setFiles={setFiles} allowedText="Allowed file types:.jpg,.png,.pdf" />
 * )
 * ```
 *
 * @property {File[]} files An array of files that have been uploaded.
 * @property {React.Dispatch<React.SetStateAction<File[]>>} setFiles A callback that will set the uploaded files on the parent component.
 * @property {DefaultTFuncReturn | string} [allowedText] A string that describes the allowed file types.
 * @property {SxProps} [sx] Additional sx props.
 *
 * @return {JSX.Element}
 * @author CML Exports Front-End Developers
 */
const FileUploaderRestrictions = ({ files, setFiles, allowedText, sx = {} }: IProps) => {
  const { t } = useTranslation('common');

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    maxSize: 2000000,
    // accept: 'image/*',
    onDrop: (acceptedFiles: File[]) => {
      setFiles(prevFiles => [...prevFiles, ...acceptedFiles.map((file: File) => Object.assign(file))]);
    },
    onDropRejected: () => {
      toast.error(t('fileuploader.alertMax', { ns: 'validations' }), {
        duration: 2000,
      });
    },
  });

  const handleRemoveFile = (file: FileProp) => {
    const uploadedFiles = files;
    const filtered = uploadedFiles.filter((i: FileProp) => i.name !== file.name);
    setFiles([...filtered]);
  };

  const handleRemoveAllFiles = () => setFiles([]);

  if (!files.length) {
    return (
      <Box
        htmlFor='input-file'
        component={'label'}
        {...getRootProps({ className: 'dropzone' })}
        sx={{
          py: 1,
          px: 4,
          display: 'flex',
          alignItems: 'center',
          flexDirection: ['column', 'column', 'row'],
          cursor: 'pointer',
          overflow: 'hidden',
          border: '2.5px dashed',
          borderRadius: '2px',
          borderColor: ({ palette }) => palette.primary.main,
          transition: '300ms ease',
          transitionProperty: 'transform background',
          ':hover': { background: '#70707015', transform: 'scale(1.01)' },
          ...sx,
        }}
      >
        <Box sx={{ mx: 'auto', width: '100%' }}>
          <Img alt='Upload img' src='/assets/upload.png' />
        </Box>

        <input hidden id='input-file' type='file' {...getInputProps()} />

        <Box sx={{ display: 'flex', flexDirection: 'column', textAlign: ['center', 'center', 'inherit'] }}>
          <Typography variant='h6' sx={{ marginBottom: { xs: 4, sm: 5 } }}>
            {t('import.title')}
          </Typography>

          <Typography color='textSecondary'>
            {allowedText || t('fileuploader.allowed', { ns: 'validations' })}
          </Typography>
        </Box>
      </Box>
    );
  }

  return (
    <Box sx={{ width: '100%' }}>
      <input type='file' {...getInputProps()} />

      <List>
        {files.map((file: FileProp) => (
          <ListItem key={file.name}>
            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', overflow: 'hidden' }}>
              {file.type.startsWith('image') ? (
                <img width={38} height={38} alt={file.name} src={URL.createObjectURL(file as any)} />
              ) : (
                <FileDocumentOutline />
              )}

              <Box sx={{ width: '100%' }}>
                <Typography sx={{ ml: 1, width: '80%', wordWrap: 'break-word' }}>{file.name}</Typography>

                <Typography sx={{ ml: 1 }} variant='body2'>
                  {Math.round(file.size / 100) / 10 > 1000
                    ? `${(Math.round(file.size / 100) / 10000).toFixed(1)} mb`
                    : `${(Math.round(file.size / 100) / 10).toFixed(1)} kb`}
                </Typography>
              </Box>
            </Box>

            <IconButton onClick={() => handleRemoveFile(file)}>
              <Close fontSize='small' />
            </IconButton>
          </ListItem>
        ))}
      </List>

      <Box className='buttons' sx={{ width: '100%', mx: 'auto', display: 'flex', justifyContent: 'center' }}>
        {/* Delete image  */}
        <Button size='small' color='error' variant='outlined' onClick={handleRemoveAllFiles}>
          <DeleteOutline fontSize='medium' />
        </Button>
      </Box>
    </Box>
  );
};

export default FileUploaderRestrictions;
