// @Components
import Dialog from '../../../components/Dialog';
// @Mui
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

interface IProps {
  open: boolean;
  toggle: () => void;
  notes?: string;
}

export function Notes({ open, toggle, notes }: IProps): JSX.Element {
  return (
    <Dialog open={open} handleClose={() => toggle()} dialogTitle={'general.notes'}>
      <CardContent sx={{ height: 150 }}>
        <Typography variant='body1'>{notes}</Typography>
      </CardContent>
    </Dialog>
  );
}
