import KeyboardBackspace from '@mui/icons-material/KeyboardBackspace';
import { IconButton } from '@mui/material';

const back = () => {
  window.history.back();
};

/**
 * @component
 * @subcategory Global
 *
 * @description
 * A GoBackButton component that displays a back button.
 * #### Example
 * ```jsx
 * import GoBackButton from './GoBackButton';
 *
 * return(
 *  <GoBackButton />;
 * )
 * ```
 * @return {JSX.Element} A GoBackButton component.
 * @author CML Exports Front-End Developers
 */
export const GoBackButton = () => {
  return (
    <IconButton sx={{ display: 'flex', mt: 1 }} color='error' onClick={back}>
      <KeyboardBackspace />
    </IconButton>
  );
};
