import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// @MUI Components
import {
  Box,
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Chip,
  IconButton,
} from '@mui/material';

// @Icons
import { Icon } from '@iconify/react';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CloseCircleOutline from 'mdi-material-ui/CloseCircleOutline';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

// @Utils
import { formatNumber } from '../../../utils/format-number.util';

// @Components
import ColumHeader from '../../../components/TableColumnHeader';

// @Models
import { IExpense, IDocument } from '../../../models/expenses/expenses-form.model';
import { CircleEditOutline } from 'mdi-material-ui';

interface ISubRow {
  row: IDocument;
  changeStatus: (id: number, status: number) => void;
  expensesHasDocuments: (id: number) => boolean;
  canEdit?: boolean;
  canAprove?: boolean;
  canCancel?: boolean;
}

interface chipItem {
  value: string | number;
  label: string;
  color: 'default' | 'success' | 'info' | 'error' | 'primary' | 'secondary' | 'warning';
}

export default function SubRow({ row, changeStatus, expensesHasDocuments, canEdit, canAprove, canCancel }: ISubRow) {
  const [open, setOpen] = useState<boolean>(false);

  const { t } = useTranslation();

  const navigate = useNavigate();

  const statusItem: chipItem[] = [
    { value: 1, color: 'info', label: `${t('general.pending', { ns: 'common' })}` }, // Pendiente
    { value: 2, color: 'error', label: `${t('general.cancelled', { ns: 'common' })}` }, // Cancelado
    { value: 3, color: 'success', label: `${t('general.approved', { ns: 'common' })}` }, // Aprobado
    { value: 4, color: 'info', label: `${t('general.paid', { ns: 'common' })}` }, // Pagado
  ];

  const status = (document: { status: number }) => statusItem.find(status => status.value === document.status);

  const subTotal = (expenses: IExpense[]) => expenses.reduce((prev, sum) => prev + sum.subTotal, 0);

  const isActionsDisabled = () => {
    // if (role === 'admin' || role === 'editor') return false;
    // else if (row.idUserExports === user?.id) return false;
    // else return true;
    if (row.status === 2 || row.status === 3) return true;
    else return false;
  };

  return (
    <TableCell style={{ borderTop: '1.5px solid #ccc', width: '100%' }} colSpan={5}>
      <Typography variant='h6' gutterBottom component='div' sx={{ ml: '1rem' }}>
        {t('general.documentTitle', { ns: 'common' })}
      </Typography>

      <Box sx={{ margin: '0 1rem 1rem 1rem', border: 'thin solid #ccc' }}>
        <Table stickyHeader size='small' aria-label='purchases'>
          <TableHead sx={{ backgroundColor: 'primary.main' }}>
            <ColumHeader
              headers={[
                '',
                t('title.quotation', { ns: 'common' }),
                t('general.supplier', { ns: 'common' }),
                t('general.subTotal', { ns: 'common' }),
                t('expenses.accountalien', { ns: 'common' }),
                t('general.status', { ns: 'common' }),
                t('general.actions', { ns: 'common' }),
              ]}
            />
          </TableHead>

          <TableBody>
            <TableRow>
              <TableCell>
                <IconButton aria-label='expand row' size='small' onClick={() => setOpen(!open)}>
                  {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              </TableCell>

              <TableCell align='center'>{row.code}</TableCell>

              <TableCell align='center' component='th' scope='row' className='texto-cortado'>
                {row.supplier.name}
              </TableCell>

              <TableCell align='center' component='th' scope='row'>
                {formatNumber(subTotal(row.expenses))}
              </TableCell>

              <TableCell align='center'>{row.otherCharges ? 'Si' : 'No'}</TableCell>

              <TableCell align='center'>
                <Chip
                  size='medium'
                  label={status(row)?.label || ''}
                  color={status(row)?.color}
                  sx={{ textTransform: 'capitalize', p: '0.5rem', m: '0 0.5rem' }}
                />
              </TableCell>

              <TableCell align='center'>
                {canCancel && row.status === 1 && (
                  <IconButton name='general.cancel' title={`${t('general.cancel', { ns: 'common' })}`}>
                    <CloseCircleOutline fontSize='inherit' color='error' onClick={() => changeStatus(row.id, 2)} />
                  </IconButton>
                )}

                {canAprove && row.status === 1 && (
                  <IconButton name='general.edit' title={`${t('general.approve', { ns: 'common' })}`}>
                    <CheckCircleOutlineIcon
                      fontSize='inherit'
                      color='success'
                      onClick={() => changeStatus(row.id, 3)}
                    />
                  </IconButton>
                )}

                {canEdit && !isActionsDisabled() && (
                  <IconButton color='primary' onClick={() => navigate('/expenses/edit/' + row.id)}>
                    <CircleEditOutline />
                  </IconButton>
                )}

                {!isActionsDisabled() && expensesHasDocuments(row.id) && (
                  <IconButton color='warning' onClick={() => navigate('/filesView/' + row.id + '?model=expenses')}>
                    <Icon icon='mi:document' width='22' />
                  </IconButton>
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Box>

      {/* <TableCell sx={{ p: '0', pl: '4rem', width: '100%' }}  colSpan={5}> */}
      <Box sx={{ p: '0', pl: '4rem', width: '95%' }}>
        <Collapse in={open} timeout='auto' unmountOnExit>
          <Box sx={{ width: '100%' }}>
            <Typography variant='h6' gutterBottom component='div'>
              {t('title.expensesDetails', { ns: 'common' })}
            </Typography>

            <Table stickyHeader size='small' aria-label='purchases' sx={{ margin: '0', border: 'thin solid #ccc' }}>
              <TableHead sx={{ backgroundColor: 'primary.main' }}>
                <ColumHeader
                  headers={[
                    t('title.description', { ns: 'common' }),
                    t('general.quantity', { ns: 'common' }),
                    // t('general.unitPrice', { ns: 'common' }),
                    t('general.subTotal', { ns: 'common' }),
                    t('general.iva', { ns: 'common' }),
                    t('general.withholdings', { ns: 'common' }),
                    t('general.total', { ns: 'common' }),
                  ]}
                />
              </TableHead>

              <TableBody>
                {row.expenses.map((expense, index) => (
                  <TableRow key={index}>
                    <TableCell align='center'> {expense.description}</TableCell>

                    <TableCell align='center' component='th' scope='row'>
                      {expense.quantity}
                    </TableCell>

                    {/* <TableCell align='center' component='th' scope='row'>
                      {formatNumber(expense.unitPrice)}
                    </TableCell> */}

                    <TableCell align='center'>{formatNumber(expense.subTotal)}</TableCell>

                    <TableCell align='center'>{formatNumber(expense.iva)}</TableCell>

                    <TableCell align='center'>{formatNumber(expense.isrRet + expense.ivaRet)}</TableCell>

                    <TableCell align='center'>{formatNumber(expense.total)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </Collapse>
      </Box>
    </TableCell>
  );
}
