// @Library
import { AsyncThunkPayloadCreator, createAsyncThunk } from '@reduxjs/toolkit';

// @Utils
import { axiosSystem, axiosCRM, axiosEstimates } from '../../../utils/api';

// @Models
import { IUserProps, ICustomerRequest, ICreateBudget, IPostBudgetResponse } from '../../../models';

// !--- INIT HANDLER ---!
const handleGetSellers: AsyncThunkPayloadCreator<IUserProps[], undefined, {}> = async (
  payload,
  { rejectWithValue },
) => {
  try {
    const response = await axiosSystem.get<IUserProps[]>('users/resellers?strategy=onlyseller', payload);

    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
};

const handleGetCustomers: AsyncThunkPayloadCreator<ICustomerRequest, { email: string }, {}> = async (
  payload,
  { rejectWithValue },
) => {
  try {
    const response = await axiosCRM.post<ICustomerRequest>('estimates/', { 'e-mail': payload.email });

    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
};

const handleGetCompanyByRol: AsyncThunkPayloadCreator<ICustomerRequest, { email: string }, {}> = async (
  payload,
  { rejectWithValue },
) => {
  try {
    const response = await axiosEstimates.get<ICustomerRequest>('customers/by-user');
    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
};

const handlePostBudget: AsyncThunkPayloadCreator<any, ICreateBudget, {}> = async (payload, { rejectWithValue }) => {
  try {
    const response = await axiosEstimates.post<IPostBudgetResponse>('budget', payload, {});

    if (response.data.data.budget.status === 'pending') {
      const idBudget = response.data.data.budget.id;
      await axiosEstimates.post('send-admin/' + idBudget, {});
    }

    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
};
// !--- END HANDLER ---!

// !--- INIT THUNKS ---!
export const getSellers = createAsyncThunk('users/getSellers', handleGetSellers);

export const getCustomers = createAsyncThunk('users/getCustomers', handleGetCustomers);
export const getCompanyByRol = createAsyncThunk('users/getCompanyByRol', handleGetCompanyByRol);

export const postBudget = createAsyncThunk('budget/postBudget', handlePostBudget);

// !--- END THUNKS ---!
