import { useTranslation } from 'react-i18next';

// @MUI Components
import {
  Card,
  CardContent,
  FormControl,
  FormLabel,
  Typography,
  RadioGroup,
  Box,
  FormControlLabel,
  InputLabel,
  Select,
  useMediaQuery,
  useTheme,
  MenuItem,
} from '@mui/material';

// @Components
import { CustomRadio } from '../../../components/StyledComponents/RadioStyled';

// @Utils
import statusItems from '../utils/statusItems';

// @Interfaces and Types
interface IProps {
  status: string;
  handleStatus: (value: string) => void;
}

/**
 * @component
 * @category Budget
 *
 * @description
 * This component is responsible for rendering the status options, which includes a dropdown list or radio buttons based on the screen size.
 *
 * #### Example
 * ```tsx
 * return(
 * <StatusOptions
 *   status={'active'}
 *   handleStatus={(value: string) => console.log(value)}
 * />
 * )
 * ```
 *
 * @property {string} status The current status of the item.
 * @property {function} handleStatus The function to handle the status change.
 *
 * @return {JSX.Element} The StatusOptions component.
 * @author CML Exports Front-End Developers
 */
export default function StatusOptions({ status, handleStatus }: IProps) {
  const { t } = useTranslation('common');

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Card sx={{ width: '100%', mb: 3, paddingX: 5 }}>
      <CardContent>
        <FormControl>
          {matches ? (
            <>
              <InputLabel>{t('general.status')}</InputLabel>

              <Select
                fullWidth
                value={status}
                label={t('general.status')}
                onChange={(e: { target: { value: string } }) => handleStatus(e.target.value)}
                sx={{ width: 300 }}
              >
                {statusItems.map(
                  item =>
                    !['review', 'request', 'toBeInvoiced'].includes(item.value) && (
                      <MenuItem key={item.value} value={item.value}>
                        {t(item.label)}
                      </MenuItem>
                    ),
                )}
              </Select>
            </>
          ) : (
            <>
              <FormLabel>
                <Typography> {t('general.status')} </Typography>
              </FormLabel>

              <RadioGroup
                row
                value={status}
                defaultValue='all'
                name='row-radio-buttons-group'
                aria-labelledby='demo-row-radio-buttons-group-label'
                onChange={e => handleStatus(e.target.value)}
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'flex-start',
                  justifyContent: 'flex-start',
                  flexWrap: 'wrap',
                  gap: 1,
                }}
              >
                {statusItems.map(
                  (status, i) =>
                    !['review', 'request', 'toBeInvoiced'].includes(status.value) && (
                      <Box key={i} sx={{ display: 'flex', alignItems: 'center', gap: 0 }}>
                        <FormControlLabel
                          value={status.value}
                          control={<CustomRadio checkedcolor={status.value} />}
                          label={t(status.label)}
                          sx={{ padding: 0, margin: 0 }}
                        />
                      </Box>
                    ),
                )}
              </RadioGroup>
            </>
          )}
        </FormControl>
      </CardContent>
    </Card>
  );
}
