import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useState, Fragment, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/reducers';
import { logout, logginCRM } from '../../redux/slices/user';
import { clearNotifications } from '../../redux/slices/notifications';

// ** MUI Imports
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { Icon } from '@iconify/react';

// ** Icons Imports
import LogoutVariant from 'mdi-material-ui/LogoutVariant';

// ** Utils
import { axiosEstimates } from '../../utils';

// ** Styled Components
const BadgeContentSpan = styled('span')(({ theme }) => ({
  width: 8,
  height: 8,
  borderRadius: '50%',
  backgroundColor: theme.palette.success.main,
  boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
}));

/**
 * @component
 * @subcategory Global
 *
 * @description
 * A dropdown component for user profile management, providing a dropdown menu with options for logging out, viewing design options, and more.
 * #### Example
 * ```tsx
 * return(
 *  <UserDropdown />
 * )
 * ```
 *
 * @return {JSX.Element} The user dropdown component.
 * @author CML Exports Front-End Developers
 */
const UserDropdown = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [permissions, setPermissions] = useState<string[]>([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { t } = useTranslation(['common']);

  const { user, loginCRM, idCRM } = useSelector((state: RootState) => state.user);

  const handleDropdownOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDropdownClose = () => setAnchorEl(null);

  const handleLogout = () => {
    dispatch(logout());
    dispatch(clearNotifications());
    window.location.href = process.env.REACT_APP_AUTH_FRONT || '';
  };

  const handleBack = () => {
    window.location.href = process.env.REACT_APP_AUTH_FRONT || '';
    dispatch(logginCRM({ loginCRM: false }));
  };

  const handleModules = useCallback(async () => {
    const res = await axiosEstimates.get('api/userData/navigation');
    setPermissions(res.data);
  }, []);

  useEffect(() => {
    if (idCRM) handleModules();
  }, [handleModules, idCRM]);

  return (
    <Fragment>
      <Badge
        overlap='circular'
        onClick={handleDropdownOpen}
        sx={{ ml: 2, cursor: 'pointer' }}
        badgeContent={<BadgeContentSpan />}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Avatar
          alt='John Doe'
          onClick={handleDropdownOpen}
          sx={{ width: 40, height: 40 }}
          src='/images/avatars/1.png'
        />
      </Badge>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => handleDropdownClose()}
        sx={{ '& .MuiMenu-paper': { width: 230, marginTop: 2 } }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Box sx={{ pt: 1, pb: 2, px: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Badge
              overlap='circular'
              badgeContent={<BadgeContentSpan />}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
              <Avatar src='/images/avatars/1.png' sx={{ width: '2.5rem', height: '2.5rem' }} />
            </Badge>

            <Box sx={{ display: 'flex', marginLeft: 3, alignItems: 'flex-start', flexDirection: 'column' }}>
              <Typography sx={{ width: '9.5rem', fontWeight: 600 }} noWrap>
                {user?.person?.name}
              </Typography>
              <Typography variant='body2' sx={{ fontSize: '0.8rem', color: 'text.disabled' }}>
                {user?.email}
              </Typography>
            </Box>
          </Box>
        </Box>

        {permissions.includes('design_options') && (
          <MenuItem sx={{ py: 2 }} onClick={() => navigate('/pdfSettings')}>
            <Icon icon='material-symbols:settings-outline' width='20' />
            <Typography sx={{ ml: 2 }}>{t('general.designOptions')}</Typography>
          </MenuItem>
        )}

        {permissions.includes('tax_folios') && (
          <MenuItem sx={{ py: 2 }} onClick={() => navigate('/invoiceSettings')}>
            <Icon icon='material-symbols:settings-outline' width='20' />
            <Typography sx={{ ml: 2 }}>{t('quoteSetting.title')}</Typography>
          </MenuItem>
        )}

        <MenuItem sx={{ py: 2 }} onClick={handleLogout}>
          <LogoutVariant sx={{ marginRight: 2, fontSize: '1.375rem', color: 'text.secondary' }} />
          {t('general.backauth')}
        </MenuItem>

        {loginCRM && (
          <MenuItem sx={{ py: 2 }} onClick={handleBack}>
            <Icon icon='ri:arrow-go-back-fill' width='20' />
            <Typography sx={{ ml: 2 }}>{t('general.backauth')}</Typography>
          </MenuItem>
        )}
      </Menu>
    </Fragment>
  );
};

export default UserDropdown;
