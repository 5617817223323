import { createBrowserRouter } from 'react-router-dom';

// @Routes
import allRoutes from './allRoutes';

export function Router() {
  const router = createBrowserRouter(allRoutes);

  return { router };
}
