import { useTranslation } from 'react-i18next';

// @MUI Components
import { Close, Send } from 'mdi-material-ui';
import { Drawer, Box, Typography, List, ListItem, Button, Divider, IconButton } from '@mui/material';

// @Hooks
import { useServices } from '../hooks/useServices';
import useCategoryForm from '../hooks/useCategoryForm';

// @Components
import CustomInput from '../../../components/CustomInput';

export default function CategoryList() {
  const { categoriesForEdit, toggleCategoryForm, handleToggleCategoryForm } = useServices();

  const { control: categoryControl, onSubmit: onSubmitCategory, toggleForm, categoryId } = useCategoryForm();

  const { t, i18n } = useTranslation(['common']);

  return (
    <Drawer
      sx={{
        width: '100%',
        maxWidth: 360,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: '100%',
          maxWidth: 360,
          boxSizing: 'border-box',
        },
      }}
      variant='temporary'
      anchor='right'
      open={toggleCategoryForm}
      onClose={() => {
        toggleForm();
        handleToggleCategoryForm();
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          p: 1,
          backgroundColor: ({ palette }) => palette.background.default,
        }}
      >
        <Typography variant='h6'>{t('general.edit')}</Typography>
        <IconButton onClick={() => handleToggleCategoryForm()}>
          <Close />
        </IconButton>
      </Box>

      <Box sx={{ p: 3 }}>
        <List>
          {categoriesForEdit.map(category => (
            <ListItem
              onClick={() => toggleForm(category.id, category)}
              sx={{
                cursor: 'pointer',
                boxShadow: 1,
                transition: 'all 0.3s',
                mb: 1,
                ':hover': { backgroundColor: 'primary.main', color: 'white' },
              }}
              key={category.id}
            >
              {i18n.language === 'es' ? category.name : category.nameEN}
            </ListItem>
          ))}
        </List>

        <Divider sx={{ my: 4 }} />

        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <Typography
            variant='h6'
            id='modal-modal-title'
            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 1, mb: 2 }}
          >
            {t('services.table.category')}
          </Typography>

          <Box
            method='POST'
            component={'form'}
            onSubmit={onSubmitCategory}
            sx={{ display: 'flex', flexDirection: 'column', gap: 2, flex: 1 }}
          >
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <CustomInput
                control={categoryControl}
                name='name'
                label={`${t('services.form.name_es')}`}
                disabled={Boolean(!categoryId)}
              />
              <CustomInput
                control={categoryControl}
                name='nameEN'
                label={`${t('services.form.name_en')}`}
                disabled={Boolean(!categoryId)}
              />
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
              <Button type='submit' variant='contained' color='primary' disabled={Boolean(!categoryId)}>
                <Send />
              </Button>

              <Button variant='contained' color='error' onClick={() => toggleForm()} disabled={Boolean(!categoryId)}>
                <Close />
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Drawer>
  );
}
