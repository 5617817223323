import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/reducers';

// @Mui
import { Card, Box, Grid } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Icon } from '@iconify/react';
import Typography from '@mui/material/Typography';
import { SendEmails } from '../../views/Email/massive-emails/send-emails.view';
import { CustomEmails } from '../../views/Email/custom/custom.view';

// @Views

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel({ children, value, index, ...other }: TabPanelProps) {
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}
export const EmailView = () => {
  const [value, setValue] = useState(0);

  const { t } = useTranslation(['common']);

  const { role } = useSelector((state: RootState) => state.user);

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Grid container sx={{ width: { xs: '85%', sm: '90%', md: '100%' }, ml: 'auto', mr: { xs: '1rem', md: 'auto' } }}>
      <Card>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            orientation='horizontal'
            variant='scrollable'
            value={value}
            onChange={handleChange}
            aria-label='Vertical tabs example'
            sx={{ borderRight: 1, borderColor: 'divider' }}
          >
            <Tab label={t('title.send')} {...a11yProps(0)} icon={<Icon icon='lucide:mails' width='25' />} />
            {/* {role === 'admin' && (
              <Tab
                label={t('title.customMail')}
                {...a11yProps(1)}
                icon={<Icon icon='mdi:email-edit-outline' width='25' />}
              />
            )} */}
          </Tabs>

          <TabPanel value={value} index={0}>
            <SendEmails />
          </TabPanel>

          {role === 'admin' && (
            <TabPanel value={value} index={1}>
              <CustomEmails />
            </TabPanel>
          )}
        </Box>
      </Card>
    </Grid>
  );
};
