import { createContext, useState } from 'react';

// @MUI Components
import { Box, Divider, Drawer, IconButton } from '@mui/material/';
import { styled, Theme, CSSObject } from '@mui/material/styles';

// @Hooks
import { useNavigation } from './useNavigation';

// @Utils
import { breakpoints } from '../../../utils/customTheme.util';

// @Components
import NavItems from '../NavItems';
import { CustomSwipeableDrawer } from '../../StyledComponents/CustomSwipeableDrawerStyled';
import { MenuLockedIcon, MenuUnlockedIcon } from '../components/NavBtn';

interface IProps {
  drawerWidth: number;
  open: boolean;
  lock: boolean;
  handleDrawerToggle: () => void;
  handleDrawerStayToggle: () => void;
  container: (() => HTMLElement) | undefined;
}

interface Context {
  closeDrawerMobile: () => void;
}

export const NavigationContext = createContext<Context>({
  closeDrawerMobile: () => {
    return null;
  },
});

export default function Navigation({ drawerWidth, open, handleDrawerToggle, lock, handleDrawerStayToggle }: IProps) {
  const { sm } = breakpoints;

  const { items } = useNavigation();

  const mobile = window.innerWidth < sm;

  const context = {
    closeDrawerMobile: () => {
      if (mobile) handleDrawerToggle();
    },
  };

  const openedMixin = (theme: Theme): CSSObject => ({
    '& .MuiModal-hidden': {
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      overflowX: 'hidden',
      display: 'flex',
    },
  });

  const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    display: 'flex',
    width: `calc(${theme.spacing(0)})`,
    [theme.breakpoints.up('sm')]: {
      width: `calc(${theme.spacing(8)})`,
    },
    p: { display: 'none' },
  });

  const CustomDrawer = styled(Drawer, { shouldForwardProp: prop => prop !== 'open' })(({ theme, open }) => ({
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-modal': openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }));

  const [hidden, setHidden] = useState(false);
  const [navVisible, setNavVisible] = useState(false);
  const [navHover, setNavHover] = useState(false);

  // ** Drawer
  const drawer = () => {
    const newItems = items.filter(item => !item?.notRender);

    return (
      <Box
        onMouseEnter={() => {
          if (!lock) handleDrawerToggle();
        }}
        onMouseLeave={() => {
          if (!lock) handleDrawerToggle();
        }}
        sx={{
          position: 'relative',
          height: '10rem',
          width: '10rem',
          zIndex: 100,
          backgroundColor: 'lime',
        }}
      >
        <Box sx={{ position: 'relative', display: 'flex', flexDirection: 'row', mx: '0.5rem' }}>
          <Box sx={{ mt: 2, mb: 0.5, mx: 'auto', alignItems: 'right' }}>
            <Box
              alt='cml'
              component='img'
              src={'/assets/400x246-CML-Exports.png'}
              style={{ width: 50, transform: `scale(${open ? 1.5 : 1})` }}
            />
          </Box>

          {!lock ? (
            <IconButton
              onClick={handleDrawerStayToggle}
              sx={{ position: 'absolute', top: '50%', right: 2, transform: 'translateY(-50%)' }}
            >
              <MenuUnlockedIcon />
            </IconButton>
          ) : (
            <IconButton
              onClick={handleDrawerToggle}
              sx={{ position: 'absolute', top: '50%', right: 2, transform: 'translateY(-50%)' }}
            >
              <MenuLockedIcon />
            </IconButton>
          )}
        </Box>

        <Divider />

        <NavItems open={open} items={newItems} />
      </Box>
    );
  };

  // Drawer Props for Mobile & Tablet screens
  const MobileDrawerProps = {
    open: navVisible,
    onOpen: () => setNavVisible(true),
    onClose: () => setNavVisible(false),
    ModalProps: {
      keepMounted: true, // Better open performance on mobile.
    },
  };

  // Drawer Props for Desktop screens
  const DesktopDrawerProps = {
    open: true,
    onOpen: () => null,
    onClose: () => null,
    onMouseEnter: () => {
      setNavHover(true);
    },
    onMouseLeave: () => {
      setNavHover(false);
    },
  };

  return (
    <Box
      component='nav'
      aria-label='mailbox folders'
      sx={{ width: { sm: 0 }, flexShrink: { sm: 0 }, position: 'relative' }}
    >
      <NavigationContext.Provider value={context}>
        <CustomSwipeableDrawer
          className='layout-vertical-nav'
          variant={hidden ? 'temporary' : 'permanent'}
          {...(hidden ? { ...MobileDrawerProps } : { ...DesktopDrawerProps })}
          // PaperProps={{
          //   sx: {
          //     width: navCollapsed && !navHover ? collapsedNavWidth : navWidth,
          //   },
          // }}
          // sx={{
          //   width: navCollapsed ? collapsedNavWidth : navWidth,
          //   '& .MuiDrawer-paper': {
          //     ...drawerColor(),
          //     ...drawerBgColor(),
          //     ...(!hidden && navCollapsed && navHover ? { boxShadow: 9 } : {}),
          //     borderRight:
          //       navigationBorderWidth === 0 ? 0 : `${navigationBorderWidth}px solid ${theme.palette.divider}`,
          //   },
          // }}
        >
          {/* {drawer()} */}
          <Box
            onMouseEnter={() => {
              if (!lock) handleDrawerToggle();
            }}
            onMouseLeave={() => {
              if (!lock) handleDrawerToggle();
            }}
            sx={{ position: 'relative', height: '10rem', width: '10rem', backgroundColor: 'lime' }}
          />
        </CustomSwipeableDrawer>
      </NavigationContext.Provider>
    </Box>
  );
}
