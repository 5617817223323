// @MUI Components
import { Box, Divider, ListSubheader, Typography, useTheme } from '@mui/material/';

// @Interfaces
import { Item } from '../../models/items';

interface Props {
  item: Item;
  open: boolean;
}

export default function NavTitle({ item, open }: Props) {
  const theme = useTheme();
  return (
    <Box sx={{ py: 1, backgroundColor: theme.palette.background.default }}>
      <ListSubheader sx={{ backgroundColor: theme.palette.background.default }}>
        {open ? (
          <Typography
            noWrap
            sx={{
              fontSize: '0.75rem',
              lineHeight: 'normal',
              letterSpacing: '0.21px',
              textTransform: 'uppercase',
              color: '#ccc',
              fontWeight: 'light',
            }}
          >
            {item.sectionTitle}
          </Typography>
        ) : (
          <Divider />
        )}
      </ListSubheader>
    </Box>
  );
}
