import Swal from 'sweetalert2';
import { createSlice, PayloadAction, AnyAction, AsyncThunk } from '@reduxjs/toolkit';

// @Actions
import { getSellers, getCustomers, postBudget, getCompanyByRol } from './actions-async';

// @Models
import {
  IStep,
  IBudgetFormState,
  IUserProps,
  ISellerForm,
  ICustomerRequest,
  ICustomerForm,
  // IShipmentForm,
  IShipment,
  // IServiceBudgetForm,
  IPostBudgetResponse,
} from '../../../models';

// @Utils
import { LOADING_INIT } from '../../../utils/contants';

// @AsyncThunk
type GenericAsyncThunk = AsyncThunk<unknown, unknown, any>;
type PendingAction = ReturnType<GenericAsyncThunk['pending']>;
type RejectedAction = ReturnType<GenericAsyncThunk['rejected']>;

const isLoaderAction = (action: AnyAction): action is PendingAction => {
  // return action.type.startsWith('budget/') && action.type.endsWith('/pending');
  return action.type === 'budget/postBudget/pending';
};

const initialSteps: IStep[] = [
  { id: 1, description: 'Informacion vendedor' },
  { id: 2, description: 'Informacion cliente' },
  { id: 3, description: 'Servicios' },
  { id: 4, description: 'Completado' },
];

const initialState: IBudgetFormState = {
  loading: LOADING_INIT,
  type: 1,
  errors: {
    error: false,
    text: [],
  },
  steps: initialSteps,
  currentStep: 1,
  status: 'active',
  sellers: [],
  sellersData: [],
  sellerForm: {
    country: '',
    sellerName: '',
    sellerEmail: '',
    email: '',
    dateOfExpiration: null,
    daysOfExpiration: 0,
    sellerId: null,
    budgetType: 1,
    incoterms: '',
    alphanumeric: '',
  },
  customers: [],
  companyByRol: [],
  customersData: [],
  customerForm: {
    customerId: null,
    city: '',
    fullName: '',
    identifier: '',
    country: '',
    state: '',
    address: '',
    postalCode: '',
    email: '',
    phone: '',
    commission: 0,
  },
  shipments: [],
};

const budgetFormSlice = createSlice({
  name: 'budgetForm',
  initialState,
  reducers: {
    backStep: state => {
      if (state.currentStep > 1) state.currentStep -= 1;
    },
    nextStep: state => {
      if (state.currentStep <= state.steps.length) state.currentStep += 1;
    },
    reset: () => {
      return initialState;
    },
    setType: (state, action: PayloadAction<{ type: number }>) => {
      state.type = action.payload.type;
    },
    setSeller: (state, action: PayloadAction<{ label: string; value: number }>) => {
      state.sellers = [action.payload];
    },
    setSellerForm: (state, action: PayloadAction<ISellerForm>) => {
      state.sellerForm = action.payload;
      state.status = action.payload.budgetType === 1 ? 'active' : 'request';
    },
    setCustomerForm: (state, action: PayloadAction<ICustomerForm>) => {
      const customer = action.payload;
      state.customerForm = customer;

      const customerData = state.customersData.find(customerData => customerData.id === customer.customerId);

      if (customerData) {
        const compareCustomer: ICustomerForm = {
          customerId: customerData.id,
          city: customerData.city,
          fullName: customerData.name,
          identifier: customerData.taxId,
          country: customerData.country,
          state: customerData.state,
          address: customerData.address,
          postalCode: customerData.postalCode,
          email: customerData.email,
          phone: customerData.phone,
          commission: customerData.commission,
        };

        const status = state.status;

        if (status !== 'request') {
          const condition = JSON.stringify(customer) !== JSON.stringify(compareCustomer);

          state.status = condition ? 'pending' : 'active';
        }
      }
    },
    /* setShipmentForm: (state, action: PayloadAction<IShipmentForm>) => {
      state.shipmentForm = action.payload;
    }, */
    addService: (state, action: PayloadAction<IShipment>) => {
      const newShipment = Object.assign(action.payload, { id: state.shipments.length + 1 });
      state.shipments = [...state.shipments, newShipment];
    },
    editService: (state, action: PayloadAction<IShipment>) => {
      const editShipment = action.payload;
      state.shipments = state.shipments.map(shipment => {
        if (shipment.id === editShipment.id) {
          return editShipment;
        }
        return shipment;
      });
    },
    deleteService: (state, action: PayloadAction<number>) => {
      const id = action.payload;
      const shipments = state.shipments.filter(shipment => shipment.id !== id);
      state.shipments = shipments.map((shipment, index) => {
        return Object.assign(shipment, { id: index + 1 });
      });
    },
  },
  extraReducers: builder => {
    builder.addCase(getSellers.fulfilled, (state, action: PayloadAction<IUserProps[]>) => {
      state.loading = LOADING_INIT;

      const sellers = action.payload;

      state.sellersData = sellers;
      state.sellers = sellers
        .filter(seller => seller.person !== null)
        .map(seller => {
          return { label: seller.person?.name || '', value: seller.id };
        });
    });
    builder.addCase(getCustomers.fulfilled, (state, action: PayloadAction<ICustomerRequest>) => {
      state.loading = LOADING_INIT;
      const customers = action.payload.data;
      state.customersData = customers;
      state.customers = customers.map(customer => {
        return {
          label: customer.name,
          value: customer.id,
        };
      });
    });
    builder.addCase(getCompanyByRol.fulfilled, (state, action: PayloadAction<ICustomerRequest>) => {
      state.loading = LOADING_INIT;
      const company = action.payload.data;
      state.companyByRol = company;
      state.companyByRol = company.map(c => {
        return {
          id: c.id,
          name: c.name,
          country: c.country,
          email: c.email,
          taxId: c.taxId,
          address: c.address,
          city: c.city,
          commission: c.commission,
          phone: c.phone,
          postalCode: c.postalCode,
          state: c.state,
        };
      });
    });
    builder.addCase(postBudget.fulfilled, (state, action: PayloadAction<IPostBudgetResponse>) => {
      if (state.currentStep <= state.steps.length) state.currentStep += 1;
      state.newBudget = action.payload.data.budget;
      state.loading = LOADING_INIT;
    });
    // Matcher para saber cuando una petición isLoading
    builder.addMatcher(isLoaderAction, state => {
      state.errors = {
        text: [],
        error: false,
      };

      state.loading = {
        show: true,
        type: 'screen',
      };
    });
    // Matcher para saber cuando una petición es rejected
    builder.addMatcher(
      (action): action is RejectedAction => action.type.startsWith('user/') && action.type.endsWith('/rejected'),
      (state, action) => {
        state.loading = LOADING_INIT;
        const message = action.payload as string;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: message,
          willClose: () => {
            window.location.href = process.env.REACT_APP_AUTH_FRONT || '';
          },
        });
        state.errors = {
          text: ['Ocurrió un error al procesar tu solicitud'],
          error: true,
        };
      },
    );
  },
});

export const {
  backStep,
  nextStep,
  reset,
  setSeller,
  setSellerForm,
  setCustomerForm,
  // setShipmentForm,
  addService,
  editService,
  deleteService,
  setType,
} = budgetFormSlice.actions;

export default budgetFormSlice.reducer;
