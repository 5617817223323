// @Imports Librairies
import { createContext, useState, ReactNode, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { AxiosError } from 'axios';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';

// @Services
import { getServices, createBudget } from '../services';

// @Imports Utils
import { IStep } from '../../../models';
import {
  CustomerInteractionContextModel,
  ServiceSupplier,
  ShipmentCountry,
  ServicesViewForm,
  ShipmentCreate,
} from '../models';

const defaultContext: CustomerInteractionContextModel = {
  steps: [],
  loading: true,
  error: false,
  services: [],
  selectedServices: [],
  countries: {
    countryOrigin: '',
    countryDestiny: '',
  },
  currentStep: 1,
  countryOrigin: '',
  countryDestiny: '',
  handleNext: () => {},
  handleBack: () => {},
  handleServices: () => {},
  handleCreateBudget: async () => {},
};

export const CustomerInteractionContext = createContext<CustomerInteractionContextModel>(defaultContext);

interface Props {
  children: ReactNode;
}

const steps: IStep[] = [
  {
    id: 1,
    description: 'general.service',
  },
  {
    id: 2,
    description: 'general.shipmentDetails',
  },
];

export const CustomerInteractionProvider = ({ children }: Props) => {
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [services, setServices] = useState<ServiceSupplier[]>(defaultContext.services);
  const [selectedServices, setSelectedServices] = useState<ServiceSupplier[]>(defaultContext.selectedServices);
  const [countries, setCountries] = useState<ShipmentCountry>(defaultContext.countries);
  const [loading, setLoading] = useState<boolean>(defaultContext.loading);
  const [error, setError] = useState<boolean>(defaultContext.error);
  // @Hooks
  const [searchParams] = useSearchParams();
  const countryOrigin = searchParams.get('origin') ?? '';
  const countryDestiny = searchParams.get('destiny') ?? '';
  const { t } = useTranslation();

  useEffect(() => {
    getServicesData();

    return () => {
      setServices([]);
      setLoading(false);
      setError(false);
    };
  }, []);

  const getServicesData = async () => {
    setLoading(true);
    try {
      const token = searchParams.get('token');
      if (!token) {
        setError(true);
        return;
      }
      const response = await getServices(token);
      setServices(response);
    } catch (error) {
      setError(true);
    }
    setLoading(false);
  };

  const handleNext = () => {
    if (currentStep === steps.length) return;
    setCurrentStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    if (currentStep === 1) return;
    setCurrentStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleServices = (data: ServicesViewForm) => {
    setSelectedServices(data.servicesSelected);
    setCountries({
      countryOrigin: data.countryOrigin,
      countryDestiny: data.countryDestiny,
    });

    handleNext();
  };

  const handleCreateBudget = async (data: ShipmentCreate) => {
    const toastId = toast.loading(t('customerInteraction.loading.title', { ns: 'common' }) || '');
    try {
      const token = searchParams.get('token') ?? '';
      await createBudget(token, data);
      toast.dismiss(toastId);
      Swal.fire({
        icon: 'success',
        title: t('alert.success', { ns: 'common' }) || '',
        text: t('alert.supplier.success', { ns: 'common' }) || '',
        showCloseButton: true,
      });
      setTimeout(() => {
        window.close();
      }, 1000);
    } catch (error) {
      toast.dismiss(toastId);
      if (error instanceof AxiosError) {
        if (error.response?.status === 400) {
          Swal.fire({
            icon: 'error',
            title: 'customerInteraction.error.expiredToken.title',
            text: t('customerInteraction.error.expiredToken.message', { ns: 'common' }) || '',
            showCloseButton: true,
          });
          return;
        }
      }
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: t('alert.somethingerror', { ns: 'common' }) || '',
        showCloseButton: true,
      });
    }
  };

  const value: CustomerInteractionContextModel = {
    steps,
    loading,
    error,
    services,
    selectedServices,
    countries,
    currentStep,
    handleNext,
    handleBack,
    handleServices,
    handleCreateBudget,
    countryOrigin,
    countryDestiny,
  };

  return <CustomerInteractionContext.Provider value={value}>{children}</CustomerInteractionContext.Provider>;
};
