import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// @MUI Components
import { IconButton, TableCell, TableRow, Typography, Box, CardHeader, Divider } from '@mui/material';

// @Icons
import PencilOutline from 'mdi-material-ui/PencilOutline';
import DeleteOutline from 'mdi-material-ui/DeleteOutline';
import { Icon } from '@iconify/react';

// @Components
import Loader from '../../../components/Loader';
import Pagination from '../../../components/Pagination';
import TableComponent from '../../../components/TableContainer';

// @Models
import { ServiceTypeModel } from '../../../models';

interface IProps {
  t: any;
  tableIsLoading: boolean;
  services: ServiceTypeModel[];
  permissions: string[];
  handleEdit: (render: boolean, data: ServiceTypeModel) => void;
  handleDelete: (id: number) => void;
}

interface IRowProps {
  row: any;
  canDelete: boolean;
  canSeeHistory: boolean;
  handleEdit: (render: boolean, data: ServiceTypeModel) => void;
  handleDelete: (id: number) => void;
}

const Row = ({ row, canDelete, canSeeHistory, handleEdit, handleDelete }: IRowProps) => {
  const { t, i18n } = useTranslation(['common']);

  return (
    <TableRow>
      <TableCell>
        <Typography
          noWrap
          sx={{ textTransform: 'capitalize', display: 'flex', alignItems: 'center', flexDirection: 'column' }}
        >
          {i18n.language === 'es' ? row.name : row.nameEN !== null ? row.nameEN : row.name}

          <Typography component='span' sx={{ fontSize: '0.8rem', color: 'text.secondary' }}>
            {row.username}
          </Typography>
        </Typography>
      </TableCell>

      <TableCell>
        <Typography noWrap sx={{ textTransform: 'capitalize', display: 'flex', alignItems: 'center' }}>
          {row.transportation.map((item: any) => (i18n.language === 'es' ? item.name : item.nameEN), '').join(' ')}
        </Typography>
      </TableCell>

      <TableCell align='center'>
        {row.canEdit && (
          <IconButton
            color='info'
            onClick={() => handleEdit(true, row)}
            title={`${t('general.edit', { ns: 'common' })}`}
          >
            <PencilOutline fontSize='medium' />
          </IconButton>
        )}

        {canDelete && (
          <IconButton color='error' onClick={() => handleDelete(row.id)} title={`${t('general.delete')}`}>
            <DeleteOutline fontSize='medium' />
          </IconButton>
        )}

        {canSeeHistory && (
          <Link to={`/services/typeServices/${row.id}`}>
            <IconButton title={`${t('general.history')}`}>
              <Icon icon='mdi:clipboard-text-history-outline' height={22} color='#00f0ff' />
            </IconButton>
          </Link>
        )}
      </TableCell>
    </TableRow>
  );
};

export const TypeServicesTable = ({ t, tableIsLoading, services, permissions, handleEdit, handleDelete }: IProps) => {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);

  if (tableIsLoading) return <Loader />;

  return (
    <Box sx={{ display: 'block', height: 500, width: '100%', mb: 20 }}>
      <CardHeader sx={{ display: 'flex', textAlign: 'center' }} titleTypographyProps={{ variant: 'h6' }} />

      <Divider sx={{ margin: 0 }} />

      <TableComponent
        Row={Row}
        data={services.slice(page * pageSize, (page + 1) * pageSize)}
        headers={[t('services.type'), t('general.category'), t('general.actions')]}
        props={{
          canDelete: permissions.includes('delete_service_type'),
          canSeeHistory: permissions.includes('service_type_history'),
          handleEdit,
          handleDelete,
        }}
      />

      <Pagination count={services.length} setPage={setPage} setPageSize={setPageSize} page={page} pageSize={pageSize} />
    </Box>
  );
};
