// @Library
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// @Redux
import { useAppDispatch } from '../../redux/store';
import { getTransportations } from '../../redux/slices/transportation/actions-async';
import { getServiceTypes } from '../../redux/slices/services/actions-async';

// @Utils
import { axiosEstimates, axiosCRM } from '../../utils/api';

// @Models
import { IBudgetById, IClientTabForm, ICustomerRequest, IServicesTab } from '../../models';

export function useBudgetEdit() {
  const [value, setValue] = useState(1);
  const [status, setStatus] = useState('');
  const [budgetHaveBill, setBudgetHaveBill] = useState(false);
  const [clientTab, setClientTab] = useState<IClientTabForm | undefined>();
  const [servicesTab, setServicesTab] = useState<IServicesTab | undefined>();

  const { t } = useTranslation();

  const { id } = useParams();

  const dispatch = useAppDispatch();

  const handleGet = async () => {
    try {
      const response = await axiosEstimates.get<IBudgetById>('budget/' + id);

      let customer;

      setBudgetHaveBill(response.data.budget.haveBill);

      if (response.data.seller) {
        const customerRes = await axiosCRM.post<ICustomerRequest>('estimates/', {
          'e-mail': response.data.seller.email,
        });

        customer = customerRes.data.data.find(customer => customer.email === response.data.customer.email);
      }

      setClientTab({
        seller: response.data.seller.name,
        customer: response.data.customer.name,
        customerEmail: response.data.customer.email,
        dateOfExpiration: response.data.budget.expirationDate,
        commission: response.data.customer.commission,
        oldCommission: customer ? customer?.commission : 16,
        daysOfExpiration: 0,
        incoterms: response.data.budget.incoterms,
        type: response.data.budget.type,
      });

      setServicesTab({
        shipments: response.data.shipments.map((shipment, index) => {
          return {
            ...shipment,
            shipmentId: shipment.id,
            id: index + 1,
          };
        }),
        amountOfIva: response.data.budget.amountOfIva,
        amountOfSubtotal: response.data.budget.amountOfSubtotal,
        amountOfTotal: response.data.budget.amountOfTotal,
        oldCustomerCommission: customer ? customer?.commission : 16,
        customerCommission: response.data.customer.commission,
        budgetType: response.data.budget.type,
      });

      setStatus(response.data.budget.status);
      // dispatch(getCustomers({ email: response.data.seller.email }));
      dispatch(getTransportations());
      dispatch(getServiceTypes());
    } catch (error) {
      console.error(error);
    }
  };

  const updateDateOfExpiration = (date: string) => {
    if (clientTab) setClientTab({ ...clientTab, dateOfExpiration: date });
  };

  const handleChange = (newValue: number) => setValue(newValue);

  useEffect(() => {
    handleGet();
  }, []);

  return { value, handleChange, t, clientTab, servicesTab, status, id, budgetHaveBill, updateDateOfExpiration };
}
