import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import { Customer } from '../models';

interface RenderCell {
  row: Customer;
}

export const defaultColumns = () => {
  const { t } = useTranslation(['common']);
  return [
    {
      flex: 0.11,
      minWidth: 130,
      field: 'name',
      headerName: `${t('general.company')}/${t('general.customer')}`,
      // headerClassName: 'gridHeader',
      // headerAlign: 'center',
      renderCell: ({ row }: RenderCell) => {
        return (
          <Typography noWrap variant='body2' sx={{ fontWeight: 600, color: 'text.primary', textDecoration: 'none' }}>
            {`${row.name} (${row.email})`}
          </Typography>
        );
      },
    },
  ];
};
