import { createSlice, PayloadAction, AnyAction, AsyncThunk } from '@reduxjs/toolkit';

// @Models
import { IServicesRequest, Loading, IServicesState, IServiceTypesRequest } from '../../../models';

// @Actions
import { getServices, getServicesBySupplier, getServiceTypes, getServicesByType } from './actions-async';

// @AsyncThunk
type GenericAsyncThunk = AsyncThunk<unknown, unknown, any>;
type PendingAction = ReturnType<GenericAsyncThunk['pending']>;
type RejectedAction = ReturnType<GenericAsyncThunk['rejected']>;

const isLoaderAction = (action: AnyAction): action is PendingAction => {
  return action.type.startsWith('user/') && action.type.endsWith('/pending');
};

const LOADING_INIT: Loading = {
  show: false,
  type: 'screen',
};

export const initialState: IServicesState = {
  loading: LOADING_INIT,
  errors: { error: false, text: [] },
  services: [],
  serviceItems: [],
  serviceTypes: [],
  serviceTypesItemsES: [],
  serviceTypesItemsEN: [],
};

export const servicesSlice = createSlice({
  name: 'service',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getServices.fulfilled, (state, action: PayloadAction<IServicesRequest[]>) => {
      state.services = action.payload;
    });
    builder.addCase(getServicesBySupplier.fulfilled, (state, action: PayloadAction<IServicesRequest[]>) => {
      state.services = action.payload;

      state.serviceItems = action.payload.map(service => {
        return {
          value: service.id,
          label: service.name,
          labelEN: service.nameEN,
        };
      });
    });
    builder.addCase(getServicesByType.fulfilled, (state, action: PayloadAction<IServicesRequest[]>) => {
      state.services = action.payload;
      state.serviceItems = action.payload.map(service => {
        return {
          value: service.id,
          label: service.name,
          labelEN: service.nameEN,
        };
      });
    });
    builder.addCase(getServiceTypes.fulfilled, (state, action: PayloadAction<IServiceTypesRequest[]>) => {
      state.serviceTypes = action.payload;
      state.serviceTypesItemsEN =
        action.payload?.map(type => {
          return {
            value: type.id,
            label: type.nameEN,
          };
        }) || [];
      state.serviceTypesItemsES =
        action.payload?.map(type => {
          return {
            value: type.id,
            label: type.name,
          };
        }) || [];
    });
    // Matcher para saber cuando una petición isLoading
    builder.addMatcher(isLoaderAction, state => {
      state.errors = { text: [], error: false };
      state.loading = { show: true, type: 'screen' };
    });
    // Matcher para saber cuando una petición es rejected
    builder.addMatcher(
      (action): action is RejectedAction => action.type.startsWith('user/') && action.type.endsWith('/rejected'),
      (state, action) => {
        state.loading = LOADING_INIT;
        state.errors = {
          text: ['Ocurrió un error al procesar tu solicitud'],
          error: true,
        };
      },
    );
  },
});

// Action creators are generated for each case reducer function
// export const { quotInfo, setServices } = servicesSlice.actions;
export default servicesSlice.reducer;
