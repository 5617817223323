// @Mui
import { Skeleton, CircularProgress } from '@mui/material/';
import Button, { ButtonProps } from '@mui/material/Button';

// @Interfaces
interface IProps extends ButtonProps {
  label: string;
  loading?: boolean;
  spinner?: boolean;
  customStyles?: React.CSSProperties;
}

/**
 * @component
 * @subcategory Global
 *
 * @description
 * A customizable button component with loading and spinner features.
 * #### Example
 * ```
 * return(
 *  <ButtonForm label="Submit" size="large" variant="outlined" />
 * )
 * ```
 *
 * @property {string} label - The text to be displayed on the button.
 * @property {ButtonProps} [size='medium'] - The size of the button.
 * @property {ButtonProps} [variant='contained'] - The variant of the button.
 * @property {boolean} [loading] - Whether the button is in a loading state.
 * @property {boolean} [spinner] - Whether to display a spinner on the button.
 * @property {React.CSSProperties} [customStyles] - Custom styles for the button.
 *
 * @returns {JSX.Element} A custom ButtonForm component.
 * @author CML Exports Front-End Developers
 */
export default function ButtonForm({
  label,
  size = 'medium',
  variant = 'contained',
  spinner,
  loading,
  customStyles,
  ...props
}: IProps) {
  return (
    <>
      {!loading ? (
        <Button size={size} variant={variant} sx={customStyles} {...props}>
          {!spinner ? label : <CircularProgress size={25} />}
        </Button>
      ) : (
        <Skeleton variant='rectangular' animation='wave' width={80} height={40} />
      )}
    </>
  );
}
