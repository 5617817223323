import toast from 'react-hot-toast';
import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// @MUI Components
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

// @Services
import { getDocumentsByBudget } from '../Fomularios Temporales Guias/services';

// @Utils
import { axiosEstimates } from '../../utils/api';

// @Components
import { CustomTabs } from '../../components';

// @Interfaces and Types
import { TabOption } from '../../models';
import { Document } from '../Fomularios Temporales Guias/models';

export function ShowDocuments() {
  const [documents, setDocuments] = useState<Document[]>([]);

  const [searchParams] = useSearchParams();

  const { t } = useTranslation(['common']);

  const validateToken = async (token: string) => {
    const toastId = toast.loading(t('toast.loading'));
    try {
      const response = await axiosEstimates.get<{ data: { idBudget: number } }>('/invoice/validate-token', {
        headers: { 'x-invoice-token': token },
      });
      const data = response.data.data;
      const documents = await getDocumentsByBudget(String(data.idBudget));
      setDocuments(documents);
    } catch (error) {
      setDocuments([]);
    }
    toast.dismiss(toastId);
  };

  useEffect(() => {
    validateToken(searchParams.get('token') || '');
  }, []);

  const tabs: TabOption[] = documents.map((document: Document, index) => ({
    id: index + 1,
    label: document.document_types.name,
    content: (
      <iframe
        src={`${process.env.REACT_APP_ESTIMATES_API}generate-pdf?documentId=${document.id}&strategy=by-document-id`}
        style={{
          display: 'block',
          width: '100%',
          height: '90vh',
        }}
      />
    ),
  }));

  if (documents.length === 0) {
    return (
      <Grid container>
        <Grid item xs={12}>
          <Card
            sx={{
              height: '95vh',
              mx: { xs: 'auto', sm: '5rem', md: '10rem', lg: '15rem', xl: '20rem' },
            }}
          >
            <CardContent sx={{ height: '95%' }}>
              <Skeleton variant='rounded' width='100%' height='100%' animation='wave' />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container>
      <Grid item xs={12} sx={{ mx: 'auto' }}>
        <Card
          sx={{
            height: '95vh',
            mx: { xs: 'auto', sm: '5rem', md: '10rem', lg: '15rem', xl: '20rem' },
          }}
        >
          <CustomTabs tabs={tabs} tab={1} />
        </Card>
      </Grid>
    </Grid>
  );
}
