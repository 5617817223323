import { axiosEstimates } from '../../../../utils';
import { Customer } from '../models';

export async function GetCustomers(serviceIds: number[]) {
  const response = await axiosEstimates.post<{ data: Customer[] }>('/customers/by-ids', {
    serviceIds,
  });

  return response.data.data;
}

export async function PostSendCustomers(customerIds: number[], comment: string, serviceIds: number[]): Promise<void> {
  await axiosEstimates.post('/email-massive/customers', {
    customerIds,
    comment,
    serviceIds,
  });
}
