// @Library
import { useForm } from 'react-hook-form';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorWrapper } from '../../../../components/StyledComponents/EditorWrapperStyled';
// @Mui
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

// @Components
import TextForm from '../../../../components/TextForm';
// import DatePicker from '../../../components/date-picker/date-picker.component';
import { registerLocale } from 'react-datepicker';
import es from 'date-fns/locale/es';

// @Mui
import { Button } from '@mui/material';
// @Models
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

interface IProps {
  handleClose: () => void;
}
const toolbar = {
  options: ['inline', 'list', 'remove', 'history'],
  inline: {
    options: ['bold', 'italic', 'underline', 'strikethrough'],
  },
  list: {
    options: ['unordered', 'ordered'],
  },
};
registerLocale('es', es);

export function FormServices({ handleClose }: IProps) {
  const { t } = useTranslation(['common']);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const { control } = useForm({
    mode: 'onSubmit',
  });
  const handleEditorChange = (value: EditorState) => {
    setEditorState(value);
  };
  return (
    <Box>
      <form>
        <Grid container spacing={5}>
          <Grid item xs={12} sm={12}>
            <TextForm control={control} label={t('general.email')} name='email' />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextForm control={control} label='Asunto' name='asunto' />
          </Grid>
          <Grid item xs={12} sm={12}>
            <EditorWrapper>
              <Editor
                editorState={editorState}
                onEditorStateChange={handleEditorChange}
                toolbar={toolbar as any}
                wrapperClassName='wrapperClassName'
                editorClassName='editorClassName'
              />
            </EditorWrapper>
          </Grid>
        </Grid>
        <Box sx={{ mt: 5, display: 'flex', justifyContent: 'flex-end' }}>
          <Button size='medium' color='error' variant='contained' onClick={handleClose}>
            {t('general.cancel', { ns: 'common' })}
          </Button>
          <Button size='medium' type='submit' variant='contained' sx={{ ml: 3 }}>
            {t('general.save', { ns: 'common' })}
          </Button>
        </Box>
      </form>
    </Box>
  );
}
