import React, { useState } from 'react';
import { Box, Tabs, Tab } from '@mui/material';
import AirGuideForm from './AirGuide/AirGuideForm';
import GAReservasForm from './GAReservas/GAReservasForm';
import GAManifiestoForm from './GAManifiesto/GAManifiestoForm';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';
import { useDocument } from '../hooks';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel({ children, value, index, ...other }: TabPanelProps) {
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

interface FormProps {
  valueForm: number;
  subform: number;
}

const RenderAirGuides = ({ valueForm }: FormProps) => {
  const [airForm, setAirForm] = useState(0);

  const { formChanged } = useDocument();

  const { t } = useTranslation(['common']);

  const handleChangeAirForm = (_: React.SyntheticEvent<Element, Event>, newValue: number) => {
    if (formChanged === true) {
      Swal.fire({
        title: t('budgetGuide.changeWarning.tittle') as string,
        text: t('budgetGuide.changeWarning.description') as string,
        icon: 'warning',
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: t('budgetGuide.changeWarning.confirmButton') as string,
        confirmButtonColor: '#56CA00',
        cancelButtonColor: '#FF4C51',
        cancelButtonText: t('budgetGuide.changeWarning.cancelButton') as string,
      }).then(result => {
        if (result.isConfirmed) {
          // if (newValue === 0) navigate('/budgets/guides/' + params.id + '/guia-aerea' + '/master');
          // if (newValue === 1) navigate('/budgets/guides/' + params.id + '/guia-aerea' + '/ga-reserva');
          // if (newValue === 2) navigate('/budgets/guides/' + params.id + '/guia-aerea' + '/ga-manifiesto');
          setAirForm(newValue);
        }
      });
    } else {
      // if (newValue === 0) navigate('/budgets/guides/' + params.id + '/guia-aerea' + '/master');
      // if (newValue === 1) navigate('/budgets/guides/' + params.id + '/guia-aerea' + '/ga-reserva');
      // if (newValue === 2) navigate('/budgets/guides/' + params.id + '/guia-aerea' + '/ga-manifiesto');
      setAirForm(newValue);
    }
  };

  return (
    <>
      <TabPanel value={valueForm} index={2}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={airForm}
            onChange={handleChangeAirForm}
            aria-label='basic tabs example'
            variant='scrollable'
            scrollButtons
            allowScrollButtonsMobile
          >
            <Tab label={t('budgetGuide.thirdTabFirstSubtittle')} />
            <Tab label={t('budgetGuide.thirdTabSecondSubtittle')} />
            <Tab label={t('budgetGuide.thirdTabThirdSubtittle')} />
          </Tabs>
        </Box>

        <br />

        <TabPanel value={airForm} index={0}>
          <AirGuideForm />
        </TabPanel>
        <TabPanel value={airForm} index={1}>
          <GAReservasForm />
        </TabPanel>
        <TabPanel value={airForm} index={2}>
          <GAManifiestoForm />
        </TabPanel>
      </TabPanel>
    </>
  );
};

export default RenderAirGuides;
