import { ReactNode, Fragment, useEffect } from 'react';

// @MUI Components
import Backdrop from '@mui/material/Backdrop';
import Box, { BoxProps } from '@mui/material/Box';

// @Interfaces and types
type SidebarType = {
  show: boolean;
  onOpen?: () => void;
  children: ReactNode;
  onClose?: () => void;
  hideBackdrop?: boolean;
  backDropClick?: () => void;
  direction?: 'left' | 'right';
};

/**
 * @component
 * @subcategory Global
 *
 * @description
 * A reusable sidebar component for displaying content in a sliding panel.
 * The component accepts various props to customize its behavior and appearance.
 *
 * #### Example
 * ```tsx
 * return(
 * <Sidebar show={true} direction="right" onOpen={() => console.log('Sidebar opened')}>
 *   <p>sidebar content</p>
 * </Sidebar>
 * )
 * ```
 *
 * @property {boolean} show Whether the sidebar is visible.
 * @property {string} [direction='left'] The direction of the sidebar ('left' or 'right').
 * @property {ReactNode} children The content of the sidebar.
 * @property {function} [backDropClick] A callback function for when the backdrop is clicked.
 * @property {BoxProps} [sx] Additional styles for the sidebar container.
 * @property {function} [onOpen] A callback function for when the sidebar is opened.
 * @property {function} [onClose] A callback function for when the sidebar is closed.
 * @property {boolean} [hideBackdrop=false] Whether to hide the backdrop.
 *
 * @return {JSX.Element} The Sidebar component.
 * @author CML Exports Front-End Developers
 */
const Sidebar = (props: BoxProps & SidebarType) => {
  const { sx, show, direction, children, hideBackdrop, onOpen, onClose, backDropClick } = props;

  const handleBackdropClick = () => backDropClick && backDropClick();

  useEffect(() => {
    if (show && onOpen) onOpen();
    if (show === false && onClose) onClose();
  }, [onClose, onOpen, show]);

  return (
    <Fragment>
      <Box
        {...props}
        sx={{
          top: 0,
          height: '100%',
          zIndex: 'drawer',
          position: 'absolute',
          transition: 'all .25s ease-in-out',
          backgroundColor: 'background.paper',
          ...(show ? { opacity: 1 } : { opacity: 0 }),
          ...(direction === 'right'
            ? { left: 'auto', right: show ? 0 : '-100%' }
            : { right: 'auto', left: show ? 0 : '-100%' }),
          ...sx,
        }}
      >
        {children}
      </Box>

      {hideBackdrop ? null : (
        <Backdrop
          open={show}
          transitionDuration={250}
          onClick={handleBackdropClick}
          sx={{ position: 'absolute', zIndex: theme => theme.zIndex.drawer - 1 }}
        />
      )}
    </Fragment>
  );
};

export default Sidebar;

Sidebar.defaultProps = {
  direction: 'left',
};
