import { forwardRef, Ref } from 'react';

// @MUI Components
import MuiAvatar, { AvatarProps } from '@mui/material/Avatar';
import { lighten, useTheme } from '@mui/material/styles';

// @Utils
import { hexToRGBA } from '../utils';

// @Interfaces and Types
import { ThemeColor } from '../models';
type CustomAvatarProps = AvatarProps & {
  color?: ThemeColor;
  skin?: 'filled' | 'light' | 'light-static';
};

export type UseBgColorType = {
  [key: string]: { color: string; backgroundColor: string };
};

const useBgColor = () => {
  const theme = useTheme();

  return {
    primaryFilled: {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
    },
    primaryLight: {
      color: theme.palette.primary.main,
      backgroundColor: hexToRGBA(theme.palette.primary.main, 0.12),
    },
    secondaryFilled: {
      color: theme.palette.secondary.contrastText,
      backgroundColor: theme.palette.secondary.main,
    },
    secondaryLight: {
      color: theme.palette.secondary.main,
      backgroundColor: hexToRGBA(theme.palette.secondary.main, 0.12),
    },
    successFilled: {
      color: theme.palette.success.contrastText,
      backgroundColor: theme.palette.success.main,
    },
    successLight: {
      color: theme.palette.success.main,
      backgroundColor: hexToRGBA(theme.palette.success.main, 0.12),
    },
    errorFilled: {
      color: theme.palette.error.contrastText,
      backgroundColor: theme.palette.error.main,
    },
    errorLight: {
      color: theme.palette.error.main,
      backgroundColor: hexToRGBA(theme.palette.error.main, 0.12),
    },
    warningFilled: {
      color: theme.palette.warning.contrastText,
      backgroundColor: theme.palette.warning.main,
    },
    warningLight: {
      color: theme.palette.warning.main,
      backgroundColor: hexToRGBA(theme.palette.warning.main, 0.12),
    },
    infoFilled: {
      color: theme.palette.info.contrastText,
      backgroundColor: theme.palette.info.main,
    },
    infoLight: {
      color: theme.palette.info.main,
      backgroundColor: hexToRGBA(theme.palette.info.main, 0.12),
    },
  };
};

/**
 * @component
 * @subcategory Global
 *
 * @description
 * A customizable avatar component with different skins and colors.
 *
 * #### Example
 * ```jsx
 * return(
 *  <Avatar src="path/to/image.jpg" skin="light" color="primary" />
 * )
 * ```
 *
 * @property {string} src The source of the avatar image.
 * @property {string} [skin='filled'] The skin of the avatar.
 * @property {string} [color='primary'] The color of the avatar.
 * @property {SxProps} [sx] Custom styles for the avatar.
 *
 * @returns {JSX.Element} A custom Avatar component.
 * @author CML Exports Front-End Developers
 */
// eslint-disable-next-line react/display-name
const Avatar = forwardRef((props: CustomAvatarProps, ref: Ref<any>) => {
  // ** Props
  const { sx, src, skin, color } = props;

  // ** Hook
  const theme = useTheme();
  const bgColors: UseBgColorType = useBgColor();

  const getAvatarStyles = (skin: 'filled' | 'light' | 'light-static' | undefined, skinColor: ThemeColor) => {
    let avatarStyles;

    if (skin === 'light') {
      avatarStyles = { ...bgColors[`${skinColor}Light`] };
    } else if (skin === 'light-static') {
      avatarStyles = {
        color: bgColors[`${skinColor}Light`].color,
        backgroundColor: lighten(theme.palette[skinColor].main, 0.88),
      };
    } else {
      avatarStyles = { ...bgColors[`${skinColor}Filled`] };
    }

    return avatarStyles;
  };

  const colors: UseBgColorType = {
    primary: getAvatarStyles(skin, 'primary'),
    secondary: getAvatarStyles(skin, 'secondary'),
    success: getAvatarStyles(skin, 'success'),
    error: getAvatarStyles(skin, 'error'),
    warning: getAvatarStyles(skin, 'warning'),
    info: getAvatarStyles(skin, 'info'),
  };

  return <MuiAvatar ref={ref} {...props} sx={!src && skin && color ? Object.assign(colors[color], sx) : sx} />;
});

Avatar.defaultProps = {
  skin: 'filled',
  color: 'primary',
};

export default Avatar;
