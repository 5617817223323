import { useTranslation } from 'react-i18next';

// @MUI Imports
import Typography from '@mui/material/Typography';

// @Models
import { Shipment } from '../models';

interface RenderCell {
  row: Shipment;
}

export const defaultColumns = () => {
  const { t } = useTranslation(['common']);

  return [
    {
      flex: 0.2,
      minWidth: 200,
      field: 'originDestiny',
      headerName: `${t('general.origin', { ns: 'common' })}/${t('general.destiny', { ns: 'common' })}`,
      headerClassName: 'gridHeader',
      renderCell: ({ row }: RenderCell) => {
        return (
          <Typography
            noWrap
            sx={{
              textTransform: 'capitalize',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {row.countryOrigin}/{row.countryDestiny}
          </Typography>
        );
      },
    },
    {
      flex: 0.2,
      minWidth: 200,
      field: 'service',
      headerName: t('general.content'),
      headerClassName: 'gridHeader',
      renderCell: ({ row }: RenderCell) => {
        return (
          <Typography
            noWrap
            sx={{
              textTransform: 'capitalize',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {row.content}
          </Typography>
        );
      },
    },
    {
      flex: 0.2,
      minWidth: 200,
      field: 'measures',
      headerName: t('general.measures'),
      headerClassName: 'gridHeader',
      headerAlign: 'center',
      renderCell: ({ row }: RenderCell) => {
        return (
          <Typography
            noWrap
            sx={{
              textTransform: 'capitalize',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {row.length} X {row.height} X {row.width}
          </Typography>
        );
      },
    },
  ];
};
