// @Routes wrapper
import { WrapperRoute as Wrapper } from './WrapperRoute';

// @Routes
import {
  LoginPage,
  HomePage,
  BudgetForm,
  BudgetView,
  BudgetPrint,
  SendBudget,
  LoginFromCRM,
  RequestedBudgets,
  // SendSuppliers,
  Expenses,
  Details,
  ExpensesForm,
  SupplierHistory,
  ServiceHistory,
  SuppServHistory,
  EmailView,
  ApproveBudget,
  DiscardBudget,
  TypeServiceHistory,
  Form,
  PdfSettings,
  SupplierChat,
  ShowBudget,
  CustomerInteractionContainer,
  CancelEmailMassive,
  ShowDocuments,
  FileView,
  Invoice,
  InvoiceSettings,
  ShareBillByEmail,
  ServicesModule,
} from '../pages';
import { SuppliersForm } from '../pages/Suppliers/supplier-form/supplier-form.view';
import BudgetFormEdit from '../pages/BudgetEdit/budget-edit.page';
import Services from '../pages/Service/Services';
import DesignOptionsTabs from '../pages/designOptions/design-options.view';
import BudgetGuides from '../pages/BudgetGuides';
import { DocumentPrint } from '../pages/Document/document-print.page';
import { Invoices } from '../pages/invoices/Invoices';
// import { InvoiceUsCml } from '../pages/invoices/invoicesUSA';
import InvoicesPrint from '../pages/invoices/views/InvoicePreviewDocument';
import RenderForms from '../pages/Fomularios Temporales Guias/RenderForms';
import { InvoiceFF } from '../pages/Budget/components/InvoiceCredit/InvoiceFF';
import { FiscalCredit } from '../pages/Budget/components/InvoiceCredit/FiscalCredit';
import { SupplierChat as Chat, SendEmailContainer } from '../views/send-supplier';
import { FormInvoiceFF } from '../pages/invoices/Components/Edit/FormFF';
import { FormInvoiceCCF } from '../pages/invoices/Components/Edit/FormCCF';
import PreviewFile from '../pages/Invoice/views/PreviewFile';
import ServicesBudget from '../pages/Suppliers/views/ServicesBudget/ServicesBudget';
import Suppliers from '../pages/Suppliers/Suppliers';
import Error404 from '../components/Error404';
import ActiveBudget from '../pages/ActiveBudget';
import BudgetPage from '../pages/Budget';

const allRoutes: { path: string; element: any }[] = [
  // * HOME
  { path: '/', element: <Wrapper component={<HomePage />} /> },

  // * LOGINS
  { path: '/login', element: <Wrapper isPublic component={<LoginPage />} /> },
  { path: '/login-from-crm', element: <Wrapper component={<LoginFromCRM />} /> },

  // * All about BUDGETS
  // WATCH
  { path: '/requests', element: <Wrapper component={<RequestedBudgets />} /> },
  { path: '/budgets', element: <Wrapper component={<BudgetPage type={1} />} /> },
  { path: '/budgets/:code', element: <Wrapper component={<BudgetPage type={1} />} /> },
  { path: '/ctis', element: <Wrapper component={<BudgetPage type={2} />} /> },
  { path: '/budgets-from-us', element: <Wrapper component={<BudgetPage type={3} />} /> },
  // CREATE
  { path: '/budgets/create', element: <Wrapper component={<BudgetForm />} /> },
  { path: '/ctis/create', element: <Wrapper component={<BudgetForm />} /> },
  { path: '/budgets-from-us/create', element: <Wrapper component={<BudgetForm />} /> },
  // EDIT
  { path: '/budgets/edit/:id', element: <Wrapper component={<BudgetFormEdit />} /> },
  // ACTIONS
  { path: '/show-budget', element: <ShowBudget /> },
  { path: '/approve', element: <ApproveBudget /> },
  { path: '/active', element: <ActiveBudget /> },
  { path: '/discard', element: <DiscardBudget /> },
  { path: '/budgets/view/:id', element: <Wrapper component={<BudgetView />} /> },
  { path: '/budgets/print/:id', element: <Wrapper component={<BudgetPrint />} /> },
  { path: '/budgets/send/:id/', element: <Wrapper component={<SendBudget />} /> },
  // BUDGETS GUIDES
  {
    path: '/budgets/guides/:id',
    element: (
      <Wrapper
        component={
          <BudgetGuides>
            <RenderForms _status={0} />
          </BudgetGuides>
        }
      />
    ),
  },

  // * All about EMAIL
  { path: '/email', element: <Wrapper component={<EmailView />} /> },
  { path: '/cancel-email-massive', element: <CancelEmailMassive /> },

  // * All about SERVICES
  { path: '/services', element: <Wrapper component={<ServicesModule />} /> },
  { path: '/services/history/:id', element: <Wrapper component={<ServiceHistory />} /> },
  { path: '/services/typeServices/:id', element: <Wrapper component={<TypeServiceHistory />} /> },
  { path: '/services/module', element: <Wrapper component={<Services />} /> },

  // * All about SUPPLIERS
  // WATCH
  { path: '/suppliers', element: <Wrapper component={<Suppliers />} /> },
  { path: '/suppliers/details/:id', element: <Wrapper component={<Details />} /> },
  { path: '/suppliers/history/:id', element: <Wrapper component={<SupplierHistory />} /> },
  { path: '/suppliers/details/history/:id', element: <Wrapper component={<SuppServHistory />} /> },
  // CREATE
  { path: '/suppliers/create', element: <Wrapper component={<SuppliersForm />} /> },
  // EDIT
  { path: '/suppliers/edit/:id', element: <Wrapper component={<SuppliersForm />} /> },
  { path: '/suppliers/budget/:idSupplier', element: <Wrapper component={<ServicesBudget />} /> },
  // ACTIONS
  { path: '/supplier-chat', element: <SupplierChat /> },
  { path: '/suppliers/chat/:id', element: <Wrapper component={<Chat />} /> },
  { path: '/suppliers/send/:id', element: <Wrapper component={<SendEmailContainer />} /> },
  { path: '/suppliers/:idSupplierBudget/budget/:idSupplier', element: <Wrapper component={<ServicesBudget />} /> },

  // * All about EXPENSES
  { path: '/expenses', element: <Wrapper component={<Expenses />} /> },
  { path: '/expenses/create', element: <Wrapper component={<ExpensesForm />} /> },
  { path: '/expenses/edit/:id', element: <Wrapper component={<ExpensesForm />} /> },

  // * All about CONFIGURATIONS
  { path: '/pdfSettings', element: <Wrapper component={<PdfSettings />} /> },
  { path: '/pdfSettings/create', element: <Wrapper component={<Form />} /> },
  { path: '/pdfConfigUpdate/:id', element: <Wrapper component={<Form />} /> },
  { path: '/DesignOptions', element: <Wrapper component={<DesignOptionsTabs />} /> },
  { path: '/invoiceSettings', element: <Wrapper component={<InvoiceSettings />} /> },

  // * All about INVOICES
  // WATCH
  { path: '/invoices/:id/', element: <Wrapper component={<Invoice />} /> },
  { path: '/invoices', element: <Wrapper component={<Invoices />} /> },
  { path: '/invoices/ctis', element: <Wrapper component={<Invoices />} /> },
  { path: '/invoices/us-cml', element: <Wrapper component={<Invoices />} /> },
  // ACTIONS
  { path: '/invoice/send/:id/:billType', element: <Wrapper component={<ShareBillByEmail />} /> },
  { path: '/invoices/:id/paymentFile/:paymentId', element: <Wrapper component={<PreviewFile />} /> },
  { path: '/invoices/ff/:id', element: <Wrapper component={<InvoiceFF />} /> },
  { path: '/invoices/creditfiscal/:id', element: <Wrapper component={<FiscalCredit />} /> },
  { path: '/invoices/form-FF/:id/:total', element: <Wrapper component={<FormInvoiceFF />} /> },
  { path: '/invoices/form-CCF/:id/:total', element: <Wrapper component={<FormInvoiceCCF />} /> },
  {
    path: '/invoices/print/:id/:billType/:budgetType/:budgetId/:templete',
    element: <Wrapper component={<InvoicesPrint />} />,
  },

  // * Another views
  { path: '/show-documents', element: <ShowDocuments /> },
  { path: '/filesView/:id', element: <Wrapper component={<FileView />} /> },
  { path: '/customer-interaction', element: <CustomerInteractionContainer /> },
  { path: '/documents/print/:id', element: <Wrapper component={<DocumentPrint />} /> },

  // * Error 404 page
  { path: '/*', element: <Error404 /> },
];

export default allRoutes;
