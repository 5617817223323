import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// @MUI
import { Table, TableFooter, TablePagination, TableRow } from '@mui/material';

// @Interfaces
interface pagProps {
  count: number;
  setPage: React.Dispatch<React.SetStateAction<number>> | ((pageNum: number) => void);
  page: number;
  setPageSize: React.Dispatch<React.SetStateAction<number>> | ((pageSizeNum: number) => void);
  limitPagination?: number[];
  pageSize: number;
}

/**
 * @component
 * @subcategory Global
 *
 * @description
 * A pagination component.
 * #### Example
 * ```
 * return (
 *  <Pagination count={100} setPage={(pageNum) => console.log(pageNum)} page={1} setPageSize={(pageSize) => console.log(pageSize)} pageSize={10} />
 * )
 * ```
 *
 * @property {pagProps} props The component accepts count, setPage, page, setPageSize, limitPagination, and pageSize as props
 * @property {number} props.count The total count of items to be paginated
 * @property {function} props.setPage A function to set the current page
 * @property {number} props.page The current page number
 * @property {function} props.setPageSize A function to set the page size
 * @property {number[]} [props.limitPagination] Optional array of limited pagination options
 * @property {number} props.pageSize The current page size
 *
 * @returns {JSX.Element} The rendered pagination component.
 * @author CML Exports Front-End Developers
 */
const Pagination = ({ count, setPage, page, setPageSize, limitPagination, pageSize }: pagProps) => {
  const { t } = useTranslation(['common']);

  const newPage = (e: unknown, newPage: number) => setPage(newPage);

  const handlePageSize = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPageSize(parseInt(e.target.value));
  };

  useEffect(() => {
    newPage(undefined, 0);
  }, [pageSize]);

  return (
    <Table sx={{ borderTop: '2px solid #ccc' }}>
      <TableFooter>
        <TableRow>
          <TablePagination
            sx={{ width: '100vw' }}
            count={count}
            page={page}
            rowsPerPage={pageSize}
            onPageChange={newPage}
            onRowsPerPageChange={handlePageSize}
            rowsPerPageOptions={limitPagination?.length ? limitPagination : [5, 10, 25, 50]}
            labelRowsPerPage={t('filters.rowsPerPage')}
          />
        </TableRow>
      </TableFooter>
    </Table>
  );
};

export default Pagination;
