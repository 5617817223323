// ** React Imports
import { useState, SyntheticEvent } from 'react';
import { useParams } from 'react-router-dom';
import { socket } from '../../../../utils/sockets.util';

// ** MUI Imports
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Box, { BoxProps } from '@mui/material/Box';

// ** Icons Imports
// import Microphone from 'mdi-material-ui/Microphone';
import Attachment from 'mdi-material-ui/Attachment';

// ** Types
import { SendMsgComponentType } from '../../../../models';

import { Dialog } from '../../../../components';
import FileUpload from '../../../../components/FileUploader/FileUpload';
import { CustomFiles } from '../../../../models/CustomFiles';

// ** Styled Components
const ChatFormWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  boxShadow: theme.shadows[1],
  padding: theme.spacing(1.25, 4),
  justifyContent: 'space-between',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.paper,
}));

const Form = styled('form')(({ theme }) => ({
  padding: theme.spacing(0, 5, 5),
}));

const SendMsgForm = (props: SendMsgComponentType) => {
  // ** Props
  const { store, dispatch, sendMsg, idUser } = props;

  // ** State
  const [msg, setMsg] = useState<string>('');
  const { id: idBudget } = useParams<{ id: string }>();
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState<CustomFiles>(null);

  const toggle = () => {
    setOpen(prev => !prev);
  };

  const handleSendMsg = (e: SyntheticEvent) => {
    e.preventDefault();
    if (!idBudget) return null;

    if (store && store.selectedChat && msg.length) {
      dispatch(sendMsg({ ...store.selectedChat, message: msg, idBudget: parseInt(idBudget), idUser }));
    }
    setMsg('');
  };

  const handleSendFile = async () => {
    if (file) {
      let filename;
      if ('name' in file) {
        // file es de tipo File
        filename = file.name;
      } else if ('fileName' in file) {
        // file es de tipo fileBase64
        filename = file.fileName;
      }
      const data = {
        idBudget,
        idUser,
        idSupplier: store.selectedChat?.contact.id,
        file: { buffer: file, filename },
      };

      socket.emit('new-file-supplier', data);
      setOpen(!open);
      setFile(null);
    }
  };

  return (
    <Form onSubmit={handleSendMsg}>
      <ChatFormWrapper>
        <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
          <TextField
            fullWidth
            // multiline
            value={msg}
            size='small'
            placeholder='Type your message here…'
            onChange={e => setMsg(e.target.value)}
            sx={{ '& .MuiOutlinedInput-input': { pl: 0 }, '& fieldset': { border: '0 !important' } }}
          />
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {/* <IconButton size='small' sx={{ color: 'text.primary' }}>
            <Microphone sx={{ fontSize: '1.375rem' }} />
          </IconButton> */}
          <IconButton
            size='small'
            component='label'
            htmlFor='upload-img'
            sx={{ mr: 4, color: 'text.primary' }}
            onClick={() => toggle()}
          >
            <Attachment sx={{ fontSize: '1.375rem' }} />
            {/* <input hidden type='file' id='upload-img' onChange={async e => await handleSendFile(e)} /> */}
          </IconButton>
          <Button type='submit' variant='contained'>
            Send
          </Button>
          <Dialog handleClose={toggle} open={open}>
            <FileUpload file={file} setFile={setFile} />
            <Box
              sx={{
                m: '1rem',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Button variant='contained' onClick={handleSendFile}>
                Enviar
              </Button>
            </Box>
          </Dialog>
        </Box>
      </ChatFormWrapper>
    </Form>
  );
};

export default SendMsgForm;
