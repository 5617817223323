// @Utils
import { axiosEstimates } from '../../../utils';

// @Interfaces and Types
import { IExpensesForm } from '../../../models';
import { expensesResponseType } from '../types';

const editExpenses = async (id: string, data: IExpensesForm, expenseDocumentFile: File | null) => {
  try {
    const response = await axiosEstimates.put<expensesResponseType>(`expenses/${id}`, data);

    if (expenseDocumentFile) {
      const formData = new FormData();
      formData.append('expenseDocumentFile', expenseDocumentFile);

      await axiosEstimates.put(`expense-file/${id}?code=${data.code}`, formData);
    }

    return response;
  } catch (err) {
    console.error(err);
  }
};

export default editExpenses;
