import { useTranslation } from 'react-i18next';

// @Interfaces
interface IProps {
  text?: string;
  file?: string;
}

/**
 * @component
 * @subcategory Global
 *
 * @description
 * Allows translate any key path from any translation file.
 * #### Example
 * ```
 * return (
 *  <Translations text="general.confirm" file="common" />
 * )
 * ```
 *
 * @property {string} [text] The key path to translate.
 * @property {"common" | "validations" | "placeholder"} [file=common] File to search the translation.
 *
 * @return {string} Text translated
 * @author CML Exports Front-End Developers
 */
const Translations = ({ text, file = 'common' }: IProps) => {
  const { t } = useTranslation([file]);

  return t(`${text}`);
};

export default Translations;
