import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import toast from 'react-hot-toast';

// @Hooks
import { useForm } from 'react-hook-form';

// @Utils
import { AxiosError } from 'axios';
import { axiosEstimates } from '../../../utils/api';

// @Models
import { PdfConfiguration } from '../../../models';
import { CustomFiles, fileBase64 } from '../../../models/CustomFiles';

const initialValues: Omit<PdfConfiguration, 'id' | 'files'> = {
  name: '',
  address: '',
  bankAccount: '',
  email: '',
  phone: '',
  title: '',
  view: 10,
  header: '',
  terms: '',
  lenguage: 'es',
  bankAccountEN: '',
  headerEN: '',
  termsEN: '',
};

export default function () {
  const [name, setName] = useState('');
  const [view, setView] = useState<number>();
  const [lenguage, setLenguage] = useState('es');
  const [formChange, setFormChange] = useState(false);
  const [currentData, setCurrentData] = useState<object | null>(null);

  const [imgSrc, setImgSrc] = useState<CustomFiles>(null);
  const [imgSign, setImgSign] = useState<CustomFiles>(null);
  const [imgStamp, setImgStamp] = useState<CustomFiles>(null);

  const [contents, setContents] = useState({
    header: '',
    terms: '',
    bank: '',
    headerEN: '',
    termsEN: '',
    bankEN: '',
  });

  const { control, handleSubmit, watch, getValues, reset } = useForm<Omit<PdfConfiguration, 'id'>>({
    defaultValues: initialValues,
    reValidateMode: 'onChange',
  });

  const { id } = useParams();

  const { t } = useTranslation(['common']);

  const navigate = useNavigate();

  const headerRef = useRef(null);
  const termsRef = useRef(null);
  const bankRef = useRef(null);
  const headerENRef = useRef(null);
  const termsENRef = useRef(null);
  const bankENRef = useRef(null);

  const handleNavigate = () => navigate('/pdfSettings');
  const handlelanguage = (value: string) => setLenguage(value);

  const setContent = (content: keyof typeof contents, value: string) => {
    contents[content] = value;
    setContents(contents);
  };

  const getConfiguration = async () => {
    try {
      if (id) {
        const response = await axiosEstimates.get('/pdfConfig/' + id);

        const dataConfig: PdfConfiguration = response.data.getConfig;
        const dataFiles: fileBase64[] = response.data.files;

        setImgSrc(dataFiles.find(file => file.fileName.includes('image') && file) || null);
        setImgSign(dataFiles.find(file => file.fileName.includes('sign') && file) || null);
        if (dataFiles[2]) setImgStamp(dataFiles.find(file => file.fileName.includes('stamp') && file) || null);

        setName(dataConfig.name);
        setView(dataConfig.view);

        setContent('terms', dataConfig.terms);
        setContent('header', dataConfig.header);
        setContent('bank', dataConfig.bankAccount);
        setContent('termsEN', dataConfig.termsEN);
        setContent('headerEN', dataConfig.headerEN);
        setContent('bankEN', dataConfig.bankAccountEN);
      }
    } catch (err) {
      console.error(err);
      toast.error('Error');
    }
  };

  const handleCreate = async (body: Omit<PdfConfiguration, 'id'>) => {
    try {
      const data = new FormData();

      if (!imgSrc) return toast.error('Debe subir una imagen');
      if (!imgSign && body.view === 1) return toast.error('Debe subir la firma');
      if (!imgStamp && body.view === 1) return toast.error('Debe subir el sello');
      if (body.view === 10) return toast.error('No se ha seleccionado niguna vista');

      if (imgSrc instanceof File) data.append('image', imgSrc);
      if (imgSign instanceof File) data.append('sign', imgSign);
      if (imgStamp && imgStamp instanceof File) data.append('stamp', imgStamp);

      data.append('name', body.name);
      data.append('title', body.title);
      data.append('header', body.header);
      data.append('terms', body.terms);
      data.append('bankAccount', body.bankAccount);
      data.append('headerEN', body.headerEN);
      data.append('termsEN', body.termsEN);
      data.append('bankAccountEN', body.bankAccountEN);
      data.append('view', String(body.view));

      await axiosEstimates.post('/pdfConfig', data);

      navigate('/pdfSettings');
    } catch (err) {
      onError(err);
    }
  };

  const handleUpdate = async (body: Omit<PdfConfiguration, 'id'>) => {
    try {
      const data = new FormData();

      if (imgSrc instanceof File) data.append('image', imgSrc);
      if (imgSign instanceof File) data.append('sign', imgSign);
      if (imgStamp && imgStamp instanceof File) data.append('stamp', imgStamp);

      data.append('name', body.name === '' ? name : body.name);
      data.append('title', body.title);
      data.append('header', body.header);
      data.append('terms', body.terms);
      data.append('bankAccount', body.bankAccount);
      data.append('view', body.view === 10 ? String(view) : String(body.view));
      data.append('headerEN', body.headerEN);
      data.append('termsEN', body.termsEN);
      data.append('bankAccountEN', body.bankAccountEN);

      await axiosEstimates.put(`/pdfConfigUpdate/${id}`, data);

      navigate('/pdfSettings');
    } catch (err) {
      onError(err);
    }
  };

  const onSubmit = async (data: Omit<PdfConfiguration, 'id'>) => {
    data.terms = contents.terms;
    data.header = contents.header;
    data.bankAccount = contents.bank;
    data.termsEN = contents.termsEN;
    data.headerEN = contents.headerEN;
    data.bankAccountEN = contents.bankEN;

    if (id) await handleUpdate(data);
    else await handleCreate(data);
  };

  const onError = (err: any) => {
    if (err instanceof AxiosError) {
      const data = err.response?.data;

      if (data.validations) {
        data.validations.forEach((e: any) => {
          if (e.field === 'view') toast.error(t('settings.viewUnique'));
          else toast.error(t('settings.nameUnique'));
        });
      }
      toast.error(err.response?.data.message);
    }
  };

  // NOTE: ALL USE EFFECTS
  useEffect(() => {
    reset({ ...initialValues, name, view });
    setFormChange(!formChange);
  }, [name, view]);

  useEffect(() => {
    const current = JSON.stringify(currentData);
    const updated = JSON.stringify(getValues());

    setFormChange(current !== updated);
  }, [watch()]);

  useEffect(() => {
    getConfiguration();
    setCurrentData(getValues());
  }, []);

  useEffect(() => {
    handlelanguage(i18next.language);
  }, [t]);

  return {
    t,
    control,
    handleSubmit: handleSubmit(onSubmit),
    handleNavigate,
    imgSrc,
    setImgSrc,
    imgSign,
    setImgSign,
    imgStamp,
    setImgStamp,
    bankRef,
    headerRef,
    termsRef,
    headerENRef,
    termsENRef,
    bankENRef,
    contents,
    setContent,
    lenguage,
    formChange,
  };
}
