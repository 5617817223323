// @MUI Components
import { Box, IconButton, TableCell, TableRow, Typography } from '@mui/material/';

// @MUI Icons
import DeleteOutline from 'mdi-material-ui/DeleteOutline';
import PencilOutline from 'mdi-material-ui/PencilOutline';

// @Models
import { IShipmentService } from '../../../../models';

interface IRowProps {
  handleRemoveService: (value: number) => void;
  handleView: (value: IShipmentService) => void;
  row: any;
}

export default function Row({ row, handleRemoveService, handleView }: IRowProps) {
  return (
    <TableRow>
      <TableCell align='center' component='th'>
        <Typography noWrap>{row.content}</Typography>
      </TableCell>

      <TableCell align='center'>
        <Typography noWrap sx={{ textTransform: 'capitalize' }}>
          {row.unitOfMeasurement}
        </Typography>
      </TableCell>

      <TableCell align='center'>
        <Typography noWrap sx={{ textTransform: 'capitalize' }}>
          {row.length}x{row.width}x{row.high}
        </Typography>
      </TableCell>

      <TableCell align='center'>
        <Typography noWrap sx={{ textTransform: 'capitalize' }}>
          {row.insurance}
        </Typography>
      </TableCell>

      <TableCell align='center'>
        <Typography noWrap sx={{ textTransform: 'capitalize' }}>
          {row.physicalWeight}
        </Typography>
      </TableCell>

      <TableCell align='center'>
        <Typography noWrap sx={{ textTransform: 'capitalize' }}>
          {row.area}
        </Typography>
      </TableCell>

      <TableCell align='center'>
        <Typography noWrap sx={{ textTransform: 'capitalize' }}>
          {row.volumetricWeight}
        </Typography>
      </TableCell>

      <TableCell align='center'>
        <Box sx={{ width: { xs: 68, md: '100%' } }}>
          <IconButton size='small' color='info' onClick={() => handleView(row)}>
            <PencilOutline />
          </IconButton>

          <IconButton size='small' color='error' onClick={() => handleRemoveService(row.id)}>
            <DeleteOutline />
          </IconButton>
        </Box>
      </TableCell>
    </TableRow>
  );
}
