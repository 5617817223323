import { Box, IconButton, Typography, Divider } from '@mui/material';
import ListItem, { ListItemProps } from '@mui/material/ListItem';
import { styled } from '@mui/material/styles';
import List from '@mui/material/List';
import { useState } from 'react';
import { Icon } from '@iconify/react';
import EmailModal from './utils/editEmailModal';
import EmailViewModal from './utils/viewEmailModal';
export type MailType = {
  id: number;
  message: string;
  subject: string;
  isRead: boolean;
  name: string;
  time: string;
};
const MailItem = styled(ListItem)<ListItemProps>(({ theme }) => ({
  zIndex: 1,
  cursor: 'pointer',
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(3),
  justifyContent: 'space-between',
  transition: 'all .25s ease-in-out',
  [theme.breakpoints.up('xs')]: {
    paddingLeft: theme.spacing(2.5),
    paddingRight: theme.spacing(2.5),
  },
  [theme.breakpoints.up('sm')]: {
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
  },
}));
export const CustomEmails = () => {
  const [show, setShow] = useState<boolean>(false);
  const [view, setView] = useState<boolean>(false);
  const handleShow = (render: boolean) => {
    setShow(render);
  };
  const handleView = (render: boolean) => {
    setView(render);
  };
  const emailstest = [
    {
      id: 1,
      name: 'correo cotizacion',
      message: 'hello',
      subject: 'karla',
      isRead: true,
      time: '10:00',
    },
    {
      id: 2,
      name: 'correo admi',
      message: 'hello',
      subject: 'ivan',
      isRead: true,
      time: '10:00',
    },
    {
      id: 3,
      name: 'correo proveedores',
      message: 'hello',
      subject: 'admin',
      isRead: true,
      time: '10:00',
    },
    {
      id: 4,
      name: 'correo servicios',
      message: 'hello',
      subject: 'karla',
      isRead: true,
      time: '10:00',
    },
  ];
  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }} />
      <Box>
        <List sx={{ p: 0 }}>
          {emailstest.map((mail: MailType, index: number) => {
            return (
              <Box
                key={mail.id}
                sx={{
                  '&:hover': {
                    zIndex: 2,
                    boxShadow: '3',
                    transform: 'translateY(-2px)',
                    '& .mail-info-right': {
                      display: 'none',
                    },
                    '& .mail-actions': {
                      display: 'flex',
                    },
                  },
                }}
              >
                <MailItem sx={{ backgroundColor: mail.isRead ? 'action.hover' : 'background.paper' }}>
                  <Box sx={{ mr: 4, display: 'flex', overflow: 'hidden', alignItems: 'center' }}>
                    <Box
                      sx={{
                        display: 'flex',
                        overflow: 'hidden',
                        flexDirection: { xs: 'column', sm: 'row' },
                        alignItems: { xs: 'flex-start', sm: 'center' },
                      }}
                    >
                      <IconButton sx={{ mr: 4 }} onClick={() => setShow(!show)} size='small' color='warning'>
                        <Icon icon='fluent:mail-edit-20-regular' width='25' />
                      </IconButton>
                      <Typography
                        sx={{
                          mr: 4,
                          fontWeight: 500,
                          whiteSpace: 'nowrap',
                          width: ['100%', 'auto'],
                          overflow: ['hidden', 'unset'],
                          textOverflow: ['ellipsis', 'unset'],
                        }}
                      >
                        {mail.name}
                      </Typography>
                      <Typography noWrap variant='body2' sx={{ width: '100%' }}>
                        {mail.subject}
                      </Typography>
                    </Box>
                  </Box>
                  <Box />
                  <IconButton onClick={() => handleView(!view)} size='small' color='info'>
                    <Icon icon='ic:baseline-remove-red-eye' width='25' />
                  </IconButton>
                </MailItem>
                {emailstest !== null && emailstest.length - 1 > index ? <Divider sx={{ my: 0, mx: -5 }} /> : null}
              </Box>
            );
          })}
        </List>
        <EmailModal open={show} handleClose={() => handleShow(false)} />
        <EmailViewModal open={view} handleClose={() => setView(false)} />
      </Box>
    </Box>
  );
};
