import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

// @MUI Components
import { Box, Select, InputLabel, MenuItem, FormControl, FormHelperText, Chip } from '@mui/material';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
// @Interfaces and Types
interface IProps {
  items: { label: string; value: string | number }[];
  control: any;
  name: string;
  label: string;
  chipsColor?: 'default' | 'error' | 'primary' | 'secondary' | 'warning' | 'info' | 'success';
  chipsVariant?: 'filled' | 'outlined';
}

/**
 * @component
 * @subcategory Global
 *
 * @description
 * A multiple select dropdown component with chips to display the selected options.
 * #### Example
 * ```jsx
 * import MultipleSelectChip from './MultipleSelectChip';
 *
 * const MyComponent = () => {
 *   return (
 *     <MultipleSelectChip items={colors} control={control} name={"panel-colors"} label={"Panel colors"} chipsColor="warning" chipsVariant="filled" />
 *   );
 * };
 * ```
 *
 * @property {Array<{label: string, value: string | number}>} items - The items to be displayed in the select dropdown.
 * @property {any} control - The control object from react-hook-form.
 * @property {string} name - The name of the field.
 * @property {string} label - The label for the select dropdown.
 * @property {'default' | 'error' | 'primary' | 'secondary' | 'warning' | 'info' | 'success'} [chipsColor='info'] - The color of the chips.
 * @property {'filled' | 'outlined'} [chipsVariant='filled'] - The variant of the chips.
 *
 * @returns {JSX.Element} The MultipleSelectShip input component.
 * @author CML Exports Front-End Developers
 */
export default function MultipleSelectChip({ items, control, name, label, chipsColor, chipsVariant }: IProps) {
  const { t } = useTranslation('common');

  return (
    <Box sx={{ width: '100%' }}>
      <Controller
        name={name}
        control={control}
        render={({ field: { value, ...othersFields }, fieldState: { error } }) => (
          <FormControl fullWidth>
            <InputLabel error={Boolean(error?.message)}>{label || 'Select a option'}</InputLabel>
            <Select
              multiple
              value={value}
              label={label || 'Select a option'}
              error={Boolean(error?.message)}
              renderValue={value => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {value.map((opt: any) => (
                    <Chip
                      key={opt}
                      label={opt.toUpperCase()}
                      variant={chipsVariant || 'filled'}
                      color={chipsColor || 'info'}
                      sx={{ fontWeight: '600' }}
                    />
                  ))}
                </Box>
              )}
              MenuProps={MenuProps}
              {...othersFields}
            >
              {items.map(item => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText sx={{ color: 'error.main' }}>{t(error?.message || '')}</FormHelperText>
          </FormControl>
        )}
      />
    </Box>
  );
}
