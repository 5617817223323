import { useContainer } from '../hooks';

// @Mui
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';

// @Views
import { SupplierFilter, ShipmentList, SendModal } from '../views';

// @Components
import { ButtonForm } from '../../../../components';
import Loader from '../../../../components/Loader';
import Error from '../../../../components/Error';

export function SendEmailContainer() {
  const {
    t,
    loading,
    error,
    control,
    addNewOption,
    emailTo,
    handleAddEmail,
    menuItemsArr,
    handleSelectShipment,
    selectedShipments,
    shipments,
    handleSendMail,
    id,
    open,
    toggle,
    navigate,
    transportations,
  } = useContainer();

  if (loading) return <Loader />;

  if (error) return <Error icon description={typeof error === 'string' ? error : 'Error'} />;

  return (
    <Grid container sx={{ width: '90%', ml: 'auto', mr: { xs: 0.5, md: 'auto' }, zIndex: 1 }}>
      <Grid item xs={12} sx={{ mx: 'auto' }}>
        <Card sx={{ height: '100%' }}>
          <CardHeader
            sx={{ display: 'flex', textAlign: 'center' }}
            title={t('budgetPage.sendRequestToSupplier', { ns: 'common' })}
            titleTypographyProps={{ variant: 'h6' }}
          />

          <Divider sx={{ margin: 0 }} />

          {open ? (
            <SendModal
              emailTo={emailTo}
              idBudget={id || '0'}
              open={open}
              selectedShipments={selectedShipments}
              toggle={toggle}
              navigate={navigate}
            />
          ) : (
            <CardContent sx={{ width: '100%', height: '90%', gap: 10 }}>
              <SupplierFilter
                control={control}
                addNewOption={addNewOption}
                emailTo={emailTo}
                handleAddEmail={handleAddEmail}
                menuItemsArr={menuItemsArr}
                transportations={transportations}
              />

              <ShipmentList
                shipments={shipments}
                handleSelectShipment={handleSelectShipment}
                selectedRows={selectedShipments}
              />

              <Box sx={{ display: 'flex', justifyContent: 'space-around', my: 4 }}>
                <ButtonForm label={t('general.back')} onClick={() => history.back()} color='error' />
                <ButtonForm
                  label={t('general.next')}
                  onClick={handleSendMail}
                  color='success'
                  disabled={emailTo.length === 0}
                />
              </Box>
            </CardContent>
          )}
        </Card>
      </Grid>
    </Grid>
  );
}
