import React, { useState } from 'react';
import { Box, Tabs, Tab } from '@mui/material';
import BookingForm from './Booking/BookingForm';
import BLForm from './BL/BLForm';
import Swal from 'sweetalert2';
import ManifiestoMaritimoForm from './ManifiestoMaritimo/ManifiestoMaritimoForm';
import { useTranslation } from 'react-i18next';
import { useDocument } from '../hooks';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel({ children, value, index, ...other }: TabPanelProps) {
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

interface FormProps {
  valueForm: number;
  subform: number;
}

const RenderFormsMaritimos = ({ valueForm }: FormProps) => {
  const [maritimpForm, setMaritimpForm] = useState(0);

  const { formChanged } = useDocument();

  const { t } = useTranslation(['common']);

  const handleChangeMaritimmoForm = (event: React.SyntheticEvent<Element, Event>, newValue: number) => {
    if (formChanged === true) {
      Swal.fire({
        title: t('budgetGuide.changeWarning.tittle') as string,
        text: t('budgetGuide.changeWarning.description') as string,
        icon: 'warning',
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: t('budgetGuide.changeWarning.confirmButton') as string,
        confirmButtonColor: '#56CA00',
        cancelButtonColor: '#FF4C51',
        cancelButtonText: t('budgetGuide.changeWarning.cancelButton') as string,
      }).then(result => {
        if (result.isConfirmed) {
          // if (newValue === 0) navigate('/budgets/guides/' + params.id + '/guia-maritima' + '/booking');
          // if (newValue === 1) navigate('/budgets/guides/' + params.id + '/guia-maritima' + '/bl');
          // if (newValue === 2) navigate('/budgets/guides/' + params.id + '/guia-maritima' + '/manifiesto');
          setMaritimpForm(newValue);
        }
      });
    } else {
      // if (newValue === 0) navigate('/budgets/guides/' + params.id + '/guia-maritima' + '/booking');
      // if (newValue === 1) navigate('/budgets/guides/' + params.id + '/guia-maritima' + '/bl');
      // if (newValue === 2) navigate('/budgets/guides/' + params.id + '/guia-maritima' + '/manifiesto');
      setMaritimpForm(newValue);
    }
  };

  return (
    <TabPanel value={valueForm} index={1}>
      <Tabs
        value={maritimpForm}
        onChange={handleChangeMaritimmoForm}
        sx={{ borderBottom: 1, borderColor: 'divider' }}
        variant='scrollable'
        scrollButtons
        allowScrollButtonsMobile
        aria-label='basic tabs example'
      >
        <Tab label={t('budgetGuide.secondTabFirstSubtittle')} />
        <Tab label={t('budgetGuide.secondTabSecondSubtittle')} />
        <Tab label={t('budgetGuide.secondTabThirdSubtittle')} />
      </Tabs>

      <br />

      <TabPanel value={maritimpForm} index={0}>
        <BookingForm />
      </TabPanel>
      <TabPanel value={maritimpForm} index={1}>
        <BLForm />
      </TabPanel>
      <TabPanel value={maritimpForm} index={2}>
        <ManifiestoMaritimoForm />
      </TabPanel>
    </TabPanel>
  );
};

export default RenderFormsMaritimos;
