import { createSlice, PayloadAction, AnyAction, AsyncThunk } from '@reduxjs/toolkit';

// @Models
import { Loading, ISuppliersRequest, ISuppliersState } from '../../../models';

// @Actions
import { getSuppliers, getSuppliersByTransport } from './actions-async';

// @AsyncThunk
type GenericAsyncThunk = AsyncThunk<unknown, unknown, any>;
type PendingAction = ReturnType<GenericAsyncThunk['pending']>;
type RejectedAction = ReturnType<GenericAsyncThunk['rejected']>;

const isLoaderAction = (action: AnyAction): action is PendingAction => {
  return action.type.startsWith('user/') && action.type.endsWith('/pending');
};

const LOADING_INIT: Loading = {
  show: false,
  type: 'screen',
};

export const initialState: ISuppliersState = {
  loading: LOADING_INIT,
  errors: {
    error: false,
    text: [],
  },
  suppliers: [],
  supplierItems: [],
};

export const suppliers = createSlice({
  name: 'suppliers',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getSuppliers.fulfilled, (state, action: PayloadAction<ISuppliersRequest[]>) => {
      state.suppliers = action.payload;
    });
    builder.addCase(getSuppliersByTransport.fulfilled, (state, action: PayloadAction<ISuppliersRequest[]>) => {
      // state.suppliers = action.payload;
      state.supplierItems = action.payload.map(supplier => {
        return {
          value: supplier.id,
          label: supplier.name,
        };
      });
    });
    // Matcher para saber cuando una petición isLoading
    builder.addMatcher(isLoaderAction, state => {
      state.errors = {
        text: [],
        error: false,
      };

      state.loading = {
        show: true,
        type: 'screen',
      };
    });
    // Matcher para saber cuando una petición es rejected
    builder.addMatcher(
      (action): action is RejectedAction => action.type.startsWith('user/') && action.type.endsWith('/rejected'),
      (state, action) => {
        state.loading = LOADING_INIT;
        state.errors = {
          text: ['Ocurrió un error al procesar tu solicitud'],
          error: true,
        };
      },
    );
  },
});

// Action creators are generated for each case reducer function
// export const { quotInfo, setServices } = suppliers.actions;
export default suppliers.reducer;
