import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { axiosEstimates } from '../../../utils';
import { ConfigCodeMany } from '../../../models/config-code';
import { schemaValidation } from '../utils/schema-validation';
import { yupResolver } from '@hookform/resolvers/yup';
import Swal from 'sweetalert2';

export default function useInvoiceSetting(type: number | undefined = 1) {
  const [show, setShow] = useState<boolean>(false);
  const [data, setdata] = useState<any>();
  const [isUpdate, setisUpdate] = useState<boolean>(false);
  const { control, handleSubmit, setValue } = useForm<ConfigCodeMany>({
    resolver: yupResolver(schemaValidation),
  });
  const handleShow = (render: boolean) => {
    setShow(render);
    resetValues();
  };

  const { t } = useTranslation(['common']);

  const handleGet = async () => {
    try {
      const { data } = await axiosEstimates.get('api/config-code/' + type, {
        headers: {
          'x-module': 'tax_folios',
        },
      });
      setdata(data);

      if (data?.data?.configCode[0]?.maxFolio) {
        setValue('minFolio', data?.data?.configCode[0]?.maxFolio + 1 || 1);
      } else setValue('minFolio', 1);
    } catch (err) {
      console.error(err);
    }
  };
  useEffect(() => {
    handleGet();
  }, [type]);

  const resetValues = () => {
    setValue('maxFolio', 0);
    setValue('authorizationNumber', '');
  };

  const updateValues = () => {
    setValue('maxFolio', data?.data?.configCode[0]?.maxFolio || 0);
    setValue('authorizationNumber', data?.data?.configCode[0]?.authorizationNumber || '');
    setValue('authorizationDate', data?.data?.configCode[0]?.authorizationDate || '');
    setValue('minFolio', data?.data?.configCode[0]?.minFolio || 0);
  };

  const handleShowUpdate = (render: boolean) => {
    setShow(render);
    updateValues();
    setisUpdate(true);
  };

  const handleCreate = async (body: ConfigCodeMany) => {
    const data = {
      minFolio: body.minFolio,
      maxFolio: body.maxFolio,
      folioType: type,
      authorizationNumber: body.authorizationNumber,
      authorizationDate: body.authorizationDate,
      status: 1,
      lastFolio: body.minFolio,
    };
    try {
      await axiosEstimates.post('/api/config-code/', data);
      Swal.fire({
        icon: 'success',
        title: t('alert.success', { ns: 'common' }) || '',
        text: t('alert.supplier.success', { ns: 'common' }) || '',
        showCloseButton: true,
      });
      handleGet();
      setShow(false);
      resetValues();
    } catch (err) {
      Swal.fire({
        icon: 'success',
        title: t('alert.error', { ns: 'common' }) || '',
        text: t('alert.error', { ns: 'common' }) || '',
        showCloseButton: true,
      });
    }
  };

  const handleUpdate = async (body: ConfigCodeMany) => {
    try {
      await axiosEstimates.put('/api/config-code/' + data?.data?.configCode[0]?.id, body);
      Swal.fire({
        icon: 'success',
        title: t('alert.success', { ns: 'common' }) || '',
        text: t('alert.supplier.success', { ns: 'common' }) || '',
        showCloseButton: true,
      });
      handleGet();
      setShow(false);
      resetValues();
    } catch (err) {
      Swal.fire({
        icon: 'success',
        title: t('alert.error', { ns: 'common' }) || '',
        text: t('alert.error', { ns: 'common' }) || '',
        showCloseButton: true,
      });
    }
  };

  const onSubmit = async (data: ConfigCodeMany) => {
    if (!isUpdate) {
      await handleCreate(data);
    } else {
      await handleUpdate(data);
    }
  };

  return {
    t,
    control,
    handleSubmit: handleSubmit(onSubmit),
    data,
    handleGet,
    show,
    handleShow,
    handleShowUpdate,
  };
}
