// @Models
import { IBillResponse } from '../models/Models';

// @Utils
import { axiosEstimates } from '../../../utils/api';

export async function getInvoice(id: number | string, billType: string) {
  const xModule = () => {
    if (billType === '4') return 'ctis_invoices';
    else if (billType === '3') return 'cml_usa_invoices';
    else return 'cml_sv_invoices';
  };

  const res = await axiosEstimates.get(`/api/get-bill/${id}`, {
    headers: { 'x-module': xModule() },
  });

  const data = res.data.data;
  return data;
}

export async function getInvoices(
  billType: number[],
  page: number,
  folio?: string,
  customerId?: number,
): Promise<IBillResponse> {
  const xModule = () => {
    if (billType[0] === 4) return 'ctis_invoices';
    else if (billType[0] === 3) return 'cml_usa_invoices';
    else return 'cml_sv_invoices';
  };

  const res = await axiosEstimates.get(`/api/get-bills/${billType}?page=${page}&folio=${folio}&cusId=${customerId}`, {
    headers: { 'x-module': xModule() },
  });

  const data = res.data.data;
  return data;
}

export async function updateStatusInvoice(id: number) {
  const res = await axiosEstimates.patch(`api/bill/${id}`);
  const data = res.data.data;
  return data;
}

export async function getBillsCustomers(billType: number[]) {
  const res = await axiosEstimates.get(`api/bills/customers?billType=${billType}`);
  const data = res.data.data;
  return data;
}
