import { Button, Card, CardContent, Grid, Typography } from '@mui/material';
import React from 'react';
import useGAReservas from './use-GAReservas';
import { DatePicker, TextForm } from '../../../../components';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const GAReservasForm = () => {
  const location = useLocation();
  const { control, onSubmit, documentId, navigate } = useGAReservas();

  const { t, i18n } = useTranslation('common');

  return (
    <Card>
      <CardContent>
        <Grid container spacing={2} rowGap={2}>
          <Grid item xs={12} sm={6}>
            <TextForm
              translationFile='validations'
              control={control}
              label={t('airGuide.invoiceOperation', { ns: 'placeholder' })}
              name='invoiceOperation'
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextForm
              translationFile='validations'
              control={control}
              label={t('BL.masterGuide', { ns: 'placeholder' })}
              name='masterGuide'
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextForm
              translationFile='validations'
              control={control}
              label={t('BL.hawbGuide', { ns: 'placeholder' })}
              name='hawbGuide'
            />
          </Grid>
          <Grid item xs={12} sm={6} />

          <Grid item xs={12} sm={6}>
            <Typography sx={{ textAlign: 'center' }} variant={'subtitle1'}>
              {t('airGuide.shipper', { ns: 'placeholder' })}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography sx={{ textAlign: 'center' }} variant={'subtitle1'}>
              {t('pdfs.consignatario', { ns: 'placeholder' })}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextForm
              translationFile='validations'
              control={control}
              label={t('airGuide.name', { ns: 'placeholder' })}
              name='nameshipper'
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextForm
              translationFile='validations'
              control={control}
              label={t('airGuide.name', { ns: 'placeholder' })}
              name='nameconsignee'
              // disabled
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextForm
              translationFile='validations'
              control={control}
              label={t('airGuide.address', { ns: 'placeholder' })}
              name='addressshipper'
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextForm
              translationFile='validations'
              control={control}
              label={t('airGuide.address', { ns: 'placeholder' })}
              name='addressconsignee'
              // disabled
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextForm
              translationFile='validations'
              control={control}
              label={t('airGuide.email', { ns: 'placeholder' })}
              name='mailshipper'
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextForm
              translationFile='validations'
              control={control}
              label={t('airGuide.email', { ns: 'placeholder' })}
              name='mailconsignee'
              // disabled
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextForm
              translationFile='validations'
              control={control}
              label={t('airGuide.phone', { ns: 'placeholder' })}
              name='phoneshipper'
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextForm
              translationFile='validations'
              control={control}
              label={t('airGuide.phone', { ns: 'placeholder' })}
              name='phoneconsignee'
              // disabled
            />
          </Grid>

          <Grid item xs={12}>
            <Typography sx={{ textAlign: 'center' }} variant={'subtitle1'}>
              {t('BL.shipmentDetails', { ns: 'placeholder' })}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <TextForm
              translationFile='validations'
              control={control}
              label={t('BL.agentInOrigin', { ns: 'placeholder' })}
              name={`shipmenDetails.${0}.agentInOrigin`}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <TextForm
              translationFile='validations'
              control={control}
              label={t('BL.product', { ns: 'placeholder' })}
              name={`shipmenDetails.${0}.product`}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <TextForm
              translationFile='validations'
              control={control}
              label={t('airGuide.weight', { ns: 'placeholder' })}
              name={`shipmenDetails.${0}.weight`}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <TextForm
              translationFile='validations'
              control={control}
              label={t('BL.destination', { ns: 'placeholder' })}
              name={`shipmenDetails.${0}.destination`}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <TextForm
              translationFile='validations'
              control={control}
              label={t('BL.dimessions', { ns: 'placeholder' })}
              name={`shipmenDetails.${0}.dimessions`}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <TextForm
              translationFile='validations'
              control={control}
              label={t('BL.numOfPieces', { ns: 'placeholder' })}
              name={`shipmenDetails.${0}.numOfPieces`}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <DatePicker
              control={control}
              name={`shipmenDetails.${0}.paymentDate`}
              label={t('BL.paymentDate', { ns: 'placeholder' })}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <DatePicker
              control={control}
              name={`shipmenDetails.${0}.deadline`}
              label={t('BL.deadline', { ns: 'placeholder' })}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <DatePicker
              control={control}
              name={`shipmenDetails.${0}.dateOfFlight`}
              label={t('BL.dateOfFlight', { ns: 'placeholder' })}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'row' },
              justifyContent: 'center',
              alignItems: 'center',
              gap: 2,
            }}
          >
            <Button variant='contained' onClick={onSubmit}>
              {documentId === null
                ? i18n.language === 'es'
                  ? 'Guardar'
                  : 'Save'
                : t('budgetGuide.saveDocumentButtonTittle')}
            </Button>

            {documentId !== null && (
              <Button
                variant='contained'
                onClick={() => navigate(documentId, location.pathname + '&value=7')}
                color='secondary'
              >
                {t('budgetGuide.viewDocumentButtonTittle')}
              </Button>
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default GAReservasForm;
