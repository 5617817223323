import { useTranslation } from 'react-i18next';

// @MUI Components
import { Grid } from '@mui/material/';

// @Components
import Row from './Row';
import UploadModal from './UploadModal';
import EmptyData from '../../../../../../components/EmptyData';
import Pagination from '../../../../../../components/Pagination';
import TableComponent from '../../../../../../components/TableContainer';

// @Hooks
import useSuppliers from '../../../../hooks/useSuppliers';
import useTabBudgets from '../../../../hooks/useTabBudgets';

// @Interfaces and Types
import { SearchField } from '../../../../../../components';

export default function TabBudgets() {
  const {
    // NOTE: DATA
    budgets,
    budgetsFounds,
    searchBudget,
    currentBudgets,
    // NOTE: FORM STATUS
    show,
    // NOTE: PAGINATION
    page,
    setPage,
    pageSize,
    setPageSize,
    // NOTE: ACTIONS
    suppliersHasDocuments,
    // NOTE: HANDLE FUNCTIONS
    handleClose,
    handleSearchBudgets,
  } = useTabBudgets();
  const { permissions } = useSuppliers();

  const { t } = useTranslation('common');

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        sm={12}
        sx={{ my: 3, pb: 2, display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}
      >
        <SearchField value={searchBudget} handleFilter={handleSearchBudgets} placeholder={t('general.search')} />
      </Grid>

      {currentBudgets()?.length ? (
        <TableComponent
          Row={Row}
          data={currentBudgets()}
          headers={[t('general.invoice'), t('general.totalServices'), t('general.total'), t('general.actions')]}
          props={{ suppliersHasDocuments, permissions }}
        />
      ) : (
        <EmptyData sx={{ my: 10 }} />
      )}

      <Pagination
        count={searchBudget ? budgetsFounds.length : budgets.length}
        page={page}
        setPage={setPage}
        pageSize={pageSize}
        setPageSize={setPageSize}
      />

      <UploadModal open={show} handleClose={handleClose} />
    </Grid>
  );
}
