import { useEffect, useState } from 'react';

// @MUI Imports
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';

// @Icons Imports
import Close from 'mdi-material-ui/Close';

// @Models
import { IBudgetById } from '../../../../models';

// @Utils
import { axiosEstimates } from '../../../../utils';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/reducers';

interface Prop {
  open: boolean;
  toggle(): void;
  idBudget: number;
}

type Budget = Omit<IBudgetById, 'customer'>;

const Header = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(3, 4),
  justifyContent: 'space-between',
  backgroundColor: theme.palette.background.paper,
}));

export function RequestContent({ open, toggle, idBudget }: Prop) {
  const [loading, setLoading] = useState<boolean>(false);
  const [budget, setBudget] = useState<Budget | undefined>();
  const {
    countries: { countries },
  } = useSelector((state: RootState) => state);

  const getCountryName = (code: string) => {
    const country = countries.find(country => country.alpha2Code === code);

    return country?.name || '';
  };

  const getRequest = async () => {
    setLoading(true);
    try {
      const response = await axiosEstimates.get<Budget>('supplier-chat-get-request/' + idBudget);
      setBudget(response.data);
      setLoading(false);
    } catch (error) {
      setBudget(undefined);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (open) {
      getRequest();
    }
  }, [open]);

  return (
    <Drawer
      open={open}
      anchor='right'
      variant='temporary'
      onClose={toggle}
      ModalProps={{ keepMounted: true }}
      sx={{ '& .MuiDrawer-paper': { width: { xs: 300, sm: 400, md: 500 } } }}
    >
      <Header>
        <Typography variant='h6'>Solicitud de Cotización</Typography>
        <Close fontSize='small' onClick={toggle} sx={{ cursor: 'pointer' }} />
      </Header>
      <Box sx={{ padding: '1rem' }}>
        {!loading && budget ? (
          <Box>
            <Box>
              <Typography variant='h5' sx={{ fontWeight: 700, fontSize: '0.9rem' }}>
                ¡Buen día!
              </Typography>
              <Typography sx={{ fontWeight: 700, fontSize: '0.9rem' }}>
                Solicito amablemente de su apoyo para cotizar lo siguiente:
              </Typography>
            </Box>
            <Divider sx={{ margin: '0.5rem 0' }} />
            <Box>
              <Typography sx={{ fontWeight: 600, fontSize: '0.875rem' }}>
                Cantidad de Servicios: {budget.shipments.length}
              </Typography>
              <Typography sx={{ fontWeight: 600, fontSize: '0.875rem' }}>
                Incoterms: {budget.budget.incoterms}
              </Typography>
              <Typography sx={{ fontWeight: 600, fontSize: '0.875rem' }}>
                Origen: {getCountryName(budget.shipments[0].origin)}, {budget.shipments[0].addressOrigin}
              </Typography>
              <Typography sx={{ fontWeight: 600, fontSize: '0.875rem' }}>
                Destino: {getCountryName(budget.shipments[0].destiny)}, {budget.shipments[0].addressDestiny}
              </Typography>
            </Box>
            <Box>
              {budget.shipments.map((shipment, index) => (
                <Box key={index} sx={{ padding: '0.5rem' }}>
                  <Typography sx={{ fontWeight: 500, fontSize: '0.875rem' }}>Servicio #{index + 1}</Typography>

                  <Divider sx={{ marginBottom: '0.5rem' }} />
                  <Typography sx={{ fontWeight: 500, fontSize: '0.875rem' }}>
                    {shipment.content} con dimensiones en{' '}
                    <strong> {shipment.unitOfMeasurement === 'cmkg' ? 'CM' : 'IN'} </strong> de Largo{' '}
                    <strong> {shipment.length} </strong> Alto <strong> {shipment.high}</strong> Ancho{' '}
                    <strong> {shipment.width} </strong> peso estimado de{' '}
                    <strong>
                      {' '}
                      {shipment.physicalWeight} {shipment.unitOfMeasurement === 'cmkg' ? 'KG' : 'LB'}{' '}
                    </strong>{' '}
                    y {shipment.unitOfMeasurement === 'cmkg' ? 'CBM:' : 'Pie Cúbico:'}
                    <strong>
                      {' '}
                      {shipment.area} {shipment.unitOfMeasurement === 'cmkg' ? 'M3' : 'Ft3'}{' '}
                    </strong>
                  </Typography>
                  {shipment.insurance !== 0 && (
                    <Typography sx={{ fontWeight: 500, fontSize: '0.875rem' }}>
                      Valor factura: {shipment.insurance}
                    </Typography>
                  )}
                  {shipment.comments !== '' && (
                    <>
                      <Typography sx={{ fontWeight: 500, fontSize: '0.875rem' }}>Comentarios:</Typography>
                      <Typography variant='body2' sx={{ fontWeight: 500, fontSize: '0.875rem' }}>
                        {shipment.comments}
                      </Typography>
                    </>
                  )}
                </Box>
              ))}
            </Box>
            <br />

            <Box>
              <Typography sx={{ fontWeight: 500, fontSize: '0.875rem' }}>
                De antemano agradezco su tiempo y colaboración.
              </Typography>
              <Typography sx={{ fontWeight: 500, fontSize: '0.875rem' }}>
                Quedo a la espera de la información.
              </Typography>
              <Typography sx={{ fontWeight: 500, fontSize: '0.875rem' }}>Saludos Cordiales.</Typography>
              <br />
            </Box>
          </Box>
        ) : (
          <Stack spacing={1}>
            <Skeleton variant='text' sx={{ fontSize: '1rem' }} />
            <Skeleton variant='rounded' width={'100%'} height={40} />
            <Skeleton variant='rectangular' width={'100%'} height={40} />
            <Skeleton variant='rounded' width={'100%'} height={40} />
          </Stack>
        )}
      </Box>
    </Drawer>
  );
}
