// @Utils
import { axiosEstimates } from '../../../utils';

// @Interfaces and Types
import { InvoiceResponse, InvoiceTypeResponse } from '../types';

const getInvoice = async (id: string) => {
  const {
    data: { data: billType },
  } = await axiosEstimates.get<InvoiceTypeResponse>(`/api/get-billType/${id}`);

  const xModule = () => {
    if (billType === 4) return 'ctis_invoices';
    else if (billType === 3) return 'cml_usa_invoices';
    else return 'cml_sv_invoices';
  };

  const response = await axiosEstimates.get<InvoiceResponse>(`/api/get-bill/${id}`, {
    headers: { 'x-module': xModule() },
  });

  const { status, message, data } = response.data;

  if (status === 200) return data;
  else if (status === 401) throw response.data;
  else throw new Error(`${status} - ${message || 'Error al guardar el pago'} `);
};

export default getInvoice;
