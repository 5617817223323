import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

// @Validations
import { serviceValidationSchema } from '../validations';

// @Utils
import { axiosEstimates } from '../../../utils';
import CustomSwalAlert from '../../../utils/CustomSwalAlert';

// @Hooks
import useServiceType from './useServiceType';
import { useServices } from '../hooks/useServices';

// @Interfaces and Types
import { ServiceFormValues } from '../def';

export default function useServiceForm() {
  const [forCreateServiceType, setForCreateServiceType] = useState(false);

  const { setReloadServiceTypes } = useServiceType();

  const { setReloadData, handleToggleForm, defaultValues, serviceId, setServiceId } = useServices();

  const { t } = useTranslation('common');

  const { control, handleSubmit, formState, setValue, getValues, setError, clearErrors } = useForm<ServiceFormValues>({
    resolver: yupResolver(serviceValidationSchema),
    defaultValues,
  });

  const onSubmit = handleSubmit(async (data: ServiceFormValues) => {
    const { serviceType, categoryId } = data;

    if (!serviceType) {
      return setError('serviceType', { message: 'service.serviceType' });
    } else if (!categoryId.length) {
      return setError('categoryId', { message: 'service.category' });
    }

    let message = '';

    try {
      if (serviceId) {
        const { status } = await axiosEstimates.put(`api/services/${serviceId}`, data);
        setReloadServiceTypes(true);
        setForCreateServiceType(false);

        if (status === 200) message = t('services.alerts.updatedService');
      } else {
        const { status } = await axiosEstimates.post('api/services/create', data);

        if (status === 200) message = t('services.alerts.createdService');
        setServiceId(undefined);
      }
    } catch (error) {
      console.error(error);
    } finally {
      clearForm();
      setReloadData(true);
      handleToggleForm();
      setForCreateServiceType(false);
      CustomSwalAlert(t('general.success'), message, 'success', false);
    }
  });

  const clearForm = () => {
    setValue('categoryId', []);
    setValue('serviceName', '');
    setValue('serviceNameEN', '');
    setValue('serviceType', { name: '', nameEN: '' });
    clearErrors();
  };

  useEffect(() => {
    setValue('serviceName', defaultValues?.serviceName || '');
    setValue('serviceNameEN', defaultValues?.serviceNameEN || '');
    setValue('serviceType', defaultValues?.serviceType || 0);
    setValue('categoryId', defaultValues?.categoryId || []);
  }, [defaultValues]);

  return {
    Controller,
    onSubmit,
    control,
    formState,
    clearForm,
    getValues,
    setValue,
    forCreateServiceType,
    setForCreateServiceType,
  };
}
