/**
 * @category Utils
 *
 * @description
 * Converts a base64 string to a file.
 *
 * Example
 * ```
 * const file = dataURLtoFile('iVBORw0KGg...', 'image.jpg');
 * ```
 *
 * @param {string} string64 The base64 string to convert.
 * @param {string} fileName The name of the file to create.
 *
 * @return {File} The created file.
 * @author CML Exports Front-End Developers
 */
export default function dataURLtoFile(string64: string, fileName: string) {
  const trimmedString = string64.split(',');
  const imageContent = atob(trimmedString[1]);
  // const imageContent = Buffer.from([trimmedString], 'base64');
  const buffer = new ArrayBuffer(imageContent.length);
  const view = new Uint8Array(buffer);

  for (let n = 0; n < imageContent.length; n++) {
    view[n] = imageContent.charCodeAt(n);
  }
  const type = 'image/jpeg';
  const blob = new Blob([buffer], { type });
  return new File([blob], fileName, { lastModified: new Date().getTime(), type });
}
