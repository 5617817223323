import { SetStateAction, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';

// @Utils
import { axiosEstimates } from '../../utils';

// @Interfaces and Types
interface IFiles {
  idUser: number;
  fileName: string;
  url: string;
  src: string;
  typeFile: string;
}

export function useFileView() {
  const [page, setPage] = useState(1);
  const [savedFiles, setSavedFiles] = useState<IFiles[]>([]);
  const [currentFile, setCurrentFile] = useState<IFiles[]>([]);

  // SUCCESS: File configs
  const filesPerPage = 1; // Define la cantidad de documentos por página
  const indexOfLastFile = page * filesPerPage;
  const indexOfFirstFile = indexOfLastFile - filesPerPage;

  const navigate = useNavigate();

  const { id } = useParams<{ id: string }>();

  const [searchParams] = useSearchParams();

  const type = searchParams.get('model');

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  // Accede a los valores de los parámetros de consulta
  const query = queryParams.get('ctg');

  // NOTE: ALL HANDLE FUNCTIONS
  const handleBack = () => {
    if (!query) {
      navigate(-1);
    }
    // Construye la nueva URL con los parámetros de búsqueda
    navigate(`/suppliers/details/${query}?ctg=true`, { replace: true, relative: 'route' });
  };

  const handleChangePage = (e: any, newPage: SetStateAction<number>) => {
    setPage(newPage);
  };

  const getDocument = async () => {
    try {
      let endpoint;

      if (type === 'expenses') endpoint = `file-expenses/${id}`;
      if (type === 'suppliers') endpoint = `file-suppliers/${id}`;

      if (!endpoint) throw Error('The endpoint is required');

      const files = await axiosEstimates.get<IFiles[]>(endpoint);

      setSavedFiles(files.data);
    } catch (err) {
      console.error(err);
    }
  };

  // NOTE: ALL USE EFFECTS
  useEffect(() => {
    getDocument();
  }, []);

  useEffect(() => {
    setCurrentFile(savedFiles.slice(indexOfFirstFile, indexOfLastFile));
  }, [savedFiles, page]);

  return {
    // NOTE: DATA
    savedFiles,
    currentFile,
    filesPerPage,
    // NOTE: PAGINATION
    page,
    // NOTE: HANDLE FUNCTIONS
    handleBack,
    handleChangePage,
  };
}
