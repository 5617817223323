import { useTranslation } from 'react-i18next';

// @MUI Components
import { CardContent, Button, Grid, Box } from '@mui/material';

// @MUI Icons
import AddIcon from '@mui/icons-material/Add';

// @Components
import { SearchField } from '../../../components';
import { ServiceCategoryTable } from '../../../views/services/serviceCategory-table/serviceCategory-view';
import { ServiceCategoryForm } from '../../../views/services/serviceCategory-form/serviceCategory-form.view';

// @Interfaces and Types
import { ServiceCategoryModel } from '../../../models/categories';
interface IProps {
  categoryData: {
    permissions: string[];
    show: boolean;
    tableIsLoading: boolean;
    filter: string;
    serviceCategory: ServiceCategoryModel | undefined;
    serviceCategories: ServiceCategoryModel[];
    addCategoryTitle: { name: string; ESname: string };
    deleteCategory: (id: number) => Promise<void>;
    handleShow: (render: boolean, serviceCategory?: ServiceCategoryModel) => void;
    handleFilter: (value: string) => void;
    handleAddCategory: (serviceCategory: ServiceCategoryModel) => void;
    handleEditCategory: (editServiceCategory: ServiceCategoryModel) => void;
  };
}

export const ServiceCategory = ({ categoryData }: IProps) => {
  const {
    // NOTE: PERMISSIONS
    permissions,
    // NOTE: STATUS
    show,
    tableIsLoading,
    // NOTE: FILTERS
    filter,
    // NOTE: DATA
    serviceCategory,
    serviceCategories,
    addCategoryTitle,
    // NOTE: ALL ACTIONS
    deleteCategory,
    // NOTE: ALL HANDLE FUNCTIONS
    handleShow,
    handleFilter,
    handleAddCategory,
    handleEditCategory,
  } = categoryData;

  const { t, i18n } = useTranslation('common');

  return (
    <CardContent>
      {show ? null : (
        <Grid
          container
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            justifyContent: 'center',
            alignItems: 'center',
            rowGap: 4,
          }}
        >
          <Grid item xs md={8} lg={6}>
            <Box sx={{ width: { sx: '100%', lg: '75%', xl: '65%' } }}>
              <SearchField value={filter} handleFilter={handleFilter} placeholder={t('general.category')} />
            </Box>
          </Grid>

          {permissions.includes('add_service_category') && (
            <Grid item xs sx={{ width: '100%', display: 'flex', justifyContent: { xs: 'center', sm: 'flex-end' } }}>
              <Button size='large' variant='contained' onClick={() => handleShow(true)}>
                <AddIcon />
                {i18n.language === 'es' ? addCategoryTitle.ESname : addCategoryTitle.name}
              </Button>
            </Grid>
          )}
        </Grid>
      )}

      {(permissions.includes('add_service_category') || permissions.includes('edit_service_category')) && show && (
        <ServiceCategoryForm
          handleRender={handleShow}
          category={serviceCategory}
          addState={handleAddCategory}
          editState={handleEditCategory}
        />
      )}

      <ServiceCategoryTable
        t={t}
        tableIsLoading={tableIsLoading}
        categories={serviceCategories}
        permissions={permissions}
        handleDelete={deleteCategory}
        handleEdit={handleShow}
      />
    </CardContent>
  );
};
