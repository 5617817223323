// @Models
import { IShipment, IShipmentForm } from '../../../../models';

export function addShipmentAdapter(shipment: IShipmentForm): IShipment | null {
  if (!shipment.id) {
    return null;
  }
  return {
    id: shipment.id,
    origin: shipment.origin,
    destiny: shipment.destiny,
    addressOrigin: shipment.addressOrigin,
    addressDestiny: shipment.addressDestiny,
    content: shipment.content,
    unitOfMeasurement: shipment.unitOfMeasurement,
    length: shipment.length,
    high: shipment.high,
    width: shipment.width,
    physicalWeight: shipment.physicalWeight,
    volumetricWeight: shipment.volumetricWeight,
    incoterms: shipment.incoterms,
    area: shipment.area,
    comments: shipment.comments,
    serial: shipment.serial,
    services: shipment.services,
    subTotal: shipment.services.reduce((prev, serv) => prev + serv.price, 0),
    ivaTotal: shipment.services.reduce((prev, serv) => prev + serv.amountOfIva, 0),
    total: shipment.services.reduce((prev, serv) => prev + serv.amountOfService, 0),
    insurance: shipment.insurance,
    taxationTo: shipment.taxationTo,
    idServiceType: null,
    idTransportation: null,
  };
}

export function editShipmentAdapter(shipment?: IShipment): IShipmentForm | undefined {
  if (!shipment) {
    return undefined;
  }
  return {
    id: shipment.id,
    origin: shipment.origin,
    destiny: shipment.destiny,
    addressOrigin: shipment.addressOrigin,
    addressDestiny: shipment.addressDestiny,
    content: shipment.content,
    unitOfMeasurement: shipment.unitOfMeasurement,
    length: shipment.length,
    high: shipment.high,
    width: shipment.width,
    physicalWeight: shipment.physicalWeight,
    volumetricWeight: shipment.volumetricWeight,
    incoterms: shipment.incoterms,
    area: shipment.area,
    comments: shipment.comments,
    serial: shipment.serial,
    // amountOfIva: 0,
    // amountOfService: 0,
    // ivaCharge: false,
    // price: 0,
    // priceCost: 0,
    // serviceAmount: 0,
    // serviceId: null,
    // serviceName: '',
    serviceType: null,
    transportationId: null,
    services: shipment.services,
    insurance: shipment.insurance,
    taxationTo: shipment.taxationTo,
  };
}
