// @Utils
import { axiosEstimates } from '../../../utils';

// @Interfaces and Types
import { IServiceTypesRequest } from '../../../models';

export default async function getTypeServices(filter: string) {
  try {
    const response = await axiosEstimates.get<{ getServiceType: IServiceTypesRequest[]; permissions: any }>(
      `/servicetypes?filter=${filter}`,
      { headers: { 'x-module': 'services' } },
    );

    if (response.status === 200) return response.data;
    else throw new Error('Error to fetch service types');
  } catch (error) {
    console.error(error);
  }
}
