import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { NumericFormat } from 'react-number-format';

// @Mui
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';

// @Utils
import DataGridStyles from '../../../utils/DataGridStyles';
import { defaultColumns } from '../utils/services-column';

// @Hooks
import { useCustomerInteraction, useRedux } from '../hooks';

// @Components
import Pagination from '../../../components/Pagination';
import { ReactSelectForm, ButtonForm } from '../../../components';

// @Interfaces and Types
import { ServicesViewForm, ServiceSupplier } from '../models';

export function ServicesView() {
  // @States
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(5);
  const [rows, setRows] = useState<ServiceSupplier[]>([]);
  const [selectedRows, setSelectedRows] = useState<ServiceSupplier[]>([]);

  // @Hooks
  const { t, i18n } = useTranslation();

  const {
    handleServices: handleNext,
    services,
    selectedServices,
    countryOrigin,
    countryDestiny,
  } = useCustomerInteraction();
  const { countryItems } = useRedux();
  const { control, handleSubmit, setValue, getValues } = useForm<ServicesViewForm>({
    defaultValues: {
      servicesSelected: [],
    },
  });

  // @Utils
  const columns = defaultColumns();

  // @Effects
  useEffect(() => {
    setSelectedRows(selectedServices);

    handleServices(countryOrigin, countryDestiny);
  }, [countryItems]);

  // @Functions
  const handleServices = (countryOrigin: string, countryDestiny: string) => {
    const servicesFiltered = services.filter(
      service => service.countryOrigin === countryOrigin && service.countryDestiny === countryDestiny,
    );

    setValue('countryOrigin', countryOrigin);
    setValue('countryDestiny', countryDestiny);
    setRows(servicesFiltered);
  };

  const onChangeCountryOrigin = (value: unknown) => {
    const countryOrigin = value as string;
    const countryDestiny = getValues('countryDestiny');

    handleServices(countryOrigin, countryDestiny);
  };

  const onChangeCountryDestiny = (value: unknown) => {
    const countryDestiny = value as string;
    const countryOrigin = getValues('countryOrigin');

    handleServices(countryOrigin, countryDestiny);
  };

  const handleSelectRows = (ids: number[]) => {
    const servicesSelected = rows.filter(row => ids.includes(row.id));

    setValue('servicesSelected', servicesSelected);
    setSelectedRows(servicesSelected);
  };

  const onSubmit = (data: ServicesViewForm) => {
    handleNext(data);
  };

  return (
    <>
      <Box style={{ width: '100%' }}>
        <Grid container spacing={5} sx={{ my: 2 }}>
          <Grid item xs={12} sm={6}>
            <ReactSelectForm
              control={control}
              name='countryOrigin'
              label={t('general.origin', { ns: 'common' })}
              items={countryItems}
              onExternalChange={onChangeCountryOrigin}
            />
          </Grid>

          <Grid item xs={11} sm={6}>
            <ReactSelectForm
              control={control}
              name='countryDestiny'
              label={t('general.destiny', { ns: 'common' })}
              items={countryItems}
              onExternalChange={onChangeCountryDestiny}
            />
          </Grid>
        </Grid>

        <DataGrid
          rows={rows}
          page={page}
          pageSize={pageSize}
          autoHeight
          sx={{ ...DataGridStyles }}
          checkboxSelection
          disableSelectionOnClick
          columns={columns}
          onSelectionModelChange={newSelection => {
            handleSelectRows(newSelection as number[]);
          }}
          selectionModel={selectedRows.map(row => row.id)}
          hideFooter
        />

        <Pagination
          count={rows.length}
          setPage={setPage}
          setPageSize={setPageSize}
          page={page}
          limitPagination={[5]}
          pageSize={pageSize}
        />
      </Box>

      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mt: 2 }}>
        <Grid container spacing={4} sx={{ mt: 1, mx: 5 }}>
          <Grid item xs={12} sx={{ display: 'grid', justifyContent: 'end' }}>
            <InputLabel>{i18n.language === 'es' ? 'Total Aproximado' : 'Estimate Total'} </InputLabel>

            <NumericFormat
              name='subTotal'
              value={selectedRows.reduce((acc, row) => {
                const iva = row.saleType === 3 ? row.salePrice * 0.13 : 0;
                const price = row.salePrice + iva;
                return acc + price;
              }, 0)}
              customInput={TextField}
              thousandSeparator
              decimalScale={2}
              disabled
            />
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', p: 2 }}>
        <ButtonForm
          label={t('general.next', { ns: 'common' })}
          color='info'
          onClick={handleSubmit(onSubmit)}
          disabled={selectedRows.length === 0}
        />
      </Box>
    </>
  );
}
