import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

// @Utils
import { axiosEstimates } from '../../utils/api';

// @Interfaces
import { IBudgetById } from '../../models';

export function useBudgetPrint() {
  const [budget, setBudget] = useState<IBudgetById | undefined>();

  const { id } = useParams<{ id: string }>();

  const handleGet = async () => {
    try {
      const response = await axiosEstimates.get<IBudgetById>('budget/' + id);
      setBudget(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    handleGet();
  }, []);

  return { budget };
}
