import { TAddExpense } from '../../../models';
import * as yup from 'yup';

export const initialValues: TAddExpense = {
  description: '',
  type: 0,
  quantity: 0,
  unitPrice: 0,
  subTotal: 0,
  iva: 0,
  ivaRet: 0,
  isrRet: 0,
  total: 0,
};

export const schemaValidation = yup
  .object()
  .shape({
    type: yup.number().typeError('errors.required').required('errors.required').min(1, 'errors.required'),
    quantity: yup.number().typeError('errors.required').required('errors.required').min(1, 'errors.required'),
    // unitPrice: yup.number().typeError('errors.required').required('errors.required').min(0.001, 'errors.required'),
    description: yup.string().required('errors.required'),
  })
  .required();
