import * as yup from 'yup';

export const schemaPayments = yup
  .object({
    type: yup.string().required('type'),
    paymentMethod: yup.string().required('errors.payments.paymentMethod'),
    bank: yup.string().optional().nullable(),
    depositAccount: yup.string().typeError('errors.payments.depositAccountInvalid').optional().nullable(),
    paymentDate: yup.string().required('errors.require.date'),
    reference: yup.string().required('errors.payments.referenceRequired'),
    receivedAmount: yup
      .number()
      .moreThan(0, 'errors.payments.amountRequired')
      .required('errors.payments.amountRequired'),
    note: yup.string().optional().nullable(),
    userId: yup.number().required('errors'),
    user: yup.object({ name: yup.string().required('errors.username') }),
  })
  .required();
