import axios from 'axios';
import { useParams } from 'react-router';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { axiosEstimates } from '../../../../../utils/api';

// @MUI Components
import { Grid, Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material';

// @MUI Icons
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ISupplierRequest } from '../../../../../models';

// @Interfaces and Types
interface ISupplierDetails {
  address: string;
  country: string;
  email: string;
  id: number;
  idSupplier: number;
  phone: string;
  created_at: string;
  update_at: string;
}

const DefaultSupplier = [
  {
    address: '',
    country: '',
    email: '',
    id: 0,
    idSupplier: 0,
    phone: '',
    created_at: '',
    update_at: '',
  },
];

export default function TabInformation() {
  const [suppDetails, setSuppDetails] = useState<ISupplierDetails[]>(DefaultSupplier);
  const [phone, setPhone] = useState(false);
  const [email, setEmail] = useState(false);
  const [address, setAddress] = useState(false);

  const { id } = useParams();

  const { t } = useTranslation(['common']);

  const getSupplierInformation = async () => {
    try {
      const responseGET = await axiosEstimates.get<ISupplierRequest>(`supplierdetails/${id}`, {
        headers: { 'x-module': 'services' },
      });
      if (!responseGET?.data?.message) setSuppDetails(responseGET.data.suppliersDetails);
      else setSuppDetails([]);
    } catch (err) {
      console.error(err);
    }
  };

  function handlePhone() {
    setPhone(!phone);
    setEmail(false);
    setAddress(false);
  }

  function handleEmail() {
    setPhone(false);
    setEmail(!email);
    setAddress(false);
  }

  function handleAddress() {
    setPhone(false);
    setEmail(false);
    setAddress(!address);
  }

  useEffect(() => {
    getSupplierInformation();
  }, []);

  return (
    <Grid container item xs={12} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: 4 }}>
      <Typography variant='h5' sx={{ textAlign: 'center' }}>
        {t('general.supplierData')}
      </Typography>

      <Box sx={{ width: '100%' }}>
        {/* Phone */}
        <Accordion expanded={phone}>
          <AccordionSummary
            id='panel1a-header'
            onClick={handlePhone}
            aria-controls='panel1a-content'
            expandIcon={<ExpandMoreIcon />}
          >
            <Typography sx={{ width: '33%', flexShrink: 0 }}>{t('general.phone')}:</Typography>

            {suppDetails?.map(
              (e: any, i: any) =>
                i < 1 && (
                  <Typography key={i} sx={{ textAlign: 'center' }}>
                    {e.phone}
                  </Typography>
                ),
            )}
          </AccordionSummary>

          <AccordionDetails>
            {suppDetails?.map(
              (e: any, i) =>
                i > 0 && (
                  <Typography sx={{ ml: '32.25%', '@media (max-width: 500px)': { m: 0 } }} key={i}>
                    {e.phone}
                  </Typography>
                ),
            )}
          </AccordionDetails>
        </Accordion>

        {/* Email */}
        <Accordion expanded={email}>
          <AccordionSummary
            id='panel1a-header'
            onClick={handleEmail}
            aria-controls='panel1a-content'
            expandIcon={<ExpandMoreIcon />}
          >
            <Typography sx={{ width: '33%', flexShrink: 0 }}>{t('general.email')}:</Typography>

            {suppDetails?.map(
              (e: any, i) =>
                i < 1 && (
                  <Typography sx={{ '@media (max-width: 500px)': { display: 'none' } }} key={i}>
                    {e.email}
                  </Typography>
                ),
            )}
          </AccordionSummary>

          <AccordionDetails>
            {suppDetails?.map(
              (e: any, i) =>
                i > 0 && (
                  <Typography sx={{ ml: '32.25%', '@media (max-width: 500px)': { m: 0 } }} key={i}>
                    {e.email}
                  </Typography>
                ),
            )}
          </AccordionDetails>
        </Accordion>

        {/* Address / Country */}
        <Accordion expanded={address}>
          <AccordionSummary
            id='panel1a-header'
            onClick={handleAddress}
            aria-controls='panel1a-content'
            expandIcon={<ExpandMoreIcon />}
          >
            <Typography sx={{ width: '33%', flexShrink: 0 }}>{t('general.address')}:</Typography>

            <Typography sx={{ '@media (max-width: 500px)': { display: 'none' } }}>
              {`${suppDetails[0].address} ${suppDetails[0].country}`}
            </Typography>
          </AccordionSummary>

          <AccordionDetails>
            {suppDetails?.map(
              (e: any, i) =>
                i > 0 && (
                  <Typography sx={{ ml: '32.25%', '@media (max-width: 500px)': { m: 0 } }} key={i}>
                    {`${e.address} ${e.country}`}
                  </Typography>
                ),
            )}
          </AccordionDetails>
        </Accordion>
      </Box>
    </Grid>
  );
}
