import { useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// @Mui
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

// @Utils
import { axiosEstimates } from '../../utils/api';
import CustomSwalAlert from '../../utils/CustomSwalAlert';

export function ShowBudget() {
  const [src, setSrc] = useState<string | null>(null);

  const [searchParams] = useSearchParams();

  const { t } = useTranslation(['common']);

  const validateToken = async (token: string) => {
    const toastId = toast.loading(t('toast.loading'));
    try {
      const response = await axiosEstimates.get<{ data: { idBudget: number } }>('/invoice/validate-token', {
        headers: { 'x-invoice-token': token },
      });
      const data = response.data.data;
      setSrc(`${process.env.REACT_APP_ESTIMATES_API}pdf/download/budget?id=${data.idBudget}`);
    } catch (error) {
      setSrc(null);
      CustomSwalAlert(t('general.error'), t('toast.budget'), 'error', false);
    }
    toast.dismiss(toastId);
  };

  useEffect(() => {
    validateToken(searchParams.get('token') || '');
  }, []);

  if (!src) {
    return (
      <Grid container>
        <Grid item xs={12}>
          <Card
            sx={{
              height: '95vh',
              mx: { xs: 'auto', sm: '5rem', md: '10rem', lg: '15rem', xl: '20rem' },
            }}
          >
            <CardContent sx={{ height: '95%' }}>
              <Skeleton variant='rounded' width='100%' height='100%' animation='wave' />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container>
      <Grid item xs={12} sx={{ mx: 'auto' }}>
        <Card
          sx={{
            height: '95vh',
            mx: { xs: 'auto', sm: '5rem', md: '10rem', lg: '15rem', xl: '20rem' },
          }}
        >
          <CardContent sx={{ height: '95%' }}>
            <iframe
              src={src}
              style={{
                display: 'block',
                width: '100%',
                height: '100%',
              }}
            />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
