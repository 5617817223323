import MiniCardComponent from '../../../components/MiniCard';
import Grid from '@mui/material/Grid';

export const MiniCard = ({ t, data }: any) => {
  const remaing = data?.data?.stadistics[0]?._max?.maxFolio - data?.data?.stadistics[0]?._min?.lastFolio + 1 || 0;

  return (
    <Grid container spacing={4}>
      <Grid item xs={6} md={3}>
        <MiniCardComponent
          title={t('invoice.Prox')}
          icon={'mdi:invoice-arrow-left-outline'}
          stats={data?.data?.stadistics[0]?._min?.lastFolio || '0'}
          color='info'
        />
      </Grid>
      <Grid item xs={6} md={3}>
        <MiniCardComponent
          title={t('invoice.Cancel')}
          icon={'mdi:invoice-remove-outline'}
          stats={data?.data?.cancelStadistics || '0'}
          color='error'
        />
      </Grid>
      <Grid item xs={6} md={3}>
        <MiniCardComponent
          title={t('invoice.Max')}
          icon={'mdi:invoice-add'}
          stats={data?.data?.configCode[0]?.maxFolio || '0'}
          color='success'
        />
      </Grid>
      <Grid item xs={6} md={3}>
        <MiniCardComponent
          title={t('invoice.Remaing')}
          icon={'mdi:invoice-clock-outline'}
          stats={`${remaing}` || '0'}
          color='warning'
        />
      </Grid>
    </Grid>
  );
};
