import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { AxiosError } from 'axios';
import sweetalert from 'sweetalert2';

// @Models
import { ServiceTypeModel } from '../../../models';

// @Services
import getTypeServices from '../services/getTypeServices';

// @Utils
import { axiosEstimates } from '../../../utils/api';

export function useServiceType(tabValue: any) {
  // NOTE: PERMISSIONS
  const [permissions, setPermissions] = useState<string[]>([]);
  // NOTE: STATUS
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [tableIsLoading, setTableIsLoading] = useState(true);
  // NOTE: FILTERS
  const [filter, setFilter] = useState('');
  // NOTE: DATA
  const [serviceType, setServiceType] = useState<ServiceTypeModel | undefined>();
  const [serviceTypes, setServiceTypes] = useState<ServiceTypeModel[]>([]);
  const [addTypeServicesTitle, setAddTypeServicesTitle] = useState({ name: '', ESname: '' });

  const { t } = useTranslation(['common']);

  const fetchTypeServices = async (fromFilters?: boolean) => {
    try {
      !fromFilters ? setIsLoading(true) : setTableIsLoading(true);

      const data = await getTypeServices(filter);

      if (data?.getServiceType.length) {
        const serviceTypes: ServiceTypeModel[] = data.getServiceType.map(type => ({
          id: type.id,
          name: type.name,
          nameEN: type.nameEN,
          transportation: type.transportation,
          canEdit: type.canEdit,
          username: type.username,
        }));

        setServiceTypes(serviceTypes);
      }

      if (data?.permissions) {
        setPermissions(Object.keys(data.permissions));
        setAddTypeServicesTitle(data.permissions.add_service_type);
      }
    } catch (err) {
      console.error(err);
      setServiceTypes([]);
    } finally {
      setTimeout(() => setIsLoading(false), 300);
      setTableIsLoading(false);
    }
  };

  // NOTE: ALL ACTIONS
  const deleteService = async (id: number) => {
    sweetalert
      .fire({
        icon: 'warning',
        title: `${t('general.areYouSure')}`,
        showConfirmButton: true,
        showCancelButton: true,
        cancelButtonColor: '#d33',
        confirmButtonColor: '#3085d6',
        cancelButtonText: t('general.cancel', { ns: 'common' }) || '',
        confirmButtonText: t('general.confirm', { ns: 'common' }) || '',
      })
      .then(async actions => {
        if (actions.isConfirmed) {
          try {
            await axiosEstimates.delete('service-type/' + id);
            setServiceTypes(services => services.filter(service => service.id !== id));
          } catch (error) {
            if (error instanceof AxiosError) {
              if (error.response?.status === 409) {
                sweetalert.fire(
                  `${t('errors.services.deleteTitle')}`,
                  `${t('errors.services.deleteRelation')}`,
                  'error',
                );
              }
            }
          }
        }
      });
  };

  // NOTE: ALL HANDLE FUNCTIONS
  const handleShow = (render: boolean, serviceType?: ServiceTypeModel) => {
    setShow(render);
    setServiceType(serviceType);
  };

  const handleFilter = (value: string) => {
    setFilter(value);
  };

  const handleAddService = async () => {
    await fetchTypeServices();
    setShow(false);
  };

  const handleEditServiceType = async () => {
    await fetchTypeServices();
    setShow(false);
  };

  // NOTE: ALL USE EFFECTS
  // NOTA: REFACTORIZAR TODAS LAS TABS PARA QUE LOS DATOS SE OBTENGAN AL CAMBIAR DE TAB
  useEffect(() => {
    if (filter || tabValue === 1 || serviceTypes.length === 0) {
      fetchTypeServices(true);
    }
  }, [filter, tabValue, serviceType]);

  return {
    // NOTE: PERMISSIONS
    permissions,
    // NOTE: STATUS
    show,
    isLoading,
    tableIsLoading,
    // NOTE: FILTERS
    filter,
    // NOTE: DATA
    serviceType,
    serviceTypes,
    addTypeServicesTitle,
    // NOTE: ACTIONS
    deleteService,
    // NOTE: HANDLE FUNCTIONS
    handleShow,
    handleFilter,
    handleAddService,
    handleEditServiceType,
  };
}
