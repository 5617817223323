import React, { useState } from 'react';

// @Components
import AppBar from '../components/AppBar';

// @Mui
import CssBaseline from '@mui/material/CssBaseline';
import { MainBoxWrapper, ContentWrapper, LayoutWrapper } from './StyledComponents';
import { VerticalNavigation } from '../components/Sidebar/Navigation/VerticalNavigation';

const drawerWidth = 280;

const MainContentWrapper = MainBoxWrapper(drawerWidth);

// @Interfaces and types
type ILayoutComponentProps = {
  window?: () => Window;
  children: React.ReactNode;
};

/**
 * @component
 * @subcategory Global
 *
 * @description
 * A layout component that wraps the main content with a navigation drawer and app bar.
 *
 * #### Example
 * ```jsx
 * return(
 * <LayoutComponent>
 *   <!-- Your content here -->
 * </LayoutComponent>
 * )
 * ```
 *
 * @property {ReactNode} children The content to be rendered inside the layout.
 * @property {function} window A function that returns the window object.
 *
 * @return {JSX.Element} The layout component with navigation drawer and app bar.
 * @author CML Exports Front-End Developers
 */
export const LayoutComponent = ({ children, window }: ILayoutComponentProps) => {
  const [open, setOpen] = useState(false);
  const [lock, setLock] = useState(false);

  const handleDrawerToggle = () => {
    setOpen(!open);
    if (lock) setLock(false);
  };

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <LayoutWrapper>
      <CssBaseline />

      {/* <Navigation
        drawerWidth={drawerWidth}
        open={open}
        handleDrawerToggle={handleDrawerToggle}
        container={container}
        lock={lock}
        handleDrawerStayToggle={handleDrawerStayToggle}
      /> */}
      <VerticalNavigation open={open} setOpen={setOpen} />

      <MainContentWrapper open={open}>
        <AppBar handleDrawerToggle={handleDrawerToggle} open={open} />
        <ContentWrapper>{children}</ContentWrapper>
      </MainContentWrapper>
    </LayoutWrapper>
  );
};
