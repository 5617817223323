// @Utils
import { axiosEstimates } from '../../../utils';

const getPaymentFile = async (paymentId: number) => {
  const response = await axiosEstimates.get(`/api/bill-payment-file/${paymentId}`);
  const { status, statusText, data } = response.data;

  if (status === 200) return data;
  else throw new Error(`${status} - ${statusText || 'Error al guardar el pago'} `);
};

export default getPaymentFile;
