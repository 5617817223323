import { Box, Divider, Grid, Stack, Typography, Button } from '@mui/material';
import { useInvoiceForm } from './UseEditInvoice';
import { ButtonForm, TextForm } from '../../../../components';
import { FFCaptureData } from '../../models/Models';
import { IGetBudgets } from '../../../../models';
import { yupResolver } from '@hookform/resolvers/yup';
import { FFCreateToValidationsSchema } from './validations/FF';
import { useForm } from 'react-hook-form';
interface IProps {
  data?: FFCaptureData;
  row?: IGetBudgets;
}

export const FormInvoiceFF = ({ data, row }: IProps) => {
  const { t, cancel, handleDataInput, onSubmit, total } = useInvoiceForm({
    data,
    row,
  });
  const numericTotal = total && parseFloat(total);
  const isValidationEnabled = numericTotal && numericTotal > 200;

  const { control, handleSubmit } = useForm<FFCaptureData>({
    resolver: isValidationEnabled ? yupResolver(FFCreateToValidationsSchema) : undefined,
  });

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '70vh',
        width: '100%',
      }}
    >
      <Box
        sx={{
          width: '800px', // Ancho de la tarjeta
          backgroundColor: 'white',
          borderRadius: '8px', // Bordes redondeados
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', // Sombra
        }}
      >
        <Stack sx={{ padding: '30px' }} spacing={2} direction='column' alignItems='center'>
          <Typography sx={{ textAlign: 'center', fontSize: 'x-large', fontWeight: '600px' }} component='div'>
            {t('general.missingData', { ns: 'common' })}
          </Typography>
          <Divider sx={{ width: '100%' }} />
          <form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {/* Primer input que abarca el 100% */}
                <TextForm
                  control={control}
                  name='accountof'
                  label={t('general.saleAccount')}
                  type='string'
                  // onChange={e => handleDataInput(e.target)}
                />
              </Grid>
              <Grid item xs={6}>
                {/* Segundo input */}
                <TextForm
                  control={control}
                  name='name'
                  label={t('general.nameBusiness')}
                  type='string'
                  // onChange={e => handleDataInput(e.target)}
                />
              </Grid>
              <Grid item xs={6}>
                {/* Tercer input */}
                <TextForm
                  control={control}
                  name='IVAWithheld'
                  label={t('general.IVAWithheld')}
                  type='number'
                  // onChange={e => handleDataInput(e.target)}
                />
              </Grid>
              <Grid item xs={6}>
                {/* Cuarto input */}
                <TextForm
                  control={control}
                  name='DUI'
                  label={t('general.nitDui')}
                  type='string'
                  // onChange={e => handleDataInput(e.target)}
                />
              </Grid>
              <Grid item xs={6}>
                {/* Quinto input */}
                <TextForm
                  control={control}
                  name='passport'
                  label={t('general.passport')}
                  type='string'
                  // onChange={e => handleDataInput(e.target)}
                />
              </Grid>
            </Grid>
          </form>
          <Divider sx={{ width: '100%' }} />
          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            {/* <ButtonForm
              label={t('general.cancel', { ns: 'common' })}
              onClick={() => cancel()}
              customStyles={{
                backgroundColor: 'red',
                color: 'white',
              }}
            /> */}
            <Button color='error' variant='contained' onClick={() => cancel()}>
              {t('general.cancel', { ns: 'common' })}
            </Button>
            {<ButtonForm size='large' label={t('general.send', { ns: 'common' })} onClick={handleSubmit(onSubmit)} />}
          </Box>
        </Stack>
      </Box>
    </Box>
  );
};
