import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// ** Views
import { StepperU, DisplayStep } from '../../views/budget-form';

// ** MUI Components
import { Grid, Box, Typography } from '@mui/material';

// ** Hooks
import { useBudgetForm } from './use-budget-form.hook';

export function BudgetForm() {
  const [isShowPDF, setIsShowPDF] = useState(false);

  const { currentStep, steps, type } = useBudgetForm();

  const { t } = useTranslation(['common']);

  const [searchParams] = useSearchParams();

  return (
    <Grid container sx={{ width: '90%', ml: 'auto', mr: { xs: 0.5, md: 'auto' }, zIndex: 1 }}>
      {!isShowPDF && (
        <Typography sx={{ width: '100%', textAlign: 'center' }} variant='h5'>
          {searchParams.get('option') === '2'
            ? t('budgetPage.types.request')
            : t(`${type === 1 ? 'general.budget' : 'general.budgetFromUS'}`)}
        </Typography>
      )}

      <Box sx={{ width: '100%' }}>
        {!isShowPDF && (
          <Box sx={{ mt: 5 }}>
            <StepperU steps={steps} currentStep={currentStep} />
          </Box>
        )}

        <DisplayStep currentStep={currentStep} setIsShowPDF={setIsShowPDF} />
      </Box>
    </Grid>
  );
}
