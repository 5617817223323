// @Utils
import { axiosEstimates } from '../../../utils';

const deletePayment = async (paymentId: string | number) => {
  const response = await axiosEstimates.delete(`/api/bill-payments/${paymentId}`);
  const { status, message } = response.data;

  if (status === 200) return true;
  else throw new Error(`${status} - ${message || 'Error al guardar el pago'} `);
};

export default deletePayment;
