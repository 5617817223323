import { DataGrid } from '@mui/x-data-grid';
import { styled } from '@mui/system';

// Estilos personalizados
const DataGridStyles = styled(DataGrid)(({ theme }) => ({
  '& .gridHeader': {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
  },
  '& .MuiDataGrid-columnHeaderTitleContainer': {
    backgroundColor: theme.palette.primary.main,
  },
  '& .css-10mb48g-MuiDataGrid-columnHeaders': {
    backgroundColor: theme.palette.primary.main,
  },
  '& .css-1jbbcbn-MuiDataGrid-columnHeaderTitle': {
    fontWeight: 700,
  },
  '& .Mui-checked': {
    color: '#fff',
  },
  maxHeight: 400,
}));

export default DataGridStyles;
