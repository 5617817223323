import * as yup from 'yup';
import { IExpensesForm } from '../../../models';

export const initialValues: IExpensesForm = {
  code: '',
  date: '',
  expenses: [],
  idBudget: null,
  idSupplier: null,
  otherCharges: false,
  status: 0,
  type: '',
};

export const schemaValidation = yup
  .object()
  .shape({
    idBudget: yup.number().typeError('errors.required').required('errors.required'),
    idSupplier: yup.number().typeError('errors.required').required('errors.required'),
    code: yup
      .string()
      .matches(/^[^/]*$/, 'El campo no puede contener "/"')
      .required('errors.required'),
    type: yup.string().required('errors.required'),
    date: yup.string().required('errors.required'),
  })
  .required();
