// @Utils
import { axiosEstimates } from '../../../utils';

// @Interfaces and Types
import { CustomerProps } from '../../../models';

const getCustomer = async (budgetId: number) => {
  const response = await axiosEstimates.get<CustomerProps>(`/customer/${budgetId}`);
  const { statusText, status, data } = response;

  if (status === 200) return data;
  else throw new Error(`${status} - ${statusText || 'Error al obtener customer'} `);
};

export default getCustomer;
