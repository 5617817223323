// @Mui
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';

// @Components
import { TextForm, ButtonForm, SelectInput, DatePicker } from '../../../components';

// @Hooks
import { useClientTab } from './use-client-tab.hook';

// @Models
import { IClientTabForm } from '../../../models';
// @Utils
import { useItems } from '../../budget-form/service-form/utils/items';
import { registerLocale } from 'react-datepicker';
import es from 'date-fns/locale/es';

interface IProps {
  data: IClientTabForm;
  budgetStatus: string;
  idBudget: number;
  handleChange: (newValue: number) => void;
  updateDateOfExpiration(date: string): void;
  budgetHaveBill: boolean;
}
registerLocale('es', es);
export const ClientTab = ({
  budgetStatus,
  data,
  idBudget,
  handleChange,
  updateDateOfExpiration,
  budgetHaveBill,
}: IProps) => {
  const { t, control, onSubmit, role, expDateChange, expDaysChange } = useClientTab({
    budgetStatus,
    data,
    idBudget,
    handleChange,
    updateDateOfExpiration,
    budgetHaveBill,
  });
  // const currentLanguage = i18next.language;

  const { incotermsItem } = useItems();

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
      <Grid container spacing={5}>
        <Grid item xs={12} sm={6}>
          <TextForm control={control} name='seller' label={t('budgetPage.seller')} disabled />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextForm control={control} name='customer' label={t('budgetPage.customer')} disabled />
        </Grid>
        {role === 'admin' && (
          <>
            <Grid item xs={12} sm={6}>
              <TextForm control={control} name='commission' label={t('general.commission')} disabled />
            </Grid>

            {budgetStatus === 'pending' && (
              <Grid item xs={12} sm={6}>
                <TextForm
                  control={control}
                  name='oldCommission'
                  label={t('general.oldCommission', { ns: 'common' })}
                  disabled
                />
              </Grid>
            )}
          </>
        )}
      </Grid>

      <Box>
        <CardHeader
          title={t('budgetPage.details')}
          sx={{ display: 'flex', textAlign: 'center', mb: 5 }}
          titleTypographyProps={{ variant: 'h6' }}
        />

        <Grid container spacing={5}>
          <Grid item xs={12} sm={6}>
            <TextForm
              control={control}
              name='daysOfExpiration'
              label={t('budgetPage.expDays')}
              type='number'
              onExternalChange={expDaysChange}
            />
          </Grid>
          {/* <Grid item xs={12} sm={6}>
            <Controller
              name='dateOfExpiration'
              control={control}
              rules={{
                required: {
                  value: true,
                  message: t('errors.required', { ns: 'common' }),
                },
              }}
              render={({ field }) => (
                <>
                  <DatePicker
                    minDate={new Date()}
                    value={field.value || ''}
                    shouldCloseOnSelect
                    onChange={date => field.onChange(date)}
                    selected={field.value ? new Date(field.value) : null}
                    customInput={
                      <TextField
                        label={t('budgetPage.expDate', { ns: 'common' })}
                        fullWidth
                        size='medium'
                        ref={field.ref}
                      />
                    }
                    locale={currentLanguage}
                  />
                </>
              )}
            />
            <small>
              {t('budgetPage.expDate', { ns: 'common' })}:{' '}
              <Moment fromNow ago add={{ days: expDays || undefined }}>
                {today}
              </Moment>{' '}
              | <Typography variant={'caption'}> {today.toLocaleDateString()} </Typography>
            </small>
          </Grid> */}
          <Grid item xs={12} sm={6}>
            <DatePicker
              name='dateOfExpiration'
              control={control}
              minDate={new Date()}
              onExternalChange={expDateChange}
            />
          </Grid>
          <Grid item xs={12}>
            <SelectInput control={control} name='incoterms' label='Incoterms' items={incotermsItem} />
          </Grid>
        </Grid>
      </Box>

      <Divider />

      <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
        <ButtonForm label={t('general.edit', { ns: 'common' })} onClick={onSubmit} />
      </Box>
    </Box>
  );
};
