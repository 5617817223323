/**
 * @Category Utils
 * @param {{mimetype: string, filename?: string}} fileInfo File mimetype and name.
 * @returns Return the file's format.
 */
export function getFileFormat(fileInfo: { mimetype: string; filename?: string }) {
  const { mimetype, filename } = fileInfo;

  if (!mimetype && !filename) {
    throw new Error("In fileInfo is required neither 'mimetype' or 'filename'");
  }

  if (mimetype) {
    if (mimetype.includes('image/jp')) return '.jpg';
    else if (mimetype.includes('png')) return '.png';
    else if (mimetype.includes('webp')) return '.webp';
    else if (mimetype.includes('pdf')) return '.pdf';
    else if (mimetype.includes('gif')) return '.gif';
    else return `Este formato no estaba contemplado: ${mimetype}`;
  }

  if (filename) return filename.split('.')[1];
}
