import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { AxiosError } from 'axios';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

// @Models
import { ServiceTypeForm, ServiceTypeModel, ISelectInput } from '../../../models';

// @Utils
import { axiosEstimates } from '../../../utils/api';

interface IParams {
  addState: (data: ServiceTypeModel) => void;
  editState: (data: ServiceTypeModel) => void;
  service?: ServiceTypeModel;
}

const schemaValidation = yup
  .object()
  .shape({
    name: yup.string().required('service.serviceType'),
    nameEN: yup.string().required('service.serviceTypeEN'),
    transportations: yup.array().required('service.category').min(1, 'errors.required'),
  })
  .required();

export function useTypeServiceForm({ addState, service, editState }: IParams) {
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm<ServiceTypeForm>({
    defaultValues: { name: '', nameEN: '', transportations: [] },
    resolver: yupResolver(schemaValidation),
  });

  const { t } = useTranslation(['common']);

  const onSubmit = async ({ name, nameEN, transportations }: ServiceTypeForm) => {
    try {
      const body = { name, nameEN, transportations: transportations.map(trans => trans.value) };

      if (!service) {
        const response = await axiosEstimates.post<ServiceTypeModel>('service-types', body);
        addState(response.data);
      } else {
        const response = await axiosEstimates.put<ServiceTypeModel>('service-types/' + service.id, body);
        editState(response.data);
      }
    } catch (error) {
      const err = error as AxiosError;
      if (err.response?.status === 409) {
        toast.error(t((err?.response?.data as { error: string })?.error));
      }
    }
  };

  useEffect(() => {
    if (service) {
      const transportations: ISelectInput<number>[] = service.transportation.map(trans => ({
        label: trans.name,
        value: trans.id,
      }));
      reset({ name: service.name, nameEN: service.nameEN, transportations });
    }
  }, [service]);

  return {
    control,
    onSubmit: handleSubmit(onSubmit),
    errors,
  };
}
