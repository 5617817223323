import Swal from 'sweetalert2';
import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

// @Redux
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/reducers';
import { useAppDispatch } from '../../redux/store';
import { clearBudgets } from '../../redux/slices/budgets';
import { getCompanyByRol } from '../../redux/slices/budget-form/actions-async';
import { getBudgets, getBudgetStatusCount } from '../../redux/slices/budgets/actions-async';

// @Utils
import { axiosEstimates } from '../../utils/api';

const ROUTE = {
  1: '/budgets',
  2: '/ctis',
  3: '/budgets-from-us',
};

// @Interfaces and Types
interface Params {
  type: 1 | 2 | 3;
}

export default function useBudget({ type }: Params) {
  // NOTE: PAGINATION
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  // NOTE: STATUS
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [isOpenComments, setIsOpenComments] = useState(false);
  const [isLoadingByFilters, setIsLoadingByFilters] = useState(false);
  // NOTE: SEARCH
  const [value, setValue] = useState('');
  const [filterCustomer, setFilterCustomer] = useState<number | null>(null);
  // NOTE: DATA
  const [status, setStatus] = useState('all');
  const [startDate] = useState<Date | null>(null);
  const [endDate] = useState<Date | null>(null);
  const [typeFilter, setTypeFilter] = useState(1);
  const [idBudget, setIdBudget] = useState<number | undefined>();
  const [addBudgetTitle, setAddBudgetTitle] = useState({ name: '', ESname: '' });
  const [cancelManyTitle, setCancelManyTitle] = useState({ name: '', ESname: '' });

  const {
    budgets: { budgets, pagination, loading, statusCount, permissions },
    user: { user, role },
    budgetForm: { companyByRol },
  } = useSelector((state: RootState) => state);

  const {
    control,
    watch,
    setValue: setValueForm,
    resetField,
  } = useForm({
    defaultValues: { typeFilter: 1, customerFilter: 0 },
  });

  const typeFilterWatch = watch('typeFilter');

  const { t } = useTranslation(['common']);

  const idUser = user?.id || 0;
  const isSeller = ['subscriber'].includes(role);

  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  // NOTE: ACTIONS
  const cancelStatus = (id: number, budgetStatus: string) => {
    const data = { id, status: budgetStatus };

    Swal.fire({
      title:
        budgetStatus === 'cancel'
          ? t('general.sure', { ns: 'validations' }) || ''
          : t(`budget.${budgetStatus}Title`, { ns: 'validations' }) || '',
      text:
        budgetStatus === 'cancel'
          ? t('delete.expenses', { ns: 'validations' }) || ''
          : t(`budget.${budgetStatus}`, { ns: 'validations' }) || '',
      icon: 'warning',
      padding: '0 2rem 1rem',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: t('general.cancel', { ns: 'common' }) || '',
      confirmButtonText: t('general.confirm', { ns: 'common' }) || '',
    }).then(result => {
      if (result.isConfirmed) {
        axiosEstimates.post('budget/status/', data).then(() => handleGet());

        Swal.fire(
          t('general.good', { ns: 'validations' }) || '',
          t(`budget.${budgetStatus}Success`, { ns: 'validations' }) || '',
          'success',
        );

        handleGetStatusCount();
      }
    });
  };

  // NOTE: HANDLE FUNCTIONS
  const handleGet = () => {
    dispatch(
      getBudgets({
        page,
        perPage,
        filter: value,
        filterCustomer,
        idUser,
        isSeller,
        status,
        type,
        from: startDate?.toISOString() || '',
        to: endDate?.toISOString() || '',
      }),
    );
  };

  const handleNavigate = () => navigate(ROUTE[type] + `/create?type=${type}`);

  const handleGetStatusCount = () => {
    dispatch(getBudgetStatusCount({ type }));
  };

  const handleView = (id: number) => navigate('/budgets/view/' + id);

  const handleCustomerFilter = (value: number) => {
    setFilterCustomer(value);
    setValueForm('customerFilter', value);
  };

  const handleStatus = (value: string) => {
    setStatus(value);
    setPage(1);
  };

  const handleToggleComments = (idBudget?: number) => {
    setIsOpenComments(prev => !prev);
    setIdBudget(idBudget);
  };

  const handleToggleFilter = () => setIsOpenFilter(!isOpenFilter);

  // NOTE: ALL USE EFFECTS
  useEffect(() => {
    if (user) dispatch(getCompanyByRol({ email: user.email }));
  }, []);

  useEffect(() => {
    if (permissions.add) setAddBudgetTitle(permissions.add);
    if (permissions.cancel_batch) setCancelManyTitle(permissions.cancel_batch);
  }, [permissions]);

  useEffect(() => {
    if (params.code) {
      handleStatus('toBeConfirmed');
      setTimeout(() => setValue(params.code || ''), 500);
    }
  }, [params.code]);

  // Cambia el tipo de filtro
  useEffect(() => {
    if (typeFilterWatch === 1) {
      setTypeFilter(1);
      resetField('customerFilter');
      setFilterCustomer(null);
    }
    if (typeFilterWatch === 2) setTypeFilter(2);
  }, [typeFilterWatch]);

  useEffect(() => {
    if (value || filterCustomer !== null) setIsLoadingByFilters(true);

    handleGet();
    handleGetStatusCount();

    return () => {
      dispatch(clearBudgets());
    };
  }, [type, page, perPage, value, status, startDate, endDate, filterCustomer]);

  useEffect(() => {
    setValue('');
  }, [type]);

  return {
    // NOTE: PERMISSIONS
    permissions: Object.keys(permissions),
    // NOTE: STATUS
    isLoading: loading.show,
    isLoadingByFilters,
    isOpenFilter,
    isOpenComments,
    // NOTE: PAGINATION
    count: pagination?.count || 0,
    page,
    setPage,
    perPage,
    setPerPage,
    // NOTE: DATA
    idUser,
    isSeller,
    idBudget,
    budgets,
    value,
    status,
    setValue,
    companyByRol,
    cancelManyTitle,
    addBudgetTitle,
    typeFilter,
    // NOTE: UTILS
    control,
    resetField,
    statusCount,
    resetState: () => handleGet(),
    // NOTE: ACTIONS
    cancelStatus,
    setTypeFilter,
    setFilterCustomer,
    // NOTE: HANDLE FUNCTIONS
    handleView,
    handleStatus,
    handleNavigate,
    handleCustomerFilter,
    handleToggleFilter,
    handleToggleComments,
  };
}
