import { useTranslation } from 'react-i18next';

// @MUI Imports
import { Box, Typography } from '@mui/material/';

// @Icon Imports
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

// @Interfaces and Types
interface IProps {
  icon?: boolean;
  description?: string | null;
}

/**
 * @component
 * @subcategory Global
 *
 * @description
 * An error page component that displays either "Unexpected Error" or "" message and a button to go back to the home page.
 * #### Example
 * ```
 * return (
 *  <Error />
 * )
 * ```
 *
 * @property {boolean} [icon=true] Indicates if you want to show the icon.
 * @property {string | null} [description=null] The error's description.
 *
 * @returns {JSX.Element} An error page component that displays either "Unexpected Error" or "" message and a button to go back to the home page.
 * @author CML Exports Front-End Developers
 */
export default function Error({ description = null, icon = true }: IProps) {
  const { t } = useTranslation('common');

  return (
    <Box
      sx={{
        p: 5,
        mt: '25vh',
        width: '90%',
        ml: 'auto',
        mr: { xs: 0.5, md: 'auto' },
        zIndex: 1,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      {icon && <ErrorOutlineIcon sx={{ color: '#f09b9b', fontSize: '4rem' }} />}

      <Typography variant='h2' sx={{ mt: 2, textAlign: 'center', fontSize: '1.4rem', color: '#afafaf' }}>
        {description || t('general.errorUnexpected')}
      </Typography>
    </Box>
  );
}
