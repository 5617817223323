import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// @Utils
import { axiosEstimates } from '../../../utils/api';
import CustomSwalAlert from '../../../utils/CustomSwalAlert';

// @Models
import { PdfConfiguration } from '../../../models';

export default function useSettings() {
  // NOTE: PERMISSIONS
  const [permissions, setPermissions] = useState<string[]>([]);
  // NOTE: DATA
  const [configuration, setConfiguration] = useState<PdfConfiguration[]>([]);
  const [addTitle, setAddTitle] = useState<{ name: string; ESname: string }>({ name: '', ESname: '' });
  const [editTitle, setEditTitle] = useState<{ name: string; ESname: string }>({ name: '', ESname: '' });
  const [deleteTitle, setDeleteTitle] = useState<{ name: string; ESname: string }>({ name: '', ESname: '' });
  const [totalData, setTotalData] = useState<number>(0);
  // NOTE: PAGINATION
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(5);

  const navigate = useNavigate();

  const { t } = useTranslation('validations');

  // NOTE: ALL ACTIONS
  const getConfiguration = async () => {
    try {
      const response = await axiosEstimates.get(`/pdfConfig?page=${page + 1}&pageSize=${pageSize}`, {
        headers: { 'x-module': 'design_options' },
      });

      if (response.data.data) {
        setConfiguration(response.data.data);
        setTotalData(response.data.totalData);
        setPermissions(Object.keys(response.data.permissions));
      }

      response.data.permissions.add_design_options && setAddTitle(response.data.permissions.add_design_options);
      response.data.permissions.edit_design_options && setEditTitle(response.data.permissions.edit_design_options);
      response.data.permissions.delete_design_options &&
        setDeleteTitle(response.data.permissions.delete_design_options);
    } catch {
      setConfiguration([]);
    }
  };

  // NOTE: ALL HANDLE FUNCTIONS
  const handleNavigate = () => navigate('/pdfSettings/create');

  const handleDelete = async (id: number) => {
    try {
      CustomSwalAlert(t('general.sure'), t('delete.designOptions'), 'question', true, async () => {
        await axiosEstimates.delete(`/pdfConfigDelete/${id}`);
        getConfiguration();
        CustomSwalAlert(t('general.good'), t('success.designOptionsDeleted'), 'success', false);
      });
    } catch (err) {
      console.error(err);
    }
  };

  const handleEdit = (id: any) => navigate(`/pdfConfigUpdate/${id}`);

  // NOTE: ALL USE EFFECTS
  useEffect(() => {
    getConfiguration();
  }, [page, pageSize]);

  return {
    // NOTE: PERMISSIONS
    canAdd: permissions.includes('add_design_options'),
    canEdit: permissions.includes('edit_design_options'),
    canDelete: permissions.includes('delete_design_options'),
    // NOTE: DATA
    configuration,
    addTitle,
    editTitle,
    deleteTitle,
    // NOTE: ALL HANDLE FUNCTIONS
    handleNavigate,
    handleEdit,
    handleDelete,
    // NOTE: PAGINATION
    totalData,
    pageSize,
    setPageSize,
    page,
    setPage,
  };
}
