// @Mui
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import { GoBackButton } from '../../../../components/GoBackButton';
export const InvoiceFF = () => {
  const src = 'https://www.proturbiomarspa.com/files/_pdf-prueba.pdf';

  return (
    <Grid container>
      <Grid item xs={12} sx={{ mx: 'auto' }}>
        <Card sx={{ height: '95vh' }}>
          <GoBackButton />
          <CardHeader
            title='ADCX00'
            sx={{ display: 'flex', textAlign: 'center' }}
            titleTypographyProps={{ variant: 'h6' }}
          />
          <Divider sx={{ margin: 0 }} />
          <CardContent sx={{ height: '90%' }}>
            <iframe
              src={src}
              style={{
                display: 'block',
                width: '100%',
                height: '100%',
              }}
            />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
