import { useTranslation } from 'react-i18next';

// * HOOKS
import useStatus from '../hooks/useStatus';
import { useServices } from '../hooks/useServices';
import useDeleteService from '../hooks/useDeleteService';

// * MUI Components
import { TableRow, TableCell, Typography, IconButton, Switch, Box } from '@mui/material';
import { DeleteOutline, Eye } from 'mdi-material-ui';

// * COMPONENTS

import EditBtn from './EditBtn';
// * DEFINITIONS
import { Service } from '../def';

export default function ServiceItem({ row }: { row: Service }) {
  const { i18n, t } = useTranslation('common');

  const { handleStatus } = useStatus(row.id);

  const { handleDelete } = useDeleteService();

  const { handleView, permissions } = useServices();

  return (
    <TableRow>
      <TableCell>
        <Typography
          sx={{ width: { xs: 200, lg: 160, xl: 250 } }}
          noWrap
          title={row.type.categories
            .map(categoty => {
              if (i18n.language === 'es') return categoty.name;
              return categoty.nameEN;
            })
            .join(', ')}
        >
          {row.type.categories
            .map(categoty => {
              if (i18n.language === 'es') return categoty.name;
              return categoty.nameEN;
            })
            .join(', ')}
        </Typography>
      </TableCell>

      <TableCell>
        <Typography
          sx={{ width: { xs: 200, lg: 160, xl: 250 } }}
          noWrap
          title={i18n.language === 'es' ? row.type.name : row.type.nameEN}
        >
          {i18n.language === 'es' ? row.type.name : row.type.nameEN}
        </Typography>
      </TableCell>

      <TableCell>
        <Typography
          sx={{ width: { xs: 200, lg: 160, xl: 250 } }}
          noWrap
          title={i18n.language === 'es' ? row.name : row.nameEN}
        >
          {i18n.language === 'es' ? row.name : row.nameEN}
        </Typography>
      </TableCell>

      <TableCell>
        <Box
          sx={{
            width: '100%',
            minHeight: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 0,
          }}
        >
          {row.canEdit && <EditBtn row={row} />}

          {permissions.includes('view_details') && (
            <IconButton color='success' onClick={async () => await handleView(row.id)}>
              <Eye />
            </IconButton>
          )}

          {permissions.includes('delete_service') && (
            <IconButton
              sx={{ p: 0.5 }}
              color='error'
              onClick={() => handleDelete(row.id)}
              title={`${t('general.delete')}`}
            >
              <DeleteOutline fontSize='medium' />
            </IconButton>
          )}

          {permissions.includes('service_status') && (
            <IconButton sx={{ p: 0 }}>
              <Switch
                sx={{ scale: '0.8' }}
                value={row.status === 'active'}
                checked={row.status === 'active'}
                onChange={e => handleStatus(e.target.checked ? 'active' : 'inactive')}
              />
            </IconButton>
          )}
        </Box>
      </TableCell>
    </TableRow>
  );
}
