import { useTranslation } from 'react-i18next';

// @MUI Components
import { Grid, Card, Divider, CardHeader } from '@mui/material';

// @Providers
import ServicesProvider from './Provider';

// @Components
import ServicesList from './components/ServicesList';
import ServicesPageHader from './components/ServicePageHeader';
import ServicesForm from './components/ServicesForm';
import ServiceTypeForm from './components/ServiceTypeForm';
import ViewService from './components/ViewService';
import CategoryList from './components/CategoryList';

export function ServicesModule() {
  const { t } = useTranslation('common');

  return (
    <ServicesProvider>
      <Grid container sx={{ width: '90%', ml: 'auto', mr: { xs: 0.5, md: 'auto' }, zIndex: 1 }}>
        <Card sx={{ width: '100%', padding: '2rem' }}>
          <CardHeader title={t('title.services')} sx={{ display: 'flex', textAlign: 'center' }} />

          <Divider sx={{ mb: 4, mx: 'auto', my: 2 }} />

          <ServicesPageHader />

          <Divider sx={{ mx: 'auto', my: 2 }} />

          <ServicesList />
        </Card>
      </Grid>

      <ServicesForm />
      <ServiceTypeForm />
      <ViewService />
      <CategoryList />
    </ServicesProvider>
  );
}
