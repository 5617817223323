import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { DefaultTFuncReturn } from 'i18next';

// @MUI Components
import Close from '@mui/icons-material/Close';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import { DialogContent, DialogTitle, Typography, Box, IconButton } from '@mui/material/';

// @Interfaces
interface Props extends DialogProps {
  open: boolean;
  dialogTitle?: string | DefaultTFuncReturn;
  handleClose: () => void;
  children?: ReactNode;
}

/**
 * @component
 * @subcategory Global
 *
 * @description
 * A custom dialog component using Material-UI, providing a flexible and reusable way to display dialogs in the application.
 * #### Example
 * ```tsx
 * <CustomDialog open={true} dialogTitle="Dialog Title" handleClose={() => console.log('Dialog closed')}
 * >
 *  <div>Dialog content</div>
 * </CustomDialog>
 * ```
 *
 * @property {boolean} open Whether the dialog is open or not.
 * @property {string | DefaultTFuncReturn} [dialogTitle] The title of the dialog, can be a string or a translation function return value.
 * @property {function} handleClose The callback function to be called when the dialog is closed.
 * @property {ReactNode} [children] The content of the dialog.
 * @property {DialogProps} props Additional props to be passed to the Material-UI Dialog component.
 *
 * @return {JSX.Element} The custom dialog component.
 * @author CML Exports Front-End Developers
 */
export default function CustomDialog({ open, dialogTitle, handleClose, children, ...props }: Props) {
  const { t } = useTranslation('common');

  return (
    <Dialog onClose={handleClose} open={open} {...props}>
      <DialogTitle>
        <Box sx={{ textAlign: 'center' }}>
          {dialogTitle && (
            <Typography variant='h4' component='span'>
              {t(dialogTitle, { ns: 'common' })}
            </Typography>
          )}

          <IconButton aria-label='close' sx={{ position: 'absolute', right: 8, top: 8 }} onClick={handleClose}>
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent sx={{ minWidth: { xs: '20rem', sm: '35rem', md: '50rem' }, p: 0 }}>
        <Box sx={{ margin: '0 1rem', padding: '1rem', display: { lg: 'flex' }, flexDirection: 'column' }}>
          {children}
        </Box>
      </DialogContent>
    </Dialog>
  );
}
