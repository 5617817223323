// @MUI Components
import { Card, Grid } from '@mui/material/';

// @Hooks
import { useInvoiceSettings } from './useInvoiceSttings';

// @Components
import { CustomTabs } from '../../components';
import { InvoiceCodeView } from './components/InvoicePage';

// @Models
import { TabOption } from '../../models';

export function InvoiceSettings() {
  const { value } = useInvoiceSettings();

  const tabs: TabOption[] = [
    { id: 1, label: 'CCF', content: <InvoiceCodeView type={1} /> },
    { id: 2, label: 'FF', content: <InvoiceCodeView type={2} /> },
    { id: 3, label: 'FSE', content: <InvoiceCodeView type={3} /> },
  ];

  return (
    <Grid container sx={{ width: '90%', ml: 'auto', mr: { xs: 0.5, md: 'auto' }, zIndex: 1 }}>
      <Card sx={{ width: '100%' }}>
        <CustomTabs tabs={tabs} tab={value} />
      </Card>
    </Grid>
  );
}
