import { useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

// @MUI Components
import { Box, Chip, ListItem, Typography } from '@mui/material';

// @MUI Icons
import CircleOutline from 'mdi-material-ui/CircleOutline';

// @Components
import { NavigationContext } from '../Navigation/Navigation';

// @Styled
import { MenuNavLink } from './styled.component';

// @Interfaces
import { Item } from '../../../models/items';

interface Props {
  item: Item;
  group?: boolean;
  open?: boolean;
  isMobile?: boolean;
  setOpen?: (arg0: boolean) => void;
}

function NavLink({ item, open, isMobile, setOpen }: Props) {
  const IconTag = item.icon === undefined ? CircleOutline : item.icon;

  const context = useContext(NavigationContext);

  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = () => {
    if (isMobile) setOpen && setOpen(false);

    navigate(item.path || '/');
    context.closeDrawerMobile();
  };

  const isNavLinkActive = () => location.pathname === item.path;

  return (
    <ListItem disablePadding className='nav-link' sx={{ display: 'flex' }} onClick={handleClick}>
      <MenuNavLink className={isNavLinkActive() ? 'active' : ''}>
        <IconTag />
        <Box className='animate__slideInLeft' display={'flex'}>
          {open && (
            <Typography className='animate__slideInLeft' sx={{ pl: 3.5, whiteSpace: 'nowrap', overflow: 'hidden' }}>
              {item.title}
            </Typography>
          )}
          {item.badgeContent && open ? (
            <Chip
              label={item.badgeContent}
              color={item.badgeColor || 'primary'}
              sx={{
                height: 20,
                fontWeight: 500,
                marginLeft: 1.25,
                '& .MuiChip-label': { px: 1.5, textTransform: 'capitalize' },
              }}
            />
          ) : null}
        </Box>
      </MenuNavLink>
    </ListItem>
  );
}

export default NavLink;
