// @Utils
import { axiosEstimates } from '../../../utils';

// @Interfaces and Types
import { PaymentType } from '../types';

const sendPayment = async (paymentData: PaymentType) => {
  const response = await axiosEstimates.post('/api/bill-payments/', paymentData);
  const { statusText, status, data } = response;

  if (status === 200) {
    return {
      data: data.data,
      message: data.data.type.toLowerCase() === 'income' ? 'general.paymentCreated' : 'general.expenseCreated',
    };
  } else throw new Error(`${status} - ${statusText && 'Error al guardar el pago'} `);
};

export default sendPayment;
