// @Library
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import React, { MouseEventHandler, useState } from 'react';

// @Components
import Dialog from '../../../components/Dialog';
import { EditorWrapper } from '../../../components/StyledComponents/EditorWrapperStyled';
import ButtonForm from '../../../components/ButtonForm';
import FileUploader from '../../../components/FileUploader/';
import FileDownloader from '../../../components/FileDownloader';

// @Hooks
import { useComments } from './use-comments.hook';

// @Mui
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import { Icon } from '@iconify/react';

interface IProps {
  open: boolean;
  toggle: () => void;
  idBudget?: number;
}

export function Comments({ open, toggle, idBudget }: IProps): JSX.Element {
  const [openFiles, setOpenFiles] = useState(false);

  class CustomOption extends React.Component {
    handleLinkClick: MouseEventHandler<HTMLDivElement> = () => {
      setOpenFiles(!openFiles);
    };

    render() {
      return (
        <Box sx={{ m: 1 }} onClick={this.handleLinkClick}>
          <Icon icon='game-icons:paper-clip' width='20' />
        </Box>
      );
    }
  }

  const { comments, t, editorState, handleEditorChange, handleSubmit, files, setFiles, savedFiles, status } =
    useComments({ open, idBudget });

  const assignColor = (iduser: number) => {
    const color = iduser % 10;
    const Colors = [
      '#D0ECE7',
      '#FCF3CF ',
      '#F5B7B1',
      '#D2B4DE',
      '#A3E4D7',
      '#F9E79F',
      '#F8C471',
      '#FDF2E9',
      '#F4ECF7 ',
      '#FAD7A0',
    ];
    return `${Colors[color]}`;
  };

  const toolbar = {
    options: ['inline', 'list', 'remove', 'history'],
    inline: { options: ['bold', 'italic', 'underline', 'strikethrough'] },
    list: { options: ['unordered', 'ordered'] },
  };

  return (
    <Dialog open={open} handleClose={() => toggle()} dialogTitle={t('general.comments', { ns: 'common' })}>
      <Box sx={{ width: { xs: '16rem', sm: '32rem', md: '46rem' } }}>
        <CardContent sx={{ width: '100%', height: 200, overflowY: 'scroll' }}>
          {[...comments].reverse().map(comment => (
            <Box key={comment.id}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography>{comment.user}</Typography>
                <Typography variant='body2'>{new Date(comment.date).toLocaleString()}</Typography>
              </Box>

              <Box sx={{ bgcolor: assignColor(comment.idUser), borderRadius: '0.2rem' }}>
                <Box
                  sx={{ padding: '0.1rem', margin: '0.3rem 1rem', color: 'text.primary' }}
                  dangerouslySetInnerHTML={{ __html: comment.comment }}
                />
              </Box>
            </Box>
          ))}
        </CardContent>
        <Divider sx={{ mx: 2 }} />
        <CardContent sx={{ width: '100%' }}>
          {status !== 'cancel' && (
            <>
              <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', my: 1 }}>
                <EditorWrapper>
                  <Editor
                    editorState={editorState}
                    onEditorStateChange={handleEditorChange}
                    toolbar={toolbar as any}
                    wrapperClassName='wrapperClassName'
                    editorClassName='editorClassName'
                    // eslint-disable-next-line react/jsx-key
                    toolbarCustomButtons={[<CustomOption />]}
                    toolbarHidden
                  />
                </EditorWrapper>
              </Box>
              {openFiles && (
                <Box sx={{ marginRight: '1rem' }}>
                  <FileUploader files={files} setFiles={setFiles} />
                </Box>
              )}
              <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
                <ButtonForm label={t('general.send')} color='success' onClick={handleSubmit} />
              </Box>
            </>
          )}
          <Box sx={{ marginRight: '1rem' }}>{savedFiles?.length > 0 && <FileDownloader files={savedFiles} />}</Box>
        </CardContent>
      </Box>
    </Dialog>
  );
}
