import { Dispatch, SetStateAction } from 'react';
import { useDropzone } from 'react-dropzone';
import { DefaultTFuncReturn } from 'i18next';
import { useTranslation } from 'react-i18next';

// @MUI Components
import { Box, Button, Typography } from '@mui/material';

// @Icons
import { DeleteOutline } from 'mdi-material-ui';
import FileDocumentOutline from 'mdi-material-ui/FileDocumentOutline';

// @Interfaces and types
import { CustomFiles, modelsType } from '../../models/CustomFiles';
import CustomSwalAlert from '../../utils/CustomSwalAlert';
interface IProps {
  title?: string | DefaultTFuncReturn;
  file: CustomFiles;
  caption?: string;
  maxMbSize?: number;
  setFile: Dispatch<SetStateAction<CustomFiles>>;
  filesViewParams?: {
    id?: string | number;
    model?: modelsType;
  };
}

/**
 * @component
 * @subcategory Global
 *
 * @description
 * A component that allows users to upload a file.
 * #### Example
 * ```
 * return(
 *  <FileUpload title="Upload File" file={file} caption="Drag and drop or click to upload" maxMbSize={10} setFile={setFile} />
 * )
 * ```
 *
 * @property {string | DefaultTFuncReturn} [title] A title to be displayed above the uploader.
 * @property {CustomFiles} file The current selected file.
 * @property {string} [caption] A caption to be displayed below the uploader.
 * @property {number} [maxMbSize] The maximum file size allowed in megabytes.
 * @property {Dispatch<SetStateAction<CustomFiles>>} setFile A callback that will set the selected file on the parent component.
 * @property {object} [filesViewParams] Files view params.
 * @property {string | number} [filesViewParams.id] Files view id.
 * @property {"budgets" | "invoices" | "supplierChat" | "pdfConfiguration" | "payments" | "expenses" | "suppliers" | "documents"} [filesViewParams.model] Files view model.
 *
 * @return {JSX.Element}
 * @author CML Exports Front-End Developers
 */
export default function FileUpload({ title, file, caption, maxMbSize, setFile, filesViewParams }: IProps) {
  const { t, i18n } = useTranslation('common');

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (fileDropped: File[]) => setFile(fileDropped[0]),
  });

  const getFileSize = () => {
    if (file instanceof File) {
      const totalSize =
        Math.round(file.size / 100) / 10 > 1000
          ? `${(Math.round(file.size / 100) / 10000).toFixed(1)} mb`
          : `${(Math.round(file.size / 100) / 10).toFixed(1)} kb`;

      if (typeof maxMbSize === 'number' && totalSize.slice(-2) === 'mb') {
        if (Number(totalSize.slice(0, 2)) > maxMbSize) {
          const errorDescription =
            i18n.language === 'es'
              ? `El tamaño del archivo no puede ser mayor a ${maxMbSize}MB`
              : `File size cannot be larger than ${maxMbSize}MB`;
          CustomSwalAlert(t('general.error'), errorDescription, 'error', false);
          setFile(null);
        }
      }

      return totalSize;
    }
  };

  const handleRemoveFile = () => setFile(null);

  const getFileName = () => {
    if (file) {
      if (file instanceof File) return file.name;
      else return file.fileName;
    }
  };

  const getPreviewFileViewParams = () => {
    if (!filesViewParams?.id && !filesViewParams?.model) return {};

    const { id, model } = filesViewParams;

    return { href: `/filesView/${id}?model=${model}` };
  };

  const getPreviewFile = () => {
    if (file instanceof File) {
      return file?.type?.includes('pdf') ? (
        <FileDocumentOutline sx={{ width: 100, height: 80 }} />
      ) : (
        <img width={'100%'} height={'100%'} src={URL.createObjectURL(file as any)} alt={file.name} />
      );
    } else {
      return file?.fileName?.includes('pdf') ? (
        <Typography
          component={'a'}
          {...getPreviewFileViewParams()}
          sx={{ cursor: 'pointer', color: '#6d6d6d', ':hover': { color: '#313131' }, transition: '300ms ease color' }}
        >
          <FileDocumentOutline sx={{ width: 100, height: 80 }} />
        </Typography>
      ) : (
        <img width={'100%'} height={'100%'} src={file?.src} />
      );
    }
  };

  // If file has content show file icon, name, size and deleteButton
  if (file) {
    return (
      <Box
        sx={{
          width: '100%',
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography
          noWrap
          sx={{ width: { xs: '80%', sm: '100%' }, textOverflow: 'ellipsis', textAlign: 'center', overflow: 'hidden' }}
        >
          {getFileName()}
        </Typography>

        <Box
          sx={{
            my: 2,
            width: 200,
            height: 160,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {getPreviewFile()}

          <Typography variant='body2'>{getFileSize()}</Typography>
        </Box>

        <Button size='small' color='error' variant='outlined' onClick={handleRemoveFile}>
          <DeleteOutline fontSize='medium' />
        </Button>
      </Box>
    );
  }

  return (
    <Box
      {...getRootProps({ className: 'dropzone' })}
      sx={{
        py: 1,
        px: 4,
        borderRadius: '2px',
        border: ({ palette }) => `2.5px dashed ${palette.primary.main}`,
        cursor: 'pointer',
        overflow: 'hidden',
        transition: '300ms ease',
        transitionProperty: 'transform background',
        ':hover': { background: '#70707010', transform: 'scale(1.01)' },
      }}
    >
      <input {...getInputProps()} />

      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography variant='h5' sx={{ fontSize: '1.25rem' }}>
          {title || t('uploader.title')}
        </Typography>

        <Box sx={{ width: 200, height: 200, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <img alt='Upload img' src='/assets/upload.png' width='100%' height='auto' />
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
          {caption && (
            <Typography sx={{ transform: 'translateY(-1rem)', fontSize: '0.75rem' }} color='textSecondary'>
              {caption}
            </Typography>
          )}

          <Typography color='textSecondary'>{t('uploader.description')}</Typography>
          <Button>{t('uploader.system')}</Button>
        </Box>
      </Box>
    </Box>
  );
}
