/**
 * @Category Utils
 * @param {String} date Date to parse.
 * @returns This function return from a common date to a date in format "M/D/Y"
 */
export const FromDateStringToMDYString = (date: string): string => {
  const parsedDate = new Date(date);

  if (isNaN(parsedDate.getTime())) {
    return 'Invalid date';
  }

  const month = String(parsedDate.getMonth() + 1).padStart(2, '0');
  const day = String(parsedDate.getDate()).padStart(2, '0');
  const year = String(parsedDate.getFullYear());

  return `${month}/${day}/${year}`;
};
