import { useTranslation } from 'react-i18next';
import { Control } from 'react-hook-form';
import { Box, Button, Modal, Typography } from '@mui/material';
import CustomInput from '../../../components/CustomInput';
import { Send, Close } from 'mdi-material-ui';
import { CategoryFormValues } from '../def';

interface Props {
  forCreateCategory: boolean;
  onSubmit: () => void;
  control: Control<CategoryFormValues, any>;
  toggleForm: () => void;
}

export default function CategoryForm({ control, onSubmit, forCreateCategory, toggleForm }: Props) {
  const { t } = useTranslation(['common']);

  return (
    <Modal
      open={forCreateCategory}
      onClose={() => toggleForm()}
      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          width: { xs: 300, sm: 400 },
          backgroundColor: 'white',
          p: 3,
        }}
      >
        <Typography
          id='modal-modal-title'
          variant='h6'
          component='h2'
          sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 1, mb: 2 }}
        >
          {t('services.table.category')}
        </Typography>
        <Box
          component={'form'}
          method='POST'
          onSubmit={onSubmit}
          sx={{ display: 'flex', flexDirection: 'column', gap: 2, flex: 1 }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, flex: 1 }}>
            <CustomInput control={control} name='name' label={`${t('services.form.name_es')}`} />
            <CustomInput control={control} name='nameEN' label={`${t('services.form.name_en')}`} />
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
            <Button type='submit' variant='contained' color='primary'>
              <Send />
            </Button>

            <Button variant='contained' color='error' onClick={() => toggleForm()}>
              <Close />
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}
