// @Mui
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import { CardHeader, IconButton, Typography } from '@mui/material';
import CardContent from '@mui/material/CardContent';
// import { DataGrid } from '@mui/x-data-grid';

// @Translations
import { useTranslation } from 'react-i18next';

// @Hooks
import CollapsibleTable from './utils/servicecollapsible';
import { useHistory } from './service-history.hook';
import KeyboardBackspace from '@mui/icons-material/KeyboardBackspace';
import { useNavigate } from 'react-router-dom';

// @Utils
// import { defautlColumn } from './utils/budget-column';
interface chipItem {
  value: string | number;
  label: string;
  color: 'default' | 'success' | 'info' | 'error' | 'primary' | 'secondary' | 'warning';
}
export const ServiceHistory = () => {
  const { history, services } = useHistory();

  const { t } = useTranslation(['common']);

  const navigate = useNavigate();

  const statusItem: chipItem[] = [
    { value: 'active', color: 'success', label: `${t('general.active', { ns: 'common' })}` },
    { value: 'inactive', color: 'error', label: `${t('general.cancelled', { ns: 'common' })}` },
  ];

  const status = statusItem.find(status => status.value === services.status);

  return (
    <Grid container sx={{ width: '90%', ml: 'auto', mr: { xs: 0.5, md: 'auto' }, zIndex: 1 }}>
      <Grid item xs={12}>
        <Card sx={{ width: '100%', mb: 2 }}>
          <IconButton sx={{ display: 'flex', mt: 1 }} color='error' onClick={() => navigate('/services')}>
            <KeyboardBackspace />
          </IconButton>

          <CardHeader title={t('general.service')} />

          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Typography align='center'>{t('general.name', { ns: 'common' })}</Typography>
                <Typography align='center'>{services.name}</Typography>
              </Grid>

              <Grid item xs={4}>
                <Typography align='center'>{t('services.type', { ns: 'common' })}</Typography>
                <Typography align='center'>{services.serviceTypeName}</Typography>
              </Grid>

              <Grid item xs={4}>
                <Typography align='center'>{t('general.status', { ns: 'common' })}</Typography>
                <Typography align='center'>
                  <Chip
                    size='medium'
                    label={status?.label || ''}
                    color={status?.color}
                    sx={{ textTransform: 'capitalize', padding: '0.5rem', margin: '0 0.5rem' }}
                  />
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        <Card>
          <Box sx={{ m: 2 }} />
          <Box
            sx={{
              p: 5,
              display: 'flex',
              flexWrap: 'wrap',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Box sx={{ display: 'flex', gap: 4, justifyContent: 'flex-end' }}>
              <Typography variant='h6' sx={{ textAlign: 'center' }}>
                {t('general.logbook')}
              </Typography>
            </Box>
          </Box>

          <CardContent>
            <CollapsibleTable history={history} />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
