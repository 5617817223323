// @Library
import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { createFilterOptions } from '@mui/material/Autocomplete';
import toast from 'react-hot-toast';

// @Utils
import { axiosEstimates } from '../../../utils';

// @Models
import { ISupplierDetails, ISelectInput } from '../../../models';
import { Shipment } from './models';
import { getShipments } from './services';

const filter = createFilterOptions();

export function useSendEmail() {
  const [shipments, setShipments] = useState<Shipment[]>([]);
  const [editorState, setEditorState] = useState<string>('');
  const [emailTo, setEmailTo] = useState<string[]>([]);
  const [menuItemsArr, setMenuItemsArr] = useState<string[]>([]);
  const [items, setItems] = useState<ISelectInput<string>[]>([]);
  const { id } = useParams<{ id: string }>();

  const contentRef = useRef(null);

  const navigate = useNavigate();

  const { t } = useTranslation(['common']);

  useEffect(() => {
    const toastId = toast.loading(t('toast.loading'));

    axiosEstimates.get<ISupplierDetails[]>('/supplier-details').then(response => {
      const emails: ISelectInput<string>[] = response.data.map(detail => ({
        value: detail.email,
        label: `${detail?.supplier} (${detail.email})`,
      }));
      setMenuItemsArr(emails.map(email => email.label));
      setItems(emails);
      if (id) {
        getShipments(id).then(shipments => {
          setShipments(shipments);
        });
      }
    });
    axiosEstimates.get<{ initialContent: string }>('/suppliers/get-content/' + id).then(res => {
      setEditorState(res.data.initialContent);
    });
    toast.dismiss(toastId);

    return () => {
      toast.dismiss(toastId);
    };
  }, []);

  const addNewOption = (options: string[], params: any): string[] => {
    const filtered = filter(options, params);
    const { inputValue } = params;
    const isExisting = options.some(option => inputValue === option);

    if (inputValue !== '' && !isExisting) {
      filtered.push(inputValue);
    }
    // @ts-ignore
    return filtered;
  };

  const handleAddEmail = (email: string[]) => {
    setEmailTo(email);
  };

  const handleEditorChange = (value: string) => {
    setEditorState(value);
  };

  const handleSendMail = async () => {
    if (!id) return null;

    if (!(emailTo.length > 0)) {
      toast.error(t('send.emailRequire'));

      return null;
    }

    const toastId = toast.loading(t('toast.loading'));

    try {
      const emails: string[] = [];
      emailTo.forEach(email => {
        const item = items.find(item => item.label === email);
        if (item) {
          emails.push(item.value);
        }
      });
      await axiosEstimates.post('/suppliers/send-emails', { emails, content: editorState, idBudget: parseInt(id) });
      toast.dismiss(toastId);
      toast.success(t('toast.sent'));
      navigate('/requests');
    } catch (error: any) {
      toast.dismiss(toastId);
      toast.error(t('general.error', { ns: 'common' }));
    }
  };

  const handleBack = () => {
    navigate('/requests');
  };

  return {
    t,
    emailTo,
    addNewOption,
    handleAddEmail,
    menuItemsArr,
    editorState,
    handleEditorChange,
    handleBack,
    handleSendMail,
    contentRef,
    shipments,
  };
}
