import * as yup from 'yup';
import { CartaPorte } from '../../../../../models';

export const schemaValidation = yup
  .object()
  .shape({
    // general
    reference: yup.string().required('required.general'),
    invoice: yup.string().required('required.general'),
    countryOrigin: yup.string().required('required.general'),
    countryDestiny: yup.string().required('required.general'),
    forwarder: yup.object().shape({
      name: yup.string().required('required.general'),
      pilot: yup.string().required('required.general'),
      licensePlate: yup.string().required('required.general'),
      van: yup.string().required('required.general'),
      numberLicense: yup.string().required('required.general'),
    }),
    loadingPlace: yup.string().required('required.general'),
    exit: yup.string().required('required.general'),
    entrance: yup.string().required('required.general'),
    finalDestination: yup.string().required('required.general'),
    referenceCode: yup.string().required('required.general'),
    expeditionDate: yup.string().required('required.general'),
    content: yup.array().of(
      yup.object().shape({
        type: yup.string(),
        quantity: yup.number().notRequired().nullable().typeError('general.number'),
        decription: yup.string().required('required.general'),
        kglb: yup.string().optional().notRequired().default('Kg'),
        grossWeight: yup.number().notRequired().nullable().typeError('general.number'),
        volume: yup.number().notRequired().nullable().typeError('general.number'),
      }),
    ),
    charges: yup.array().of(
      yup.object().shape({
        description: yup.string().notRequired(),
        prepaid: yup.number().optional().notRequired().nullable().typeError('general.number'),
        collect: yup.number().optional().notRequired().nullable().typeError('general.number'),
      }),
    ),
    by: yup.object().shape({
      name: yup.string().required('required.general'),
      direction: yup.string().required('required.general'),
      email: yup.string().notRequired(),
      phone: yup.string().notRequired(),
    }),
    shipper: yup.object().shape({
      name: yup.string().required('required.general'),
      direction: yup.string().required('required.general'),
      email: yup.string().notRequired(),
      phone: yup.string().notRequired(),
    }),
    notifyto: yup.object().shape({
      name: yup.string().required('required.general'),
      contact: yup.string().required('required.general'),
    }),
    destinyAgent: yup.object().shape({
      name: yup.string().required('required.general'),
      contact: yup.string().required('required.general'),
    }),
  })
  .required();

export const initialValues: CartaPorte = {
  bill: '',
  reference: '',
  invoice: '',
  countryOrigin: '',
  countryDestiny: '',
  consignee: '',
  forwarder: {
    name: '',
    pilot: '',
    licensePlate: '',
    van: '',
    numberLicense: '',
  },
  loadingPlace: '',
  exit: '',
  entrance: '',
  finalDestination: '',
  referenceCode: '',
  expeditionDate: '',
  content: [],
  charges: [],
  conveyors: [],
  by: {
    name: '',
    email: '',
    phone: '',
    direction: '',
  },
  shipper: {
    name: '',
    email: '',
    phone: '',
    direction: '',
  },
  notifyto: {
    name: '',
    contact: '',
  },
  destinyAgent: {
    name: '',
    contact: '',
  },
};
