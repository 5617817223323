// @Library
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';

// @Utils
import { initialValues, schemaValidation } from '../utils/expenseSchema';

// @Interfaces and types
import { IAddExpense, TAddExpense } from '../../../models';
interface IProps {
  open: boolean;
  handleAdd: (value: TAddExpense) => void;
  handleEdit: (value: IAddExpense) => void;
  expense?: IAddExpense;
}

export default function useExpenseForm({ open, handleAdd, handleEdit, expense }: IProps) {
  // NOTE: FORM CONTROL
  const { control, reset, handleSubmit, setValue, watch } = useForm<TAddExpense>({
    defaultValues: initialValues,
    resolver: yupResolver(schemaValidation),
  });

  const { t } = useTranslation();

  const type = watch('type');
  const unitPrice = watch('unitPrice');
  const quantity = watch('quantity');
  const ivaRet = watch('ivaRet');
  const isrRet = watch('isrRet');
  const iva = watch('iva');
  const subTotal = watch('subTotal');

  // NOTE: ALL USE EFFECTS
  useEffect(() => {
    if (open) reset(expense || initialValues);
  }, [open]);

  useEffect(() => {
    const subTotal = unitPrice * quantity;
    const iva = type === 3 ? subTotal * 0.13 : 0;

    setValue('subTotal', subTotal);
    setValue('iva', iva);
  }, [unitPrice, quantity, type]);

  useEffect(() => {
    const isrRet = subTotal * 0.1;
    setValue('isrRet', isrRet);
  }, [iva, subTotal, ivaRet]);

  useEffect(() => {
    const total = subTotal + iva - ivaRet - isrRet;
    setValue('total', total);
  }, [isrRet]);

  const onSubmit = (data: TAddExpense) => (expense ? handleEdit(Object.assign(expense, data)) : handleAdd(data));

  return {
    control,
    t,
    onSubmit: handleSubmit(onSubmit),
  };
}
