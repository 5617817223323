// @Library
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { EditorState } from 'draft-js';
import { convertToHTML } from 'draft-convert';
import toast from 'react-hot-toast';

// @Models
import { IBudgetComments } from '../../../models';

// @Utils
import { axiosEstimates } from '../../../utils/api';
import { socket } from '../../../utils/sockets.util';

// @Redux
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/reducers';

interface IParams {
  open: boolean;
  idBudget?: number;
}

interface IFiles {
  idUser: number;
  filename: string;
  url: string;
}

interface RequestData {
  comments: IBudgetComments[];
  budget: {
    status: string;
  };
}

export function useComments({ open, idBudget }: IParams) {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [comments, setComments] = useState<IBudgetComments[]>([]);
  const [files, setFiles] = useState<File[]>([]);
  const [status, setStatus] = useState<string>('active');
  const [savedFiles, setSavedFiles] = useState<IFiles[]>([]);
  const { t } = useTranslation(['common']);

  const {
    user: { idCRM },
  } = useSelector((state: RootState) => state);

  const getComments = async () => {
    try {
      if (idBudget) {
        const comments = await axiosEstimates.get<RequestData>('comments/' + idBudget);
        setComments(comments.data.comments);
        setStatus(comments.data.budget.status);
      }
    } catch (error) {
      setComments([]);
    }
  };

  const getFiles = async () => {
    try {
      if (idBudget) {
        const files = await axiosEstimates.get<IFiles[]>('file-comments/' + idBudget);
        setSavedFiles(files?.data);
      }
    } catch (error) {
      setSavedFiles([]);
    }
  };

  const updateComments = async () => {
    await getComments();
    await getFiles();
  };

  useEffect(() => {
    if (open) {
      getComments();
      getFiles();
      setEditorState(EditorState.createEmpty());
    }

    return () => {
      setComments([]);
    };
  }, [open]);

  useEffect(() => {
    socket.on('newNotification', updateComments);

    return () => {
      socket.off('newNotification');
    };
  }, [idBudget]);

  const handleEditorChange = (value: EditorState) => {
    setEditorState(value);
  };

  const handleSubmit = async () => {
    if (!idBudget || !idCRM || status === 'cancel') return null;

    const comment = convertToHTML(editorState.getCurrentContent());
    if (comment === '<p></p>') return toast.error(t('comments.required'));

    // Envía un email al receptor del comentario
    const formData = new FormData();

    files.forEach(file => {
      formData.append('files', file);
    });

    formData.append('idBudget', idBudget.toString());
    formData.append('idCRM', idCRM.toString());
    formData.append('comment', comment);

    await axiosEstimates
      .post('/comment-email', formData)
      .then(_ => {
        updateComments();
        toast.success(t('toast.sent'));
      })
      .catch(_ => {
        toast.error(t('toast.error'));
      })
      .finally(() => {
        setEditorState(EditorState.createEmpty());
        setFiles([]);
      });
  };

  return { comments, t, editorState, handleEditorChange, handleSubmit, files, setFiles, savedFiles, status };
}
