import { useState } from 'react';

// @MUI Imports
import DialogP from '../../../../../../components/Dialog';
import FileUploaderMultiple from '../../../../../../components/FileUploader/';

interface Props {
  open: boolean;
  handleClose: () => void;
}

export default function UploadModal({ open, handleClose }: Props) {
  const [files, setFiles] = useState<File[]>([]);

  return (
    <DialogP dialogTitle={'general.upload'} open={open} handleClose={handleClose}>
      {open && <FileUploaderMultiple files={files} setFiles={setFiles} />}
    </DialogP>
  );
}
