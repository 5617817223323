import i18next from 'i18next';

const LocalizedTextMap = () => {
  const currentLanguage = i18next.language;

  if (currentLanguage === 'en') {
    return {
      // Root
      noRowsLabel: 'No rows',
      noResultsOverlayLabel: 'No results found.',

      // Density selector toolbar button text
      toolbarDensity: 'Density',
      toolbarDensityLabel: 'Density',
      toolbarDensityCompact: 'Compact',
      toolbarDensityStandard: 'Standard',
      toolbarDensityComfortable: 'Comfortable',

      // Columns selector toolbar button text
      toolbarColumns: 'Columns',
      toolbarColumnsLabel: 'Select columns',

      // Filters toolbar button text
      toolbarFilters: 'Filters',
      toolbarFiltersLabel: 'Show filters',
      toolbarFiltersTooltipHide: 'Hide filters',
      toolbarFiltersTooltipShow: 'Show filters',
      toolbarFiltersTooltipActive: (count: number) =>
        count !== 1 ? `${count} active filters` : `${count} active filter`,

      // Quick filter toolbar field
      toolbarQuickFilterPlaceholder: 'Search…',
      toolbarQuickFilterLabel: 'Search',
      toolbarQuickFilterDeleteIconLabel: 'Clear',

      // Export selector toolbar button text
      toolbarExport: 'Export',
      toolbarExportLabel: 'Export',
      toolbarExportCSV: 'Download as CSV',
      toolbarExportPrint: 'Print',
      toolbarExportExcel: 'Download as Excel',

      // Columns panel text
      columnsPanelTextFieldLabel: 'Find column',
      columnsPanelTextFieldPlaceholder: 'Column title',
      columnsPanelDragIconLabel: 'Reorder column',
      columnsPanelShowAllButton: 'Show all',
      columnsPanelHideAllButton: 'Hide all',

      // Filter panel text
      filterPanelAddFilter: 'Add filter',
      filterPanelDeleteIconLabel: 'Delete',
      filterPanelLogicOperator: 'Logic operator',
      filterPanelOperator: 'Operator',
      filterPanelOperatorAnd: 'And',
      filterPanelOperatorOr: 'Or',
      filterPanelColumns: 'Columns',
      filterPanelInputLabel: 'Value',
      filterPanelInputPlaceholder: 'Filter value',

      // Filter operators text
      filterOperatorContains: 'contains',
      filterOperatorEquals: 'equals',
      filterOperatorStartsWith: 'starts with',
      filterOperatorEndsWith: 'ends with',
      filterOperatorIs: 'is',
      filterOperatorNot: 'is not',
      filterOperatorAfter: 'is after',
      filterOperatorOnOrAfter: 'is on or after',
      filterOperatorBefore: 'is before',
      filterOperatorOnOrBefore: 'is on or before',
      filterOperatorIsEmpty: 'is empty',
      filterOperatorIsNotEmpty: 'is not empty',
      filterOperatorIsAnyOf: 'is any of',

      // Filter values text
      filterValueAny: 'any',
      filterValueTrue: 'true',
      filterValueFalse: 'false',

      // Column menu text
      columnMenuLabel: 'Menu',
      columnMenuShowColumns: 'Show columns',
      columnMenuManageColumns: 'Manage columns',
      columnMenuFilter: 'Filter',
      columnMenuHideColumn: 'Hide column',
      columnMenuUnsort: 'Unsort',
      columnMenuSortAsc: 'Sort by ASC',
      columnMenuSortDesc: 'Sort by DESC',

      // Column header text
      columnHeaderFiltersTooltipActive: (count: number) =>
        count !== 1 ? `${count} active filters` : `${count} active filter`,
      columnHeaderFiltersLabel: 'Show filters',
      columnHeaderSortIconLabel: 'Sort',

      // Rows selected footer text
      footerRowSelected: (count: number) =>
        count !== 1 ? `${count.toLocaleString()} rows selected` : `${count.toLocaleString()} row selected`,

      // Total row amount footer text
      footerTotalRows: 'Total Rows:',

      // Total visible row amount footer text
      footerTotalVisibleRows: (
        visibleCount: { toLocaleString: () => any },
        totalCount: { toLocaleString: () => any },
      ) => `${visibleCount.toLocaleString()} of ${totalCount.toLocaleString()}`,

      // Checkbox selection text
      checkboxSelectionHeaderName: 'Checkbox selection',
      checkboxSelectionSelectAllRows: 'Select all rows',
      checkboxSelectionUnselectAllRows: 'Unselect all rows',
      checkboxSelectionSelectRow: 'Select row',
      checkboxSelectionUnselectRow: 'Unselect row',

      // Boolean cell text
      booleanCellTrueLabel: 'yes',
      booleanCellFalseLabel: 'no',

      // Actions cell more text
      actionsCellMore: 'more',

      // Column pinning text
      pinToLeft: 'Pin to left',
      pinToRight: 'Pin to right',
      unpin: 'Unpin',

      // Tree Data
      treeDataGroupingHeaderName: 'Group',
      treeDataExpand: 'see children',
      treeDataCollapse: 'hide children',

      // Grouping columns
      groupingColumnHeaderName: 'Group',
      groupColumn: (name: any) => `Group by ${name}`,
      unGroupColumn: (name: any) => `Stop grouping by ${name}`,

      // Master/detail
      detailPanelToggle: 'Detail panel toggle',
      expandDetailPanel: 'Expand',
      collapseDetailPanel: 'Collapse',

      // Used core components translation keys
      MuiTablePagination: {},

      // Row reordering text
      rowReorderingHeaderName: 'Row reordering',

      // Aggregation
      aggregationMenuItemHeader: 'Aggregation',
      aggregationFunctionLabelSum: 'sum',
      aggregationFunctionLabelAvg: 'avg',
      aggregationFunctionLabelMin: 'min',
      aggregationFunctionLabelMax: 'max',
      aggregationFunctionLabelSize: 'size',
    };
  }
  if (currentLanguage === 'es') {
    return {
      noRowsLabel: 'No se encontro información',
      noResultsOverlayLabel: 'No se encontro información',
      errorOverlayDefaultLabel: 'Ha ocurrido un error.',

      // Density selector toolbar button text
      toolbarDensity: 'Densidad',
      toolbarDensityLabel: 'Densidad',
      toolbarDensityCompact: 'Compacta',
      toolbarDensityStandard: 'Standard',
      toolbarDensityComfortable: 'Comoda',

      // Columns selector toolbar button text
      toolbarColumns: 'Columnas',
      toolbarColumnsLabel: 'Seleccionar columnas',

      // Filters toolbar button text
      toolbarFilters: 'Filtros',
      toolbarFiltersLabel: 'Mostrar filtros',
      toolbarFiltersTooltipHide: 'Ocultar filtros',
      toolbarFiltersTooltipShow: 'Mostrar filtros',
      toolbarFiltersTooltipActive: (count: number) =>
        count > 1 ? `${count} filtros activos` : `${count} filtro activo`,

      // Quick filter toolbar field
      toolbarQuickFilterPlaceholder: 'Buscar…',
      toolbarQuickFilterLabel: 'Buscar',
      toolbarQuickFilterDeleteIconLabel: 'Limpiar',

      // Export selector toolbar button text
      toolbarExport: 'Exportar',
      toolbarExportLabel: 'Exportar',
      toolbarExportCSV: 'Descargar como CSV',
      toolbarExportPrint: 'Imprimir',
      toolbarExportExcel: 'Descargar como Excel',

      // Columns panel text
      columnsPanelTextFieldLabel: 'Columna de búsqueda',
      columnsPanelTextFieldPlaceholder: 'Título de columna',
      columnsPanelDragIconLabel: 'Reorder columna',
      columnsPanelShowAllButton: 'Mostrar todo',
      columnsPanelHideAllButton: 'Ocultar todo',

      // Filter panel text
      filterPanelAddFilter: 'Agregar filtro',
      filterPanelDeleteIconLabel: 'Borrar',
      filterPanelLinkOperator: 'Operador lógico',
      filterPanelOperators: 'Operadores',

      // TODO v6: rename to filterPanelOperator
      filterPanelOperatorAnd: 'Y',
      filterPanelOperatorOr: 'O',
      filterPanelColumns: 'Columnas',
      filterPanelInputLabel: 'Valor',
      filterPanelInputPlaceholder: 'Valor de filtro',

      // Filter operators text
      filterOperatorContains: 'contiene',
      filterOperatorEquals: 'es igual',
      filterOperatorStartsWith: 'comienza con',
      filterOperatorEndsWith: 'termina con',
      filterOperatorIs: 'es',
      filterOperatorNot: 'no es',
      filterOperatorAfter: 'es posterior',
      filterOperatorOnOrAfter: 'es en o posterior',
      filterOperatorBefore: 'es anterior',
      filterOperatorOnOrBefore: 'es en o anterior',
      filterOperatorIsEmpty: 'está vacío',
      filterOperatorIsNotEmpty: 'no esta vacío',
      filterOperatorIsAnyOf: 'es cualquiera de',

      // Filter values text
      filterValueAny: 'cualquiera',
      filterValueTrue: 'verdadero',
      filterValueFalse: 'falso',

      // Column menu text
      columnMenuLabel: 'Menú',
      columnMenuShowColumns: 'Mostrar columnas',
      columnMenuFilter: 'Filtro',
      columnMenuHideColumn: 'Ocultar',
      columnMenuUnsort: 'Desordenar',
      columnMenuSortAsc: 'Ordenar ASC',
      columnMenuSortDesc: 'Ordenar DESC',

      // Column header text
      columnHeaderFiltersTooltipActive: (count: number) =>
        count > 1 ? `${count} filtros activos` : `${count} filtro activo`,
      columnHeaderFiltersLabel: 'Mostrar filtros',
      columnHeaderSortIconLabel: 'Ordenar',

      // Rows selected footer text
      footerRowSelected: (count: number) =>
        count > 1 ? `${count.toLocaleString()} filas seleccionadas` : `${count.toLocaleString()} fila seleccionada`,

      // Total row amount footer text
      footerTotalRows: 'Filas Totales:',

      // Total visible row amount footer text
      footerTotalVisibleRows: (visibleCount: any, totalCount: any) =>
        `${visibleCount.toLocaleString()} de ${totalCount.toLocaleString()}`,

      // Checkbox selection text
      checkboxSelectionHeaderName: 'Seleccionar casilla',
      checkboxSelectionSelectAllRows: 'Seleccionar todas las filas',
      checkboxSelectionUnselectAllRows: 'Deseleccionar todas las filas',
      checkboxSelectionSelectRow: 'Seleccionar fila',
      checkboxSelectionUnselectRow: 'Deseleccionar fila',

      // Boolean cell text
      booleanCellTrueLabel: 'si',
      booleanCellFalseLabel: 'no',

      // Actions cell more text
      actionsCellMore: 'más',

      // Column pinning text
      pinToLeft: 'Anclar a la izquierda',
      pinToRight: 'Anclar a la derecha',
      unpin: 'Desanclar',

      // Tree Data
      treeDataGroupingHeaderName: 'Grupo',
      treeDataExpand: 'mostrar hijos',
      treeDataCollapse: 'ocultar hijos',

      // Grouping columns
      groupingColumnHeaderName: 'Grupo',
      groupColumn: (name: any) => `Agrupar por ${name}`,
      unGroupColumn: (name: any) => `No agrupar por ${name}`,

      // Master/detail
      detailPanelToggle: 'Alternar detalle',
      expandDetailPanel: 'Expandir',
      collapseDetailPanel: 'Contraer',

      // Row reordering text
      rowReorderingHeaderName: 'Reordenar filas',

      // Aggregation
      aggregationMenuItemHeader: 'Agregación',
      aggregationFunctionLabelSum: 'sum',
      aggregationFunctionLabelAvg: 'avg',
      aggregationFunctionLabelMin: 'min',
      aggregationFunctionLabelMax: 'max',
      aggregationFunctionLabelSize: 'tamaño',
    };
  }
};

export default LocalizedTextMap;
