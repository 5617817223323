import { useState } from 'react';
import {
  Box,
  IconButton,
  Paper,
  ToggleButton,
  ToggleButtonGroup,
  Toolbar,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useNavigation } from './useNavigation';
import BurgerMenu from '../../Dropdown/BurgerMenu';
import { Close } from 'mdi-material-ui';
import NavItems from '../NavItems';
import { MenuLockedIcon, MenuUnlockedIcon } from '../components/NavBtn';

interface props {
  open: boolean;
  setOpen: (arg0: boolean) => void;
}

export const VerticalNavigation = ({ open, setOpen }: props) => {
  const [locked, setLocked] = useState(false);
  const { items } = useNavigation();
  const newItems = items.filter(item => !item?.notRender);
  const theme = useTheme();

  // const mobile = window.innerWidth < sm;
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleMouseEnter = () => {
    if (!locked) setOpen(true);
  };

  const handleMouseLeave = () => {
    if (!locked) setOpen(false);
  };

  const toggleLock = () => {
    setLocked(!locked);
    setOpen(true); // Keep the navbar open when locked
  };

  const desktopNavbarStyles = {
    width: open ? '250px' : '80px',
    transition: 'width 0.2s',
    height: '100vh',
    position: !locked ? 'fixed' : 'relative',
    zIndex: 999,
    overflowY: 'auto',
    backgroundColor: theme.palette.background.default,
    overflowX: 'hidden',
    borderWidth: open ? (locked ? '0px' : '1px') : '0px',
    boxShadow: open ? (!locked ? '34px -2px 57px -26px rgba(0,0,0,0.53)' : 'none') : 'none',
    WebkitBoxShadow: open ? (!locked ? '34px -2px 57px -26px rgba(0,0,0,0.53)' : 'none') : 'none',
    MozBoxShadow: open ? (!locked ? '34px -2px 57px 2px rgba(0,0,0,0.53)' : 'none') : 'none',
  };

  const mobileNavbarStyles = {
    width: open ? '250px' : '0px',
    display: open ? 'block' : 'none',
    transition: 'width 0.2s',
    height: '100%',
    overflowY: 'auto',
    position: 'fixed', // Cambiamos a 'fixed' para evitar que se corte
    zIndex: 999,
    top: 0,
  };

  const overlay = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.7)', // Fondo oscuro con transparencia
    display: open ? 'block' : 'none',
    zIndex: 998,
  };

  const desktopLocker = () =>
    open && (
      <ToggleButton value='locked' onClick={toggleLock} sx={{ border: 'none' }}>
        {locked ? <MenuLockedIcon /> : <MenuUnlockedIcon />}
      </ToggleButton>
    );

  const mobileLocker = () =>
    open && (
      <IconButton onClick={handleMouseLeave}>
        <Close />
      </IconButton>
    );

  return (
    <Box sx={{ position: mobile ? 'absolute' : 'relative' }}>
      {mobile && (
        <Toolbar>
          {open && <Box sx={overlay} onClick={() => setOpen(false)} />}
          <BurgerMenu handleDrawerToggle={handleMouseEnter} open={open} />
        </Toolbar>
      )}

      <Paper
        elevation={0}
        sx={mobile ? mobileNavbarStyles : desktopNavbarStyles}
        variant='outlined'
        onMouseEnter={() => {
          if (!mobile) handleMouseEnter();
        }}
        onMouseLeave={() => {
          if (!mobile) handleMouseLeave();
        }}
      >
        {/* HEADER */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center',
            height: 80,
            position: 'relative',
          }}
        >
          <Box
            alt='cml'
            component='img'
            src={'/assets/400x246-CML-Exports.png'}
            style={{ width: 50, transform: `scale(${open ? 1.5 : 1})` }}
          />
          <Box />

          <ToggleButtonGroup
            orientation='vertical'
            exclusive
            value={locked ? 'locked' : null}
            sx={{
              border: 'none',
              position: 'absolute',
              top: '50%',
              right: '0' /* Alinea a la derecha */,
              transform: 'translate(0, -50%)' /* Centra verticalmente */,
            }}
          >
            {mobile ? mobileLocker() : desktopLocker()}
          </ToggleButtonGroup>
        </Box>

        {/* ITEM LIST */}
        <NavItems open={open} items={newItems} isMobile={mobile} setOpen={setOpen} />
      </Paper>
    </Box>
  );
};
