import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import { encryptTransform } from 'redux-persist-transform-encrypt';
// @Storage
import { storage } from './storage';
// @Models
import { IUserSessionProps } from '../models';
// @Slice
import user from './slices/user';
import budgetForm from './slices/budget-form';
import services from './slices/services';
import countries from './slices/countries';
import transportation from './slices/transportation';
import suppliers from './slices/suppliers';
import budgets from './slices/budgets';
import notifications from './slices/notifications';
import supplierChat from './slices/supplier-chat';

const userPersistConfig = {
  key: 'user',
  storage,
  blacklist: ['loading', 'errors', 'password'],
  transforms: [
    encryptTransform({
      secretKey: process.env.REACT_APP_ENCRYPT_SECRET as string,
    }),
  ],
};

const rootReducer = combineReducers({
  user: persistReducer<IUserSessionProps>(userPersistConfig, user),
  budgetForm,
  services,
  countries,
  transportation,
  suppliers,
  budgets,
  notifications,
  supplierChat,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
