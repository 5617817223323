import { useState } from 'react';

// ** MUI Imports
// import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Dialog from '../../../../components/Dialog';
import { CustomAvatar } from '../../../../components';

// ** Icons Imports
import Eye from 'mdi-material-ui/Eye';
import FileDocumentOutline from 'mdi-material-ui/FileDocumentOutline';
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';

interface Props {
  filename: string;
  url: string;
}

interface ModalProps extends Props {
  open: boolean;
  onClose: () => void;
}

const ViewerModal = ({ filename, url, onClose, open }: ModalProps) => {
  return (
    <Dialog
      title={filename}
      open={open}
      // maxWidth='lg'
      fullWidth
      handleClose={onClose}
      sx={{ width: '100%', height: '100%' }}
    >
      <div style={{ maxWidth: '100%', maxHeight: '100%', overflow: 'auto' }}>
        <DocViewer
          documents={[{ uri: url, fileName: filename, fileType: filename.split('.')[1] }]}
          pluginRenderers={DocViewerRenderers}
        />
      </div>
    </Dialog>
  );
};

const renderFilePreview = (file: Props) => {
  const extensions = ['png', 'jpg', 'jpeg'];
  const extension = file?.filename.split('.')[1];

  if (extensions.includes(extension)) {
    return <CustomAvatar alt='User Image' src={file.url} variant='rounded' />;
  } else return <FileDocumentOutline />;
};

export function FileView({ filename, url }: Props) {
  const [open, setOpen] = useState(false);

  const toggle = () => {
    setOpen(prev => !prev);
  };

  return (
    <>
      <ViewerModal filename={filename} url={url} onClose={toggle} open={open} />
      <div style={{ maxWidth: '15rem' }}>
        <div className='file-details'>
          <div className='file-preview' onClick={toggle}>
            {renderFilePreview({ filename, url })}
          </div>

          <div>
            <Typography className='file-name' noWrap sx={{ maxWidth: '100%', textOverflow: 'ellipsis' }}>
              {filename}
            </Typography>
          </div>
        </div>
        <IconButton onClick={toggle}>
          <Eye fontSize='small' />
        </IconButton>
      </div>
    </>
  );
}
