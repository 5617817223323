import React from 'react';
import { Toaster } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { redirect as RouteRedirect, RouterProvider } from 'react-router-dom';

// @Redux
import { useSelector } from 'react-redux';
import { RootState } from './redux/reducers';
import { useAppDispatch } from './redux/store';
import { getCountries } from './redux/slices/countries/actions-async';
import { getUser } from './redux/slices/user/ActionsAsync';

// @Routes
import { Router } from './router/router';
import { socket } from './utils';

export const App = () => {
  const { user } = useSelector((state: RootState) => state);

  const dispatch = useAppDispatch();

  const { router } = Router();

  const { i18n } = useTranslation();

  React.useEffect(() => {
    dispatch(getCountries());

    window.localStorage.setItem('lang', user?.language || 'es');
    i18n.changeLanguage(user?.language || 'es');

    socket.on('connect', () => {
      if (user.isAuthenticated) {
        socket.emit('joinUserId', user.user?.id.toString());
      }
    });

    if (user.isAuthenticated) {
      dispatch(getUser({ email: user.user?.email || '', idSystem: user.user?.id || 0 }));
      RouteRedirect('/');
    }

    return () => {
      socket.on('disconnect', () => {
        console.log('Socket disconnected');
      });
    };
  }, [user.user, user.role]);

  return (
    <>
      <Toaster />
      <RouterProvider router={router} />
    </>
  );
};
